import HttpService from "./htttp.service";

class PrintingMachineUserService {

  getPrintingMachineUsers = async(id) => {
    const fetchUrl = 'printing_machine_users?id=' + id;
    return await HttpService.get(fetchUrl);
  }

  getPrintingMachineUsersByUserId = async(user_id) => {
    const fetchUrl = 'printing_machine_users?user_id=' + user_id;
    return await HttpService.get(fetchUrl);
  }

  addPrintingMachineUser = async(data) => {
    const fetchUrl = 'printing_machine_users';
    return await HttpService.post(fetchUrl, data);
  }

  updatePrintingMachineUser = async (id, data) => {
    const fetchUrl = 'printing_machine_users/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showPrintingMachineUser = async(id) => {
    const fetchUrl = 'printing_machine_users/' + id;
    return await HttpService.get(fetchUrl);
  }

  deletePrintingMachineUser = async (id) => {
    const fetchUrl = 'printing_machine_users/' + id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllPrintingMachineUser = async (printing_machine_id) => {
    const fetchUrl = 'printing_machine_users?printing_machine_id=' + printing_machine_id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllPrintingMachineUserByUserId = async (user_id) => {
    const fetchUrl = 'printing_machine_users?user_id=' + user_id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new PrintingMachineUserService();
