/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import purchaseOrderData from "layouts/manage-purchase-orders/data";

//temporary check for staging only it will be removed once it goes on production
const domainRegex = /\@(techhighway\.co\.in|techhighway\.ca)$/i;

function PurchaseOrders() {
  const { columns, rows, open, handleClickOpen, handleClose, deletePurchaseOrder, selectedId, totalData, page, pageSize, getData, sortColumn, sortColumnValue, searchValue, pagePermission, companyName,selectedEmail, selectedSupplierName, openPreview, sendEmail, handlePrint } = purchaseOrderData();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.3rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                <Grid container>
                  <Grid item xs={11}>
                    <MDTypography mt={1} variant="h6" color="white">
                      Manage Purchase Orders
                    </MDTypography>
                  </Grid>
                  <Grid item xs={1}>
                    {pagePermission.new ? (
                      <MDButton ml={5}
                        component={Link}
                        to="/manage-purchase-orders/add"
                        title="Add"
                        variant="outlined" color="white" iconOnly circular>
                        <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>add</Icon>
                      </MDButton>
                    ) : ''}
                  </Grid>
                </Grid>
                </MDBox>
                <MDBox>
                  <DataTable
                    entriesPerPage={{
                      "defaultValue": pageSize
                    }}
                    canSearch={true}
                    showTotalEntries={true}
                    table={{ columns, rows }}
                    isSorted={true}
                    totalData={totalData}
                    initialPageIndex={page - 1}
                    getData={getData}
                    pageCount={Math.ceil(totalData/pageSize)}
                    sortCol={sortColumn}
                    sortColValue={sortColumnValue}
                    searchVal={searchValue}
                    noEndBorder={false}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      <MDDialog
        open={open}
        onClose={handleClose}
        successAction={() => deletePurchaseOrder(selectedId)}
        dialogTitle={'Are you Sure?'}
        cancleButton={'No, Please!'}
        successButton={'Yes, Delete it!'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
      >
      <p>You will not be able to recover the deleted record!</p>
      </MDDialog>
      <MDDialog
        open={openPreview}
        onClose={handleClose}
        successAction={() => sendEmail(selectedId, (selectedEmail ? selectedEmail : ''))}
        dialogTitle={'Mail Preview'}
        cancleButton={'Cancel'}
        successButton={'Send'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <MDBox fullWidth>
          <MDBox sx={{ width: '100%', border: '1px solid lightgray', borderRadius: 5}}>
            <MDBox sx={{ paddingLeft: 2, paddingTop: 3 }}>
              <b>From: </b> {companyName}{process.env.REACT_APP_IS_DEMO === "true" ? ' Staging' : ''} &lt;email.of.aditya.prints@gmail.com&gt;
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <b>To: </b>&lt;{process.env.REACT_APP_IS_DEMO === "true" && !domainRegex.test(selectedEmail) ? 'adityaprints@outlook.com' : selectedEmail}&gt;
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <b>Cc: </b>&lt;adityaprints@outlook.com&gt;
              <hr sx={{ border: '1px solid lightgray', width: '100%' }} />
            </MDBox>
            <MDBox sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 2 }}>
              <b>Subject: </b>Purchase Order #{selectedId}
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <hr sx={{ border: '1px solid lightgray', width: '100%' }} />
            </MDBox>
            <MDBox sx={{ paddingLeft: 3, paddingTop: 2, paddingBottom: 2 }}>
              <h4>Hi {selectedSupplierName},</h4>
              <br />
              <p>Please find attached Purchase Order.</p>
              <br />
              <h5>Thanks & Regards,</h5>
              <h5>{companyName}</h5>
              <br />
              <MDButton
                  onClick={() => handlePrint(selectedId)}
                  variant="gradient" size="large" color="secondary" title="Click to print/download the attachment" iconOnly>
                    <Icon fontSize="medium">picture_as_pdf_icon </Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDDialog>
    </>
  );
}

export default PurchaseOrders;
