import HttpService from "./htttp.service";

class GrnService {

  getGrns = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'grn';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'grn?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + searchValue;
    }
    return await HttpService.get(fetchUrl);
  }

  addGrn = async(data) => {
    const fetchUrl = 'grn';
    return await HttpService.post(fetchUrl, data);
  }

  updateGrn = async (id, data) => {
    const fetchUrl = 'grn/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showGrn = async(id) => {
    const fetchUrl = 'grn/' + id;
    return await HttpService.get(fetchUrl);
  }
}

export default new GrnService();
