/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { useLocation, useNavigate } from "react-router-dom";
import authPermission from "utility/auth-permissions";
const loggedUserId = localStorage.getItem('user_id');

function DataTableBodyCell({ noBorder, borderTop, align, children, ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission(location.pathname);
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={props?.verticalRowPadding ? props?.verticalRowPadding : 1.5}
      px={props.header ? (props?.horizontalRowPadding) : 0}
      onDoubleClick={() => {
        if (pagePermission.edit && props?.id && props?.doubleClick && props?.header != 'actions' && props?.header != 'Actions') {
          if ((location.pathname == '/user-management' && loggedUserId != props?.id) || (location.pathname != '/user-management')) {
            navigate(location.pathname + '/edit?id=' + props?.id);
          }
        }

      }}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        borderTop: borderTop ? `${borderWidth[2]} solid ${light.main}` : "none",
      })}
      {...props}
    >
      <MDBox
        display="inline-block"
        width="auto"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  borderTop: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  borderTop: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
