/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// Custom styles for MDButton
import MDSwitchRoot from "components/MDSwitch/MDSwitchRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDSwitch = forwardRef(
  ({ label, checked, color, defaultChecked, disabled, size, labelPlacement, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    return (
      <FormControlLabel sx={{'marginRight': '0px', 'marginLeft': '0px'}} control={
        <MDSwitchRoot
          {...rest}
          ref={ref}
          checked={checked}
          color={color}
          size={size}
          disabled={disabled}
          ownerState={{ checked, color, defaultChecked, disabled, size }}
        >
        </MDSwitchRoot>
        } label={label ? label : ''}
        labelPlacement={labelPlacement} />
    );
  }
);

// Setting default values for the props of MDButton
MDSwitch.defaultProps = {
  color: "primary",
  defaultChecked: "",
  disabled: false,
  size: "small",
  labelPlacement: "start"
};

// Typechecking props for the MDButton
MDSwitch.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
  ]),
};

export default MDSwitch;
