import HttpService from "./htttp.service";

class SupplierItemService {

  getSupplierItems = async(page, pageSize, sortColumn, sortColumnValue, searchValue, supplierId = '') => {
    const fetchUrl = 'supplier_items?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue) + '&supplier_id=' + supplierId;
    return await HttpService.get(fetchUrl);
  }

  addSupplierItem = async(newSupplierItem) => {
    const fetchUrl = 'supplier_items';
    return await HttpService.post(fetchUrl, newSupplierItem);
  }

  updateSupplierItem = async (id, data) => {
    const fetchUrl = 'supplier_items/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updateSupplierItemStatus = async (id, data) => {
    const fetchUrl = 'supplier_items/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showSupplierItem = async(id) => {
    const fetchUrl = 'supplier_items/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteSupplierItem = async (id) => {
    const fetchUrl = 'supplier_items/' + id;
    return await HttpService.delete(fetchUrl);
  }

  getItemsBySupplierId = async(id, item, size, gsm) => {
    const fetchUrl = 'supplier_items_by_supplier_id/' + id + '?item=' + item + '&size=' + size + '&gsm=' + gsm;
    return await HttpService.get(fetchUrl);
  }

  getItemsByGroupName = async(groupName) => {
    const fetchUrl = 'get_items_by_group_name?group_name=' + groupName;
    return await HttpService.get(fetchUrl);
  }
}

export default new SupplierItemService();
