/* eslint-disable prefer-destructuring */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Select from '@mui/material/Select';
import { styled } from "@mui/material/styles";

export default styled(Select)(({ theme, ownerState }) => {
    const { palette, functions } = theme;
    const { disabled } = ownerState;
    const { grey, transparent } = palette;
  
    return {
        ".MuiSelect-select": {
            height: 'auto !important',
            minHeight: '3.3em !important',
            textOverflow: 'ellipsis !important',
            whiteSpace: 'nowrap !important',
            overflow: 'hidden !important',
            backgroundColor: disabled ? `${grey[200]} !important` : transparent.main,
        },
        ".MuiNativeSelect-select": {
            textOverflow: 'ellipsis !important',
            whiteSpace: 'nowrap !important',
            overflow: 'hidden !important',
            backgroundColor: disabled ? `${grey[200]} !important` : transparent.main,
            '& option': {
                fontSize: '17px',
            }
        }
    };
  });
