/*
  Number format in thousand separator or on to fix decimal digits
*/
const numberFormat = (number, isSeparator = true, decimalDigit = 2) => {
    return isSeparator ?
                new Intl.NumberFormat('en-IN', {
                    minimumFractionDigits: decimalDigit,
                    maximumFractionDigits: decimalDigit,
                }).format(number)
                : Number(number).toFixed(decimalDigit);
}

/*
  Regex validation by type
*/
const onlyNumbers = (value, type) => {
    // var value = event;
    let regex = '';
    switch (type) {
        case 'mediumInt':
            regex = /^[0-9]\d{0,7}$/;
            return value ? regex.test(value) : true;
        case 'smallInt':
            regex = /^[0-9]\d{0,4}$/;
            return value ? regex.test(value) : true;
        case 'decimal(10,2)':
            regex = /^(?=\d{1,8}(?:\.\d{0,2})?$)\d+(?:\.\d{0,2})?$/;
            return value ? regex.test(value) : true;
        case '-decimal(10,2)':
            regex = /^-?\d{1,8}\.?\d{0,2}|-$/;
            return value ? regex.test(value) : true;
        case 'decimal(6,2)':
            regex = /^\d{1,4}\.?\d{0,2}$/;
            return value ? regex.test(value) : true;
        case 'decimal(5,2)':
            regex = /^[-]?\d{0,3}\.?\d{0,2}$/;
            return value ? regex.test(value) : true;
        case 'pos_percentage':
            regex = /^((?:\.0{1,2})?|[1-4]?\d{1,2}(?:\.\d{1,2})?)$/;
            return value ? regex.test(value) : true;
        case 'percentage':
            regex = /^-?((?:\.0{1,2})?|(?:\d{1,3}\.)?|(?:\d{1,3}(?:\.\d{1,4})?)|-|)$/;
            return value ? regex.test(value) : true;
        case 'decimal(12,4)':
            regex = /^\d{1,8}\.?\d{0,4}$/;
            return value ? regex.test(value) : true;
        case 'decimal(16,4)':
          regex = /^\d{1,12}\.?\d{0,4}$/;
          return value ? regex.test(value) : true;
        case '-decimal(16,4)':
          regex = /^-?(?=.{1,16}$)\d{1,12}(?:\.(?=\d{1,4}$)\d{1,4})?|-$/;
          return value ? regex.test(value) : true;
        default:
            return false;
    }
}

/*
  Format datetime from utc to local time (12 hours format)
*/
const formatDateTime = (date) => {
  if (!date) return;

  date = date + " UTC";

  let d = new Date(date);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  let year = d.getFullYear();
  let hours = d.getHours();
  let min = d.getMinutes();
  let ap = "AM";

  if (d.getHours() > 12) {
    hours = d.getHours() - 12;
  }
  if (d.getHours() > 11) {
    ap = "PM";
  }

  if (month.toString().length < 2) {
    month = month.toString().padStart(2, "0");
  }
  if (day.toString().length < 2) {
    day = day.toString().padStart(2, "0");
  }

  if (hours < 10) {
    hours = hours.toString().padStart(2, "0");
  }

  if (min.toString().length < 2) {
    min = min.toString().padStart(2, "0");
  }

  return `${day}-${month}-${year} ${hours}:${min} ${ap}`;
};

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/*
  Find the time difference between two times
*/
const timeDifference = (startedAt, endedAt) => {
    var startedAt = new Date(startedAt);
    var endedAt = new Date(endedAt);
    var diffMs = (endedAt - startedAt); // milliseconds between startedAt & endedAt
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    diffHrs = (diffDays * 24) + diffHrs;
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + (diffHrs > 1 ? " hours, " : " hour, ") + diffMins + (diffMins > 1 ? " minutes" : " minute");
};

function amountToWords(amount) {
    const rupees = Math.floor(amount);
    const paise = Math.round((amount - rupees) * 100);
    let words = '';

    if (rupees > 0) {
      words += convertNumber(rupees);
    }

    return words.trim();

    function convertNumber(num) {
      const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
      const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
      const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
      const thousands = ['', 'Thousand', 'Lakh', 'Crore'];

      let words = '';
      let i = 0;

      while (num > 0) {
        const chunk = num % 1000;
        num = Math.floor(num / 1000);
        if (chunk > 0) {
          words = convertChunk(chunk) + (i > 0 ? ' ' + thousands[i] : '') + (words ? ' ' + words : '');
        }
        i++;
      }

      return words.trim();

      function convertChunk(num) {
        let words = '';
        if (num >= 100) {
          words += units[Math.floor(num / 100)] + ' Hundred';
          num %= 100;
        }
        if (num > 0) {
          if (words) words += ' ';
          if (num < 10) words += units[num];
          else if (num < 20) words += teens[num - 10];
          else {
            words += tens[Math.floor(num / 10)];
            if (num % 10 > 0) words += ' ' + units[num % 10];
          }
        }
        return words;
      }
    }
}

function numberToAlphabet(num) {
  if (num < 1 || num > 26) {
      throw new Error("Input must be between 1 and 26");
  }
  return String.fromCharCode(num + 64); // 65 is the ASCII code for 'A'
}


export {
    numberFormat,
    onlyNumbers,
    formatDateTime,
    formatDate,
    timeDifference,
    amountToWords,
    numberToAlphabet
};
