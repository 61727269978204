/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useState} from "react";

import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "services/auth-service";
import ParameterServices from "services/parameter-services";

// authentication context
export const AuthContext = createContext({
  isAuthenticated: false,
  isLoader: true,
  userPermission: {},
  userDetail: {},
  systemParameters: {},
  refreshParameter: () => {},
  login: () => {},
  register: () => {},
  logout: () => {},
});

const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [userPermission, setUserPermission] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [systemParameters, setSystemParameters] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");
  // console.log('token', token);
  // useEffect(() => {
  //   if (!token) return;

  //   setIsAuthenticated(true);
  //   setUserPermission(userPermission);
  //   navigate(location.pathname);
  // }, []);

  // useEffect(() => {
  //   console.log("60=>", isAuthenticated);
  //   console.log("61Token=>", token);
  //   if (!token) {
  //     navigate("/auth/login");
  //     return;
  //   }
  //   if (!isAuthenticated) {
  //     const fetchLogin = async() => {
  //       const user = await AuthService.getProfile();
  //       localStorage.setItem("user_id", user.data.id);
  //       setUserDetail(user.data);
  //       setUserPermission(user.permissions);
  //       setIsAuthenticated(true);
  //     }
  //     fetchLogin();
  //   }
  //   // setIsAuthenticated(isAuthenticated);
  //   // setUserPermission(userPermission);
  //   // navigate(location.pathname);
  // }, []);

  const refreshParameter = async() => {
    const parameter = await ParameterServices.getParameters();
    setSystemParameters(parameter);
  }

  const login = async(token) => {
    localStorage.setItem("token", token);
    const user = await AuthService.getProfile();
    localStorage.setItem("user_id", user.data.id);
    localStorage.setItem("role", user.data.role);
    setUserDetail(user.data);
    setUserPermission(user.permissions);
    refreshParameter();
    setIsLoader(false);
    setIsAuthenticated(true);
    navigate("/dashboard");
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    setIsAuthenticated(false);
    setUserPermission({});
    navigate("/auth/login");
  };

  const refresh = async() => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    const user = await AuthService.getProfile();
    localStorage.setItem("user_id", user.data.id);
    localStorage.setItem("role", user.data.role);
    setUserDetail(user.data);
    setUserPermission(user.permissions);
    refreshParameter();
    setIsLoader(false);
    setIsAuthenticated(true);
    navigate(location.pathname + location.search);
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, userPermission, userDetail, isLoader, systemParameters, refreshParameter, login, logout, refresh }}>
      {children}
    </AuthContext.Provider>
  );
};

export {
  AuthContextProvider,
};
