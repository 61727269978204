import { useNavigate, Navigate } from "react-router-dom";
import { useEffect } from "react";
import MDCircularProgress from 'components/MDCircularProgress';
import MDBox from 'components/MDBox';

const ProtectedRoute = ({ isAuthenticated, refresh, isLoader, redirectPath = "/auth/login", children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate("/auth/login");
      return;
    } else {
      if (!isAuthenticated) {
        refresh();
      }
    }
  }, []);

  if (isLoader) {
    <MDBox sx={{ display: 'flex' }}>
      <MDCircularProgress />
    </MDBox>
  } else {
    if (!isAuthenticated) {
      return <Navigate to={redirectPath} replace />;
    }
  }

  return children;
};

export default ProtectedRoute;
