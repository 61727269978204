import HttpService from "./htttp.service";

class PrintingMachinesService {

  getPrintingMachines = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'printing_machines';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'printing_machines?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addPrintingMachine = async(newPrintingMachine) => {
    const fetchUrl = 'printing_machines';
    return await HttpService.post(fetchUrl, newPrintingMachine);
  }

  updatePrintingMachine = async (id, data) => {
    const fetchUrl = 'printing_machines/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updatePrintingMachineStatus = async (id, data) => {
    const fetchUrl = 'printing_machines/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showPrintingMachine = async(id) => {
    const fetchUrl = 'printing_machines/' + id;
    return await HttpService.get(fetchUrl);
  }

  deletePrintingMachine = async (id) => {
    const fetchUrl = 'printing_machines/' + id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new PrintingMachinesService();
