/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import authPermission from "utility/auth-permissions";
import dayjs from 'dayjs';
import InvoiceService from "services/invoice-service";
import { numberFormat } from "utility/helpers";
import { invoicePDFTemplate } from "./invoice-pdf-template";
import { invoicePDFTemplatePrint } from "./invoice-pdf-template-print";
import InvoiceMaterialItemService from "services/invoice-material-item-service";
import InvoicePrintingItemService from "services/invoice-printing-item-service";
import WoPaymentService from "services/wo-payment-service";
import InvoiceStockItemService from "services/invoice-stock-item-service";

const stageType = ['DESIGN','PAPER','PRINTING','BINDING','EXTRA'];

export default function data(props) {
  const { hasPermission, systemConstant } = authPermission();
  const pagePermission = hasPermission('/manage-invoices');
  const woPermission = hasPermission('/manage-work-orders');
  const customerPermission = hasPermission('/manage-customers');
  const companyName = systemConstant('COMPANY_NAME');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.invoice;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [invoiceData, setInvoiceData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [maxId, setMaxId] = useState('');
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPreview(false);
  };

  const getInvoiceData = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    const response = await InvoiceService.getInvoices(page, pageSize, sortColumn, sortColumnValue, searchValue);
    setInvoiceData(response?.data?.data);
    setTotalData(response?.data?.total);
    setMaxId(response?.max_id);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    //getInvoiceData(page_no, page_size, sort_column, sort_column_value, search_value);
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveWorkOrder(preserveData);
    getInvoiceData(page_no, page_size, sortColumn, sortColumnValue, searchWord);
  };

  const deleteInvoice = async (id) => {
    try {
      const response = await InvoiceService.deleteInvoice(id);
      if (response) {
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Invoice'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Invoice'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Invoice'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    getInvoiceData(page_no, page_size, sort_column, sort_column_value, search_value);
    setSelectedId('');
    handleClose();
  };

  const getGstPrice = (gstPct, amount) => {
    return (Math.round(amount) * gstPct)/100;
  }

  const getTotal = (amount, sGst = 0, cGst =0) => {
    const gst = Number(sGst) + Number(cGst);
    const total = Number(amount) + Number((amount * gst)/100);
    return Math.round(total);
  }

  const getOutstanding = (amount, deductingAmount = 0) => {
    return Math.round(amount - deductingAmount);
  }

  const getStageTypeInputValue = (stage, data) => {
    switch (stage) {
      case 'DESIGN':
        return data.is_design;
      case 'PAPER':
        return data.is_paper;
      case 'PRINTING':
        return data.is_printing;
      case 'BINDING':
        return data.is_binding;
      case 'EXTRA':
        return data.is_extra;
      default:
      return false;
    }
  }

  const getPDFData = async(id) => {
    const promises = [
      InvoiceService.showInvoice(id),
      InvoiceMaterialItemService.getInvoiceMaterialItems(id),
      InvoicePrintingItemService.getInvoicePrintingItems(id),
      InvoiceStockItemService.getInvoiceStockItems(id),
    ];
    const response = await Promise.all(promises);
    const payment = await WoPaymentService.getWoPayments(response[0]?.work_order_id);
    let totalAll = 0;
    let totalRows = 2;
    const totalArray = [];
    const rowPresentArray = [];
    stageType.map((stage) => {
      let total = 0;
      rowPresentArray[stage] = false;
      response[1][stage]?.map((item, ind) => {
        if (getStageTypeInputValue(stage, response[0])) {
          total = Number(total) + Number(item.amount);
          rowPresentArray[stage] = true;
          totalRows = totalRows + 1;
        } else {
          if (item?.is_print) {
            total = Number(total) + Number(item.amount);
            rowPresentArray[stage] = true;
            totalRows = totalRows + 1;
          }
        }
      });
      totalArray[stage] = Number(total);
      totalAll = Number(totalAll) + Number(total);
    });

    response[2]?.map((eachRes, index) => {
      totalAll = Number(totalAll) + Number(eachRes.amount);
      totalRows = totalRows + 1;
    });

    response[3]?.map((eachRes, index) => {
      totalAll = Number(totalAll) + Number(eachRes.total);
      totalRows = totalRows + 1;
    });

    let receivedAmount = 0;
    payment?.map((eachRes) => {
      receivedAmount = Number(receivedAmount) + Number(eachRes.amount);
    });

    return {
      invoice: response[0],
      material: totalArray,
      materialItems: response[1],
      printing: response[2],
      stock: response[3],
      rowPresentArray: rowPresentArray,
      totalAmount: response[0]?.discount ? (Number(totalAll) - Number(response[0].discount)) : totalAll,
      receivedAmount: receivedAmount,
      lastRowHeight: response[0]?.instruction ? 400 : ((19 - totalRows) > 0 ? (19 - totalRows)* 25 : 0),
    };
  }

  const handlePrint = async(id, isDesign = false) => {
    const response = await getPDFData(id);
    const companyName = systemConstant('COMPANY_NAME');
    const companyAddress = systemConstant('COMPANY_ADDRESS');
    if(response){
        let pdfTemplate = invoicePDFTemplate(companyName, companyAddress, response.invoice, response.rowPresentArray, response.material, response.materialItems, response.printing, response.stock, Math.round(response.totalAmount), response.receivedAmount, response.lastRowHeight, getGstPrice, getOutstanding, getTotal);
        if (isDesign) {
          pdfTemplate = invoicePDFTemplatePrint(companyName, companyAddress, response.invoice, response.rowPresentArray, response.material, response.materialItems, response.printing, response.stock, Math.round(response.totalAmount), response.receivedAmount, response.lastRowHeight, getGstPrice, getOutstanding, getTotal);
        }

        const originalBody = document.body;
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`${pdfTemplate}`);
        printWindow.document.close();
        await new Promise(r => setTimeout(r, 2000));
        printWindow.print();
        printWindow.close();
        document.body = originalBody;
    }
  };

  const sendEmail = async(id, email) => {
    setOpenPreview(false);
    if (email) {
      openAlertSB({color: 'info', icon: 'info_icon', msg: 'Sending email is in process.Please wait.', title: 'Invoice'});
      try {
        const response = await InvoiceService.sendEmail(id);
        if (response) {
          openAlertSB({color: 'success', icon: 'check', msg: 'Email sent Successfully!', title: 'Invoice'});
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Invoice'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Invoice'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      }
    } else {
      openAlertSB({color: 'warning', icon: 'star', msg: 'Email is not added to this Customer', title: 'Invoice'});
    }
    getInvoiceData(page_no, page_size, sort_column, sort_column_value, search_value);
  }

  const rows = [];
  invoiceData?.map((eachInvoice, index) => {
      const col = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-invoices/edit?id=${eachInvoice.id}` : ''} variant="body2" color="text">
                {eachInvoice?.id}
              </MDTypography>,
        invoice_no: (<MDTypography display="block" variant="body2">
            {eachInvoice ? eachInvoice.invoice_no : ''}
            </MDTypography>
          ),
        work_order_id: <MDTypography color="info" component={Link} to={woPermission.edit ? `/manage-work-orders/edit?id=${eachInvoice.work_order_id}&ref=/manage-invoices` : ''} display="block" variant="h6">
            {eachInvoice?.work_order_id}
            </MDTypography>,
        release_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
          {eachInvoice ? dayjs(eachInvoice?.release_date).format('DD-MM-YYYY') : ''}
          </MDTypography>
          ),
        customer_name: (<MDTypography color="info" component={Link} to={customerPermission.edit ? `/manage-customers/edit?id=${eachInvoice?.customer_id}&ref=/manage-invoices` : ''} display="block" variant="h6">
          {eachInvoice?.customer_name}
          </MDTypography>
        ),
        job_type: (<MDTypography whiteSpace="nowrap" color="info" component={Link} to={pagePermission.edit ? `/manage-invoices/edit?id=${eachInvoice.id}` : ''} display="block" variant="h6">
          {eachInvoice?.job_type}
          </MDTypography>
        ),
        total: (<MDTypography display="block" variant="h6" textAlign="right">
          {eachInvoice ? numberFormat(eachInvoice?.total) : ''}
          </MDTypography>
        ),
        created: (<MDTypography display="block" variant="body2">
            <MDTypography display="block" variant="body2">
                {eachInvoice?.first_name}
            </MDTypography>
            <MDTypography display="block" variant="body2" whiteSpace="nowrap" sx={{
              "&.MuiTypography-root": {
                fontSize: "11px",
              }
            }}>
                {eachInvoice? dayjs(eachInvoice?.created_at).format('DD-MM-YYYY hh:mm A') : ''}
            </MDTypography>
          </MDTypography>
        ),
        action: (
              <MDBox display="flex">
                <MDTypography mr={1} mt={1} component={Link} onClick={() => handlePrint(eachInvoice.id, false)} title="Print Blank Invoice" display="block" variant="caption" color="secondary" fontWeight="medium">
                    <Icon fontSize="small">print_icon</Icon>
                </MDTypography>
                <MDTypography mr={1} mt={1} component={Link} onClick={() => handlePrint(eachInvoice.id, true)} title="Print Design Invoice" display="block" variant="caption" color="primary" fontWeight="medium">
                    <Icon fontSize="small">picture_as_pdf_icon</Icon>
                </MDTypography>
                <MDTypography mr={1} mt={1} component={Link} onClick={() => {setOpenPreview(true);setSelectedId(eachInvoice.id);setSelectedInvoice(eachInvoice.invoice_no);setSelectedEmail(eachInvoice?.customer?.email);setSelectedCustomerName(eachInvoice?.customer_name)}} color={eachInvoice?.is_email_sent ? 'success' : 'warning'} display="block" variant="caption" title={eachInvoice?.is_email_sent ? 'Email is sent' : 'Send Email'} fontWeight="medium">
                    <Icon fontSize="small">forward_to_inbox_icon</Icon>
                </MDTypography>
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-invoices/edit?id=${eachInvoice.id}`} title="Edit" display="block" variant="caption" color="info" fontWeight="medium">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
                ) : ''}
                {(maxId == eachInvoice?.id) && pagePermission.remove ? (
                  <MDTypography mt={1} component={Link} onClick={() => handleClickOpen(eachInvoice.id)} title="Remove" display="block" variant="caption" color="error" fontWeight="medium">
                    <Icon fontSize="small">delete</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            )
      };
      rows[index] = col;
  });

  return {
    columns: [
      // { Header: "Inv#", accessor: "id", width: "7%", align: "left" },
      { Header: "GST Invoice No#", accessor: "invoice_no", width: "10%", align: "left" },
      { Header: "WO#", accessor: "work_order_id", width: "5%", align: "left" },
      { Header: "Date", accessor: "release_date", width: "7%", align: "left" },
      { Header: "Party", accessor: "customer_name", width: "18%", align: "left" },
      { Header: "Job Type", accessor: "job_type", width: "35%", align: "left" },
      { Header: "Net Amount", accessor: "total", width: "9%", align: "right" },
      { Header: "Created By & At", accessor: "created", width: "9%", align: "left" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "8%", align: "left" }] : [],
    ],
    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deleteInvoice: deleteInvoice,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
    companyName: companyName,
    selectedEmail: selectedEmail,
    selectedCustomerName: selectedCustomerName,
    openPreview: openPreview,
    sendEmail: sendEmail,
    handlePrint: handlePrint,
    selectedInvoice: selectedInvoice,
  };
}
