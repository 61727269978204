import HttpService from "./htttp.service";

class InvoiceMaterialItemService {

  getInvoiceMaterialItems = async(id) => {
    let fetchUrl = 'inv_material_items';
    if (id) {
      fetchUrl = 'inv_material_items?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addInvoiceMaterialItem = async(data) => {
    const fetchUrl = 'inv_material_items';
    return await HttpService.post(fetchUrl, {invoice_id: data.invoice_id, material: data.material});
  }

  deleteAllInvoiceMaterialItem = async (invoice_id) => {
    const fetchUrl = 'inv_material_items?invoice_id=' + invoice_id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new InvoiceMaterialItemService();
