/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import ReportService from "services/report-service";
import { Link } from "react-router-dom";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const woPermission = hasPermission('/manage-work-orders');
    const [jobCostSheetSummary, setJobCostSheetSummary] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [grandSale, setGrandSale] = useState(0);
    const [grandExpense, setGrandExpense] = useState(0);
    const [grandElectricity, setGrandElectricity] = useState(0);
    const [grandLabor, setGrandLabor] = useState(0);
    const [grandMisc, setGrandMisc] = useState(0);
    const [grandProfit, setGrandProfit] = useState(0);
    const [startDate, setStartDate] = useState((dayjs(new Date()).startOf('month')));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [filter, setFilter] = useState('');
    const { cacheItems, setCacheItems } = useCache();
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getJobCostSheetSummary = async(startDate = '', endDate = '') => {
        const data = await ReportService.getJobCostSheetSummary(startDate, endDate);
        setJobCostSheetSummary(Object.values(data.job_cost_sheet_summary));
        setGrandSale(data.grand_sale);
        setGrandExpense(data.grand_expense);
        setGrandElectricity(data.grand_electricity);
        setGrandLabor(data.grand_labor);
        setGrandMisc(data.grand_misc);
        setGrandProfit(data.grand_profit);
    }

    useEffect(() => {
        getJobCostSheetSummary(dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : dayjs(new Date()).startOf('month').format('YYYY-MM-DD'), dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : dayjs(new Date()).format('YYYY-MM-DD'));
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : dayjs(new Date()).startOf('month').format('YYYY-MM-DD');
        const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : (startDate == '' ? dayjs(new Date()).format('YYYY-MM-DD') : '');
        getJobCostSheetSummary(sDate, eDate);
        setSearchLoading(false);
        if (startDate == '') {
            setStartDate(dayjs(new Date()).startOf('month'));
            setEndDate(dayjs(new Date()));
        }
    }

    const clearFilters = () => {
        setStartDate('');
        setEndDate('');
        setFilter(null);
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data, grandSale, grandExpense, grandElectricity, grandLabor, grandMisc, grandProfit) => {
        setExcelLoading(true);
        const fileName = 'job_cost_sheet_summary_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Job Cost Sheet Summary']);
        //Merge cells for supplier row
        worksheet.mergeCells(`A1:G1`);
        // Add headers
        const headerRow = worksheet.addRow(['Date', 'Total Sales', 'Raw', 'Electricity', 'Labour', 'Miscellaneous', 'Profit']);
        // Set the width for each column
        worksheet.columns = [
            { width: 20 }, 
            { width: 20 },
            { width: 20 },
            { width: 20 }, 
            { width: 20 },
            { width: 20 },
            { width: 20 },
        ];
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 1; i < 8; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
            //Add border
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        // Add data
        data?.forEach((row, index) => {
            const cellRow = worksheet.addRow([row?.date, Number(row?.total_sales), Number(row?.total_expense), Number(row?.electricity), Number(row?.labor), Number(row?.misc), Number(row?.profit)]);
            let rowColor = index % 2 === 0 ? 'e0f8f7' : 'effbfb';
            for (var i = 1; i < 8; i++) {
                cellRow.getCell(i).fill = {
                    type: 'pattern',
                    name: 'sans-serif',
                    pattern: 'solid',
                    fgColor: { argb: rowColor },
                };
                // Add border to the row
                cellRow.getCell(i).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
                if (i > 1) {
                    cellRow.getCell(i).numFmt = '#,##0.00';
                }
            }
        });

        //Grand row
        const grandRow = worksheet.addRow(['Total', Number(grandSale), Number(grandExpense), Number(grandElectricity), Number(grandLabor), Number(grandMisc), Number(grandProfit)]);
        for (var i = 1; i < 8; i++) {
            grandRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            if (i > 1) {
                grandRow.getCell(i).numFmt = '#,##0.00';
            }
        }
        grandRow.font = { bold: true, name: 'sans-serif' };
      
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(jobCostSheetSummary){
            const pdfTemplate = PDFTemplate(jobCostSheetSummary, grandSale, grandExpense, grandElectricity, grandLabor, grandMisc, grandProfit, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`${pdfTemplate}`);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Job Cost Sheet Summary Report'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Job Cost Sheet Summary'}
                                </MDTypography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <MDBox>
                                    
                                </MDBox>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                     <Grid item lg={6} xs={12} px={1} md={8}>
                                        <MDBox mb={1}>
                                            <MDDateRangePicker
                                                label="Start Date"
                                                name="start_date"
                                                value={startDate ? startDate : null}
                                                format="DD-MM-YYYY"
                                                onChange={(newValue) => {
                                                    setDate(newValue, 'start_date');
                                                }}
                                                endLabel="End Date"
                                                endName="end_date"
                                                endValue={endDate ? endDate : null}
                                                endOnChange={(newValue) => {
                                                    setDate(newValue, 'end_date');
                                                }}
                                                onFilterChange={onFilterChange}
                                                filter={filter}
                                                filterSx={{width: 150}}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {jobCostSheetSummary.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(jobCostSheetSummary,  grandSale, grandExpense, grandElectricity, grandLabor, grandMisc, grandProfit)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '15%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Date</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '16%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Total Sales</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '14%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Raw</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '14%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Electricity</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '14%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Labour</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '14%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Miscellaneous</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '14%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Profit</TableCell>
                                        </TableRow>
                                        {jobCostSheetSummary?.map((item, index) => {
                                            let rowColor = index % 2 === 0 ? '#e0f8f7' : '#effbfb';
                                            return (
                                                <TableRow sx={{ backgroundColor: rowColor }}>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{dayjs(item?.date).format('DD-MMM-YYYY')}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.total_sales)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.total_expense)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.electricity)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.labor)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.misc)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.profit)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        {jobCostSheetSummary.length == 0 ? 
                                            <TableRow>
                                                <TableCell colSpan={7} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow>
                                            :
                                            <TableRow>
                                                <TableCell sx={{fontWeight: 'bold', paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>Total</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(grandSale)}</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(grandExpense)}</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(grandElectricity)}</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(grandLabor)}</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(grandMisc)}</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(grandProfit)}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
