import dayjs from 'dayjs';

export const PDFTemplate = (jobCostSheet, grandCost, grandExpense, grandTotal, numberFormat) => {
    return  `<html>
  <head>
    <title>job_cost_sheet_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Job Cost Sheet</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Sr No.</th>
                <th style="background-color: #dddddd; width: 29%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Item</th>
                <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Machine</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Size</th>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">GSM</th>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Rate</th>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Qty.</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Amount</th>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Markup %</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-bottom: 1px solid #000;">Amount</th>
            </tr>
            ${jobCostSheet?.map((eachItem, ind) => {
                let rowColor = '#effbfb';
                return `
                    <tr style="background-color: #f8ece0;">
                        <td colspan="10" style="border-bottom: 1px solid #000;"><b>Work Order: </b>${eachItem?.id}  &nbsp;&nbsp;&nbsp;&nbsp;<b>Party: </b>${eachItem?.customer_name}&nbsp;&nbsp;&nbsp;&nbsp;<b>Job Type: </b>${eachItem?.job_type}</td>
                    </tr>
                    ${eachItem?.items?.map((item, index) => {
                        rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                        const color = item?.is_expense ? 'red' : '';
                        return `
                            <tr style="background-color: ${rowColor}">
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${index + 1}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.item}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.machine}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.size}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.gsm ? item?.gsm : ''}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.rate}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.quantity}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right; color: ${color};">${numberFormat(item?.amount)}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.markup_pct * 1}</td>
                                <td style="font-size: 0.775rem; border-bottom: 1px solid #000; text-align: right; color: ${color};">${numberFormat(item?.total)}</td>
                            </tr>
                        `
                    }). join('')}
                    <tr>
                        <td colspan="9" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right; border-bottom: ${eachItem?.total_expense > 0 ? '' : '1px solid #000'};"><b>Total Cost:</b></td>
                        <td style="font-size: 0.875rem; text-align: right; border-bottom: ${eachItem?.total_expense > 0 ? '' : '1px solid #000'};">${numberFormat(eachItem?.total_cost)}</td>
                    </tr>
                    ${eachItem?.total_expense > 0 ? 
                    `   
                        <tr>
                            <td colspan="9" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Total Expense:</b></td>
                            <td style="font-size: 0.875rem; text-align: right; color: red;">${numberFormat(eachItem?.total_expense)}</td>
                        </tr>
                        <tr>
                            <td colspan="9" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right; border-bottom: ${jobCostSheet.length != (ind + 1) ? '1px solid #000' : ''};"><b>Total:</b></td>
                            <td style="font-size: 0.875rem; text-align: right; border-bottom: ${jobCostSheet.length != (ind + 1) ? '1px solid #000' : ''};">${numberFormat(eachItem?.total)}</td>
                        </tr>
                    ` : ``}
                `
            }).join('')}
            <tr>
                <td colspan="10" style="font-size: 0.875rem; border-bottom: 2px solid #000;"></td>
            </tr>
            <tr>
                <td colspan="9" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Grand Total Cost:</b></td>
                <td style="font-size: 0.875rem; text-align: right;">${numberFormat(grandCost)}</td>
            </tr> 
            <tr>
                <td colspan="9" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Grand Total Expense:</b></td>
                <td style="font-size: 0.875rem; text-align: right; color: red;">${numberFormat(grandExpense)}</td>
            </tr> 
            <tr>
                <td colspan="9" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Grand Total:</b></td>
                <td style="font-size: 0.875rem; text-align: right;">${numberFormat(grandTotal)}</td>
            </tr>        
        </table>
      </center>
    </div>
  </body>
</html>`

}
