/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportService from "services/report-service";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
import ItemService from "services/item-service";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const grnPermission = hasPermission('/manage-grn');
    const woPermission = hasPermission('/manage-work-orders');
    const [itemsDropdown, setItemsDropdown] = useState([]);
    const [itemReceiptVsIssue, setItemReceiptVsIssue] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const { cacheItems, setCacheItems } = useCache();
    const [itemId, setItemId] = useState('');
    const [startDate, setStartDate] = useState((dayjs(new Date()).startOf('month')));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [filter, setFilter] = useState('');
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getItemReceiptVsIssue = async(itemId = '', startDate = '', endDate = '') => {
        const data = await ReportService.getItemReceiptVsIssue(itemId, startDate, endDate);
        const itemsVsIssue = Object.values(data);
        const sortedItemsVsIssue = itemsVsIssue.map(item => ({
            ...item,
            items: item.items.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
            }),
        }));
        setItemReceiptVsIssue(sortedItemsVsIssue);
    }

    useEffect(() => {
        const getItemsData = async() => {
            let response;
            if (cacheItems.hasOwnProperty('items')) {
                response = cacheItems['items'];
            } else {
                response = await ItemService.getItems(); // Fetch items from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    items: response,
                }));
            }

              if (response) {
                var itemsArray = [];
                response?.map((eachItem, index) => {
                  if(eachItem?.active) {
                      itemsArray[index] = {
                          key: eachItem?.id,
                          value: eachItem?.description,
                          disabled: eachItem?.active ? false : true,
                      }
                  }
                });
                setItemsDropdown(itemsArray);
              }
          }
          getItemsData();
        getItemReceiptVsIssue(itemId, dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '', dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '');
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
        const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
        getItemReceiptVsIssue(itemId, sDate, eDate);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setItemId('');
        setStartDate('');
        setEndDate('');
        setFilter(null);
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data) => {
        setExcelLoading(true);
        const fileName = 'item_receipt_vs_issue_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Item Receipt Vs Issue']);
        //Merge cells for supplier row
        // worksheet.mergeCells(`A1:E1`);
        // Add headers
        const headerRow = worksheet.addRow(['', 'Document', 'Doc No', 'Date', 'Job Type','Quantity', '']);
        // Set the width for each column
        worksheet.columns = [
            { width: 40 },
            { width: 15 }, 
            { width: 15 },
            { width: 20 },
            { width: 45},
            { width: 15},
            { width: 40 },
            { width: 40 },
        ];
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i=1; i<8; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
            // Add border
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        let k = 3;
        data.forEach((purchase, index) => {
            const itemRow = 'Item : ' + purchase?.item_name;
            const openingStock = 'Opening Stock ' + (startDate ? 'as On ' + dayjs(startDate).format('DD-MMM-YYYY') : '') + ': ' + purchase?.opening_stock;
            const closingStock = 'Closing Stock as On ' + (endDate ? dayjs(endDate).format('DD-MMM-YYYY') : dayjs(new Date).format('DD-MMM-YYYY')) + ': ' + purchase?.closing_stock;
            // const cellRowItem = worksheet.addRow([itemRow, '', '', openingStock, '']);
            // for (var i=1; i<6; i++) {
            //     cellRowItem.getCell(i).fill = {
            //         type: 'pattern',
            //         name: 'sans-serif',
            //         pattern: 'solid',
            //         fgColor: { argb: 'f8ece0' },
            //     };
            // }    
            // cellRowItem.border = {
            //     top: { style: 'thin' },
            //     left: { style: 'thin' },
            //     bottom: { style: 'thin' },
            //     right: { style: 'thin' }
            // };
            // cellRowItem.getCell(4).alignment = { horizontal: 'right' };
            
            // // Merge cells for supplier row
            // worksheet.mergeCells(`A${k}:C${k}`);
            // worksheet.mergeCells(`D${k}:E${k}`);
            // k += 1;
            let rowColor = 'e0f8f7';
            // Add data
            purchase?.items?.forEach((row, index) => {
                const cellRow = worksheet.addRow([(index == 0 ? itemRow : ''), row?.document, row?.doc_no, row?.date, row?.job_type, row?.quantity, (index == 0 ? openingStock : (purchase?.items.length == index + 1 ? closingStock : '')), (purchase?.items.length == 1 ? closingStock : '')]);
                    rowColor = (rowColor == 'effbfb') ? 'e0f8f7' : 'effbfb';
                    if (index == 0) {
                        cellRow.getCell(1).fill = {
                            type: 'pattern',
                            name: 'sans-serif',
                            pattern: 'solid',
                            fgColor: { argb: 'f8ece0' },
                        };
                        cellRow.getCell(7).fill = {
                            type: 'pattern',
                            name: 'sans-serif',
                            pattern: 'solid',
                            fgColor: { argb: 'f8ece0' },
                        };
                    }
                    for (var i=2; i<7; i++) {
                        cellRow.getCell(i).fill = {
                            type: 'pattern',
                            name: 'sans-serif',
                            pattern: 'solid',
                            fgColor: { argb: rowColor },
                        };
                    }
                    cellRow.getCell(2).font = { bold: true, name: 'sans-serif' };
                    cellRow.getCell(7).font = { bold: true, name: 'sans-serif', color: {argb: (index == 0 ? 'FFFFA500' : 'FF00FF00')} };
                    cellRow.getCell(8).font = { bold: true, name: 'sans-serif', color: {argb: 'FF00FF00'}};
                    for (var j=1; j<9; j++) {
                        // Add border to the row
                        cellRow.getCell(j).border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    }
                    k += 1;
            });
            // const blankRow = worksheet.addRow([]);
            // k += 1;
            // const closingStock = 'Closing Stock as On ' + (endDate ? dayjs(endDate).format('DD-MMM-YYYY') : dayjs(new Date).format('DD-MMM-YYYY')) + ': ' + purchase?.closing_stock;
            // const cellClosingStock = worksheet.addRow([closingStock]);
            // cellClosingStock.border = {
            //     top: { style: 'thin' },
            //     left: { style: 'thin' },
            //     bottom: { style: 'thin' },
            //     right: { style: 'thin' }
            // };
            // cellClosingStock.font = { bold: true, name: 'sans-serif' };
            // cellClosingStock.getCell(1).alignment = { horizontal: 'right' };
            // // Merge cells for closing stock row
            // worksheet.mergeCells(`A${k}:E${k}`);
            // k += 1;
        });
      
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(itemReceiptVsIssue){
            const pdfTemplate = PDFTemplate(itemReceiptVsIssue, startDate, endDate, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`${pdfTemplate}`);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Item Receipt Vs Issue Report'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Item Receipt Vs Issue'}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                    <Grid item xs={12} lg={minWidthD ? 4 : 3} px={1} md={6}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Item"
                                                fullWidth
                                                name="item_id"
                                                value={itemId}
                                                onChange={(e) => setItemId(e.target.value != null ? e.target.value : '')}
                                                options={itemsDropdown}
                                            />
                                        </MDBox>
                                     </Grid>
                                     <Grid item lg={6} xs={12} px={1} md={8}>
                                        <MDBox mb={1}>
                                            <MDDateRangePicker
                                                label="Start Date"
                                                name="start_date"
                                                value={startDate ? startDate : null}
                                                format="DD-MM-YYYY"
                                                onChange={(newValue) => {
                                                    setDate(newValue, 'start_date');
                                                }}
                                                endLabel="End Date"
                                                endName="end_date"
                                                endValue={endDate ? endDate : null}
                                                endOnChange={(newValue) => {
                                                    setDate(newValue, 'end_date');
                                                }}
                                                onFilterChange={onFilterChange}
                                                filter={filter}
                                                filterSx={{width: minWidthD? 150 : 80}}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {itemReceiptVsIssue.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(itemReceiptVsIssue)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                    <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '15%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Document</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Doc No</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Date</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '45%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Job Type</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Quantity</TableCell>
                                        </TableRow>
                                        {itemReceiptVsIssue?.map((eachItem) => {
                                            let rowColor = '#e0f8f7';
                                            return (
                                                <>
                                                    <TableRow sx={{ backgroundColor: '#f8ece0' }}>
                                                        <TableCell colSpan={3} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Item :</b> {eachItem?.item_name}</TableCell>
                                                        <TableCell colSpan={2} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', borderRight: '1px solid #ccc', textAlign: 'right', color: 'orange'}}><b>Opening Stock {startDate ? 'as On ' + dayjs(startDate).format('DD-MMM-YYYY') : ''}: {eachItem?.opening_stock}</b></TableCell>
                                                    </TableRow>
                                                    {eachItem?.items?.map((item) => {
                                                        rowColor = (rowColor == '#e0f8f7') ? '#effbfb' : '#e0f8f7';
                                                        return (
                                                            <TableRow sx={{ backgroundColor: rowColor }}>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}><b>{item?.document}</b></TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right', color: 'info.main'}} component={Link} to={item?.document == 'WO' ? (woPermission.edit ? `/manage-work-orders/edit?id=${item?.doc_no}&tab=3&close_window=1` : ``) : (grnPermission.edit ? `/manage-grn/edit?id=${item?.doc_no}&tab=1&close_window=1` : ``)} target={'_blank'}>{item?.doc_no}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.date}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.job_type}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.quantity}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                    <TableRow>
                                                        <TableCell colSpan={5} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', borderRight: '1px solid #ccc', textAlign: 'right', color: 'green' }}><b>Closing Stock as On {endDate ? dayjs(endDate).format('DD-MMM-YYYY') : dayjs(new Date).format('DD-MMM-YYYY')}: {eachItem?.closing_stock}</b></TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}
                                        {itemReceiptVsIssue.length == 0 && ( 
                                            <TableRow>
                                                <TableCell colSpan={5} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;