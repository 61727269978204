/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDButton
import MDRadioRoot from "components/MDRadio/MDRadioRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDRadio = forwardRef(
  ({ label, name, value, options, onChange, disabled, required, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const selectOption = options ? options : {};

    return (
        <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label"
              sx={{
                '&.MuiFormLabel-root': {
                  fontSize: "13px"
                }
              }}
            >{label}</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                sx={{
                  '& .MuiSvgIcon-root': {
                    height: 15,
                    width: 15,
                  },
                }}
                {...rest}
            >
                {selectOption?.map((eachOption) => (
                    <FormControlLabel value={eachOption?.value} control={<MDRadioRoot />} label={eachOption?.label} disabled={disabled} fontWeight="0px !important"/>
                ))}
            </RadioGroup>
        </FormControl>
    );
  }
);

// Setting default values for the props of MDButton
MDRadio.defaultProps = {
  required: false,
};

export default MDRadio;
