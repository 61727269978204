import dayjs from 'dayjs';

export const PDFTemplate = (customerMonthlySale, totalData, numberFormat) => {
    return  `<html>
  <head>
    <title>customer_monthly_sales_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Customer Monthly Sales</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Party</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Apr</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">May</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Jun</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Jul</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Aug</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Sep</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Oct</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Nov</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Dec</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Jan</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Feb</th>
                <th style="background-color: #dddddd; width: 6.2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Mar</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-bottom: 1px solid #000;">Total</th>
            </tr>
            ${customerMonthlySale?.map((item, index) => {
                let rowColor = index % 2 === 0 ? '#e0f8f7' : '#effbfb';
                return `
                    <tr style="background-color: ${rowColor}">
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.customer}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.apr)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.may)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.jun)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.jul)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.aug)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.sep)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.oct)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.nov)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.dec)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.jan)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.feb)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.mar)}</td>
                        <td style="font-size: 0.775rem; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.total)}</td>
                    </tr>
                `
            }).join('')} 
            <tr>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;">Total</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.apr)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.may)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.jun)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.jul)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.aug)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.sep)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.oct)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.nov)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.dec)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.jan)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.feb)}</th>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(totalData?.mar)}</th>
                <th style="font-size: 0.775rem;text-align: right !important;">${numberFormat(totalData?.total)}</th>
            </tr>       
        </table>
      </center>
    </div>
  </body>
</html>`

}
