import HttpService from "./htttp.service";

class CustomerPaymentService {

  getCustomerPayments = async(id, page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'customer_payments';
    if (id && page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'customer_payments?id=' + id + '&page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addCustomerPayment = async(data) => {
    const fetchUrl = 'customer_payments';
    return await HttpService.post(fetchUrl, data);
  }

  getCurrentBalance = async(id) => {
    const fetchUrl = 'customer_payment_balance/' + id;
    return await HttpService.get(fetchUrl);
  };

  getOutStandingAmount = async(id) => {
    const fetchUrl = 'customer_outstanding_amount/' + id;
    return await HttpService.get(fetchUrl);
  };

  getCustomerOutstandingWo = async(id) => {
    const fetchUrl = 'customer_outstanding_wo/' + id;
    return await HttpService.get(fetchUrl);
  };
}

export default new CustomerPaymentService();
