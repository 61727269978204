import { forwardRef } from "react";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import MDTimePickerRoot from "./MDTimePickerRoot";

const MDTimePicker = forwardRef(
    ({ label,
       name,
       value,
       onChange,
       slotProps,
       disabled,
       sx,
       ...rest }, ref) => {
      const [controller] = useMaterialUIController();
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']} sx={{marginTop: '-0.500rem'}}>
                <MDTimePickerRoot
                {...rest}
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                slotProps={slotProps}
                disabled={disabled}
                ownerState={{ disabled }}
                sx={sx}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
    }
);

// Setting default values for the props of MDTimePicker
MDTimePicker.defaultProps = {
    slotProps: { textField: {
        fullWidth: true,
        error: false,
        required: false,
      },
      field: {
        clearable: true
      },
      textField: { size: 'small' }
    },
    sx: {width: "100%", height: "40px"},
    disabled: false,
  };

export default MDTimePicker;
