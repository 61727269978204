/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation, useSearchParams } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import authPermission from "utility/auth-permissions";
import WorkOrderService from "services/work-order-service";
import WoStockItemService from "services/wo-stock-item-service";
import ItemService from "services/item-service";
import dayjs from 'dayjs';
import ColorServices from "services/color-services";
import { jobCardPDFTemplate } from "./job-card-pdf-template";
import WoMaterialItemService from "services/wo-material-item-service";
import WoPrintingItemService from "services/wo-printing-item-service";
import WoOtherItemService from "services/wo-other-item-service";
import CustomersService from "services/customer-service";
import { useCache } from "context";

const stageType = ['DESIGN','PAPER','PRINTING','BINDING','EXTRA'];

export default function data(props) {
  const { hasPermission, systemConstant } = authPermission();
  const pagePermission = hasPermission('/manage-work-orders');
  const customerPermission = hasPermission('/manage-customers');
  const [searchParams] = useSearchParams();
  const statusIdParam = searchParams.get('status_param') ? searchParams.get('status_param').split(',').map(Number) : '';
  const preserveTable = useContext(PreserveTable);
  const {
    page_no,
    page_size,
    sort_column,
    sort_column_value,
    search_value,
    wo_id_filter,
    date_filter,
    customer_id_filter,
    job_type_filter,
    status_id_filter,
    show_filter } = preserveTable?.workOrder;
  const noColorPrintingMachineId = systemConstant('NO_COLOR_PRINTING_MACHINE_ID');
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [workOrderData, setWorkOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [colorData, setColorData] = useState([]);
  const { cacheItems, setCacheItems } = useCache();
  let location = useLocation();
  const [woIdsOptions, setWoIdsOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [filterData, setFilterData] = useState({
    wo_id_filter: wo_id_filter,
    date_filter: date_filter,
    customer_id_filter: customer_id_filter,
    job_type_filter: job_type_filter,
    status_id_filter: status_id_filter,
  });
  const [filterLoading, setFilterLoading] = useState({
    search_loading: false,
    clear_loading: false,
  });
  const draftStatus = systemConstant('DRAFT_STATUS');
  const newStatus = systemConstant('NEW_STATUS');
  const inProgressStatus = systemConstant('IN_PROGRESS_STATUS');
  const completedStatus = systemConstant('COMPLETED_STATUS');
  const billedStatus = systemConstant('BILLED_STATUS');
  const approvedStatus = systemConstant('APPROVED_STATUS');
  const cancelStatus = systemConstant('CANCELLED_STATUS');

  let statusOptions = [
    {
      key: draftStatus,
      value: 'Draft',
      disabled: false,
    },
    {
      key: newStatus,
      value: 'New',
      disabled: false,
    },
    {
      key: inProgressStatus,
      value: 'In Progress',
      disabled: false,
    },
    {
      key: completedStatus,
      value: 'Completed',
      disabled: false,
    },
    {
      key: billedStatus,
      value: 'Billed',
      disabled: false,
    },
    {
      key: approvedStatus,
      value: 'Approved',
      disabled: false,
    },
    {
      key: cancelStatus,
      value: 'Cancelled',
      disabled: false,
    }
  ];

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getWorkOrderData = async (page, pageSize, sortColumn, sortColumnValue, searchValue, clearSearch=true, statuses = []) => {
    let response = '';
    if (clearSearch) {
      response = await WorkOrderService.getWorkOrders(page, pageSize, sortColumn, sortColumnValue, searchValue, statuses, '', '', '', '', '');
    } else {
      response = await WorkOrderService.getWorkOrders(page, pageSize, sortColumn, sortColumnValue, searchValue, statuses, filterData?.wo_id_filter, filterData?.date_filter, filterData?.customer_id_filter, filterData?.job_type_filter, filterData?.status_id_filter);
    }
    setWorkOrderData(response?.data);
    setTotalData(response?.total);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }

    if (show_filter) {
      getAllWoData();
      getAllCustomerData();
    }

    //getWorkOrderData(page_no, page_size, sort_column, sort_column_value, search_value, false, statusIdParam);
    // getAllWoData();
    // getAllCustomerData();

    // const getColor = async() => {
    //   const response = await ColorServices.getColors();
    //   if (response) {
    //     setColorData(response);
    //   }
    // }
    // getColor();
  }, []);

  const getAllWoData = async() => {
    try {
      const response = await WorkOrderService.getWorkOrders('', '', '', '', '', '');
      var woIdsArray = [];
      if (response) {
        response?.map((eachWo, index) => {
          woIdsArray.push({
            key: eachWo?.id,
            value: eachWo?.id + '',
          });
        });
      }
      setWoIdsOptions(woIdsArray);
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  }

  const getAllCustomerData = async() => {
    try {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('customers')) {
        response = cacheItems['customers'];
      } else {
        response = await CustomersService.getCustomers(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            customers: response,
        }));
      }

      var customerArray = [];
      if (response) {
        response?.map((eachCustomer, index) => {
          customerArray.push({
            key: eachCustomer?.id,
            value: eachCustomer.name,
          });
        });
      }
      setCustomerOptions(customerArray);
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  }

  const woSearchHandler = async (e, clearSearch) => {
    e.preventDefault();
    if (clearSearch) {
      setFilterData({
        wo_id_filter: '',
        date_filter: '',
        customer_id_filter: '',
        job_type_filter: '',
        status_id_filter: '',
      });
      setFilterLoading({
        ...filterLoading,
        clear_loading: true,
      });
      var preserveData = {
        "page_no": page_no,
        "page_size": page_size,
        "sort_column": sort_column,
        "sort_column_value": sort_column_value,
        "search_value": search_value,
        "wo_id_filter": '',
        "date_filter": '',
        "customer_id_filter": '',
        "job_type_filter": '',
        "status_id_filter": '',
        "show_filter": true,
      };
      preserveTable.setPreserveWorkOrder(preserveData);
    } else {
      setFilterLoading({
        ...filterLoading,
        search_loading: true,
      });
      var preserveData = {
        "page_no": page_no,
        "page_size": page_size,
        "sort_column": sort_column,
        "sort_column_value": sort_column_value,
        "search_value": search_value,
        "wo_id_filter": filterData?.wo_id_filter,
        "date_filter": filterData?.date_filter,
        "customer_id_filter": filterData?.customer_id_filter,
        "job_type_filter": filterData?.job_type_filter,
        "status_id_filter": filterData?.status_id_filter,
        "show_filter": true,
      };
      preserveTable.setPreserveWorkOrder(preserveData);
    }
    await getWorkOrderData(page_no, page_size, sort_column, sort_column_value, search_value, clearSearch);
    setFilterLoading({
      search_loading: false,
      clear_loading: false,
    });
  };

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
      "wo_id_filter": filterData?.wo_id_filter,
      "date_filter": filterData?.date_filter,
      "customer_id_filter": filterData?.customer_id_filter,
      "job_type_filter": filterData?.job_type_filter,
      "status_id_filter": filterData?.status_id_filter,
      "show_filter": show_filter,
    };
    preserveTable.setPreserveWorkOrder(preserveData);
    getWorkOrderData(page_no, page_size, sortColumn, sortColumnValue, searchWord, false, statusIdParam);
  };

  const deleteWorkOrder = async (id) => {
    try {
      const stockItems = await WoStockItemService.getWoStockItems(id);
      if (stockItems.length > 0) {
        for (const eachItem of stockItems) {
          const item = await ItemService.showItem(eachItem.item_id);
          item.stock = Number(item.stock) + Number(eachItem.quantity);
          await ItemService.updateItemQuantity(item);
        }
      }
      const response = await WorkOrderService.deleteWorkOrder(id);
      if (response) {
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    getWorkOrderData(page_no, page_size, sort_column, sort_column_value, search_value, false);
    setSelectedId('');
    handleClose();
  };

  const getPDFData = async(id) => {
    const color = await ColorServices.getColors();
    const colorArray = [];
    if (color) {
      color?.map((eachColor, index) => {
        colorArray[eachColor.id] = eachColor.name.charAt(0);
      });
    }
    const material = await WoMaterialItemService.getWoMaterialItems(id);
    let hasGsm = 0;
    let hasSize = 0;
    Object.keys(material).forEach(stageType => {
      material[stageType].forEach(row => {
        if (row.gsm !== "0.0000" && row.gsm !== null) {
          hasGsm = 1;
        }
        if (row.size_id !== null && row.size !== null) {
          hasSize = 1;
        }

      });
    });

    const printing = await WoPrintingItemService.getWoPrintingItems(id);
    printing?.map((eachPrinting, index) => {
      let color = eachPrinting.color_scheme.split(',').map(Number);
      printing[index]['color_length'] = color.length + (color.length > 1 ? ' Colors' : ' Color');
      let color_scheme = '';
      color?.map((eachColor, index) => {
        color_scheme += colorArray[eachColor] != 'undefined' ? ((index != 0 ? ' + ' : '') + colorArray[eachColor]) : '';
      });
      printing[index]['color_scheme'] = color_scheme;
    });
    const stock = await WoStockItemService.getWoStockItems(id);
    const other = await WoOtherItemService.getWoOtherItems(id);
    const data = await WorkOrderService.showWorkOrder(id);

    // Check if 'gsm' is present in any 'poItems'
    const hasGsmOther = other.some(item => item.gsm > 0);

    // Check if 'size' is present in any 'poItems'
    const hasSizeOther = other.some(item => item.size);

    return {
      order: data,
      material: material,
      printing: printing,
      stock: stock,
      other: other,
      hasGsm: hasGsm,
      hasSize: hasSize,
      hasGsmOther: hasGsmOther,
      hasSizeOther: hasSizeOther,
    };
  }

  const handlePrint = async(id, index) => {
    const cloneWorkOrderData = [...workOrderData];
    cloneWorkOrderData[index]['job_icon_loading'] = true;
    const response = await getPDFData(id);
    const companyName = systemConstant('COMPANY_NAME');
    if(response){
        const pdfTemplate = jobCardPDFTemplate(companyName, response.order, response.material, response.printing, response.stock, response.other, noColorPrintingMachineId, response.hasGsm, response.hasSize, response.hasGsmOther, response.hasSizeOther);

        const originalBody = document.body;
        const printWindow = window.open('', '_blank');
        cloneWorkOrderData[index]['job_icon_loading'] = false;
        setWorkOrderData(cloneWorkOrderData);
        printWindow.document.write(`${pdfTemplate}`);
        printWindow.document.close();
        await new Promise(r => setTimeout(r, 2000));
        printWindow.print();
        printWindow.close();
        document.body = originalBody;
    }
  };

  const rows = [];

  const filterOn = async () => {
    getAllWoData();
    getAllCustomerData();
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sort_column,
      "sort_column_value": sort_column_value,
      "search_value": search_value,
      "wo_id_filter": '',
      "date_filter": '',
      "customer_id_filter": '',
      "job_type_filter": '',
      "status_id_filter": '',
      "show_filter": true,
    };
    preserveTable.setPreserveWorkOrder(preserveData);
    await getWorkOrderData(page_no, page_size, sort_column, sort_column_value, search_value);
  }

  const filterOff = async () => {
    setFilterData({
      wo_id_filter: '',
      date_filter: '',
      customer_id_filter: '',
      job_type_filter: '',
      status_id_filter: '',
    });
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sort_column,
      "sort_column_value": sort_column_value,
      "search_value": search_value,
      "wo_id_filter": '',
      "date_filter": '',
      "customer_id_filter": '',
      "job_type_filter": '',
      "status_id_filter": '',
      "show_filter": false,
    };
    preserveTable.setPreserveWorkOrder(preserveData);
    await getWorkOrderData(page_no, page_size, sort_column, sort_column_value, search_value);
  }

  workOrderData?.map((eachWorkOrder, index) => {
      const col = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-work-orders/edit?id=${eachWorkOrder.id}` : ''} variant="h6" color="text"
          sx={{
            "&.MuiTypography-root": {
              fontSize: "0.825rem",
            }
          }}>
                {eachWorkOrder?.id}
              </MDTypography>,
        release_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap"
          sx={{
            "&.MuiTypography-root": {
              fontSize: "0.825rem",
            }
          }}>
          {eachWorkOrder ? dayjs(eachWorkOrder?.release_date).format('DD-MM-YYYY') : ''}
          </MDTypography>
          ),
        customer_name: (<MDTypography color="info" component={Link} to={customerPermission.edit ? `/manage-customers/edit?id=${eachWorkOrder?.customer_id}&ref=/manage-work-orders` : ''} display="block" variant="h6">
          {eachWorkOrder?.customer_name}
          </MDTypography>
        ),
        job_type: (<MDTypography color="info" component={Link} to={pagePermission.edit ? `/manage-work-orders/edit?id=${eachWorkOrder.id}` : ''} display="block" variant="h6">
          {eachWorkOrder?.job_type}
          </MDTypography>
        ),
        name: (<MDTypography display="block" variant="body2"
          sx={{
            "&.MuiTypography-root": {
              fontSize: "0.825rem",
            }
          }}>
          {eachWorkOrder?.name}
          </MDTypography>
        ),
        mobile: (<MDTypography display="block" variant="body2"
          sx={{
            "&.MuiTypography-root": {
              fontSize: "0.825rem",
            }
          }}>
          {eachWorkOrder?.mobile}
          </MDTypography>
        ),
        delivery_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap"
          sx={{
            "&.MuiTypography-root": {
              fontSize: "0.825rem",
            }
          }}>
          {eachWorkOrder ? dayjs(eachWorkOrder?.delivery_date).format('DD-MM-YYYY') : ''}
          </MDTypography>
        ),
        first_name: (<MDTypography display="block" variant="body2">
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "0.825rem",
                }
              }}>
                {eachWorkOrder?.first_name}
            </MDTypography>
            <MDTypography display="block" variant="body2" whiteSpace="nowrap" sx={{
              "&.MuiTypography-root": {
                fontSize: "11px",
              }
            }}>
                {eachWorkOrder? dayjs(eachWorkOrder?.created_at).format('DD-MM-YYYY hh:mm A') : ''}
            </MDTypography>
          </MDTypography>
        ),
        action: (
              <MDBox display="flex">
                <MDTypography mr={1} mt={1} component={Link} onClick={() => handlePrint(eachWorkOrder.id, index)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                    {eachWorkOrder?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                </MDTypography>
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-work-orders/edit?id=${eachWorkOrder.id}`} title="Edit" display="block" variant="caption" color="info" fontWeight="medium">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.new ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-work-orders/add?id=${eachWorkOrder.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                    <Icon fontSize="small">content_copy_icon</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.remove ? (
                  <MDTypography mt={1} component={Link} onClick={() => handleClickOpen(eachWorkOrder.id)} title="Remove" display="block" variant="caption" color="error" fontWeight="medium">
                    <Icon fontSize="small">delete</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            )
      };
      rows[index] = col;
  });

  const firstRow = {
    id: (<MDSelect
          label=""
          fullWidth
          value={filterData?.wo_id_filter}
          onChange={(e) => setFilterData({
            ...filterData,
            ['wo_id_filter']: e.target.value,
          })}
          options={woIdsOptions}
        />),
    release_date: (
      <MDInput
        type="text"
        label=""
        fullWidth
        value={filterData?.date_filter}
        name="date_filter"
        sx={{width: "90px"}}
        inputProps={{'maxLength':10}}
        onChange={(e) => setFilterData({
          ...filterData,
          ['date_filter']: e.target.value,
        })}
      />
      ),
    customer_name: (<MDSelect
                      label=""
                      fullWidth
                      value={filterData?.customer_id_filter}
                      onChange={(e) => setFilterData({
                        ...filterData,
                        ['customer_id_filter']: e.target.value,
                      })}
                      options={customerOptions}
                      sx={{width: "200px"}}
                    />),
    job_type: (<MDInput
                type="text"
                label=""
                fullWidth
                value={filterData.job_type_filter}
                name="job_type_filter"
                sx={{width: "450px"}}
                inputProps={{'maxLength':100}}
                onChange={(e) => setFilterData({
                  ...filterData,
                  ['job_type_filter']: e.target.value,
                })}
              />),
    name: (<MDSelect
            label=""
            fullWidth
            value={filterData?.status_id_filter}
            onChange={(e) => setFilterData({
              ...filterData,
              ['status_id_filter']: e.target.value,
            })}
            options={statusOptions}
          />),
    mobile: (<MDTypography display="block" variant="body2">
    </MDTypography>),
    delivery_date: (<MDTypography display="block" variant="body2">
    </MDTypography>),
    first_name: (<MDTypography display="block" variant="body2">
    </MDTypography>),
    action: (
      <MDBox display="flex">
        <MDTypography mr={1} component={Link} onClick={(e) => woSearchHandler(e, false)} title="Search" display="block" variant="caption" color="success" fontWeight="medium">
            {filterLoading?.search_loading ? <MDCircularProgress color="info" size={20}/> : <Icon fontSize="medium" sx={{ fontWeight: "bold" }}>search_icon</Icon>}
        </MDTypography>
        <MDTypography component={Link} onClick={(e) => woSearchHandler(e, true)} title="Clear" display="block" variant="caption" color="primary" fontWeight="medium">
            {filterLoading?.clear_loading ? <MDCircularProgress color="info" size={20}/> : <Icon fontSize="medium" sx={{ fontWeight: "bold" }}>clear_icon</Icon>}
        </MDTypography>
        {renderAlertSB}
    </MDBox>
    )
  };
  if (show_filter) {
    rows.unshift(firstRow);
  }

  return {
    columns: [
      { Header: "WO#", accessor: "id", width: "5%", align: "left" },
      { Header: "Date", accessor: "release_date", width: "6%", align: "left" },
      { Header: "Party", accessor: "customer_name", width: "23%", align: "left" },
      { Header: "Job Type", accessor: "job_type", width: "37%", align: "left" },
      { Header: "Status", accessor: "name", width: "6%", align: "left" },
      { Header: "Phone No.", accessor: "mobile", width: "6%", align: "left" },
      { Header: "Delivery Date", accessor: "delivery_date", width: "6%", align: "left" },
      { Header: "Created By & At", accessor: "first_name", width: "8%", align: "left" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "7%", align: "center" }] : [],
    ],
    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deleteWorkOrder: deleteWorkOrder,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
    show_filter: show_filter,
    filterOn: filterOn,
    filterOff: filterOff,
  };
}
