import dayjs from 'dayjs';
import brandWhite from "assets/images/logo.png";

export const PoPDFTemplate = (companyName, companyAddress, purchaseOrder, poItem,  hasGsm, hasSize, numberFormat) => {
    let phone = '';
    if (purchaseOrder?.supplier?.phone){
      phone = purchaseOrder?.supplier?.phone;
      if (purchaseOrder?.supplier?.mobile) {
        phone = phone + ' / ' + purchaseOrder?.supplier?.mobile;
      }
    } else if (purchaseOrder?.supplier?.mobile) {
      phone = purchaseOrder?.supplier?.mobile;
    }

    let totalColspan = 5;
    if (!hasGsm && !hasSize) {
      totalColspan = 3;
    } else if (!hasGsm) {
      totalColspan = 4;
    } else if (!hasSize) {
      totalColspan = 4;
    }

    return  `<html>
  <head>
    <title>AP_Purchase_Order_${purchaseOrder?.id} </title>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <center>
        <table style="width:98%; border:1px solid black;border-collapse: collapse;">
          <tr>
            <td>
              <table style="width: 100%;">
                <tr>
                  <td style="text-align:right; font-size:13px;padding-top:1rem;padding-left:3rem;">${companyAddress}</td>
                  <th style="text-align:right;padding-right: 1rem;padding-left: 0.5rem;"><hr style="border: none; border-left: 2px solid #5bc0de; height: 50px; width: 0; margin-top:20px;"></th>
                  <th style="text-align:right; padding-right: 2rem; width: 8rem;"><img src="${brandWhite}" alt="Aditya Prints" style="max-width:8rem;"></th>
                </tr>
              </table>
            </td>
          </tr>
          <tr height="10px"></tr>
          <tr style="background-color: #f0dc4e38;">
            <td style="padding-top: 1rem;padding-left: 1rem;padding-right: 1rem;padding-bottom: 1rem;">
              <table style="width:100%;">
                <tr>
                  <td style="width: 78% !important;font-size:14px; align:left;vertical-align: top;"><b>To,</b></td>
                  <td style="width: 30% !important;font-size:14px; align:right;vertical-align: top;"><b>PURCHASE ORDER</b></td>
                </tr>
                <tr>
                  <td style="width: 78%;font-size:14px; align:left;vertical-align: top;"><b>${purchaseOrder?.supplier?.name}</b></td>
                  <td style="width: 30%;font-size:14px; align:right;vertical-align: top;"><b>Number: ${purchaseOrder?.id}</b></td>
                </tr>
                <tr>
                  <td style="width: 78%;font-size:13px; word-break: break-word !important; align:left;padding-right:160px !important;vertical-align: top;">${purchaseOrder?.supplier?.address}</td>
                  <td style="width: 30%;font-size:14px; align:right;vertical-align: top;"><b>Date: ${dayjs(purchaseOrder.submitted_date).format('DD-MM-YYYY')}</b></td>
                </tr>
                <tr>
                  ${purchaseOrder.work_order_id ? `<td style="width: 78%;font-size:14px; align:left;vertical-align: bottom;padding-top: 10px !important;"><b>Customer: </b>${purchaseOrder.work_order.customer.name}</td>` : ``}
                  <td style="width: 30%;font-size:14px; align:left;vertical-align: bottom;padding-top: 10px  !important;"><b>${phone ? 'Phone: ' : ''}</b>${phone}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="background-color: #f0dc4e38;">
            <td style="padding-top: 1rem;padding-left: 1rem;padding-right: 1rem;padding-bottom: 1rem;">
              <table style="width:100%; background-color: #fff;border-radius: 15px;border-collapse: collapse;">
                <tr>
                  <td colspan="${Number(totalColspan) + 2}" style="font-size:15px; align:left; padding-left: 1.5rem;padding-top:1rem;">
                    <p style="font-size:15px;"><b>Dear Sir / Madam,</b>
                    <br/>
                    Kindly supply the following goods in good condition
                    </p>
                  </td>
                </tr>
                <tr height="20px">
                </tr>
                <tr height="30px">
                  <th style="width: 40px;background-color: #143a8e4d; font-size:14px; text-align:center; border-right:1px solid #143a8e4d;">Sr. No.</th>
                  <th style="background-color: #143a8e4d; font-size:14px; text-align:center; border-right:1px solid #143a8e4d;">Item</th>
                  ${hasGsm ? `<th style="background-color: #143a8e4d; font-size:14px; text-align:center; border-right:1px solid #143a8e4d;">GSM</th>` : ``}
                  ${hasSize ? `<th style="background-color: #143a8e4d; font-size:14px; text-align:center; border-right:1px solid #143a8e4d;">Size</th>` : ``}
                  <th style="background-color: #143a8e4d; font-size:14px; text-align:center; border-right:1px solid #143a8e4d;">Rate</th>
                  <th style="background-color: #143a8e4d; font-size:14px; text-align:center; border-right:1px solid #143a8e4d;">Quantity</th>
                  <th style="background-color: #143a8e4d; font-size:14px; text-align:center;">Amount</th>
                </tr>
                ${poItem?.map((eachPoItem, index) => {
                  return `<tr height="25px">
                  <td style="font-size:14px; text-align:center; border-right:1px solid #143a8e4d; padding: 5px;">${index + 1}</td>
                  <td style="max-width: 250px; word-wrap: break-word !important; font-size:14px; text-align:left; border-right:1px solid #143a8e4d; padding: 5px;">${eachPoItem?.item?.description}</td>
                  ${hasGsm ? `<td style="font-size:14px; text-align:center; border-right:1px solid #143a8e4d; padding: 5px;">${numberFormat(eachPoItem?.gsm, false)}</td>`  : ``}
                  ${hasSize ? `<td style="font-size:14px; text-align:center; border-right:1px solid #143a8e4d; padding: 5px;">${eachPoItem?.size_id ? eachPoItem.size.name : ''}</td>` : ``}
                  <td style="font-size:14px; text-align:right; border-right:1px solid #143a8e4d; padding: 5px;">${numberFormat(eachPoItem?.rate, false)}</td>
                  <td style="font-size:14px; text-align:right; border-right:1px solid #143a8e4d; padding: 5px;">${eachPoItem?.quantity * 1}</td>
                  <td style="font-size:14px; text-align:right; padding: 5px;">${numberFormat(eachPoItem?.amount)}</td>
                </tr>`
                }).join('')}
                <tr height="25px">
                <th colspan="${totalColspan}" style="background-color: #f0dc4e38;"></th>
                <th style="background-color: #143a8e4d; font-size:14px; border-right:1px solid #143a8e4d;text-align:right; padding: 5px;">Total:</th>
                <th style="background-color: #143a8e4d; font-size:14px; text-align:right; padding: 5px;">${numberFormat(purchaseOrder?.amount)}</th>
                </tr>
                ${poItem.length == 0 ?
                  `<tr><td colspan="${Number(totalColspan) + 2}" style="font-size:14px; text-align:center; border:1px solid #143a8e4d; padding: 2px;"> Not Applicable</td></tr>` : ``}
                <tr height="30px">
                </tr>
                <tr height="45px">
                  <td colspan="${Number(totalColspan) + 2}" style="fonts-size:14px; text-align:right; padding-right:2rem;">
                    Signature
                  </td>
                </tr> 
              </table>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </body>
</html>`

}
