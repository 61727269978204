/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import ParametersService from "services/parameter-services";
import { useNavigate } from "react-router-dom";
import authPermission from "utility/auth-permissions";
import { useCache } from "context";

export default function data(props) {
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-parameters');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.parameter;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [parametersData, setParametersData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [totalData, setTotalData] = useState(0);
  const { cacheItems, setCacheItems } = useCache();
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    // const getParametersData = async (e) => {
    //   const response = await ParametersService.getParameters(page_no, page_size, sort_column, sort_column_value, search_value);
    //   setParametersData(response?.data);
    //   setTotalData(response?.total);
    // };
    // getParametersData();
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveParameter(preserveData);
    const response = await ParametersService.getParameters(page_no, page_size, sortColumn, sortColumnValue, searchWord);
    setParametersData(response?.data);
    setTotalData(response?.total);
  };

  const updateParameterStatus = async (id, status) => {
    try {
      var data = {'active': status};
      const res = await ParametersService.updateParameterStatus(id, data);
      if (res) {
        if (cacheItems.hasOwnProperty('parameters')) {
          // Create a new object without the key
          const { parameters, ...rest } = cacheItems; // Destructure to exclude key
          setCacheItems(rest); // Set the new cache items without key
        }
        openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Parameter'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        const response = await ParametersService.getParameters(page_no, page_size, sort_column, sort_column_value, search_value);
        setParametersData(response?.data);
        setTotalData(response?.total);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Parameter'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Parameter'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const deleteParameter = async (id) => {
    try {
      const response = await ParametersService.deleteParameter(id);
      if (response) {
        if (cacheItems.hasOwnProperty('parameters')) {
          // Create a new object without the key
          const { parameters, ...rest } = cacheItems; // Destructure to exclude key
          setCacheItems(rest); // Set the new cache items without key
        }
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Parameter'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Parameter'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Parameter'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    const res = await ParametersService.getParameters(page_no, page_size, sort_column, sort_column_value, search_value);
    setParametersData(res?.data);
    setTotalData(res?.total);
    setSelectedId('');
    handleClose();
  };

  const allRows = [];
  parametersData?.map((eachParameter, index) => {
      const eachData = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-parameters/edit?id=${eachParameter.id}` : ''} variant="body2" color="text">
                {eachParameter.id}
              </MDTypography>,
        name: (<MDTypography display="block" variant="body2">
                {eachParameter.name}
                </MDTypography>
              ),
        value: (<MDTypography display="block" variant="body2">
                  {eachParameter.value}
                  </MDTypography>
               ),
        action: (
                <MDBox display="flex">
                  {pagePermission.status ? (
                    <MDSwitch
                      checked={eachParameter.active ? true : false}
                      color="info"
                      title="Status"
                      onChange={() => updateParameterStatus(eachParameter.id , !eachParameter.active)}
                    />
                  ) : ''}
                  {pagePermission.edit ? (
                    <MDTypography mr={1} mt={1} component={Link} to={`/manage-parameters/edit?id=${eachParameter.id}`} title="Edit" display="block" variant="body2" color="info">
                      <Icon fontSize="small">edit</Icon>
                    </MDTypography>
                  ) : ''}
                  {pagePermission.new ? (
                    <MDTypography mr={1} mt={1} component={Link} to={`/manage-parameters/add?id=${eachParameter.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                      <Icon fontSize="small">content_copy_icon</Icon>
                    </MDTypography>
                  ) : ''}
                  {pagePermission.remove ? (
                    <MDTypography mt={1} component={Link} onClick={() => handleClickOpen(eachParameter.id)} title="Remove" display="block" variant="body2" color="error">
                      <Icon fontSize="small">delete</Icon>
                    </MDTypography>
                  ) : ''}
                  {renderAlertSB}
                </MDBox>
              )
      };
      allRows[index] = eachData;
  });

  return {
    columns: [
      { Header: "Id", accessor: "id", width: "10%", align: "left" },
      { Header: "Name", accessor: "name", width: "35%", align: "left" },
      { Header: "Value", accessor: "value", width: "35%", align: "left" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "20%", align: "center" }] : [],
    ],
    rows: allRows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deleteParameter: deleteParameter,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
