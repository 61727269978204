import HttpService from "./htttp.service";

class StatusesService {

  getStatuses = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'statuses';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'statuses?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addStatus = async(newStatus) => {
    const fetchUrl = 'statuses';
    return await HttpService.post(fetchUrl, newStatus);
  }

  updateStatus = async (id, data) => {
    const fetchUrl = 'statuses/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updateActiveStatus = async (id, data) => {
    const fetchUrl = 'statuses/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showStatus = async(id) => {
    const fetchUrl = 'statuses/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteStatus = async (id) => {
    const fetchUrl = 'statuses/' + id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new StatusesService();
