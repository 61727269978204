import HttpService from "./htttp.service";

class ReportService {
  getPurchaseRegister = async(supplierId, statusId, startDate, endDate) => {
    const fetchUrl = 'purchase_register?supplier_id=' + supplierId + '&status_id=' + statusId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getExcelExport = async(data) => {
    const fetchUrl = 'export_excel';
    return await HttpService.post(fetchUrl, data);
  }

  getSupplierItemRegister = async (id) => {
    let fetchUrl = 'supplier_item_register?supplier_id=' + id;
    return await HttpService.get(fetchUrl);
  }

  getIncompleteWo = async (workOrderId, jobType, startDate, endDate) => {
    const fetchUrl = 'incomplete_work_orders?work_order_id=' + workOrderId + '&job_type=' + jobType + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getSupplierPurchaseRegister = async(supplierId, grnId, startDate, endDate) => {
    const fetchUrl = 'supplier_purchase_register?supplier_id=' + supplierId + '&grn_id=' + grnId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getPaymentReceived = async(customerId, paymentId, isCash, startDate, endDate) => {
    const fetchUrl = 'payment_received?customer_id=' + customerId + '&payment_type=' + paymentId + '&is_cash=' + isCash + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getSupplierPaymentDue = async(supplierId, billType, startDate, endDate) => {
    const fetchUrl = 'supplier_payment_due?supplier_id=' + supplierId + '&bill_type=' + billType + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getSupplierPaymentMade = async(supplierId, paymentType, startDate, endDate) => {
    const fetchUrl = 'supplier_payment_made?supplier_id=' + supplierId + '&payment_type=' + paymentType + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getDayWiseCosting = async(startDate, endDate) => {
    const fetchUrl = 'day_wise_costing?start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getSupplierInvoiceRegister = async(supplierId, billNo, purchaseOrderId, startDate, endDate) => {
    const fetchUrl = 'supplier_invoice_register?supplier_id=' + supplierId + '&bill_no=' + billNo + '&purchase_order_id=' + purchaseOrderId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getInvoiceRegister = async(customerId, invoiceNo, billType, workOrderId, startDate, endDate) => {
    const fetchUrl = 'invoice_register?customer_id=' + customerId + '&invoice_no=' + invoiceNo + '&bill_type=' + billType + '&work_order_id=' + workOrderId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getJobCostSheet = async(customerId, machineId, workOrderId, startDate, endDate) => {
    const fetchUrl = 'job_cost_sheet?customer_id=' + customerId + '&machine_id=' + machineId + '&work_order_id=' + workOrderId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getJobCostSheetSummary = async(startDate, endDate) => {
    const fetchUrl = 'job_cost_sheet_summary?start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getOutstandingBill = async(customerId, paymentType, billType, startDate, endDate) => {
    const fetchUrl = 'outstanding_bill?customer_id=' + customerId + '&payment_type=' + paymentType + '&bill_type=' + billType + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getItemWiseSupplierWiseGrn = async(supplierId, itemId, startDate, endDate) => {
    const fetchUrl = 'item_wise_supplier_wise_grn?supplier_id=' + supplierId + '&item_id=' + itemId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getItemReceiptVsIssue = async(itemId, startDate, endDate) => {
    const fetchUrl = 'item_receipt_vs_issue?item_id=' + itemId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getCustomerMonthlySale = async(customerId, totalSort, startDate, endDate) => {
    const fetchUrl = 'customer_monthly_sale?customer_id=' + customerId + '&total_ort=' + totalSort + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getDailyPaymentReceived = async(customerId, paymentTypes, startDate, endDate, months, durationType) => {
    const fetchUrl = 'daily_payment_received?customer_id=' + customerId + '&payment_types=' + paymentTypes + '&start_date=' + startDate + '&end_date=' + endDate + '&months=' + months + '&duration_type=' + durationType;
    return await HttpService.get(fetchUrl);
  }

  getMachineWiseSales = async(machineIds, startDate, endDate, months, durationType) => {
    const fetchUrl = 'machine_wise_sales?machine_ids=' + machineIds + '&start_date=' + startDate + '&end_date=' + endDate + '&months=' + months + '&duration_type=' + durationType;
    return await HttpService.get(fetchUrl);
  }

  getOperatorJobSheet = async(operatorId, startDate, endDate) => {
    const fetchUrl = 'operator_job_sheet?operator_id=' + operatorId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }

  getCustomerInventoryInward = async(customerId, startDate, endDate) => {
    const fetchUrl = 'customer_inventory_inward?customer_id=' + customerId + '&start_date=' + startDate + '&end_date=' + endDate;
    return await HttpService.get(fetchUrl);
  }
}

export default new ReportService();
