import HttpService from "./htttp.service";

class DashboardService {

    getDashboardStatistics = async() => {
        const fetchUrl = 'dashboard_statistics';
        return await HttpService.get(fetchUrl);
    }

    getOperatorDashboardStatistics = async(operatorId) => {
        const fetchUrl = 'operator_dashboard_statistics/' + operatorId;
        return await HttpService.get(fetchUrl);
    }
}

export default new DashboardService();
