import HttpService from "./htttp.service";

class PaymentTypesService {

  getPaymentTypes = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'payment_types';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'payment_types?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addPaymentType = async(newPaymentType) => {
    const fetchUrl = 'payment_types';
    return await HttpService.post(fetchUrl, newPaymentType);
  }

  updatePaymentType = async (id, data) => {
    const fetchUrl = 'payment_types/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updatePaymentTypeStatus = async (id, data) => {
    const fetchUrl = 'payment_types/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showPaymentType = async(id) => {
    const fetchUrl = 'payment_types/' + id;
    return await HttpService.get(fetchUrl);
  }

  deletePaymentType = async (id) => {
    const fetchUrl = 'payment_types/' + id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new PaymentTypesService();
