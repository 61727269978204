/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation, useSearchParams } from "react-router-dom";

// Material Dashboard 2 React components
import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import Divider from '@mui/material/Divider';
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import WorkOrderService from "services/work-order-service";
import ScheduleWorkOrderService from "services/schedule-work-order-service";
import WoMaterialItemService from "services/wo-material-item-service";
import WoPrintingItemService from "services/wo-printing-item-service";
import WoOtherItemService from "services/wo-other-item-service";
import WoStockItemService from "services/wo-stock-item-service";
import ColorServices from "services/color-services";
import SizeService from "services/size-service";
import CustomersService from "services/customer-service";
import UserService from "services/user-service";
import ReportService from "services/report-service";
import { jobCardPDFTemplate } from "./job-card-pdf-template";
import { useNavigate } from "react-router-dom";
import authPermission from "utility/auth-permissions";
import { formatDateTime, formatDate, timeDifference, numberFormat } from "utility/helpers";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./job-sheet-report-pdf-template";
import dayjs from 'dayjs';
import { useCache } from "context";

export default function data(props) {
  const loggedUserId = localStorage.getItem('user_id');
  const { hasPermission, systemConstant } = authPermission();
  const newStatus = systemConstant('NEW_STATUS');
  const inProgressStatus = systemConstant('IN_PROGRESS_STATUS');
  const completedStatus = systemConstant('COMPLETED_STATUS');
  const approvedStatus = systemConstant('APPROVED_STATUS');
  const billedStatus = systemConstant('BILLED_STATUS');
  const noColorPrintingMachineId = systemConstant('NO_COLOR_PRINTING_MACHINE_ID');
  const workOrderPermission = hasPermission('/manage-work-orders');
  const customerPermission = hasPermission('/manage-customers');
  const preserveTable = useContext(PreserveTable);
  const {
    newProgressWoId,
    newProgressCustomer,
    newProgressSize,
    newProgressOperator,
    newProgressCreatedDate } = preserveTable?.adminNewProgressScheduleWorkOrder;
  const {
    completedWoId,
    completedCustomer,
    completedSize,
    completedOperator,
    completedCompletionDate,
    completedCreatedDate,
    completedStartedDate } = preserveTable?.adminCompletedScheduleWorkOrder;
  const {
    page_no,
    page_size,
    sort_column,
    sort_column_value,
    search_value } = preserveTable?.operatorCompletedScheduleWorkOrder;
  const loggedUserRole = localStorage.getItem('role');
  const [searchParams] = useSearchParams();
  const returnTab = searchParams.get('returnTab');
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [scheduleWorkOrders, setScheduleWorkOrders] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [operatorCompletedWorkOrders, setOperatorCompletedWorkOrders] = useState([]);
  const [operatorCompletedTotalWo, setOperatorCompletedTotalWo] = useState(0);
  const [newProgressScheduleWo, setNewProgressScheduleWo] = useState([]);
  const [newProgressTotalData, setNewProgressTotalData] = useState(0);
  const [completedScheduleWo, setCompletedScheduleWo] = useState([]);
  const [completedTotalData, setCompletedTotalData] = useState(0);
  const [showCompletedTab, setShowCompletedTab] = useState(false);
  const [showOperatorCompletedTab, setShowOperatorCompletedTab] = useState(false);
  const [colorData, setColorData] = useState([]);
  const [endedAtOpen, setEndedAtOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [markCompleteOpen, setMarkCompleteOpen] = useState(false);
  const [updateCompletedDateOpen, setUpdateCompletedDateOpen] = useState(false);
  const [currentJobInProgress, setCurrentJobInProgress] = useState(false);
  const [currentJobInProgressWoId, setCurrentJobInProgressWoId] = useState('');
  const [currentJobInProgressWoPrintingItemId, setCurrentJobInProgressWoPrintingItemId] = useState('');
  const [nextJobOpen, setNextJobOpen] = useState(false);
  const [nextPrintJobOpen, setNextPrintJobOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [workOrderId, setWorkOrderId] = useState('');
  const [value, setValue] = useState(0);
  const [operatorTabValue, setOperatorTabValue] = useState(0);
  const [newProgressSearchWoId, setNewProgressSearchWoId] = useState(newProgressWoId);
  const [completedSearchWoId, setCompletedSearchWoId] = useState(completedWoId);
  const [operatorSearchWoId, setOperatorSearchWoId] = useState('');
  const [operatorCompletedSearchWoId, setOperatorCompletedSearchWoId] = useState('');
  const [newProgressSearchCustomer, setNewProgressSearchCustomer] = useState(newProgressCustomer);
  const [completedSearchCustomer, setCompletedSearchCustomer] = useState(completedCustomer);
  const [operatorSearchCustomer, setOperatorSearchCustomer] = useState('');
  const [operatorCompletedSearchCustomer, setOperatorCompletedSearchCustomer] = useState('');
  const [newProgressSearchSize, setNewProgressSearchSize] = useState(newProgressSize);
  const [completedSearchSize, setCompletedSearchSize] = useState(completedSize);
  const [operatorSearchSize, setOperatorSearchSize] = useState('');
  const [operatorCompletedSearchSize, setOperatorCompletedSearchSize] = useState('');
  const [adminNewProgressWoIdOptions, setAdminNewProgressWoIdOptions] = useState([]);
  const [adminCompletedWoIdOptions, setAdminCompletedWoIdOptions] = useState([]);
  const [operatorNewProgressWoIdOptions, setOperatorNewProgressWoIdOptions] = useState([]);
  const [operatorCompletedWoIdOptions, setOperatorCompletedWoIdOptions] = useState([]);
  const [customersOptions, setCustomersOptions] = useState([]);
  const [sizesOptions, setSizesOptions] = useState([]);
  const [newProgressSearchOperator, setNewProgressSearchOperator] = useState(newProgressOperator);
  const [completedSearchOperator, setCompletedSearchOperator] = useState(completedOperator);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [newProgressSearchCreatedDate, setNewProgressSearchCreatedDate] = useState(newProgressCreatedDate);
  const [completedSearchCreatedDate, setCompletedSearchCreatedDate] = useState(completedCreatedDate);
  const [operatorSearchCreatedDate, setOperatorSearchCreatedDate] = useState('');
  const [operatorCompletedSearchCreatedDate, setOperatorCompletedSearchCreatedDate] = useState('');
  const [completedSearchCompletionDate, setCompletedSearchCompletionDate] = useState(completedCompletionDate);
  const [operatorCompletedSearchCompletionDate, setOperatorCompletedSearchCompletionDate] = useState('');
  const [operatorCompletedSearchStartedDate, setOperatorCompletedSearchStartedDate] = useState(dayjs(new Date).format('YYYY-MM-DD'));
  const [updateCompletedDateValue, setUpdateCompletedDateValue] = useState(null);
  const [completedSearchStartedDate, setCompletedSearchStartedDate] = useState(completedStartedDate);
  const [endedAtLoading, setEndedAtLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [markCompleteLoading, setMarkCompleteLoading] = useState(false);
  const [updateCompletedDateLoading, setUpdateCompletedDateLoading] = useState(false);
  const { cacheItems, setCacheItems } = useCache();
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState({
    progress_search: false,
    progress_clear: false,
    completed_search: false,
    completed_clear: false,
    operator_search: false,
    operator_clear: false,
    operator_completed_search: false,
    operator_completed_clear: false,
  });
  const [adminNewProgressRowExpanded, setAdminNewProgressRowExpanded] = useState({});
  const [adminCompletedRowExpanded, setAdminCompletedRowExpanded] = useState({});
  const [operatorNewProgressRowExpanded, setOperatorNewProgressRowexpanded] = useState({});
  const [operatorCompletedRowExpanded, setOperatorCompletedRowexpanded] = useState({});
  const [operatorDropdown, setOperatorDropdown] = useState([]);
  const [operatorJobSheet, setOperatorJobSheet] = useState([]);
  const [reportSearchLoading, setReportSearchLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [operatorId, setOperatorId] = useState(loggedUserRole == 'Operator' ? loggedUserId : '');
  const [startDate, setStartDate] = useState((dayjs(new Date())));
  const [endDate, setEndDate] = useState('');
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  let location = useLocation();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      setShowCompletedTab(true);
    } else {
      setShowCompletedTab(false);
    }
  };

  const handleOperatorViewTabChange = (event, newValue) => {
    setOperatorTabValue(newValue);
    if (newValue == 1) {
      setShowOperatorCompletedTab(true);
    } else {
      setShowOperatorCompletedTab(false);
    }
  };

  const handleClickEndedAtOpen = (id, woId) => {
    setSelectedId(id);
    setWorkOrderId(woId);
    setEndedAtOpen(true);
  };

  const handleClickRejectOpen = (id) => {
    setSelectedId(id);
    setRejectOpen(true);
  };

  const handleClickMarkCompleteOpen = (id) => {
    setSelectedId(id);
    setMarkCompleteOpen(true);
  };

  const handleClickUpdateCompletedDate = (id) => {
    setSelectedId(id);
    setUpdateCompletedDateOpen(true);
  };

  const handleClose = () => {
    setEndedAtOpen(false);
    setRejectOpen(false);
    setMarkCompleteOpen(false);
    setNextJobOpen(false);
    setNextPrintJobOpen(false);
    setEndedAtLoading(false);
    setRejectLoading(false);
    setMarkCompleteLoading(false);
    setUpdateCompletedDateOpen(false);
    setUpdateCompletedDateValue(null);
  };

  const getAdminInProgWoIds = async() => {
    const statuses = [Number(newStatus), Number(inProgressStatus)];
    var adminNewProgressWoIdsArray = [];
    let optionsResponse = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, '', '', '', '', '', '');
    const optWoIds = [];
    const optionCloneScheduleWo = [...Object.entries(optionsResponse)];
    optionCloneScheduleWo.forEach( async (eachScheduleWo, index) => {
      eachScheduleWo[1]?.wo_printing_items?.forEach((eachItem, i) => {
        adminNewProgressWoIdsArray[index] = {
          key: eachScheduleWo[1]?.id,
          value: eachScheduleWo[1]?.id,
        }
      });
    });
    setAdminNewProgressWoIdOptions(adminNewProgressWoIdsArray);
  }

  const getAdminCompletedWoIds = async() => {
    const statuses = [Number(completedStatus), Number(inProgressStatus)];
    var adminCompletedWoIdsArray = [];
    let optionsResponse = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, '', '', '', '', '', '');
    const optWoIds = [];
    const optionCloneScheduleWo = [...Object.entries(optionsResponse)];
    optionCloneScheduleWo.forEach( async (eachScheduleWo, index) => {

      var is_include_completed_jobs = false;
      eachScheduleWo[1]?.wo_printing_items?.forEach( async (eachWoPrintItem, i) => {
        if (eachWoPrintItem?.operator_id && eachWoPrintItem?.started_at && eachWoPrintItem?.ended_at) {
          is_include_completed_jobs = true;
        }
        if (is_include_completed_jobs === true) {
          adminCompletedWoIdsArray[index] = {
            key: eachScheduleWo[1]?.id,
            value: eachScheduleWo[1]?.id,
          }
        }
      });
    });
    setAdminCompletedWoIdOptions(adminCompletedWoIdsArray);
  }

  const getOperatorInProgWoIds = async() => {
    const statuses = [Number(newStatus), Number(inProgressStatus)];
    var operatorNewProgressWoIdsArray = [];
    let optionsResponse = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, '', '', '', '', '', '');
    const optWoIds = [];
    const optionCloneScheduleWo = [...Object.entries(optionsResponse)];
    optionCloneScheduleWo.forEach( async (eachScheduleWo, index) => {
      eachScheduleWo[1]?.wo_printing_items?.forEach((eachItem, i) => {
        if (!eachItem?.ended_at && ((eachItem?.operator_id == loggedUserId) || (!eachItem?.operator_id)) && eachItem?.print_machine?.printing_machine_users?.length > 0) {
          var belongsToOperator = false;
          eachItem?.print_machine?.printing_machine_users?.forEach( async (eachUser, userIndex) => {
            if (eachItem?.print_machine?.printing_machine_users[userIndex]['user_id'] == loggedUserId ) {
              belongsToOperator = true;
            }
          });
          if (belongsToOperator == true) {
            if (!optWoIds.includes(eachScheduleWo[1]?.id) && (eachItem?.print_machine?.printing_machine_users?.length > 0)) {
              eachItem?.print_machine?.printing_machine_users?.forEach( async (eachUser, userIndex) => {
                if (eachItem?.print_machine?.printing_machine_users[userIndex]['user_id'] == loggedUserId ) {
                  optWoIds.push(eachScheduleWo[1]?.id);
                  operatorNewProgressWoIdsArray[index] = {
                    key: eachScheduleWo[1]?.id,
                    value: eachScheduleWo[1]?.id,
                  }
                }
              });
            }
          }
        }
      });
    });
    setOperatorNewProgressWoIdOptions(operatorNewProgressWoIdsArray);
  }

  const getOperatorCompletedWoIds = async() => {
    const statusess = [Number(systemConstant('IN_PROGRESS_STATUS')), Number(systemConstant('COMPLETED_STATUS')), Number(systemConstant('APPROVED_STATUS')), Number(systemConstant('BILLED_STATUS'))];
    var operatorCompletedWoIdsArray = [];
    let optionOperatorCompletedresponse = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statusess, '', '', '', '', '', '', loggedUserId);
    optionOperatorCompletedresponse?.forEach( async (eachScheduleWo, index) => {
      operatorCompletedWoIdsArray[index] = {
        key: Number(eachScheduleWo?.id),
        value: eachScheduleWo?.id,
      }
    });
    setOperatorCompletedWoIdOptions(operatorCompletedWoIdsArray);
  }

  const getOperatorData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('operators')) {
        response = cacheItems['operators'];
      } else {
        response = await UserService.getUsers('', '', '', '', '', 'Operator');; // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            operators: response,
        }));
      }
      if (response) {
        var operatorArray = [];
        response?.map((eachOperator, index) => {
          if(eachOperator?.active) {
              operatorArray[index] = {
                  key: eachOperator?.id,
                  value: eachOperator.first_name + (eachOperator.middle_name ? " " + eachOperator.middle_name : '') + " " + eachOperator.last_name,
                  disabled: eachOperator?.active ? false : true,
              }
          }
        });
        setOperatorDropdown(operatorArray);
      }
  }

  const getOperatorJobSheet = async(operatorId = '', startDate = '', endDate = '') => {
      const data = await ReportService.getOperatorJobSheet(operatorId, startDate, endDate);
      setOperatorJobSheet(Object.values(data));
  }

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    if (returnTab) {
      setValue(Number(returnTab));
      if (Number(returnTab) == 1) {
        setShowCompletedTab(true);
      } else {
        setShowCompletedTab(false);
      }
    }
    getCustomersData();
    getSizesData();

    if (loggedUserRole == 'Operator') {
      getOperatorInProgWoIds();
      getOperatorCompletedWoIds();

      getCurrentRunningJobs();
      getOperatorScheduleWo();
      getOperatorCompletedScheduleWo(page_no, page_size, sort_column, sort_column_value, search_value, true, operatorCompletedSearchStartedDate);
    } else if (loggedUserRole == 'Admin') {
      getAdminInProgWoIds();
      getAdminCompletedWoIds();

      getOperatorsUserData();
      getAdminNewInProgressScheduleWorkOrders(false);
      getAdminCompletedScheduleWorkOrders(false);
      getOperatorData();
    }

    getOperatorJobSheet(operatorId, dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '', dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '');

  }, [newStatus, inProgressStatus]);

  const reportsubmitHandler = () => {
      setReportSearchLoading(true);
      const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
      const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
      getOperatorJobSheet(operatorId, sDate, eDate);
      setReportSearchLoading(false);
  }

  const clearFilters = () => {
      if (loggedUserRole == 'Admin') {
          setOperatorId('');
      }
      setStartDate('');
      setEndDate('');
      setFilter(null);
  }

  const setDate = (dateValue, field) => {
      setFilter(null);
      if (field == 'start_date'){
          setStartDate(dateValue);
      } else if (field == 'end_date') {
          setEndDate(dateValue);
      }
  }

  const generateAndDownloadExcelFile = async(data) => {
      setExcelLoading(true);
      const fileName = 'operator_job_sheet_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');

      const headingRow = worksheet.addRow(['Report: Operator Job Sheet']);
      //Merge cells for supplier row
      worksheet.mergeCells(`A1:I1`);
      // Add headers
      let headerRow;
      if (loggedUserRole == 'Admin') {
          headerRow = worksheet.addRow(['Operator', 'Sr No.', 'Job Card No.', 'Party Name', 'Narration', 'Job Type', 'Size', 'Printing Date', 'Color', 'Quantity', 'Type', 'Duration']);
      } else {
          headerRow = worksheet.addRow(['Sr No.', 'Job Card No.', 'Party Name', 'Narration', 'Job Type', 'Size', 'Printing Date', 'Color', 'Quantity', 'Type', 'Duration']);
      }

      // Set the width for each column
      worksheet.columns = [
          { width: 25 },
          { width: 10 },
          { width: 20 },
          { width: 20 },
          { width: 50 },
          { width: 70 },
          { width: 25 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 15 },
          { width: 20 },
      ];
      headerRow.font = { bold: true, name: 'sans-serif' };
      if (loggedUserRole == 'Admin') {
        for (var i = 2; i < 12; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
        }
      } else {
        for (var i = 1; i < 11; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
        }
      }
      //Add border
      headerRow.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
      };

      let k = 3;
      data.forEach((purchase, index) => {
          let y = 0;
          let rowColor = 'e0f8f7';
          let po = '';
          // Add data
          purchase?.items?.forEach((row, index) => {
              y = y + 1;
              let cellRow;
              if (loggedUserRole == 'Admin') {
                  cellRow = worksheet.addRow([(index == 0 ? purchase?.operator_name : ''), y, row?.wo_id, row?.customer_name, row?.job_type, row?.size, formatDateTime(row?.printing_date), row?.color, Number(row?.quantity), row?.type, row?.duration]);
              } else {
                  cellRow = worksheet.addRow([y, row?.wo_id, row?.customer_name, row?.job_type, row?.size, formatDateTime(row?.printing_date), row?.color, Number(row?.quantity), row?.type, row?.duration]);
              }
              rowColor = y % 2 === 0 ? 'e0f8f7' : 'effbfb';
              if (loggedUserRole == 'Admin') {
                for (var i = 2; i < 12; i++) {
                    cellRow.getCell(i).fill = {
                        type: 'pattern',
                        name: 'sans-serif',
                        pattern: 'solid',
                        fgColor: { argb: rowColor },
                    };
                }
                cellRow.getCell(4).font = { bold: true, name: 'sans-serif' };
                cellRow.getCell(11).font = { bold: true, name: 'sans-serif' };
              } else {
                for (var i = 1; i < 11; i++) {
                    cellRow.getCell(i).fill = {
                        type: 'pattern',
                        name: 'sans-serif',
                        pattern: 'solid',
                        fgColor: { argb: rowColor },
                    };
                }
                cellRow.getCell(3).font = { bold: true, name: 'sans-serif' };
                cellRow.getCell(10).font = { bold: true, name: 'sans-serif' };
              }
              // Add border to the row
              cellRow.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' }
              };
              k += 1;
          });
      });

      // Generate the Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      setExcelLoading(false);
  };

  const jobReportHandlePrint = async() => {
      setPrintLoading(true);
      if(operatorJobSheet){
          const pdfTemplate = PDFTemplate(operatorJobSheet, numberFormat);

          const originalBody = document.body;
          const printWindow = window.open('', '_blank');
          printWindow.document.write(`${pdfTemplate}`);
          printWindow.document.close();
          await new Promise(r => setTimeout(r, 2000));
          printWindow.print();
          printWindow.close();
          document.body = originalBody;
      }
      setPrintLoading(false);
  };

  const onFilterChange = (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
  }

  const getCurrentRunningJobs = async() => {
    let currentRunningJobs = await WoPrintingItemService.getWoPrintingItems('', '', loggedUserId);
    if (currentRunningJobs && currentRunningJobs?.length > 0) {
      setCurrentJobInProgressWoId(currentRunningJobs[0].work_order_id);
      setCurrentJobInProgressWoPrintingItemId(currentRunningJobs[0].id);
      setCurrentJobInProgress(true);
    } else {
      setCurrentJobInProgress(false);
    }
  }

  const getCustomersData = async() => {
    try {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('customers')) {
        response = cacheItems['customers'];
      } else {
        response = await CustomersService.getCustomers(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            customers: response,
        }));
      }
      var customerArray = [];
      if (response) {
        response?.map((eachCustomer, index) => {
          customerArray.push({
            key: eachCustomer?.id,
            value: eachCustomer.name,
          });
        });
      }
      setCustomersOptions(customerArray);
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  }

  const getSizesData = async() => {
    try {
      const group = [Number(systemConstant('GROUP_PRINTING'))];
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('sizes_of_group_printing')) {
        response = cacheItems['sizes_of_group_printing'];
      } else {
        response = await SizeService.getSizes('', '', '', '', '', group); // Fetch sizes_of_group_printing from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            sizes_of_group_printing: response,
        }));
      }

      if (response) {
        var sizesArray = [];
        response?.map((eachSize, index) => {
          sizesArray.push({
            key: eachSize?.id,
            value: eachSize?.name,
          });
        });
        setSizesOptions(sizesArray);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  }

  const getOperatorsUserData = async() => {
    try {
      const response = await UserService.getUsers('', '', '', '', '', 'Operator');
      if (response) {
        var operatorsArray = [];
        response?.map((eachUser, index) => {
          operatorsArray.push({
            key: eachUser.id,
            value: eachUser.first_name + (eachUser.middle_name ? " " + eachUser.middle_name : '') + " " + eachUser.last_name,
          });
        });
        setOperatorOptions(operatorsArray);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  }

  const getOperatorCompletedScheduleWo = async (page, pageSize, sortColumn, sortColumnValue, searchValue, clearSearch=true, jobStartedDate = '') => {
    const statusess = [Number(systemConstant('IN_PROGRESS_STATUS')), Number(systemConstant('COMPLETED_STATUS')), Number(systemConstant('APPROVED_STATUS')), Number(systemConstant('BILLED_STATUS'))];
    let operatorCompletedresponse = '';
    let searchOperator = '';
    if (clearSearch) {
      operatorCompletedresponse = await ScheduleWorkOrderService.getScheduleWorkOrders(page, pageSize, sortColumn, sortColumnValue, searchValue, statusess, '', '', '', '', '', jobStartedDate, loggedUserId);
    } else {
      operatorCompletedresponse = await ScheduleWorkOrderService.getScheduleWorkOrders(page, pageSize, sortColumn, sortColumnValue, searchValue, statusess, operatorCompletedSearchWoId, operatorCompletedSearchCustomer, operatorCompletedSearchSize, operatorCompletedSearchCreatedDate, operatorCompletedSearchCompletionDate, operatorCompletedSearchStartedDate, loggedUserId);
    }

    setOperatorCompletedWorkOrders(operatorCompletedresponse?.data);
    setOperatorCompletedTotalWo(operatorCompletedresponse?.total);
  };

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable?.setOperatorCompletedScheduleWorkOrder(preserveData);
    getOperatorCompletedScheduleWo(page_no, page_size, sortColumn, sortColumnValue, searchWord, false);
  };

  const getOperatorScheduleWo = async (clearSearch=true) => {
    const statuses = [Number(newStatus), Number(inProgressStatus)];
    let response = '';
    let searchOperator = '';
    if (clearSearch) {
      response = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, '', '', '', '', '', '', '');
    } else {
      response = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, operatorSearchWoId, operatorSearchCustomer, operatorSearchSize, operatorSearchCreatedDate, '', '');
    }
    const scheduleWork = [];
    const woIds = [];
    const cloneScheduleWo = [...Object.entries(response)];
    cloneScheduleWo.forEach( async (eachScheduleWo, index) => {
      eachScheduleWo[1]?.wo_printing_items?.forEach((eachItem, i) => {
        if (!eachItem?.ended_at && ((eachItem?.operator_id == loggedUserId) || (!eachItem?.operator_id)) && eachItem?.print_machine?.printing_machine_users?.length > 0) {
          var belongsToOperator = false;
          eachItem?.print_machine?.printing_machine_users?.forEach( async (eachUser, userIndex) => {
            if (eachItem?.print_machine?.printing_machine_users[userIndex]['user_id'] == loggedUserId ) {
              belongsToOperator = true;
            }
          });
          if (belongsToOperator == true) {
            if (!cloneScheduleWo[index][1]['job_detail_ids']) {
              cloneScheduleWo[index][1]['job_detail_ids'] = [];
              cloneScheduleWo[index][1]['job_detail_operators'] = [];
            }
            if (!cloneScheduleWo[index][1]['job_detail_ids'][eachItem?.id]) {
              cloneScheduleWo[index][1]['job_detail_ids'][eachItem?.id] = [];
              if (eachItem?.operator_id && eachItem?.started_at) {
                cloneScheduleWo[index][1]['job_detail_operators'][eachItem?.id] = {"machine_name": eachItem?.print_machine?.name, "started_by_id": eachItem?.operator_user?.id, "started_by":(eachItem?.operator_user?.first_name + ' ' + eachItem?.operator_user?.last_name), "started_at":eachItem?.started_at};
              } else if (!eachItem?.started_at) {
                cloneScheduleWo[index][1]['job_detail_operators'][eachItem?.id] = {"machine_name": eachItem?.print_machine?.name, "operators":""};
              }
            }
            if (eachItem?.print_machine?.printing_machine_users?.length > 0) {
              eachItem?.print_machine?.printing_machine_users?.forEach( async (eachPrintUser, i) => {
                if (!cloneScheduleWo[index][1]['job_detail_ids'][eachItem?.id].includes(eachPrintUser.user_id)) {
                  cloneScheduleWo[index][1]['job_detail_ids'][eachItem?.id].push(eachPrintUser.user_id);
                  let name = (eachPrintUser?.user?.first_name + " " + eachPrintUser?.user?.last_name);
                  let operators = cloneScheduleWo[index][1]['job_detail_operators'][eachItem?.id]['operators'];
                  cloneScheduleWo[index][1]['job_detail_operators'][eachItem?.id]['machine_name'] = eachItem?.print_machine?.name;
                  cloneScheduleWo[index][1]['job_detail_operators'][eachItem?.id]['operators'] = !operators ? name : (operators + ', ' + name);
                }
              });
            }

            if (!woIds.includes(eachScheduleWo[1]?.id) && (eachItem?.print_machine?.printing_machine_users?.length > 0)) {
              eachItem?.print_machine?.printing_machine_users?.forEach( async (eachUser, userIndex) => {
                if (eachItem?.print_machine?.printing_machine_users[userIndex]['user_id'] == loggedUserId ) {
                  scheduleWork.push(cloneScheduleWo[index][1]);
                  woIds.push(eachScheduleWo[1]?.id);
                }
              });
            }
          }
        }
        });
    });
    setScheduleWorkOrders(scheduleWork);
    setTotalData(scheduleWork?.length);
  };

  const getAdminNewInProgressScheduleWorkOrders = async (clearSearch='true') => {
    const statuses = [Number(newStatus), Number(inProgressStatus)];
    let response = '';
    let searchOperator = '';
    if (clearSearch) {
      response = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, '', '', '', '', '', '');
      searchOperator = '';
    } else {
      response = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, newProgressSearchWoId, newProgressSearchCustomer, newProgressSearchSize, newProgressSearchCreatedDate, '', '');
      searchOperator = newProgressSearchOperator;
    }

    const cloneNewInProgressScheduleWo = [...Object.entries(response)];
    const newInProgressscheduleWork = [];
    cloneNewInProgressScheduleWo.forEach( async (eachScheduleWo, index) => {
      if (eachScheduleWo[1]?.wo_printing_items?.length > 0) {
        const uniqueOperatorArray = [];
        var operatorNames = "";
        const operatorIds = [];
        eachScheduleWo[1]?.wo_printing_items?.forEach( async (eachWoPrintItem, i) => {
          if (!cloneNewInProgressScheduleWo[index][1]['job_detail_ids']) {
            cloneNewInProgressScheduleWo[index][1]['job_detail_ids'] = [];
            cloneNewInProgressScheduleWo[index][1]['job_detail_operators'] = [];
          }
          if (!cloneNewInProgressScheduleWo[index][1]['job_detail_ids'][eachWoPrintItem?.id]) {
            cloneNewInProgressScheduleWo[index][1]['job_detail_ids'][eachWoPrintItem?.id] = [];
            if (eachWoPrintItem?.operator_id && eachWoPrintItem?.started_at  && eachWoPrintItem?.ended_at) {
              cloneNewInProgressScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id] = {"machine_name": eachWoPrintItem?.print_machine?.name, "started_by":(eachWoPrintItem?.operator_user?.first_name + ' ' + eachWoPrintItem?.operator_user?.last_name), "started_at":eachWoPrintItem?.started_at, "ended_at":eachWoPrintItem?.ended_at};
              operatorIds.push(eachWoPrintItem?.operator_id);
            } else if (eachWoPrintItem?.operator_id && eachWoPrintItem?.started_at) {
              cloneNewInProgressScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id] = {"machine_name": eachWoPrintItem?.print_machine?.name, "started_by":(eachWoPrintItem?.operator_user?.first_name + ' ' + eachWoPrintItem?.operator_user?.last_name), "started_at":eachWoPrintItem?.started_at};
              operatorIds.push(eachWoPrintItem?.operator_id);
            } else {
              cloneNewInProgressScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id] = {"machine_name": eachWoPrintItem?.print_machine?.name, "operators":""};
              if (eachWoPrintItem?.print_machine?.printing_machine_users?.length > 0) {
                eachWoPrintItem?.print_machine?.printing_machine_users?.forEach( async (eachPrintUser, i) => {
                  if (!uniqueOperatorArray.includes(eachPrintUser.user_id)) {
                    uniqueOperatorArray.push(eachPrintUser.user_id);
                    var name = (eachPrintUser?.user?.first_name + (eachPrintUser?.user?.middle_name ? (" " + eachPrintUser?.user?.middle_name) : '') + " " + eachPrintUser?.user?.last_name);
                    operatorNames = !operatorNames ? name : (operatorNames + '%0A' + name);
                  }
                  if (!cloneNewInProgressScheduleWo[index][1]['job_detail_ids'][eachWoPrintItem?.id].includes(eachPrintUser.user_id)) {
                    cloneNewInProgressScheduleWo[index][1]['job_detail_ids'][eachWoPrintItem?.id].push(eachPrintUser.user_id);
                    operatorIds.push(eachPrintUser.user_id);
                    let name = (eachPrintUser?.user?.first_name + " " + eachPrintUser?.user?.last_name);
                    let operators = cloneNewInProgressScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id]['operators'];
                    cloneNewInProgressScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id]['machine_name'] = eachWoPrintItem?.print_machine?.name;
                    cloneNewInProgressScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id]['operators'] = !operators ? name : (operators + ', ' + name);
                  }
                });
              }
            }
          }
          cloneNewInProgressScheduleWo[index][1]['assigned_operator_ids'] = uniqueOperatorArray;
          cloneNewInProgressScheduleWo[index][1]['assigned_operators'] = operatorNames;
        });
        cloneNewInProgressScheduleWo[index][1]['operator_ids'] = operatorIds;
        if (searchOperator) {
          if (operatorIds?.includes(searchOperator)) {
            newInProgressscheduleWork.push(cloneNewInProgressScheduleWo[index][1]);
          }
        } else {
          newInProgressscheduleWork.push(cloneNewInProgressScheduleWo[index][1]);
        }
      }
    });
    setNewProgressScheduleWo(newInProgressscheduleWork);
    setNewProgressTotalData(newInProgressscheduleWork?.length);

    return newInProgressscheduleWork;
  };

  const getAdminCompletedScheduleWorkOrders = async (clearSearch='true', jobStartedDate = '') => {
    const statuses = [Number(completedStatus), Number(inProgressStatus)];
    let response = '';
    let searchOperator = '';
    let searchStartedDate = '';
    if (clearSearch) {
      response = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, '', '', '', '', '', jobStartedDate);
      searchOperator = '';
      searchStartedDate = jobStartedDate;
    } else {
      response = await ScheduleWorkOrderService.getScheduleWorkOrders('', '', '', '', '', statuses, completedSearchWoId, completedSearchCustomer, completedSearchSize, completedSearchCreatedDate, completedSearchCompletionDate, completedSearchStartedDate);
      searchOperator = completedSearchOperator;
      searchStartedDate = completedSearchStartedDate;
    }

    const cloneCompletedScheduleWo = [...Object.entries(response)];
    const completedScheduleWork = [];

    cloneCompletedScheduleWo.forEach( async (eachCompletedScheduleWo, index) => {
      const operatorIds = [];
      var is_completed_jobs = false;
      eachCompletedScheduleWo[1]?.wo_printing_items?.forEach( async (eachWoPrintItem, i) => {
        if (!cloneCompletedScheduleWo[index][1]['job_detail_ids']) {
          cloneCompletedScheduleWo[index][1]['job_detail_ids'] = [];
          cloneCompletedScheduleWo[index][1]['job_detail_operators'] = [];
        }
        if (!cloneCompletedScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id] && eachWoPrintItem?.operator_id && eachWoPrintItem?.started_at && eachWoPrintItem?.ended_at && searchStartedDate && (formatDate(eachWoPrintItem?.started_at) == searchStartedDate)) {
          is_completed_jobs = true;
          cloneCompletedScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id] = {"machine_name": eachWoPrintItem?.print_machine?.name, "completed_by":(eachWoPrintItem?.operator_user?.first_name + ' ' + eachWoPrintItem?.operator_user?.last_name), "started_at":eachWoPrintItem?.started_at, "ended_at":eachWoPrintItem?.ended_at};
        }
        if (!cloneCompletedScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id] && eachWoPrintItem?.operator_id && eachWoPrintItem?.started_at && eachWoPrintItem?.ended_at && !searchStartedDate) {
          is_completed_jobs = true;
          cloneCompletedScheduleWo[index][1]['job_detail_operators'][eachWoPrintItem?.id] = {"machine_name": eachWoPrintItem?.print_machine?.name, "completed_by":(eachWoPrintItem?.operator_user?.first_name + ' ' + eachWoPrintItem?.operator_user?.last_name), "started_at":eachWoPrintItem?.started_at, "ended_at":eachWoPrintItem?.ended_at};
        }
        operatorIds.push(eachWoPrintItem?.operator_id);
      });
      cloneCompletedScheduleWo[index][1]['operator_ids'] = operatorIds;
      if (is_completed_jobs === true) {
      if (searchOperator) {
        if (operatorIds?.includes(searchOperator)) {
          completedScheduleWork.push(cloneCompletedScheduleWo[index][1]);
        }
      } else {
        completedScheduleWork.push(cloneCompletedScheduleWo[index][1]);
      }
      }
    });

    setCompletedScheduleWo(completedScheduleWork);
    setCompletedTotalData(completedScheduleWork?.length);
  };

  const newProgressSearchHandler = async (e, clearSearch) => {
    e.preventDefault();
    if (clearSearch) {
      setSearchLoading({
        ...searchLoading,
        progress_clear: true,
      });
      setNewProgressSearchWoId('');
      setNewProgressSearchCustomer();
      setNewProgressSearchSize('');
      setNewProgressSearchOperator('');
      setNewProgressSearchCreatedDate('');
      var preserveData = {
        "newProgressWoId": "",
        "newProgressCustomer": "",
        "newProgressSize": "",
        "newProgressOperator": "",
        "newProgressCreatedDate": "",
      };
      preserveTable.setPreserveAdminNewProgressScheduleWorkOrder(preserveData);
    } else {
      setSearchLoading({
        ...searchLoading,
        progress_search: true,
      });
      var preserveData = {
        "newProgressWoId": newProgressSearchWoId,
        "newProgressCustomer": newProgressSearchCustomer,
        "newProgressSize": newProgressSearchSize,
        "newProgressOperator": newProgressSearchOperator,
        "newProgressCreatedDate": newProgressSearchCreatedDate,
      };
      preserveTable.setPreserveAdminNewProgressScheduleWorkOrder(preserveData);
    }
    await getAdminNewInProgressScheduleWorkOrders(clearSearch);
    setSearchLoading({
      ...searchLoading,
      progress_search: false,
      progress_clear: false,
    });
  };

  const completedSearchHandler = async (e, clearSearch) => {
    e.preventDefault();
    if (clearSearch) {
      setSearchLoading({
        ...searchLoading,
        completed_clear: true,
      });
      setCompletedSearchWoId('');
      setCompletedSearchCustomer();
      setCompletedSearchSize('');
      setCompletedSearchOperator('');
      setCompletedSearchCreatedDate('');
      setCompletedSearchCompletionDate('');
      setCompletedSearchStartedDate('');
      var preserveData = {
        "completedWoId": "",
        "completedCustomer": "",
        "completedSize": "",
        "completedOperator": "",
        "completedCompletionDate": "",
        "completedCreatedDate": "",
        "completedStartedDate": "",
      };
      preserveTable.setPreserveAdminCompletedScheduleWorkOrder(preserveData);
    } else {
      setSearchLoading({
        ...searchLoading,
        completed_search: true,
      });
      var preserveData = {
        "completedWoId": completedSearchWoId,
        "completedCustomer": completedSearchCustomer,
        "completedSize": completedSearchSize,
        "completedOperator": completedSearchOperator,
        "completedCompletionDate": completedSearchCompletionDate,
        "completedCreatedDate": completedSearchCreatedDate,
        "completedStartedDate": completedSearchStartedDate,
      };
      preserveTable.setPreserveAdminCompletedScheduleWorkOrder(preserveData);
    }
    await getAdminCompletedScheduleWorkOrders(clearSearch);
    setSearchLoading({
      ...searchLoading,
      completed_search: false,
      completed_clear: false,
    });
  };

  const operatorSearchHandler = async (e, clearSearch) => {
    e.preventDefault();
    if (clearSearch) {
      setSearchLoading({
        ...searchLoading,
        operator_clear: true,
      });
      setOperatorSearchWoId('');
      setOperatorSearchCustomer();
      setOperatorSearchSize('');
      setOperatorSearchCreatedDate('');
    } else {
      setSearchLoading({
        ...searchLoading,
        operator_search: true,
      });
    }
    await getOperatorScheduleWo(clearSearch);
    setSearchLoading({
      ...searchLoading,
      operator_search: false,
      operator_clear: false,
    });
  };

  const operatorCompletedSearchHandler = async (e, clearSearch) => {
    e.preventDefault();
    if (clearSearch) {
      setSearchLoading({
        ...searchLoading,
        operator_completed_clear: true,
      });
      setOperatorCompletedSearchWoId('');
      setOperatorCompletedSearchCustomer();
      setOperatorCompletedSearchSize('');
      setOperatorCompletedSearchCreatedDate('');
      setOperatorCompletedSearchCompletionDate('');
      setOperatorCompletedSearchStartedDate('');
    } else {
      setSearchLoading({
        ...searchLoading,
        operator_completed_search: true,
      });
    }
    await getOperatorCompletedScheduleWo(page_no, page_size, sort_column, sort_column_value, search_value, clearSearch);
    setSearchLoading({
      ...searchLoading,
      operator_completed_search: false,
      operator_completed_clear: false,
    });
  };

  const updateStartedAt = async (id, woId, index, woStatusId, startedAt) => {
    if (currentJobInProgress) {
      setNextJobOpen(true);
    } else {
    const cloneScheduleWorkOrders = [...scheduleWorkOrders];
    cloneScheduleWorkOrders[index]['job_detail_operators'][id]['start_job_loading'] = true;
    try {
      const woPrintingItemData = await WoPrintingItemService.getWoPrintingItems(woId, id);

      const promises = woPrintingItemData.map( async (eachPrintingItemData, key) => {
        const updatePrintingItemData = {
          work_order_id: eachPrintingItemData.work_order_id,
          description: eachPrintingItemData.description,
          wo_printing_type_id: eachPrintingItemData.wo_printing_type_id,
          quantity: eachPrintingItemData.quantity,
          waste_qty: eachPrintingItemData.waste_qty,
          printing_machine_id: eachPrintingItemData.printing_machine_id,
          color_scheme: eachPrintingItemData.color_scheme,
          no_sets: eachPrintingItemData.no_sets,
          printing_rate: eachPrintingItemData.printing_rate,
          amount: eachPrintingItemData.amount,
          markup_pct: eachPrintingItemData.markup_pct,
          free_amount: eachPrintingItemData.free_amount,
          total: eachPrintingItemData.total,
          is_lamination: eachPrintingItemData.is_lamination,
          is_approved: eachPrintingItemData.is_approved,
          operator_id: loggedUserId,
          completed_date: eachPrintingItemData.completed_date,
          started_at: true,
          ended_at: eachPrintingItemData.ended_at,
        };
        return WoPrintingItemService.updateWoPrintingItem(eachPrintingItemData?.id, updatePrintingItemData);
      });
      const responses = await Promise.all(promises);

      if (responses) {
        if (woStatusId === Number(newStatus) && !startedAt) {
          var data = {'started_at': true};
          const res = await WorkOrderService.updateWorkOrderStatus(woId, data);
          var updateStatusId = {'status_id': inProgressStatus};
          const resp = await WorkOrderService.updateWorkOrderStatus(woId, updateStatusId);
        }
        cloneScheduleWorkOrders[index]['job_detail_operators'][id]['start_job_loading'] = false;
        setScheduleWorkOrders(scheduleWorkOrders);
        if (loggedUserRole == 'Operator') {
          await getOperatorScheduleWo(false);
          await getCurrentRunningJobs();
          reportsubmitHandler();
        } else if (loggedUserRole == 'Admin') {
          await getAdminNewInProgressScheduleWorkOrders();
        }
        openAlertSB({color: 'success', icon: 'check', msg: 'Printing Job Started Successfully', title: 'Schedule Work Order'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    }
  };

  const updateEndedAt = async (id, woId) => {
    try {
      setEndedAtLoading(true);
      const woPrintingItemData = await WoPrintingItemService.getWoPrintingItems(woId, id);

      const promises = woPrintingItemData.map( async (eachPrintingItemData, key) => {
        const updatePrintingItemData = {
          work_order_id: eachPrintingItemData.work_order_id,
          description: eachPrintingItemData.description,
          wo_printing_type_id: eachPrintingItemData.wo_printing_type_id,
          quantity: eachPrintingItemData.quantity,
          waste_qty: eachPrintingItemData.waste_qty,
          printing_machine_id: eachPrintingItemData.printing_machine_id,
          color_scheme: eachPrintingItemData.color_scheme,
          no_sets: eachPrintingItemData.no_sets,
          printing_rate: eachPrintingItemData.printing_rate,
          amount: eachPrintingItemData.amount,
          markup_pct: eachPrintingItemData.markup_pct,
          free_amount: eachPrintingItemData.free_amount,
          total: eachPrintingItemData.total,
          is_lamination: eachPrintingItemData.is_lamination,
          is_approved: eachPrintingItemData.is_approved,
          operator_id: eachPrintingItemData.operator_id,
          completed_date: true,
          started_at: eachPrintingItemData.started_at,
          ended_at: true,
        };
        return WoPrintingItemService.updateWoPrintingItem(eachPrintingItemData?.id, updatePrintingItemData);
      });
      const responses = await Promise.all(promises);

      if (responses) {
        const printing = await WoPrintingItemService.getWoPrintingItems(woId);
        let isScheduleWoCompleted = true;
        printing?.map((eachPrinting, index) => {
          if (eachPrinting?.id !== id && !eachPrinting?.ended_at) {
            isScheduleWoCompleted = false;
          }
        });
        if (isScheduleWoCompleted === true) {
          var data = {'ended_at': true};
          const res = await WorkOrderService.updateWorkOrderStatus(woId, data);
          const completedStatus = await WorkOrderService.updateWorkOrderStatus(woId, {status_id: systemConstant('COMPLETED_STATUS')});
        }
        if (loggedUserRole == 'Operator') {
          await getOperatorScheduleWo(false);
          await getCurrentRunningJobs();
          reportsubmitHandler();
        } else if (loggedUserRole == 'Admin') {
          await getAdminNewInProgressScheduleWorkOrders();
          await getAdminCompletedScheduleWorkOrders();
        }
        openAlertSB({color: 'success', icon: 'check', msg: 'Printing Job Ended Successfully', title: 'Schedule Work Order'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        setSelectedId('');
        setWorkOrderId('');
        handleClose();
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const markCompleteScheduleWorkOrder = async (woId) => {
    try {
      setMarkCompleteLoading(true);
      const woPrintingItemData = await WoPrintingItemService.getWoPrintingItems(woId);

      const promises = woPrintingItemData.map( async (eachPrintingItemData, key) => {
        const updatePrintingItemData = {
          work_order_id: eachPrintingItemData.work_order_id,
          description: eachPrintingItemData.description,
          wo_printing_type_id: eachPrintingItemData.wo_printing_type_id,
          quantity: eachPrintingItemData.quantity,
          waste_qty: eachPrintingItemData.waste_qty,
          printing_machine_id: eachPrintingItemData.printing_machine_id,
          color_scheme: eachPrintingItemData.color_scheme,
          no_sets: eachPrintingItemData.no_sets,
          printing_rate: eachPrintingItemData.printing_rate,
          amount: eachPrintingItemData.amount,
          markup_pct: eachPrintingItemData.markup_pct,
          free_amount: eachPrintingItemData.free_amount,
          total: eachPrintingItemData.total,
          is_lamination: eachPrintingItemData.is_lamination,
          is_approved: eachPrintingItemData.is_approved,
          operator_id: eachPrintingItemData.operator_id ? eachPrintingItemData.operator_id : loggedUserId,
          completed_date: true,
          started_at: eachPrintingItemData.started_at ? eachPrintingItemData.started_at : true,
          ended_at: eachPrintingItemData.ended_at ? eachPrintingItemData.ended_at : true,
        };
        return WoPrintingItemService.updateWoPrintingItem(eachPrintingItemData?.id, updatePrintingItemData);
      });
      const responses = await Promise.all(promises);

      if (responses) {
        const woData = await WorkOrderService.showWorkOrder(woId);
        if (woData?.started_at === null) {
          var data = {'started_at': true};
          const res = await WorkOrderService.updateWorkOrderStatus(woId, data);
        }
        var data = {'ended_at': true};
        const res = await WorkOrderService.updateWorkOrderStatus(woId, data);
        const completedStatus = await WorkOrderService.updateWorkOrderStatus(woId, {status_id: systemConstant('COMPLETED_STATUS')});

        if (loggedUserRole == 'Admin') {
          await getAdminNewInProgressScheduleWorkOrders();
          await getAdminCompletedScheduleWorkOrders(false);
          reportsubmitHandler();
        }
        openAlertSB({color: 'success', icon: 'check', msg: 'Schedule Work Order Marked As Completed Successfully', title: 'Schedule Work Order'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        setSelectedId('');
        handleClose();
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  }

  const updateCompletedDate = async (woId) => {
    if (!updateCompletedDateValue) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Completed Date is mandatory.", title: 'Schedule Work Order'});
    } else {
      const woData = await WorkOrderService.showWorkOrder(woId);

      const diffInMs = Math.abs(new Date(woData?.completed_date) - new Date(updateCompletedDateValue));
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

      let days = 0;
      if (woData?.completed_date > updateCompletedDateValue) {
        days = 0 - diffInDays;
      } else {
        days = 0 + diffInDays;
      }

      if (days !== 0) {
        setUpdateCompletedDateLoading(true);
        try {
          const printing = await WoPrintingItemService.getWoPrintingItems(woId);

          const promises = printing.map( async (eachPrinting, index) => {

            var printStartedAt = new Date(eachPrinting?.started_at);
            printStartedAt.setDate(printStartedAt.getDate() + days);
            printStartedAt = dayjs(printStartedAt).format('YYYY-MM-DD HH:mm:ss');

            var printEndedAt = new Date(eachPrinting?.ended_at);
            printEndedAt.setDate(printEndedAt.getDate() + days);
            printEndedAt = dayjs(printEndedAt).format('YYYY-MM-DD HH:mm:ss');

            var printCompletedDate = new Date(eachPrinting?.completed_date);
            printCompletedDate.setDate(printCompletedDate.getDate() + days);
            printCompletedDate = dayjs(printCompletedDate).format('YYYY-MM-DD');

            const eachPrintingData = {
              work_order_id: eachPrinting?.work_order_id,
              description: eachPrinting?.description,
              wo_printing_type_id: eachPrinting?.wo_printing_type_id,
              quantity: eachPrinting?.quantity,
              waste_qty: eachPrinting?.waste_qty,
              printing_machine_id: eachPrinting?.printing_machine_id,
              color_scheme: eachPrinting?.color_scheme,
              no_sets: eachPrinting?.no_sets,
              printing_rate: eachPrinting?.printing_rate,
              amount: eachPrinting?.amount,
              markup_pct: eachPrinting?.markup_pct,
              free_amount: eachPrinting?.free_amount,
              total: eachPrinting?.total,
              is_lamination: eachPrinting?.is_lamination,
              is_approved: eachPrinting?.is_approved,
              operator_id: eachPrinting?.operator_id,
              completed_date: printCompletedDate,
              started_at: printStartedAt,
              ended_at: printEndedAt,
            };
            return WoPrintingItemService.updateWoPrintingItem(eachPrinting?.id, eachPrintingData);
          });
          const responses = await Promise.all(promises);

          var woStartedAt = new Date(woData?.started_at);
          woStartedAt.setDate(woStartedAt.getDate() + days);
          woStartedAt = dayjs(woStartedAt).format('YYYY-MM-DD HH:mm:ss');

          var woEndedAt = new Date(woData?.ended_at);
          woEndedAt.setDate(woEndedAt.getDate() + days);
          woEndedAt = dayjs(woEndedAt).format('YYYY-MM-DD HH:mm:ss');

          const data = {
            customer_id: woData?.customer_id,
            hsn_code_id: woData?.hsn_code_id,
            customer_gstin: woData?.customer_gstin,
            job_type: woData?.job_type,
            size_id: woData?.size_id,
            release_date: woData?.release_date,
            delivery_date: woData?.delivery_date,
            delivery_time: woData?.delivery_time,
            instruction: woData?.instruction,
            is_show_instruction: woData?.is_show_instruction,
            completed_date: updateCompletedDateValue,
            cut_size_id: woData?.cut_size_id,
            amount: woData?.amount,
            total: woData?.total,
            is_with_bill: woData?.is_with_bill,
            payment_type: woData?.payment_type,
            status_id: woData?.status_id,
            vat: woData?.vat,
            state_gst: woData?.state_gst,
            central_gst: woData?.central_gst,
            sales_user_id: woData?.sales_user_id,
            sequence: woData?.sequence,
            operator_id: woData?.operator_id,
            started_at: woStartedAt,
            ended_at: woEndedAt,
          };
          const res = await WorkOrderService.updateWorkOrder(woId, data);
          if (res) {
            openAlertSB({color: 'success', icon: 'check', msg: 'Completed Date Successfully Updated', title: 'Schedule Work Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
            if (loggedUserRole == 'Admin') {
                await getAdminCompletedScheduleWorkOrders(false);
                reportsubmitHandler();
            }
            setSelectedId('');
            setUpdateCompletedDateLoading(false);
            handleClose();
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
        }
      } else {
        openAlertSB({color: 'warning', icon: 'star', msg: "Selected completed date is same as work order completed date.", title: 'Schedule Work Order'});
      }
    }
  }

  const approveScheduleWorkOrder = async (id, isWithBill, index) => {
    const cloneCompletedScheduleWo = [...completedScheduleWo];
    cloneCompletedScheduleWo[index]['approve_wo_loading'] = true;
    try {
      var res = '';
      if (isWithBill) {
        res = await WorkOrderService.updateWorkOrderStatus(id, {status_id: systemConstant('BILLED_STATUS')});
      } else {
        res = await WorkOrderService.updateWorkOrderStatus(id, {status_id: systemConstant('APPROVED_STATUS')});
      }
      if (res) {
        openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Schedule Work Order'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        if (loggedUserRole == 'Admin') {
          await getAdminCompletedScheduleWorkOrders(false);
        }
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const rejectScheduleWorkOrder = async (woId) => {
    try {
      setRejectLoading(true);
      const printing = await WoPrintingItemService.getWoPrintingItems(woId);
      const promises = printing.map( async (eachPrinting, index) => {
        const eachPrintingData = {
          work_order_id: eachPrinting.work_order_id,
          description: eachPrinting.description,
          wo_printing_type_id: eachPrinting.wo_printing_type_id,
          quantity: eachPrinting.quantity,
          waste_qty: eachPrinting.waste_qty,
          printing_machine_id: eachPrinting.printing_machine_id,
          color_scheme: eachPrinting.color_scheme,
          no_sets: eachPrinting.no_sets,
          printing_rate: eachPrinting.printing_rate,
          amount: eachPrinting.amount,
          markup_pct: eachPrinting.markup_pct,
          free_amount: eachPrinting.free_amount,
          total: eachPrinting.total,
          is_lamination: eachPrinting.is_lamination,
          is_approved: eachPrinting.is_approved,
          operator_id: eachPrinting.operator_id,
          completed_date: null,
          started_at: eachPrinting.started_at,
          ended_at: null,
        };
        return WoPrintingItemService.updateWoPrintingItem(eachPrinting.id, eachPrintingData);
      });
      const responses = await Promise.all(promises);

      var data = {'ended_at': null};
      const response = await WorkOrderService.updateWorkOrderStatus(woId, data);
      const res = await WorkOrderService.updateWorkOrderStatus(woId, {status_id: systemConstant('IN_PROGRESS_STATUS')});
      if (res) {
        if (loggedUserRole == 'Admin') {
            await getAdminNewInProgressScheduleWorkOrders(false);
            await getAdminCompletedScheduleWorkOrders(false);
            reportsubmitHandler();
        }
        openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Schedule Work Order'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        setSelectedId('');
        handleClose();
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const getPDFData = async(id, showAllPrintingItems, woPrintingItemId) => {
    const color = await ColorServices.getColors();
    const colorArray = [];
    if (color) {
      color?.map((eachColor, index) => {
        colorArray[eachColor.id] = eachColor.name.charAt(0);
      });
    }
    const material = await WoMaterialItemService.getWoMaterialItems(id);
    var printing = '';
    if (showAllPrintingItems === 'operator' || showAllPrintingItems === 'operator_completed') {
      printing = await WoPrintingItemService.getWoPrintingItems(id, woPrintingItemId);
    } else {
      printing = await WoPrintingItemService.getWoPrintingItems(id);
    }

    printing?.map((eachPrinting, index) => {
      let color = eachPrinting.color_scheme.split(',').map(Number);
      printing[index]['color_length'] = color.length + (color.length > 1 ? ' Colors' : ' Color');
      let color_scheme = '';
      color?.map((eachColor, index) => {
        color_scheme += (index != 0 ? ' + ' : '') + colorArray[eachColor];
      });
      printing[index]['color_scheme'] = color_scheme;
    });

    const stock = await WoStockItemService.getWoStockItems(id);
    const other = await WoOtherItemService.getWoOtherItems(id);
    const data = await WorkOrderService.showWorkOrder(id);
    return {
      order: data,
      material: material,
      printing: printing,
      stock: stock,
      other: other
    };
  }

  const handlePrint = async (id, index, showAllPrintingItems, woPrintingItemId='', woPrintingItemIndex='') => {
    if (currentJobInProgress && (showAllPrintingItems != 'operator_completed') && ((currentJobInProgressWoId != id) || (currentJobInProgressWoPrintingItemId != woPrintingItemId))) {
      setNextPrintJobOpen(true);
    } else {
    let cloneOperatorCompletedWorkOrders = [];
    let cloneScheduleWorkOrders = [];
    let cloneNewProgressScheduleWo = [];
    let cloneCompletedScheduleWo = [];
    if (showAllPrintingItems === 'operator_completed') {
      cloneOperatorCompletedWorkOrders = [...operatorCompletedWorkOrders];
      cloneOperatorCompletedWorkOrders[index]['wo_printing_items'][woPrintingItemIndex]['job_icon_loading'] = true;
    } else if (showAllPrintingItems === 'operator') {
      cloneScheduleWorkOrders = [...scheduleWorkOrders];
      cloneScheduleWorkOrders[index]['job_detail_operators'][woPrintingItemId]['job_icon_loading'] = true;
    } else if (showAllPrintingItems === 'newInProgress') {
      cloneNewProgressScheduleWo = [...newProgressScheduleWo];
      cloneNewProgressScheduleWo[index]['job_icon_loading'] = true;
    } else if (showAllPrintingItems === 'completed'){
      cloneCompletedScheduleWo = [...completedScheduleWo];
      cloneCompletedScheduleWo[index]['job_icon_loading'] = true;
    }
    const response = await getPDFData(id, showAllPrintingItems, woPrintingItemId);
    const companyName = systemConstant('COMPANY_NAME');
    if(response){
        const pdfTemplate = jobCardPDFTemplate(companyName, response.order, response.material, response.printing, response.stock, response.other, noColorPrintingMachineId);
        const originalBody = document.body;
        const printWindow = window.open('', '_blank');
        if (showAllPrintingItems === 'operator_completed') {
          cloneOperatorCompletedWorkOrders[index]['wo_printing_items'][woPrintingItemIndex]['job_icon_loading'] = false;
          setScheduleWorkOrders(cloneOperatorCompletedWorkOrders);
        } else if (showAllPrintingItems === 'operator') {
          cloneScheduleWorkOrders[index]['job_detail_operators'][woPrintingItemId]['job_icon_loading'] = false;
          setScheduleWorkOrders(cloneScheduleWorkOrders);
        } else if (showAllPrintingItems === 'newInProgress') {
          cloneNewProgressScheduleWo[index]['job_icon_loading'] = false;
          setNewProgressScheduleWo(cloneNewProgressScheduleWo);
        } else if (showAllPrintingItems === 'completed') {
          cloneCompletedScheduleWo[index]['job_icon_loading'] = false;
          setCompletedScheduleWo(cloneCompletedScheduleWo);
        }
        printWindow.document.write(`${pdfTemplate}`);
        printWindow.document.close();
        await new Promise(r => setTimeout(r, 2000));
        printWindow.print();
        printWindow.close();
        document.body = originalBody;
    }
    }
  };

  const changeHandler = (fieldValue, index) => {
    const cloneNewProgressScheduleWo = [...newProgressScheduleWo];
    cloneNewProgressScheduleWo[index]['sequence'] = fieldValue;
    cloneNewProgressScheduleWo[index]['sequence_changed'] = true;
    setNewProgressScheduleWo(cloneNewProgressScheduleWo);
  };

  const submitHandler = async (e, index='') => {
    e.preventDefault();
    if (index) {
      const cloneNewProgressScheduleWo = [...newProgressScheduleWo];
      cloneNewProgressScheduleWo[index]['sequence_loading'] = true;
      setNewProgressScheduleWo(cloneNewProgressScheduleWo);
    } else {
      setSaveBtnLoading(true);
    }
    try {
      const promises = newProgressScheduleWo.map( async (eachScheduleWo, index) => {
        if (eachScheduleWo?.sequence_changed) {
          const scheduleWoData = {
            customer_id: eachScheduleWo.customer_id,
            hsn_code_id: eachScheduleWo.hsn_code_id,
            customer_gstin: eachScheduleWo.customer_gstin,
            job_type: eachScheduleWo.job_type,
            size_id: eachScheduleWo.size_id,
            release_date: eachScheduleWo.release_date,
            delivery_date: eachScheduleWo.delivery_date,
            delivery_time: eachScheduleWo.delivery_time,
            instruction: eachScheduleWo.instruction,
            is_show_instruction: eachScheduleWo.is_show_instruction,
            completed_date: eachScheduleWo.completed_date,
            cut_size_id: eachScheduleWo.cut_size_id,
            amount: eachScheduleWo.amount,
            total: eachScheduleWo.total,
            is_with_bill: eachScheduleWo.is_with_bill,
            payment_type: eachScheduleWo.payment_type,
            status_id: eachScheduleWo.status_id,
            vat: eachScheduleWo.vat,
            state_gst: eachScheduleWo.state_gst,
            central_gst: eachScheduleWo.central_gst,
            sales_user_id: eachScheduleWo.sales_user_id,
            sequence: (eachScheduleWo.sequence && eachScheduleWo.sequence < 999) ? eachScheduleWo.sequence : 999,
            operator_id: eachScheduleWo.operator_id,
            started_at: eachScheduleWo.started_at,
            ended_at: eachScheduleWo.ended_at,
          };
          return WorkOrderService.updateWorkOrder(eachScheduleWo.id, scheduleWoData);
        }
        return null;
      });
      const responses = await Promise.all(promises);
      const res = await getAdminNewInProgressScheduleWorkOrders(false);
      if (res) {
        setSaveBtnLoading(false);
        openAlertSB({color: 'success', icon: 'check', msg: 'Schedule Work Order Updated Successfully', title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Schedule Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Schedule Work Order'};
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  let allRows = [];
  const operatorNewProgressRows = [];
  const operatorCompletedRows = [];
  if (loggedUserRole == 'Operator') {
    scheduleWorkOrders?.map((eachScheduleWo, index) => {
        const eachData = {
          id: <MDTypography color={workOrderPermission.edit ? "info" : "text"} component={Link} to={workOrderPermission.edit ? `/manage-work-orders/edit?id=${eachScheduleWo.id}` : ''} variant="body2">
                  {eachScheduleWo?.id}
                </MDTypography>,
          customer_name: (<MDTypography color={customerPermission.edit ? "info" : "text"} component={Link} to={customerPermission.edit ? `/manage-customers/edit?id=${eachScheduleWo?.customer_id}` : ''} display="block" variant="body2">
            {eachScheduleWo?.customer_name}
            </MDTypography>
          ),
          job_type_and_status: (<MDTypography display="block" variant="body2">
                <MDTypography display="block" variant="body2">
                  {eachScheduleWo?.job_type}
                </MDTypography>
                <MDTypography display="block" variant="body2" color={eachScheduleWo?.status_id == systemConstant('NEW_STATUS') ? "error" : "warning"}>
                  {'(' + eachScheduleWo?.name + ')'}
                </MDTypography>
              </MDTypography>
          ),
          size: (<MDTypography display="block" variant="body2">
            {eachScheduleWo?.size?.name}
            </MDTypography>
          ),
          delivery_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
            {eachScheduleWo ? dayjs(eachScheduleWo?.delivery_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          release_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
            {eachScheduleWo ? dayjs(eachScheduleWo?.release_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          job_details : (
            <>
            {
              (() => {
                let flag = 0;
                return eachScheduleWo?.job_detail_operators?.map((jobDetail, i) => {
                  flag++;
                  return (<>
                    {flag > 1 && <Divider sx={{ opacity: 1, margin:"5px", bgcolor: "#C0C0C0" }} />}
                    {((jobDetail?.started_by && jobDetail?.started_at && !jobDetail?.ended_at && (jobDetail?.started_by_id == loggedUserId)) ?
                      <MDBox sx={{ height: "65px", alignItems: "center" }}>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Printing Machine: "}</b>{jobDetail?.machine_name}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Started By: "}</b>{jobDetail?.started_by}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Started At: "}</b>{formatDateTime(jobDetail?.started_at)}
                        </MDTypography>
                      </MDBox> :
                      ((!jobDetail?.started_at && !jobDetail?.ended_at && eachScheduleWo?.job_detail_ids[i]?.includes(Number(loggedUserId))) &&
                        <MDBox display="flex" sx={{height:"40px", alignItems: "center"}}>
                          <MDTypography sx={{width: "270px"}} display="block" variant="button">
                            <b>{"Printing Machine: "}</b>{jobDetail?.machine_name}
                          </MDTypography>
                        </MDBox>)
                    )
                    }
                  </>)
                })
              })()
            }
            </>
          ),
          action: (
                <>
                  {
                    (() => {
                      let printFlag = 0;
                      return eachScheduleWo?.job_detail_operators?.map((jobDetail, i) => {
                        printFlag++;
                        return (<>
                          {printFlag > 1 && <Divider sx={{ opacity: 1, margin:"5px", bgcolor: "#C0C0C0" }} />}
                          {((jobDetail?.started_by && jobDetail?.started_at && !jobDetail?.ended_at && (jobDetail?.started_by_id == loggedUserId)) ?
                            <MDBox display="flex" sx={{ height: "65px", alignItems: "center"}}>
                              <MDTypography mr={1} mt={1} component={Link} onClick={ async () => await handlePrint(eachScheduleWo?.id, index, 'operator', i)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                                  {jobDetail?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                              </MDTypography>
                              <MDTypography mt={1} component={Link} onClick={() => handleClickEndedAtOpen(i, eachScheduleWo?.id)} title="Stop" display="block" variant="body2" color="error">
                                <Icon fontSize="small">stop_circle_icon</Icon>
                              </MDTypography>
                            </MDBox> :
                            ((!jobDetail?.started_at && !jobDetail?.ended_at) &&
                              <MDBox display="flex" sx={{height:"40px"}}>
                                <MDTypography mr={1} mt={1} component={Link} onClick={ async () => await handlePrint(eachScheduleWo?.id, index, 'operator', i)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                                    {jobDetail?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                                </MDTypography>
                                <MDTypography mt={1} component={Link} onClick={() => updateStartedAt(i, eachScheduleWo?.id, index, eachScheduleWo?.status_id, eachScheduleWo?.started_at)} title="Start" display="block" variant="body2" color="success">
                                  {jobDetail?.start_job_loading ?
                                    <MDCircularProgress
                                      size={20}
                                      color="info"
                                    /> :
                                    <Icon fontSize="small">play_circle_filled_icon</Icon>
                                  }
                                </MDTypography>
                              </MDBox>
                          ))
                          }
                        </>)
                      })
                    })()
                  }
                  {renderAlertSB}
                </>
              ),
              subRows: [],
              expanded: false,
        };
        operatorNewProgressRows[index] = eachData;
    });

    operatorNewProgressRows?.map((row, i) => {
        operatorNewProgressRowExpanded['' + i] = false;
    });

    operatorCompletedWorkOrders?.map((eachScheduleWo, index) => {
        const eachData = {
          id: <MDTypography color={workOrderPermission.edit ? "info" : "text"} component={Link} to={workOrderPermission.edit ? `/manage-work-orders/edit?id=${eachScheduleWo.id}` : ''} variant="body2">
                  {eachScheduleWo?.id}
                </MDTypography>,
          customer_name: (<MDTypography color={customerPermission.edit ? "info" : "text"} component={Link} to={customerPermission.edit ? `/manage-customers/edit?id=${eachScheduleWo?.customer_id}` : ''} display="block" variant="body2">
            {eachScheduleWo?.customer_name}
            </MDTypography>
          ),
          job_type_and_status: (<MDTypography display="block" variant="body2">
                <MDTypography display="block" variant="body2">
                  {eachScheduleWo?.job_type}
                </MDTypography>
                <MDTypography display="block" variant="body2" color={(eachScheduleWo?.status_id == systemConstant('IN_PROGRESS_STATUS') ? "warning" : "success")}>
                  {'(' + eachScheduleWo?.name + ')'}
                </MDTypography>
              </MDTypography>
          ),
          size: (<MDTypography display="block" variant="body2">
            {eachScheduleWo?.size?.name}
            </MDTypography>
          ),
          delivery_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
            {eachScheduleWo ? dayjs(eachScheduleWo?.delivery_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          release_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
            {eachScheduleWo ? dayjs(eachScheduleWo?.release_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          job_details : (
            <>
            {
              (() => {
                let flag = 0;
                return eachScheduleWo?.job_detail_operators?.map((jobDetail, i) => {
                  flag++;
                  return (<>
                    {flag > 1 && <Divider sx={{ opacity: 1, margin:"5px", bgcolor: "#C0C0C0" }} />}
                    {((jobDetail?.started_by && jobDetail?.started_at && !jobDetail?.ended_at && (jobDetail?.started_by_id == loggedUserId)) ?
                      <MDBox sx={{ height: "65px", alignItems: "center" }}>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Printing Machine: "}</b>{jobDetail?.machine_name}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Started By: "}</b>{jobDetail?.started_by}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Started At: "}</b>{formatDateTime(jobDetail?.started_at)}
                        </MDTypography>
                      </MDBox> :
                      ((!jobDetail?.started_at && !jobDetail?.ended_at && eachScheduleWo?.job_detail_ids[i]?.includes(Number(loggedUserId))) &&
                        <MDBox display="flex" sx={{height:"40px", alignItems: "center"}}>
                          <MDTypography sx={{width: "270px"}} display="block" variant="button">
                            <b>{"Printing Machine: "}</b>{jobDetail?.machine_name}
                          </MDTypography>
                        </MDBox>)
                    )
                    }
                  </>)
                })
              })()
            }
            </>
          ),
          action: (
                <>
                  {
                    (() => {
                      let printFlag = 0;
                      return eachScheduleWo?.job_detail_operators?.map((jobDetail, i) => {
                        printFlag++;
                        return (<>
                          {printFlag > 1 && <Divider sx={{ opacity: 1, margin:"5px", bgcolor: "#C0C0C0" }} />}
                          {((jobDetail?.started_by && jobDetail?.started_at && !jobDetail?.ended_at && (jobDetail?.started_by_id == loggedUserId)) ?
                            <MDBox display="flex" sx={{ height: "65px", alignItems: "center"}}>
                              <MDTypography mr={1} mt={1} component={Link} onClick={ async () => await handlePrint(eachScheduleWo?.id, index, 'operator', i)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                                  {jobDetail?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                              </MDTypography>
                              <MDTypography mt={1} component={Link} onClick={() => handleClickEndedAtOpen(i, eachScheduleWo?.id)} title="Stop" display="block" variant="body2" color="error">
                                <Icon fontSize="small">stop_circle_icon</Icon>
                              </MDTypography>
                            </MDBox> :
                            ((!jobDetail?.started_at && !jobDetail?.ended_at) &&
                              <MDBox display="flex" sx={{height:"40px"}}>
                                <MDTypography mr={1} mt={1} component={Link} onClick={ async () => await handlePrint(eachScheduleWo?.id, index, 'operator', i)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                                    {jobDetail?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                                </MDTypography>
                                <MDTypography mt={1} component={Link} onClick={() => updateStartedAt(i, eachScheduleWo?.id, index, eachScheduleWo?.status_id, eachScheduleWo?.started_at)} title="Start" display="block" variant="body2" color="success">
                                  {jobDetail?.start_job_loading ?
                                    <MDCircularProgress
                                      size={20}
                                      color="info"
                                    /> :
                                    <Icon fontSize="small">play_circle_filled_icon</Icon>
                                  }
                                </MDTypography>
                              </MDBox>
                          ))
                          }
                        </>)
                      })
                    })()
                  }
                  {renderAlertSB}
                </>
              ),
              subRows: [],
              expanded: false,
        };
        operatorCompletedRows[index] = eachData;
    });

    operatorCompletedRows?.map((row, i) => {
        operatorCompletedRowExpanded['' + i] = false;
    });

    if (showOperatorCompletedTab) {
      allRows = operatorCompletedRows;
    } else {
      allRows = operatorNewProgressRows;
    }
  }

  const adminNewProgressRows = [];
  const adminCompletedRows = [];

  if (loggedUserRole == 'Admin') {
    newProgressScheduleWo?.map((eachScheduleWo, index) => {
        const eachData = {
          id: <MDTypography color={workOrderPermission.edit ? "info" : "text"} component={Link} to={workOrderPermission.edit ? `/manage-work-orders/edit?id=${eachScheduleWo.id}&ref=${encodeURIComponent(`/manage-schedule-work-orders`)}` : ''} variant="body2">
                  {eachScheduleWo?.id}
                </MDTypography>,
          customer_name: (<MDTypography color={customerPermission.edit ? "info" : "text"} component={Link} to={customerPermission.edit ? `/manage-customers/edit?id=${eachScheduleWo?.customer_id}` : ''} display="block" variant="body2">
            {eachScheduleWo?.customer_name}
            </MDTypography>
          ),
          job_type_and_status: (<MDTypography display="block" variant="body2">
                <MDTypography display="block" variant="body2">
                  {eachScheduleWo?.job_type}
                </MDTypography>
                <MDTypography display="block" variant="body2" color={eachScheduleWo?.status_id == systemConstant('NEW_STATUS') ? "error" : "warning"}>
                  {'(' + eachScheduleWo?.name + ')'}
                </MDTypography>
              </MDTypography>
          ),
          size: (<MDTypography display="block" variant="body2">
            {eachScheduleWo?.size?.name}
            </MDTypography>
          ),
          delivery_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
            {eachScheduleWo ? dayjs(eachScheduleWo?.delivery_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          release_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
            {eachScheduleWo ? dayjs(eachScheduleWo?.release_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          job_details: (
            <>
            {
              (() => {
                let flag = 0;
                return eachScheduleWo?.job_detail_operators?.map((jobDetail, i) => {
                  flag++;
                  return (<>
                    {flag > 1 && <Divider sx={{ opacity: 1, margin:"5px", bgcolor: "#C0C0C0" }} />}
                    {(jobDetail?.started_by && jobDetail?.started_at && jobDetail?.ended_at) ?
                      <>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Printing Machine: "}</b>{jobDetail?.machine_name}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Completed By: "}</b>{jobDetail?.started_by}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Started At: "}</b>{formatDateTime(jobDetail?.started_at)}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Ended At: "}</b>{formatDateTime(jobDetail?.ended_at)}
                        </MDTypography>
                      </> :
                      (jobDetail?.started_by && jobDetail?.started_at ?
                      <>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Printing Machine: "}</b>{jobDetail?.machine_name}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Started By: "}</b>{jobDetail?.started_by}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Started At: "}</b>{formatDateTime(jobDetail?.started_at)}
                        </MDTypography>
                      </> :
                      <>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Printing Machine: "}</b>{jobDetail?.machine_name}
                        </MDTypography>
                        <MDTypography sx={{width: "270px"}} display="block" variant="button">
                          <b>{"Operators: "}</b>{jobDetail?.operators}
                        </MDTypography>
                      </>)
                    }
                  </>)
                })
              })()
            }
            </>
            ),
          sequence: (
            <>
            {eachScheduleWo?.status_id == Number(newStatus) ?
            <MDBox sx={{display: "flex", alignItems: "center"}}>
              <MDBox>
              <MDInput
                type="text"
                value={(eachScheduleWo?.sequence != 999) ? (eachScheduleWo?.sequence) : '' }
                name="sequence"
                inputProps={{'maxLength':3, 'pattern':'^([1-9][0-9]{0,2})|([0]{1}[1-9]{1}[0]{1})|([0][1-9]{1,2})|([0]{1,2}[1-9])$'}}
                size="small"
                onChange={(e) => changeHandler(e.target.value, index)}
                sx={{ 'width':'80px' }}
              />
              </MDBox>
              <MDTypography ml={2} mt={1} component={Link} onClick={(e) => submitHandler(e, index)} title="Save" display="block" variant="caption" color="info" fontWeight="medium">
                  {eachScheduleWo?.sequence_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">save_icon</Icon>}
              </MDTypography>
            </MDBox>
            : <MDTypography display="block" variant="body2">
              {(eachScheduleWo?.sequence != 999) ? (eachScheduleWo?.sequence) : ''}
              </MDTypography>}
            </>
          ),
          action: (
                <MDBox display="flex">
                  <MDTypography mr={1} mt={1} component={Link} onClick={ async () => await handlePrint(eachScheduleWo?.id, index, 'newInProgress', '')} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                      {eachScheduleWo?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                  </MDTypography>
                  <MDTypography mt={1} component={Link} onClick={() => handleClickMarkCompleteOpen(eachScheduleWo?.id)} title="Mark as Complete" display="block" variant="caption" color="success" fontWeight="medium">
                      <Icon fontSize="small">fact_check_icon</Icon>
                  </MDTypography>
                  {renderAlertSB}
                </MDBox>
              ),
              subRows: [],
              expanded: false,
        };
        adminNewProgressRows[index] = eachData;
    });

    adminNewProgressRows?.map((row, i) => {
        adminNewProgressRowExpanded['' + i] = false;
    });

    completedScheduleWo?.map((eachScheduleWo, index) => {
        const eachData = {
          id: <MDTypography color={workOrderPermission.edit ? "info" : "text"} component={Link} to={workOrderPermission.edit ? `/manage-work-orders/edit?id=${eachScheduleWo.id}&returnTab=1&ref=${encodeURIComponent(`/manage-schedule-work-orders`)}` : ''} variant="body2">
                  {eachScheduleWo?.id}
                </MDTypography>,
          customer_name: (<MDTypography color={customerPermission.edit ? "info" : "text"} component={Link} to={customerPermission.edit ? `/manage-customers/edit?id=${eachScheduleWo?.customer_id}` : ''} display="block" variant="body2">
            {eachScheduleWo?.customer_name}
            </MDTypography>
          ),
          job_type_and_status: (<MDTypography display="block" variant="body2">
                <MDTypography display="block" variant="body2">
                  {eachScheduleWo?.job_type}
                </MDTypography>
                <MDTypography display="block" variant="body2" color={eachScheduleWo?.status_id == systemConstant('IN_PROGRESS_STATUS') ? "warning" : "success"}>
                  {'(' + eachScheduleWo?.name + ')'}
                </MDTypography>
              </MDTypography>
          ),
          size: (<MDTypography display="block" variant="body2">
            {eachScheduleWo?.size?.name}
            </MDTypography>
          ),
          delivery_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
            {eachScheduleWo ? dayjs(eachScheduleWo?.delivery_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          release_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
            {eachScheduleWo ? dayjs(eachScheduleWo?.release_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          job_details: (
            <>
              {
                (() => {
                  let flag = 0;
                  return eachScheduleWo?.job_detail_operators?.map((jobDetail, i) => {
                    flag++;
                    return (<>
                      {flag > 1 && <Divider sx={{ opacity: 1, margin:"5px", bgcolor: "#C0C0C0" }} />}
                      {(jobDetail?.completed_by && jobDetail?.started_at && jobDetail?.ended_at) &&
                        <>
                          <MDTypography sx={{width: "270px"}} display="block" variant="button">
                            <b>{"Printing Machine: "}</b>{jobDetail?.machine_name}
                          </MDTypography>
                          <MDTypography sx={{width: "270px"}} display="block" variant="button">
                            <b>{"Completed By: "}</b>{jobDetail?.completed_by}
                          </MDTypography>
                          <MDTypography sx={{width: "270px"}} display="block" variant="button">
                            <b>{"Started At: "}</b>{formatDateTime(jobDetail?.started_at)}
                          </MDTypography>
                          <MDTypography sx={{width: "270px"}} display="block" variant="button">
                            <b>{"Ended At: "}</b>{formatDateTime(jobDetail?.ended_at)}
                          </MDTypography>
                        </>
                      }
                    </>)
                  })
                })()
              }
              {
                <>
                  {<Divider sx={{ opacity: 1, margin:"5px", bgcolor: "#C0C0C0" }} />}
                  <MDTypography sx={{marginTop: "2px"}} display="block" variant="button">
                  <b>{"Total WO Time:  "}</b>{(eachScheduleWo?.started_at && eachScheduleWo?.ended_at) ? timeDifference(eachScheduleWo?.started_at, eachScheduleWo?.ended_at) : ''}
                  </MDTypography>
                </>
              }
            </>
          ),
          completed_date: (<MDTypography display="block" variant="body2"  whiteSpace="nowrap">
            {eachScheduleWo?.completed_date ? dayjs(eachScheduleWo?.completed_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
          action: (
                <MDBox display="flex">
                  <MDTypography mr={1} mt={1} component={Link} onClick={ async () => await handlePrint(eachScheduleWo?.id, index, 'completed')} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                      {eachScheduleWo?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                  </MDTypography>
                  {(eachScheduleWo?.status_id == Number(systemConstant('COMPLETED_STATUS'))) &&
                  <>
                  <MDTypography mr={1} mt={1} component={Link} onClick={() => approveScheduleWorkOrder(eachScheduleWo.id, eachScheduleWo.is_with_bill, index)} title="Approve" display="block" variant="body2" color="success">
                  {eachScheduleWo?.approve_wo_loading ?
                    <MDCircularProgress
                      size={20}
                      color="info"
                    /> :
                    <Icon fontSize="small">check_box_icon</Icon>
                  }
                  </MDTypography>
                  <MDTypography mr={1} mt={1} component={Link} onClick={() => handleClickRejectOpen(eachScheduleWo.id)} title="Reject" display="block" variant="body2" color="error">
                    <Icon fontSize="small">cancel_icon</Icon>
                  </MDTypography>
                  <MDTypography mt={1} component={Link} onClick={() => handleClickUpdateCompletedDate(eachScheduleWo.id)} title="Update Completed Date" display="block" variant="body2" color="info">
                    <Icon fontSize="small">restore_icon</Icon>
                  </MDTypography>
                  </>
                  }
                  {renderAlertSB}
                </MDBox>
              ),
              subRows: [],
              expanded: false,
        };
        adminCompletedRows[index] = eachData;
    });

    completedScheduleWo?.map((row, i) => {
        adminCompletedRowExpanded['' + i] = false;
    });

    if (showCompletedTab) {
      allRows = adminCompletedRows;
    } else {
      allRows = adminNewProgressRows;
    }
  }

  // Create a function that will render our row sub components
  const renderInProgressRowSubComponent = React.useCallback(
    ({ row }) => {
      let woJobDetails = newProgressScheduleWo?.[row.index];
      return (
        (() => {
          let flag = 0;
          return woJobDetails?.job_detail_operators?.map((jobDetail, i) => {
            flag++;
            return (
              <MDBox sx={{marginLeft: "8%", marginRight: "17%"}}>
                {flag === 1 &&
                  <>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  <MDBox mt={0.4} sx={{display: "flex", justifyContent:"center"}}>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Job Details:"}</b>
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  <MDBox display="flex" mt={(flag === 1) ? 0.4 : 0}>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      <b>{"Printing Machine"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      <b>{"Operator"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      <b>{"Started At"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      <b>{"Ended At"}</b>
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  </>
                }
                {flag > 1 && <Divider sx={{ opacity: 1, marginTop:"3px", marginBottom:"3px", bgcolor: "#f0f2f5" }} />}
                {(jobDetail?.started_by && jobDetail?.started_at && jobDetail?.ended_at) ?
                  <MDBox display="flex" mt={(flag === 1) ? 0.4 : 0} mb={0.4}>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      {jobDetail?.machine_name}
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      {jobDetail?.started_by}
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      {formatDateTime(jobDetail?.started_at)}
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      {formatDateTime(jobDetail?.ended_at)}
                    </MDTypography>
                  </MDBox> :
                  (jobDetail?.started_by && jobDetail?.started_at ?
                  <MDBox display="flex" mt={(flag === 1) ? 0.4 : 0} mb={0.4}>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      {jobDetail?.machine_name}
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      {jobDetail?.started_by}
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      {formatDateTime(jobDetail?.started_at)}
                    </MDTypography>
                  </MDBox> :
                  <MDBox display="flex" mt={(flag === 1) ? 0.4 : 0} mb={0.4}>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      {jobDetail?.machine_name}
                    </MDTypography>
                    <MDTypography sx={{width: "75%"}} display="block" variant="caption">
                      <b> {""}</b>{jobDetail?.operators}
                    </MDTypography>
                  </MDBox>)
                }
              </MDBox>)
          })
        })()
      )
    },
    [newProgressScheduleWo]
  );

  // Create a function that will render our row sub components
  const renderCompletedRowSubComponent = React.useCallback(
    ({ row }) => {
      let woCompletedJobDetails = completedScheduleWo?.[row.index];
      return (
        (() => {
          let flag = 0;
          return woCompletedJobDetails?.job_detail_operators?.map((jobDetail, i) => {
            flag++;
            return (<MDBox sx={{marginLeft: "8%", marginRight: "14%"}}>
              {flag === 1 &&
                <>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  <MDBox mt={0.4} sx={{display: "flex", justifyContent:"center"}}>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Job Details:"}</b>
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  <MDBox display="flex" mt={(flag === 1) ? 0.4 : 0}>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Printing Machine"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Completed By"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Started At"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Ended At"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Total Time"}</b>
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                </>
              }
              {flag > 1 && <Divider sx={{ opacity: 1, marginTop:"3px", marginBottom:"3px", bgcolor: "#f0f2f5" }} />}
              {(jobDetail?.completed_by && jobDetail?.started_at && jobDetail?.ended_at) &&
                <MDBox display="flex" mt={(flag === 1) ? 0.4 : 0}>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {jobDetail?.machine_name}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {jobDetail?.completed_by}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {formatDateTime(jobDetail?.started_at)}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {formatDateTime(jobDetail?.ended_at)}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {(jobDetail?.started_at && jobDetail?.ended_at) ? timeDifference(jobDetail?.started_at, jobDetail?.ended_at) : ''}
                  </MDTypography>
                </MDBox>
              }
              {(i === (woCompletedJobDetails?.job_detail_operators?.length - 1)) &&
                <>
                  <Divider sx={{ opacity: 1, marginTop:"5px", marginBottom:"5px", bgcolor: "#f0f2f5" }} />
                  <MDTypography sx={{marginTop: "2px", marginBottom: "2px"}} display="block" variant="caption">
                  <b>{"Total WO Time:  "}</b>{(woCompletedJobDetails?.started_at && woCompletedJobDetails?.ended_at) ? timeDifference(woCompletedJobDetails?.started_at, woCompletedJobDetails?.ended_at) : ''}
                  </MDTypography>
                </>
              }
            </MDBox>)
          })
        })()
      );
    },
    [completedScheduleWo]
  );

  // Create a function that will render our row sub components
  const renderOperatorRowSubComponent = React.useCallback(
    ({ row }) => {
      let woOperatorJobDetails = scheduleWorkOrders?.[row.index];
      return (
        (() => {
          let flag = 0;
          return woOperatorJobDetails?.job_detail_operators?.map((jobDetail, i) => {
            flag++;
            return (<MDBox sx={{marginLeft: "8%", marginRight: "17%"}}>
              {flag === 1 &&
                <>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  <MDBox mt={0.4} sx={{display: "flex", justifyContent:"center"}}>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Job Details:"}</b>
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  <MDBox display="flex" mt={(flag === 1) ? 0.4 : 0}>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      <b>{"Printing Machine"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                      <b>{"Started By"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "45%"}} display="block" variant="caption">
                      <b>{"Started At"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "5%"}} display="block" variant="caption">
                      <b>{"Action"}</b>
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                </>
              }
              {flag > 1 && <Divider sx={{ opacity: 1, marginTop:"3px", marginBottom:"3px", bgcolor: "#f0f2f5" }} />}
              {((jobDetail?.started_by && jobDetail?.started_at && !jobDetail?.ended_at && (jobDetail?.started_by_id == loggedUserId)) ?
                <MDBox mt={(flag === 1) ? 0.4 : 0} sx={{display: "flex", alignItems:"center"}}>
                  <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                    {jobDetail?.machine_name}
                  </MDTypography>
                  <MDTypography sx={{width: "25%"}} display="block" variant="caption">
                    {jobDetail?.started_by}
                  </MDTypography>
                  <MDTypography sx={{width: "45%"}} display="block" variant="caption">
                    {formatDateTime(jobDetail?.started_at)}
                  </MDTypography>
                  <MDBox display="flex" sx={{width: "5%", alignItems:"center"}}>
                    <MDTypography mr={1} component={Link} onClick={ async () => await handlePrint(woOperatorJobDetails?.id, row.index, 'operator', i)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                        {jobDetail?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                    </MDTypography>
                    <MDTypography component={Link} onClick={() => handleClickEndedAtOpen(i, woOperatorJobDetails?.id)} title="Stop" display="block" variant="caption" color="error">
                      <Icon fontSize="small">stop_circle_icon</Icon>
                    </MDTypography>
                  </MDBox>
                </MDBox> :
                ((!jobDetail?.started_at && !jobDetail?.ended_at && woOperatorJobDetails?.job_detail_ids[i]?.includes(Number(loggedUserId))) &&
                  <MDBox mt={(flag === 1) ? 0.4 : 0} sx={{display: "flex", alignItems:"center"}}>
                    <MDTypography sx={{width: "95%"}} display="block" variant="caption">
                      {jobDetail?.machine_name}
                    </MDTypography>
                    <MDBox display="flex" sx={{width: "5%", alignItems:"center"}}>
                      <MDTypography mr={1} component={Link} onClick={ async () => await handlePrint(woOperatorJobDetails?.id, row.index, 'operator', i)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                          {jobDetail?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                      </MDTypography>
                      <MDTypography component={Link} onClick={() => updateStartedAt(i, woOperatorJobDetails?.id, row.index, woOperatorJobDetails?.status_id, woOperatorJobDetails?.started_at)} title="Start" display="block" variant="caption" color="success">
                        {jobDetail?.start_job_loading ?
                          <MDCircularProgress
                            size={20}
                            color="info"
                          /> :
                          <Icon fontSize="small">play_circle_filled_icon</Icon>
                        }
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                )
              )
              }
              {renderAlertSB}
            </MDBox>)
          })
        })()
      )
    },
    [scheduleWorkOrders, currentJobInProgress, renderAlertSB]
  );

  // Create a function that will render our row sub components
  const renderOperatorCompletedRowSubComponent = React.useCallback(
    ({ row }) => {
      let woOperatorJobDetails = operatorCompletedWorkOrders[row.index];
      return (
        (() => {
          let flag = 0;
          return woOperatorJobDetails?.wo_printing_items?.map((jobDetail, i) => {
            if (((jobDetail?.operator_id && (jobDetail?.operator_id === Number(loggedUserId)) && jobDetail?.started_at && jobDetail?.ended_at && jobDetail?.completed_date))) {
              flag++;
            }
            return (<MDBox sx={{marginLeft: "8%", marginRight: "12%"}}>
              {(jobDetail?.operator_id && (jobDetail?.operator_id === Number(loggedUserId)) && jobDetail?.started_at && jobDetail?.ended_at && jobDetail?.completed_date) && flag === 1 &&
                <>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  <MDBox mt={0.4} sx={{display: "flex", justifyContent:"center"}}>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Job Details:"}</b>
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ opacity: 1, marginTop:"2px", marginBottom:"2px", bgcolor: "#f0f2f5" }} />
                  <MDBox display="flex" mt={(flag === 1) ? 0.4 : 0}>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Printing Machine"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Completed Date"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Started At"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "15%"}} display="block" variant="caption">
                      <b>{"Ended At"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                      <b>{"Total Time"}</b>
                    </MDTypography>
                    <MDTypography sx={{width: "5%"}} display="block" variant="caption">
                      <b>{"Action"}</b>
                    </MDTypography>
                  </MDBox>

                </>
              }
              {(operatorCompletedSearchStartedDate && formatDate(jobDetail?.started_at) == operatorCompletedSearchStartedDate) &&
                <>
              {((jobDetail?.operator_id && (jobDetail?.operator_id === Number(loggedUserId)) && jobDetail?.started_at && jobDetail?.ended_at && jobDetail?.completed_date)) && <Divider sx={{ opacity: 1, margin:"3px", bgcolor: "#f0f2f5" }} />}
              {(jobDetail?.operator_id && (jobDetail?.operator_id === Number(loggedUserId)) && jobDetail?.started_at && jobDetail?.ended_at && jobDetail?.completed_date) &&
                <MDBox mt={(flag === 1) ? 0.4 : 0} sx={{display: "flex", alignItems:"center"}}>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {jobDetail?.print_machine?.name}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {jobDetail?.completed_date}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {formatDateTime(jobDetail?.started_at)}
                  </MDTypography>
                  <MDTypography sx={{width: "15%"}} display="block" variant="caption">
                    {formatDateTime(jobDetail?.ended_at)}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    <b>{(jobDetail?.started_at && jobDetail?.ended_at) ? timeDifference(jobDetail?.started_at, jobDetail?.ended_at) : ''}</b>
                  </MDTypography>
                  <MDTypography sx={{width: "5%"}} pl={1} component={Link} onClick={ async () => await handlePrint(woOperatorJobDetails?.id, row.index, 'operator_completed', jobDetail?.id, i)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                      {jobDetail?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                  </MDTypography>
                </MDBox>
              }
              </>
              }
              {!operatorCompletedSearchStartedDate &&
              <>
              {((jobDetail?.operator_id && (jobDetail?.operator_id === Number(loggedUserId)) && jobDetail?.started_at && jobDetail?.ended_at && jobDetail?.completed_date)) && <Divider sx={{ opacity: 1, margin:"3px", bgcolor: "#f0f2f5" }} />}
              {(jobDetail?.operator_id && (jobDetail?.operator_id === Number(loggedUserId)) && jobDetail?.started_at && jobDetail?.ended_at && jobDetail?.completed_date) &&
                <MDBox mt={(flag === 1) ? 0.4 : 0} sx={{display: "flex", alignItems:"center"}}>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {jobDetail?.print_machine?.name}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {jobDetail?.completed_date}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    {formatDateTime(jobDetail?.started_at)}
                  </MDTypography>
                  <MDTypography sx={{width: "15%"}} display="block" variant="caption">
                    {formatDateTime(jobDetail?.ended_at)}
                  </MDTypography>
                  <MDTypography sx={{width: "20%"}} display="block" variant="caption">
                    <b>{(jobDetail?.started_at && jobDetail?.ended_at) ? timeDifference(jobDetail?.started_at, jobDetail?.ended_at) : ''}</b>
                  </MDTypography>
                  <MDTypography sx={{width: "5%"}} pl={1} component={Link} onClick={ async () => await handlePrint(woOperatorJobDetails?.id, row.index, 'operator_completed', jobDetail?.id, i)} title="Print Job Card" display="block" variant="caption" color="secondary" fontWeight="medium">
                      {jobDetail?.job_icon_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                  </MDTypography>
                </MDBox>
              }
              </>
            }
            </MDBox>)
          })
        })()
      );
    },
    [operatorCompletedWorkOrders]
  );

  const handleRowClick = (row) => {
    row.toggleRowExpanded();
  };

  return {
    columns: [
      { Header: ((loggedUserRole == 'Admin') ? <MDTypography component="a" variant="button" color="text" fontWeight="medium">{"WO#"}</MDTypography> : "WO#"), accessor: "id", width: "8%", align: "left" },
      { Header: ((loggedUserRole == 'Admin') ? <MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Party"}</MDTypography> : "Party"), accessor: "customer_name", sorting_key: 'cu.name', width: "24%", align: "left" },
      { Header: ((loggedUserRole == 'Admin') ? <MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Job Type & Status"}</MDTypography> : "Job Type & Status"), accessor: "job_type_and_status", sorting_key: 'job_type', width: "34%", align: "left" },
      { Header: ((loggedUserRole == 'Admin') ? <MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Size"}</MDTypography> : "Size"), accessor: "size", sorting_key: 'sz.name', width: "11%", align: "left" },
      { Header: ((loggedUserRole == 'Admin') ? <MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Delivery Date"}</MDTypography> : "Delivery Date"), accessor: "delivery_date", sorting_key: 'delivery_date', width: ((loggedUserRole == 'Admin') ? "5%" : "12%"), align: "left" },
      { Header: ((loggedUserRole == 'Admin') ? <MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Created Date"}</MDTypography> : "Created Date"), accessor: "release_date", sorting_key: 'created_at', width: ((loggedUserRole == 'Admin') ? "5%" : "11%"), align: "left" },
      // { Header: (<MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Job Details"}</MDTypography>), accessor: "job_details", width: "1%", align: "left" },
      ...((loggedUserRole == 'Admin' && !showCompletedTab)) ? [{ Header: (<MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Sequence"}</MDTypography>), accessor: "sequence", width: "15%", align: "center" }] : [],
      ...((loggedUserRole == 'Admin' && showCompletedTab)) ? [{ Header: (<MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Completed Date"}</MDTypography>), accessor: "completed_date", width: "5%", align: "left" }] : [],
      ...(loggedUserRole == 'Admin') ? [{ Header: (<MDTypography component="a" variant="button" color="text" fontWeight="medium">{"Actions"}</MDTypography>), accessor: "action", width: "8%", align: "center" }] : [],
      {
        // Make an expander cell
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <Icon fontSize="small">keyboard_arrow_up_icon</Icon> : <Icon fontSize="small">keyboard_arrow_down_icon</Icon>}
          </span>
        ),
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          <span>
            {row.isExpanded ? <Icon sx={{marginTop: "10px"}} fontSize="small">keyboard_arrow_up_icon</Icon> : <Icon sx={{marginTop: "10px"}} fontSize="small">keyboard_arrow_down_icon</Icon>}
          </span>
        ),
      },
    ],
    rows: allRows,
    scheduleWorkOrders: scheduleWorkOrders,
    totalData: totalData,
    operatorCompletedTotalWo: operatorCompletedTotalWo,
    newProgressTotalData: newProgressTotalData,
    loggedUserRole: loggedUserRole,
    handleTabChange: handleTabChange,
    value: value,
    handleOperatorViewTabChange: handleOperatorViewTabChange,
    operatorTabValue: operatorTabValue,
    completedTotalData: completedTotalData,
    submitHandler: submitHandler,
    endedAtOpen: endedAtOpen,
    rejectOpen: rejectOpen,
    markCompleteOpen: markCompleteOpen,
    updateCompletedDateOpen: updateCompletedDateOpen,
    nextJobOpen: nextJobOpen,
    nextPrintJobOpen: nextPrintJobOpen,
    handleClose: handleClose,
    selectedId: selectedId,
    workOrderId: workOrderId,
    updateEndedAt: updateEndedAt,
    rejectScheduleWorkOrder: rejectScheduleWorkOrder,
    markCompleteScheduleWorkOrder: markCompleteScheduleWorkOrder,
    newProgressSearchWoId: newProgressSearchWoId,
    setNewProgressSearchWoId: setNewProgressSearchWoId,
    newProgressSearchCustomer: newProgressSearchCustomer,
    setNewProgressSearchCustomer: setNewProgressSearchCustomer,
    completedSearchCustomer: completedSearchCustomer,
    setCompletedSearchCustomer: setCompletedSearchCustomer,
    newProgressSearchSize: newProgressSearchSize,
    setNewProgressSearchSize: setNewProgressSearchSize,
    completedSearchWoId: completedSearchWoId,
    setCompletedSearchWoId: setCompletedSearchWoId,
    completedSearchSize: completedSearchSize,
    setCompletedSearchSize: setCompletedSearchSize,
    operatorSearchWoId: operatorSearchWoId,
    setOperatorSearchWoId: setOperatorSearchWoId,
    operatorSearchCustomer: operatorSearchCustomer,
    setOperatorSearchCustomer: setOperatorSearchCustomer,
    operatorSearchSize: operatorSearchSize,
    setOperatorSearchSize: setOperatorSearchSize,
    operatorCompletedSearchWoId: operatorCompletedSearchWoId,
    setOperatorCompletedSearchWoId: setOperatorCompletedSearchWoId,
    operatorCompletedSearchCustomer: operatorCompletedSearchCustomer,
    setOperatorCompletedSearchCustomer: setOperatorCompletedSearchCustomer,
    operatorCompletedSearchSize: operatorCompletedSearchSize,
    setOperatorCompletedSearchSize: setOperatorCompletedSearchSize,
    operatorNewProgressWoIdOptions: operatorNewProgressWoIdOptions,
    operatorCompletedWoIdOptions: operatorCompletedWoIdOptions,
    adminNewProgressWoIdOptions: adminNewProgressWoIdOptions,
    adminCompletedWoIdOptions: adminCompletedWoIdOptions,
    customersOptions: customersOptions,
    sizesOptions: sizesOptions,
    newProgressSearchOperator: newProgressSearchOperator,
    setNewProgressSearchOperator: setNewProgressSearchOperator,
    completedSearchOperator: completedSearchOperator,
    setCompletedSearchOperator: setCompletedSearchOperator,
    operatorOptions: operatorOptions,
    newProgressSearchCreatedDate: newProgressSearchCreatedDate,
    setNewProgressSearchCreatedDate: setNewProgressSearchCreatedDate,
    completedSearchCreatedDate: completedSearchCreatedDate,
    setCompletedSearchCreatedDate: setCompletedSearchCreatedDate,
    operatorSearchCreatedDate: operatorSearchCreatedDate,
    setOperatorSearchCreatedDate: setOperatorSearchCreatedDate,
    operatorCompletedSearchCreatedDate: operatorCompletedSearchCreatedDate,
    setOperatorCompletedSearchCreatedDate: setOperatorCompletedSearchCreatedDate,
    completedSearchCompletionDate: completedSearchCompletionDate,
    setCompletedSearchCompletionDate: setCompletedSearchCompletionDate,
    operatorCompletedSearchCompletionDate: operatorCompletedSearchCompletionDate,
    setOperatorCompletedSearchCompletionDate: setOperatorCompletedSearchCompletionDate,
    operatorCompletedSearchStartedDate: operatorCompletedSearchStartedDate,
    setOperatorCompletedSearchStartedDate: setOperatorCompletedSearchStartedDate,
    completedSearchStartedDate: completedSearchStartedDate,
    setCompletedSearchStartedDate: setCompletedSearchStartedDate,
    newProgressSearchHandler: newProgressSearchHandler,
    completedSearchHandler: completedSearchHandler,
    operatorSearchHandler: operatorSearchHandler,
    operatorCompletedSearchHandler: operatorCompletedSearchHandler,
    endedAtLoading: endedAtLoading,
    rejectLoading: rejectLoading,
    markCompleteLoading: markCompleteLoading,
    searchLoading: searchLoading,
    saveBtnLoading: saveBtnLoading,
    renderInProgressRowSubComponent: renderInProgressRowSubComponent,
    renderCompletedRowSubComponent: renderCompletedRowSubComponent,
    renderOperatorRowSubComponent: renderOperatorRowSubComponent,
    renderOperatorCompletedRowSubComponent: renderOperatorCompletedRowSubComponent,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    currentJobInProgressWoId: currentJobInProgressWoId,
    handleRowClick: handleRowClick,
    adminNewProgressRowExpanded: adminNewProgressRowExpanded,
    adminCompletedRowExpanded: adminCompletedRowExpanded,
    operatorNewProgressRowExpanded: operatorNewProgressRowExpanded,
    operatorCompletedRowExpanded: operatorCompletedRowExpanded,
    operatorId: operatorId,
    setOperatorId: setOperatorId,
    operatorDropdown: operatorDropdown,
    startDate: startDate,
    endDate: endDate,
    setDate: setDate,
    onFilterChange: onFilterChange,
    filter: filter,
    clearFilters: clearFilters,
    generateAndDownloadExcelFile: generateAndDownloadExcelFile,
    reportsubmitHandler: reportsubmitHandler,
    reportSearchLoading: reportSearchLoading,
    operatorJobSheet: operatorJobSheet,
    jobReportHandlePrint: jobReportHandlePrint,
    printLoading: printLoading,
    excelLoading: excelLoading,
    workOrderPermission: workOrderPermission,
    customerPermission: customerPermission,
    updateCompletedDateValue: updateCompletedDateValue,
    setUpdateCompletedDateValue: setUpdateCompletedDateValue,
    updateCompletedDateLoading: updateCompletedDateLoading,
    updateCompletedDate: updateCompletedDate,
  };
}
