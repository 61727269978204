/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
import PropTypes from "prop-types";
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

// Custom styles for MDButton
import MDCheckboxRoot from "components/MDCheckbox/MDCheckboxRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDCheckbox = forwardRef(
  ({ label, labelPlacement, name, checked, onChange, disabled, ...rest }, ref) => {
    const [controller] = useMaterialUIController();

    return (
        <Box>
            <FormControlLabel
                label={label}
                labelPlacement={labelPlacement}
                control={
                    <MDCheckboxRoot
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    name={name ? name : ''}
                    {...rest}
                    ownerState={{ disabled }}
                    />
                }
            />
        </Box>
    );
  }
);

// Setting default values for the props of MDButton
MDCheckbox.defaultProps = {
  disabled: false,
  size: 'small'
};

// Typechecking props for the MDCheckbox
MDCheckbox.propTypes = {
    labelPlacement: PropTypes.oneOf(["top", "bottom", "start", "end"]),
  };

export default MDCheckbox;
