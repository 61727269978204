/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import PaymentTypesService from "services/payment-type-service";
import { useNavigate } from "react-router-dom";
import authPermission from "utility/auth-permissions";
import { useCache } from "context";

export default function data(props) {
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-payment-types');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.paymentType;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [paymentTypesData, setPaymentTypesData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [totalData, setTotalData] = useState(0);
  let location = useLocation();
  const { cacheItems, setCacheItems } = useCache();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    // const getPaymentTypesData = async (e) => {
    //   const response = await PaymentTypesService.getPaymentTypes(page_no, page_size, sort_column, sort_column_value, search_value);
    //   setPaymentTypesData(response?.data);
    //   setTotalData(response?.total);
    // };
    // getPaymentTypesData();
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreservePaymentType(preserveData);
    const response = await PaymentTypesService.getPaymentTypes(page_no, page_size, sortColumn, sortColumnValue, searchWord);
    setPaymentTypesData(response?.data);
    setTotalData(response?.total);
  };

  const updatePaymentTypeStatus = async (id, status) => {
    try {
      var data = {'active': status};
      const res = await PaymentTypesService.updatePaymentTypeStatus(id, data);
      if (res) {
        openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Payment Type'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        const response = await PaymentTypesService.getPaymentTypes(page_no, page_size, sort_column, sort_column_value, search_value);
        setPaymentTypesData(response?.data);
        setTotalData(response?.total);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Payment Type'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Payment Type'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const deletePaymentType = async (id) => {
    try {
      const response = await PaymentTypesService.deletePaymentType(id);
      if (response) {
        if (cacheItems.hasOwnProperty('payment_types')) {
          // Create a new object without the key
          const { payment_types, ...rest } = cacheItems; // Destructure to exclude key
          setCacheItems(rest); // Set the new cache items without key
        }
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Payment Type'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Payment Type'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Payment Type'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    const res = await PaymentTypesService.getPaymentTypes(page_no, page_size, sort_column, sort_column_value, search_value);
    setPaymentTypesData(res?.data);
    setTotalData(res?.total);
    setSelectedId('');
    handleClose();
  };

  const allRows = [];
  paymentTypesData?.map((eachPaymentType, index) => {
      const eachData = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-payment-types/edit?id=${eachPaymentType.id}` : ''} variant="body2" color="text">
                {eachPaymentType?.id}
              </MDTypography>,
        name: (<MDTypography display="block" variant="body2">
          {eachPaymentType?.name}
          </MDTypography>
          ),
        action: (
              <MDBox display="flex">
                {pagePermission.status ? (
                  <MDSwitch
                    checked={eachPaymentType.active ? true : false}
                    color="info"
                    title="Status"
                    onChange={() => updatePaymentTypeStatus(eachPaymentType.id , !eachPaymentType.active)}
                  />
                ) : ''}
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-payment-types/edit?id=${eachPaymentType.id}`} title="Edit" display="block" variant="body2" color="info">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.new ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-payment-types/add?id=${eachPaymentType.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                    <Icon fontSize="small">content_copy_icon</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.remove ? (
                  <MDTypography mt={1} component={Link} onClick={() => handleClickOpen(eachPaymentType.id)} title="Remove" display="block" variant="body2" color="error">
                    <Icon fontSize="small">delete</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            )
      };
      allRows[index] = eachData;
  });

  return {
    columns: [
      { Header: "Id", accessor: "id", width: "15%", align: "left" },
      { Header: "Name", accessor: "name", width: "70%", align: "left" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "15%", align: "center" }] : [],
    ],
    rows: allRows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deletePaymentType: deletePaymentType,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
