import HttpService from "./htttp.service";

class PrintingTypeService {

  getWoPrintingTypes = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'wo_printing_type';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'wo_printing_type?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addWoPrintingType = async(newPrintingType) => {
    const fetchUrl = 'wo_printing_type';
    return await HttpService.post(fetchUrl, newPrintingType);
  }

  updateWoPrintingType = async (id, data) => {
    const fetchUrl = 'wo_printing_type/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updateWoPrintingTypeStatus = async (id, data) => {
    const fetchUrl = 'wo_printing_type/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showWoPrintingType = async(id) => {
    const fetchUrl = 'wo_printing_type/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteWoPrintingType = async (id) => {
    const fetchUrl = 'wo_printing_type/' + id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new PrintingTypeService();
