import HttpService from "./htttp.service";

class ItemService {
  // authEndpoint = process.env.API_URL;

  getItems = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'items';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'items?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  };

  addItem = async (data) => {
    const fetchUrl = 'items';
    return await HttpService.post(fetchUrl, data);
  };

  deleteItem = async (id) => {
    const fetchUrl = 'items/' + id;
    return await HttpService.delete(fetchUrl);
  };

  updateItemStatus = async (id, data) => {
    const fetchUrl = 'items/' + id;
    return await HttpService.patch(fetchUrl, data);
  };

  showItem = async (id) => {
    const fetchUrl = 'items/' + id;
    return await HttpService.get(fetchUrl);
  };

  updateItem = async (id, data) => {
    const fetchUrl = 'items/' + id;
    return await HttpService.put(fetchUrl, data);
  };

  updateItemQuantity = async (data) => {
    const fetchUrl = 'items_quantity_update';
    return await HttpService.patch(fetchUrl, data);
  }
}

export default new ItemService();
