/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAccordion from "components/MDAccordion";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import authPermission from "utility/auth-permissions";
import { PreserveTable } from "context";

// @mui icons
import Icon from "@mui/material/Icon";
import { useMediaQuery } from "@mui/material";
import CustomerPaymentService from "services/customer-payment-service";
import PaymentTypesService from "services/payment-type-service";
import dayjs from "dayjs";
import { numberFormat } from "utility/helpers";
import MDDialog from "components/MDDialog";
import MDSelect from "components/MDSelect";
import MDDatePicker from "components/MDDatePicker";
import MDCheckbox from "components/MDCheckbox";
import { onlyNumbers } from "utility/helpers";
import MDMultipleSelect from "components/MDMultipleSelect";
import { useCache } from "context";

function CustomerRatesForm() {
  const { hasPermission } = authPermission();
  const preserveTable = useContext(PreserveTable);
  const paymentPermission = hasPermission('/manage-customers/payments');
  const woPermission = hasPermission('/manage-work-orders');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const ref = searchParams.get('ref');
  const closeWindow = searchParams.get('close_window') ? 1 : 0;
  const customer_name = searchParams.get('customer_name');
  const navigate = useNavigate();
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const { cacheItems, setCacheItems } = useCache();
  const minWidth = useMediaQuery('(min-width:1725px)');
  const minWidthD = useMediaQuery('(min-width:1150px)');
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.customerPayment;

  const [paymentItems, setPaymentItems] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [outstandingAmount, setOutstandingAmount] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [paymentTypeDropdown, setPaymentTypeDropdown] = useState([]);
  const [workOrderDropdown, setWorkOrderDropdown] = useState([]);
  const [workOrderIds, setWorkOrderIds] = useState([]);
  const [paymentDate, setPaymentDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));

  const [inputs, setInputs] = useState({
    customer_id: id,
    work_order_id: null,
    payment_type_id: "",
    amount: "",
    description: "",
    is_cash: 0,
    cheque_no: "",
    type: "Manual"
  });

  const getCustomerPaymentData = async (e) => {
    const response = await CustomerPaymentService.getCustomerPayments(id, page_no, page_size, sort_column, sort_column_value, search_value);
    const balance = await CustomerPaymentService.getCurrentBalance(id);
    const outstanding = await CustomerPaymentService.getOutStandingAmount(id);
    setPaymentItems(response?.data);
    setTotalData(response?.total);
    setCurrentBalance(balance);
    setOutstandingAmount(outstanding);
  };

  useEffect(() => {
    getCustomerPaymentData();

    const getPaymentType = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('payment_types')) {
        response = cacheItems['payment_types'];
      } else {
        response = await PaymentTypesService.getPaymentTypes(); // Fetch required data from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            payment_types: response,
        }));
      }
      if (response) {
        var dataArray = [];
        var otherDetail = [];
        response?.map((eachData, index) => {
          dataArray[index] = {
              key: eachData.id,
              value: eachData.name,
              disabled: eachData?.active ? false : true,
          }
        });
        setPaymentTypeDropdown(dataArray);
      }
    }
    getPaymentType();

    const getWoDropdown = async() => {
      const dropdown = await CustomerPaymentService.getCustomerOutstandingWo(id);
      setWorkOrderDropdown(dropdown);
    }
    getWoDropdown();
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveCustomerPayment(preserveData);
    const response = await CustomerPaymentService.getCustomerPayments(id, page_no, page_size, sortColumn, sortColumnValue, searchWord);
    setPaymentItems(response?.data);
    setTotalData(response?.total);
  };

  const changeHandler = (e) => {
    if (e.target.name == 'is_cash') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked === true ? 1 : 0,
        ['cheque_no']: e.target.checked === true ? "" : inputs.cheque_no,
      });
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  }

  const submitHandler = async (e) => {
    const newPayment = {
      customer_id: inputs.customer_id,
      work_order_id: inputs.work_order_id,
      work_order_ids: workOrderIds,
      payment_type_id: inputs.payment_type_id,
      payment_date: paymentDate,
      amount: numberFormat(inputs.amount, false, 4),
      description: inputs.description,
      is_cash: inputs.is_cash,
      cheque_no: inputs.cheque_no ? inputs.cheque_no : 0,
      type: inputs.type,
    }

    if (newPayment.work_order_ids.length <= 0) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Work Order is mandatory.Please select at least one.", title: 'Customer Payment'});
    } else if (!newPayment.payment_type_id) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Payment type is mandatory.", title: 'Customer Payment'});
    } else if (!newPayment.amount) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Amount is mandatory.", title: 'Customer Payment'});
    } else {
      try {
        const response = await CustomerPaymentService.addCustomerPayment(newPayment);
        if (response) {
          openAlertSB({color: 'success', icon: 'check', msg: "Payment added successfully!", title: 'Customer Payment'});
          getCustomerPaymentData();
          setOpenForm(false);
          setInputs({
            customer_id: id,
            work_order_id: null,
            payment_type_id: "",
            amount: "",
            description: "",
            is_cash: 0,
            cheque_no: "",
            type: "Manual"
          });
          setPaymentDate(dayjs(new Date()).format('YYYY-MM-DD'));
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Customer Payment'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Customer Payment'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      }
    }
    return () => {

    };
  }

  const goBack = async() => {
    if (ref) {
      window.location.href = `/manage-customers/edit?id=${id}&ref=${ref}`;
    } else if (closeWindow) {
      //window.open("about:blank", "_self");
      window.close();
    } else {
      window.location.href = `/manage-customers/edit?id=${id}`;
    }
  }

  const rows = [];
  paymentItems?.map((eachItem, index) => {
    const cols = {
        payment_type: (
          <MDTypography display="block" variant="body2">
            {eachItem?.payment_type?.name}
          </MDTypography>),
        payment_date: (
          <MDTypography display="block" variant="body2">
            {dayjs(eachItem?.payment_date).format('DD-MM-YYYY')}
          </MDTypography>),
        amount: (
          <MDTypography display="block" variant="body2">
            {numberFormat(eachItem?.amount)}
          </MDTypography>),
        description: (
          <MDTypography display="block" variant="body2">
            {eachItem?.description ?eachItem?.description : ''}
          </MDTypography>),
        work_order_id: eachItem?.work_order_id ? (
              <MDTypography component={Link} to={woPermission.edit ? `/manage-work-orders/edit?id=${eachItem?.work_order_id}&tab=5&ref=${encodeURIComponent(`/manage-customers/payments?id=${id}&customer_name=${customer_name}`)}` : ''} variant="body2" color="info">
                {eachItem?.work_order_id}
              </MDTypography>
        ) : (''),
        is_cash: (
          <MDTypography display="block" variant="body2">
            {eachItem?.is_cash_label  }
          </MDTypography>),
        cheque_no: (
          <MDTypography display="block" variant="body2">
            {eachItem?.cheque_no > 0 ? eachItem?.cheque_no : ''}
          </MDTypography>),
        type: (
          <MDTypography display="block" variant="body2">
            {eachItem?.type}
          </MDTypography>),
        created: (
          <MDTypography display="block" variant="body2">
            <MDTypography display="block" variant="body2">
                {eachItem?.first_name + " " + eachItem?.last_name}
            </MDTypography>
            <MDTypography display="block" variant="body2">
                {eachItem? dayjs(eachItem?.created_at).format('DD-MM-YYYY hh:mm A') : ''}
            </MDTypography>
          </MDTypography>
        ),
    };
    rows[index] = cols;
  });

  const columns = [
    { Header: "Pymt. Type", accessor: "payment_type", width: "15%", align: "left" },
    { Header: "Rec. Date", accessor: "payment_date", width: "10%", align: "left" },
    { Header: "Amount", accessor: "amount", width: "10%", align: "left" },
    { Header: "Description", accessor: "description", width: "20%", align: "left" },
    { Header: "Wo #", accessor: "work_order_id", width: "5%", align: "left" },
    { Header: "Cash?", accessor: "is_cash", width: "8%", align: "left" },
    { Header: "Cheque No", accessor: "cheque_no", width: "10%", align: "left" },
    { Header: "Mode", accessor: "type", width: "10%", align: "left" },
    { Header: "Entered By & At", accessor: "created", width: "15%", align: "left" },
  ];

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={'Customer Payments : ' + customer_name}/>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.625rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={12} md={minWidth ? 7 : 5}>
                      <MDTypography mt={1} variant="h6" color="white">
                        {'Customer Payments : ' + customer_name}
                      </MDTypography>
                    </Grid>
                      <Grid item xs={12} md={minWidth ? 5 : 7} display={minWidthD ? 'flex' : ''} justifyContent="flex-end" alignItems={"center"}>
                      <MDBox
                          py={"0.450rem"}
                          px={2}
                          mt={minWidthD ? '' : 1}
                          mr={minWidthD ? 2 : ''}
                          variant="gradient"
                          bgColor="error"
                          borderRadius="lg"
                          coloredShadow="error"
                          width="auto"
                          whiteSpace={minWidth ? 'nowrap' : ''}
                          textAlign="center"
                        >
                          <MDTypography variant="h6" color="white">
                            {`Outstanding Amount: ${outstandingAmount > 0 ? numberFormat(outstandingAmount) : '0.00'}`}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          py={"0.450rem"}
                          px={2}
                          mt={minWidthD ? '' : 1}
                          mr={minWidthD ? 2 : ''}
                          variant="gradient"
                          bgColor="success"
                          borderRadius="lg"
                          coloredShadow="success"
                          width="auto"
                          whiteSpace={minWidth ? 'nowrap' : ''}
                          textAlign="center"
                        >
                          <MDTypography variant="h6" color="white">
                            {`Current Balance: ${currentBalance > 0 ? numberFormat(currentBalance) : '0.00'}`}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" textAlign="center" mt={minWidthD ? '' : 1}>
                          {paymentPermission.new == 1 && (
                            <MDButton
                              onClick={() => setOpenForm(true)}
                              sx={{padding: '10px'}}
                              variant="gradient" color="warning" title="Add Payment" startIcon={<Icon sx={{ fontWeight: "bold" }}>add </Icon>}>
                                Add Payment
                            </MDButton>
                          )}
                          <MDButton
                            title="Edit"
                            component={Link}
                            sx={{marginLeft: 2}}
                            to={ref ? `/manage-customers/edit?id=${id}&ref=${ref}` : `/manage-customers/edit?id=${id}`}
                            variant="outlined" color="white" iconOnly circular>
                                <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>edit</Icon>
                          </MDButton>
                        </MDBox>
                      </Grid>
                  </Grid>
                </MDBox>
                <MDBox px={2} mt={2} mb={2}>
                  <DataTable
                    // entriesPerPage={true}
                    entriesPerPage={{
                      "defaultValue": page_size
                    }}
                    canSearch={true}
                    showTotalEntries={true}
                    table={{ columns, rows }}
                    isSorted={true}
                    totalData={totalData}
                    initialPageIndex={page_no - 1}
                    getData={getData}
                    pageCount={Math.ceil(totalData/page_size)}
                    sortCol={sort_column}
                    sortColValue={sort_column_value}
                    searchVal={search_value}
                    noEndBorder={false}
                    horizontalRowPadding={0.5}
                  />
                  <Grid container>
                    <Grid item md={11} xs={12} px={3}></Grid>
                    <Grid item md={1} xs={12} px={3}>
                      <MDBox>
                        <MDButton component={Link} onClick={() => goBack()} variant="gradient" color="primary" fullWidth>
                          Back
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
      <MDDialog
        open={openForm}
        onClose={() => setOpenForm(false)}
        successAction={submitHandler}
        dialogTitle={'Add Payment'}
        cancleButton={'Back'}
        successButton={'Save'}
        cancleButtonColor={'primary'}
        successButtonColor={'info'}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <MDBox component="form" role="form" method="POST" mt={1} py={3} fullWidth>
          <Grid container>
            <Grid items px={3} md={4} xs={12}>
              <MDBox mb={2}>
                <MDMultipleSelect
                  fullWidth
                  label="Wo #"
                  name="work_order_id"
                  value={workOrderIds}
                  onChange={(e) => setWorkOrderIds(e.target.value != null ? e.target.value : '')}
                  options={workOrderDropdown}
                  required={true}
                />
              </MDBox>
            </Grid>
            <Grid items px={3} md={4} xs={12}>
              <MDBox mb={2}>
                <MDSelect
                  fullWidth
                  label="Payment Type"
                  name="payment_type_id"
                  value={inputs?.payment_type_id}
                  onChange={changeHandler}
                  options={paymentTypeDropdown}
                  required={true}
                />
              </MDBox>
            </Grid>
            <Grid items px={3} md={4} xs={12}>
              <MDBox mb={2}>
                <MDDatePicker
                  label="Payment Date"
                  name="payment_date"
                  value={dayjs(paymentDate).isValid() ? dayjs(paymentDate) : dayjs(new Date())}
                  format="DD-MM-YYYY"
                  onChange={(newValue) => {
                    setPaymentDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                  }}
                  slotProps={{ textField: {
                      fullWidth: true,
                      required: true
                    },
                    field: {
                      clearable: true
                    },
                    textField: { size: 'small' },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid items px={3} md={6} xs={12}>
              <MDBox mb={2}>
                <MDInput
                  fullWidth
                  type="text"
                  label="Amount"
                  name="amount"
                  value={inputs?.amount}
                  onChange={(e) => {onlyNumbers(e.target.value, '-decimal(16,4)') ? changeHandler(e) : ''}}
                  required={true}
                />
              </MDBox>
            </Grid>
            <Grid items px={3} md={2} xs={12}>
              <MDBox mb={2}>
                <MDCheckbox
                  fullWidth
                  label="Is Cash?"
                  name="is_cash"
                  checked={inputs?.is_cash}
                  onChange={changeHandler}
                />
              </MDBox>
            </Grid>
            <Grid items px={3} md={4} xs={12}>
              <MDBox mb={2}>
                <MDInput
                  fullWidth
                  type="text"
                  label="Cheque No."
                  name="cheque_no"
                  value={inputs?.cheque_no}
                  onChange={changeHandler}
                  disabled={inputs?.is_cash ? true : false}
                  inputProps={{maxLength: 8}}
                />
              </MDBox>
            </Grid>
            <Grid items px={3} md={12} xs={12}>
              <MDBox mb={2}>
                <MDInput
                  fullWidth
                  type="text"
                  label="Description"
                  name="description"
                  value={inputs?.description}
                  onChange={changeHandler}
                  inputProps={{maxLength: 250}}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDDialog>
    </>
  );
}

export default CustomerRatesForm;
