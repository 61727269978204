/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useState, useEffect, useContext } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import Switch from '@mui/material/Switch';
import customAlert from "components/MDAlert/customAlert";
import { PreserveTable } from "context";
import PrintingTypeService from "services/printing-type-service";
import authPermission from "utility/auth-permissions";

// @mui icons
import Icon from "@mui/material/Icon";
import { useCache } from "context";

export default function data() {
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-wo-printing-types');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.woPrintingType;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const navigate = useNavigate();
  const [printingTypeData, setPrintingTypeData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [totalData, setTotalData] = useState(0);
  const { cacheItems, setCacheItems } = useCache();
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    // const getPrintingTypeData = async () => {
    //   const response = await PrintingTypeService.getWoPrintingTypes(page_no, page_size, sort_column, sort_column_value, search_value);
    //   setPrintingTypeData(response?.data);
    //   setTotalData(response?.total);
    // };
    //   getPrintingTypeData();
  }, []);

    const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
      var preserveData = {
        "page_no": page_no,
        "page_size": page_size,
        "sort_column": sortColumn,
        "sort_column_value": sortColumnValue,
        "search_value": searchWord,
      };
      preserveTable.setPreserveWoPrintingType(preserveData);
      const response = await PrintingTypeService.getWoPrintingTypes(page_no, page_size, sortColumn, sortColumnValue, searchWord);
      setPrintingTypeData(response?.data);
      setTotalData(response?.total);
    };

    const updatePrintingTypeStatus = async (id, status) => {
      try {
        var data = {'active': status};
        const res = await PrintingTypeService.updateWoPrintingTypeStatus(id, data);
        if (res) {
          openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Work Order Printing Type'});
          setTimeout(function() {
              closeAlertSB();
          }, 3000);
          const response = await PrintingTypeService.getWoPrintingTypes(page_no, page_size, sort_column, sort_column_value, search_value);
          setPrintingTypeData(response?.data);
          setTotalData(response?.total);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order Printing Type'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order Printing Type'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      }
    };

  const deletePrintingType = async (id) => {
    try {
      const response = await PrintingTypeService.deleteWoPrintingType(id);
      if (response) {
        if (cacheItems.hasOwnProperty('printing_types')) {
          // Create a new object without the key
          const { printing_types, ...rest } = cacheItems; // Destructure to exclude key
          setCacheItems(rest); // Set the new cache items without key
        }
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Work Order Printing Type'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order Printing Type'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order Printing Type'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    setSelectedId('');
    handleClose();
    const res = await PrintingTypeService.getWoPrintingTypes(page_no, page_size, sort_column, sort_column_value, search_value);
    setPrintingTypeData(res?.data);
    setTotalData(res?.total);
  };

  let rows = [];
  printingTypeData?.map((eachPrintingType, index) => {
      const cols = {
        id:(
              <MDTypography component={Link} to={pagePermission.edit ? `/manage-wo-printing-types/edit?id=${eachPrintingType.id}` : ''} variant="body2" color="text">
                {eachPrintingType?.id}
              </MDTypography>
            ),
        name:(
          <MDTypography display="block" variant="body2">
          {eachPrintingType?.name}
          </MDTypography>
          ),
        action:(
              <MDBox display="flex">
                {pagePermission.status ? (
                  <MDSwitch checked={eachPrintingType?.active ? true : false} onChange={() => updatePrintingTypeStatus(eachPrintingType?.id, !eachPrintingType?.active)} inputProps={{ 'aria-label': 'controlled' }} title="Status" color="info"/>
                ) : ''}
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-wo-printing-types/edit?id=${eachPrintingType?.id}`} title="Edit" display="block" variant="body2" color="info">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
              ) : ''}
              {pagePermission.new ? (
                <MDTypography mr={1} mt={1} component={Link} to={`/manage-wo-printing-types/add?id=${eachPrintingType.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                  <Icon fontSize="small">content_copy_icon</Icon>
                </MDTypography>
              ) : ''}
              {pagePermission.remove ? (
                  <MDTypography mt={1} component="a"
                  onClick={() => handleClickOpen(eachPrintingType?.id)}
                  title="Remove"
                  display="block" variant="body2" color="error">
                    <Icon fontSize="small">delete</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            ),
      };
      rows[index] = cols;
  });

  return {
    columns: [
      { Header: "id", accessor: "id", width: "15%", align: "left" },
      { Header: "name", accessor: "name", width: "75%", align: "left" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "10%", align: "center" }] : [],
    ],

    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deletePrintingType: deletePrintingType,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
