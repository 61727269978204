/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ItemService from "services/item-service";

import { Link } from "react-router-dom";
import GroupService from "services/group-service";
import UnitService from "services/unit-service";
import { getUnit } from "@mui/material/styles/cssUtils";
import { useCache } from "context";

function Form() {
  const { action } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const [active, setActive] = useState(1);
  const [customerSupplied, setCustomerSupplied] = useState(0);
  const [groupDropdown, setGroupDropdown] = useState([]);
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const { cacheItems, setCacheItems } = useCache();

  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [inputs, setInputs] = useState({
    description: "",
    group_id: "",
    purchase_unit_id: "",
    inventory_unit_id: "",
    stock: 0,
    markup_pct:"",
    created_at: "",
  });

  const [errors, setErrors] = useState({
    descriptionError: false,
    stockError:false,
    markupPctError:false,
  });

  useEffect(() => {
    // Function for dropdown
    const getGroupData = async() => {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('material_groups')) {
          response = cacheItems['material_groups'];
        } else {
          response = await GroupService.getMaterialGroups(); // Fetch customers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              material_groups: response,
          }));
        }
        if (response) {
          var array = [];
          response?.map((eachGroup, index) => {
              array[index] = {
                key: eachGroup.id,
                value: eachGroup.name,
                disabled: eachGroup?.active ? false : true,
              }
          });
          setGroupDropdown(array);
        }
    }
    getGroupData();

    const getUnitData = async() => {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('units')) {
          response = cacheItems['units'];
        } else {
          response = await UnitService.getUnits(); // Fetch customers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              units: response,
          }));
        }
        if (response) {
          var array = [];
          response?.map((eachUnit, index) => {
              array[index] = {
                key: eachUnit.id,
                value: eachUnit.name,
                disabled: eachUnit?.active ? false : true,
              }
          });
          setUnitDropdown(array);
        }
    }
    getUnitData();

    if (action === "add" && id == null) return;

    const showItem = async() => {
      try {
        const data = await ItemService.showItem(id);
        setHeaderTitle(data.description);
        data.markup_pct = Number(data.markup_pct) * 1;
        setInputs(data);
        setCreatedBy(data.user.first_name + (data.user.middle_name ? (" " + data.user.middle_name) : "") + " " + data.user.last_name);
        setActive(data.active);
        setCustomerSupplied(data.customer_supplied);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Item'};
          navigate('/manage-items', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Item'};
          navigate('/manage-items', {state: state});
        }
      }
    }
    showItem();
  }, []);

  const changeHandler = (e) => {
    if (e.target.name == 'stock') {
      setErrors({
        stockError: false,
      });
    }
    if (e.target.name == 'markup_pct') {
      setErrors({
        markupPctError: false,
      });
    }
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();

    const decimalPattern = /^([0-9]{1,8}|[0-9]{1,8}\.[0-9]{1,4})$/;
    if (inputs.stock?.length > 0 && !inputs.stock.trim().match(decimalPattern)) {
      setErrors({ ...errors, stockError: true });
      return;
    }

    const markupPctPattern = /^([0-9]{1,3}(\.[0-9]{1,4})?|100(\.00?)?)$/;
    if (!inputs.markup_pct.toString().trim().match(markupPctPattern)) {
      setErrors({ ...errors, markupPctError: true });
      return;
    }

    const newItem = { description: inputs.description, group_id: inputs.group_id, purchase_unit_id: inputs.purchase_unit_id, inventory_unit_id: inputs.inventory_unit_id, stock: inputs.stock, markup_pct: inputs.markup_pct, customer_supplied: customerSupplied ? 1 : 0, active: active ? 1 : 0 };

    if (action === 'add') {
      try {
        const response = await ItemService.addItem(newItem);
        setLoading(false);
        if (response) {
          if (cacheItems.hasOwnProperty('items')) {
            // Create a new object without the key
            const { items, ...rest } = cacheItems; // Destructure to exclude key
            setCacheItems(rest); // Set the new cache items without key
          }
          const state = {color: 'success', icon: 'check', msg: 'Item Added Successfully!', title: 'Item'};
          navigate('/manage-items', { state: state });
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Item'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Item'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    } else {
      try {
        const response = await ItemService.updateItem(id, newItem);
        setLoading(false);
        if (response) {
          if (cacheItems.hasOwnProperty('items')) {
            // Create a new object without the key
            const { items, ...rest } = cacheItems; // Destructure to exclude key
            setCacheItems(rest); // Set the new cache items without key
          }
          openAlertSB({color: 'success', icon: 'check', msg: 'Item Updated Successfully!', title: 'Item'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Item'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Item'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    }

    return () => {
      setInputs({
        description: "",
        group_id: "",
        purchase_unit_id: "",
        inventory_unit_id: "",
        stock:"",
        markup_pct:"",
      });

      setErrors({
        descriptionError: false,
        stockError:false,
        markupPctError:false,
      });
    };
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === "add" ? 'Add Item' : ('Edit Item : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.75rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <MDTypography variant="h6" color="white">
                    {action === "add" ? 'Add Item' : ('Edit Item : ' + headerTitle)}
                  </MDTypography>
                </MDBox>
                <MDBox py={3}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Description"
                            fullWidth
                            value={inputs.description}
                            name="description"
                            inputProps={{'maxLength':80}}
                            onChange={changeHandler}
                            error={errors.descriptionError}
                            required
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                            <MDSelect
                             label="Group"
                             fullWidth
                             name="group_id"
                             value={inputs.group_id}
                             onChange={changeHandler}
                             options={groupDropdown}
                            />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                             label="Purchase Unit"
                             fullWidth
                             name="purchase_unit_id"
                             value={inputs.purchase_unit_id}
                             onChange={changeHandler}
                             options={unitDropdown}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                             label="Inventory Unit"
                             fullWidth
                             name="inventory_unit_id"
                             value={inputs.inventory_unit_id}
                             onChange={changeHandler}
                             options={unitDropdown}
                             onKeyPress={(e) => {
                              console.log('hi');
                             }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Stock"
                            fullWidth
                            value={inputs.stock}
                            name="stock"
                            inputProps={{'maxLength':7}}
                            onChange={changeHandler}
                            error={errors.stockError}
                            helperText={errors.stockError ? "Please enter a valid number." : ""}
                            disabled={true}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Markup %"
                            fullWidth
                            value={inputs.markup_pct ? inputs.markup_pct : ''}
                            name="markup_pct"
                            onChange={changeHandler}
                            error={errors.markupPctError}
                            helperText={errors.markupPctError ? "Please enter a valid number. It cannot have value more than 3 digit before decimal and more than 4 digits after the decimal point." : ""}
                            required
                          />
                        </MDBox>
                      </Grid>
                      {action === 'edit' &&
                        <>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                name=""
                                value={createdBy}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={dayjs(inputs.created_at).format('DD-MM-YYYY HH:mm A')}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      }
                      <Grid item md={6} xs={6} px={3}>
                        <MDBox mb={2}>
                          <MDSwitch label="Item Supplied By Customer" checked={customerSupplied ? true : false} onChange={() => setCustomerSupplied(prev => !prev)} inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={6} px={3}>
                        <MDBox mb={2}>
                          <MDSwitch label="Active" checked={active ? true : false} onChange={() => setActive(prev => !prev)} inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                        </MDBox>
                      </Grid>
                      <Grid item md={10} xs={8}></Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={2}>
                          <MDButton component={Link} to={`/manage-items`} variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
