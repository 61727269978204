/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDTypography from 'components/MDTypography';
import DataTable from "examples/Tables/DataTable";
import { PurchaseOrderContext } from "context";

// @mui icons
import SupplierItemService from "services/supplier-item-service";
import PoItemService from "services/po-item-service";
import { Icon } from "@mui/material";
import MDCircularProgress from "components/MDCircularProgress";

function PoItem({supplierId, numberFormat, onlyNumbers, isCompleted}) {
  const purchaseOrderContext = useContext(PurchaseOrderContext);
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const poItem = purchaseOrderContext.item;
  const poTotal = purchaseOrderContext.poTotal;
  const [itemDropdown, setItemDropdown] = useState([]);
  const [otherData, setOtherData] = useState([]);

  const getItems = async() => {
    let response = [];
    if (supplierId) {
      response = await SupplierItemService.getItemsBySupplierId(supplierId, '', '', '');
    }
    if (response) {
      var dataArray = [];
      var otherArray = [];
      response?.map((eachData, index) => {
        dataArray[index] = {
            key: eachData.id,
            value: eachData.item.description,
            disabled: eachData?.active ? false : true,
        }
        otherArray[eachData.id] = {
          size_id: eachData.size_id,
          item_id: eachData.item_id,
          gsm: eachData.gsm,
          rate: eachData.purchase_rate,
          size_name: eachData.size?.name,
      }
      });
      setOtherData(otherArray);
      setItemDropdown(dataArray);
    }
  }

  useEffect(() => {
    getItems();
  }, []);

  const onChangeItemField = (index, fieldName, fieldValue, otherDataArray) => {
    const clonePoItem = [...poItem];
    clonePoItem[index][fieldName] = fieldValue;
    const prevAmount = clonePoItem[index]['amount'];
    if (fieldName == 'supplier_item_id') {
      clonePoItem[index]['item_id'] = (fieldValue > 0) ? otherDataArray[clonePoItem[index]['supplier_item_id']]['item_id'] : '';
      clonePoItem[index]['size_id'] = (fieldValue > 0) ? otherDataArray[clonePoItem[index]['supplier_item_id']]['size_id'] : '';
      clonePoItem[index]['size_name'] = (fieldValue > 0) ? otherDataArray[clonePoItem[index]['supplier_item_id']]['size_name'] : '';
      clonePoItem[index]['gsm'] = (fieldValue > 0) ? otherDataArray[clonePoItem[index]['supplier_item_id']]['gsm'] : '';
      clonePoItem[index]['rate'] = (fieldValue > 0) ? otherDataArray[clonePoItem[index]['supplier_item_id']]['rate'] : '';
      if (fieldValue == '') {
        clonePoItem[index]['quantity'] = 0;
      }
    }
    let amount = (clonePoItem[index]['quantity'] * clonePoItem[index]['rate']);
    amount = Number(amount) - Number((amount * clonePoItem[index]['discount_pct'])/100);
    clonePoItem[index]['amount'] = amount ? amount.toFixed(2) : 0.00;
    let diff = Number(clonePoItem[index]['amount']) - Number(prevAmount);
    clonePoItem[index]['loading'] = false;
    purchaseOrderContext.setPoAmount(Number(poTotal) + Number(diff));
    purchaseOrderContext.setPoItem(clonePoItem);
  };

  const refreshRate = async(index, fieldName, fieldValue) => {
    const clonePoItem = [...poItem];
    clonePoItem[index]['loading'] = true;
    purchaseOrderContext.setPoItem(clonePoItem);

    let response = [];
    if (supplierId) {
      response = await SupplierItemService.getItemsBySupplierId(supplierId, '', '', '');
    }
    var dataArray = [];
    var otherArray = [];
    if (response) {
      response?.map((eachData, index) => {
        dataArray[index] = {
            key: eachData.id,
            value: eachData.item.description,
            disabled: eachData?.active ? false : true,
        }
        otherArray[eachData.id] = {
          size_id: eachData.size_id,
          item_id: eachData.item_id,
          gsm: eachData.gsm,
          rate: eachData.purchase_rate,
          size_name: eachData.size?.name,
      }
      });
      setOtherData(otherArray);
      setItemDropdown(dataArray);
    }
    onChangeItemField(index, fieldName, fieldValue, otherArray);
  }

  const rows = [];
  poItem?.map((eachItem, index) => {
    const cols = {
        item: (
            <MDSelect
                fullWidth
                value={eachItem?.supplier_item_id}
                onChange={(e) => onChangeItemField(index, 'supplier_item_id', e.target.value, otherData)}
                options={itemDropdown}
                sx={{
                  minWidth: 400,
                  "& .MuiNativeSelect-select": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
                disabled={isCompleted ? true : false}
                height="40px"
            />),
        size: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.size_name}
            </MDTypography>),
        gsm: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.gsm ? numberFormat(eachItem?.gsm, false) : ''}
            </MDTypography>
            ),
        quantity: (
            <MDInput
                type="text"
                value={eachItem?.quantity}
                onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeItemField(index, 'quantity', e.target.value, otherData) : ''}}
                sx={{
                  width: '5.5rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
                disabled={isCompleted ? true : false}
            />
            ),
        rate: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.rate ? numberFormat(eachItem?.rate, true, 4) : ''}
            </MDTypography>
            ),
        refresh_rate: ((eachItem?.supplier_item_id <= 0) || (isCompleted)) ? (<></>) : (
          <MDTypography sx={{cursor: "pointer"}} title="Refresh Rate" display="block" variant="caption" color="info" fontWeight="medium" onClick={(e) => refreshRate(index, 'supplier_item_id', eachItem?.supplier_item_id)}>
            {eachItem?.loading ? <MDCircularProgress
                                size={20}
                                color="info"
                              />: <Icon fontSize="small">refresh_icon</Icon>}
          </MDTypography>),
        discount_pct: (
          <MDInput
              type="text"
              value={eachItem?.discount_pct ? eachItem?.discount_pct : ''}
              onChange={(e) => {onlyNumbers(e.target.value, 'pos_percentage') ? onChangeItemField(index, 'discount_pct', e.target.value, otherData) : ''}}
              sx={{
                width: '5.5rem',
                "& .MuiInputBase-input": {
                  padding: "0.5rem",
                  fontSize: "13px",
                }
              }}
              disabled={isCompleted ? true : false}
          />
          ),
        amount: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {(eachItem?.amount || eachItem?.quantity) ? numberFormat(eachItem?.amount) : ''}
            </MDTypography>
            ),
    };
    rows[index] = cols;
  });

  const columns = [
    { Header: "Item", accessor: "item", width: "30%", align: "left" },
    { Header: "Size", accessor: "size", width: "18%", align: "left" },
    { Header: "GSM", accessor: "gsm", width: "10%", align: "right" },
    { Header: "Quantity", accessor: "quantity", width: "10%", align: "center" },
    { Header: "Rate", accessor: "rate", width: "10%", align: "right" },
    { Header: "", accessor: "refresh_rate", width: "1%", align: "left" },
    { Header: "Disc. %", accessor: "discount_pct", width: "10%", align: "center" },
    { Header: "Amount", accessor: "amount", width: "20%", align: "right" },
  ];

  return (
    <Card>
        <MDBox>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder={true}
            doubleClick={false}
            thBgColor="#a195952e"
            verticalRowPadding={0.5}
            horizontalRowPadding={0.5}
            tableBorderRadius={"0.35rem"}
            headerFontSize={"0.75rem"}
          />
        </MDBox>
      </Card>
  );
}

export default PoItem;
