/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useCallback } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy, useExpanded } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function DataTable({
  entriesPerPage,
  canSearch,
  canFilter,
  showFilter,
  filterOn,
  filterOff,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  borderTop,
  totalData,
  initialPageIndex,
  getData,
  pageCount,
  sortCol,
  sortColValue,
  searchVal,
  doubleClick,
  verticalRowPadding,
  horizontalRowPadding,
  tableBorderRadius,
  thBgColor,
  noRowBottomBorder,
  headerFontSize,
  renderRowSubComponent,
  handleRowClick,
  rowExpanded,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const initialPageIndx = initialPageIndex ? initialPageIndex : 0;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: initialPageIndx, pageSize: defaultValue, expanded: rowExpanded }, autoResetExpanded: false, manualPagination: true, pageCount: pageCount, autoResetPage: false, manualSortBy: true},
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, globalFilter, expanded },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  // useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const [sortColumn, setSortColumn] = useState(sortCol);
  const [sortColumnValue, setSortColumnValue] = useState(sortColValue);
  const [searchWord, setSearchWord] = useState(searchVal);

  const fetchData = useCallback(() => { getData(pageIndex + 1, pageSize, sortColumn, sortColumnValue, searchWord) },[pageIndex, pageSize, sortColumn, sortColumnValue, searchWord])
  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    if (getData) {
        fetchData();
    }
  }, [fetchData]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  let pageFlag = 0;
  const paginateSkipBtn = {
    ':hover': {
      backgroundColor: '#fff',
      cursor: 'default',
    }
  };
  let renderPagination = [];
  if (pageOptions?.length > 7) {
    renderPagination = pageOptions.map((option) => (
      <>
      {((option == 0) ||
        (option == pageOptions.length-1) ||
        (option == pageIndex) ||
        (pageIndex < 4 && option < 5) ||
        (option == pageIndex+1) ||
        (option == pageIndex-1) ||
        ((pageIndex >= (pageOptions.length - 4)) && option >= (pageOptions.length - 5))
       ) ?
       (() => {
         pageFlag = 1; // set pageFlag to 1
         return (
          <MDPagination
            item
            key={option}
            onClick={() => {gotoPage(Number(option));}}
            active={pageIndex === option}
          >
            {option + 1}
          </MDPagination>
          )
      })() :
      (() => {
      if (pageFlag === 1) {
        pageFlag = 0; // set pageFlag to 0
        return (
          <MDPagination
            item
            sx={paginateSkipBtn}
          >
            {"..."}
          </MDPagination>
        );
      }
    })()
      }
      </>
    ));
  } else {
    renderPagination = pageOptions.map((option) => (
      <MDPagination
        item
        key={option}
        onClick={() => {gotoPage(Number(option));}}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));

  }

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(searchVal ? searchVal : globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    // setGlobalFilter(value || undefined);
    gotoPage(0);
    setSearchWord(value);
  }, 500);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (column.Header == 'actions' || column.Header == 'Actions') {
      sortedValue = false;
    } else if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 && totalData === 0 ? 0 : (pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1);

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0 && totalData < pageSize) {
    entriesEnd = totalData;
  } else if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = totalData;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{ boxShadow: "none", borderRadius: tableBorderRadius }}>
      {entriesPerPage || canSearch || canFilter ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1.5} pr={2} pb={1} pl={2}>
          {canFilter && (
            <MDBox mr={2} display="flex" alignItems="center">
              {showFilter ?
                <MDButton variant="gradient" color={"primary"} fullWidth onClick={filterOff()} iconOnly title={"Hide Filter"}>
                  <Icon sx={{ fontWeight: "bold" }}>filter_alt_off_icon</Icon>
                </MDButton>
                : <MDButton variant="gradient" color={"success"} fullWidth onClick={filterOn()} iconOnly title={"Show Filter"}>
                  <Icon sx={{ fontWeight: "bold" }}>filter_alt_icon</Icon>
                </MDButton>
              }
            </MDBox>
          )}
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  gotoPage(0);
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(currentTarget.value);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps({'sortColumn': setSortColumn, 'sortColumnValue': setSortColumnValue}))}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                  isSorted={isSorted}
                  column={column}
                  verticalRowPadding={verticalRowPadding}
                  horizontalRowPadding={horizontalRowPadding}
                  thBgColor={thBgColor}
                  headerFontSize={headerFontSize}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {(totalData === 0 && data?.length === 1) ?
            page.map((row, key) => {
              prepareRow(row);
              return (
                <>
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noRowBottomBorder ? true : noEndBorder && rows.length - 1 === key}
                      borderTop={borderTop ? borderTop : false}
                      align={cell.column.align ? cell.column.align : "left"}
                      doubleClick={doubleClick}
                      verticalRowPadding={verticalRowPadding}
                      horizontalRowPadding={horizontalRowPadding}
                      id={row.cells[0]['value']['props']['children']}
                      header={cell['column']['Header']}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
                <TableRow >
                  <DataTableBodyCell
                    colSpan={headerGroups[0].headers.length}
                    align="center"
                  >
                    No records found
                  </DataTableBodyCell>
                </TableRow>
                </>
              );
            })
            :
            ((totalData === 0) ?
            <TableRow >
              <DataTableBodyCell
                colSpan={headerGroups[0].headers.length}
                align="center"
              >
                No records found
              </DataTableBodyCell>
            </TableRow>
            :
            page.map((row, key) => {
              prepareRow(row);
              return (
                <>
                <TableRow {...row.getRowProps({ onClick: () => handleRowClick(row) })}>
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noRowBottomBorder ? true : noEndBorder && rows.length - 1 === key}
                      borderTop={borderTop ? borderTop : false}
                      align={cell.column.align ? cell.column.align : "left"}
                      doubleClick={doubleClick}
                      verticalRowPadding={verticalRowPadding}
                      horizontalRowPadding={horizontalRowPadding}
                      id={row.cells[0]['value']['props']['children']}
                      header={cell['column']['Header']}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
                </>
              );
            }))
          }
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : ((!showTotalEntries && pageOptions.length === 0 && data?.length > 0) ? 0 : ((!showTotalEntries && pageOptions.length === 0 && data?.length === 0) ? 1.5 : 1))}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {totalData} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  canFilter: false,
  showFilter: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  borderTop: false,
  doubleClick: true,
  verticalRowPadding: 0.75,
  horizontalRowPadding: 2,
  tableBorderRadius: "0.75rem",
  thBgColor: '',
  headerFontSize: '',
  renderRowSubComponent: () => {},
  handleRowClick: () => {},
  rowExpanded: {},
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  canFilter: PropTypes.bool,
  showFilter: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  borderTop: PropTypes.bool,
};

export default DataTable;
