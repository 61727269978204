import dayjs from 'dayjs';

export const PDFTemplate = (supplierInvoiceRegister, grandTotal, numberFormat) => {
    return  `<html>
  <head>
    <title>supplier_invoice_register_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Supplier Invoice Register</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            ${supplierInvoiceRegister?.map((eachItem, index) => {
                let rowColor = '#effbfb';
                return `
                    <tr style="background-color: #f8ece0;">
                        <td colspan="8" style="border-bottom: 1px solid #000;"><b>Supplier Name: </b>${eachItem?.supplier_name}&nbsp;&nbsp;&nbsp;&nbsp;<b>Bill #:</b> ${eachItem?.bill_no ? eachItem?.bill_no : ''}&nbsp;&nbsp;&nbsp;&nbsp;<b>Bill Date:</b> ${eachItem?.bill_date}</td>
                    </tr>
                    <tr>
                        <th style="width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">GRN#</th>
                        <th style="width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">PO#</th>
                        <th style="width: 25%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Item</th>
                        <th style="width: 18%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Size</th>
                        <th style="width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">GSM</th>
                        <th style="width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Quantity</th>
                        <th style="width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Rate</th>
                        <th style="width: 15%; font-size: 0.875rem; border-bottom: 1px solid #000;">Amount</th>
                    </tr>
                    ${eachItem?.items?.map((item) => {
                        rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                        return `
                            <tr style="background-color: ${rowColor}">
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.grn_no}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.purchase_order_id}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.item}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.size}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.gsm}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.quantity}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${numberFormat(item?.rate, true, 4)}</td>
                                <td style="font-size: 0.775rem; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.amount)}</td>
                            </tr>
                        `
                    }). join('')}
                    <tr>
                        <td colspan="7" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right; border-bottom: 1px solid #000;"><b>Total:</b></td>
                        <td style="font-size: 0.875rem; text-align: right; border-bottom: 1px solid #000;">${numberFormat(eachItem?.total)}</td>
                    </tr>
                `
            }).join('')}
            <tr>
                <td colspan="7" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Grand Total:</b></td>
                <td style="font-size: 0.875rem; text-align: right;">${numberFormat(grandTotal)}</td>
            </tr>        
        </table>
      </center>
    </div>
  </body>
</html>`

}
