import HttpService from "./htttp.service";

class PoItemService {

  getPoItems = async(id) => {
    let fetchUrl = 'po_items';
    if (id) {
      fetchUrl = 'po_items?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addPoItem = async(data) => {
    const fetchUrl = 'po_items';
    return await HttpService.post(fetchUrl, data);
  }

  updatePoItem = async (id, data) => {
    const fetchUrl = 'po_items/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showPoItem = async(id) => {
    const fetchUrl = 'po_items/' + id;
    return await HttpService.get(fetchUrl);
  }

  deletePoItem = async (id) => {
    const fetchUrl = 'po_items/' + id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllPoItem = async (purchase_order_id) => {
    const fetchUrl = 'po_items?purchase_order_id=' + purchase_order_id;
    return await HttpService.delete(fetchUrl);
  }

  getPoItemsWithPendingReceipts = async(supplierId, cancelledStatus) => {
    const fetchUrl = 'get_open_po_items?supplier_id=' + supplierId + '&cancelled_status=' + cancelledStatus;
    return await HttpService.get(fetchUrl);
  }
}

export default new PoItemService();
