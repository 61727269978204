/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDTypography from 'components/MDTypography';
import DataTable from "examples/Tables/DataTable";
import { GrnContext } from "context";
import MDCheckbox from "components/MDCheckbox";


function GrnItem({grnItems, numberFormat, onlyNumbers, isCompleted, openAlertSB}) {
  const grnContext = useContext(GrnContext);
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const grnItem = grnItems;

  const onChangeItemField = (index, fieldName, fieldValue) => {
    const cloneGrnItem = [...grnItem];
    const receivedQty = cloneGrnItem[index]['quantity'] - cloneGrnItem[index]['received_qty'];
    cloneGrnItem[index][fieldName] = fieldValue;

    if (fieldName == "new_received_qty" && fieldValue > receivedQty) {
        cloneGrnItem[index]['new_received_qty'] = receivedQty;
        openAlertSB({color: 'warning', icon: 'star', msg: 'Quantity can not be grater than PO quantity', title: 'GRN'});
    }
    const amount = cloneGrnItem[index]['new_received_qty'] * cloneGrnItem[index]['rate'];
    cloneGrnItem[index]['amount'] = Number(amount) - Number((amount * cloneGrnItem[index]['discount_pct'])/100) ;
    grnContext.setGrnItem(cloneGrnItem);
  };

  const rows = [];
  let totalAmount = 0;
  let grnAmount = 0;
  grnItem?.map((eachItem, index) => {
    // let amount = eachItem?.new_received_qty * eachItem?.rate;
    totalAmount = Number(totalAmount) + Number(eachItem?.amount);
    grnAmount = eachItem.is_checked ? (Number(grnAmount) + Number(eachItem?.amount)) : (Number(grnAmount) + Number(0));
    const cols = {
        select: (
            <MDCheckbox
            checked={eachItem.is_checked}
            onChange={(e) => onChangeItemField(index, 'is_checked', e.target.checked)}
            disabled={isCompleted ? true : false}
          />),
        po: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.purchase_order_id}
            </MDTypography>),
        wo: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.work_order_id}
            </MDTypography>),
        customer: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.customer}
            </MDTypography>),
        job_type: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.job_type}
            </MDTypography>),
        item: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.item?.description}
            </MDTypography>),
        size: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.size?.name}
            </MDTypography>),
        gsm: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.gsm ? numberFormat(eachItem.gsm, false) : ''}
            </MDTypography>
            ),
        quantity: (
            <MDInput
                type="text"
                value={eachItem?.new_received_qty * 1}
                onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeItemField(index, 'new_received_qty', e.target.value) : ''}}
                sx={{
                  width: '5rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
                disabled={isCompleted ? true : false}
            />
            ),
        rate: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.rate ? numberFormat(eachItem.rate, true, 4) : ''}
            </MDTypography>
            ),
        amount: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.amount ? numberFormat(eachItem?.amount) : ''}
            </MDTypography>
            ),
    };
    rows[index] = cols;
  });

  grnContext.setGrnAmount(grnAmount);

  const columns = [
    { Header: "Select", accessor: "select", width: "4%", align: "center" },
    { Header: "PO#", accessor: "po", width: "4%", align: "left" },
    { Header: "WO#", accessor: "wo", width: "4%", align: "left" },
    { Header: "Party", accessor: "customer", width: "12%", align: "left" },
    { Header: "Job Type", accessor: "job_type", width: "18%", align: "left" },
    { Header: "Item", accessor: "item", width: "15%", align: "left" },
    { Header: "Size", accessor: "size", width: "8%", align: "left" },
    { Header: "GSM", accessor: "gsm", width: "8%", align: "right" },
    { Header: "Quantity", accessor: "quantity", width: "8%", align: "center" },
    { Header: "Rate", accessor: "rate", width: "8%", align: "right" },
    { Header: "Amount", accessor: "amount", width: "8%", align: "right" },
  ];

  return (
    <Card sx={{borderRadius: "0.35rem"}}>
        <MDBox>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder={true}
            doubleClick={false}
            thBgColor="#a195952e"
            verticalRowPadding={0.5}
            horizontalRowPadding={0.5}
            tableBorderRadius={"0.35rem"}
            headerFontSize={"0.75rem"}
          />
        </MDBox>
        <MDBox mt={3} mb={1}>
            <Grid container>
                <Grid item xs={12} md={10}></Grid>
                <Grid item xs={12} md={2} textAlign="right">
                    <MDTypography display="block" variant="subtitle2" mr={2} sx={{fontWeight: "500"}}>
                        {numberFormat(totalAmount)}
                    </MDTypography>
                </Grid>
            </Grid>
        </MDBox>
      </Card>
  );
}

export default GrnItem;
