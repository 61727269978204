/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const customRoutes = [
  // {
  //     "name": "Dashboard",
  //     "route": "dashboard_header",
  //     "order": 1,
  //     "indent": 1,
  //     "is_heading": 1
  // },
      {
        "name": "Work Order",
        "route": "work_order",
        "order": 11,
        "indent": 1
      },
      {
        "name": "Material Tab",
        "route": "material_tab",
        "order": 12,
        "indent": 2
      },
      {
        "name": "Printing Tab",
        "route": "printing_tab",
        "order": 13,
        "indent": 2
      },
      {
        "name": "Material From Stock Tab",
        "route": "material_from_stock_tab",
        "order": 14,
        "indent": 2
      },
      {
        "name": "Other Items Tab",
        "route": "other_items_tab",
        "order": 15,
        "indent": 2
      },
      {
        "name": "Payment Tab",
        "route": "payment_tab",
        "order": 16,
        "indent": 2
      },
      {
        "name": "Completion Date",
        "route": "completion_date",
        "order": 17,
        "indent": 2
      },
      {
        "name": "Wo Approve",
        "route": "work_order_approve",
        "order": 18,
        "indent": 2
      },
      {
        "name": "Wo Rates",
        "route": "wo_rates",
        "order": 19,
        "indent": 2
      },
];

export default customRoutes;
