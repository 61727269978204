import dayjs from 'dayjs';

export const PDFTemplate = (supplierItemRegister, numberFormat) => {
    return  `<html>
  <head>
    <title>supplier_item_register_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Supplier Item Register</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 60%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: left !important;">Item</th>
                <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: left !important;">Unit</th>
                <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-bottom: 1px solid #000; text-align: left !important;">Rate</th>
            </tr>
            ${supplierItemRegister?.map((eachItem, index) => {
                let rowColor = '#e0f8f7';
                return `
                    <tr style="background-color: #f8ece0;">
                        <td colspan="3" style="border-bottom: 1px solid #000;"><b>Supplier : </b>${eachItem?.supplier_name}</td>
                    </tr>
                    ${eachItem?.supplierItems?.map((item) => {
                        if (item?.item_name || item?.unit_name || (item?.purchase_rate > 0)) {
                            rowColor = rowColor = '#e0f8f7' ? '#effbfb' : '#e0f8f7';
                            return `
                                <tr style="background-color: ${rowColor}">
                                    <td style="font-size: 0.775rem; text-align: left !important; border-right: 1px solid #000; border-bottom: ${(index + 1) != supplierItemRegister?.length ? '1px solid #000' : ''};">${item?.item_name ? item?.item_name : ''}</td>
                                    <td style="font-size: 0.775rem; text-align: left !important; border-right: 1px solid #000; border-bottom: ${(index + 1) != supplierItemRegister?.length ? '1px solid #000' : ''};">${item?.unit_name ? item?.unit_name : ''}</td>
                                    <td style="font-size: 0.775rem; text-align: right; border-bottom: ${(index + 1) != supplierItemRegister?.length ? '1px solid #000' : ''};">${item?.purchase_rate}</td>
                                </tr>
                            `
                        }
                    }). join('')}
                `
            }).join('')}        
        </table>
      </center>
    </div>
  </body>
</html>`

}
