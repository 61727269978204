/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

import { useState, useEffect, useContext } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import customAlert from "components/MDAlert/customAlert";
import { PreserveTable } from "context";
import ItemService from "services/item-service";
import authPermission from "utility/auth-permissions";

// @mui icons
import Icon from "@mui/material/Icon";
import { useCache } from "context";

export default function data() {
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-items');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.item;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const { cacheItems, setCacheItems } = useCache();

  const [itemData, setItemData] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [totalData, setTotalData] = useState(0);
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const getItemData = async (page_no, page_size, sort_column, sort_column_value, search_value) => {
    const response = await ItemService.getItems(page_no, page_size, sort_column, sort_column_value, search_value);
    setItemData(response?.data);
    setTotalData(response?.total);
  };

  useEffect(() => {
      if (location?.state?.color) {
        openAlertSB(location?.state);
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
      }
      //getItemData(page_no, page_size, sort_column, sort_column_value, search_value);
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveItem(preserveData);
    getItemData(page_no, page_size, sortColumn, sortColumnValue, searchWord);
  };

  const updateItemStatus = async (id, status) => {
    try {
      var data = {'active': status};
      const res = await ItemService.updateItemStatus(id, data);
      if (res) {
        if (cacheItems.hasOwnProperty('items')) {
          // Create a new object without the key
          const { items, ...rest } = cacheItems; // Destructure to exclude key
          setCacheItems(rest); // Set the new cache items without key
        }
        openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Item'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        getItemData(page_no, page_size, sort_column, sort_column_value, search_value);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const deleteItem = async (id) => {
    try {
      const response = await ItemService.deleteItem(id);
      if (response) {
        if (cacheItems.hasOwnProperty('items')) {
          // Create a new object without the key
          const { items, ...rest } = cacheItems; // Destructure to exclude key
          setCacheItems(rest); // Set the new cache items without key
        }
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    setSelectedId('');
    handleClose();
    getItemData(page_no, page_size, sort_column, sort_column_value, search_value);
  };

  let rows = [];
  itemData?.map((eachItem, index) => {
      const cols = {
        id:(
              <MDTypography component={Link} to={pagePermission.edit ? `/manage-items/edit?id=${eachItem.id}` : ''} variant="body2" color="text">
                {eachItem?.id}
              </MDTypography>
            ),
        description:(
          <MDTypography display="block" variant="body2" title={eachItem?.description}>
            {eachItem?.description}
          </MDTypography>
          ),
        group_id:(
          <MDTypography display="block" variant="body2" title={eachItem?.group?.name}>
          {eachItem?.group ? (eachItem?.group.name) : ('')}
          </MDTypography>
          ),
        purchase_unit_id:(
          <MDTypography display="block" variant="body2">
          {eachItem?.purchase ? (eachItem?.purchase.name) : ('')}
          </MDTypography>
          ),
        inventory_unit_id:(
          <MDTypography display="block" variant="body2">
          {eachItem?.inventory ? (eachItem?.inventory.name) : ('')}
          </MDTypography>
        ),
        customer_supplied:(
          <MDTypography display="block" variant="body2">
          {eachItem?.customer_supplied ? 'Yes' : 'No'}
          </MDTypography>
        ),
        action:(
              <MDBox display="flex">
                {pagePermission.status ? (
                  <MDSwitch checked={eachItem?.active ? true : false} onChange={() => updateItemStatus(eachItem?.id, !eachItem?.active)} inputProps={{ 'aria-label': 'controlled' }} title="Status" color="info"/>
                ) : ''}
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-items/edit?id=${eachItem?.id}`} title="Edit" display="block" variant="body2" color="info">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
              ) : ''}
              {pagePermission.new ? (
                <MDTypography mr={1} mt={1} component={Link} to={`/manage-items/add?id=${eachItem.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                  <Icon fontSize="small">content_copy_icon</Icon>
                </MDTypography>
              ) : ''}
              {pagePermission.remove ? (
                  <MDTypography mt={1} component="a"
                  onClick={() => handleClickOpen(eachItem?.id)}
                  title="Remove"
                  display="block" variant="body2" color="error">
                    <Icon fontSize="small">delete</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            ),
      };
      rows[index] = cols;
  });

  return {
    columns: [
      { Header: "id", accessor: "id", width: "8%", align: "left" },
      { Header: "Description", accessor: "description", sorting_key: 'items.description', width: "27%", align: "left" },
      { Header: "Group", accessor: "group_id", sorting_key: 'mg.name', width: "11%", align: "left" },
      { Header: "Purchase Unit", accessor: "purchase_unit_id", sorting_key: 'pu.name', width: "12%", align: "center" },
      { Header: "Inventory Unit", accessor: "inventory_unit_id", sorting_key: 'iu.name', width: "12%", align: "center" },
      { Header: "Item Supplied By Customer", accessor: "customer_supplied", sorting_key: 'items.customer_supplied', width: "20%", align: "center" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "10%", align: "center" }] : [],
    ],

    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deleteItem: deleteItem,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
