import HttpService from "./htttp.service";

class WoPrintingItemService {

  getWoPrintingItems = async(id='', woPrintingItemId='', operator_id='') => {
    let fetchUrl = 'wo_printing_items';
    if (operator_id) { // to check if any job is In Progress for this operator id
      fetchUrl = 'wo_printing_items?operator_id=' + operator_id;
    } else if (id && woPrintingItemId) {
      fetchUrl = 'wo_printing_items?id=' + id + '&wo_printing_item_id=' + woPrintingItemId;
    } else if (id) {
      fetchUrl = 'wo_printing_items?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addWoPrintingItem = async(data) => {
    const fetchUrl = 'wo_printing_items';
    return await HttpService.post(fetchUrl, data);
  }

  updateWoPrintingItem = async (id, data) => {
    const fetchUrl = 'wo_printing_items/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showWoPrintingItem = async(id) => {
    const fetchUrl = 'wo_printing_items/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteWoPrintingItem = async (id) => {
    const fetchUrl = 'wo_printing_items/' + id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllWoPrintingItem = async (work_order_id) => {
    const fetchUrl = 'wo_printing_items?work_order_id=' + work_order_id;
    return await HttpService.delete(fetchUrl);
  }

  approveWoPrintingItem = async (work_order_id) => {
    const fetchUrl = 'wo_printing_items_approve?work_order_id=' + work_order_id;
    return await HttpService.patch(fetchUrl);
  }
}

export default new WoPrintingItemService();
