/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCheckbox from 'components/MDCheckbox';
import DataTable from "examples/Tables/DataTable";
import { WorkOrderContext } from "context";
import authPermission from "utility/auth-permissions";

// @mui icons
import WoStockItemService from "services/wo-stock-item-service";
import ItemService from "services/item-service";
import { useCache } from "context";

function Stock({numberFormat, isCancel, openAlertSB, onlyNumbers}) {

  const workOrderContext = useContext(WorkOrderContext);
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { hasPermission, systemConstant } = authPermission();
  const stockItem = workOrderContext.stockItem;
  const otherItemData = workOrderContext.otherData;
  // const [stockItem, setStockItem] = useState([]);
  const [itemDropdown, setItemDropdown] = useState([]);
  // const [otherItemData, setOtherItemData] = useState([]);
  const { cacheItems, setCacheItems } = useCache();
  useEffect(() => {

    const getItems = async() => {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('items')) {
          response = cacheItems['items'];
        } else {
          response = await ItemService.getItems(); // Fetch customers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              items: response,
          }));
        }

        if (response) {
          var dataArray = [];
          var otherDetail = [];
          response?.map((eachData, index) => {
            if (eachData.stock > 0) {
              dataArray[index] = {
                key: eachData.id,
                value: eachData.description,
                disabled: eachData?.active ? false : true,
              }
              const eachStockItem = stockItem.filter((option) => option?.item_id == eachData.id);
              let qty = 0;
              if (id && action === 'add' && (eachStockItem?.length > 0)) {
                eachStockItem?.map((eachStock, index) => {
                  qty = qty + eachStock?.quantity;
                });
              }
              otherDetail[eachData.id] = {
                opening_stock: (id && action === 'add' && (eachStockItem?.length > 0)) ? (eachData.stock - qty) : eachData.stock,
                rate: eachData.rate,
                markup_pct: eachData.markup_pct,
              }
            }
          });

          stockItem?.map( async (eachStock, index) => {
            if (eachStock?.id > 0) {
              const data = dataArray.filter((option) => option?.key == eachStock?.item_id);
              if (data?.length == 0) {
                dataArray[dataArray?.length] = {
                  key: eachStock?.item_id,
                  value: eachStock?.item.description,
                  disabled: true,
                }
                otherDetail[eachStock?.item_id] = {
                  opening_stock: 0,
                  rate: eachStock?.item.rate,
                  markup_pct: eachStock?.item.markup_pct,
                }
              }
            }
          });

          if (otherItemData.length == 0) {
            workOrderContext.setItemOtherData(otherDetail);
          }

          setItemDropdown(dataArray);
        }
    }
    getItems();
  }, []);

  const onChangeStockField = (index, fieldName, fieldValue) => {
    const cloneStockItem = [...stockItem];
    const cloneOtherData = [...otherItemData];
    let woTotal = workOrderContext.woTotal;
    let item = cloneStockItem[index]['item_id'];
    let prevQty = cloneStockItem[index]['quantity'];
    let prevTotal = cloneStockItem[index]['total'];

    cloneStockItem[index][fieldName] = fieldValue;
    if (fieldName == 'quantity') {
      let currentOpeningStock = Number(cloneOtherData[item]['opening_stock']) + Number(prevQty);

      if (fieldValue > currentOpeningStock) {
        cloneStockItem[index]['quantity'] = prevQty;
        openAlertSB({color: 'warning', icon: 'star', msg: 'Quantity can not be more than opening stock (' + currentOpeningStock + ')', title: 'Work Order'});
      }
    }
    if (fieldName == 'item_id') {
      if (item) {
        cloneOtherData[item]['opening_stock'] = Number(cloneOtherData[item]['opening_stock']) + Number(prevQty);
      }
      cloneStockItem[index]['opening_stock'] = cloneOtherData[fieldValue] ? cloneOtherData[fieldValue]['opening_stock'] : 0;
      cloneStockItem[index]['rate'] = cloneOtherData[fieldValue] ? cloneOtherData[fieldValue]['rate'] : 0;
      cloneStockItem[index]['markup_pct'] = cloneOtherData[fieldValue] ? (Number(cloneOtherData[fieldValue]['markup_pct'])) * 1 : 0;
      cloneStockItem[index]['quantity'] = 0;
    } else if (fieldName != 'is_wo_day_wise') {
      let openingStock = cloneOtherData[cloneStockItem[index]['item_id']]['opening_stock'];
      let diff = Number(cloneStockItem[index]['quantity']) - Number(prevQty);
      cloneOtherData[cloneStockItem[index]['item_id']]['opening_stock'] = Number(openingStock) - Number(diff);
    }

    if (fieldName == 'total') {
      let amount = cloneStockItem[index]['quantity'] * cloneStockItem[index]['rate'];
      let total = cloneStockItem[index]['total'];
      let markup_pct = amount > 0 ? (((total - amount) * 100)/amount) : 0;
      cloneStockItem[index]['markup_pct'] = markup_pct > 0 ? markup_pct : 0;
    } else {
      let amount = cloneStockItem[index]['quantity'] * cloneStockItem[index]['rate'];
      let total = Number(amount) + Number((amount * cloneStockItem[index]['markup_pct'])/100);
      cloneStockItem[index]['total'] = total > 0 ? total.toFixed(2) : 0;
    }

    workOrderContext.setWoStockItem(cloneStockItem);
    if (fieldName == 'is_wo_day_wise') {
      woTotal = (fieldValue === true) ? (Number(woTotal) + Number(cloneStockItem[index]['total'])).toFixed(2) : (Number(woTotal) - Number(cloneStockItem[index]['total'])).toFixed(2)
      workOrderContext.setWoAmount(woTotal);
    } else {
      if (cloneStockItem[index]['is_wo_day_wise']) {
        let diff = Number(cloneStockItem[index]['total']) - Number(prevTotal);
        woTotal = Number(woTotal) + Number(diff);
        workOrderContext.setWoAmount(woTotal);
      }
    }
    workOrderContext.setItemOtherData(cloneOtherData);
  };

  const rows = [];
  stockItem?.map((eachItem, index) => {
    const cols = {
        is_day_wise:(
            <MDCheckbox
                checked={eachItem?.is_wo_day_wise}
                onChange={(e) => onChangeStockField(index, 'is_wo_day_wise', e.target.checked)}
                disabled={eachItem?.is_approved || isCancel ? true : false}
            />),
        item: (
            <MDSelect
                fullWidth
                value={eachItem?.item_id}
                onChange={(e) => onChangeStockField(index, 'item_id', e.target.value)}
                options={itemDropdown}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  minWidth: 430,
                  "& .MuiNativeSelect-select": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
                height="40px"
            />),
        opening_stock: (
            <MDTypography display="block" variant="body2"
            sx={{
              "&.MuiTypography-root": {
                fontSize: "14px",
              }
            }}
          >
                {eachItem?.opening_stock}
            </MDTypography>
            ),
        quantity: (
            <MDInput
                type="text"
                value={eachItem?.quantity}
                onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeStockField(index, 'quantity', e.target.value) : ''}}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: '5.5rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
            />
            ),
        rate: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
                {eachItem?.rate ? numberFormat(eachItem?.rate, true, 4) : ''}
            </MDTypography>),
        markup_pct: (
            <MDInput
                type="text"
                value={eachItem?.item_id ? eachItem?.markup_pct : ''}
                onChange={(e) => {onlyNumbers(e.target.value, 'percentage') ? onChangeStockField(index, 'markup_pct', e.target.value) : ''}}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: '5.5rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
            />),
        amount: (
            <MDInput
                type="text"
                value={eachItem?.total}
                onChange={(e) => {onlyNumbers(e.target.value, 'decimal(16,4)') ? onChangeStockField(index, 'total', e.target.value) : ''}}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: '7rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
            />),
    };
    rows[index] = cols;
  });

  const columns = [
    { Header: "Is Work Order Item?", accessor: "is_day_wise", width: "10%", align: "center" },
    { Header: "Item", accessor: "item", width: "30%", align: "left" },
    { Header: "Opening Stock", accessor: "opening_stock", width: "15%", align: "left" },
    { Header: "Quantity", accessor: "quantity", width: "10%", align: "center" },
    ...(hasPermission('wo_rates').view ? [{ Header: "Rate", accessor: "rate", width: "10%", align: "left" }] : []),
    ...(hasPermission('wo_rates').view ? [{ Header: "Markup %", accessor: "markup_pct", width: "10%", align: "center" }] : []),
    ...(hasPermission('wo_rates').view ? [{ Header: "Amount", accessor: "amount", width: "10%", align: "center" }] : []),
  ];

  return (
    <Card>
        <MDBox>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder={true}
            doubleClick={false}
            thBgColor="#a195952e"
            verticalRowPadding={0.2}
            horizontalRowPadding={0.5}
            tableBorderRadius={"0.35rem"}
            headerFontSize={"0.75rem"}
          />
        </MDBox>
      </Card>
  );
}

export default Stock;
