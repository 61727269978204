/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCheckbox from 'components/MDCheckbox';
import DataTable from "examples/Tables/DataTable";
import MDDialog from "components/MDDialog";
import MDTooltip from "components/MDTooltip";
import { WorkOrderContext } from "context";
import customAlert from "components/MDAlert/customAlert";
import MDCircularProgress from "components/MDCircularProgress";
import authPermission from "utility/auth-permissions";

// @mui icons
import Icon from "@mui/material/Icon";
import WoMaterialItemService from "services/wo-material-item-service";
import SupplierItemService from "services/supplier-item-service";
import { useCache } from "context";

const stageType = ['DESIGN','PAPER','PRINTING','BINDING','EXTRA'];

function Material({numberFormat, isCancel, onlyNumbers, isApproved}) {

  const workOrderContext = useContext(WorkOrderContext);
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { hasPermission, systemConstant } = authPermission();
  const materialItem = workOrderContext.materialItem;
  const stageTotals = workOrderContext.stageTotals;
  const [supplierDropdown, setSupplierDropdown] = useState([]);
  const [itemDropdown, setItemDropdown] = useState([]);
  const [otherDetail, setOtherDetail] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [selectedIndex, setSelectedIndex] = useState('');
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const { cacheItems, setCacheItems } = useCache();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (stage, index, id) => {
    setSelectedId(id);
    setSelectedStage(stage);
    setSelectedIndex(index);
    setOpen(true);
  };

  useEffect(() => {
    const fetchDropdown = async () => {
      const supplierArray = [];
      const itemsArray = [];
      const otherArray = [];

      const promises = stageType.map( async(stage) => {
        return SupplierItemService.getItemsByGroupName(stage);
      });

      try {
        const responses = await Promise.all(promises);
        responses?.map((response, mainIndex) => {
          let stageName = stageType[mainIndex];
          supplierArray[stageName] = [];
          itemsArray[stageName] = [];
          otherArray[stageName] = [];
          response?.map((eachSupplier, index) => {
            supplierArray[stageName][index] = {
              key: eachSupplier.id,
              value: eachSupplier.name,
              disabled: eachSupplier?.active ? false : true,
            };
            itemsArray[stageName][eachSupplier.id] = [];
            otherArray[stageName][eachSupplier.id] = [];
            eachSupplier?.supplier_items?.map((eachSupItem, itemIndex) => {
              itemsArray[stageName][eachSupplier.id][itemIndex] = {
                key: eachSupItem.id,
                value: eachSupItem.item.description,
                disabled: eachSupItem?.active ? false : true,
              };
              otherArray[stageName][eachSupplier.id][eachSupItem.id] = {
                size_id: eachSupItem.size_id,
                item_id: eachSupItem.item_id,
                size_name: eachSupItem.size?.name,
                gsm: eachSupItem.gsm,
                rate: eachSupItem.purchase_rate,
                markup_pct: eachSupItem.item.markup_pct,
              }
            })
          });
        });
        setOtherDetail(otherArray);
        setItemDropdown(itemsArray);
        setSupplierDropdown(supplierArray);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDropdown();
  }, []);

  const addRow = (stageName) => {
    const cloneMaterialItem = {...materialItem};
    cloneMaterialItem[stageName][cloneMaterialItem[stageName].length] = {
      work_order_id: id,
      id: 0,
      stage_type: stageName,
      supplier_id: '',
      supplier_item_id: '',
      item_id: '',
      size_id: '',
      size_name: '',
      gsm: '',
      quantity: '',
      rate: '',
      amount: '',
      markup_pct: '',
      markup_amount: '',
      is_po_generated: '',
      is_approved: 0
    };
    workOrderContext.setWoMaterialItem(cloneMaterialItem);
    // setMaterialItem(cloneMaterialItem);
  };

  const deleteRow = async (stage, index, id) => {
    const cloneMaterialItem = {...materialItem};
    const cloneStageTotals = {...stageTotals};
    let woTotal = workOrderContext.woTotal;
    cloneStageTotals[stage] = (cloneStageTotals[stage] - cloneMaterialItem[stage][index]['markup_amount']).toFixed(2);
    workOrderContext.setWoAmount((Number(woTotal) - Number(cloneMaterialItem[stage][index]['markup_amount'])).toFixed(2));
    cloneMaterialItem[stage].splice(index, 1);
    // if (id < 1) {
    //   cloneMaterialItem[stage].splice(index, 1);
    // } else {
    //   const deleteItem = await WoMaterialItemService.deleteWoMaterialItem(id);
    //   cloneMaterialItem[stage].splice(index, 1);
    // }
    workOrderContext.setWoMaterialItem(cloneMaterialItem);
    // setMaterialItem(cloneMaterialItem);
    workOrderContext.setMaterialTotal(cloneStageTotals);
  }

  const refreshRate = (stageNm, index, supplierId, supplierItemId) => {
    const supplierArray = [];
    const itemsArray = [];
    const otherArray = [];

    const updateRates = async () => {
      const promises = stageType.map( async(stage) => {
        return SupplierItemService.getItemsByGroupName(stage);
      });
      try {
        const cloneMaterialItem = {...materialItem};
        const cloneStageTotals = {...stageTotals};
        cloneMaterialItem[stageNm][index]['loading'] = true;

        const responses = await Promise.all(promises);
        responses?.map((response, mainIndex) => {
          let stageName = stageType[mainIndex];
          supplierArray[stageName] = [];
          itemsArray[stageName] = [];
          otherArray[stageName] = [];
          response?.map((eachSupplier, index) => {
            supplierArray[stageName][index] = {
              key: eachSupplier.id,
              value: eachSupplier.name,
              disabled: eachSupplier?.active ? false : true,
            };
            itemsArray[stageName][eachSupplier.id] = [];
            otherArray[stageName][eachSupplier.id] = [];
            eachSupplier?.supplier_items?.map((eachSupItem, itemIndex) => {
              itemsArray[stageName][eachSupplier.id][itemIndex] = {
                key: eachSupItem.id,
                value: eachSupItem.item.description,
                disabled: eachSupItem?.active ? false : true,
              };
              otherArray[stageName][eachSupplier.id][eachSupItem.id] = {
                size_id: eachSupItem.size_id,
                item_id: eachSupItem.item_id,
                size_name: eachSupItem.size?.name,
                gsm: eachSupItem.gsm,
                rate: eachSupItem.purchase_rate,
                markup_pct: eachSupItem.item.markup_pct,
              }
            })
          });
        });
        setOtherDetail(otherArray);
        setItemDropdown(itemsArray);
        setSupplierDropdown(supplierArray);

        cloneMaterialItem[stageNm][index]['rate'] = otherArray[stageNm][supplierId][supplierItemId]['rate'];

        let woTotal = workOrderContext.woTotal;
        let qty = cloneMaterialItem[stageNm][index]['quantity'];
        let rate = cloneMaterialItem[stageNm][index]['rate'];
        let markup = cloneMaterialItem[stageNm][index]['markup_pct'];
        let amount = cloneMaterialItem[stageNm][index]['amount'] = (qty * rate).toFixed(2);
        let markup_amount = Number(amount) + Number((amount * markup)/100);
        let diff = (markup_amount > 0 ? markup_amount.toFixed(2) : 0.00) - cloneMaterialItem[stageNm][index]['markup_amount'];
        cloneMaterialItem[stageNm][index]['markup_amount'] = markup_amount > 0 ? markup_amount.toFixed(2) : 0.00;
        cloneStageTotals[stageNm] = (Number(cloneStageTotals[stageNm]) + Number(diff)).toFixed(2);
        workOrderContext.setWoAmount((Number(woTotal) + Number(diff)).toFixed(2));

        workOrderContext.setWoMaterialItem(cloneMaterialItem);
        workOrderContext.setMaterialTotal(cloneStageTotals);
        cloneMaterialItem[stageNm][index]['loading'] = false;
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      }
    };
    updateRates();
  };

  const onChangeMaterialField = (stageName, index, fieldName, fieldValue) => {
    const cloneMaterialItem = {...materialItem};
    const cloneStageTotals = {...stageTotals};
    let markupAmount = cloneMaterialItem[stageName][index]['markup_amount'];
    cloneMaterialItem[stageName][index][fieldName] = fieldValue;

    if (fieldName === 'supplier_id') {
      cloneMaterialItem[stageName][index]['supplier_item_id'] = '';
      cloneMaterialItem[stageName][index]['size_name'] = '';
      cloneMaterialItem[stageName][index]['gsm'] = '';
      cloneMaterialItem[stageName][index]['rate'] = '';
      cloneMaterialItem[stageName][index]['amount'] = '';
      var filterSupplier = supplierDropdown[stageName].filter(eachSupplier => (eachSupplier.key == fieldValue));
      if (cloneMaterialItem[stageName][index]['supplier'] && filterSupplier?.length === 1) {
        cloneMaterialItem[stageName][index]['supplier']['id'] = fieldValue ? filterSupplier[0]['key'] : '';
        cloneMaterialItem[stageName][index]['supplier']['name'] = fieldValue ? filterSupplier[0]['value'] : '';
      } else if (cloneMaterialItem[stageName][index]['supplier']) {
        cloneMaterialItem[stageName][index]['supplier']['id'] = '';
        cloneMaterialItem[stageName][index]['supplier']['name'] = '';
      }
    } else if (fieldName === 'supplier_item_id') {
      cloneStageTotals[stageName] = (cloneStageTotals[stageName] - cloneMaterialItem[stageName][index]['markup_amount']).toFixed(2);
      let supId = cloneMaterialItem[stageName][index]['supplier_id'];
      cloneMaterialItem[stageName][index]['size_id'] = fieldValue ? otherDetail[stageName][supId][fieldValue]['size_id'] : '';
      cloneMaterialItem[stageName][index]['item_id'] = fieldValue ? otherDetail[stageName][supId][fieldValue]['item_id'] : '';
      cloneMaterialItem[stageName][index]['size_name'] = fieldValue ? otherDetail[stageName][supId][fieldValue]['size_name'] : '';
      cloneMaterialItem[stageName][index]['quantity'] = '0';
      cloneMaterialItem[stageName][index]['markup_pct'] = fieldValue ? (Number(otherDetail[stageName][supId][fieldValue]['markup_pct'])) * 1 : '';
      cloneMaterialItem[stageName][index]['gsm'] = fieldValue ? otherDetail[stageName][supId][fieldValue]['gsm'] : '';
      cloneMaterialItem[stageName][index]['rate'] = fieldValue ? otherDetail[stageName][supId][fieldValue]['rate'] : '';
      cloneMaterialItem[stageName][index]['amount'] = '0.00';
      cloneMaterialItem[stageName][index]['markup_amount'] = '0.00';
      var filterItem = itemDropdown[stageName][supId].filter(eachItem => (eachItem.key == fieldValue));
      if (cloneMaterialItem[stageName][index]['item'] && filterItem?.length === 1) {
        cloneMaterialItem[stageName][index]['item']['id'] = fieldValue ? filterItem[0]['key'] : '';
        cloneMaterialItem[stageName][index]['item']['description'] = fieldValue ? filterItem[0]['value'] : '';
      } else if (cloneMaterialItem[stageName][index]['item']) {
        cloneMaterialItem[stageName][index]['item']['id'] = '';
        cloneMaterialItem[stageName][index]['item']['description'] = '';
      }
    } else if (fieldName === 'markup_amount') {
        let woTotal = workOrderContext.woTotal;
        let amount = cloneMaterialItem[stageName][index]['quantity'] * cloneMaterialItem[stageName][index]['rate'];
        let total = cloneMaterialItem[stageName][index]['markup_amount'];
        let markup_pct = amount > 0 ? (((total - amount) * 100)/amount) : 0;
        cloneMaterialItem[stageName][index]['markup_pct'] = markup_pct * 1;
        let diff = Number(total).toFixed(2) - markupAmount;
        cloneStageTotals[stageName] = (Number(cloneStageTotals[stageName]) + Number(diff)).toFixed(2);
        workOrderContext.setWoAmount((Number(woTotal) + Number(diff)).toFixed(2));
    } else {
        let woTotal = workOrderContext.woTotal;
        let qty = cloneMaterialItem[stageName][index]['quantity'];
        let rate = cloneMaterialItem[stageName][index]['rate'];
        let markup = cloneMaterialItem[stageName][index]['markup_pct'];
        let amount = cloneMaterialItem[stageName][index]['amount'] = (qty * rate).toFixed(2);
        let markup_amount = Number(amount) + Number((amount * markup)/100);
        let diff = markup_amount.toFixed(2) - cloneMaterialItem[stageName][index]['markup_amount'];
        cloneMaterialItem[stageName][index]['markup_amount'] = markup_amount.toFixed(2);
        cloneStageTotals[stageName] = (Number(cloneStageTotals[stageName]) + Number(diff)).toFixed(2);
        workOrderContext.setWoAmount((Number(woTotal) + Number(diff)).toFixed(2));
    }
    workOrderContext.setWoMaterialItem(cloneMaterialItem);
    // setMaterialItem(cloneMaterialItem);
    workOrderContext.setMaterialTotal(cloneStageTotals);
  };

  const getRows = (stageName) => {
    let stageRows = [];
    materialItem[stageName]?.map((eachItem, index) => {
      const cols = {
        delete: (eachItem.is_approved && eachItem.is_po_generated) || isCancel ? (<MDTypography display="block" variant="body2"></MDTypography>) : (
          <MDButton title="Remove" variant="outlined" color="primary" iconOnly size="small" onClick={(e) => deleteRow(stageName, index, eachItem.id)}>
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDButton>
        ),
        supplier: (
          <MDTooltip title={eachItem?.supplier?.name}>
          <MDBox>
          <MDSelect
            fullWidth
            value={eachItem.supplier_id}
            onChange={(e) => onChangeMaterialField(stageName, index, 'supplier_id', e.target.value)}
            options={supplierDropdown[stageName]}
            disabled={(eachItem.is_approved && eachItem.is_po_generated) || isCancel ? true : ((eachItem.is_approved && isApproved) ? true : false)}
            sx={{
              width: eachItem.supplier_id ? 280 : 200,
              "& .MuiNativeSelect-select": {
                padding: "0.5rem",
                fontSize: "13px",
              }
            }}
            height="40px"
          />
          </MDBox>
        </MDTooltip>),
        item: (
          <MDTooltip title={eachItem?.item?.description}>
          <MDBox>
          <MDSelect
            fullWidth
            value={eachItem.supplier_item_id}
            onChange={(e) => onChangeMaterialField(stageName, index, 'supplier_item_id', e.target.value)}
            options={itemDropdown[stageName] ? itemDropdown[stageName][eachItem.supplier_id] : []}
            disabled={(eachItem.is_approved && eachItem.is_po_generated) || isCancel ? true : ((eachItem.is_approved && isApproved) ? true : false)}
            sx={{
              width: eachItem.item_id ? 250 : 180,
              "& .MuiNativeSelect-select": {
                padding: "0.5rem",
                fontSize: "13px",
              }
            }}
            height="40px"
          />
          </MDBox>
          </MDTooltip>),
        size: (
          <MDTypography display="block" variant="body2"
            sx={{
              "&.MuiTypography-root": {
                fontSize: "14px",
              }
            }}
          >
              {eachItem?.size_name}
          </MDTypography>),
        gsm: (
          <MDTypography display="block" variant="body2"
            sx={{
              "&.MuiTypography-root": {
                fontSize: "14px",
              }
            }}
          >
              {eachItem?.gsm ? numberFormat(eachItem?.gsm, false) : ''}
          </MDTypography>),
        quantity: (
          <MDInput
            type="text"
            value={eachItem.quantity}
            onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeMaterialField(stageName, index, 'quantity', e.target.value) : ''}}
            disabled={(eachItem.is_approved && eachItem.is_po_generated) || isCancel ? true : ((eachItem.is_approved && isApproved) ? true : false)}
            sx={{
              width: '4.5rem',
              "& .MuiInputBase-input": {
                padding: "0.5rem",
                fontSize: "13px",
              }
            }}
          />),
        rate: (
            <MDTypography display="block" variant="body2"
              sx={{
                "&.MuiTypography-root": {
                  fontSize: "14px",
                }
              }}
            >
              {eachItem?.rate ? numberFormat(eachItem?.rate, true, 4) : ''}
            </MDTypography>),
        refresh_rate: (!eachItem?.rate) || (eachItem.is_approved && eachItem.is_po_generated) || isCancel ? (<></>) : (
              <MDTypography sx={{cursor: "pointer"}} title="Refresh Rate" display="block" variant="caption" color="info" fontWeight="medium" onClick={(e) => refreshRate(stageName, index, eachItem.supplier_id, eachItem.supplier_item_id)}>
                {eachItem?.loading ? <MDCircularProgress
                                    size={20}
                                    color="info"
                                  />: <Icon fontSize="small">refresh_icon</Icon>}
              </MDTypography>),
        amount: (
            <MDTypography display="block" variant="body2" sx={{
              "&.MuiTypography-root": {
                fontSize: "14px",
              }
            }}>
                {numberFormat(eachItem?.amount)}
            </MDTypography>),
        pct: (
          <MDInput
            type="text"
            value={eachItem.item_id ? eachItem.markup_pct : ''}
            onChange={(e) => {onlyNumbers(e.target.value, 'percentage') ? onChangeMaterialField(stageName, index, 'markup_pct', e.target.value) : ''}}
            disabled={(eachItem.is_approved && eachItem.is_po_generated) || isCancel ? true : ((eachItem.is_approved && isApproved) ? true : false)}
            sx={{
              width: '4.2rem',
              "& .MuiInputBase-input": {
                padding: "0.5rem",
                fontSize: "13px",
              }
            }}
          />),
        total: (
          <MDInput
              type="text"
              value={eachItem?.markup_amount}
              onChange={(e) => {onlyNumbers(e.target.value, 'decimal(16,4)') ? onChangeMaterialField(stageName, index, 'markup_amount', e.target.value) : ''}}
              disabled={(eachItem.is_approved && eachItem.is_po_generated) || isCancel ? true : ((eachItem.is_approved && isApproved) ? true : false)}
              sx={{
                width: '6.3rem',
                "& .MuiInputBase-input": {
                  padding: "0.5rem",
                  fontSize: "13px",
                }
              }}
          />),
        po:(
          <MDCheckbox
            checked={eachItem.is_po_generated}
            onChange={(e) => onChangeMaterialField(stageName, index, 'is_po_generated', e.target.checked)}
            disabled={(eachItem.is_approved && eachItem.is_po_generated) || isCancel ? true : ((eachItem.is_approved && isApproved) ? true : false)}
            sx={{marginLeft: "25px"}}
          />),
      };
      stageRows[index] = cols;
    });
    return stageRows;
  };

  const columns = [
    { Header: "", accessor: "delete", width: "2%", align: "center" },
    { Header: "Supplier", accessor: "supplier", width: "18%", align: "left" },
    { Header: "Item", accessor: "item", width: "16%", align: "left" },
    { Header: "Size", accessor: "size", width: "10%", align: "left" },
    { Header: "GSM", accessor: "gsm", width: "5%", align: "right" },
    { Header: "Quantity", accessor: "quantity", width: "5%", align: "center" },
    ...(hasPermission('wo_rates').view ? [{ Header: "Rate", accessor: "rate", width: "5%", align: "right" }] : []),
    ...(hasPermission('wo_rates').view ? [{ Header: "", accessor: "refresh_rate", width: "1%", align: "left" }] : []),
    ...(hasPermission('wo_rates').view ? [{ Header: "Amount", accessor: "amount", width: "8%", align: "right" }] : []),
    ...(hasPermission('wo_rates').view ? [{ Header: "%", accessor: "pct", width: "6%", align: "center" }] : []),
    ...(hasPermission('wo_rates').view ? [{ Header: "Total", accessor: "total", width: "8%", align: "center" }] : []),
    { Header: "PO", accessor: "po", width: "2%", align: "center" },
  ];

  return (
    stageType?.map((stage) => (
      <>
      <Card sx={{marginBottom: '1rem'}}>
        <MDBox
          py={"0.3rem"}
          mb={1}
          px={2}
          variant="gradient"
          bgColor="light"
          sx={{
            borderTopLeftRadius: "0.35rem",
            borderTopRightRadius: "0.35rem"
          }}
          display="flex"
        >
          <Grid container>
            <Grid item xs={6} md={1}>
              <MDTypography variant="h6" color="black">
                {stage}
              </MDTypography>
            </Grid>
            {!isCancel ? (
              <Grid item xs={6} md={1} display="flex" textAlign="right">
                <MDButton title="Add" variant="outlined" color="info" iconOnly circular size="small" onClick={() => addRow(stage)}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                </MDButton>
              </Grid>
            ) : ''}
            <Grid item xs={12} md={5}></Grid>
            {hasPermission('wo_rates').view ? (
              <Grid item xs={12} md={5}>
                <MDTypography variant="h6" color="black" sx={{textAlign: "right"}}>
                  {`${stage} Total = Rs. ${stageTotals[stage] ? numberFormat(stageTotals[stage]) : 0.00 || 0.00}`}
                </MDTypography>
              </Grid>
            ) : ''}
          </Grid>
        </MDBox>
        <MDBox>
          <DataTable
            table={{ columns, rows:getRows(stage) }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder={true}
            doubleClick={false}
            verticalRowPadding={0.2}
            horizontalRowPadding={0.7}
            tableBorderRadius={"0.35rem"}
            headerFontSize={"0.75rem"}
          />
        </MDBox>
      </Card>
      {renderAlertSB}
      {/* <MDDialog
          open={open}
          onClose={handleClose}
          successAction={() => deleteRow(selectedStage, selectedIndex, selectedId)}
          dialogTitle={'Are you Sure?'}
          cancleButton={'No, Please!'}
          successButton={'Yes, Delete it!'}
          cancleButtonColor={'light'}
          successButtonColor={'info'}
          icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
        >
        <p>You will not be able to recover the deleted record!</p>
        </MDDialog> */}
      </>
    ))
  );
}

export default Material;
