import HttpService from "./htttp.service";

class PurchaseOrderService {

  getPurchaseOrders = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'purchase_orders';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'purchase_orders?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addPurchaseOrder = async(data) => {
    const fetchUrl = 'purchase_orders';
    return await HttpService.post(fetchUrl, data);
  }

  updatePurchaseOrder = async (id, data) => {
    const fetchUrl = 'purchase_orders/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showPurchaseOrder = async(id) => {
    const fetchUrl = 'purchase_orders/' + id;
    return await HttpService.get(fetchUrl);
  }

  deletePurchaseOrder = async (id) => {
    const fetchUrl = 'purchase_orders/' + id;
    return await HttpService.delete(fetchUrl);
  }

  updatePurchaseOrderStatus = async (id, data) => {
    const fetchUrl = 'purchase_orders/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  sendEmail = async (id) => {
    const fetchUrl = 'po_send_email/' + id + '?is_demo_env=' + (process.env.REACT_APP_IS_DEMO === "true" ? 1 : 0);
    return await HttpService.get(fetchUrl);
  }

  updatePoTotal = async (data) => {
    const fetchUrl = 'po_update_total';
    return await HttpService.put(fetchUrl, data);
  }
}

export default new PurchaseOrderService();
