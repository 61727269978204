/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import PrintingRatesService from "services/printing-rate-service";
import { useNavigate } from "react-router-dom";
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";

export default function data(props) {
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-printing-rates');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.printingRate;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [printingRatesData, setPrintingRatesData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [totalData, setTotalData] = useState(0);
  let location = useLocation();
  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    // const getPrintingRatesData = async (e) => {
    //   const response = await PrintingRatesService.getPrintingRates(page_no, page_size, sort_column, sort_column_value, search_value);
    //   setPrintingRatesData(response?.data);
    //   setTotalData(response?.total);
    // };
    // getPrintingRatesData();
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreservePrintingRate(preserveData);
    // setPage(page_no);
    // setPageSize(page_size);
    // setSortColumn(sortColumn);
    // setSortColumnValue(sortColumnValue);
    // setSearchValue(searchWord);
    const response = await PrintingRatesService.getPrintingRates(page_no, page_size, sortColumn, sortColumnValue, searchWord);
    setPrintingRatesData(response?.data);
    setTotalData(response?.total);
  };

  const deletePrintingRate = async (id) => {
    try {
      const response = await PrintingRatesService.deletePrintingRate(id);
      if (response) {
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Printing Rate'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Printing Rate'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Printing Rate'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    const res = await PrintingRatesService.getPrintingRates(page_no, page_size, sort_column, sort_column_value, search_value);
    setPrintingRatesData(res?.data);
    setTotalData(res?.total);
    setSelectedId('');
    handleClose();
  };

  const allRows = [];
  printingRatesData?.map((eachPrintingRate, index) => {
      const eachData = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-printing-rates/edit?id=${eachPrintingRate.id}` : ''} variant="body2" color="text">
                {eachPrintingRate.id}
              </MDTypography>,
        printing_machine_id: (<MDTypography display="block" variant="body2" title={eachPrintingRate.printing_machine.name}>
                {eachPrintingRate.printing_machine.name}
                </MDTypography>
              ),
        quantity: (<MDTypography display="block" variant="body2">
                {eachPrintingRate.quantity}
                </MDTypography>
              ),
        cash_rate: (<MDTypography display="block" variant="body2">
                {eachPrintingRate.cash_rate ? numberFormat(eachPrintingRate.cash_rate) : ''}
                </MDTypography>
              ),
        credit_rate: (<MDTypography display="block" variant="body2">
                {eachPrintingRate.credit_rate ? numberFormat(eachPrintingRate.credit_rate) : ''}
                </MDTypography>
              ),
        action: (
                <MDBox display="flex">
                  {pagePermission.edit ? (
                    <MDTypography mr={1} mt={1} component={Link} to={`/manage-printing-rates/edit?id=${eachPrintingRate.id}`} title="Edit" display="block" variant="body2" color="info">
                      <Icon fontSize="small">edit</Icon>
                    </MDTypography>
                  ) : ''}
                  {pagePermission.new ? (
                    <MDTypography mr={1} mt={1} component={Link} to={`/manage-printing-rates/add?id=${eachPrintingRate.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                      <Icon fontSize="small">content_copy_icon</Icon>
                    </MDTypography>
                  ) : ''}
                  {pagePermission.remove ? (
                    <MDTypography mt={1} component={Link} onClick={() => handleClickOpen(eachPrintingRate.id)} title="Remove" display="block" variant="body2" color="error">
                      <Icon fontSize="small">delete</Icon>
                    </MDTypography>
                  ) : ''}
                  {renderAlertSB}
                </MDBox>
              )
      };
      allRows[index] = eachData;
  });

  return {
    columns: [
      { Header: "Id", accessor: "id", width: "10%", align: "left" },
      { Header: "Printing Machine", accessor: "printing_machine_id", sorting_key: 'printing_machines.name', width: "25%", align: "left" },
      { Header: "Quantity", accessor: "quantity", width: "15%", align: "left" },
      { Header: "Cash Rate", accessor: "cash_rate", width: "15%", align: "left" },
      { Header: "Credit Rate", accessor: "credit_rate", width: "15%", align: "left" },
      ...(pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "20%", align: "center" }] : [],
    ],
    rows: allRows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deletePrintingRate: deletePrintingRate,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
