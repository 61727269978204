/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";
import { AuthContext } from "context";

export default function authPermission() {
  
  const authContext = useContext(AuthContext);

  const hasPermission = (handler) => {
    const permissions = authContext.userPermission;
    if (localStorage.getItem('user_id') != 1) {
      if (typeof permissions[handler] !== 'undefined'){
        return permissions[handler];
      } else {
          return {
              view: 0,
              status: 0,
              new: 0,
              edit: 0,
              remove: 0,
          };
      }
    } else {
        return {
          view: 1,
          status: 1,
          new: 1,
          edit: 1,
          remove: 1,
        };
    }
  };

  const systemConstant = (key) => {
    const parameter = authContext.systemParameters;
    let value = '';
    if (Object.keys(parameter).length > 0) {
      parameter.map((eachParameter) => {
        if (eachParameter.name == key) {
          value = eachParameter.value;
        }
      });
    }
    return value;
  };

  
  return {
    hasPermission:hasPermission,
    systemConstant:systemConstant,
  };
}
