/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import SupplierItemService from "services/supplier-item-service";
import { useNavigate } from "react-router-dom";
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";

export default function data(props) {
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-supplier-items');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.supplierItem;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [supplierItemsData, setSupplierItemsData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [totalData, setTotalData] = useState(0);
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    // const getSupplierItemsData = async (e) => {
    //   const response = await SupplierItemService.getSupplierItems(page_no, page_size, sort_column, sort_column_value, search_value);
    //   setSupplierItemsData(response?.data);
    //   setTotalData(response?.total);
    // };
    // getSupplierItemsData();
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveSupplierItem(preserveData);
    const response = await SupplierItemService.getSupplierItems(page_no, page_size, sortColumn, sortColumnValue, searchWord);
    setSupplierItemsData(response?.data);
    setTotalData(response?.total);
  };

  const updateSupplierItemStatus = async (id, status) => {
    try {
      var data = {'active': status};
      const res = await SupplierItemService.updateSupplierItemStatus(id, data);
      if (res) {
        openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Supplier Item'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        const response = await SupplierItemService.getSupplierItems(page_no, page_size, sort_column, sort_column_value, search_value);
        setSupplierItemsData(response?.data);
        setTotalData(response?.total);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Supplier Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const deleteSupplierItem = async (id) => {
    try {
      const response = await SupplierItemService.deleteSupplierItem(id);
      if (response) {
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Supplier Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Supplier Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier Item'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    const res = await SupplierItemService.getSupplierItems(page_no, page_size, sort_column, sort_column_value, search_value);
    setSupplierItemsData(res?.data);
    setTotalData(res?.total);
    setSelectedId('');
    handleClose();
  };

  const allRows = [];
  supplierItemsData?.map((eachSupplierItem, index) => {
      const eachData = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-supplier-items/edit?id=${eachSupplierItem.id}` : ''} variant="body2" color="text">
                {eachSupplierItem?.id}
              </MDTypography>,
        supplier_id: (<MDTypography display="block" variant="body2" title={eachSupplierItem?.supplier?.name}>
                {eachSupplierItem?.supplier?.name}
                </MDTypography>
              ),
        item_id: (<MDTypography display="block" variant="body2" title={eachSupplierItem?.item?.description}>
                {(eachSupplierItem?.item) ? (eachSupplierItem?.item?.description) : ('')}
                </MDTypography>
              ),
        purchase_unit: (<MDTypography display="block" variant="body2">
                {eachSupplierItem?.unit ? (eachSupplierItem?.unit?.name) : ('')}
                </MDTypography>
              ),
        purchase_rate: (<MDTypography display="block" variant="body2">
              {eachSupplierItem?.purchase_rate ? eachSupplierItem.purchase_rate : ''}
              </MDTypography>
            ),
        size_id: (<MDTypography display="block" variant="body2" title={eachSupplierItem?.size?.name}>
              {(eachSupplierItem?.size) ? (eachSupplierItem?.size?.name) : ('')}
              </MDTypography>
            ),
        gsm: (<MDTypography display="block" variant="body2">
              {eachSupplierItem?.gsm ? numberFormat(eachSupplierItem?.gsm, false) : ''}
              </MDTypography>
            ),
        action: (
                <MDBox display="flex">
                  {pagePermission.status ? (
                    <MDSwitch
                      checked={eachSupplierItem.active ? true : false}
                      color="info"
                      title="Status"
                      onChange={() => updateSupplierItemStatus(eachSupplierItem.id , !eachSupplierItem.active)}
                    />
                  ) : ''}
                  {pagePermission.edit ? (
                    <MDTypography mr={1} mt={1} component={Link} to={`/manage-supplier-items/edit?id=${eachSupplierItem.id}`} title="Edit" display="block" variant="body2" color="info">
                      <Icon fontSize="small">edit</Icon>
                    </MDTypography>
                  ) : ''}
                  {pagePermission.new ? (
                    <MDTypography mr={1} mt={1} component={Link} to={`/manage-supplier-items/add?id=${eachSupplierItem.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                      <Icon fontSize="small">content_copy_icon</Icon>
                    </MDTypography>
                  ) : ''}
                  {pagePermission.remove ? (
                    <MDTypography mt={1} component={Link} onClick={() => handleClickOpen(eachSupplierItem.id)} title="Remove" display="block" variant="body2" color="error">
                      <Icon fontSize="small">delete</Icon>
                    </MDTypography>
                  ) : ''}
                  {renderAlertSB}
                </MDBox>
              )
      };
      allRows[index] = eachData;
  });

  return {
    columns: [
      { Header: "Id", accessor: "id", width: "8%", align: "left" },
      { Header: "Supplier", accessor: "supplier_id", sorting_key: 'suppliers.name', width: "20%", align: "left" },
      { Header: "Item", accessor: "item_id", sorting_key: 'items.description', width: "16%", align: "left" },
      { Header: "Purchase Unit", accessor: "purchase_unit", sorting_key: 'units.name', width: "11%", align: "left" },
      { Header: "Purchase Rate", accessor: "purchase_rate", width: "11%", align: "left" },
      { Header: "Size", accessor: "size_id", sorting_key: 'sizes.name', width: "16%", align: "left" },
      { Header: "GSM", accessor: "gsm", width: "10%", align: "left" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "8%", align: "center" }] : [],
    ],
    rows: allRows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deleteSupplierItem: deleteSupplierItem,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
