/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportService from "services/report-service";
import SuppliersService from "services/supplier-service";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import { Link } from "react-router-dom";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const woPermission = hasPermission('/manage-work-orders');
    const [dayWiseCosting, setDayWiseCosting] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [grandTotal, setGrandTotal] = useState(0);
    const [startDate, setStartDate] = useState((dayjs(new Date()).subtract(1, 'month')));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [filter, setFilter] = useState('');
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');
    const { cacheItems, setCacheItems } = useCache();

    const getDayWiseCosting = async(startDate = '', endDate = '') => {
        const data = await ReportService.getDayWiseCosting(startDate, endDate);
        setDayWiseCosting(Object.values(data.day_wise_costing));
        setGrandTotal(data.grand_total);
    }

    useEffect(() => {
        getDayWiseCosting(dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '', dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '');
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
        const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
        getDayWiseCosting(sDate, eDate);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setStartDate('');
        setEndDate('');
        setFilter(null);
        getDayWiseCosting();
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data, grandTotal) => {
        setExcelLoading(true);
        const fileName = 'day_wise_costing_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Daywise Costing']);
        //Merge cells for supplier row
        worksheet.mergeCells(`A1:D1`);
        // Add headers
        const headerRow = worksheet.addRow(['Work Order#', 'Date', 'Party', 'Job Type', 'Delivery Date', 'Item', 'Quantity', 'Rate', 'Amount']);
        // Set the width for each column
        worksheet.columns = [
            { width: 12 }, 
            { width: 15 },
            { width: 25 },
            { width: 35 },
            { width: 15 },
            { width: 50 }, 
            { width: 10 },
            { width: 18 },
            { width: 18 },
        ];
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 1; i < 10; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
            //Add border
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        let k = 3;
        data.forEach((eachData, index) => {
            // const woRow = 'Work Order#: ' + eachData?.id + '     Date: ' + eachData?.release_date + '     Party: ' + eachData?.customer + '     Job Type: ' + eachData?.job_type + '     Delivery Date: ' + eachData?.delivery_date;
            // const cellRowWo = worksheet.addRow([woRow]);
            // cellRowWo.getCell(1).fill = {
            //     type: 'pattern',
            //     name: 'sans-serif',
            //     pattern: 'solid',
            //     fgColor: { argb: 'f8ece0' },
            // };
            // cellRowWo.border = {
            //     right: { style: 'thin' }
            // };
            // // Merge cells for supplier row
            // worksheet.mergeCells(`A${k}:D${k}`);
            // k += 1;
            let rowInd = 0;
            let rowColor = 'effbfb';
            // Add data
            eachData?.items?.forEach((row, index) => {
                const cellRow = worksheet.addRow([(rowInd == 0 ? eachData?.id : ''), (rowInd == 0 ? eachData?.release_date : ''), (rowInd == 0 ? eachData?.customer : ''), (rowInd == 0 ? eachData?.job_type : ''), (rowInd == 0 ? eachData?.delivery_date : ''), row?.item, row?.quantity, (row?.rate), Number(row?.amount)]);
                rowColor = rowColor == 'effbfb' ? 'e0f8f7' : 'effbfb';
                for (var i = 1; i < 10; i++) {
                    cellRow.getCell(i).fill = {
                        type: 'pattern',
                        name: 'sans-serif',
                        pattern: 'solid',
                        fgColor: { argb: (i > 5 ? rowColor : (rowInd == 0 ? 'f8ece0' : 'FFFFFF') )},
                    };
                    // Add border to the row
                    cellRow.getCell(i).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                }
                cellRow.getCell(8).numFmt = '#,##0.0000';
                cellRow.getCell(9).numFmt = '#,##0.00';
                k += 1;
                rowInd += 1;
            });
            // if (eachData.length != index + 1) {
            //     const cellRowTotal = worksheet.addRow(['', '', '', '']);
            //     worksheet.mergeCells(`A${k}:D${k}`);
            //     k += 1;
            // }
        });

        const cellRowGrandTotal = worksheet.addRow(['','','','','','', '', 'Total:', Number(grandTotal)]);
        // worksheet.mergeCells(`A${k}:C${k}`);
        // cellRowGrandTotal.getCell(8).alignment = { horizontal: 'right' };
        cellRowGrandTotal.getCell(9).numFmt = '#,##0.00';
        cellRowGrandTotal.font = { bold: true, name: 'sans-serif' };
      
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(dayWiseCosting){
            const pdfTemplate = PDFTemplate(dayWiseCosting, grandTotal, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`${pdfTemplate}`);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Daywise Costing'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Daywise Costing'}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                     <Grid item lg={6} xs={12} px={1} md={8}>
                                        <MDBox mb={1}>
                                            <MDDateRangePicker
                                                label="Start Date"
                                                name="start_date"
                                                value={startDate ? startDate : null}
                                                format="DD-MM-YYYY"
                                                onChange={(newValue) => {
                                                    setDate(newValue, 'start_date');
                                                }}
                                                endLabel="End Date"
                                                endName="end_date"
                                                endValue={endDate ? endDate : null}
                                                endOnChange={(newValue) => {
                                                    setDate(newValue, 'end_date');
                                                }}
                                                onFilterChange={onFilterChange}
                                                filter={filter}
                                                filterSx={{width: minWidthD ? 130 : 60}}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {dayWiseCosting.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(dayWiseCosting, grandTotal)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '30%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Item</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Quantity</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Rate</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Amount</TableCell>
                                        </TableRow>
                                        {dayWiseCosting?.map((eachItem, index) => {
                                            let rowColor = '#effbfb';
                                            return (
                                                <>
                                                    <TableRow sx={{ backgroundColor: '#f8ece0' }}>
                                                        <TableCell colSpan={6} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Work Order#:</b> <a style={{color: 'blue'}} href={woPermission.edit ? `/manage-work-orders/edit?id=${eachItem?.id}&tab=3&close_window=1`: ``} target={'_blank'}>{eachItem?.id}</a>&nbsp;&nbsp;&nbsp;&nbsp;<b>Date: </b>{eachItem?.release_date}&nbsp;&nbsp;&nbsp;&nbsp;<b>Party: </b>{eachItem?.customer}&nbsp;&nbsp;&nbsp;&nbsp;<b>Job Type: </b>{eachItem?.job_type}&nbsp;&nbsp;&nbsp;&nbsp;<b>Delivery Date: </b>{eachItem?.delivery_date}</TableCell>
                                                    </TableRow>
                                                    {eachItem?.items?.map((item) => {
                                                        rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                                                        return (
                                                            <TableRow sx={{ backgroundColor: rowColor }}>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '30%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.item}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.quantity}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.rate}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.amount)}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                    {dayWiseCosting.length == index + 1 ? `` : (
                                                    <TableRow>
                                                        <TableCell colSpan={4} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>&nbsp;</TableCell>
                                                    </TableRow>
                                                    )}
                                                </>
                                            )
                                        })}
                                        {dayWiseCosting.length == 0 ? 
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow>
                                        : 
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>Total:</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(grandTotal)}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
