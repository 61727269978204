/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDCircularProgress from "components/MDCircularProgress";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PermissionService from "services/permission-service";
import customAlert from "components/MDAlert/customAlert";
import { useCache } from "context";

function Form() {
  const { action } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [headerTitle, setHeaderTitle] = useState('');

  const navigate = useNavigate();
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const { cacheItems, setCacheItems } = useCache();

  const [inputs, setInputs] = useState({
    name: "",
    handler: "",
    indent:"",
    display_order:"",
    is_heading:0,
    is_custom:0,
    created_at: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    handlerError: false,
    indentError: false,
    displayOrderError: false,
  });

  useEffect(() => {
    if (action === "add") return;

    const showPermission = async() => {
      try {
        const data = await PermissionService.showPermission(id);
        setHeaderTitle(data.name);
        setInputs(data);
        setCreatedBy(data.user.first_name + (data.user.middle_name ? (" " + data.user.middle_name) : "") + " " + data.user.last_name);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Permission'};
          navigate('/permission', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Permission'};
          navigate('/permission', {state: state});
        }
      }
    }
    showPermission();
  }, []);

  const changeHandler = (e) => {
    if (e.target.name == 'indent') {
      setErrors({
        indentError: false,
      });
    }
    if (e.target.name == 'display_order') {
      setErrors({
        displayOrderError: false,
      });
    }
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log('add');
    if (inputs.name.trim().length < 1) {
      setErrors({ ...errors, nameError: true });
      return;
    }

    if (inputs.handler.trim().length < 1) {
      setErrors({ ...errors, handlerError: true });
      return;
    }
    const indentFormat = /^[0-5]{1}$/;
    if (!inputs.indent.toString().trim().match(indentFormat)) {
      setErrors({ ...errors, indentError: true });
      return;
    }

    const displayOrderFormat = /^[0-9]{1,3}$/;
    if (!inputs.display_order.toString().trim().match(displayOrderFormat)) {
      setErrors({ ...errors, displayOrderError: true });
      return;
    }

    const newPermission = { name: inputs.name, handler: inputs.handler, indent: inputs.indent, display_order: inputs.display_order, is_heading: inputs.is_heading, is_custom: inputs.is_custom };

    if (action === 'add') {
      try {
        const response = await PermissionService.addPermission(newPermission);
        setLoading(false);
        if (response) {
          if (cacheItems.hasOwnProperty('permissions')) {
            // Create a new object without the key
            const { permissions, ...rest } = cacheItems; // Destructure to exclude key
            setCacheItems(rest); // Set the new cache items without key
          }
          const state = {color: 'success', icon: 'check', msg: 'Permission Added Successfully!', title: 'Permission'};
          navigate('/permission', { state: state });
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Permission'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Permission'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    } else {
      try {
        const response = await PermissionService.updatePermission(id, newPermission);
        setLoading(false);
        if (response) {
          if (cacheItems.hasOwnProperty('permissions')) {
            // Create a new object without the key
            const { permissions, ...rest } = cacheItems; // Destructure to exclude key
            setCacheItems(rest); // Set the new cache items without key
          }
          openAlertSB({color: 'success', icon: 'check', msg: 'Permission Updated Successfully!', title: 'Permission'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Permission'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Permission'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    }

    return () => {
      setInputs({
        name: "",
        handler: "",
        indent:"",
        display_order:"",
        is_heading:"",
        is_custom:"",
      });

      setErrors({
        nameError: false,
        handlerError: false,
        indentError: false,
        displayOrderError: false,
      });
    };
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === "add" ? 'Add Permission' : ('Edit Permission : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.75rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <MDTypography variant="h6" color="white">
                    {action === "add" ? 'Add Permission' : ('Edit Permission : ' + headerTitle)}
                  </MDTypography>
                </MDBox>
                <MDBox py={3}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Name"
                            fullWidth
                            value={inputs.name}
                            name="name"
                            inputProps={{'maxLength':50}}
                            onChange={changeHandler}
                            error={errors.nameError}
                            required
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Handler"
                            fullWidth
                            name="handler"
                            value={inputs.handler}
                            inputProps={{'maxLength':100}}
                            onChange={changeHandler}
                            error={errors.handlerError}
                            required
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Indent"
                            fullWidth
                            name="indent"
                            value={inputs.indent}
                            onChange={changeHandler}
                            error={errors.indentError}
                            helperText={errors.indentError ? "Please enter a valid number." : ""}
                            required
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Display Order"
                              fullWidth
                              name="display_order"
                              value={inputs.display_order}
                              onChange={changeHandler}
                              error={errors.displayOrderError}
                              helperText={errors.displayOrderError ? "Please enter a valid number." : ""}
                              required
                            />
                        </MDBox>
                      </Grid>
                      {action === 'edit' &&
                        <>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                name=""
                                value={createdBy}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={dayjs(inputs.created_at).format('DD-MM-YYYY HH:mm A')}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      }
                      <Grid item md={10} xs={8}></Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={2}>
                          <MDButton component={Link} to={`/permission`} variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
