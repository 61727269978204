/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDTooltip
import MDTooltipRoot from "components/MDTooltip/MDTooltipRoot";

const MDTooltip = forwardRef(({ title, placement, arrow, color, ...rest }, ref) => (
  <MDTooltipRoot
    title={title}
    placement={placement}
    arrow={arrow}
    color={color}
    {...rest}
  />
));

// Setting default values for the props of MDTooltip
MDTooltip.defaultProps = {
  title: '',
  placement: 'bottom',
  arrow: true,
  color: 'light',
};

// Typechecking props for the MDTooltip
MDTooltip.propTypes = {
  color: PropTypes.oneOf([
    "light",
    "dark",
  ]),
};

export default MDTooltip;
