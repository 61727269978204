import HttpService from "./htttp.service";

class WoMaterialItemService {

  getWoMaterialItems = async(id) => {
    let fetchUrl = 'wo_material_items';
    if (id) {
      fetchUrl = 'wo_material_items?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addWoMaterialItem = async(data) => {
    const fetchUrl = 'wo_material_items';
    return await HttpService.post(fetchUrl, data);
  }

  updateWoMaterialItem = async (id, data) => {
    const fetchUrl = 'wo_material_items/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showWoMaterialItem = async(id) => {
    const fetchUrl = 'wo_material_items/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteWoMaterialItem = async (id) => {
    const fetchUrl = 'wo_material_items/' + id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllWoMaterialItem = async (work_order_id) => {
    const fetchUrl = 'wo_material_items?work_order_id=' + work_order_id;
    return await HttpService.delete(fetchUrl);
  }

  approveWoMaterialItem = async (work_order_id, is_generate_po = false, newStatusId) => {
    const fetchUrl = 'wo_material_items_approve?work_order_id=' + work_order_id + '&is_generate_po=' + is_generate_po + '&new_status_id=' + newStatusId;
    return await HttpService.patch(fetchUrl);
  }
}

export default new WoMaterialItemService();
