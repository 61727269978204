/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useState } from "react";

//Work Order Context
export const GrnContext = createContext({
    item: [],
    grnTotal: 0,
    payment: [],
    setItem: () => {},
    setGrnAmount: () => {},
    setPoPaymentItem: () => {},
  });
  
  const GrnContextProvider = ({ children }) => {
    const [item, setItem] = useState([]);
    const [grnTotal, setGrnTotal] = useState(0);
    const [payment, setPayment] = useState([]);
  
    const setGrnItem = async(items) => {
        setItem(items);
    };

    const setGrnAmount = (amount) => {
        setGrnTotal(amount);
    };

    const setPoPayment = (items) => {
      setPayment(items);
    };
  
    return (
      <GrnContext.Provider value={{ item, grnTotal, payment, setGrnItem, setGrnAmount, setPoPayment }}>
        {children}
      </GrnContext.Provider>
    );
  };

  export {
    GrnContextProvider
  }