// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import invoiceData from "layouts/manage-invoices/wo-data";

function Invoices() {
  const { columns, rows, open, handleClickOpen, handleClose, selectedId, totalData, page, pageSize, getData, sortColumn, sortColumnValue, searchValue, pagePermission } = invoiceData();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.625rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                <Grid container>
                  <Grid item xs={11}>
                    <MDTypography mt={1} variant="h6" color="white">
                      Select work order to create invoice
                    </MDTypography>
                  </Grid>
                </Grid>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    entriesPerPage={{
                      "defaultValue": pageSize
                    }}
                    canSearch={true}
                    showTotalEntries={true}
                    table={{ columns, rows }}
                    isSorted={true}
                    totalData={totalData}
                    initialPageIndex={page - 1}
                    getData={getData}
                    pageCount={Math.ceil(totalData/pageSize)}
                    sortCol={sortColumn}
                    sortColValue={sortColumnValue}
                    searchVal={searchValue}
                    noEndBorder={false}
                    doubleClick={false}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Invoices;
