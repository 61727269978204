/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuppliersService from "services/supplier-service";
import ReportService from "services/report-service";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const [suppliersDropdown, setSuppliersDropdown] = useState([]);
    const [supplierItemRegister, setSupplierItemRegister] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [supplierId, setSupplierId] = useState('');
    const { cacheItems, setCacheItems } = useCache();
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getSupplierItemRegister = async(supplierId = '') => {
        const data = await ReportService.getSupplierItemRegister(supplierId);
        setSupplierItemRegister(data);
    }

    useEffect(() => {
        const getSuppliersData = async() => {
            let response; // Declare response outside the if...else block
            if (cacheItems.hasOwnProperty('suppliers')) {
              response = cacheItems['suppliers'];
            } else {
              response = await SuppliersService.getSuppliers(); // Fetch customers from the API
              setCacheItems(prevCache => ({
                  ...prevCache,
                  suppliers: response,
              }));
            }
            if (response) {
              var supplierArray = [];
              response?.map((eachSupplier, index) => {
                if(eachSupplier?.active) {
                    supplierArray[index] = {
                        key: eachSupplier?.id,
                        value: eachSupplier?.name,
                        disabled: eachSupplier?.active ? false : true,
                    }
                }
              });
              setSuppliersDropdown(supplierArray);
            }
        }
        getSuppliersData();

        getSupplierItemRegister();
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        getSupplierItemRegister(supplierId);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setSupplierId('');
        getSupplierItemRegister();
    }

    const generateAndDownloadExcelFile = async(data) => {
        setExcelLoading(true);
        const fileName = 'supplier_item_register_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Supplier Item Register']);
        //Merge cells for supplier row
        worksheet.mergeCells(`A1:C1`);
        // Add headers
        const headerRow = worksheet.addRow(['Supplier', 'Item', 'Unit', 'Rate']);
        // Set the width for each column
        worksheet.columns = [
            { width: 25 },
            { width: 70 }, 
            { width: 20 },
            { width: 25 }
        ];
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i=2; i<5; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
        }
        for (var i=1; i<5; i++) {
            // Add border
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        let k = 3;
        data.forEach((purchase, index) => {
            // const supplierRow = 'Supplier : ' + purchase?.supplier_name;
            // const cellRowSupplier = worksheet.addRow([supplierRow]);
            // cellRowSupplier.getCell(1).fill = {
            //     type: 'pattern',
            //     name: 'sans-serif',
            //     pattern: 'solid',
            //     fgColor: { argb: 'f8ece0' },
            // };
            // cellRowSupplier.border = {
            //     right: { style: 'thin' }
            // };
            
            // // Merge cells for supplier row
            // worksheet.mergeCells(`A${k}:C${k}`);
            // k += 1;
            let rowColor = 'e0f8f7';
            let rowInd = 0;
            // Add data
            purchase?.supplierItems?.forEach((row, index) => {
                if (row?.item_name || row?.unit_name || (row?.purchase_rate > 0)){
                    const cellRow = worksheet.addRow([(rowInd == 0 ? purchase?.supplier_name :''), row?.item_name, row?.unit_name, Number(row?.purchase_rate)]);
                    rowColor = (rowColor == 'effbfb') ? 'e0f8f7' : 'effbfb';
                    for (var i=2; i<5; i++) {
                        cellRow.getCell(i).fill = {
                            type: 'pattern',
                            name: 'sans-serif',
                            pattern: 'solid',
                            fgColor: { argb: rowColor },
                        };
                    }
                    if (rowInd == 0) {
                        cellRow.getCell(1).fill = {
                            type: 'pattern',
                            name: 'sans-serif',
                            pattern: 'solid',
                            fgColor: { argb: 'f8ece0' },
                        };
                    }
                    cellRow.getCell(3).numFmt = '#,##0.00';
                    // Add border to the row
                    for (i=1; i<5; i++) {
                        cellRow.getCell(i).border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    }
                    k += 1;
                    rowInd += 1;
                }
            });
        });
      
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(supplierItemRegister){
            const pdfTemplate = PDFTemplate(supplierItemRegister, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`${pdfTemplate}`);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Supplier Item Register'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Supplier Item Register'}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                    <Grid item xs={12} lg={minWidthD ? 3 : 2.5} md={6} px={1}>
                                        <MDBox mb={1.5}>
                                            <MDSelect
                                                label="Supplier"
                                                fullWidth
                                                name="supplier_id"
                                                value={supplierId}
                                                onChange={(e) => setSupplierId(e.target.value != null ? e.target.value : '')}
                                                options={suppliersDropdown}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {supplierItemRegister.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(supplierItemRegister)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '60%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Item</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Unit</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Rate</TableCell>
                                        </TableRow>
                                        {supplierItemRegister?.map((eachItem) => {
                                            let rowColor = '#e0f8f7';
                                            return (
                                                <>
                                                    <TableRow sx={{ backgroundColor: '#f8ece0' }}>
                                                        <TableCell colSpan={3} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Supplier :</b> {eachItem?.supplier_name}</TableCell>
                                                    </TableRow>
                                                    {eachItem?.supplierItems?.map((item) => {
                                                        if (item?.item_name || item?.unit_name || (item?.purchase_rate > 0)){
                                                            rowColor = (rowColor == '#e0f8f7') ? '#effbfb' : '#e0f8f7';
                                                            return (
                                                                <TableRow sx={{ backgroundColor: rowColor }}>
                                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '60%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.item_name}</TableCell>
                                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.unit_name ? item?.unit_name : ''}</TableCell>
                                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.purchase_rate}</TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )
                                        })}
                                        {supplierItemRegister.length == 0 && (
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
