/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat, numberToAlphabet } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import ReportService from "services/report-service";
import { Link } from "react-router-dom";
import MDSelect from "components/MDSelect";
import PrintingMachineService from "services/printing-machine-service";
import MDMultipleSelect from "components/MDMultipleSelect";
import MDDatePicker from "components/MDDatePicker";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const cuPermission = hasPermission('/manage-customers');
    const [printingMachineDropdown, setPrintingMachineDropdown] = useState([]);
    const [defaultPrintingMachine, setDefaultPrintingMachine] = useState([]);
    const [machineWiseSale, setMachineWiseSale] = useState([]);
    const [totalData, setTotalDate] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [printingMachine, setPrintingMachine] = useState([]);
    const [startMonth, setStartMonth] = useState(4);
    const [endMonth, setEndMonth] = useState(3);
    const [startYear, setStartYear] = useState(dayjs(new Date()));
    const [endYear, setEndYear] = useState(dayjs(new Date()).add(1, 'year'));
    const [durationType, setDurationType] = useState(1);
    const [printingMachineArray, setPrintingMachineArray] = useState([]);
    const [startDate, setStartDate] = useState(dayjs(new Date).startOf('month'));
    const [endDate, setEndDate] = useState(dayjs(new Date));
    const [filter, setFilter] = useState('');
    const { cacheItems, setCacheItems } = useCache();
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getMachineWiseSales = async(printingMachine = '', startDate = '', endDate = '', months = '', durationType = 1) => {
        const data = await ReportService.getMachineWiseSales(printingMachine, startDate, endDate, months, durationType);
        setMachineWiseSale(Object.values(data.machine_wise_sales));
        setTotalDate(data.totals);
        setPrintingMachineArray(printingMachine);
    }

    useEffect(() => {
        const printingMachineIds = [];
        const getPrintingMachine = async() => {
            let response; // Declare response outside the if...else block
            if (cacheItems.hasOwnProperty('printing_machines')) {
                response = cacheItems['printing_machines'];
            } else {
                response = await PrintingMachineService.getPrintingMachines(); // Fetch customers from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    printing_machines: response,
                }));
            }
            if (response) {
                var printingMachineArray = [];
                response?.map((eachPrintingMachine, index) => {
                    if(eachPrintingMachine?.active) {
                    printingMachineIds.push(eachPrintingMachine?.id);
                        printingMachineArray[index] = {
                            key: eachPrintingMachine?.id,
                            value: eachPrintingMachine?.name,
                            disabled: eachPrintingMachine?.active ? false : true,
                        }
                    }
                });
                setPrintingMachineDropdown(printingMachineArray);
                setDefaultPrintingMachine(printingMachineIds);
            }
          }
          getPrintingMachine();
    }, []);

    useEffect(() => {
        setPrintingMachine(defaultPrintingMachine);
        getMachineWiseSales(defaultPrintingMachine, dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '', dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '', '', durationType);
    }, [defaultPrintingMachine])
    
    const submitHandler = () => {
        setSearchLoading(true);
        let sDate = '';
        let eDate = '';
        let months = '';
        if (durationType == 1) {
            sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
            eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
        } else if (durationType == 2) {
            sDate = dayjs(startYear).isValid() ? dayjs(startYear).startOf('year').add((startMonth - 1), 'month').format('YYYY-MM-DD') : '';
            eDate = dayjs(endYear).isValid() ? dayjs(endYear).startOf('year').endOf('month').add((endMonth - 1), 'month').format('YYYY-MM-DD') : '';
            const monthList = [];
            for (let i = startMonth; i <= 12; i++) {
                monthList.push(i);
              }
              for (let i = 1; i <= endMonth; i++) {
                monthList.push(i);
              }
            months = monthList.join(', ');
        } else if (durationType == 3) {
            sDate = dayjs(startYear).isValid() ? dayjs(startYear).startOf('year').add(3, 'month').format('YYYY-MM-DD') : '';
            eDate = dayjs(endYear).isValid() ? dayjs(endYear).startOf('year').endOf('month').add(2, 'month').format('YYYY-MM-DD') : '';
        }
        getMachineWiseSales(printingMachine, sDate, eDate, months, durationType);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setStartDate('');
        setEndDate('');
        setFilter(null);
        setStartYear(null);
        setEndYear(null);
        setStartMonth('');
        setEndMonth('');
        setDurationType(1);
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
            if (!dateValue){
                setEndDate(dateValue);
            }
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data, totalData, machineDropdown, machineArray) => {
        setExcelLoading(true);
        const fileName = 'machine_wise_sales_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Machine Wise Sales']);
        const headerColumn = ['Date'];
        const columnWidth = [
            { width: 13 },
        ];
        {machineDropdown?.map((eachType) => {
            if (machineArray.includes(eachType?.key)) {
                headerColumn.push(eachType?.value);
                columnWidth.push({width: 20});
            }
        })}
        headerColumn.push('Total');
        columnWidth.push({width: 18});
        const headerColumnLength = headerColumn.length;

        //Merge cells for heading row
        worksheet.mergeCells(`A1:${numberToAlphabet(headerColumnLength)}1`);
        // Add headers
        const headerRow = worksheet.addRow(headerColumn);
        worksheet.columns = columnWidth;
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 1; i <= headerColumnLength; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
            //Add border
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        // Add data
        data?.forEach((row, index) => {
            const cellColumn = [row?.date];
            machineDropdown?.map((eachType) => {
                if (machineArray.includes(eachType?.key)) {
                    cellColumn.push(row[eachType?.key] ? Number(row[eachType?.key]) : 0);
                }
            })
            cellColumn.push(row?.total ? Number(row?.total) : 0);

            const cellRow = worksheet.addRow(cellColumn);
            let rowColor = index % 2 === 0 ? 'e0f8f7' : 'effbfb';
            for (var i = 1; i <= headerColumnLength; i++) {
                cellRow.getCell(i).fill = {
                    type: 'pattern',
                    name: 'sans-serif',
                    pattern: 'solid',
                    fgColor: { argb: rowColor },
                };
                // Add border to the row
                cellRow.getCell(i).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
                if (i>1) {
                    cellRow.getCell(i).numFmt = '#,##0.00';
                }
            }
        });

        const totalColumn = ['Total'];
        machineDropdown?.map((eachType) => {
            if (machineArray.includes(eachType?.key)) {
                totalColumn.push(totalData[eachType?.key] ? Number(totalData[eachType?.key]) : 0);
            }
        })
        totalColumn.push(totalData?.total ? Number(totalData?.total) : 0);
            
        const totalRow = worksheet.addRow(totalColumn);
        totalRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 2; i <= headerColumnLength; i++) {
            totalRow.getCell(i).numFmt = '#,##0.00';
            totalRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(machineWiseSale){
            const pdfTemplate = PDFTemplate(machineWiseSale, totalData, printingMachineDropdown, printingMachineArray, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            if (printingMachineArray.length > 5) {
                printWindow.document.write(`
                    <style>
                        @media print {
                            @page { size: landscape; }
                        }
                    </style>
                    ${pdfTemplate}
                `);
            } else {
                printWindow.document.write(`${pdfTemplate}`);
            }
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }
    
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Machine Wise Sales'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Machine Wise Sales'}
                                </MDTypography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <MDBox>
                                    
                                </MDBox>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                    <Grid item xs={12} lg={7} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDMultipleSelect
                                                label="Printing Machine"
                                                name="printing_machine"
                                                fullWidth
                                                value={printingMachine}
                                                options={printingMachineDropdown}
                                                onChange={(e) => setPrintingMachine(e.target.value != null ? e.target.value : '')}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={5} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Duration Type"
                                                fullWidth
                                                name="duration_type"
                                                value={durationType}
                                                onChange={(e) => setDurationType(e.target.value != null ? e.target.value : 1)}
                                                options={[{key: 1, value: 'Daily'}, {key: 2, value: 'Monthly'}, {key: 3, value: 'Yearly'}]}
                                            />
                                        </MDBox>
                                    </Grid>
                                    {(durationType == 1 || durationType == '') && (
                                        <Grid item lg={5} xs={12} px={1} md={6}>
                                            <MDBox mb={1}>
                                                <MDDateRangePicker
                                                    label="Start Date"
                                                    name="start_date"
                                                    value={startDate ? startDate : null}
                                                    format="DD-MM-YYYY"
                                                    onChange={(newValue) => {
                                                        setDate(newValue, 'start_date');
                                                    }}
                                                    endLabel="End Date"
                                                    endName="end_date"
                                                    endValue={endDate ? endDate : null}
                                                    endOnChange={(newValue) => {
                                                        setDate(newValue, 'end_date');
                                                    }}
                                                    onFilterChange={onFilterChange}
                                                    filter={filter}
                                                    filterSx={{width: minWidthD? 150 : 80}}
                                                />
                                            </MDBox>
                                        </Grid>
                                    )}
                                    {(durationType == 2 || durationType == 3) && (
                                    <>
                                        <Grid item xs={12} lg={2} md={6} px={1}>
                                            <MDBox mb={1}>
                                                <MDDatePicker
                                                    views={["year"]}
                                                    label="Start Year"
                                                    name="start_year"
                                                    value={startYear || null}
                                                    format="YYYY"
                                                    onChange={(newValue) => {
                                                        setStartYear(newValue);
                                                    }}
                                                    slotProps={{ textField: {
                                                        fullWidth: true,
                                                        required: true
                                                        },
                                                        field: {
                                                        clearable: true
                                                        },
                                                        textField: { size: 'small' }
                                                    }}
                                                    maxDate={endYear}
                                                    />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2} md={6} px={1}>
                                            <MDBox mb={1}>
                                                <MDDatePicker
                                                    views={["year"]}
                                                    label="End Year"
                                                    name="end_year"
                                                    value={endYear || null}
                                                    format="YYYY"
                                                    disabled={!startYear}
                                                    onChange={(newValue) => {
                                                        setEndYear(newValue);
                                                    }}
                                                    slotProps={{ textField: {
                                                        fullWidth: true,
                                                        required: true
                                                        },
                                                        field: {
                                                        clearable: true
                                                        },
                                                        textField: { size: 'small' }
                                                    }}
                                                    minDate={startYear}
                                                />
                                            </MDBox>
                                        </Grid>
                                        {durationType == 2 && (
                                            <>
                                                <Grid item xs={12} lg={2} md={6} px={1}>
                                                    <MDBox mb={1}>
                                                        <MDSelect
                                                            label="Start Month"
                                                            name="start_month"
                                                            fullWidth
                                                            value={startMonth}
                                                            options={[
                                                                {key: 1, value: 'January'}, 
                                                                {key: 2, value: 'February'}, 
                                                                {key: 3, value: 'March'}, 
                                                                {key: 4, value: 'April'}, 
                                                                {key: 5, value: 'May'},
                                                                {key: 6, value: 'June'},
                                                                {key: 7, value: 'July'},
                                                                {key: 8, value: 'August'},
                                                                {key: 9, value: 'September'},
                                                                {key: 10, value: 'October'},
                                                                {key: 11, value: 'November'},
                                                                {key: 12, value: 'December'}, 
                                                            ]}
                                                            onChange={(e) => setStartMonth(e.target.value != null ? e.target.value : '')}
                                                            isSort={false}
                                                        />
                                                    </MDBox>
                                                </Grid>
                                                <Grid item xs={12} lg={2} md={6} px={1}>
                                                    <MDBox mb={1}>
                                                        <MDSelect
                                                            label="End Month"
                                                            name="end_month"
                                                            fullWidth
                                                            value={endMonth}
                                                            options={[
                                                                {key: 1, value: 'January'}, 
                                                                {key: 2, value: 'February'}, 
                                                                {key: 3, value: 'March'}, 
                                                                {key: 4, value: 'April'}, 
                                                                {key: 5, value: 'May'},
                                                                {key: 6, value: 'June'},
                                                                {key: 7, value: 'July'},
                                                                {key: 8, value: 'August'},
                                                                {key: 9, value: 'September'},
                                                                {key: 10, value: 'October'},
                                                                {key: 11, value: 'November'},
                                                                {key: 12, value: 'December'}, 
                                                            ]}
                                                            onChange={(e) => setEndMonth(e.target.value != null ? e.target.value : '')}
                                                            isSort={false}
                                                        />
                                                    </MDBox>
                                                </Grid>
                                            </>
                                        )}
                                    </>    
                                    )}
                                    <Grid item lg={1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {machineWiseSale.length > 0 && (
                                        <Grid item lg={1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(machineWiseSale, totalData, printingMachineDropdown, printingMachineArray)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '8%', fontSize: '0.875rem', borderRight: '1px solid #ccc', textAlign: 'center'}}>Date</TableCell>
                                            {printingMachineDropdown?.map((eachType) => {
                                                if (printingMachineArray.includes(eachType?.key)) {
                                                    return (
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.975rem', borderRight: '1px solid #ccc', textAlign: 'center'}}>{eachType?.value}</TableCell>
                                                    )
                                                }
                                            })}
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc', textAlign: 'center'}}>Total</TableCell>
                                        </TableRow>
                                        {machineWiseSale?.map((eachItem, index) => {
                                            let rowColor = index % 2 === 0 ? '#e0f8f7' : '#effbfb';
                                            return (
                                                <TableRow sx={{backgroundColor: rowColor}}>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: '#284464'}}><b>{eachItem?.date}</b></TableCell>
                                                    {printingMachineDropdown?.map((eachType) => {
                                                        if (printingMachineArray.includes(eachType?.key)) {
                                                            return (
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right', color: '#284464'}}><b>{eachItem[eachType?.key] ? numberFormat(eachItem[eachType?.key]) : '0.00'}</b></TableCell>
                                                            )
                                                        }
                                                    })}
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}><b>{numberFormat(eachItem?.total)}</b></TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        {machineWiseSale.length <= 0 ? 
                                            <TableRow>
                                                <TableCell colSpan={Number(2) + Number(printingMachineArray.length)} sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', fontSize: '0.875rem', borderRight: '1px solid #ccc', textAlign: 'center', color:'red'}}>No records found.</TableCell>
                                            </TableRow>
                                        :
                                            <TableRow>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Total</TableCell>
                                                {printingMachineDropdown?.map((eachType) => {
                                                    if (printingMachineArray.includes(eachType?.key)) {
                                                        return (
                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{totalData[eachType?.key] ? numberFormat(totalData[eachType?.key]) : '0.00'}</TableCell>
                                                        )
                                                    }
                                                })}
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', fontSize: '0.875rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.total)}</TableCell>
                                            </TableRow> 
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
