/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDTabPanel = forwardRef(
  ({ children, value, index, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    return (
        <div
        role="tabpanel"
        ref={ref}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...rest}
      >
        {value === index && (
          <MDBox sx={{ p: 3 }}>
            <MDTypography>{children}</MDTypography>
          </MDBox>
        )}
      </div>
    );
  }
);

// Typechecking props for the MDButton
MDTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default MDTabPanel;
