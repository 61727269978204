/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDSwitch from "components/MDSwitch";
import UserService from "services/user-service";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";

// @mui icons
import Icon from "@mui/material/Icon";


// Images
import customAlert from "components/MDAlert/customAlert";
import authPermission from "utility/auth-permissions";
import defaultUserImage from "utility/default-user-image";
import { useCache } from "context";

export default function data() {

  const [userData, setUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();

  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/user-management');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.user;
  const loggedUserId = localStorage.getItem('user_id');

  const [totalData, setTotalData] = useState(0);
  const { cacheItems, setCacheItems } = useCache();
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const getUserData = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    const response = await UserService.getUsers(page, pageSize, sortColumn, sortColumnValue, searchValue);
    setUserData(response?.data);
    setTotalData(response?.total);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    //getUserData(page_no, page_size, sort_column, sort_column_value, search_value);
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveUser(preserveData);
    getUserData(page_no, page_size, sortColumn, sortColumnValue, searchWord);
  };

  const updateUserStatus = async (id, status) => {
    try {
      var data = {'active': status};
      const res = await UserService.updateUserStatus(id, data);
      if (res) {
        if (cacheItems.hasOwnProperty('users') || cacheItems.hasOwnProperty('salesperson') || cacheItems.hasOwnProperty('operators')) {
          // Create a new object without the key
          const { users, salesperson, ...rest } = cacheItems; // Destructure to exclude key
          setCacheItems(rest); // Set the new cache items without key
        }
        openAlertSB({color: 'success', icon: 'check', msg: res, title: 'User'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        const response = await UserService.getUsers(page_no, page_size, sort_column, sort_column_value, search_value);
        setUserData(response?.data);
        setTotalData(response?.total);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'User'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'User'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const deleteUser = async (id) => {
    try {
    const response = await UserService.deleteUser(id);
    if (cacheItems.hasOwnProperty('users') || cacheItems.hasOwnProperty('salesperson') || cacheItems.hasOwnProperty('operators')) {
      // Create a new object without the key
      const { users, salesperson, ...rest } = cacheItems; // Destructure to exclude key
      setCacheItems(rest); // Set the new cache items without key
    }
    openAlertSB({color: 'success', icon: 'check', msg: response, title: 'User'});
    setTimeout(function() {
      closeAlertSB();
    }, 4000);
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'User'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'User'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    setSelectedId('');
    handleClose();
    getUserData(page_no, page_size, sort_column, sort_column_value, search_value);
  };

  const formatDate = (date) => {
      var d = new Date(date);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = d.getFullYear();
      if (month.length < 2) {
          month = '0' + month;
      }
      if (day.length < 2) {
          day = '0' + day;
      }
      return [year, month, day].join('-');
  };

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="body2">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="body2" color="text">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  let rows = [];
  userData?.map((eachUser, index) => {
      const cols = {
        id:(
              <MDTypography component={Link} to={(eachUser.id != loggedUserId && pagePermission.edit) ? `/user-management/edit?id=${eachUser.id}` : ''} variant="body2" color="text">
                {eachUser.id}
              </MDTypography>
            ),
        first_name:<Author image={defaultUserImage(eachUser.first_name + " " +eachUser.last_name)} name={eachUser.first_name + (eachUser.middle_name ? " " + eachUser.middle_name : "") + " " +eachUser.last_name} email="" />,
        role:<Job title={eachUser.role} description="" />,
        email: (
          <MDTypography component="a" href="#" variant="body2" color="text">
            {eachUser.email}
          </MDTypography>
        ),
        created_at: (
          <MDTypography component="a" href="#" variant="body2" color="text" whiteSpace="nowrap">
            {formatDate(eachUser.created_at)}
          </MDTypography>
        ),
        action: (eachUser.id != loggedUserId) ? (
          <MDBox display="flex">
            {pagePermission.status ? (
              <MDSwitch
                checked={eachUser.active ? true : false}
                color="info"
                title="Status"
                onChange={() => updateUserStatus(eachUser.id , !eachUser.active)}
              />
            ) : ''}
            {pagePermission.edit ? (
              <MDTypography mr={1} mt={1} component={Link} to={`/user-management/edit?id=${eachUser.id}`} display="block" variant="body2" color="info" title="Edit">
                <Icon fontSize="medium">edit</Icon>
              </MDTypography>
            ) : ''}
            {pagePermission.new ? (
              <MDTypography mr={1} mt={1} component={Link} to={`/user-management/add?id=${eachUser.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                <Icon fontSize="medium">content_copy_icon</Icon>
              </MDTypography>
            ) : ''}
            {pagePermission.remove ? (
              <MDTypography mt={1} title="Remove" component={Link}
              onClick={() => handleClickOpen(eachUser.id)}
              display="block" variant="body2" color="error">
                <Icon fontSize="medium">delete</Icon>
              </MDTypography>
            ) : ''}
            {renderAlertSB}
          </MDBox>
        ) : '' ,
      };
      rows[index] = cols;
  });


  return {
    columns: [
      { Header: "id", accessor: "id", width: "10%", align: "left" },
      { Header: "user", accessor: "first_name", width: "28%", align: "left" },
      { Header: "email", accessor: "email", width: "27%", align: "left" },
      { Header: "role", accessor: "role", width: "8%", align: "left" },
      { Header: "creation date", accessor: "created_at", width: "18%", align: "center" },
      ...(pagePermission.edit || pagePermission.remove || pagePermission.status) ? [{ Header: "actions", accessor: "action", align: "center" }] : [],
    ],

    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deleteUser: deleteUser,
    selectedId: selectedId,
    pagePermission: pagePermission,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
  };
}
