import HttpService from "./htttp.service";

class CustomerRateService {

  getCustomerRates = async(id) => {
    const fetchUrl = 'customer_rates?id=' + id;
    return await HttpService.get(fetchUrl);
  }

  addCustomerRate = async(newCustomerRate) => {
    const fetchUrl = 'customer_rates';
    return await HttpService.post(fetchUrl, newCustomerRate);
  }

  updateCustomerRate = async (id, data) => {
    const fetchUrl = 'customer_rates/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updateCustomerRateStatus = async (id, data) => {
    const fetchUrl = 'customer_rates/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showCustomerRate = async(id) => {
    const fetchUrl = 'customer_rates/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteCustomerRate = async (id) => {
    const fetchUrl = 'customer_rates/' + id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllCustomerRate = async (customer_id, printing_machine_id) => {
    const fetchUrl = 'customer_rates?customer_id=' + customer_id + '&printing_machine_id=' + printing_machine_id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new CustomerRateService();
