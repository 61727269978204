/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportService from "services/report-service";
import CustomerService from "services/customer-service";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
import PaymentTypeService from "services/payment-type-service";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const woPermission = hasPermission('/manage-work-orders');
    const [customerDropdown, setCustomerDropdown] = useState([]);
    const [paymentDropdown, setPaymentDropdown] = useState([]);
    const [paymentReceived, setPaymentReceived] = useState([]);
    const [total, setTotal] = useState(0);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [isCash, setIsCash] = useState('');
    const [startDate, setStartDate] = useState((dayjs(new Date()).startOf('month')));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [filter, setFilter] = useState('');
    const { cacheItems, setCacheItems } = useCache();
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getPaymentReceived = async(customerId = '', paymentId = '', isCash = '', startDate = '', endDate = '') => {
        const data = await ReportService.getPaymentReceived(customerId, paymentId, isCash, startDate, endDate);
        setPaymentReceived(data.wo_payments);
        setTotal(data.total);
    }

    useEffect(() => {
        const getCustomersData = async() => {
            let response; // Declare response outside the if...else block
            if (cacheItems.hasOwnProperty('customers')) {
                response = cacheItems['customers'];
            } else {
                response = await CustomerService.getCustomers(); // Fetch customers from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    customers: response,
                }));
            }
          
            if (response) {
              var customerArray = [];
              response?.map((eachCustomer, index) => {
                if(eachCustomer?.active) {
                    customerArray[index] = {
                        key: eachCustomer?.id,
                        value: eachCustomer?.name,
                        disabled: eachCustomer?.active ? false : true,
                    }
                }
              });
              setCustomerDropdown(customerArray);
            }
        }
        getCustomersData();

        const getPaymentData = async() => {
            let response; // Declare response outside the if...else block
            if (cacheItems.hasOwnProperty('payment_types')) {
                response = cacheItems['payment_types'];
            } else {
                response = await PaymentTypeService.getPaymentTypes(); // Fetch customers from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    payment_types: response,
                }));
            }
              if (response) {
                var paymentArray = [];
                response?.map((eachPayment, index) => {
                  if(eachPayment?.active) {
                      paymentArray[index] = {
                          key: eachPayment?.id,
                          value: eachPayment?.name,
                          disabled: eachPayment?.active ? false : true,
                      }
                  }
                });
                setPaymentDropdown(paymentArray);
              }
          }
          getPaymentData();
        getPaymentReceived(customerId, paymentId, isCash, dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '', dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '');
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
        const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
        getPaymentReceived(customerId, paymentId, isCash, sDate, eDate);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setCustomerId('');
        setPaymentId('');
        setIsCash('');
        setStartDate('');
        setEndDate('');
        setFilter(null);
        getPaymentReceived();
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data, total) => {
        setExcelLoading(true);
        const fileName = 'payment_received_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Payment Received']);
        //Merge cells for supplier row
        worksheet.mergeCells(`A1:E1`);
        // Add headers
        const headerRow = worksheet.addRow(['Payment Date', 'Party', 'Payment Type', 'JC# / Bill#', 'Amount']);
        // Set the width for each column
        worksheet.columns = [
            { width: 20 }, 
            { width: 30 },
            { width: 25},
            { width: 10 }, 
            { width: 20 },
        ];
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 1; i < 6; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
            //Add border
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        let k = 3;
        // Add data
        data?.forEach((row, index) => {
            const cellRow = worksheet.addRow([dayjs(row?.payment_date).format('DD-MMM-YYYY'), row?.customer, row?.payment_name, row?.work_order_id, Number(row?.amount)]);
            let rowColor = index % 2 == 0 ? 'e0f8f7' : 'effbfb';
            for (var i = 1; i < 6; i++) {
                cellRow.getCell(i).fill = {
                    type: 'pattern',
                    name: 'sans-serif',
                    pattern: 'solid',
                    fgColor: { argb: rowColor },
                };
                // Add border to the row
                cellRow.getCell(i).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
            cellRow.getCell(5).numFmt = '#,##0.00';
            k += 1;
        });

        const cellRowGrandTotal = worksheet.addRow(['', '', '', 'Total:', Number(total)]);
        // worksheet.mergeCells(`A${k}:D${k}`);
        // cellRowGrandTotal.getCell(1).alignment = { horizontal: 'right' };
        cellRowGrandTotal.getCell(5).numFmt = '#,##0.00';
        cellRowGrandTotal.font = { bold: true, name: 'sans-serif' };
      
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(paymentReceived){
            const pdfTemplate = PDFTemplate(paymentReceived, total, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`${pdfTemplate}`);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Payment Received'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Payment Received'}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                    <Grid item xs={12} lg={2} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Customer"
                                                fullWidth
                                                name="customer_id"
                                                value={customerId}
                                                onChange={(e) => setCustomerId(e.target.value != null ? e.target.value : '')}
                                                options={customerDropdown}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={2} px={1} md={3}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Payment Type"
                                                fullWidth
                                                name="payment_type"
                                                value={paymentId}
                                                onChange={(e) => setPaymentId(e.target.value != null ? e.target.value : '')}
                                                options={paymentDropdown}
                                            />
                                        </MDBox>
                                     </Grid>
                                     <Grid item xs={12} lg={1} px={1} md={3}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Type"
                                                fullWidth
                                                name="type"
                                                value={paymentId}
                                                onChange={(e) => setIsCash(e.target.value != null ? e.target.value : '')}
                                                options={[{key: 1, value: 'Cash'}, {key: 2, value:'Cheque'}]}
                                            />
                                        </MDBox>
                                     </Grid>
                                     <Grid item lg={5} xs={12} px={1} md={8}>
                                        <MDBox mb={1}>
                                            <MDDateRangePicker
                                                label="Start Date"
                                                name="start_date"
                                                value={startDate ? startDate : null}
                                                format="DD-MM-YYYY"
                                                onChange={(newValue) => {
                                                    setDate(newValue, 'start_date');
                                                }}
                                                endLabel="End Date"
                                                endName="end_date"
                                                endValue={endDate ? endDate : null}
                                                endOnChange={(newValue) => {
                                                    setDate(newValue, 'end_date');
                                                }}
                                                onFilterChange={onFilterChange}
                                                filter={filter}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {paymentReceived.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(paymentReceived, total)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Payment Date</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '30%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Party</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Payment Type</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>JC# / Bill#</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Amount</TableCell>
                                        </TableRow>
                                        {paymentReceived?.map((item, index) => {
                                            let rowColor = index % 2 == 0 ? '#e0f8f7' : '#effbfb';
                                            return (
                                                <TableRow sx={{ backgroundColor: rowColor }}>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{dayjs(item?.payment_date).format('DD-MMM-YYYY')}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '30%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.customer}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.payment_name}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: 'info.main'}} component={Link} to={woPermission.edit ? `/manage-work-orders/edit?id=${item?.work_order_id}&tab=5&close_window=1` : ``} target={'_blank'}>{item?.work_order_id}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.amount)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        {paymentReceived.length == 0 ? 
                                            <TableRow>
                                                <TableCell colSpan={5} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow>
                                        : 
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>Total:</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(total)}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
