import HttpService from "./htttp.service";

class WoPaymentService {

  getWoPayments = async(id) => {
    let fetchUrl = 'wo_payments';
    if (id) {
      fetchUrl = 'wo_payments?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addWoPayment = async(data) => {
    const fetchUrl = 'wo_payments';
    return await HttpService.post(fetchUrl, data);
  }

  updateWoPayment = async (id, data) => {
    const fetchUrl = 'wo_payments/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showWoPayment = async(id) => {
    const fetchUrl = 'wo_payments/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteWoPayment = async (id) => {
    const fetchUrl = 'wo_payments/' + id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllWoPayment = async (work_order_id) => {
    const fetchUrl = 'wo_payments?work_order_id=' + work_order_id;
    return await HttpService.delete(fetchUrl);
  }

  approveWoPayment = async (work_order_id) => {
    const fetchUrl = 'wo_payments_approve?work_order_id=' + work_order_id;
    return await HttpService.patch(fetchUrl);
  }
}

export default new WoPaymentService();
