import dayjs from 'dayjs';

export const PDFTemplate = (incompleteWo, numberFormat) => {
    return  `<html>
  <head>
    <title>incomplete_work_orders_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Incomplete Work Orders</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">WO#</th>
                <th style="background-color: #dddddd; width: 13%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Date</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Party</th>
                <th style="background-color: #dddddd; width: 28%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Job Type</th>
                <th style="background-color: #dddddd; width: 2%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Qty.</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Size</th>
                <th style="background-color: #dddddd; max-width: 37%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Instruction</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-bottom: 1px solid #000;">Status</th>
            </tr>
            ${incompleteWo?.map((item, index) => {
                let rowColor = index % 2 === 0 ? '#e0f8f7' : '#effbfb';
                return `
                    <tr style="background-color: ${rowColor}">
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1) != incompleteWo?.length ? '1px solid #000' : ''};">${item?.work_order_id}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1) != incompleteWo?.length ? '1px solid #000' : ''};">${dayjs(item?.release_date).format('DD-MMM-YYYY')}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1) != incompleteWo?.length ? '1px solid #000' : ''};">${item?.customer_name}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1) != incompleteWo?.length ? '1px solid #000' : ''};">${item?.job_type}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1) != incompleteWo?.length ? '1px solid #000' : ''};text-align: right;">0.00</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1) != incompleteWo?.length ? '1px solid #000' : ''};">${item?.size_name ? item?.size_name : ''}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1) != incompleteWo?.length ? '1px solid #000' : ''};">${item?.instruction ? item?.instruction : ''}</td>
                        <td style="font-size: 0.775rem; border-bottom: ${(index + 1) != incompleteWo?.length ? '1px solid #000' : ''};">${item?.status_name}</td>
                    </tr>
                `
            }).join('')}        
        </table>
      </center>
    </div>
  </body>
</html>`

}
