import { forwardRef } from "react";

import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import useMediaQuery from '@mui/material/useMediaQuery';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const predefinedFilters = [
  { value: "Today", key: 1 },
  { value: "Yesterday", key: 2 },
  { value: "This Week", key: 3 },
  { value: "Last Week", key: 4 },
  { value: "This Month", key: 5 },
  { value: "Last Month", key: 6 },
  { value: "This Year", key: 7 },
  { value: "Last Year", key: 8 },
];

const MDDateRangePicker = forwardRef(
  ({ label,
     name,
     value,
     format,
     onChange,
     slotProps,
     disabled,
     endLabel,
     endName,
     endValue,
     endOnChange,
     endDisabled,
     sx,
     filter,
     onFilterChange,
     ...rest }, ref) => {
    const [controller] = useMaterialUIController();

    const minWidthD = useMediaQuery('(min-width:1432px)');
    const handleFilterChange = (event) => {
      const selectedFilter = predefinedFilters.find((filter) => filter.key === event.target.value);
      if (selectedFilter) {
        dayjs.locale('IN');
        let startDate = dayjs();
        let endDate = dayjs();
        
        switch (selectedFilter.key) {
          case 1:
            startDate = dayjs().startOf("day");
            endDate = dayjs().endOf("day");
            break;
          case 2:
            startDate = dayjs().subtract(1, "day").startOf("day");
            endDate = dayjs().subtract(1, "day").endOf("day");
            break;
          case 3:
            startDate = dayjs().startOf("week").add(1, "day");
            endDate = dayjs().endOf("week").add(1, "day");
            break;
          case 4:
            let lastWeek = dayjs().subtract(1, "week");
            startDate = lastWeek.startOf("week").add(1, "day");
            endDate = lastWeek.endOf("week").add(1, "day");
            break;
          case 5:
            startDate = dayjs().startOf("month");
            endDate = dayjs().endOf("month");
            break;
          case 6:
            let lastMonth = dayjs().subtract(1, "month");
            startDate = lastMonth.startOf("month");
            endDate = lastMonth.endOf("month");
            break;
          case 7:
            startDate = dayjs().startOf("year").add(3, 'month');
            endDate = dayjs().endOf("year").add(3, 'month');
            break;
          case 8:
            let lastYear = dayjs().subtract(1, "year");
            startDate = lastYear.startOf("year").add(3, 'month');
            endDate = lastYear.endOf("year").add(3, 'month');
          break;
          default:
            break;
        }
        onFilterChange(startDate, endDate);
      } else {
        onFilterChange('', '');
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']} sx={{marginTop: '-0.500rem'}}>
          <div style={{ display: 'flex', gap: 5 }}>
            <MDSelect
              label="Pre-filter"
              name="pre_filter"
              value={filter}
              onChange={(value) => handleFilterChange(value)}
              options={predefinedFilters}
              sx={rest.filterSx ? rest.filterSx : {minWidth: minWidthD ? 80 : 60}}
            />
            <DatePicker
                {...rest}
                label={label}
                name={name}
                value={value}
                format={format}
                onChange={onChange}
                slotProps={slotProps}
                disabled={disabled}
                sx={sx}
                maxDate={endValue}
                renderInput={(params) => (
                <MDInput {...params} 
                inputProps={{
                  style: {
                    paddingRight: 4, /* adjust the value to your liking */
                  },
                }}/>
                )}
            />
            <DatePicker
                {...rest}
                label={endLabel}
                name={endName}
                value={endValue}
                format={format}
                onChange={endOnChange}
                slotProps={slotProps}
                disabled={endDisabled ? endDisabled : (value ? false : true)}
                sx={sx}
                minDate={value}
                renderInput={(params) => (
                <MDInput {...params}
                inputProps={{
                  style: {
                    paddingRight: 4, /* adjust the value to your liking */
                  },
                }} />
                )}
            />
          </div>
        </DemoContainer>
      </LocalizationProvider>
    );
  }
);

// Setting default values for the props of MDDatePicker
MDDateRangePicker.defaultProps = {
  slotProps: { textField: {
      fullWidth: true,
      error: false,
      required: false,
      size: 'small',
    },
    field: {
      clearable: true
    },
  },
  sx: {width: "100%", height: "40px"},
  disabled: false,
  endDisabled: false,
  filter: "",
  onFilterChange: () => {},
};

export default MDDateRangePicker;