import HttpService from "./htttp.service";

class InvoiceService {

  getInvoices = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'invoices';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'invoices?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + searchValue;
    }
    return await HttpService.get(fetchUrl);
  }

  addInvoice = async(data) => {
    const fetchUrl = 'invoices';
    return await HttpService.post(fetchUrl, data);
  }

  updateInvoice = async (id, data) => {
    const fetchUrl = 'invoices/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showInvoice = async(id) => {
    const fetchUrl = 'invoices/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteInvoice = async(id) => {
    const fetchUrl = 'invoices/' + id;
    return await HttpService.delete(fetchUrl);
  }

  sendEmail = async (id) => {
    const fetchUrl = 'invoice_send_email/' + id + '?is_demo_env=' + (process.env.REACT_APP_IS_DEMO === "true" ? 1 : 0);
    return await HttpService.get(fetchUrl);
  }

  updateInvoiceTotal = async (data) => {
    const fetchUrl = 'invoice_update_total';
    return await HttpService.put(fetchUrl, data);
  }
}

export default new InvoiceService();