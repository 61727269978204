/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import customerInventoriesData from "layouts/manage-customers/data-customer-inventories";

function Customers() {
  const {
    columns,
    rows,
    open,
    handleClickOpen,
    handleClose,
    deleteCustomerInventory,
    selectedId,
    totalData,
    page,
    pageSize,
    getData,
    sortColumn,
    sortColumnValue,
    searchValue,
    pagePermission,
    id,
    ref,
    customer_name } = customerInventoriesData();

    const goBack = async() => {
        window.location.href = `/manage-customers/edit?id=${id}`;
    }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={'Customer Inventories : ' + customer_name}/>
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.3rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                <Grid container>
                  <Grid item xs={10}>
                    <MDTypography mt={1} variant="h6" color="white">
                      {'Customer Inventories : ' + customer_name}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={2} justifyContent="space-between" textAlign="right">
                    {pagePermission.new ? (
                      <MDButton ml={5}
                        component={Link}
                        to={`/manage-customers/customer-inventories/add?customer_id=${id}&customer_name=${customer_name}`}
                        title="Add"
                        variant="outlined" color="white" iconOnly circular>
                        <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>add</Icon>
                      </MDButton>
                    ) : ''}
                    <MDButton
                      title="Edit"
                      component={Link}
                      sx={{marginLeft: 2}}
                      to={ref ? `/manage-customers/edit?id=${id}&ref=${ref}` : `/manage-customers/edit?id=${id}`}
                      variant="outlined" color="white" iconOnly circular>
                          <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>edit</Icon>
                    </MDButton>
                  </Grid>
                </Grid>
                </MDBox>
                <MDBox mb={2}>
                  <DataTable
                    // entriesPerPage={true}
                    entriesPerPage={{
                      "defaultValue": pageSize
                    }}
                    canSearch={true}
                    showTotalEntries={true}
                    table={{ columns, rows }}
                    isSorted={true}
                    totalData={totalData}
                    initialPageIndex={page - 1}
                    getData={getData}
                    pageCount={Math.ceil(totalData/pageSize)}
                    sortCol={sortColumn}
                    sortColValue={sortColumnValue}
                    searchVal={searchValue}
                    noEndBorder={false}
                    horizontalRowPadding={0.5}
                    doubleClick={false}
                  />
                  <Grid container>
                    <Grid item md={11} xs={12} px={3}></Grid>
                    <Grid item md={1} xs={12} px={3}>
                      <MDBox>
                        <MDButton component={Link} onClick={() => goBack()} variant="gradient" color="primary" fullWidth>
                          Back
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      <MDDialog
        open={open}
        onClose={handleClose}
        successAction={() => deleteCustomerInventory(selectedId)}
        dialogTitle={'Are you Sure?'}
        cancleButton={'No, Please!'}
        successButton={'Yes, Delete it!'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
      >
      <p>You will not be able to recover the deleted record!</p>
      </MDDialog>
    </>
  );
}

export default Customers;
