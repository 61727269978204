import dayjs from 'dayjs';

export const PDFTemplate = (supplierPaymentDue, grandTotal, numberFormat) => {
    return  `<html>
  <head>
    <title>supplier_payment_due_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Supplier Payment Due</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">GRN</th>
                <th style="background-color: #dddddd; width: 12%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Bill No.</th>
                <th style="background-color: #dddddd; width: 13%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Bill Date</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Net Amount</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Payment</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">OS Amount</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-bottom: 1px solid #000;">Days Due</th>
            </tr>
            ${supplierPaymentDue?.map((eachItem, index) => {
                let rowColor = '#effbfb';
                return `
                    <tr style="background-color: #f8ece0;">
                        <td colspan="7" style="border-bottom: 1px solid #000;"><b>Supplier : </b>${eachItem?.supplier_name}</td>
                    </tr>
                    ${eachItem?.items?.map((item) => {
                        rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                        return `
                            <tr style="background-color: ${rowColor}">
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.grn_no}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.bill_no}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.bill_date}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${numberFormat(item?.net_amount)}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${numberFormat(item?.payment)}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${numberFormat(item?.out_standing)}</td>
                                <td style="font-size: 0.775rem; border-bottom: 1px solid #000; text-align: right;">${item?.due_days}</td>
                            </tr>
                        `
                    }). join('')}
                    <tr>
                        <td colspan="6" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right; border-bottom: 1px solid #000;"><b>Total:</b></td>
                        <td style="font-size: 0.875rem; text-align: right; border-bottom: 1px solid #000;">${numberFormat(eachItem?.total)}</td>
                    </tr>
                `
            }).join('')}
            <tr>
                <td colspan="6" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Grand Total:</b></td>
                <td style="font-size: 0.875rem; text-align: right;">${numberFormat(grandTotal)}</td>
            </tr>        
        </table>
      </center>
    </div>
  </body>
</html>`

}
