import dayjs from 'dayjs';
import { formatDateTime } from "utility/helpers";

export const PDFTemplate = (operatorJobSheet, numberFormat) => {
    const loggedUserRole = localStorage.getItem('role');
    return  `<html>
  <head>
    <title>operator_job_sheet_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Operator Job Sheet</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 4%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Sr No.</th>
                <th style="background-color: #dddddd; width: 6%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Job Card No.</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Party Name</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Narration</th>
                <th style="background-color: #dddddd; width: 25%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Job Type</th>
                <th style="background-color: #dddddd; width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Size</th>
                <th style="background-color: #dddddd; width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Printing Date</th>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Color</th>
                <th style="background-color: #dddddd; width: 4%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Quantity</th>
                <th style="background-color: #dddddd; width: 3%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Type</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-bottom: 1px solid #000;">Duration</th>
            </tr>
            ${operatorJobSheet?.map((eachItem, index) => {
                let i = 0;
                let rowColor = '#e0f8f7';
                return `
                    ${loggedUserRole == 'Admin' ? (
                        `<tr>
                            <td colspan="10" style="border-right: 1px solid #000; border-bottom: 1px solid #000;"><b>Operator : </b>${eachItem?.operator_name}</td>
                        </tr>`) : ''}
                    ${eachItem?.items?.map((item) => {
                        i += 1;
                        rowColor = i % 2 === 0 ? '#e0f8f7' : '#effbfb';
                        return `
                            <tr style="background-color: ${rowColor}">
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${i}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.wo_id}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;"><b>${item?.customer_name}</b></td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.description}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.job_type}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.size}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${formatDateTime(item?.printing_date)}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.color}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right">${item?.quantity}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.type}</td>
                                <td style="font-size: 0.775rem; text-align: right;border-bottom: 1px solid #000;"><b>${item?.duration}</b></td>
                            </tr>
                        `
                    }). join('')}
                `
            }).join('')}
        </table>
      </center>
    </div>
  </body>
</html>`

}
