/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import MDDatePicker from "components/MDDatePicker";
import customAlert from "components/MDAlert/customAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui icons
import Icon from "@mui/material/Icon";
import HsnCodeService from "services/hsn-code-service";
import SizeService from "services/size-service";
import authPermission from "utility/auth-permissions";
import { numberFormat, onlyNumbers } from "utility/helpers";
import InvoiceService from "services/invoice-service";
import WorkOrderService from "services/work-order-service";
import WoMaterialItemService from "services/wo-material-item-service";
import WoPrintingItemService from "services/wo-printing-item-service";
import WoPaymentService from "services/wo-payment-service";
import InvoiceMaterialItemService from "services/invoice-material-item-service";
import InvoicePrintingItemService from "services/invoice-printing-item-service";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { invoicePDFTemplate } from "./invoice-pdf-template";
import { invoicePDFTemplatePrint } from "./invoice-pdf-template-print";
import Paper from '@mui/material/Paper';
import MDCheckbox from "components/MDCheckbox";
import MDDialog from "components/MDDialog";
import { useCache } from "context";
import WoStockItemService from "services/wo-stock-item-service";
import InvoiceStockItemService from "services/invoice-stock-item-service";

const stageType = ['DESIGN','PAPER','PRINTING','BINDING','EXTRA'];
//temporary check for staging only it will be removed once it goes on production
const domainRegex = /\@(techhighway\.co\.in|techhighway\.ca)$/i;

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const ref = searchParams.get('ref');
  const closeWindow = searchParams.get('close_window') ? 1 : 0;
  const navigate = useNavigate();

  const { systemConstant } = authPermission();
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const companyName = systemConstant('COMPANY_NAME');
  const { cacheItems, setCacheItems } = useCache();
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [releaseDate, setReleaseDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [hsnCodeDropdown, setHsnCodeDropdown] = useState([]);
  const [hsnTax, setHsnTax] = useState([]);
  const [sizeDropdown, setSizeDropdown] = useState([]);
  const [printingItems, setPrintingItems] = useState([]);
  const [materialItems, setMaterialItems] = useState([]);
  const [paymentItems, setPaymentItems] = useState([]);
  const [materialTotal, setMaterialTotal] = useState([]);
  const [materialAllTotal, setMaterialAllTotal] = useState(0);
  const [printingTotal, setPrintingTotal] = useState(0);
  const [stockItems, setStockItems] = useState([]);
  const [stockTotal, setStockTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);
  const [invoice, setInvoice] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);

  const goBack = async() => {
    if (ref) {
      window.location.href = ref;
    } else if (closeWindow) {
      window.open("about:blank", "_self");
      window.close();
    } else {
      window.location.href = '/manage-invoices';
    }
  }

  const [inputs, setInputs] = useState({
    work_order_id: "",
    customer_id: "",
    customer_name: "",
    hsn_code_id: "",
    customer_gstin: "",
    job_type: "",
    size_id: "",
    dc_no: "",
    instruction: "",
    discount: "",
    discount_desc: "",
    vat: "",
    state_gst: "",
    central_gst: "",
    is_design: 1,
    is_printing: 1,
    is_paper: 1,
    is_binding: 1,
    is_extra: 1,
  });

  useEffect(() => {
    const getHsnCodeData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('hsn_codes')) {
        response = cacheItems['hsn_codes'];
      } else {
        response = await HsnCodeService.getHsnCodes(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            hsn_codes: response,
        }));
      }
      if (response) {
        var hsnArray = [];
        var hsnGst = [];
        response?.map((eachCode, index) => {
          hsnArray[index] = {
            key: eachCode.id,
            value: eachCode.code,
            disabled: eachCode?.active ? false : true,
          }
          hsnGst[eachCode.id] = {
            gst: eachCode.tax_pct
          }
        });
        setHsnTax(hsnGst);
        setHsnCodeDropdown(hsnArray);
      }
    }
    getHsnCodeData();

    const getSizeData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('sizes')) {
        response = cacheItems['sizes'];
      } else {
        response = await SizeService.getSizes(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            sizes: response,
        }));
      }
      if (response) {
        var sizeArray = [];
        response?.map((eachSize, index) => {
          sizeArray[index] = {
            key: eachSize.id,
            value: eachSize.name,
            disabled: eachSize?.active ? false : true,
          }
        });
        setSizeDropdown(sizeArray);
      }
    }
    getSizeData();

    if (action === "add") {
        const showWorkOrder = async() => {
            const promises = [
                WorkOrderService.showWorkOrder(id),
                WoMaterialItemService.getWoMaterialItems(id),
                WoPrintingItemService.getWoPrintingItems(id),
                WoPaymentService.getWoPayments(id),
                WoStockItemService.getWoStockItems(id),
            ];
            try {
              const printArray = [];
              const materialArray = [];
              const totalArray = [];
              const response = await Promise.all(promises);
              response[0].customer_name = response[0].customer.name;
              response[0].work_order_id = response[0].id;
              response[0].instruction = '';
              setInputs(response[0]);
              //modify material items
              let materialSum = 0;
              stageType.map((stage) => {
                materialArray[stage] = [];
                let total = 0;
                response[1][stage]?.map((item, ind) => {
                  materialArray[stage][ind] = {
                    stage_type: stage,
                    item_id: item?.item_id,
                    item_description: item?.item.description,
                    quantity: item?.quantity * 1,
                    rate: Number(Number(item?.markup_amount)/Number(item?.quantity)).toFixed(4),
                    markup_pct: item?.markup_pct,
                    amount: item?.markup_amount,
                    is_print: 1,
                  };
                  total = Number(total) + Number(item?.markup_amount);
                });
                totalArray[stage] = Number(total).toFixed(2);
                materialSum = Number(materialSum) + Number(total);
              })
              setMaterialTotal(totalArray);
              setMaterialItems(materialArray);
              setMaterialAllTotal(materialSum);
              //modify printing items
              let printingSum = 0;
              if (response[2]?.length > 0) {
                response[2]?.map((eachRes, index) => {
                  let valueArray = eachRes.color_scheme.split(',').map(Number);
                  printArray[index] = {
                    description: eachRes.description,
                    wo_printing_type: eachRes.print_type.name,
                    quantity: eachRes.quantity * 1,
                    printing_machine: eachRes.print_machine.name,
                    color_scheme: valueArray.length + ' ' + (valueArray.length > 1 ? 'colors' : 'color'),
                    no_sets: eachRes.no_sets,
                    amount: Number(eachRes.total).toFixed(2),
                  }
                  printingSum = Number(printingSum) + Number(eachRes.total);
                });
              }
              setPrintingItems(printArray);
              setPrintingTotal(printingSum);
              //get total paid
              let paymentSum = 0;
              if (response[3]?.length > 0) {
                response[3]?.map((eachRes) => {
                  paymentSum = Number(paymentSum) + Number(eachRes.amount);
                });
              }
              setPaymentItems(response[3]);
              setPaidTotal(paymentSum);
              let stockSum = 0;
              const stockArray = [];
              if (response[4]?.length > 0) {
                let i = 0;
                response[4]?.map((eachRes, index) => {
                  if (eachRes.is_wo_day_wise) {
                    stockArray[i] = {
                      item_id: eachRes?.item_id,
                      item_description: eachRes?.item.description,
                      quantity: eachRes?.quantity * 1,
                      rate: Number(Number(eachRes?.total)/Number(eachRes?.quantity)).toFixed(4),
                      markup_pct: eachRes?.markup_pct,
                      total: eachRes?.total,
                    }
                    stockSum = Number(stockSum) + Number(eachRes.total);
                    i = i + 1;
                  }
                });
              }
              setStockItems(stockArray);
              setStockTotal(stockSum)
              setTotal(Number(materialSum) + Number(printingSum) + Number(stockSum));
            } catch (res) {
              if (res.hasOwnProperty("message")) {
                const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Invoice'};
                navigate('/manage-invoices/create', {state: state});
              } else {
                const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Invoice'};
                navigate('/manage-invoices/create', {state: state});
              }
            }
        }
        showWorkOrder();

    } else {
        const showInvoice = async() => {
            const promises = [
                InvoiceService.showInvoice(id),
                InvoiceMaterialItemService.getInvoiceMaterialItems(id),
                InvoicePrintingItemService.getInvoicePrintingItems(id),
                InvoiceStockItemService.getInvoiceStockItems(id),
            ];
            try {
                const response = await Promise.all(promises);
                const payment = await WoPaymentService.getWoPayments(response[0]?.work_order_id);
                response[0]['discount'] = Number(response[0]['discount']).toFixed(2);
                setHeaderTitle(response[0].invoice_no ? response[0].invoice_no : '');
                setReleaseDate((action == 'add' && id) ? (dayjs(new Date()).format('YYYY-MM-DD')) : (response[0].release_date ? response[0].release_date : ''));
                setInputs(response[0]);
                setInvoice(response[0]);
                //Get total of all items
                let materialSum = 0;
                const totalArray = [];
                const materialPrintArray = [];
                stageType.map((stage) => {
                  let total = 0;
                  let isPrint = 1;
                  response[1][stage]?.map((item, ind) => {
                    response[1][stage][ind]['quantity'] = response[1][stage][ind]['quantity'] * 1;
                    response[1][stage][ind]['rate'] = response[1][stage][ind]['rate'] * 1;
                    total = Number(total) + Number(item?.amount);
                  });
                  totalArray[stage] = Number(total).toFixed(2);
                  materialSum = Number(materialSum) + Number(total);
                })
                setMaterialTotal(totalArray);
                setMaterialAllTotal(materialSum);

                let printingSum = 0;
                if (response[2]?.length > 0) {
                  response[2]?.map((eachRes, index) => {
                    response[2][index]['amount'] = Number(response[2][index]['amount']).toFixed(2);
                    printingSum = Number(printingSum) + Number(eachRes.amount);
                  });
                }
                setPrintingTotal(printingSum);

                let stockSum = 0;
                if (response[3]?.length > 0) {
                  response[3]?.map((eachRes, index) => {
                    response[3][index]['quantity'] = response[3][index]['quantity'] * 1;
                    response[3][index]['rate'] = Number(response[3][index]['rate']) * 1;
                    response[3][index]['total'] = Number(response[3][index]['total']).toFixed(2);
                    stockSum = Number(stockSum) + Number(eachRes.total);
                  });
                }
                setStockTotal(stockSum);

                let paymentSum = 0;
                if (payment?.length > 0) {
                  payment?.map((eachRes) => {
                    paymentSum = Number(paymentSum) + Number(eachRes.amount);
                  });
                }
                setMaterialItems(response[1]);
                setPrintingItems(response[2]);
                setStockItems(response[3]);
                setPaymentItems(payment);
                setPaidTotal(paymentSum);
                setTotal(Number(materialSum) + Number(printingSum) + Number(stockSum) - Number(response[0].discount));
            } catch (res) {
                if (res.hasOwnProperty("message")) {
                    const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Invoice'};
                    navigate('/manage-invoices', {state: state});
                } else {
                    const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Invoice'};
                    navigate('/manage-invoices', {state: state});
                }
            }
        }
        showInvoice();
    }

  }, []);

  const changeHandler = (e) => {
    setLoading(false);
    if (e.target.name == 'hsn_code_id' && e.target.name > 0) {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
        ['state_gst']: Number(hsnTax[e.target.value]['gst'])/2,
        ['central_gst']: Number(hsnTax[e.target.value]['gst'])/2,
      });
    } else if (e.target.name == 'discount') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
      setTotal(Number(printingTotal) + Number(materialAllTotal) - Number(e.target.value));
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getGstPrice = (gstPct, amount) => {
    return (Math.round(amount) * gstPct)/100;
  }

  const getTotal = (amount, sGst = 0, cGst =0) => {
    const gst = Number(sGst) + Number(cGst);
    const total = Number(amount) + Number((amount * gst)/100);
    return Math.round(total);
  }

  const getOutstanding = (amount, deductingAmount = 0) => {
    return Math.round(amount - deductingAmount);
  }

  const onChangePrintingField = (index, fieldName, fieldValue) => {
    const clonePrintingItem = [...printingItems];
    const prevPrintingTotal = printingTotal;
    const prevTotal = total;
    const prevAmount = clonePrintingItem[index]['amount'];

    clonePrintingItem[index][fieldName] = fieldValue;
    if (fieldName == 'amount') {
      const diff = Number(clonePrintingItem[index][fieldName]) - Number(prevAmount);
      setPrintingTotal(Number(prevPrintingTotal) + Number(diff));
      setTotal(Number(prevTotal) + Number(diff));
    }
    setPrintingItems(clonePrintingItem);
  }

  const onChangeMaterialField = (stageName, index, fieldName, fieldValue) => {
    const cloneMaterialItem = {...materialItems};
    const cloneMaterialTotal = materialTotal;
    const prevTotal = total;
    const prevMaterialAllTotal = materialAllTotal;
    const prevAmount = cloneMaterialItem[stageName][index]['amount'];

    cloneMaterialItem[stageName][index][fieldName] = fieldValue;
    if (fieldName == 'quantity' || fieldName == 'rate') {
      cloneMaterialItem[stageName][index]['amount'] = cloneMaterialItem[stageName][index]['quantity'] * cloneMaterialItem[stageName][index]['rate'];
      const diff = Number(cloneMaterialItem[stageName][index]['amount']) - Number(prevAmount);
      cloneMaterialTotal[stageName] = Number(cloneMaterialTotal[stageName]) + Number(diff);
      setTotal(Number(prevTotal) + Number(diff));
      setMaterialTotal(cloneMaterialTotal);
      setMaterialAllTotal(Number(prevMaterialAllTotal) + Number(diff));
    }
    setMaterialItems(cloneMaterialItem);
  }

  const onChangeStockField = (index, fieldName, fieldValue) => {
    const cloneStockItem = [...stockItems];
    const cloneStockTotal = stockTotal;
    const prevTotal = total;
    const prevAmount = cloneStockItem[index]['total'];

    cloneStockItem[index][fieldName] = fieldValue;
    if (fieldName == 'rate') {
      cloneStockItem[index]['total'] = cloneStockItem[index]['quantity'] * cloneStockItem[index]['rate'];
      const diff = Number(cloneStockItem[index]['total']) - Number(prevAmount);
      setTotal(Number(prevTotal) + Number(diff));
      setStockTotal(Number(cloneStockTotal) + Number(diff));
    }
    setStockItems(cloneStockItem);
  }

  const onChangeMaterialPrint = (stage, fieldValue) => {
    switch (stage) {
      case 'DESIGN':
        setInputs({
          ...inputs,
          ['is_design']: fieldValue,
        });
        break;
      case 'PAPER':
        setInputs({
          ...inputs,
          ['is_paper']: fieldValue,
        });
        break;
      case 'PRINTING':
        setInputs({
          ...inputs,
          ['is_printing']: fieldValue,
        });
        break;
      case 'BINDING':
        setInputs({
          ...inputs,
          ['is_binding']: fieldValue,
        });
        break;
      case 'EXTRA':
        setInputs({
          ...inputs,
          ['is_extra']: fieldValue,
        });
        break;
      default:
        setInputs({
          ...inputs,
        });
        break;
    }
  }

  const getStageTypeInputValue = (stage, data) => {
    switch (stage) {
      case 'DESIGN':
        return data.is_design;
      case 'PAPER':
        return data.is_paper;
      case 'PRINTING':
        return data.is_printing;
      case 'BINDING':
        return data.is_binding;
      case 'EXTRA':
        return data.is_extra;
      default:
      return false;
    }
  }

  const submitHandler = async (e, buttonClicked = 'save') => {
    setLoading(true);
    e.preventDefault();

    const newInvoice = {
      work_order_id: inputs.work_order_id,
      customer_id: inputs.customer_id,
      customer_name: inputs.customer_name,
      hsn_code_id: inputs.hsn_code_id,
      customer_gstin: inputs.customer_gstin,
      job_type: inputs.job_type,
      size_id: inputs.size_id,
      release_date: releaseDate,
      dc_no: inputs.dc_no,
      instruction: inputs.instruction,
      printing_charge: printingTotal,
      discount: inputs.discount ? inputs.discount : 0,
      discount_desc: inputs.discount_desc ? inputs.discount_desc : null,
      vat: inputs.vat,
      state_gst: inputs.state_gst,
      central_gst: inputs.central_gst,
      amount: total,
      total: getTotal(total, inputs.state_gst, inputs.central_gst),
      is_design: inputs.is_design,
      is_printing: inputs.is_printing,
      is_paper: inputs.is_paper,
      is_binding: inputs.is_binding,
      is_extra: inputs.is_extra,
    };

    if (!newInvoice.customer_name) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Party is mandatory", title: 'Invoice'});
      setLoading(false);
    } else if (!newInvoice.job_type) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Job type is mandatory", title: 'Invoice'});
      setLoading(false);
    } else if (!newInvoice.size_id) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Size is mandatory", title: 'Invoice'});
      setLoading(false);
    } else {
      if (action === 'add') {
        try {
          const response = await InvoiceService.addInvoice(newInvoice);
          setLoading(false);
          if (response) {
            if (Object.keys(materialItems).length > 0) {
              const obj = {};
              Object.keys(materialItems).forEach(key => {
                obj[key] = materialItems[key];
              });
              const matArray = {
                'material': obj,
                'invoice_id': response.id,
              }
              const materialResponse = await InvoiceMaterialItemService.addInvoiceMaterialItem(matArray);
            }

            if (printingItems.length > 0) {
              const printArray = {
                'printing': printingItems ? printingItems : '',
                'invoice_id': response.id,
              }
              const printingResponse = await InvoicePrintingItemService.addInvoicePrintingItem(printArray);
            }

            if (stockItems.length > 0) {
              const stockArray = {
                'stock': stockItems ? stockItems : '',
                'invoice_id': response.id,
              }
              const stockResponse = await InvoiceStockItemService.addInvoiceStockItem(stockArray);
            }
            const totalUpdateResponse = await InvoiceService.updateInvoiceTotal({id: response.id});
            const state = {color: 'success', icon: 'check', msg: 'Invoice added successfully!', title: 'Invoice'};
            navigate('/manage-invoices', { state: state })
          }
        } catch (res) {
          const totalUpdateResponse = await InvoiceService.updateInvoiceTotal({id: ''});
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Invoice'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Invoice'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      } else {
        try {
          const response = await InvoiceService.updateInvoice(id ,newInvoice);
          setLoading(false);
          if (response) {
            if (Object.keys(materialItems).length > 0) {
              const matArray = {
                'material': materialItems,
                'invoice_id': response.id,
              }
              const deleteWoMaterialItem = await InvoiceMaterialItemService.deleteAllInvoiceMaterialItem(response.id);
              const materialResponse = await InvoiceMaterialItemService.addInvoiceMaterialItem(matArray);
            }

            if (printingItems.length > 0) {
              const printArray = {
                'printing': printingItems ? printingItems : '',
                'invoice_id': response.id,
              }
              const deleteWoPrintingItem = await InvoicePrintingItemService.deleteAllInvoicePrintingItem(response.id);
              const printingResponse = await InvoicePrintingItemService.addInvoicePrintingItem(printArray);
            }

            if (stockItems.length > 0) {
              const stockArray = {
                'stock': stockItems ? stockItems : '',
                'invoice_id': response.id,
              }
              const stockResponse = await InvoiceStockItemService.addInvoiceStockItem(stockArray);
            }
            const state = {color: 'success', icon: 'check', msg: 'Invoice updated successfully!', title: 'Invoice'};
            if (ref) {
              navigate(`${ref}`, { state: state });
            } else {
              navigate('/manage-invoices', { state: state });
            }
          }
        } catch (res) {
          setLoading(false);
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Invoice'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Invoice'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      }
    }

    return () => {
      setInputs({
        work_order_id: "",
        customer_id: "",
        customer_name: "",
        hsn_code_id: "",
        customer_gstin: "",
        job_type: "",
        size_id: "",
        dc_no: "",
        instruction: "",
        discount: "",
        discount_desc: "",
        vat: "",
        state_gst: "",
        central_gst: "",
        is_design: 1,
        is_printing: 1,
        is_paper: 1,
        is_binding: 1,
        is_extra: 1,
      });
    };
  };

  const getPDFData = async() => {
    const promises = [
        InvoiceService.showInvoice(id),
        InvoiceMaterialItemService.getInvoiceMaterialItems(id),
        InvoicePrintingItemService.getInvoicePrintingItems(id),
        InvoiceStockItemService.getInvoiceStockItems(id),
    ];
    const response = await Promise.all(promises);
    const payment = await WoPaymentService.getWoPayments(response[0]?.work_order_id);
    let totalAll = 0;
    let totalRows = 2;
    const totalArray = [];
    const rowPresentArray = [];
    stageType.map((stage) => {
      let total = 0;
      rowPresentArray[stage] = false;
      response[1][stage]?.map((item, ind) => {
        if (getStageTypeInputValue(stage, response[0])) {
          total = Number(total) + Number(item.amount);
          rowPresentArray[stage] = true;
          totalRows = totalRows + 1;
        } else {
          if (item?.is_print) {
            total = Number(total) + Number(item.amount);
            rowPresentArray[stage] = true;
            totalRows = totalRows + 1;
          }
        }
      });
      totalArray[stage] = Number(total);
      totalAll = Number(totalAll) + Number(total);
    });

    response[2]?.map((eachRes, index) => {
      totalAll = Number(totalAll) + Number(eachRes.amount);
      totalRows = totalRows + 1;
    });

    response[3]?.map((eachRes, index) => {
      totalAll = Number(totalAll) + Number(eachRes.total);
      totalRows = totalRows + 1;
    });

    let receivedAmount = 0;
    payment?.map((eachRes) => {
      receivedAmount = Number(receivedAmount) + Number(eachRes.amount);
    });

    return {
      invoice: response[0],
      material: totalArray,
      materialItems: response[1],
      printing: response[2],
      stock: response[3],
      rowPresentArray: rowPresentArray,
      totalAmount: response[0]?.discount ? (Number(totalAll) - Number(response[0].discount)) : totalAll,
      receivedAmount: receivedAmount,
      lastRowHeight: response[0]?.instruction ? 400 : ((19 - totalRows) > 0 ? (19 - totalRows)* 25 : 0),
    };
  }

  const handlePrint = async(isDesign = false) => {
    const response = await getPDFData();
    const companyName = systemConstant('COMPANY_NAME');
    const companyAddress = systemConstant('COMPANY_ADDRESS');
    if(response){
        let pdfTemplate = invoicePDFTemplate(companyName, companyAddress, response.invoice, response.rowPresentArray, response.material, response.materialItems, response.printing, response.stock, Math.round(response.totalAmount), response.receivedAmount, response.lastRowHeight, getGstPrice, getOutstanding, getTotal);
        if (isDesign) {
          pdfTemplate = invoicePDFTemplatePrint(companyName, companyAddress, response.invoice, response.rowPresentArray, response.material, response.materialItems, response.printing, response.stock, Math.round(response.totalAmount), response.receivedAmount, response.lastRowHeight, getGstPrice, getOutstanding, getTotal);
        }

        const originalBody = document.body;
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`${pdfTemplate}`);
        printWindow.document.close();
        await new Promise(r => setTimeout(r, 2000));
        printWindow.print();
        printWindow.close();
        document.body = originalBody;
    }
  };

  const sendEmail = async(id, email) => {
    setOpenPreview(false);
    setEmailLoading(true);
    if (email) {
      try {
        const response = await InvoiceService.sendEmail(id);
        if (response) {
          setInputs({
            ...inputs,
            ['is_email_sent']: 1,
          })
          openAlertSB({color: 'success', icon: 'check', msg: 'Email sent Successfully!', title: 'Invoice'});
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Invoice'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Invoice'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      }
    } else {
      openAlertSB({color: 'warning', icon: 'star', msg: 'Email is not added to this Customer', title: 'Invoice'});
    }
    setEmailLoading(false);
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add Invoice' : ('Invoice : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.3rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={12} md={9}>
                      <MDTypography mt={1} variant="h6" color="white">
                        {action === 'add' ? 'Add Invoice' : ('Invoice : ' + headerTitle)}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      {action === 'edit' && (
                        <MDBox textAlign="right">
                          <MDButton
                              onClick={(e) => handlePrint(false)}
                              variant="gradient" color="secondary" title="Print Blank Invoice" iconOnly>
                                <Icon sx={{ fontWeight: "bold" }}>print_icon </Icon>
                          </MDButton>
                          <MDButton
                              sx={{ marginLeft: 2 }}
                              onClick={(e) => handlePrint(true)}
                              variant="gradient" color="primary" title="Print Design Invoice" iconOnly>
                                <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>
                          </MDButton>
                          <MDButton
                              onClick={() => setOpenPreview(true)}
                              sx={{marginLeft: 2}}
                              variant="gradient" color={inputs.is_email_sent ? 'success' : 'warning'} title={inputs.is_email_sent ? 'Email is sent' : 'Send Email'} iconOnly>
                                {emailLoading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />:<Icon sx={{ fontWeight: "bold" }}>forward_to_inbox_icon</Icon>}
                          </MDButton>
                        </MDBox>
                      )}
                      </Grid>
                  </Grid>
                </MDBox>
                <MDBox mt={3} component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Customer Name"
                              fullWidth
                              value={inputs.customer_name}
                              name="customer_name"
                              onChange={changeHandler}
                              inputProps={{style: {'maxLength':100, fontWeight: 'bold'}}}
                              required
                              sx={{
                                '& .MuiInputLabel-root': {
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={6} xs={12} px={3}>
                          <MDBox mb={2}>
                            <MDDatePicker
                              label="Date"
                              name="release_date"
                              value={dayjs(releaseDate).isValid() ? dayjs(releaseDate) : dayjs(new Date())}
                              format="DD-MM-YYYY"
                              disabled={true}
                              onChange={(newValue) => {
                                setReleaseDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                              }}
                              slotProps={{ textField: {
                                  fullWidth: true,
                                  required: true
                                },
                                field: {
                                  clearable: true
                                },
                                textField: { size: 'small' },
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={2} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Job Card No."
                              fullWidth
                              value={inputs.work_order_id}
                              name="work_order_id"
                              onChange={changeHandler}
                              disabled={true}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={4} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="Size"
                              labelProps={{fontWeight: 'bold'}}
                              fullWidth
                              native
                              name="size_id"
                              value={inputs.size_id}
                              onChange={changeHandler}
                              options={sizeDropdown}
                              required
                              sx={{
                                "& .MuiNativeSelect-select": {
                                  fontWeight: 'bold',
                                },
                                "&.Mui-disabled .MuiNativeSelect-select": {
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Job Type"
                              fullWidth
                              value={inputs.job_type}
                              name="job_type"
                              onChange={changeHandler}
                              inputProps={{style: {'maxLength':100, fontWeight: 'bold'}}}
                              required
                              sx={{
                                '& .MuiInputLabel-root': {
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="HSN/SAC"
                              fullWidth
                              name="hsn_code_id"
                              onChange={changeHandler}
                              value={hsnCodeDropdown.length > 0 ? inputs.hsn_code_id : ''}
                              options={hsnCodeDropdown}
                              required
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="DC No"
                              fullWidth
                              value={inputs.dc_no}
                              name="dc_no"
                              onChange={changeHandler}
                              inputProps={{style: {'maxLength':120}}}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={12} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              fullWidth
                              multiline
                              rows={6}
                              type="text"
                              label="Instruction"
                              name="instruction"
                              value={inputs.instruction}
                              inputProps={{maxLength: '6000'}}
                              onChange={changeHandler}
                            />
                          </MDBox>
                        </Grid>
                    </Grid>
                    <MDBox mx={3}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={4} sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '70%', fontSize: '0.875rem'}}>Particulars</TableCell>
                              <TableCell align="center" sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem'}}>Quantity</TableCell>
                              <TableCell align="center" sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem'}}>Rate</TableCell>
                              <TableCell align="center" sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem'}}>Amount</TableCell>
                            </TableRow>
                            {printingItems?.map((eachPrinting, index) => (
                              <TableRow key={index}>
                                <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '5%'}}>{index + 1}</TableCell>
                                <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '15%'}}></TableCell>
                                <TableCell align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '25%'}}>
                                  <MDInput
                                    type="text"
                                    value={eachPrinting?.description}
                                    onChange={(e) => onChangePrintingField(index, 'description', e.target.value)}
                                    sx={{width: '19rem'}}
                                    inputProps={{maxLength: 250}}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '20%', fontSize: '0.75rem'}}>{eachPrinting?.color_scheme + ' ' + eachPrinting?.no_sets + ' sets' + ' ' + eachPrinting?.quantity + ' sheets' + ' ' + eachPrinting?.wo_printing_type}</TableCell>
                                <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%'}}></TableCell>
                                <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%'}}></TableCell>
                                <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%'}}>
                                  <MDInput
                                    type="text"
                                    value={eachPrinting?.amount}
                                    onChange={(e) => {onlyNumbers(e.target.value, 'decimal(16,4)') ? onChangePrintingField(index, 'amount', e.target.value) : ''}}
                                    sx={{width: '6rem'}}
                                    inputProps={{style:{textAlign: 'right'}}}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                            {stageType.map((stage) => {
                              if (materialItems[stage]?.length > 0) {
                                return (
                                  <>
                                    <TableRow sx={{backgroundColor: '#effbfb'}}>
                                      <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '5%'}}>
                                        <MDCheckbox
                                          checked={getStageTypeInputValue(stage, inputs)}
                                          onChange={(e) => onChangeMaterialPrint(stage, e.target.checked)}
                                        />
                                      </TableCell>
                                      <TableCell align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', fontWeight: 'bold',width: '15%', fontSize: '0.875rem'}}>{stage}</TableCell>
                                      <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '25%'}}></TableCell>
                                      <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '20%'}}></TableCell>
                                      <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%'}}></TableCell>
                                      <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%'}}></TableCell>
                                      <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%'}}>
                                        <MDInput
                                          type="text"
                                          value={numberFormat((materialTotal[stage] ? materialTotal[stage] : 0) , false)}
                                          sx={{width: '6rem'}}
                                          disabled={true}
                                          inputProps={{style:{textAlign: 'right'}}}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    {materialItems[stage].map((item, index) => (
                                      <TableRow key={index} sx={{ backgroundColor: '#F8ECE0'}}>
                                        <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '5%' }}>
                                          <MDCheckbox
                                            checked={item?.is_print}
                                            onChange={(e) => onChangeMaterialField(stage, index, 'is_print', e.target.checked)}
                                            disabled={getStageTypeInputValue(stage, inputs)}
                                          />
                                        </TableCell>
                                        <TableCell align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '15%' }}></TableCell>
                                        <TableCell align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '25%' }}>
                                          <MDInput
                                            type="text"
                                            value={item.item_description}
                                            onChange={(e) => onChangeMaterialField(stage, index, 'item_description', e.target.value)}
                                            sx={{width: '19rem'}}
                                            inputProps={{maxLength: 80}}
                                          />
                                        </TableCell>
                                        <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '20%' }}></TableCell>
                                        <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '10%' }}>
                                          <MDInput
                                            type="text"
                                            value={item.quantity}
                                            onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeMaterialField(stage, index, 'quantity', e.target.value) : ''}}
                                            sx={{width: '6rem'}}
                                          />
                                        </TableCell>
                                        <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '10%' }}>
                                          <MDInput
                                            type="text"
                                            value={item.rate}
                                            onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeMaterialField(stage, index, 'rate', e.target.value) : ''}}
                                            sx={{width: '6rem'}}
                                          />
                                        </TableCell>
                                        <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '10%' }}>
                                          <MDInput
                                            type="text"
                                            value={numberFormat(item.amount, false)}
                                            sx={{ width: '6rem' }}
                                            disabled={true}
                                            inputProps={{style:{textAlign: 'right'}}}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </>
                                );
                              }
                            })}
                            {stockItems?.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '5%' }}>{index + 1}</TableCell>
                                <TableCell align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '15%' }}></TableCell>
                                <TableCell align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '25%' }}>
                                  <MDInput
                                    type="text"
                                    value={item.item_description}
                                    onChange={(e) => onChangeStockField(index, 'item_description', e.target.value)}
                                    sx={{width: '19rem'}}
                                    inputProps={{maxLength: 80}}
                                  />
                                </TableCell>
                                <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '20%' }}></TableCell>
                                <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '10%' }}>
                                  <MDInput
                                    type="text"
                                    value={item.quantity * 1}
                                    // onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeMaterialField(stage, index, 'quantity', e.target.value) : ''}}
                                    sx={{width: '6rem'}}
                                    disabled={true}
                                  />
                                </TableCell>
                                <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '10%' }}>
                                  <MDInput
                                    type="text"
                                    value={item.rate}
                                    onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeStockField(index, 'rate', e.target.value) : ''}}
                                    sx={{width: '6rem'}}
                                  />
                                </TableCell>
                                <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px',  width: '10%' }}>
                                  <MDInput
                                    type="text"
                                    value={numberFormat(item.total, false)}
                                    sx={{ width: '6rem' }}
                                    disabled={true}
                                    inputProps={{style:{textAlign: 'right'}}}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '5%'}}></TableCell>
                              <TableCell align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '15%', fontSize: '0.75rem'}}>Discount Description</TableCell>
                              <TableCell colSpan={2} align="left" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '25%'}}>
                                <MDInput
                                  fullWidth
                                  type="text"
                                  name="discount_desc"
                                  value={inputs?.discount_desc}
                                  onChange={changeHandler}
                                  inputProps={{maxLength: 5000}}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%'}}></TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%', fontSize: '0.75rem'}}>Discount</TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%', paddingTop: '1px', paddingBottom: '1px', borderBottom: '2px solid'}}>
                                <MDInput
                                  type="text"
                                  name="discount"
                                  value={inputs.discount}
                                  onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? changeHandler(e) : ''}}
                                  sx={{width: '6rem'}}
                                  inputProps={{style:{textAlign: 'right'}}}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={6} align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%', fontSize: '0.75rem'}}>Total</TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%', fontSize: '0.75rem'}}>{numberFormat(Math.round(total))}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5} align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%', fontSize: '0.75rem'}}>SGST %</TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%'}}>
                                <MDInput
                                  type="text"
                                  name="state_gst"
                                  value={inputs.state_gst}
                                  onChange={changeHandler}
                                  sx={{width: '6rem'}}
                                  inputProps={{style:{textAlign: 'right'}}}
                                />
                              </TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%', fontSize: '0.75rem'}}>{inputs.state_gst ? numberFormat(getGstPrice(inputs.state_gst, total)) : 0.00}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5} align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '2px solid', width: '10%', fontSize: '0.75rem'}}>CGST %</TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '2px solid', width: '10%'}}>
                                <MDInput
                                  type="text"
                                  name="central_gst"
                                  value={inputs.central_gst}
                                  onChange={changeHandler}
                                  sx={{width: '6rem'}}
                                  inputProps={{style:{textAlign: 'right'}}}
                                />
                              </TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '2px solid', width: '10%', fontSize: '0.75rem'}}>{inputs.central_gst ? numberFormat(getGstPrice(inputs.central_gst, total)) : 0.00}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={6} align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: (paymentItems?.length > 0 ? '0px' : '2px solid'), width: '10%', fontWeight: 'bold', fontSize: '0.75rem'}}>Total</TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: (paymentItems?.length > 0 ? '0px' : '2px solid'), width: '10%', fontSize: '0.75rem'}}>{numberFormat(getTotal(total, inputs.state_gst, inputs.central_gst))}</TableCell>
                            </TableRow>
                            { paymentItems?.map((eachPayment, index) => (
                              <TableRow>
                                <TableCell colSpan={6} align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: (paymentItems?.length -1 != index ? '0px' : '2px solid'), width: '10%', fontSize: '0.75rem'}}>{eachPayment?.payment_type.name}</TableCell>
                                <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: (paymentItems?.length -1 != index ? '0px' : '2px solid'), width: '10%', fontSize: '0.75rem'}}>-{numberFormat(eachPayment?.amount)}</TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={6} align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%', fontWeight: 'bold', fontSize: '0.875rem'}}>Outstanding Amount</TableCell>
                              <TableCell align="right" sx={{paddingTop: '1px', paddingBottom: '1px', borderBottom: '0px', width: '10%', fontSize: '0.875rem'}}>{numberFormat(getOutstanding(getTotal(total, inputs.state_gst, inputs.central_gst), paidTotal))}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </MDBox>
                    <Grid container mb={2}>
                      <Grid item md={10} xs={1}></Grid>
                      <Grid item md={1} xs={3}>
                        <MDBox mt={4} mb={1} mr={2}>
                          <MDButton component={Link} onClick={() => goBack()} variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={3}>
                          <MDBox mt={4} mb={1} mr={3}>
                            <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                              {loading ? <MDCircularProgress
                                  size={24}
                                  sx={{
                                    position: 'absolute',
                                  }}
                                />: (action === 'add' ? 'Save' : 'Update')}
                            </MDButton>
                          </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
      <MDDialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        successAction={() => sendEmail(id, (inputs.customer_id ? inputs.customer.email : ''))}
        dialogTitle={'Mail Preview'}
        cancleButton={'Cancel'}
        successButton={'Send'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <MDBox fullWidth>
          <MDBox sx={{ width: '100%', border: '1px solid lightgray', borderRadius: 5}}>
            <MDBox sx={{ paddingLeft: 2, paddingTop: 3 }}>
              <b>From: </b> {companyName}{process.env.REACT_APP_IS_DEMO === "true" ? ' Staging' : ''} &lt;email.of.aditya.prints@gmail.com&gt;
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <b>To: </b>&lt;{(process.env.REACT_APP_IS_DEMO === "true" && !domainRegex.test(invoice?.customer?.email)) ? 'adityaprints@outlook.com' : invoice?.customer?.email}&gt;
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <b>Cc: </b>&lt;adityaprints@outlook.com&gt;
              <hr sx={{ border: '1px solid lightgray', width: '100%' }} />
            </MDBox>
            <MDBox sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 2 }}>
              <b>Subject: </b>Invoice #{invoice?.invoice_no}
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <hr sx={{ border: '1px solid lightgray', width: '100%' }} />
            </MDBox>
            <MDBox sx={{ paddingLeft: 3, paddingTop: 2, paddingBottom: 2 }}>
              <h4>Hi {invoice?.customer_name},</h4>
              <br />
              <p>Please find attached Invoice.</p>
              <br />
              <h5>Thanks & Regards,</h5>
              <h5>{companyName}</h5>
              <br />
              <MDButton
                  onClick={(e) => handlePrint(true)}
                  variant="gradient" size="large" color="secondary" title="Click to print/download the attachment" iconOnly>
                    <Icon fontSize="medium">picture_as_pdf_icon </Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDDialog>
    </>
  );
}

export default Form;
