import HttpService from "./htttp.service";

class CustomerInventoryService {

  getCustomerInventories = async(page, pageSize, sortColumn, sortColumnValue, searchValue, customer_id='') => {
    let fetchUrl = 'customer_inventories';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'customer_inventories?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    } else if (customer_id) {
      fetchUrl = 'customer_inventories?customer_id=' + customer_id;
    }

    return await HttpService.get(fetchUrl);
  }

  addCustomerInventory = async(newCustomer) => {
    const fetchUrl = 'customer_inventories';
    return await HttpService.post(fetchUrl, newCustomer);
  }

  updateCustomerInventory = async (id, data) => {
    const fetchUrl = 'customer_inventories/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updateCustomerInventoryStatus = async (id, data) => {
    const fetchUrl = 'customer_inventories/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showCustomerInventory = async(id) => {
    const fetchUrl = 'customer_inventories/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteCustomerInventory = async (id) => {
    const fetchUrl = 'customer_inventories/' + id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new CustomerInventoryService();
