/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useState } from "react";

//Work Order Context
export const PurchaseOrderContext = createContext({
    item: [],
    poTotal: 0,
    setPoItem: () => {},
    setPoAmount: () => {},
  });
  
  const PurchaseOrderContextProvider = ({ children }) => {
    const [item, setItem] = useState([]);
    const [poTotal, setPoTotal] = useState(0);
  
    const setPoItem = async(items) => {
        setItem(items);
    };

    const setPoAmount = (amount) => {
      setPoTotal(amount);
    };
  
    return (
      <PurchaseOrderContext.Provider value={{ item, poTotal, setPoItem, setPoAmount }}>
        {children}
      </PurchaseOrderContext.Provider>
    );
  };

  export {
    PurchaseOrderContextProvider
  }