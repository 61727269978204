import HttpService from "./htttp.service";

class InvoicePrintingItemService {

  getInvoicePrintingItems = async(id) => {
    let fetchUrl = 'inv_printing_items';
    if (id) {
      fetchUrl = 'inv_printing_items?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addInvoicePrintingItem = async(data) => {
    const fetchUrl = 'inv_printing_items';
    return await HttpService.post(fetchUrl, data);
  }

  deleteAllInvoicePrintingItem = async (invoice_id) => {
    const fetchUrl = 'inv_printing_items?invoice_id=' + invoice_id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new InvoicePrintingItemService();
