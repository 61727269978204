import dayjs from 'dayjs';

export const PDFTemplate = (customerInventoryInward, numberFormat) => {
    return  `<html>
  <head>
    <title>daily_customer_paper_inward_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Daily Customer Paper - Inward</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">User</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Customer</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Supplier</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Item</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Size</th>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">GSM</th>
                <th style="background-color: #dddddd; width: 5%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Quantity</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-bottom: 1px solid #000;">Date</th>
            </tr>
            ${customerInventoryInward?.map((eachItem, index) => {
                let rowColor = index % 2 == 0 ? '#e0f8f7' : '#effbfb';
                    return `
                        <tr style="background-color: ${rowColor}">
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1 != customerInventoryInward.length) ? '1px solid #000' : ''};">${eachItem?.user_name}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1 != customerInventoryInward.length) ? '1px solid #000' : ''};">${eachItem?.customer_name}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1 != customerInventoryInward.length) ? '1px solid #000' : ''};">${eachItem?.supplier_name}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1 != customerInventoryInward.length) ? '1px solid #000' : ''};">${eachItem?.item}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1 != customerInventoryInward.length) ? '1px solid #000' : ''};">${eachItem?.size}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1 != customerInventoryInward.length) ? '1px solid #000' : ''};">${eachItem?.gsm}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: ${(index + 1 != customerInventoryInward.length) ? '1px solid #000' : ''};">${eachItem?.quantity * 1}</td>
                            <td style="font-size: 0.775rem; border-bottom: ${(index + 1 != customerInventoryInward.length) ? '1px solid #000' : ''}; text-align: right;">${dayjs(eachItem?.date).format('DD-MM-YYYY hh:mm A')}</td>
                        </tr>
                    `
            }).join('')}       
        </table>
      </center>
    </div>
  </body>
</html>`

}
