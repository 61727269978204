/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDButton
import MDDialogRoot from "components/MDDialog/MDDialogRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDDialog = forwardRef(
  ({ open,
     onClose,
     successAction,
     dialogTitle,
     dialogContent,
     cancleButton,
     successButton,
     cancleButtonColor,
     successButtonColor,
     icon,
     showCancelButton,
     showSuccessButton,
     children,
     ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    return (
      <MDDialogRoot
        {...rest}
        ref={ref}
        open={open}
        onClose={onClose}
      >
        {icon && icon}
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          {showCancelButton && <MDButton color={cancleButtonColor ? cancleButtonColor : "light"} onClick={onClose}>{cancleButton ? cancleButton : "No"}</MDButton>}
          {showSuccessButton && <MDButton color={successButtonColor ? successButtonColor : "info"}   onClick={() => successAction()} autoFocus>
            {successButton ? successButton : "Yes"}
          </MDButton>}
        </DialogActions>
      </MDDialogRoot>
    );
  }
);

// Setting default values for the props of MDDialog
MDDialog.defaultProps = {
  cancleButton: 'No',
  successButton: 'Yes',
  showCancelButton: true,
  showSuccessButton: true,
};

// Typechecking props for the MDDialog
MDDialog.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
  ]),
};

export default MDDialog;
