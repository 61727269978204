import dayjs from 'dayjs';

export const PDFTemplate = (dailyPaymentReceived, totalData, paymentTypeDropdown, paymentTypeArray, numberFormat) => {
    return  `<html>
  <head>
    <title>daily_payment_received_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Daily Payment Received</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Date</th>
                <th style="background-color: #dddddd; width: 18%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Party</th>
                ${paymentTypeDropdown?.map((eachType) => {
                  if (paymentTypeArray.includes(eachType?.key)) {
                    return `
                      <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${eachType?.value}</th>
                    `
                  }
                }).join('')}
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-bottom: 1px solid #000;">Total</th>
            </tr>
            ${dailyPaymentReceived?.map((item, index) => {
                let rowColor = index % 2 === 0 ? '#e0f8f7' : '#effbfb';
                return `
                    <tr style="background-color: ${rowColor}">
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.date}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: left !important;">${item?.customer_name}</td>
                        ${paymentTypeDropdown?.map((eachType) => {
                          if (paymentTypeArray.includes(eachType?.key)) {
                            return `
                              <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;">${item[eachType?.key] ? numberFormat(item[eachType?.key]) : '0.00'}</td>
                            `
                          }
                        }).join('')}
                        <td style="font-size: 0.775rem; border-bottom: 1px solid #000;text-align: right !important;">${numberFormat(item?.total)}</td>
                    </tr>
                `
            }).join('')} 
            <tr>
                <th colspan='2' style="font-size: 0.775rem; border-right: 1px solid #000;">Total</th>
                ${paymentTypeDropdown?.map((eachType) => {
                  if (paymentTypeArray.includes(eachType?.key)) {
                    return `
                      <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${totalData[eachType?.key] ? numberFormat(totalData[eachType?.key]) : '0.00'}</th>
                    `
                  }
                }).join('')}
                <th style="font-size: 0.775rem;text-align: right !important;">${numberFormat(totalData?.total)}</th>
            </tr>       
        </table>
      </center>
    </div>
  </body>
</html>`

}
