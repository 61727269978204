import dayjs from 'dayjs';

export const PDFTemplate = (itemWiseSupplierWiseGrn, numberFormat) => {
    return  `<html>
  <head>
    <title>item_wise_supplier_wise_grn_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Item Wise Supplier Wise GRN</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            ${itemWiseSupplierWiseGrn?.map((eachItem, index) => {
                const suppliersGrn = eachItem?.suppliers;
                return `
                    <tr style="background-color: #f8ece0;">
                        <td colspan="7" style="border-bottom: 1px solid #000;"><b>Item : </b>${eachItem?.item}</td>
                    </tr>
                    ${Object.entries(suppliersGrn).map(([key, value]) => {
                        if (key === 'supplier') {
                            return `
                                <tr style="background-color: #f8ece0;">
                                    <td colspan="7" style="border-bottom: 1px solid #000;"><b>Supplier : </b>${value}</td>
                                </tr>
                            `
                        } else if (key === 'items') {
                            return `
                                <tr>
                                    <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">PO No</th>
                                    <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">GRN No</th>
                                    <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Date</th>
                                    <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Bill No</th>
                                    <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Quantity</th>
                                    <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Size</th>
                                    <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-bottom: 1px solid #000;">GSM</th>
                                </tr>
                                ${value.map((item) => {
                                    let rowColor = '#effbfb';
                                    rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                                    return `
                                        <tr style="background-color: ${rowColor}">
                                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.purchase_order_id}</td>
                                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.grn_id}</td>
                                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.date}</td>
                                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.bill_no}</td>
                                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.quantity}</td>
                                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.size ? item?.size : ''}</td>
                                            <td style="font-size: 0.775rem; border-bottom: 1px solid #000; text-align: right;">${item?.gsm}</td>
                                        </tr>
                                    `
                                }). join('')}
                            `
                        } else if (key === 'total_quantity') {
                            return `
                                <tr>
                                    <td colspan="4" style="font-size: 0.875rem; border-right: 1px solid #000; border-bottom: ${itemWiseSupplierWiseGrn.length != index + 1 ? '1px solid #000' : ''};text-align: right;"><b>Total</b></td>
                                    <td style="font-size: 0.875rem; border-right: 1px solid #000; border-bottom: ${itemWiseSupplierWiseGrn.length != index + 1 ? '1px solid #000' : ''};text-align: right;"><b>${value}</b></td>
                                    <td colspan="2" style="font-size: 0.875rem; border-bottom: ${itemWiseSupplierWiseGrn.length != index + 1 ? '1px solid #000' : ''};"></td>
                                </tr>
                            `
                        }   
                    }). join('')}
                `
            }).join('')}       
        </table>
      </center>
    </div>
  </body>
</html>`

}
