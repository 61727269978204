/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import * as React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import MDDatePicker from "components/MDDatePicker";
import customAlert from "components/MDAlert/customAlert";
import MDTabPanel from "components/MDTabPanel";
import MDTooltip from "components/MDTooltip";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import WorkOrderService from "services/work-order-service";

// @mui icons
import Icon from "@mui/material/Icon";
import CustomersService from "services/customer-service";
import HsnCodeService from "services/hsn-code-service";
import SizeService from "services/size-service";
import UserService from "services/user-service";
import MDRadio from "components/MDRadio";
import Material from "./material-item";
import Printing from "./printing-item";
import Stock from "./stock-item";
import Other from "./other-item";
import Payment from "./payment";
import useMediaQuery from '@mui/material/useMediaQuery';
import { WorkOrderContext, useCache } from "context";
import WoMaterialItemService from "services/wo-material-item-service";
import WoPrintingItemService from "services/wo-printing-item-service";
import WoStockItemService from 'services/wo-stock-item-service';
import WoOtherItemService from "services/wo-other-item-service";
import WoPaymentService from "services/wo-payment-service";
import authPermission from "utility/auth-permissions";
import { jobCardPDFTemplate } from "./job-card-pdf-template";
import ColorServices from "services/color-services";
import ItemService from "services/item-service";
import MDDialog from "components/MDDialog";
import MDTimePicker from "components/MDTimePicker";
import { numberFormat, onlyNumbers } from "utility/helpers";
import { invoicePDFTemplate } from "layouts/manage-invoices/invoice-pdf-template";
import InvoiceMaterialItemService from "services/invoice-material-item-service";
import InvoicePrintingItemService from "services/invoice-printing-item-service";
import InvoiceService from "services/invoice-service";
import InvoiceStockItemService from "services/invoice-stock-item-service";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const stageType = ['DESIGN','PAPER','PRINTING','BINDING','EXTRA'];

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const tab = localStorage.getItem("woTabValue") ? Number(localStorage.getItem("woTabValue")) : (searchParams.get('tab') ? Number(searchParams.get('tab')) : 0);
  const ref = searchParams.get('ref');
  const closeWindow = searchParams.get('close_window') ? 1 : 0;
  const returnTab = searchParams.get('returnTab');
  const navigate = useNavigate();
  const location = useLocation();

  const workOrderContext = useContext(WorkOrderContext);
  const { cacheItems, setCacheItems } = useCache();
  const { hasPermission, systemConstant } = authPermission();
  const invoicePermission = hasPermission('/manage-invoices');
  const cancelStatus = systemConstant('CANCELLED_STATUS');
  const approvedStatus = systemConstant('APPROVED_STATUS');
  const billedStatus = systemConstant('BILLED_STATUS');
  const draftStatus = systemConstant('DRAFT_STATUS');
  const defaultHsn = systemConstant('DEFAULT_HSN');
  const newStatus = systemConstant('NEW_STATUS');
  const noColorPrintingMachineId = systemConstant('NO_COLOR_PRINTING_MACHINE_ID');
  const blackColorId = systemConstant('BLACK_COLOR_ID');
  const doubleTheQuantity = [Number(systemConstant('DG_PRINTING_TYPE')), Number(systemConstant('SB_PRINTING_TYPE'))];
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [workOrder, setWorkOrder] = useState({});
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [jobIconLoading, setJobIconLoading] = useState(false);
  const [releaseDate, setReleaseDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [deliveryDate, setDeliveryDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [completedDate, setCompletedDate] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(dayjs(new Date()));
  const [customerDropdown, setCustomerDropdown] = useState([]);
  const [hsnCodeDropdown, setHsnCodeDropdown] = useState([]);
  const [hsnTax, setHsnTax] = useState([]);
  const [sizeDropdown, setSizeDropdown] = useState([]);
  const [salesUserDropdown, setSalesUserDropdown] = useState([]);
  const [customerGstin, setCustomerGstin] = useState([]);
  const [customerContactPerson, setCustomerContactPerson] = useState([]);
  const [customerMobile, setCustomerMobile] = useState([]);
  const [value, setValue] = useState(0);
  const minWidth = useMediaQuery('(min-width:1725px)');
  const minWidthD = useMediaQuery('(min-width:1150px)');
  const minHeaderWidth = useMediaQuery('(min-width:1040px)');
  const minHeaderWidthWo = useMediaQuery('(min-width:800px)');
  const [open, setOpen] = useState(false);
  const [isShowInstruction, setIsShowInstruction] = useState(false);
  //for printing tab
  const [colorScheme, setColorScheme] = useState('');
  const [colorData, setColorData] = useState([]);
  const [matFromStockDayWiseTotal, setMatFromStockDayWiseTotal] = useState(0);
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const pageReload = (tab) => {
    localStorage.setItem("woTabValue", tab);
    navigate(0);
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const goBack = async() => {
    if (!ref && !returnTab) {
      if (closeWindow) {
        window.open("about:blank", "_self");
        window.close();
      } else {
        window.location.href = '/manage-work-orders';
      }
    }
  }

  const woEditBtnStyle = {
    padding: 0,
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    borderTopRightRadius: "0.375rem",
    borderBottomRightRadius: "0.375rem",
    borderColor: "#d2d6da",
    backgroundColor: "#F5F5F5",
    ':hover': {
      backgroundColor: '#F5F5F5',
      borderColor: "#d2d6da",
      opacity : 1,
    }
  };

  const [inputs, setInputs] = useState({
    customer_id: "",
    hsn_code_id: "",
    customer_gstin: "",
    job_type: "",
    size_id: "",
    instruction: '',
    cut_size_id: "",
    amount: "",
    total: "",
    is_with_bill: 0,
    payment_type: "Cash",
    status_id: draftStatus,
    vat: systemConstant('VAT'),
    state_gst: 0,
    central_gst: 0,
    sales_user_id: '',
    sequence: 0,
  });

  const [errors, setErrors] = useState({
    jobTypeError: false,
    instructionError: false,
  });

  useEffect(() => {
    // Function for dropdown
    const getCustomerData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('customers')) {
        response = cacheItems['customers'];
      } else {
        response = await CustomersService.getCustomers(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            customers: response,
        }));
      }

      if (response) {
        var customerArray = [];
        var gstin = [];
        var contactName = [];
        var mobile = [];
        response?.map((eachCustomer, index) => {
          customerArray[index] = {
            key: eachCustomer.id,
            value: eachCustomer.name,
            disabled: eachCustomer?.active ? false : true,
          }
          gstin[eachCustomer.id] = eachCustomer.gstin;
          contactName[eachCustomer.id] = eachCustomer.contact_person;
          mobile[eachCustomer.id] = eachCustomer.mobile;
        });
        setCustomerDropdown(customerArray);
        setCustomerGstin(gstin);
        setCustomerContactPerson(contactName);
        setCustomerMobile(mobile);
      }
    }
    getCustomerData();

    const getHsnCodeData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('hsn_codes')) {
        response = cacheItems['hsn_codes'];
      } else {
        response = await HsnCodeService.getHsnCodes(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            hsn_codes: response,
        }));
      }

      if (response) {
        var hsnArray = [];
        var hsnGst = [];
        response?.map((eachCode, index) => {
          hsnArray[index] = {
            key: eachCode.id,
            value: eachCode.code,
            disabled: eachCode?.active ? false : true,
          }
          hsnGst[eachCode.id] = {
            gst: eachCode.tax_pct
          }
        });
        setHsnTax(hsnGst);
        setHsnCodeDropdown(hsnArray);
      }
    }
    getHsnCodeData();

    const getSizeData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('sizes')) {
        response = cacheItems['sizes'];
      } else {
        response = await SizeService.getSizes(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            sizes: response,
        }));
      }

      if (response) {
        var sizeArray = [];
        response?.map((eachSize, index) => {
          sizeArray[index] = {
            key: eachSize.id,
            value: eachSize.name,
            disabled: eachSize?.active ? false : true,
          }
        });
        setSizeDropdown(sizeArray);
      }
    }
    getSizeData();

    const getSalesUserData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('salesperson')) {
        response = cacheItems['salesperson'];
      } else {
        response = await UserService.getUsers('', '', '', '', '', 'Salesperson'); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            salesperson: response,
        }));
      }

      if (response) {
        var userArray = [];
        response?.map((eachUser, index) => {
          userArray[index] = {
            key: eachUser.id,
            value: eachUser.first_name + (eachUser.middle_name ? " " + eachUser.middle_name : '') + " " + eachUser.last_name,
            disabled: eachUser?.active ? false : true,
          }
        });
        setSalesUserDropdown(userArray);
      }
    }
    getSalesUserData();

    var matFromStockTotal = 0;
    //Get Items
    const getWoItems = async() => {
      let matItems = [];
      let pntItems = [];
      let matStockItems = [];
      let othItems = [];
      let payItems = [];

      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('colors')) {
        response = cacheItems['colors'];
      } else {
        response = await ColorServices.getColors(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            colors: response,
        }));
      }

      let colorList = colorScheme;
      if (response) {
        response.map((color) => {
            if (color.active) {
                colorList = colorList + (colorList ? ',' : '') + color.id;
                setColorScheme(colorList);
            }
        })
        setColorData(response);
      }

      if (id) {
        pntItems = await WoPrintingItemService.getWoPrintingItems(id);
        pntItems?.map((eachItem, index) => {
          pntItems[index]['is_approved'] = action === 'add' ? 0 : pntItems[index]['is_approved'];
          pntItems[index]['markup_pct'] = Number(pntItems[index]['markup_pct']) * 1;
          pntItems[index]['free_amount'] = Number(pntItems[index]['free_amount']).toFixed(2);
          pntItems[index]['is_show_color'] = (noColorPrintingMachineId != pntItems[index]['printing_machine_id']) ? true : false;
          if (action === 'add') {
            pntItems[index]['operator_id'] = null;
            pntItems[index]['completed_date'] = null;
            pntItems[index]['started_at'] = null;
            pntItems[index]['ended_at'] = null;
          }
        });
        const matFromStock = await WoStockItemService.getWoStockItems(id);
        let i = 0;
        matFromStock?.map( async (eachItem, index) => {
          if (eachItem?.is_wo_day_wise === 1) {
            matFromStockTotal = matFromStockTotal + Number(eachItem?.total);
          }
          matFromStock[index]['is_approved'] = action === 'add' ? 0 : matFromStock[index]['is_approved'];
          matFromStock[index]['markup_pct'] = Number(matFromStock[index]['markup_pct']) * 1;
          matFromStock[index]['quantity'] = Number(matFromStock[index]['quantity']) * 1;
          matFromStock[index]['total'] = Number(matFromStock[index]['total']).toFixed(2);
          if (action === 'add') {
            const item = await ItemService.showItem(eachItem?.item_id);
            if (item?.stock >= matFromStock[index]['quantity']) {
              matFromStock[index]['opening_stock'] = item?.stock;
              matStockItems[i] = matFromStock[index];
              i = i + 1;
            }
          } else {
            matStockItems[i] = matFromStock[index];
            i = i + 1;
          }
        });
        setMatFromStockDayWiseTotal(matFromStockTotal);
        othItems = await WoOtherItemService.getWoOtherItems(id);
        othItems?.map((eachItem, index) => {
          othItems[index]['gsm'] = Number(othItems[index]['gsm']).toFixed(2);
          othItems[index]['quantity'] = Number(othItems[index]['quantity']) * 1;
          othItems[index]['old_quantity'] = Number(othItems[index]['quantity']) * 1;
        });
        payItems = await WoPaymentService.getWoPayments(id);
        payItems?.map((eachItem, index) => {
          payItems[index]['is_approved'] = action === 'add' ? 0 : payItems[index]['is_approved'];
          payItems[index]['amount'] = Number(payItems[index]['amount']).toFixed(2);
        });
      }
      //Get Material Item
      matItems = await WoMaterialItemService.getWoMaterialItems(id ? id : 'l');
      const totalArray = [];
      stageType.forEach((stage) => {
        let total = 0;
        matItems[stage].forEach((eachItem,index) => {
          matItems[stage][index]['is_delete'] = false;
          matItems[stage][index]['is_approved'] = action === 'add' ? 0 : matItems[stage][index]['is_approved'];
          matItems[stage][index]['size_name'] = eachItem.size?.name;
          matItems[stage][index]['markup_pct'] = Number(matItems[stage][index]['markup_pct']) * 1;
          matItems[stage][index]['quantity'] = Number(matItems[stage][index]['quantity']) * 1;
          matItems[stage][index]['markup_amount'] = Number(matItems[stage][index]['markup_amount']).toFixed(2);
          total = Number(total) + Number(matItems[stage][index]['markup_amount']);
        });
        totalArray[stage] = total.toFixed(2);
      });
      workOrderContext.setMaterialTotal(totalArray);
      workOrderContext.setWoMaterialItem(matItems);
      //Get Printing Item
      let i = pntItems.length % 5;
      for (i; i<5; i++) {
        pntItems[pntItems.length] = {
            work_order_id: id,
            id: 0,
            description: '',
            wo_printing_type_id: '',
            quantity: '',
            waste_qty: '',
            printing_machine_id: '',
            color_scheme: colorList,
            no_sets: '',
            printing_rate: '',
            amount: '',
            markup_pct: '',
            free_amount: '',
            total: '',
            is_lamination: 0,
            is_approved: 0,
            is_show_color: true,
        };
      }
      workOrderContext.setWoPrintingItem(pntItems);
      //Get Material Stock Item
      if (id && action === 'add') {
        matStockItems = [];
        othItems = [];
        payItems = [];
      }
      let j = matStockItems.length % 5;
      for (j; j<5; j++) {
        matStockItems[matStockItems.length] = {
            work_order_id: id,
            id: 0,
            item_id: '',
            opening_stock: '',
            quantity: '',
            rate: '',
            markup_pct: '',
            total: '',
            is_wo_day_wise: 0,
            is_approved: 0
        };
      }
      workOrderContext.setWoStockItem(matStockItems);
      //Get Other Item
      let k = othItems.length % 5;
      for (k; k<5; k++) {
        othItems[othItems.length] = {
            work_order_id: id,
            id: 0,
            item_id: '',
            size_id: '',
            gsm: '',
            quantity: '',
            old_quantity: 0,
        };
      }
      workOrderContext.setWoOtherItem(othItems);
      //Get Payment
      let l = payItems.length % 5;
      for (l; l<5; l++) {
        payItems[payItems.length] = {
            work_order_id: id,
            id: 0,
            payment_type_id: '',
            payment_date: dayjs(new Date()).format('YYYY-MM-DD'),
            amount: '',
            description: '',
            is_cash: 0,
            cheque_no: '',
            is_approved: 0
        };
      }
      workOrderContext.setWoPayment(payItems);
    }
    getWoItems();

    if (action === "add" && id == null) return;

    const showWorkOrder = async() => {
      try {
        const data = await WorkOrderService.showWorkOrder(id);
        setHeaderTitle(data.customer_id ? (data.id + " (" + data.customer.name + ")") : '');
        setDeliveryDate((action == 'add' && id) ? (dayjs(new Date()).format('YYYY-MM-DD')) : (data.delivery_date ? data.delivery_date : ''));
        setDeliveryTime((action == 'add' && id) ? (dayjs(new Date())) : (data.delivery_time ? data.delivery_date + ' ' + data.delivery_time : ''));
        setReleaseDate((action == 'add' && id) ? (dayjs(new Date()).format('YYYY-MM-DD')) : (data.release_date ? data.release_date : ''));
        setCompletedDate((action == 'add' && id) ? '' : (data.completed_date ? data.completed_date : ''));
        setIsShowInstruction(data.is_show_instruction ? true : false);
        workOrderContext.setWoAmount(data.amount);
        if(action === 'add') {
          data.status_id = draftStatus;
          data.status = {};
        }
        setInputs(data);
        setWorkOrder(data);
        if (tab && data.status_id) {
          handleTabChange('', tab);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'};
          navigate('/manage-work-orders', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'};
          navigate('/manage-work-orders', {state: state});
        }
      }
    }
    showWorkOrder();
    localStorage.removeItem("woTabValue");
  }, []);

  useEffect(() => {
    if (action === "add" && id) {
      workOrderContext.setWoAmount(workOrderContext.woTotal - matFromStockDayWiseTotal);
    }
  }, [matFromStockDayWiseTotal]);

  const changeHandler = (e) => {
    setLoading(false);
    if (e.target.name == 'hsn_code_id') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value > 0 ? e.target.value : defaultHsn,
        ['state_gst']: Number(hsnTax[e.target.value > 0 ? e.target.value : defaultHsn]['gst'])/2,
        ['central_gst']: Number(hsnTax[e.target.value > 0 ? e.target.value : defaultHsn]['gst'])/2,
      });
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const calculateTotal = (amount) => {
    const gst = action === 'add' ? (hsnTax.length > 0 ? (hsnTax[inputs.hsn_code_id ? inputs.hsn_code_id : defaultHsn] ? hsnTax[inputs.hsn_code_id ? inputs.hsn_code_id : defaultHsn]['gst'] : 18) : 18) : (Number(inputs.state_gst) + Number(inputs.central_gst));
    const total = Number(amount) + Number((amount * gst)/100);
    return total.toFixed(2);
  }

  const submitHandler = async (e, buttonClicked = 'save') => {
    if (buttonClicked == 'approve') {
      setApproveLoading(true);
    } else {
      setLoading(true);
    }
    e.preventDefault();

    const materialItem = workOrderContext.materialItem;
    const printingItem = workOrderContext.printingItem;
    const stockItem = workOrderContext.stockItem;
    const otherItem = workOrderContext.otherItem;
    const payment = workOrderContext.payment;
    const amount = workOrderContext.woTotal;
    const otherItemData = workOrderContext.otherData;
    const itemStockData = workOrderContext.itemStockData;
    const total = calculateTotal(amount);

    const hasValidPrintingItem = printingItem.some((item) => {
      return item.quantity > 0 && item.wo_printing_type_id > 0 && item.printing_machine_id > 0;
    });

    const newWorkOrder = {
      customer_id: inputs.customer_id,
      hsn_code_id: inputs.hsn_code_id ? inputs.hsn_code_id : defaultHsn,
      customer_gstin: customerGstin[inputs.customer_id],
      release_date: releaseDate,
      delivery_date: deliveryDate,
      delivery_time: dayjs(deliveryTime).format('H:m:s'),
      job_type: inputs.job_type,
      size_id: inputs.size_id,
      instruction: inputs.instruction ? inputs.instruction : null,
      is_show_instruction: isShowInstruction ? 1 : 0,
      completed_date: completedDate ? completedDate : null,
      cut_size_id: Number(inputs.cut_size_id) ? Number(inputs.cut_size_id) : null,
      amount: amount ? amount : 0.00,
      total: total ? total : 0.00,
      is_with_bill: inputs.is_with_bill,
      payment_type: inputs.payment_type,
      status_id: (inputs.status_id == draftStatus && hasValidPrintingItem) ? newStatus :  inputs.status_id,
      vat: inputs.vat ? inputs.vat : 0.00,
      state_gst: action === 'add' ? (hsnTax[inputs.hsn_code_id ? inputs.hsn_code_id : defaultHsn]['gst']/2) : inputs.state_gst,
      central_gst: action === 'add' ? (hsnTax[inputs.hsn_code_id ? inputs.hsn_code_id : defaultHsn]['gst']/2) : inputs.central_gst,
      sales_user_id: inputs.sales_user_id ? inputs.sales_user_id : null,
      sequence: (action == 'add' && id) ? 999 : (inputs.sequence ? inputs.sequence : 0),
      is_use_customer_balance: workOrderContext.isUseCustomerBalance,
    };

    if (!newWorkOrder.customer_id) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Party is mandatory", title: 'Work Order'});
      setLoading(false);
      setApproveLoading(false);
    } else if (!newWorkOrder.job_type) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Job type is mandatory", title: 'Work Order'});
      setLoading(false);
      setApproveLoading(false);
    } else if (!newWorkOrder.size_id) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Size is mandatory", title: 'Work Order'});
      setLoading(false);
      setApproveLoading(false);
    } else {
      if (action === 'add') {
        try {
          const response = await WorkOrderService.addWorkOrder(newWorkOrder);
          setLoading(false);
          if (response) {
            if (Object.keys(materialItem).length > 0) {
              const matArray = {
                'material': materialItem,
                'work_order_id': response.id,
              }
              const materialResponse = await WoMaterialItemService.addWoMaterialItem(matArray);
            }

            if (printingItem.length > 0) {
              const printArray = {
                'printing': printingItem ? printingItem : '',
                'work_order_id': response.id,
              }
              const printingResponse = await WoPrintingItemService.addWoPrintingItem(printArray);
            }

            if (stockItem.length > 0) {
              const stockArray = {
                'stock': stockItem ? stockItem : '',
                'work_order_id': response.id,
              }
              const stockResponse = await WoStockItemService.addWoStockItem(stockArray);
            }

            if (otherItem.length > 0) {
              const otherArray = {
                'other': otherItem ? otherItem : '',
                'work_order_id': response.id,
              }
              const otherResponse = await WoOtherItemService.addWoOtherItem(otherArray);
            }

            if (payment.length > 0) {
              const paymentArray = {
                'payment': payment ? payment : '',
                'work_order_id': response.id,
              }
              const paymentResponse = await WoPaymentService.addWoPayment(paymentArray);
            }

            if (otherItemData.length > 0) {
              const itemArray = {
                'item': otherItemData ? otherItemData : '',
                'work_order_id': response.id,
              }
              const updateItemResponse = await ItemService.updateItemQuantity(itemArray);
            }
            const totalUpdateResponse = await WorkOrderService.updateWorkOrderTotal({id: response.id});
            if (newWorkOrder.is_use_customer_balance) {
              const usingCustomerBalance = await WorkOrderService.useCustomerBalance({id: response.id});
            }
            let msg = 'Work Order Added Successfully!';
            if (buttonClicked == 'approve') {
              const promises = [
                WoMaterialItemService.approveWoMaterialItem(response.id, true, newStatus),
                WoPrintingItemService.approveWoPrintingItem(response.id),
                WoStockItemService.approveWoStockItem(response.id),
                WoPaymentService.approveWoPayment(response.id)
              ];

              const results = await Promise.all(promises);
              msg = 'Work Order Approved Successfully!';
              setApproveLoading(false);
            }
            const state = {color: 'success', icon: 'check', msg: msg, title: 'Work Order'};
            navigate('/manage-work-orders', { state: state })
          }
        } catch (res) {
          const totalUpdateResponse = await WorkOrderService.updateWorkOrderTotal({id: ''});
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
          setApproveLoading(false);
        }
      } else {
        try {
          const response = await WorkOrderService.updateWorkOrder(id ,newWorkOrder);
          setLoading(false);
          if (Object.keys(materialItem).length > 0) {
            const matArray = {
              'material': materialItem,
              'work_order_id': response.id,
            }
            const deleteWoMaterialItem = await WoMaterialItemService.deleteAllWoMaterialItem(response.id);
            const materialResponse = await WoMaterialItemService.addWoMaterialItem(matArray);
          }

          if (printingItem.length > 0) {
            const printArray = {
              'printing': printingItem ? printingItem : '',
              'work_order_id': response.id,
            }
            const deleteWoPrintingItem = await WoPrintingItemService.deleteAllWoPrintingItem(response.id);
            const printingResponse = await WoPrintingItemService.addWoPrintingItem(printArray);
          }

          if (stockItem.length > 0) {
            const stockArray = {
              'stock': stockItem ? stockItem : '',
              'work_order_id': response.id,
            }
            const deleteWoStockItem = await WoStockItemService.deleteAllWoStockItem(response.id);
            const stockResponse = await WoStockItemService.addWoStockItem(stockArray);
          }

          if (otherItem.length > 0) {
            const otherArray = {
              'other': otherItem ? otherItem : '',
              'work_order_id': response.id,
            }
            const deleteWoOtherItem = await WoOtherItemService.deleteAllWoOtherItem(response.id);
            const otherResponse = await WoOtherItemService.addWoOtherItem(otherArray);
          }

          if (payment.length > 0) {
            const paymentArray = {
              'payment': payment ? payment : '',
              'work_order_id': response.id,
            }
            const deleteWoPaymentItem = await WoPaymentService.deleteAllWoPayment(response.id);
            const paymentResponse = await WoPaymentService.addWoPayment(paymentArray);
          }

          if (otherItemData.length > 0) {
            const itemArray = {
              'item': otherItemData ? otherItemData : ''
            }
            if (cacheItems.hasOwnProperty('items')) {
              // Create a new object without the key
              const { items, ...rest } = cacheItems; // Destructure to exclude key
              setCacheItems(rest); // Set the new cache items without key
            }
            const updateItemResponse = await ItemService.updateItemQuantity(itemArray);
          }
          if (itemStockData.length > 0) {
            // const itemArray = {
            //   'stockItem': itemStockData ? itemStockData : ''
            // }
            if (cacheItems.hasOwnProperty('items')) {
              // Create a new object without the key
              const { items, ...rest } = cacheItems; // Destructure to exclude key
              setCacheItems(rest); // Set the new cache items without key
            }
            // const updateItemResponse = await ItemService.updateItemQuantity(itemArray);
          }
          const totalUpdateResponse = await WorkOrderService.updateWorkOrderTotal({id: response.id});

          if (newWorkOrder.is_use_customer_balance) {
            const usingCustomerBalance = await WorkOrderService.useCustomerBalance({id: response.id});
          }

          let msg = 'Work Order Updated Successfully!';
          if (buttonClicked == 'approve') {
            const promises = [
              WoMaterialItemService.approveWoMaterialItem(id, true, newStatus),
              WoPrintingItemService.approveWoPrintingItem(id),
              WoStockItemService.approveWoStockItem(id),
              WoPaymentService.approveWoPayment(id)
            ];

            const results = await Promise.all(promises);
            msg = 'Work Order Approved Successfully!';
            setApproveLoading(false);
          }

          if (response) {
            const state = {color: 'success', icon: 'check', msg: msg, title: 'Work Order'};
            if (ref && returnTab) {
              navigate(`${ref}` + `?returnTab=${returnTab}`, { state: state });
            } else if (ref) {
              navigate(`${ref}`, { state: state });
            } else {
              openAlertSB(state);
              setTimeout(function() {
                if (buttonClicked == 'approve') {
                  navigate('/manage-work-orders', { state: state })
                } else{
                  // pageReload(value);
                  navigate('/manage-work-orders', { state: state });
                }
              }, 500);
            }
          }
        } catch (res) {
          const totalUpdateResponse = await WorkOrderService.updateWorkOrderTotal({id: id});
          setLoading(false);
          setApproveLoading(false);
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      }
    }

    return () => {
      setInputs({
        customer_id: "",
        hsn_code_id: "",
        customer_gstin: "",
        job_type: "",
        size_id: "",
        instruction: '',
        cut_size_id: "",
        amount: "",
        total: "",
        is_with_bill: 1,
        payment_type: "Cash",
        status_id: "",
        vat: 0.00,
        state_gst: 0.00,
        central_gst: 0.00,
        sales_user_id: '',
        sequence: 0,
      });

      setErrors({
        jobTypeError: false,
        instructionError: false,
      });
    };
  };

  const getPDFData = async() => {
    const color = colorData;
    const colorArray = [];
    if (color) {
      color?.map((eachColor, index) => {
        colorArray[eachColor.id] = eachColor.name.charAt(0);
      });
    }
    const material = await WoMaterialItemService.getWoMaterialItems(id);
    let hasGsm = 0;
    let hasSize = 0;
    Object.keys(material).forEach(stageType => {
      material[stageType].forEach(row => {
        if (row.gsm !== "0.0000" && row.gsm !== null) {
          hasGsm = 1;
        }
        if (row.size_id !== null && row.size !== null) {
          hasSize = 1;
        }

      });
    });

    const printing = await WoPrintingItemService.getWoPrintingItems(id);
    printing?.map((eachPrinting, index) => {
      let color = eachPrinting.color_scheme.split(',').map(Number);
      printing[index]['color_length'] = color.length + (color.length > 1 ? ' Colors' : ' Color');
      let color_scheme = '';
      color?.map((eachColor, index) => {
        color_scheme += colorArray[eachColor] != 'undefined' ? ((index != 0 ? ' + ' : '') + colorArray[eachColor]) : '';
      });
      printing[index]['color_scheme'] = color_scheme;
    });
    const stock = await WoStockItemService.getWoStockItems(id);
    const other = await WoOtherItemService.getWoOtherItems(id);

    // Check if 'gsm' is present in any 'poItems'
    const hasGsmOther = other.some(item => item.gsm > 0);

    // Check if 'size' is present in any 'poItems'
    const hasSizeOther = other.some(item => item.size);

    return {
      material: material,
      printing: printing,
      stock: stock,
      other: other,
      hasGsm: hasGsm,
      hasSize: hasSize,
      hasGsmOther: hasGsmOther,
      hasSizeOther: hasSizeOther,
    };
  }

  const handlePrint = async() => {
    setJobIconLoading(true);
    const response = await getPDFData();
    const companyName = systemConstant('COMPANY_NAME');
    if(response){
        const pdfTemplate = jobCardPDFTemplate(companyName, workOrder, response.material, response.printing, response.stock, response.other, noColorPrintingMachineId, response.hasGsm, response.hasSize, response.hasGsmOther, response.hasSizeOther);

        const originalBody = document.body;
        const printWindow = window.open('', '_blank');
        setJobIconLoading(false);
        printWindow.document.write(`${pdfTemplate}`);
        printWindow.document.close();
        await new Promise(r => setTimeout(r, 2000));
        printWindow.print();
        printWindow.close();
        document.body = originalBody;
    }
  };

  const cancelWorkOrder = async (id) => {
    try {
      const stockItems = await WoStockItemService.getWoStockItems(id);
      if (stockItems.length > 0) {
        for (const eachItem of stockItems) {
          const item = await ItemService.showItem(eachItem.item_id);
          item.stock = Number(item.stock) + Number(eachItem.quantity);
          await ItemService.updateItemQuantity(item);
        }
      }
      const cancelWo = await WorkOrderService.updateWorkOrderStatus(id, {status_id: cancelStatus});
      const state = {color: 'success', icon: 'check', msg: 'Work Order Cancelled Successfully!', title: 'Work Order'};
      navigate('/manage-work-orders', { state: state });
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  }
  //Invoice print logic
  const getGstPrice = (gstPct, amount) => {
    return (amount * gstPct)/100;
  }

  const getTotal = (amount, sGst = 0, cGst =0) => {
    const gst = Number(sGst) + Number(cGst);
    const total = Number(amount) + Number((amount * gst)/100);
    return Math.floor(total);
  }

  const getOutstanding = (amount, deductingAmount = 0) => {
    return amount - deductingAmount;
  }

  const getStageTypeInputValue = (stage, data) => {
    switch (stage) {
      case 'DESIGN':
        return data.is_design;
      case 'PAPER':
        return data.is_paper;
      case 'PRINTING':
        return data.is_printing;
      case 'BINDING':
        return data.is_binding;
      case 'EXTRA':
        return data.is_extra;
      default:
      return false;
    }
  }

  const getInvoicePDFData = async(id) => {
    const promises = [
      InvoiceService.showInvoice(id),
      InvoiceMaterialItemService.getInvoiceMaterialItems(id),
      InvoicePrintingItemService.getInvoicePrintingItems(id),
      InvoiceStockItemService.getInvoiceStockItems(id),
    ];
    const response = await Promise.all(promises);
    const payment = await WoPaymentService.getWoPayments(response[0]?.work_order_id);
    let totalAll = 0;
    let totalRows = 2;
    const totalArray = [];
    const rowPresentArray = [];
    stageType.map((stage) => {
      let total = 0;
      rowPresentArray[stage] = false;
      response[1][stage]?.map((item, ind) => {
        if (getStageTypeInputValue(stage, response[0])) {
          total = Number(total) + Number(item.amount);
          rowPresentArray[stage] = true;
          totalRows = totalRows + 1;
        } else {
          if (item?.is_print) {
            total = Number(total) + Number(item.amount);
            rowPresentArray[stage] = true;
            totalRows = totalRows + 1;
          }
        }
      });
      totalArray[stage] = Number(total);
      totalAll = Number(totalAll) + Number(total);
    });

    response[2]?.map((eachRes, index) => {
      totalAll = Number(totalAll) + Number(eachRes.amount);
      totalRows = totalRows + 1;
    });

    response[3]?.map((eachRes, index) => {
      totalAll = Number(totalAll) + Number(eachRes.total);
      totalRows = totalRows + 1;
    });

    let receivedAmount = 0;
    payment?.map((eachRes) => {
      receivedAmount = Number(receivedAmount) + Number(eachRes.amount);
    });

    return {
      invoice: response[0],
      material: totalArray,
      materialItems: response[1],
      printing: response[2],
      stock: response[3],
      rowPresentArray: rowPresentArray,
      totalAmount: response[0]?.discount ? (Number(totalAll) - Number(response[0].discount)) : totalAll,
      receivedAmount: receivedAmount,
      lastRowHeight: response[0]?.instruction ? 400 : ((19 - totalRows) > 0 ? (19 - totalRows)* 25 : 0),
    };
  }

  const handleInvoicePrint = async(id) => {
    setInvoiceLoading(true);
    const response = await getInvoicePDFData(id);
    const companyName = systemConstant('COMPANY_NAME');
    const companyAddress = systemConstant('COMPANY_ADDRESS');
    if(response){
        const pdfTemplate = invoicePDFTemplate(companyName, companyAddress, response.invoice, response.rowPresentArray, response.material, response.materialItems, response.printing, response.stock, response.totalAmount, response.receivedAmount, response.lastRowHeight, getGstPrice, getOutstanding, getTotal);

        const originalBody = document.body;
        const printWindow = window.open('', '_blank');
        setInvoiceLoading(false);
        printWindow.document.write(`${pdfTemplate}`);
        printWindow.document.close();
        await new Promise(r => setTimeout(r, 2000));
        printWindow.print();
        printWindow.close();
        document.body = originalBody;
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add Work Order' : ('Work Order : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.75rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={12} md={minWidth ? 5 : 9}>
                    <MDTypography variant="h6" color="white" sx={{
                      height: "45%",
                      display:"flex",
                      alignItems: "center",
                      '&.MuiTypography-h6': {
                        fontSize: "0.850rem"
                      },
                    }}>
                      {action === 'add' ? 'Add Work Order' : ('Work Order : ' + headerTitle)}
                    </MDTypography>
                    {hasPermission('wo_rates').view ? (
                      <>
                        <Divider sx={{ opacity: 1, margin:"0px", bgcolor: "#fff", width: (inputs.is_with_bill && minHeaderWidth) ? "93%" : ((!inputs.is_with_bill && minHeaderWidthWo) ? "50%" : "95%") }} />
                        <MDBox mb={minHeaderWidth ? 0 : 1} sx={{display: "flex", flexDirection: (minHeaderWidth ? "row" : "column" )}}>
                          <MDTypography variant="h6" color="white" sx={{
                            height: "55%",
                            color: "#ffeb07",
                            '&.MuiTypography-h6': {
                              fontSize: "1rem"
                            },
                          }}>
                            Marked up Total = Rs.{numberFormat(workOrderContext.woTotal)}
                          </MDTypography>
                          <MDTypography variant="h6" color="white" sx={{
                            height: "50%",
                            color: "#ffeb07",
                            '&.MuiTypography-h6': {
                              fontSize: "1rem"
                            },
                          }}>
                            {(inputs.is_with_bill == 1 && minHeaderWidth) && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                            {inputs.is_with_bill == 1 ? (
                              `Amount with ${action == 'add' ? (hsnTax.length > 0 ? (hsnTax[inputs.hsn_code_id ? inputs.hsn_code_id : defaultHsn] ? numberFormat(hsnTax[inputs.hsn_code_id ? inputs.hsn_code_id : defaultHsn]['gst'], false) : '18') : '18') : Number(inputs.state_gst) + Number(inputs.central_gst)} % GST = Rs.${numberFormat(calculateTotal(workOrderContext.woTotal))}`
                            ) : ''}
                          </MDTypography>
                        </MDBox>
                      </>
                    ) : ''}
                    </Grid>
                      <Grid item xs={12} md={minWidth ? 7 : 3} display={minWidthD ? 'flex' : ''} justifyContent="flex-end" alignItems="center">
                      {action === 'add' && hasPermission('work_order_approve').view && (
                        <MDButton
                          onClick={(e) => submitHandler(e, 'approve')}
                          sx={{marginLeft: 2}}
                          variant="gradient" color="success" title="Approve" disabled={approveLoading} startIcon={<Icon sx={{ fontWeight: "bold" }}>done_outline_icon </Icon>}>
                            {approveLoading ? <MDCircularProgress
                                  size={24}
                                  sx={{
                                    position: 'absolute',
                                  }}
                                />: 'Approve'}
                        </MDButton>
                      )}
                      {action === 'edit' && (
                        <MDBox display="flex" textAlign="center" mt={minWidthD ? '' : 1}>
                          {invoicePermission.view && workOrder?.invoice_id ? (
                            <MDButton
                              sx={{
                                marginLeft: 2,
                                color: '#000',
                                backgroundColor: '#0FF', // Change the background color
                                '&:hover': {
                                  color: '#000',
                                  backgroundColor: '#0FF', // Change the hover color
                                }}}
                              onClick={() => handleInvoicePrint(workOrder?.invoice_id)}
                              variant="gradient" title={`Print Invoice #${workOrder?.invoice_id}`} iconOnly>
                                {invoiceLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>receipt_icon</Icon>}
                            </MDButton>
                          ) : ''}
                          <MDButton
                              sx={{marginLeft: 2}}
                              onClick={handlePrint}
                              variant="gradient" color="secondary" title="Print Job Card" iconOnly>
                                {jobIconLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>print_icon</Icon>}
                          </MDButton>
                          {inputs.status_id != systemConstant('CANCELLED_STATUS') && (
                            <>
                              <MDButton
                              sx={{marginLeft: 2}}
                              onClick={() => handleClickOpen()}
                              target="_blank"
                              variant="gradient" color="error" title="Cancel this work order" iconOnly>
                                <Icon sx={{ fontWeight: "bold" }}>cancel_icon </Icon>
                              </MDButton>
                              {hasPermission('work_order_approve').view && (
                              <MDButton
                                onClick={(e) => submitHandler(e, 'approve')}
                                sx={{marginLeft: 2}}
                                variant="gradient" color="success" title="Approve" disabled={approveLoading} startIcon={approveLoading ? '' : <Icon sx={{ fontWeight: "bold" }}>done_outline_icon </Icon>}>
                                  {approveLoading ? <MDCircularProgress
                                  size={24}
                                  sx={{
                                    position: 'absolute',
                                  }}
                                />: 'Approve'}
                              </MDButton>
                              )}
                            </>
                          )}
                        </MDBox>
                      )}
                      </Grid>
                  </Grid>
                </MDBox>
                <MDBox>
                  <MDBox px={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs sx={{padding: 0, margin: 0.75}} value={value} onChange={handleTabChange} textColor="primary" aria-label="basic tabs example">
                      <Tab label="Work Order" {...a11yProps(0)} />
                      {hasPermission('material_tab').view && (<Tab label="Material" {...a11yProps(1)} />)}
                      {hasPermission('printing_tab').view && (<Tab label="Printing" {...a11yProps(2)} />)}
                      {hasPermission('material_from_stock_tab').view && (<Tab label="Material From Stock" {...a11yProps(3)} />)}
                      {hasPermission('other_items_tab').view && (<Tab label="Other Items" {...a11yProps(4)} />)}
                      {hasPermission('payment_tab').view && (<Tab label="Payment" {...a11yProps(5)} />)}
                    </Tabs>
                  </MDBox>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <MDTabPanel value={value} index={0}>
                      <Grid container>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="Party"
                              labelProps={{fontWeight: 'bold'}}
                              fullWidth
                              native
                              name="customer_id"
                              value={inputs.customer_id}
                              onChange={changeHandler}
                              options={customerDropdown}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                              required
                              sx={{
                                "& .MuiNativeSelect-select": {
                                  fontWeight: 'bold',
                                }
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Job Type"
                              fullWidth
                              value={inputs.job_type}
                              name="job_type"
                              onChange={changeHandler}
                              inputProps={{style: {'maxLength':100, fontWeight: 'bold', color: inputs.status_id == cancelStatus && action === 'edit' ? '#9E9E9E' : ''}}}
                              error={errors.stockError}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                              required
                              sx={{
                                '& .MuiInputLabel-root': {
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={3} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="Size"
                              labelProps={{fontWeight: 'bold'}}
                              fullWidth
                              native
                              name="size_id"
                              value={inputs.size_id}
                              onChange={changeHandler}
                              options={sizeDropdown}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                              required
                              sx={{
                                "& .MuiNativeSelect-select": {
                                  fontWeight: 'bold',
                                },
                                "&.Mui-disabled .MuiNativeSelect-select": {
                                  fontWeight: 'bold',
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={3} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="Cut Size"
                              fullWidth
                              native
                              name="cut_size_id"
                              value={inputs.cut_size_id}
                              onChange={changeHandler}
                              options={sizeDropdown}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={3} xs={12} px={3}>
                          <MDBox mb={2}>
                            <MDDatePicker
                              label="Delivery Date"
                              name="delivery_date"
                              value={dayjs(deliveryDate).isValid() ? dayjs(deliveryDate) : dayjs(new Date())}
                              format="DD-MM-YYYY"
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                              onChange={(newValue) => {
                                setDeliveryDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                              }}
                              slotProps={{ textField: {
                                  fullWidth: true,
                                  required: true
                                },
                                field: {
                                  clearable: true
                                },
                                textField: { size: 'small' }
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={3} xs={12} px={3}>
                          <MDBox mb={2}>
                            <MDTimePicker
                              label="Delivery Time"
                              name="delivery_time"
                              value={dayjs(deliveryTime).isValid() ? dayjs(deliveryTime) : null}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                              onChange={(newValue) => {
                                setDeliveryTime(dayjs(newValue).isValid() ? dayjs(newValue) : null);
                              }}
                              slotProps={{ textField: {
                                  fullWidth: true,
                                  required: true
                                },
                                field: {
                                  clearable: true
                                },
                                textField: { size: 'small' }
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={3} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="HSN/SAC"
                              fullWidth
                              name="hsn_code_id"
                              onChange={changeHandler}
                              value={hsnCodeDropdown.length > 0 ? (inputs.hsn_code_id ? inputs.hsn_code_id : defaultHsn) : ''}
                              options={hsnCodeDropdown}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                              required
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={3} xs={12} px={3}>
                          <MDBox mb={2}>
                            <MDDatePicker
                              label="Date"
                              name="release_date"
                              value={dayjs(releaseDate).isValid() ? dayjs(releaseDate) : dayjs(new Date())}
                              format="DD-MM-YYYY"
                              disabled={true}
                              onChange={(newValue) => {
                                setReleaseDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                              }}
                              slotProps={{ textField: {
                                  fullWidth: true,
                                  required: true
                                },
                                field: {
                                  clearable: true
                                },
                                textField: { size: 'small' }
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={3} px={3}>
                          <MDTooltip title={inputs.customer_id ? customerContactPerson[inputs.customer_id] : ''}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Contact Person Name"
                              fullWidth
                              value={inputs.customer_id ? customerContactPerson[inputs.customer_id] : ''}
                              disabled={true}
                            />
                          </MDBox>
                          </MDTooltip>
                        </Grid>
                        <Grid item xs={12} md={3} px={3}>
                          <MDTooltip title={inputs.customer_id ? customerGstin[inputs.customer_id] : ''}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Customer GSTIN"
                              fullWidth
                              value={inputs.customer_id ? customerGstin[inputs.customer_id] : ''}
                              disabled={true}
                            />
                          </MDBox>
                          </MDTooltip>
                        </Grid>
                        <Grid item xs={12} md={3} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Customer Mobile"
                              fullWidth
                              value={inputs.customer_id ? customerMobile[inputs.customer_id] : ''}
                              disabled={true}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={3} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="Sales Person"
                              fullWidth
                              name="sales_user_id"
                              value={inputs.sales_user_id}
                              onChange={changeHandler}
                              options={salesUserDropdown}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={3} xs={12} px={3}>
                          <MDBox mb={2}>
                            <MDDatePicker
                              label="Completion Date"
                              name="completed_date"
                              value={dayjs(completedDate).isValid() ? dayjs(completedDate) : null}
                              format="DD-MM-YYYY"
                              disabled={hasPermission('completion_date').edit && inputs.status_id != cancelStatus ? false : true}
                              onChange={(newValue) => {
                                setCompletedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                              }}
                              slotProps={{ textField: {
                                  fullWidth: true,
                                  required: false,
                                },
                                field: {
                                  clearable: true
                                },
                                textField: { size: 'small' }
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={3} px={3}>
                          <MDBox mb={1}>
                            <MDInput
                              type="text"
                              label="Status"
                              fullWidth
                              value={inputs?.status?.name ? inputs?.status?.name : 'Draft'}
                              inputProps={{style: {fontWeight: 'bold'}}}
                              disabled={true}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={3} xs={12} px={3}>
                          <MDBox mb={1}>
                            <MDRadio
                              label="Payment Type"
                              name="payment_type"
                              value={inputs.payment_type}
                              onChange={changeHandler}
                              options={[{"label":"Cash", "value":"Cash"}, {"label":"Credit", "value":"Credit"}]}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={3} xs={12} px={3}>
                          <MDBox mb={1}>
                            <MDRadio
                              label="Bill"
                              name="is_with_bill"
                              value={inputs.is_with_bill}
                              onChange={changeHandler}
                              options={[{"label":"Work Order", "value":0}, {"label":"With Bill", "value":1}]}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={6} xs={12} px={3}>
                          <MDBox mb={1}>
                            <MDSwitch checked={isShowInstruction ? true : false} onChange={() => setIsShowInstruction(prev => !prev)} label="Show instructions on job card" inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={12} px={3}>
                          <MDBox mb={0}>
                            <MDInput
                              fullWidth
                              multiline
                              rows={6}
                              type="text"
                              label="Instruction"
                              name="instruction"
                              value={inputs.instruction}
                              inputProps={{maxLength: '6000'}}
                              onChange={changeHandler}
                              disabled={inputs.status_id == cancelStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDTabPanel>
                    <MDTabPanel value={value} index={1}><Material numberFormat={numberFormat} isCancel={(inputs.status_id == cancelStatus) ? true : false} onlyNumbers={onlyNumbers} isApproved={(inputs.status_id == approvedStatus || inputs.status_id == billedStatus) ? true : false}></Material></MDTabPanel>
                    <MDTabPanel value={value} index={2}><Printing customerId={inputs.customer_id} paymentType={inputs.payment_type} numberFormat={numberFormat} isCancel={inputs.status_id == cancelStatus ? true : false} onlyNumbers={onlyNumbers} colorData={colorData} colorScheme={colorScheme} doubleTheQuantity={doubleTheQuantity} noColorPrintingMachineId={noColorPrintingMachineId} blackColorId={blackColorId}></Printing></MDTabPanel>
                    <MDTabPanel value={value} index={3}><Stock numberFormat={numberFormat} isCancel={inputs.status_id == cancelStatus ? true : false} openAlertSB={openAlertSB} onlyNumbers={onlyNumbers}></Stock></MDTabPanel>
                    <MDTabPanel value={value} index={4}><Other numberFormat={numberFormat} isCancel={inputs.status_id == cancelStatus ? true : false} openAlertSB={openAlertSB} onlyNumbers={onlyNumbers}></Other></MDTabPanel>
                    <MDTabPanel value={value} index={5}><Payment calculateTotal={calculateTotal} numberFormat={numberFormat} isCancel={inputs.status_id == cancelStatus ? true : false} onlyNumbers={onlyNumbers} isWithBill={inputs.is_with_bill} customerId={inputs.customer_id}></Payment></MDTabPanel>
                    <Grid container mb={2}>
                      <Grid item md={inputs.status_id != cancelStatus ? 10 : 11} xs={1}></Grid>
                      <Grid item md={1} xs={3}>
                        <MDBox mr={2}>
                          <MDButton component={Link}
                            to={(ref && returnTab) ? (ref + `?returnTab=${returnTab}`) : (ref ? ref : "")}
                            onClick={() => goBack()}
                            variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      {inputs.status_id != cancelStatus ? (
                        <Grid item md={1} xs={3}>
                          <MDBox mr={3}>
                            <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                              {loading ? <MDCircularProgress
                                  size={24}
                                  sx={{
                                    position: 'absolute',
                                  }}
                                />: (action === 'add' ? 'Save' : 'Save')}
                            </MDButton>
                          </MDBox>
                        </Grid>
                      ) : ''}
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
      <MDDialog
          open={open}
          onClose={handleClose}
          successAction={() => cancelWorkOrder(id)}
          dialogTitle={'Are you Sure?'}
          cancleButton={'No, Please!'}
          successButton={'Yes, Cancel it!'}
          cancleButtonColor={'light'}
          successButtonColor={'info'}
          icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
        >
        <p>You will not be able to make any changes in this work order after cancellation.</p>
        </MDDialog>
    </>
  );
}

export default Form;
