import HttpService from "./htttp.service";

class WoOtherItemService {

  getWoOtherItems = async(id) => {
    let fetchUrl = 'wo_other_items';
    if (id) {
      fetchUrl = 'wo_other_items?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addWoOtherItem = async(data) => {
    const fetchUrl = 'wo_other_items';
    return await HttpService.post(fetchUrl, data);
  }

  updateWoOtherItem = async (id, data) => {
    const fetchUrl = 'wo_other_items/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showWoOtherItem = async(id) => {
    const fetchUrl = 'wo_other_items/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteWoOtherItem = async (id) => {
    const fetchUrl = 'wo_other_items/' + id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllWoOtherItem = async (work_order_id) => {
    const fetchUrl = 'wo_other_items?work_order_id=' + work_order_id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new WoOtherItemService();
