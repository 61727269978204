import HttpService from "./htttp.service";

class WorkOrderService {

  getWorkOrders = async(page, pageSize, sortColumn, sortColumnValue, searchValue, statuses, searchWoId='', searchDate='', searchCustomer='', searchJobType='', searchStatus='') => {
    let fetchUrl = 'work_orders';
    if (searchWoId || searchDate || searchCustomer || searchJobType || searchStatus) { // filter Work Order List
      fetchUrl = 'work_orders?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue) + '&search_wo_id=' + searchWoId + '&search_date=' + searchDate + '&search_customer_id=' + searchCustomer + '&search_job_type=' + searchJobType + '&search_status_id=' + searchStatus;
    } else if (page && pageSize && sortColumn && sortColumnValue) { // get Work Order for list page
      fetchUrl = 'work_orders?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue) + '&statuses=' + statuses;
    }
    return await HttpService.get(fetchUrl);
  }

  getWorkOrdersForInvoice = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'work_orders_invoice';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'work_orders_invoice?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addWorkOrder = async(data) => {
    const fetchUrl = 'work_orders';
    return await HttpService.post(fetchUrl, data);
  }

  updateWorkOrder = async (id, data) => {
    const fetchUrl = 'work_orders/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showWorkOrder = async(id) => {
    const fetchUrl = 'work_orders/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteWorkOrder = async (id) => {
    const fetchUrl = 'work_orders/' + id;
    return await HttpService.delete(fetchUrl);
  }

  updateWorkOrderStatus = async (id, data) => {
    const fetchUrl = 'work_orders/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  updateWorkOrderTotal = async (data) => {
    const fetchUrl = 'wo_update_total';
    return await HttpService.put(fetchUrl, data);
  }

  useCustomerBalance = async (data) => {
    const fetchUrl = 'use_customer_balance';
    return await HttpService.put(fetchUrl, data);
  }
}

export default new WorkOrderService();
