/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportService from "services/report-service";
import SuppliersService from "services/supplier-service";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import { Link } from "react-router-dom";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const grnPermission = hasPermission('/manage-grn');
    const [suppliersDropdown, setSuppliersDropdown] = useState([]);
    const [supplierPaymentDue, setSupplierPaymentDue] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [supplierId, setSupplierId] = useState('');
    const [billType, setBillType] = useState('');
    const [grandTotal, setGrandTotal] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filter, setFilter] = useState('');
    const { cacheItems, setCacheItems } = useCache();
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getSupplierPaymentDue = async(supplierId = '', billType = '', startDate = '', endDate = '') => {
        const data = await ReportService.getSupplierPaymentDue(supplierId, billType, startDate, endDate);
        setSupplierPaymentDue(Object.values(data.supplier_payment_due));
        setGrandTotal(data.grand_total);
    }

    useEffect(() => {
        const getSuppliersData = async() => {
            let response; // Declare response outside the if...else block
            if (cacheItems.hasOwnProperty('suppliers')) {
              response = cacheItems['suppliers'];
            } else {
              response = await SuppliersService.getSuppliers(); // Fetch customers from the API
              setCacheItems(prevCache => ({
                  ...prevCache,
                  suppliers: response,
              }));
            }
            if (response) {
              var supplierArray = [];
              response?.map((eachSupplier, index) => {
                if(eachSupplier?.active) {
                    supplierArray[index] = {
                        key: eachSupplier?.id,
                        value: eachSupplier?.name,
                        disabled: eachSupplier?.active ? false : true,
                    }
                }
              });
              setSuppliersDropdown(supplierArray);
            }
        }
        getSuppliersData();
        getSupplierPaymentDue(supplierId, billType);
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
        const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
        getSupplierPaymentDue(supplierId, billType, sDate, eDate);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setSupplierId('');
        setBillType('');
        setStartDate('');
        setEndDate('');
        setFilter(null);
        getSupplierPaymentDue();
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data, grandTotal) => {
        setExcelLoading(true);
        const fileName = 'supplier_payment_due_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Supplier Payment Due']);
        //Merge cells for supplier row
        worksheet.mergeCells(`A1:G1`);
        // Add headers
        const headerRow = worksheet.addRow(['Supplier', 'GRN', 'Bill No.', 'Bill Date', 'Net Amount', 'Payment', 'Outstanding Amount', 'Days Due']);
        // Set the width for each column
        worksheet.columns = [
            { width: 25 },
            { width: 10 }, 
            { width: 10 },
            { width: 18 },
            { width: 18 }, 
            { width: 18 },
            { width: 20 },
            { width: 18 },
            { width: 10 },
            { width: 18 },
        ];
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 1; i < 11; i++) {
            if(i>1 && i<9) {
                headerRow.getCell(i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dddddd' }, // Red color
                };
            }
            //Add border
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        let k = 3;
        data.forEach((eachData, index) => {
            // const supplierRow = 'Supplier : ' + eachData?.supplier_name;
            // const cellRowSupplier = worksheet.addRow([supplierRow]);
            // cellRowSupplier.getCell(1).fill = {
            //     type: 'pattern',
            //     name: 'sans-serif',
            //     pattern: 'solid',
            //     fgColor: { argb: 'f8ece0' },
            // };
            // cellRowSupplier.border = {
            //     right: { style: 'thin' }
            // };
            // // Merge cells for supplier row
            // worksheet.mergeCells(`A${k}:G${k}`);
            // k += 1;
            let rowInd = 0;
            let rowColor = 'effbfb';
            // Add data
            eachData?.items?.forEach((row, index) => {
                const cellRow = worksheet.addRow([(rowInd==0 ? eachData?.supplier_name : ''), row?.grn_no, row?.bill_no, row?.bill_date, Number(row?.net_amount), Number(row?.payment), Number(row?.out_standing), row?.due_days, (rowInd + 1 == eachData.items.length ? 'Total' : ''), (rowInd + 1 == eachData.items.length ? Number(eachData?.total) : '')]);
                rowColor = rowColor == 'effbfb' ? 'e0f8f7' : 'effbfb';
                for (var i = 1; i < 11; i++) {
                    if(i>1 && i<9) {
                        cellRow.getCell(i).fill = {
                            type: 'pattern',
                            name: 'sans-serif',
                            pattern: 'solid',
                            fgColor: { argb: rowColor },
                        };
                    }
                    // Add border to the row
                    cellRow.getCell(i).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                }
                cellRow.getCell(5).numFmt = '#,##0.00';
                cellRow.getCell(6).numFmt = '#,##0.00';
                cellRow.getCell(7).numFmt = '#,##0.00';
                cellRow.getCell(10).numFmt = '#,##0.00';
                cellRow.getCell(9).font = { bold: true, name: 'sans-serif' };
                cellRow.getCell(10).font = { bold: true, name: 'sans-serif' };
                
                if (rowInd == 0) {
                    cellRow.getCell(1).fill = {
                        type: 'pattern',
                        name: 'sans-serif',
                        pattern: 'solid',
                        fgColor: { argb: 'f8ece0' },
                    };
                }
                k += 1;
                rowInd += 1;
            });
            // const cellRowTotal = worksheet.addRow(['Total:', '', '', '', '', '', Number(eachData?.total)]);
            // worksheet.mergeCells(`A${k}:F${k}`);
            // cellRowTotal.getCell(1).alignment = { horizontal: 'right' };
            // cellRowTotal.getCell(7).numFmt = '#,##0.00';
            // cellRowTotal.font = { bold: true, name: 'sans-serif' };
            // k += 1;
        });

        const cellRowGrandTotal = worksheet.addRow(['', '', '', '', '', '', '', '', 'Grand Total:', Number(grandTotal)]);
        // worksheet.mergeCells(`A${k}:F${k}`);
        // cellRowGrandTotal.getCell(1).alignment = { horizontal: 'right' };
        cellRowGrandTotal.getCell(10).numFmt = '#,##0.00';
        cellRowGrandTotal.font = { bold: true, name: 'sans-serif' };
      
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(supplierPaymentDue){
            const pdfTemplate = PDFTemplate(supplierPaymentDue, grandTotal, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`${pdfTemplate}`);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Supplier Payment Due'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Supplier Payment Due'}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                    <Grid item xs={12} lg={minWidthD ? 3 : 2.5} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Supplier"
                                                fullWidth
                                                name="supplier_id"
                                                value={supplierId}
                                                onChange={(e) => setSupplierId(e.target.value != null ? e.target.value : '')}
                                                options={suppliersDropdown}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={minWidthD ? 2 : 1.5} px={1} md={6}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Bill Type"
                                                fullWidth
                                                name="bill_type"
                                                value={billType}
                                                onChange={(e) => setBillType(e.target.value != null ? e.target.value : '')}
                                                options={[{key: 1, value: 'With Bill'}, {key: 2, value: 'Work Order'}]}
                                            />
                                        </MDBox>
                                     </Grid>
                                     <Grid item lg={5} xs={12} px={1} md={8}>
                                        <MDBox mb={1}>
                                            <MDDateRangePicker
                                                label="Start Date"
                                                name="start_date"
                                                value={startDate ? startDate : null}
                                                format="DD-MM-YYYY"
                                                onChange={(newValue) => {
                                                    setDate(newValue, 'start_date');
                                                }}
                                                endLabel="End Date"
                                                endName="end_date"
                                                endValue={endDate ? endDate : null}
                                                endOnChange={(newValue) => {
                                                    setDate(newValue, 'end_date');
                                                }}
                                                onFilterChange={onFilterChange}
                                                filter={filter}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {supplierPaymentDue.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(supplierPaymentDue, grandTotal)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '5%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>GRN</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Bill No.</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Bill Date</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Net Amount</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Payment</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '15%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Outstanding Amount</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Days Due</TableCell>
                                        </TableRow>
                                        {supplierPaymentDue?.map((eachItem) => {
                                            let rowColor = '#effbfb';
                                            return (
                                                <>
                                                    <TableRow sx={{ backgroundColor: '#f8ece0' }}>
                                                        <TableCell colSpan={7} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Supplier :</b> {eachItem?.supplier_name}</TableCell>
                                                    </TableRow>
                                                    {eachItem?.items?.map((item) => {
                                                        rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                                                        return (
                                                            <TableRow sx={{ backgroundColor: rowColor }}>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '5%', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: 'info.main'}} component={Link} to={grnPermission.edit ? `/manage-grn/edit?id=${item?.grn_no}&tab=2&close_window=1` : ``} target={'_blank'}>{item?.grn_no}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.bill_no}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.bill_date}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.net_amount)}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.payment)}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '15%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.out_standing)}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.due_days}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                    <TableRow>
                                                        <TableCell colSpan={6} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>Total:</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(eachItem?.total)}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}
                                        {supplierPaymentDue.length == 0 ? 
                                            <TableRow>
                                                <TableCell colSpan={7} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow>
                                        : 
                                            <TableRow>
                                                <TableCell colSpan={6} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>Grand Total:</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(grandTotal)}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
