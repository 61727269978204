/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import dayjs from 'dayjs';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ColorsService from "services/color-services";

// @mui icons
import Icon from "@mui/material/Icon";
import { useCache } from "context";

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const location = useLocation();

  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [active, setActive] = useState(1);
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const { cacheItems, setCacheItems } = useCache();

  const [inputs, setInputs] = useState({
    name: "",
    created_at: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
  });

  useEffect(() => {
    if (action === "add" && id == null) return;

    const showColor = async() => {
      try {
        const data = await ColorsService.showColor(id);
        setHeaderTitle(data.name);
        setInputs(data);
        setCreatedBy(data.user.first_name + (data.user.middle_name ? (" " + data.user.middle_name) : "") + " " + data.user.last_name);
        setActive(data.active);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Color'};
          navigate('/manage-colors', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Color'};
          navigate('/manage-colors', {state: state});
        }
      }
    }
    showColor();
  }, []);

  // const addColorHandler = (newColor) => setColor(newColor);

  const changeHandler = (e) => {
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (inputs.name.trim().length < 1) {
      setErrors({ ...errors, nameError: true });
      return;
    }

    const newColor = { name: inputs.name, active: active ? 1 : 0 };
    // addColorHandler(newColor);

    if (action === 'add') {
      try {
        const response = await ColorsService.addColor(newColor);
        setLoading(false);
        if (response) {
          if (cacheItems.hasOwnProperty('colors')) {
            // Create a new object without the key
            const { colors, ...rest } = cacheItems; // Destructure to exclude key
            setCacheItems(rest); // Set the new cache items without key
          }
          const state = {color: 'success', icon: 'check', msg: 'Color Added Successfully!', title: 'Color'};
          navigate('/manage-colors', { state: state });
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Color'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Color'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    } else {
      try {
        const response = await ColorsService.updateColor(id ,newColor);
        setLoading(false);
        if (response) {
          if (cacheItems.hasOwnProperty('colors')) {
            // Create a new object without the key
            const { colors, ...rest } = cacheItems; // Destructure to exclude key
            setCacheItems(rest); // Set the new cache items without key
          }
          openAlertSB({color: 'success', icon: 'check', msg: 'Color Updated Successfully!', title: 'Color'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Color'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Color'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    }

    return () => {
      setInputs({
        name: "",
      });

      setErrors({
        nameError: false,
      });
    };
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add Color' : ('Edit Color : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.75rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <MDTypography variant="h6" color="white">
                    {action === 'add' ? 'Add Color' : ('Edit Color : ' + headerTitle)}
                  </MDTypography>
                </MDBox>
                <MDBox py={3}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Name"
                            fullWidth
                            value={inputs.name}
                            name="name"
                            inputProps={{'maxLength':50}}
                            onChange={changeHandler}
                            error={errors.nameError}
                            required
                          />
                        </MDBox>
                      </Grid>

                      {action === 'edit' &&
                        <>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                name=""
                                value={createdBy}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={dayjs(inputs.created_at).format('DD-MM-YYYY HH:mm A')}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      }

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDSwitch checked={active ? true : false} onChange={() => setActive(prev => !prev)} label="Active" inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                        </MDBox>
                      </Grid>
                      <Grid item md={10} xs={8}></Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={2}>
                          <MDButton component={Link} to="/manage-colors" variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
