/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import WorkOrderService from "services/work-order-service";

// @mui icons
import Icon from "@mui/material/Icon";
import { WorkOrderContext } from "context";
import WoMaterialItemService from "services/wo-material-item-service";
import WoPrintingItemService from "services/wo-printing-item-service";
import WoStockItemService from 'services/wo-stock-item-service';
import WoOtherItemService from "services/wo-other-item-service";
import authPermission from "utility/auth-permissions";
import MDButton from "components/MDButton";

const stageType = ['DESIGN','PAPER','PRINTING','BINDING','EXTRA'];

function JobCard () {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const location = useLocation();

  const workOrderContext = useContext(WorkOrderContext);
  const { hasPermission, systemConstant } = authPermission();
  const [workOrder, setWorkOrder] = useState({});
  const [materialItem, setMaterialItem] = useState({material});
  const [printingItem, setPrintingItem] = useState([]);
  const [stockItem, setStockItem] = useState([]);
  const [otherItem, setOtherItem] = useState([]);

  const [printable, setPrintable] = useState(false);
  const printableRef = useRef(null);



  useEffect(() => {
    const showWorkOrder = async() => {
      try {
        const data = await WorkOrderService.showWorkOrder(id);
        setWorkOrder(data);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Work Order'};
          navigate('/manage-work-orders', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Work Order'};
          navigate('/manage-work-orders', {state: state});
        }
      }
    }
    showWorkOrder();

    const getWoMaterialItems = async() => {
      const material = await WoMaterialItemService.getWoMaterialItems(id);
      setMaterialItem(material);
    }
    getWoMaterialItems();

    const getWoPrintingItems = async() => {
      const printing = await WoPrintingItemService.getWoPrintingItems(id);
      setPrintingItem(printing);
    }
    getWoPrintingItems();

    const getWoStockItems = async() => {
      const stock = await WoStockItemService.getWoStockItems(id);
      setStockItem(stock);
    }
    getWoStockItems();
    const getWoOtherItems = async() => {
      const other = await WoOtherItemService.getWoOtherItems(id);
      setOtherItem(other);
    }
    getWoOtherItems();
    // handlePrint();
  }, []);

  const handlePrint = async() => {
    await new Promise(r => setTimeout(r, 2000));
    setPrintable(true);
    const printableContent = printableRef.current;
    const originalBody = document.body;
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
        <html>
        <head>
            <title>Aditya Prints - Business Management System</title>
        </head>
        <body>
            ${printableContent.outerHTML}
        </body>
        </html>
    `);
    printWindow.document.close();
    await new Promise(r => setTimeout(r, 2000));
    printWindow.print();
    printWindow.close();
    document.body = originalBody;
  };

  const handleBeforePrint = () => {
    setPrintable(true);
  };

  const handleAfterPrint = () => {
    setPrintable(false);
  };

  return (
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle='Job Card' />
        <Card sx={{padding: 2}}>
        <Grid container>
          <Grid item md={11}></Grid>
          <Grid item md={1} textAlign="right">
            <MDButton
              onClick={handlePrint}
              sx={{marginBottom: 2}}
              variant="gradient" color="error" title="Print Job Card" iconOnly>
                <Icon sx={{ fontWeight: "bold" }}>print_icon </Icon>
            </MDButton>
          </Grid>
        </Grid>
      <div ref={printableRef}>
            <center>
              <table style={{width:'98%', border:'2px solid black', padding: '3px'}}>
                  <tr>
                      <td colSpan={2}>
                        <table style={{width: '100%'}}>
                          <tr><th style={{textAlign:'center', fontSize:'14px'}}>Aditya Prints </th></tr>
                          <tr><th style={{textAlign:'center', fontSize:'24px'}}>JOB CARD #{workOrder?.id} </th></tr>
                        </table>
                      </td>
                  </tr>
                  <tr><td height="20px"></td></tr>
                  <tr>
                    <td>
                      <table style={{width: '90%'}}>
                        <tr>
                          <th style={{fontSize:'14px', textAlign:'left'}}>Date:</th>
                          <td style={{fontSize:'14px', textAlign:'left', paddingRight: '60px'}}>{workOrder?.release_date}</td>
                        </tr>
                        <tr>
                          <th style={{fontSize:'14px', textAlign:'left'}}>Party</th>
                          <td style={{fontSize:'14px', textAlign:'left', paddingRight: '60px'}}>{workOrder?.customer?.name}</td>
                        </tr>
                        <tr>
                          <th style={{fontSize:'14px', textAlign:'left'}}>Job Type:</th>
                          <td style={{fontSize:'14px', textAlign:'left', paddingRight: '60px'}}>{workOrder?.job_type}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table style={{width: '57%', float:'right'}}>
                        <tr>
                          <th style={{fontSize:'14px', textAlign:'left'}}>Delivery Date:</th>
                          <td style={{fontSize:'14px', textAlign:'right'}}>{workOrder?.delivery_date}</td>
                        </tr>
                        <tr>
                          <th style={{fontSize:'14px', textAlign:'left'}}>Phone No</th>
                          <td style={{fontSize:'14px', textAlign:'right'}}>{workOrder?.customer?.mobile}</td>
                        </tr>
                        <tr>
                          <th style={{fontSize:'14px', textAlign:'left'}}>Size:</th>
                          <td style={{fontSize:'14px', textAlign:'right'}}>{workOrder?.size?.name}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr ><td height="20px" colSpan={2}><hr style={{border: '1px solid', width: '100%'}}></hr></td></tr>
                  <tr>
                    <td colSpan={2}>
                      <table style={{width:'100%', border:'1px solid #cccccc', borderCollapse: 'collapse'}}>
                        <tr height="25px">
                          <th colSpan={5} style={{fontSize:'14px', textAlign:'center'}}><u>Issued Item Details</u></th>
                        </tr>
                        <tr height="25px">
                          <th style={{background: '#dddddd',fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Sr No.</th>
                          <th style={{background: '#dddddd',fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Item</th>
                          <th style={{background: '#dddddd',fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Wt(GSM)</th>
                          <th style={{background: '#dddddd',fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Size</th>
                          <th style={{background: '#dddddd',fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Quantity</th>
                        </tr>
                        {stageType.map((stage) => {
                          if (materialItem[stage]?.length > 0) {
                            return (
                              <>
                                <tr>
                                  <td colSpan={stage == 'PAPER' ? 3 : 5} style={{background: '#effbfb', fontSize:'14px', textAlign:'left', padding: '2px'}}>{stage}</td>
                                  {stage == 'PAPER' ? <td colSpan={2} style={{background: '#effbfb', fontSize:'14px', textAlign:'right', padding: '2px'}}>Cut Size: {workOrder?.cut_size?.name}</td> : ''}
                                </tr>
                                {materialItem[stage]?.map((eachMaterialItem, index) => {
                                  return (<tr>
                                    <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{index + 1}</td>
                                    <td style={{maxWidth: '250px', wordBreak: 'break-all', fontSize:'14px', textAlign:'left', border:'1px solid #cccccc', padding: '2px'}}>{eachMaterialItem?.item?.description}</td>
                                    <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachMaterialItem?.gsm}</td>
                                    <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachMaterialItem?.size?.name}</td>
                                    <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachMaterialItem?.quantity}</td>
                                  </tr>)
                                })}
                              </>
                            )
                          }
                        })}
                        {materialItem['DESIGN']?.length == 0 && materialItem['PAPER']?.length == 0 && materialItem['BINDING']?.length == 0 && materialItem['PRINTING']?.length == 0 && materialItem['PAPER']?.length == 0 && (
                          <tr>
                            <td colSpan={5} style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}> Not Applicable</td>
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                  <tr ><td height="20px" colSpan={2}><hr style={{border: '1px solid', width: '100%'}}></hr></td></tr>
                  <tr>
                    <td colSpan={2}>
                      <table style={{width:'100%', border:'1px solid #cccccc', borderCollapse: 'collapse'}}>
                        <tr height="25px">
                          <th colSpan={10} style={{fontSize:'14px', textAlign:'center'}}><u>Printing Item Details</u></th>
                        </tr>
                        <tr height="25px">
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Sr No.</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Narration</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Print Type</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Quantity</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Waste</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Color Scheme</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Colors</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}># Set</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Lamination</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Creasing</th>
                        </tr>
                        {printingItem?.map((eachPrintItem, index) => {
                          return (<tr>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{index + 1}</td>
                            <td style={{maxWidth: '150px', wordBreak: 'break-all', fontSize:'14px', textAlign:'left', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.description}</td>
                            <td style={{fontSize:'14px', textAlign:'left', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.print_type?.name}</td>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.quantity}</td>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.waste_qty}</td>
                            <td style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.color_scheme}</td>
                            <td style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.color_scheme}</td>
                            <td style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.no_sets}</td>
                            <td style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.is_lamination ? 'Yes' : 'No'}</td>
                            <td style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}>{eachPrintItem?.is_creasing ? 'Yes' : 'NO'}</td>
                          </tr>)
                        })}
                        {printingItem.length == 0 && (
                          <tr>
                            <td colSpan={10} style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}> Not Applicable</td>
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                  <tr ><td height="20px" colSpan={2}><hr style={{border: '1px solid', width: '100%'}}></hr></td></tr>
                  <tr>
                    <td colSpan={2}>
                      <table style={{width:'100%', border:'1px solid #cccccc', borderCollapse: 'collapse'}}>
                        <tr height="25px">
                          <th colSpan={5} style={{fontSize:'14px', textAlign:'center'}}><u>Stock Item Details</u></th>
                        </tr>
                        <tr height="25px">
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Sr No.</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Item</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Opening Stock</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Quantity</th>
                        </tr>
                        {stockItem?.map((eachStockItem, index) => {
                          return (<tr>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{index + 1}</td>
                            <td style={{maxWidth: '250px', wordBreak: 'break-all', fontSize:'14px', textAlign:'left', border:'1px solid #cccccc', padding: '2px'}}>{eachStockItem?.item?.description}</td>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachStockItem?.opening_stock}</td>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachStockItem?.quantity}</td>
                          </tr>)
                        })}
                        {stockItem.length == 0 && (
                          <tr>
                            <td colSpan={4} style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}> Not Applicable</td>
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                  <tr ><td height="20px" colSpan={2}><hr style={{border: '1px solid', width: '100%'}}></hr></td></tr>
                  <tr>
                    <td colSpan={2}>
                      <table style={{width:'100%', border:'1px solid #cccccc', borderCollapse: 'collapse'}}>
                        <tr height="25px">
                          <th colSpan={5} style={{fontSize:'14px', textAlign:'center'}}><u>Other Item Details</u></th>
                        </tr>
                        <tr height="25px">
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Sr No.</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Item</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Wt(GSM)</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Size</th>
                          <th style={{background: '#dddddd', fontSize:'14px', textAlign:'center', border:'1px solid #cccccc'}}>Quantity</th>
                        </tr>
                        {otherItem?.map((eachOtherItem, index) => {
                          return (<tr>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{index + 1}</td>
                            <td style={{maxWidth: '250px', wordBreak: 'break-all', fontSize:'14px', textAlign:'left', border:'1px solid #cccccc', padding: '2px'}}>{eachOtherItem?.item?.description}</td>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachOtherItem?.gsm}</td>
                            <td style={{fontSize:'14px', textAlign:'left', border:'1px solid #cccccc', padding: '2px'}}>{eachOtherItem?.size?.name}</td>
                            <td style={{fontSize:'14px', textAlign:'right', border:'1px solid #cccccc', padding: '2px'}}>{eachOtherItem?.quantity}</td>
                          </tr>)
                        })}
                        {otherItem.length == 0 && (
                          <tr>
                            <td colSpan={5}  style={{fontSize:'14px', textAlign:'center', border:'1px solid #cccccc', padding: '2px'}}> Not Applicable</td>
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
              </table>
            </center>
          </div>
         </Card>
       <Footer />
     </DashboardLayout>
  );
}

export default JobCard;
