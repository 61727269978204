/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDSnackbar from "components/MDSnackbar";

export default function customAlert() {
  //Alert Notification starts
  const [alertSB, setAlertSB] = useState(false);
  const [colorSB, setColorSB] = useState('');
  const [iconSB, setIconSB] = useState('');
  const [contentSB, setContentSB] = useState('');
  const [titleSB, setTitleSB] = useState('');

  const openAlertSB = (props) => {
    setAlertSB(true);
    setColorSB(props?.color);
    setIconSB(props?.icon);
    setContentSB(props?.msg);
    setTitleSB(props?.title);
  };

  const closeAlertSB = () => {
    setAlertSB(false);
  };

  const renderAlertSB = (
    <MDSnackbar
      color={colorSB}
      icon={iconSB}
      title={titleSB}
      content={contentSB}
      open={alertSB}
      onClose={closeAlertSB}
      close={closeAlertSB}
      bgWhite
    >
    </MDSnackbar> 
  );
  //Alert Notification ends
  return {
    openAlertSB:openAlertSB,
    closeAlertSB:closeAlertSB,
    renderAlertSB:renderAlertSB
  };
}
