/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default function defaultUserImage(name, size = 250, length = 2, font_color = 'fff') {
  const bgColor = '223453';
  return 'https://ui-avatars.com/api/?bold=true&name=' + encodeURI(name) + '&length=' + length + '&size=' + size + '&background=' + bgColor + '&color=' + font_color;
}
