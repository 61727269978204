import HttpService from "./htttp.service";

class PrintingRatesService {

  getPrintingRates = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    const fetchUrl = 'printing_rates?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    return await HttpService.get(fetchUrl);
  }

  addPrintingRate = async(newPrintingRate) => {
    const fetchUrl = 'printing_rates';
    return await HttpService.post(fetchUrl, newPrintingRate);
  }

  updatePrintingRate = async (id, data) => {
    const fetchUrl = 'printing_rates/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updatePrintingRateStatus = async (id, data) => {
    const fetchUrl = 'printing_rates/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showPrintingRate = async(id) => {
    const fetchUrl = 'printing_rates/' + id;
    return await HttpService.get(fetchUrl);
  }

  deletePrintingRate = async (id) => {
    const fetchUrl = 'printing_rates/' + id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new PrintingRatesService();
