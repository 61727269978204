import HttpService from "./htttp.service";

class PoPaymentService {

  getPoPayments = async(id) => {
    let fetchUrl = 'po_payments';
    if (id) {
      fetchUrl = 'po_payments?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addPoPayment = async(data) => {
    const fetchUrl = 'po_payments';
    return await HttpService.post(fetchUrl, data);
  }

  deleteAllPoPayment = async (grn_id) => {
    const fetchUrl = 'po_payments?grn_id=' + grn_id;
    return await HttpService.delete(fetchUrl);
  }

  approvePoPayment = async (grn_id) => {
    const fetchUrl = 'po_payments_approve?grn_id=' + grn_id;
    return await HttpService.patch(fetchUrl);
  }
}

export default new PoPaymentService();
