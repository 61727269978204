/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCheckbox from "components/MDCheckbox";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import SupplierItemService from "services/supplier-item-service";
import SupplierService from "services/supplier-service";
import SizeService from "services/size-service";
import authPermission from "utility/auth-permissions";

function Form() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const ref = searchParams.get('ref');
  const navigate = useNavigate();

  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-suppliers/base-rate');

  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [headerTitle, setHeaderTitle] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [supplierItems, setSupplierItems] = useState([]);
  const [searchGsm, setSearchGsm] = useState('');
  const [searchSize, setSearchSize] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [sizesOptions, setSizesOptions] = useState([]);
  const [changeRate, setChangeRate] = useState('');
  const [selectAll, setSelectAll] = useState(0);
  const [loading, setLoading] = useState(false);

  const showSupplier = async() => {
    try {
      const data = await SupplierService.showSupplier(id);
      setHeaderTitle(data.name);
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Supplier'};
        navigate('/manage-suppliers', {state: state});
      } else {
        const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier'};
        navigate('/manage-suppliers', {state: state});
      }
    }
  }

  const showSupplierItem = async(id, searchItem, searchSize, searchGsm) => {
    setLoading(true);
    const response = await SupplierItemService.getItemsBySupplierId(id, searchItem, ((searchSize == 'all' || searchSize == null) ? '' : searchSize), searchGsm);
    const result = response.map(v => ({
      ...v,
      is_checked: false,
      purchase_rate: Number(v.purchase_rate).toFixed(2),
      gsm: Number(v.gsm).toFixed(2)
    }));
    setSupplierItems(result);
    setLoading(false);
  }

  useEffect(() => {
    showSupplier();
    showSupplierItem(id, searchItem, searchSize, searchGsm);

    const getSizesData = async() => {
      try {
        const response = await SizeService.getSizes();
        if (response) {
          var sizesArray = [];
          response?.map((eachSize, index) => {
            sizesArray.push({
              key: eachSize?.id,
              value: eachSize?.name,
              disabled: eachSize?.active ? false : true,
            });
          });
          setSizesOptions(sizesArray);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Size'};
          navigate('/manage-supplier-items', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Size'};
          navigate('/manage-supplier-items', {state: state});
        }
      }
    }
    getSizesData();
  }, []);

  useEffect(() => {
    setBtnLoading(false);
  }, [searchItem, searchSize, searchGsm]);

  const searchHandler = async (e) => {
    e.preventDefault();
    showSupplierItem(id, searchItem, searchSize, searchGsm);
  };

  const onChangeSelectItem = (index, fieldValue) => {
    setBtnLoading(false);
    const cloneSupplierItems = [...supplierItems]
    cloneSupplierItems[index]['is_checked'] = fieldValue
    setSupplierItems(cloneSupplierItems);
  };

  const onChangeSelectAll = (fieldValue) => {
    setSelectAll(fieldValue);
    const cloneSupplierItems = [...supplierItems]
    cloneSupplierItems.map((items, index) => {
      cloneSupplierItems[index]['is_checked'] = fieldValue
    });
    setSupplierItems(cloneSupplierItems);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    let isAnyUpdated = false;
    const promises = supplierItems.map(async(item) => {
      if (item.is_checked) {
        isAnyUpdated = true;
        item.purchase_rate = changeRate;
        return await SupplierItemService.updateSupplierItem(item.id, item);
      }
      return null;
    });
    const responses = await Promise.all(promises);
    showSupplierItem(id, '', 'all', '');
    setBtnLoading(false);
    if (isAnyUpdated) {
      openAlertSB({color: 'success', icon: 'check', msg: 'Rate Updated Successfully!', title: 'Supplier Item Base Rate'});
      setSearchItem('');
      setSearchGsm('');
      setSearchSize('all');
      setChangeRate('');
    } else {
      openAlertSB({color: 'warning', icon: 'star', msg: 'No Item selected to update!', title: 'Supplier Item Base Rate'});
    }
  };

  const columns = [
    { Header: (<MDCheckbox checked={selectAll} onChange={(e) => onChangeSelectAll(e.target.checked)} />), accessor: "select", width: "10%", align: "center" },
    { Header: "Item", accessor: "item", width: "35%", align: "left" },
    { Header: "Size", accessor: "size", width: "20%", align: "left" },
    { Header: "Unit", accessor: "unit", width: "10%", align: "left" },
    { Header: "Gsm", accessor: "gsm", width: "15%", align: "right" },
    { Header: "Rate", accessor: "rate", width: "15%", align: "right" },
  ];

  const rows = [];
  supplierItems.map((eachItem, index) => {
    const cols = {
      select:(<MDCheckbox
                checked={eachItem.is_checked}
                onChange={(e) => onChangeSelectItem(index, e.target.checked)}
              />),
      item:(<MDTypography display="block" variant="body2">
            {eachItem.description}
            </MDTypography>),
      size:(<MDTypography display="block" variant="body2">
            {eachItem.name}
            </MDTypography>),
      unit:(<MDTypography display="block" variant="body2">
            {eachItem.unit_name}
            </MDTypography>),
      gsm:(<MDTypography display="block" variant="body2">
            {eachItem.gsm}
            </MDTypography>),
      rate:(<MDTypography display="block" variant="body2">
            {eachItem.purchase_rate}
            </MDTypography>),
    };
    rows[index] = cols;
  });

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={('Base Rate : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.625rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={11}>
                      <MDTypography mt={1} variant="h6" color="white">
                          {('Base Rate : ' + headerTitle)}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={1} justifyContent="space-between">
                        <MDButton
                        component={Link}
                        to={`/manage-suppliers/edit?id=${id}`}
                        variant="outlined" color="white" iconOnly circular  title="Edit Supplier">
                          <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>edit</Icon>
                        </MDButton>
                      </Grid>
                  </Grid>
                </MDBox>
                <MDBox mt={3} px={2}>
                  <MDBox component="form" role="form" method="POST" onSubmit={searchHandler}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Item"
                            fullWidth
                            value={searchItem}
                            inputProps={{'maxLength':80}}
                            onChange={(e) => setSearchItem(e.target.value)}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MDBox mb={2}>
                          <MDSelect
                            label="Size"
                            fullWidth
                            value={searchSize}
                            onChange={(e) => setSearchSize(e.target.value)}
                            options={sizesOptions}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="GSM"
                            fullWidth
                            value={searchGsm}
                            inputProps={{'maxLength':80}}
                            onChange={(e) => setSearchGsm(e.target.value)}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} md={2}></Grid>
                      <Grid item xs={4} md={1}>
                        <MDBox>
                          <MDButton variant="gradient" color="success" fullWidth type="submit">
                            Search
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
                <MDBox mt={3} px={2}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                      totalData={rows.length}
                      progressPending={loading}
                      doubleClick={false}
                    />
                    {pagePermission.edit ?
                      <Grid container spacing={2}  sx={{marginTop: "1px"}}>
                        <Grid item xs={8} md={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Update item rate with base rate"
                              fullWidth
                              value={changeRate}
                              inputProps={{'pattern':'^([0-9]{1,8}|[0-9]{1,8}\.[0-9]{1,4})$'}}
                              onChange={(e) => setChangeRate(e.target.value)}
                              required
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={2} md={1}>
                          <MDBox>
                            <MDButton variant="gradient" color="info" fullWidth type="submit">
                              {btnLoading ?
                                <MDCircularProgress
                                  size={20}
                                /> :
                                'Update'
                              }
                            </MDButton>
                          </MDBox>
                        </Grid>
                        <Grid item xs={2} md={1}>
                          <MDBox>
                            <MDButton component={Link} to={ref ? `/manage-suppliers/edit?id=${id}&ref=${ref}` : "/manage-suppliers/edit?id=" + id} variant="gradient" color="primary" fullWidth>
                              Back
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid> : ''
                    }
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
