import dayjs from 'dayjs';

export const PDFTemplate = (itemReceiptVsIssue, startDate, endDate, numberFormat) => {
    return  `<html>
  <head>
    <title>item_receipt_vs_issue_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Item Receipt Vs Issue</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: left !important;">Document</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: left !important;">Doc No</th>
                <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: left !important;">Date</th>
                <th style="background-color: #dddddd; width: 45%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: left !important;">Job Type</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-bottom: 1px solid #000; text-align: left !important;">Quantity</th>
            </tr>
            ${itemReceiptVsIssue?.map((eachItem, index) => {
                let rowColor = '#e0f8f7';
                return `
                    <tr style="background-color: #f8ece0;">
                        <td colspan="3" style="border-bottom: 1px solid #000; border-right: 1px solid #000;"><b>Item : </b>${eachItem?.item_name}</td>
                        <td colspan="2" style="border-bottom: 1px solid #000; text-align: right !important;color: orange;"><b>Opening Stock ${startDate ? 'as On ' + dayjs(startDate).format('DD-MMM-YYYY') : ''}: ${eachItem?.opening_stock}</b></td>
                    </tr>
                    ${eachItem?.items?.map((item) => {
                        rowColor = rowColor = '#e0f8f7' ? '#effbfb' : '#e0f8f7';
                            return `
                                <tr style="background-color: ${rowColor}">
                                    <td style="font-size: 0.775rem; text-align: left !important; border-right: 1px solid #000; border-bottom: 1px solid #000;"><b>${item?.document}</b></td>
                                    <td style="font-size: 0.775rem; text-align: right !important; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.doc_no}</td>
                                    <td style="font-size: 0.775rem; text-align: left !important; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.date}</td>
                                    <td style="font-size: 0.775rem; text-align: left !important; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.job_type}</td>
                                    <td style="font-size: 0.775rem; text-align: right; border-bottom: 1px solid #000;">${item?.quantity}</td>
                                </tr>
                            `
                    }). join('')}
                    <tr>
                        <td colspan="5" style="text-align: right !important;color: green;"><b>Closing Stock as On ${endDate ? dayjs(endDate).format('DD-MMM-YYYY') : dayjs(new Date).format('DD-MMM-YYYY')}: ${eachItem?.closing_stock}</b></td>
                    </tr>
                `
            }).join('')}        
        </table>
      </center>
    </div>
  </body>
</html>`

}
