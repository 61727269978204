/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportService from "services/report-service";
import CustomerService from "services/customer-service";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import { Link } from "react-router-dom";
import MDInput from "components/MDInput";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const invoicePermission = hasPermission('/manage-invoices');
    const woPermission = hasPermission('/manage-work-orders');
    const { cacheItems, setCacheItems } = useCache();
    const [customerDropdown, setCustomerDropdown] = useState([]);
    const [invoiceRegister, setInvoiceRegister] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [billType, setBillType] = useState('');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [grandAmount, setGrandAmount] = useState(0);
    const [grandTax, setGrandTax] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [startDate, setStartDate] = useState((dayjs(new Date()).startOf('month')));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [filter, setFilter] = useState('');
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getInvoiceRegister = async(customerId = '', invoiceNo = '', billType = '', workOrderId = '', startDate = '', endDate = '') => {
        const data = await ReportService.getInvoiceRegister(customerId, invoiceNo, billType, workOrderId, startDate, endDate);
        setInvoiceRegister(Object.values(data.invoice_register));
        setGrandAmount(data.grand_amount);
        setGrandTax(data.grand_tax);
        setGrandTotal(data.grand_total);
    }

    useEffect(() => {
        const getCustomersData = async() => {
            let response;
            if (cacheItems.hasOwnProperty('customers')) {
                response = cacheItems['customers'];
            } else {
                response = await CustomerService.getCustomers(); // Fetch customers from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    customers: response,
                }));
            }
            if (response) {
              var customerArray = [];
              response?.map((eachCustomer, index) => {
                if(eachCustomer?.active) {
                    customerArray[index] = {
                        key: eachCustomer?.id,
                        value: eachCustomer?.name,
                        disabled: eachCustomer?.active ? false : true,
                    }
                }
              });
              setCustomerDropdown(customerArray);
            }
        }
        getCustomersData();
        getInvoiceRegister(customerId, invoiceNo, billType, workOrderId, dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '', dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '');
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
        const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
        getInvoiceRegister(customerId, invoiceNo, billType, workOrderId, sDate, eDate);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setCustomerId('');
        setBillType('');
        setInvoiceNo('');
        setWorkOrderId('');
        setStartDate('');
        setEndDate('');
        setFilter(null);
        getInvoiceRegister();
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data, grandAmount, grandTax, grandTotal) => {
        setExcelLoading(true);
        const fileName = 'invoice_register_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Invoice Register']);
        //Merge cells for supplier row
        worksheet.mergeCells(`A1:G1`);

        // Add headers
        const headerRow = worksheet.addRow(['Customer Name', 'Date', 'Job Type', 'Work Order', 'Invoice No', 'Basic', 'TAX(VAT/GST)', 'Total']);
        // Set the width for each column
        worksheet.columns = [
            { width: 25 },
            { width: 15 }, 
            { width: 35 },
            { width: 10 },
            { width: 20 }, 
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
        ];
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 1; i < 13; i++) {
            if (i<9) {
                headerRow.getCell(i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dddddd' }, // Red color
                };
            }
            //Add border
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        let k = 3;
        data.forEach((eachData, index) => {
            // const customerRow = 'Customer Name: ' + eachData?.customer_name;
            // const cellRowCustomer = worksheet.addRow([customerRow]);
            // cellRowCustomer.getCell(1).fill = {
            //     type: 'pattern',
            //     name: 'sans-serif',
            //     pattern: 'solid',
            //     fgColor: { argb: 'f8ece0' },
            // };
            // cellRowCustomer.border = {
            //     right: { style: 'thin' }
            // };
            // // Merge cells for supplier row
            // worksheet.mergeCells(`A${k}:G${k}`);
            k += 1;
            let rowInd = 0;
            let rowColor = 'effbfb';
            // Add data
            eachData?.items?.forEach((row, index) => {
                const cellRow = worksheet.addRow([(rowInd == 0 ? eachData?.customer_name : ''), row?.date, row?.job_type, row?.work_order_id, row?.invoice_no, Number(row?.amount), Number(row?.tax), Number(row?.total), (eachData.items.length == rowInd + 1 ? 'Total' : ''), (eachData.items.length == rowInd + 1 ? Number(eachData?.amount) : ''), (eachData.items.length == rowInd + 1 ? Number(eachData?.tax) : ''), (eachData.items.length == rowInd + 1 ? Number(eachData?.total) : '')]);
                rowColor = rowColor == 'effbfb' ? 'e0f8f7' : 'effbfb';
                for (var i = 1; i < 13; i++) {
                    if (i<9) {
                        cellRow.getCell(i).fill = {
                            type: 'pattern',
                            name: 'sans-serif',
                            pattern: 'solid',
                            fgColor: { argb: i>1 ? rowColor : (rowInd == 0 ? 'f8ece0' : 'FFFFFF') },
                        };
                    }
                    // Add border to the row
                    cellRow.getCell(i).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                }
                cellRow.getCell(6).numFmt = '#,##0.00';
                cellRow.getCell(7).numFmt = '#,##0.00';
                cellRow.getCell(8).numFmt = '#,##0.00';
                cellRow.getCell(10).numFmt = '#,##0.00';
                cellRow.getCell(11).numFmt = '#,##0.00';
                cellRow.getCell(12).numFmt = '#,##0.00';
                cellRow.getCell(9).font = { bold: true, name: 'sans-serif' };
                cellRow.getCell(10).font = { bold: true, name: 'sans-serif' };
                cellRow.getCell(11).font = { bold: true, name: 'sans-serif' };
                cellRow.getCell(12).font = { bold: true, name: 'sans-serif' };

                k += 1;
                rowInd += 1;
            });
            // const cellRowTotal = worksheet.addRow(['Total:', '', '', '', Number(eachData?.amount), Number(eachData?.tax), Number(eachData?.total)]);
            // worksheet.mergeCells(`A${k}:D${k}`);
            // cellRowTotal.getCell(1).alignment = { horizontal: 'right' };
            // cellRowTotal.getCell(5).numFmt = '#,##0.00';
            // cellRowTotal.getCell(6).numFmt = '#,##0.00';
            // cellRowTotal.getCell(7).numFmt = '#,##0.00';
            // cellRowTotal.font = { bold: true, name: 'sans-serif' };
            // k += 1;
        });

        const cellRowGrandTotal = worksheet.addRow(['', '', '', '', '','', '', '', 'Grand Total:', Number(grandAmount), Number(grandTax), Number(grandTotal)]);
        // worksheet.mergeCells(`A${k}:D${k}`);
        // cellRowGrandTotal.getCell(1).alignment = { horizontal: 'right' };
        cellRowGrandTotal.getCell(10).numFmt = '#,##0.00';
        cellRowGrandTotal.getCell(11).numFmt = '#,##0.00';
        cellRowGrandTotal.getCell(12).numFmt = '#,##0.00';
        cellRowGrandTotal.font = { bold: true, name: 'sans-serif' };
      
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(invoiceRegister){
            const pdfTemplate = PDFTemplate(invoiceRegister, grandAmount, grandTax, grandTotal, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`${pdfTemplate}`);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Invoice Register'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Invoice Register'}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                    <Grid item xs={12} lg={minWidthD ? 2 : 6} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Customer"
                                                fullWidth
                                                name="customer_id"
                                                value={customerId}
                                                onChange={(e) => setCustomerId(e.target.value != null ? e.target.value : '')}
                                                options={customerDropdown}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={minWidthD ? 1 : 6} px={1} md={3}>
                                        <MDBox mb={1}>
                                            <MDInput
                                                label="Invoice No#"
                                                fullWidth
                                                name="bill_no"
                                                value={invoiceNo}
                                                onChange={(e) => setInvoiceNo(e.target.value != null ? e.target.value : '')}
                                            />
                                        </MDBox>
                                     </Grid>
                                     <Grid item xs={12} lg={minWidthD ? 1 : 6} px={1} md={3}>
                                        <MDBox mb={1}>
                                            <MDInput
                                                label="WO#"
                                                fullWidth
                                                name="work_order_"
                                                value={workOrderId}
                                                onChange={(e) => setWorkOrderId(e.target.value != null ? e.target.value : '')}
                                            />
                                        </MDBox>
                                     </Grid>
                                     <Grid item xs={12} lg={minWidthD ? 1 : 6} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Bill Type"
                                                fullWidth
                                                name="bill_type"
                                                value={billType}
                                                onChange={(e) => setBillType(e.target.value != null ? e.target.value : '')}
                                                options={[{key: 1, value: 'With Bill'}, {key: 2, value: 'Work Order'}]}
                                            />
                                        </MDBox>
                                    </Grid>
                                     <Grid item lg={5} xs={12} px={1} md={8}>
                                        <MDBox mb={1}>
                                            <MDDateRangePicker
                                                label="Start Date"
                                                name="start_date"
                                                value={startDate ? startDate : null}
                                                format="DD-MM-YYYY"
                                                onChange={(newValue) => {
                                                    setDate(newValue, 'start_date');
                                                }}
                                                endLabel="End Date"
                                                endName="end_date"
                                                endValue={endDate ? endDate : null}
                                                endOnChange={(newValue) => {
                                                    setDate(newValue, 'end_date');
                                                }}
                                                onFilterChange={onFilterChange}
                                                filter={filter}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {invoiceRegister.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(invoiceRegister, grandAmount, grandTax, grandTotal)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Date</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '40%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Job Type</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Work Order</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Invoice No</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Basic</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>TAX(VAT/GST)</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Total</TableCell>
                                        </TableRow>
                                        {invoiceRegister?.map((eachItem) => {
                                            let rowColor = '#effbfb';
                                            return (
                                                <>
                                                    <TableRow sx={{ backgroundColor: '#f8ece0' }}>
                                                        <TableCell colSpan={7} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Customer Name:</b> {eachItem?.customer_name}</TableCell>
                                                    </TableRow>
                                                    {eachItem?.items?.map((item) => {
                                                        rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                                                        return (
                                                            <TableRow sx={{ backgroundColor: rowColor }}>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.date}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '40%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.job_type}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: 'info.main'}}  component={Link} to={woPermission.edit ? `/manage-work-orders/edit?id=${item?.work_order_id}&close_window=1` : ``} target={'_blank'}>{item?.work_order_id}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: 'info.main'}}  component={Link} to={invoicePermission.edit ? `/manage-invoices/edit?id=${item?.invoice_id}&close_window=1` : ``} target={'_blank'}>{item?.invoice_no}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.amount)}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.tax)}</TableCell>
                                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.total)}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                    <TableRow>
                                                        <TableCell colSpan={4} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>Total:</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(eachItem?.amount)}</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(eachItem?.tax)}</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(eachItem?.total)}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}
                                        {invoiceRegister.length == 0 ? 
                                            <TableRow>
                                                <TableCell colSpan={7} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow>
                                        : 
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>Grand Total:</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(grandAmount)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(grandTax)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{numberFormat(grandTotal)}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
