import dayjs from 'dayjs';
import { numberFormat, amountToWords } from 'utility/helpers';
import brandWhite from "assets/images/logo.png";

const stageType = ['DESIGN','PAPER','PRINTING','BINDING','EXTRA'];
const getStageTypeInputValue = (stage, data) => {
  switch (stage) {
    case 'DESIGN':
      return data.is_design;
    case 'PAPER':
      return data.is_paper;
    case 'PRINTING':
      return data.is_printing;
    case 'BINDING':
      return data.is_binding;
    case 'EXTRA':
      return data.is_extra;
    default:
    return false;
  }
}

// helper function to calculate character width
function getCharWidth(char) {
  const widths = {
    'A': 1, 'B': 1, 'C': 1, 'D': 1, 'E': 1, 'F': 1, 'G': 1, 'H': 1, 'I': 1, 'J': 1, 'K': 1, 'L': 1, 'M': 1,
    'N': 1, 'O': 1, 'P': 1, 'Q': 1, 'R': 1, 'S': 1, 'T': 1, 'U': 1, 'V': 1, 'W': 1, 'X': 1, 'Y': 1, 'Z': 1,
    'a': 1, 'b': 1, 'c': 1, 'd': 1, 'e': 1, 'f': 1, 'g': 1, 'h': 1, 'i': 1, 'j': 1, 'k': 1, 'l': 1, 'm': 1,
    'n': 1, 'o': 1, 'p': 1, 'q': 1, 'r': 1, 's': 1, 't': 1, 'u': 1, 'v': 1, 'w': 1, 'x': 1, 'y': 1, 'z': 1,
    '0': 1, '1': 1, '2': 1, '3': 1, '4': 1, '5': 1, '6': 1, '7': 1, '8': 1, '9': 1,
    ' ': 0.5, // space character is half the width of a normal character
  };
  return widths[char] || 1; // default to 1 if character width is not defined
}

export const invoicePDFTemplatePrint = (companyName, companyAddress, invoice, rowPresentArray, material, materialItems, printing, stock, totalAmount, receivedAmount, lastRowHeight, getGstPrice, getOutstanding, getTotal) => {
  const regex = /(<([^>]+)>)/gi;
  const address = companyAddress.replace(regex, "");

  return  `<html>
  <head>
    <title>Invoice_${invoice?.invoice_no} </title>
    <style>
      table td {
        font-size:12px;
        font-family: Helvetica;
      }
      .note_table td ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .note_table td ul li:before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: #666;
        border: 1px solid #333;
        margin-right: 10px;
        vertical-align: middle;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
      .mtable{
        width:100%;
        border:1px solid #CCCCCC;
      }
      .mtable_noborder{
          border:0px;
      }
      .mtable td{
          font-size:12px;
          font-family: Helvetica;
      }
      @page {
         margin: '10px';
      }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div style="border: 0px solid red;height:260px;">
        <table border=0 align="center" cellspacing=0 cellpadding=3 style="padding-left:0px; padding-right:20px;padding-top:0px;margin-top:-10px;width:99%">
            <tr>
              <th colspan=5 style="padding-left:400px;padding-top:-23px;font-size:14px;" id="lbl">
                  TAX INVOICE<br>
                  ${invoice?.invoice_no}
              </th>
            </tr>
            <tr style="height:115px;">
                <td colspan=2><img src="${brandWhite}" alt="Aditya Prints" style="max-width:20rem !important;"/></td>
                <td colspan=3>&nbsp;</td>
            </tr>
            <tr>
                <td colspan=3 style="padding-left:45px;">
                    <table style="width: 100%;">
                      <tr>
                          <td style="white-space: nowrap;font-size: 10px !important;">GSTIN/UIN</td>
                          <td style="font-size: 10px !important;">- 27AAAPO5919Q1Z2</td>
                      </tr>
                      <tr>
                          <td style="width: 13%;font-size: 10px !important;">MSME No</td>
                          <td style="font-size: 10px !important;">- UDYAM-MH-23-0205434</td>
                      </tr>
                    </table>
                </td>
                <td colspan=2 style="text-align:right;vertical-align:top;height:30px;">
                    <div style="width:100%;float:right;font-size:18px;"><b>${invoice.customer_name}</b></div>
                    <div style="width:100%;float:right;margin-top: 8px !important;"><b>${dayjs(invoice.release_date).format('DD MMM YYYY')}</b></div>
                </td>
            </tr>
        </table>
    </div>
    <div style="margin-top: 10px !important; border-top: 1px solid #2E64FE;border-left: 1px solid #2E64FE;border-right: 1px solid #2E64FE;height:420px;padding-left: 5px;padding-right: 5px !important;">
        <table border=0 cellspacing=0 cellpadding=3 style="width: 100% !important;padding: 15px !important;">
          <tr>
              <td colspan=5 style="padding-left:10px;font-weight: bold;">CUSTOMER - GSTIN # ${invoice.customer_gstin ? invoice.customer_gstin : ''}</td>
          </tr>
          <tr>
              <td colspan=5 style="padding-left:10px;">HSN/SAC - ${invoice.hsn.code}</td>
          </tr>
          <tr style="border: 1px solid;">
              <td colspan=2 style="width: 50%;padding-left:10px;">DC No. : ${invoice.dc_no ? invoice.dc_no : ''}</td>
              <td colspan=3 style="width: 50%;text-align:right;padding-right:10px;">Job Card No. : ${invoice.work_order_id}</td>
          </tr>
          <tr height="5px"></tr>
          <tr style="height:25px;background-color: #cbf7ee;border: 1px solid #cbf7ee;border-radius: 5px;margin-left: 5px !important;">
              <td style="width: 10% !important;">Sr No.</td>
              <td colspan=3 style="width:75%;text-align:left;margin-left:-10px;">Particulars</td>
              <td style="width: 15%;text-align:right;">Amount</td>
          </tr>
          <tr height="8px"></tr>
          ${invoice.instruction ? `
            <tr>
              <td></td>
              <td colspan=3 style="width:75%;text-align:left;vertical-align:top;"><pre>${invoice.instruction}</pre></td>
              <td style="width: 15%;text-align:right;">${numberFormat(totalAmount)}</td>
            </tr>
              ` :
              `
            <tr>
                <td style="text-align:center;width:10%;vertical-align:top;">*</td>
                <td style="width:12.5%;vertical-align:top;" colspan="1"><b>Job Type :</b></td>
                <td style="width:62.5%;text-align:left;" colspan="2"><span style="">${invoice.job_type}</span></td>
                <td></td>
            </tr>
            <tr>
                <td style="text-align:center;">*</td>
                <td  colspan="3" style="width: 15%;"><b>Size :</b><span style="padding-left:52px;">${invoice.size.name}</span></td>
                <td></td>
            </tr>
            ${printing?.map((item, index) => {
              return `
                <tr style="padding-left:0px;">
                    <td style="text-align:center;width: 10%;">*</td>
                    <td colspan="3" style="width: 75%;">${(item.description ? item.description : ' ') + '    (' + item?.color_scheme + ' ' + item?.no_sets + ' sets' + ' ' + item?.quantity + ' sheets' + ' ' + item?.wo_printing_type + ')'}</td>
                    <td style="text-align:right;width: 15%;">${numberFormat(item.amount)}</td></td>
                </tr>
                `}).join("")
            }
            ${stageType?.map((stage) => {
              var isStageAll = getStageTypeInputValue(stage, invoice);
              if (rowPresentArray[stage]) {
                if (isStageAll) {
                  return `
                    <tr>
                      <td style="text-align:center;width: 10%;">*</td>
                      <td colspan="3" style="width: 75%;"><b>${stage}</b></td>
                      <td style="text-align:right;width: 15%;">${numberFormat(material[stage])}</td>
                    </tr>
                  `;
                } else {
                  let i = 0;
                  return materialItems[stage]?.map((item) => {
                    let currentI = i;
                    i++;
                    if (item.is_print) {
                      let spaces = '';
                      for (let j = 0; j < stage.length; j++) {
                        const charWidth = getCharWidth(stage[j]); // calculate width of each character
                        spaces += Array(charWidth + 1).fill('&nbsp;').join(''); // add spaces based on character width
                      }
                      return `
                        <tr>
                          <td style="text-align:center;width: 10%;">*</td>
                          <td colspan="3" style="width: 75%;"><b>${currentI == 0 ? stage : ''}</b><ul style='padding:0px;margin:0px;width:83%;float:right;'><li>${item.item_description}&nbsp;&nbsp;&nbsp;&nbsp;Qty: ${item.quantity * 1} ${item.items.purchase ? item.items.purchase.name : ''}</li></ul></td>
                          <td style="text-align:right;width: 15%;">${numberFormat(item.amount)}</td>
                        </tr>
                      `;
                    }
                  }).join("");
                }
              }
            }).join("")}
            ${stock?.map((item, index) => {
              return `
                <tr style="padding-left:0px;">
                    <td style="text-align:center;width: 10%;">*</td>
                    <td colspan="3" style="width: 75%;">${item.item_description}&nbsp;&nbsp;&nbsp;&nbsp;Qty: ${item.quantity * 1} ${item.items.purchase ? item.items.purchase.name : ''}</td>
                    <td style="text-align:right;width: 15%;">${numberFormat(item.total)}</td></td>
                </tr>
                `}).join("")
            }
          `}
      </table>
    </div>
    <div style="border: 0px solid green;height:30px;text-align:left;padding-left:22px;padding-right:22px;white-space:normal;border-left: 1px solid #2E64FE;border-right: 1px solid #2E64FE;">${invoice?.discount_desc ? 'Discount: ' + (invoice.discount_desc ? invoice.discount_desc : '') : ''}</div>
    <div style="padding-bottom: 8px !important;border-left: 1px solid #2E64FE;border-right: 1px solid #2E64FE;padding-left: 5px;padding-right: 5px;">
      <table width=100% border=0 cellpadding=0 cellspacing=0 style="padding: 15px !important;">
          <tr>
              <td style="vertical-align:top;">
                  <table border=0 align="center" cellspacing=0 cellpadding=3 style="padding-left:5px; padding-right:5px;padding-top:${invoice?.discount > 0 ? `35px` : `20px`}">
                      <tr>
                          <td style="width:50%;padding-left:0px;">Received Amount : ${receivedAmount > 0 ? '-' + numberFormat(receivedAmount) : '0.00'}</td>
                      </tr>
                      <tr>
                          <td style="width:60%;padding-left:0px;">Outstanding Amount: ${numberFormat(getOutstanding(getTotal(totalAmount, invoice.state_gst, invoice.central_gst), 0))}</td>
                      </tr>
                      <tr>
                          <td style="font-size:14px;padding-left:0px;">
                              <b>Rs. ${getTotal(totalAmount, invoice.state_gst, invoice.central_gst) ? amountToWords(getOutstanding(getTotal(totalAmount, invoice.state_gst, invoice.central_gst), 0)) : 'Zero'} Only</b>
                          </td>
                      </tr>
                  </table>
              </td>
              <td style="vertical-align:top;width:40%; padding-right: 8px;">
                  <table border=0 cellspacing=0 cellpadding=0 style="padding-left:0px;padding-top:0px;width:100%;">
                      ${invoice?.discount > 0 ? `
                        <tr>
                            <td  style="text-align:right;font-size:12px;">Discount</td>
                            <td style="text-align:right;font-size:12px;">${numberFormat(invoice?.discount)}</td>
                        </tr>` : ``}
                      <tr>
                          <td style="text-align:right;font-size:14px;width:55%;">Total</td>
                          <td style="text-align:right;font-size:14px;">${numberFormat(totalAmount)}</td>
                      </tr>
                      <tr>
                          <td style="text-align:right;font-size:14px;">
                            SGST &nbsp;&nbsp;&nbsp;${numberFormat(invoice?.state_gst)}%
                          </td>
                          <td style="text-align:right;font-size:14px;">
                            ${numberFormat(getGstPrice(invoice?.state_gst, totalAmount))}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align:right;font-size:14px;">
                            CGST &nbsp;&nbsp;&nbsp;${numberFormat(invoice?.central_gst)}%
                          </td>
                          <td style="text-align:right;font-size:14px;">
                            ${numberFormat(getGstPrice(invoice?.central_gst, totalAmount))}
                          </td>
                      </tr>
                      <tr>
                          <td colspan=2><div style="width:75%;float:right;"><hr style="border: 0.5px solid;"/></div></td>
                      </tr>
                      <tr>
                          <td style="text-align:right;font-size:16px;"><b>Total</b></td>
                          <td style="text-align:right;font-size:16px;">${numberFormat(getTotal(totalAmount, invoice.state_gst, invoice.central_gst))}</td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
    </div>
    <div style="border-right: 1px solid #2E64FE;border-bottom: 1px solid #2E64FE;">
      <table style="width: 97%;border-top: 1px solid #2E64FE;">
        <tr>
          <td style="width: 100%;">
            <table style="width: 100%;margin-top: -4px;margin-left: -1px;">
              <tr>
                <td style="width: 65%;padding-left: 2rem;">
                  <table style="width: 60%;">
                    <tr>
                      <td style="width: 35%;font-size: 12px;text-align: left;">Bank Name</td>
                      <td style="width: 65%;font-size: 12px;text-align: left;">- Canara Bank</td>
                    </tr>
                    <tr>
                      <td style="width: 35%;font-size: 12px;text-align: left;">Branch</td>
                      <td style="width: 65%;font-size: 12px;text-align: left;">- Nasik City</td>
                    </tr>
                    <tr>
                      <td style="width: 35%;font-size: 12px;text-align: left;">Account No.</td>
                      <td style="width: 65%;font-size: 12px;text-align: left;">- 120028207363</td>
                    </tr>
                    <tr>
                      <td style="width: 35%;font-size: 12px;text-align: left;">IFSC Code</td>
                      <td style="width: 65%;font-size: 12px;text-align: left;">- CNRB0000240</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table style="width: 100%;">
                    <tr>
                      <td style="width: 50%;font-size: 12px;text-align: right;">Your's Faithfully</td>
                    </tr>
                    <tr>
                      <td style="width: 50%;font-size: 12px;text-align: right;">for <span style="font-size: 18px;">ADITYA PRINTS</span></td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="width: 65%;padding-left: 2rem;">
                  <table style="width: 100%;" class="note_table">
                    <tr>
                      <td style="width: 50%;font-size: 12px;text-align: left;">
                        <ul>
                            <li>
                            If the payment of the bill is not received within 8 days overdue<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;interest @ 20% will be charged till the date of payment.
                            </li>
                            <li>Subject to Nasik Jurisdiction.</li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </td>
                <td style="font-size: 18px;text-align: right;">Proprietor</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="font-size: 10px;text-align: center; width:100%;padding-left: 1rem;"><b>${address} <br> E-mail: adityaprints@outlook.com</b></td>
        </tr>
      </table>
    </div>
  </body>
</html>`

}
