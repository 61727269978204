import React, { createContext, useContext, useState } from 'react';

// Create a Cache Context
const CacheContext = createContext();

// Create a provider component
export const CacheContextProvider = ({ children }) => {
    const [cacheItems, setCacheItems] = useState({}); // Initialize cache as an object

    return (
        <CacheContext.Provider value={{ cacheItems, setCacheItems }}>
            {children}
        </CacheContext.Provider>
    );
};

// Custom hook to use the Cache Context
export const useCache = () => {
    return useContext(CacheContext);
};