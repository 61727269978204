/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTooltip from "components/MDTooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCheckbox from 'components/MDCheckbox';
import DataTable from "examples/Tables/DataTable";
import { WorkOrderContext } from "context";

// @mui icons
import Icon from "@mui/material/Icon";
import WoPrintingItemService from "services/wo-printing-item-service";
import PrintingTypeService from "services/printing-type-service";
import PrintingMachinesService from "services/printing-machine-service";
import ColorServices from "services/color-services";
import CustomerRateService from "services/customer-rate-service";
import MDButton from "components/MDButton";
import MDCircularProgress from "components/MDCircularProgress";
import authPermission from "utility/auth-permissions";
import { useCache } from "context";

function Printing({customerId, paymentType, numberFormat, isCancel, onlyNumbers, colorData, colorScheme, doubleTheQuantity, noColorPrintingMachineId, blackColorId}) {

  const workOrderContext = useContext(WorkOrderContext);
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { hasPermission, systemConstant } = authPermission();
  const printingItem = workOrderContext.printingItem;
//   const [printingItem, setPrintingItem] = useState([]);
  const [printingTypeDropdown, setPrintingTypeDropdown] = useState([]);
  const [printingMachineDropdown, setPrintingMachineDropdown] = useState([]);
//   const [colorData, setColorData] = useState([]);
  const [customerRate, setCustomerRate] = useState([]);
  const [open, setOpen] = useState(false);
  const [printingTotal, setPrintingTotal] = useState(0);
  const [setsTotal, setSetsTotal] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [calculateChargeLoading, setCalculateChargeLoading] = useState(false);
  const { cacheItems, setCacheItems } = useCache();
//   const [colorScheme, setColorScheme] = useState('');

  useEffect(() => {
    const getWoPrintingItems = async() => {
        let total = 0;
        let sets = 0;
        printingItem?.map((eachItem) => {
            total = Number(total) + Number(eachItem?.total);
            sets = Number(sets) + Number(eachItem?.no_sets);
        });
        setPrintingTotal(total.toFixed(2));
        setSetsTotal(sets);
    }
    getWoPrintingItems();

    const getPrintingType = async() => {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('printing_types')) {
          response = cacheItems['printing_types'];
        } else {
          response = await PrintingTypeService.getWoPrintingTypes(); // Fetch customers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              printing_types: response,
          }));
        }
        
        if (response) {
          var dataArray = [];
          response?.map((eachData, index) => {
            dataArray[index] = {
              key: eachData.id,
              value: eachData.name,
              disabled: eachData?.active ? false : true,
            }
          });
          setPrintingTypeDropdown(dataArray);
        }
    }
    getPrintingType();

    const getPrintingMachine = async() => {
        let response;
        if (cacheItems.hasOwnProperty('printing_machines')) {
          response = cacheItems['printing_machines'];
        } else {
          response = await PrintingMachinesService.getPrintingMachines(); // Fetch customers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              printing_machines: response,
          }));
        }
        
        if (response) {
          var dataArray = [];
          response?.map((eachData, index) => {
            dataArray[index] = {
              key: eachData.id,
              value: eachData.name,
              disabled: eachData?.active ? false : true,
            }
          });
          setPrintingMachineDropdown(dataArray);
        }
    }
    getPrintingMachine();

    const getCustomerRate = async() => {
        let response = [];
        if (customerId) {
            response = await CustomerRateService.getCustomerRates(customerId);
        }
        const cloneAllRates = [...Object.entries(response)];
        let rateArray = [];
        cloneAllRates.map((eachData) => {
            rateArray[eachData[0]] = [];
            eachData[1]['rates'].map((eachRate, ind) => {
                rateArray[eachData[0]][ind] = {
                    quantity: eachRate.quantity,
                    cash_rate: eachRate.cash_rate,
                    credit_rate: eachRate.credit_rate,
                }
            });
        });
        setCustomerRate(rateArray);
    }
    getCustomerRate();
  }, []);

  const getPrintingRate = (rates, quantity, paymentType) => {
    if (rates.length > 0) {
        for (let i = 0; i < rates.length; i++) {
        if (quantity <= rates[i].quantity) {
            return paymentType == 'Cash' ? rates[i].cash_rate : rates[i].credit_rate;
        }
        }
        return paymentType == 'Cash' ? rates[rates.length -1].cash_rate : rates[rates.length - 1].credit_rate; // or return a default rate if no match found
    }
    return 0.00
  };

  const calculateTotal = (clonePrintingItem, index, rowTotal, rowSet, prevTotal, prevSet, woTotal) => {
    let diffTotal = Number(clonePrintingItem[index]['total']) - Number(prevTotal);
    let diffSet = Number(clonePrintingItem[index]['no_sets']) - Number(prevSet);
    rowTotal = Number(rowTotal) + Number(diffTotal);
    rowSet = Number(rowSet) + Number(diffSet);
    let newWoTotal = Number(woTotal) + Number(diffTotal);

    return {
        newWoTotal: newWoTotal,
        rowTotal: rowTotal,
        rowSet: rowSet,
    }
  }

  const onChangePrintingField = (index, fieldName, fieldValue, colorId = '') => {
    const clonePrintingItem = [...printingItem];
    let rowTotal = printingTotal;
    let rowSet = setsTotal;
    let prevTotal = clonePrintingItem[index]['total'];
    let prevSet = clonePrintingItem[index]['no_sets'];
    let woTotal = workOrderContext.woTotal;

    if (fieldName == 'color_scheme') {
        let colorScheme = clonePrintingItem[index][fieldName];
        let valueArray = colorScheme.split(',').map(Number);
        if (fieldValue === true) {
            valueArray.push(colorId);
        } else {
            let ind = valueArray.indexOf(colorId);
            valueArray.splice(ind, 1);
        }
        clonePrintingItem[index][fieldName] = valueArray.toString();
    } else {
        clonePrintingItem[index][fieldName] = fieldValue;
    }

    if (fieldName == 'wo_printing_type_id' && (fieldValue == '' || fieldValue == 0)){
        clonePrintingItem[index]['quantity'] = 0;
        clonePrintingItem[index]['no_sets'] = 0;
        clonePrintingItem[index]['markup_pct'] = 0;
        clonePrintingItem[index]['rate'] = 0;
        clonePrintingItem[index]['total'] = 0;
        clonePrintingItem[index]['amount'] = 0;
        clonePrintingItem[index]['free_amount'] = 0;
        clonePrintingItem[index]['waste_qty'] = 0;
    } else if (fieldName == 'quantity' && (fieldValue == '' || fieldValue == 0)) {
        clonePrintingItem[index]['markup_pct'] = '';
        clonePrintingItem[index]['rate'] = '';
        clonePrintingItem[index]['total'] = '';
        clonePrintingItem[index]['amount'] = '';
        clonePrintingItem[index]['free_amount'] = '';
    }

    if (fieldName == 'printing_machine_id') {
        if (noColorPrintingMachineId == clonePrintingItem[index]['printing_machine_id']) {
            clonePrintingItem[index]['color_scheme'] = blackColorId;
            clonePrintingItem[index]['is_show_color'] = false;
        } else {
            clonePrintingItem[index]['color_scheme'] = colorScheme;
            clonePrintingItem[index]['is_show_color'] = true;
        }
        var filterPrintingMachine = printingMachineDropdown?.filter(eachPrintingMachine => (eachPrintingMachine.key == fieldValue));
        if (clonePrintingItem[index]['print_machine'] && filterPrintingMachine?.length === 1) {
          clonePrintingItem[index]['print_machine']['id'] = fieldValue ? filterPrintingMachine[0]['key'] : '';
          clonePrintingItem[index]['print_machine']['name'] = fieldValue ? filterPrintingMachine[0]['value'] : '';
        } else if (clonePrintingItem[index]['print_machine']) {
          clonePrintingItem[index]['print_machine']['id'] = '';
          clonePrintingItem[index]['print_machine']['name'] = '';
        }
    }

    let colors = clonePrintingItem[index]['color_scheme'].split(',').map(Number);
    if (colors.includes(0)) {
        let ind = colors.indexOf(0);
        colors.splice(ind, 1);
    }
    let quantity = clonePrintingItem[index]['quantity'];
    let woPrintingTypeId = clonePrintingItem[index]['wo_printing_type_id'];
    let printingMachineId = clonePrintingItem[index]['printing_machine_id'];
    let set = clonePrintingItem[index]['no_sets'];

    if (colors.length > 0 && quantity > 0 && woPrintingTypeId > 0 && printingMachineId > 0 && set > 0) {
        if (doubleTheQuantity.includes(woPrintingTypeId)) {
            quantity = quantity * 2;
        }
        quantity = Math.ceil(quantity / 1000) * 1000;
        //if quantity is not found will use max quantity rate;
        let rate = 0.00;
        if (customerId) {
            rate = getPrintingRate(customerRate[printingMachineId], quantity, paymentType);
        }

        let amount = rate * set * colors.length;
        clonePrintingItem[index]['amount'] = amount.toFixed(2);
        amount = Number(amount) + Number((amount * clonePrintingItem[index]['markup_pct'])/100);
        let total = (amount > 0 ? Number(amount) : 0) + Number(clonePrintingItem[index]['free_amount']);
        clonePrintingItem[index]['printing_rate'] = rate;
        clonePrintingItem[index]['total'] = total > 0 ? total.toFixed(2) : 0;
    } else {
        let amount = 0;
        clonePrintingItem[index]['amount'] = amount.toFixed(2);
        amount = Number(amount) + Number((amount * clonePrintingItem[index]['markup_pct'])/100);
        let total = (amount > 0 ? Number(amount) : 0) + Number(clonePrintingItem[index]['free_amount']);
        clonePrintingItem[index]['printing_rate'] = 0.00;
        clonePrintingItem[index]['total'] = total > 0 ? total.toFixed(2) : 0;
    }

    const calculatedTotals = calculateTotal(clonePrintingItem, index, rowTotal, rowSet, prevTotal, prevSet, woTotal);
    workOrderContext.setWoAmount((calculatedTotals.newWoTotal).toFixed(2));

    setPrintingTotal(calculatedTotals.rowTotal.toFixed(2));
    setSetsTotal(calculatedTotals.rowSet);
    workOrderContext.setWoPrintingItem(clonePrintingItem);
  };

  const calculateCharge = async () => {
    setCalculateChargeLoading(true);
    const clonePrintingItem = [...printingItem];

    let rowTotal = printingTotal;
    let rowSet = setsTotal;
    let woTotal = workOrderContext.woTotal;

    let response = await CustomerRateService.getCustomerRates(customerId);
    const cloneAllRates = [...Object.entries(response)];
    let rateArray = [];
    cloneAllRates.map((eachData) => {
        rateArray[eachData[0]] = [];
        eachData[1]['rates'].map((eachRate, ind) => {
            rateArray[eachData[0]][ind] = {
                quantity: eachRate.quantity,
                cash_rate: eachRate.cash_rate,
                credit_rate: eachRate.credit_rate,
            }
        });
    });
    setCustomerRate(rateArray);

    clonePrintingItem.forEach(async(item, index) => {
        let prevTotal = clonePrintingItem[index]['total'];
        let prevSet = clonePrintingItem[index]['no_sets'];
        if (clonePrintingItem[index]['is_approved'] == 0) {
          let colors = clonePrintingItem[index]['color_scheme'].split(',').map(Number);
          if (colors.includes(0)) {
              let ind = colors.indexOf(0);
              colors.splice(ind, 1);
          }
          let quantity = clonePrintingItem[index]['quantity'];
          let woPrintingTypeId = clonePrintingItem[index]['wo_printing_type_id'];
          let printingMachineId = clonePrintingItem[index]['printing_machine_id'];
          let set = clonePrintingItem[index]['no_sets'];
          if (colors.length > 0 && quantity > 0 && woPrintingTypeId > 0 && printingMachineId > 0 && set > 0) {
              if (doubleTheQuantity.includes(woPrintingTypeId)) {
                  quantity = quantity * 2;
              }
              quantity = Math.ceil(quantity / 1000) * 1000;
              //if quantity is not found will use max quantity rate;
              let rate = 0.00;
              if (customerId) {
                  rate = getPrintingRate(rateArray[printingMachineId], quantity, paymentType);
              }
              let amount = rate * set * colors.length;
              clonePrintingItem[index]['amount'] = amount.toFixed(2);
              amount = Number(amount) + Number((amount * clonePrintingItem[index]['markup_pct'])/100);
              let total = (amount > 0 ? Number(amount) : 0) + Number(clonePrintingItem[index]['free_amount']);
              clonePrintingItem[index]['printing_rate'] = rate;
              clonePrintingItem[index]['total'] = total.toFixed(2);
          }
        }

        const calculatedTotals = calculateTotal(clonePrintingItem, index, rowTotal, rowSet, prevTotal, prevSet, woTotal);
        woTotal = calculatedTotals.newWoTotal;
        rowTotal = calculatedTotals.rowTotal;
        workOrderContext.setWoPrintingItem(clonePrintingItem);
    });

    workOrderContext.setWoAmount((woTotal).toFixed(2));
    setPrintingTotal(rowTotal.toFixed(2));
    setCalculateChargeLoading(false);
  }

  const ColorType = ({value, approved, index, colorCheckboxData}) => {
    let valueArray = value.split(',').map(Number);
    return (
        <MDBox display="flex">
        { colorCheckboxData?.map((color) => {
            let checked = valueArray.includes(color.id) ? true : false;
            if (valueArray.includes(color.id) || color.active) {
                return <MDBox bgColor={color.name} alignItem="center" sx={{width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}} mr={1}>
                  <MDBox bgColor={'#fff'} sx={{width: 14, height: 14,alignItems: 'center'}}>
                    <MDCheckbox
                        checked={checked}
                        onChange={(e) => onChangePrintingField(index, 'color_scheme', e.target.checked, color.id)}
                        disabled={approved ? true : false}
                        sx={{
                          marginTop: '-12px',
                          marginLeft: '-1px',
                        }}
                    />
                  </MDBox>
                </MDBox>
            }
        })
        }
    </MDBox>
    );
  };

  const rows = [];
  let set = 0;
  let totalCharge = 0;
  if (colorData.length > 0) {
    printingItem?.map((eachItem, index) => {
        set = Number(set) + Number(eachItem?.no_sets);
        totalCharge = Number(totalCharge) + Number(eachItem?.total);
        const cols = {
            narration: (
                <MDTooltip title={eachItem?.description}>
                <MDBox>
                <MDInput
                    type="text"
                    value={eachItem?.description}
                    onChange={(e) => onChangePrintingField(index, 'description', e.target.value)}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                    sx={{
                      width: '26rem',
                      "& .MuiInputBase-input": {
                        padding: "0.5rem",
                        fontSize: "13px",
                      }
                    }}
                />
                </MDBox>
                </MDTooltip>
                ),
            print_type: (
                <MDSelect
                    fullWidth
                    value={eachItem?.wo_printing_type_id}
                    onChange={(e) => onChangePrintingField(index, 'wo_printing_type_id', e.target.value)}
                    options={printingTypeDropdown}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                    sx={{
                      width: '4.5rem',
                      "& .MuiNativeSelect-select": {
                        padding: "0.5rem",
                        fontSize: "13px",
                      }
                    }}
                    height="40px"
                />),
            qty: (
                <MDBox display="flex">
                    <MDInput
                    type="text"
                    value={eachItem?.quantity}
                    onChange={(e) => {onlyNumbers(e.target.value, 'mediumInt') ? onChangePrintingField(index, 'quantity', e.target.value) : ''}}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                    sx={{
                      width: '4rem',
                      "& .MuiInputBase-input": {
                        padding: "0.5rem",
                        fontSize: "13px",
                      }
                    }}
                    />
                    <MDTypography p={1} display="block" variant="body2"
                    sx={{
                      "&.MuiTypography-root": {
                        fontSize: "13px",
                      }
                    }}
                  >
                    +
                    </MDTypography>
                    <MDInput
                    type="text"
                    value={eachItem?.waste_qty}
                    onChange={(e) => {onlyNumbers(e.target.value, 'mediumInt') ? onChangePrintingField(index, 'waste_qty', e.target.value) : ''}}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                    sx={{
                      width: '4rem',
                      "& .MuiInputBase-input": {
                        padding: "0.5rem",
                        fontSize: "13px",
                      }
                    }}
                    />
                </MDBox>
                ),
            printing_machine: (
                <MDTooltip title={eachItem?.print_machine?.name}>
                <MDBox>
                <MDSelect
                    fullWidth
                    value={eachItem?.printing_machine_id}
                    onChange={(e) => onChangePrintingField(index, 'printing_machine_id', e.target.value)}
                    options={printingMachineDropdown}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                    sx={{
                      width: 200,
                      "& .MuiNativeSelect-select": {
                        padding: "0.5rem",
                        fontSize: "13px",
                      }
                    }}
                    height="40px"
                />
                </MDBox>
                </MDTooltip>
                ),
            //color_types: (<ColorType value={eachItem?.color_scheme} approved={eachItem?.is_approved || isCancel} index={index} colorCheckboxData={colorData} />),
            sets: (
                <MDInput
                    type="text"
                    value={eachItem?.no_sets}
                    onChange={(e) => onChangePrintingField(index, 'no_sets', e.target.value)}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                    sx={{
                      width: '4rem',
                      "& .MuiInputBase-input": {
                        padding: "0.5rem",
                        fontSize: "13px",
                      }
                    }}
                />),
            markup_pct: (
                <MDInput
                    type="text"
                    value={eachItem?.markup_pct ? eachItem?.markup_pct : ''}
                    onChange={(e) => {onlyNumbers(e.target.value, 'percentage') ? onChangePrintingField(index, 'markup_pct', e.target.value) : ''}}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                    sx={{
                      width: '4.5rem',
                      "& .MuiInputBase-input": {
                        padding: "0.5rem",
                        fontSize: "13px",
                      }
                    }}
                />),
            free_amount: (
                <MDInput
                    type="text"
                    value={eachItem?.free_amount}
                    onChange={(e) => {onlyNumbers(e.target.value, '-decimal(10,2)') ? onChangePrintingField(index, 'free_amount', e.target.value) : ''}}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                    sx={{
                      width: '6rem',
                      "& .MuiInputBase-input": {
                        padding: "0.5rem",
                        fontSize: "13px",
                      }
                    }}
                />),
            printing_charges: (
                <MDTypography display="block" variant="body2" sx={{
                  "&.MuiTypography-root": {
                    fontSize: "14px",
                  }
                }}>
                    {(eachItem?.wo_printing_type_id && eachItem?.quantity && eachItem?.printing_machine_id && eachItem?.no_sets) ? numberFormat(eachItem?.total) : ''}
                </MDTypography>),
            lamination:(
                <MDCheckbox
                    checked={eachItem?.is_lamination}
                    onChange={(e) => onChangePrintingField(index, 'is_lamination', e.target.checked)}
                    disabled={eachItem?.is_approved || isCancel ? true : false}
                />),
        };
        rows.push(cols);
        if (eachItem?.is_show_color === true) {
            const colsS = {
                narration: (<MDTypography display="block" variant="body2"></MDTypography>),
                print_type: (<MDTypography display="block" variant="body2"></MDTypography>),
                qty: (<MDTypography display="block" variant="body2"></MDTypography>),
                printing_machine: (<ColorType value={eachItem?.color_scheme} approved={eachItem?.is_approved || isCancel} index={index} colorCheckboxData={colorData} />),
                sets: (<MDTypography display="block" variant="body2"></MDTypography>),
                markup_pct: (<MDTypography display="block" variant="body2"></MDTypography>),
                free_amount: (<MDTypography display="block" variant="body2"></MDTypography>),
                printing_charges: (<MDTypography display="block" variant="body2"></MDTypography>),
                lamination:(<MDTypography display="block" variant="body2"></MDTypography>),
            };
            rows.push(colsS);
        }
      });
      const lastRow = {
        narration: (
            <MDTypography display="block" variant="body2">
            </MDTypography>
            ),
        print_type: (
          <MDTypography display="block" variant="body2">
          </MDTypography>),
        qty: (
          <MDTypography display="block" variant="body2">
          </MDTypography>
            ),
        printing_machine: (
          <MDTypography display="block" variant="body2">
          </MDTypography>
            ),
        //color_types: (<ColorType value={eachItem?.color_scheme} approved={eachItem?.is_approved || isCancel} index={index} colorCheckboxData={colorData} />),
        sets: (
          <MDTypography display="block" variant="subtitle2" whiteSpace="nowrap"  sx={{fontWeight: "500"}}>
              #Sets : {set}
          </MDTypography>),
        markup_pct: (
          <MDTypography display="block" variant="body2">
          </MDTypography>),
        free_amount: (
          <MDTypography display="block" variant="subtitle2" whiteSpace="nowrap" sx={{fontWeight: "500"}}>
              Printing Charges :
          </MDTypography>),
        printing_charges: (
          <MDTypography display="block" variant="subtitle2" whiteSpace="nowrap" sx={{fontWeight: "500"}}>
            Rs.{numberFormat(totalCharge)}
          </MDTypography>),
        lamination:(
          <MDTypography display="block" variant="body2">
          </MDTypography>),
      };
      rows.push(lastRow);
  }

  const columns = [
    { Header: "Narration", accessor: "narration", width: "15%", align: "left" },
    { Header: "WO Print Type", accessor: "print_type", width: "6%", align: "center" },
    { Header: "Qty + Waste", accessor: "qty", width: "9%", align: "left" },
    { Header: "Printing Machine", accessor: "printing_machine", width: "10%", align: "left" },
    { Header: "#Sets", accessor: "sets", width: "6%", align: "center" },
    ...(hasPermission('wo_rates').view ? [{ Header: "Markup %", accessor: "markup_pct", width: "6%", align: "center" }] : []),
    ...(hasPermission('wo_rates').view ? [{ Header: "Free Amount", accessor: "free_amount", width: "5%", align: "center" }] : []),
    ...(hasPermission('wo_rates').view ? [{ Header: "Printing Charges", accessor: "printing_charges", width: "11%", align: "right" }] : []),
    { Header: "Lamination", accessor: "lamination", width: "5%", align: "right" },
    // { Header: "Color Type", accessor: "color_types", width: "20%", align: "left" },
  ];

  return (
    <Card sx={{borderRadius: "0.35rem"}}>
        <MDBox>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder={true}
            doubleClick={false}
            thBgColor="#a195952e"
            noRowBottomBorder={true}
            verticalRowPadding={0.2}
            horizontalRowPadding={0.2}
            tableBorderRadius={"0.35rem"}
            headerFontSize={"0.75rem"}
          />
        </MDBox>
        <MDBox mt={1} mb={1}>
            <Grid container>
                <Grid item xs={12} md={12}>
                  <MDButton
                    onClick={calculateCharge}
                    variant="gradient" color="dark" sx={{marginLeft: 1, width: "180px"}}
                    disabled={calculateChargeLoading}>
                    {calculateChargeLoading ? <MDCircularProgress
                        size={20}
                        sx={{
                          position: 'absolute',
                        }}
                      />: ('Calculate Charges')}
                  </MDButton>
                </Grid>
            </Grid>
        </MDBox>
      </Card>
  );
}

export default Printing;
