/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDButton
import MDAccordionRoot from "components/MDAccordion/MDAccordionRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDAccordion = forwardRef(
  ({ accordionTitle,
     defaultExpanded,
     disabled,
     disableGutters,
     summarySx,
     detailSx,
     children,
     ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    return (
      <MDAccordionRoot
        {...rest}
        defaultExpanded={defaultExpanded}
        disabled={disabled}
        disableGutters={disableGutters}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={summarySx}
        >
          {accordionTitle}
        </AccordionSummary>
        <AccordionDetails
          sx={detailSx}
        >
          {children}
        </AccordionDetails>
      </MDAccordionRoot>
    );
  }
);

// Setting default values for the props of MDAlert
MDAccordion.defaultProps = {
  defaultExpanded: false,
  disabled: false,
  disableGutters: false
};

export default MDAccordion;
