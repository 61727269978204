/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";


// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDButton
import MDCircularProgressRoot from "components/MDCircularProgress/MDCircularProgressRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDCircularProgress = forwardRef(
  ({color, ...rest }, ref) => {
    const [controller] = useMaterialUIController();

    return (
        <MDCircularProgressRoot
            color={color ? color : "light"}
            {...rest}
            >   
        </MDCircularProgressRoot>
    );
  }
);

// Setting default values for the props of MDButton
MDCircularProgress.defaultProps = {
};

export default MDCircularProgress;
