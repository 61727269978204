/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportService from "services/report-service";
import SuppliersService from "services/supplier-service";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
import ItemService from "services/item-service";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const grnPermission = hasPermission('/manage-grn');
    const poPermission = hasPermission('/manage-purchase-orders');
    const [suppliersDropdown, setSuppliersDropdown] = useState([]);
    const [itemsDropdown, setItemsDropdown] = useState([]);
    const [itemWiseSupplierWiseGrn, setItemWiseSupplierWiseGrn] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [supplierId, setSupplierId] = useState('');
    const [itemId, setItemId] = useState('');
    const [startDate, setStartDate] = useState((dayjs(new Date()).subtract(1, 'month')));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [filter, setFilter] = useState('');
    const { cacheItems, setCacheItems } = useCache();
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getItemWiseSupplierWiseGrn = async(supplierId = '', itemId = '', startDate = '', endDate = '') => {
        const data = await ReportService.getItemWiseSupplierWiseGrn(supplierId, itemId, startDate, endDate);
        setItemWiseSupplierWiseGrn(Object.values(data));
    }

    useEffect(() => {
        const getSuppliersData = async() => {
            let response;
            if (cacheItems.hasOwnProperty('suppliers')) {
                response = cacheItems['suppliers'];
            } else {
                response = await SuppliersService.getSuppliers(); // Fetch items from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    suppliers: response,
                }));
            }

            if (response) {
              var supplierArray = [];
              response?.map((eachSupplier, index) => {
                if(eachSupplier?.active) {
                    supplierArray[index] = {
                        key: eachSupplier?.id,
                        value: eachSupplier?.name,
                        disabled: eachSupplier?.active ? false : true,
                    }
                }
              });
              setSuppliersDropdown(supplierArray);
            }
        }
        getSuppliersData();
        const getItemsData = async() => {
            let response;
            if (cacheItems.hasOwnProperty('items')) {
                response = cacheItems['items'];
            } else {
                response = await ItemService.getItems(); // Fetch items from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    items: response,
                }));
            }

            if (response) {
                var itemsArray = [];
                response?.map((eachItem, index) => {
                    if(eachItem?.active) {
                        itemsArray[index] = {
                            key: eachItem?.id,
                            value: eachItem?.description,
                            disabled: eachItem?.active ? false : true,
                        }
                    }
                });
                setItemsDropdown(itemsArray);
            }
          }
          getItemsData();
        getItemWiseSupplierWiseGrn(supplierId, itemId, dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '', dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '');
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
        const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
        getItemWiseSupplierWiseGrn(supplierId, itemId, sDate, eDate);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setSupplierId('');
        setItemId('');
        setStartDate('');
        setEndDate('');
        setFilter(null);
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data) => {
        setExcelLoading(true);
        const fileName = 'item_wise_supplier_wise_grn_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Item Wise Supplier Wise GRN']);
        //Merge cells for supplier row
        worksheet.mergeCells(`A1:G1`);
        let k = 2;
        data.forEach((eachItem, index) => {
            const suppliersGrn = eachItem?.suppliers;
            const itemRow = 'Item : ' + eachItem?.item;
            const cellRowItem = worksheet.addRow([itemRow]);
            cellRowItem.getCell(1).fill = {
                type: 'pattern',
                name: 'sans-serif',
                pattern: 'solid',
                fgColor: { argb: 'f8ece0' },
            };
            for (var i=1; i<11; i++) {
                cellRowItem.getCell(i).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
            // // Merge cells for supplier row
            // worksheet.mergeCells(`A${k}:G${k}`);
            k += 1;
            console.log(eachItem?.suppliers);
            const supplierName = suppliersGrn['supplier'];
            const supplierQuantity = suppliersGrn['total_quantity'];
            // Add headers
            const headerRow = worksheet.addRow(['Supplier', 'PO No','GRN No', 'Date', 'Bill No', 'Quantity', 'Size', 'GSM']);
            // Set the width for each column
            worksheet.columns = [
                { width: 60 }, 
                { width: 10 }, 
                { width: 10 },
                { width: 20 },
                { width: 10 }, 
                { width: 10 },
                { width: 20 },
                { width: 10 },
                { width: 10 },
                { width: 10 },
            ];
            headerRow.font = { bold: true, name: 'sans-serif' };
            for (var i=1; i<11; i++) {
                //Add border
                headerRow.getCell(i).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
            k += 1;
            let rowInd = 0;
            suppliersGrn['items']?.map((row) => {
                let rowColor = '#effbfb';
                rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                const cellRow = worksheet.addRow([(rowInd == 0 ? supplierName :''), row?.purchase_order_id, row?.grn_id, row?.date, row?.bill_no, row?.quantity, row?.size, row?.gsm, (suppliersGrn['items'].length == rowInd + 1 ? 'Total' : ''), (suppliersGrn['items'].length == rowInd + 1 ? supplierQuantity : '')]);
                rowColor = rowColor == 'effbfb' ? 'e0f8f7' : 'effbfb';
                cellRow.getCell(5).alignment = { horizontal: 'right' };
                cellRow.getCell(7).alignment = { horizontal: 'right' };
                cellRow.getCell(8).alignment = { horizontal: 'right' };
                for (var i = 1; i < 11; i++) {
                    if (i<9) {
                        cellRow.getCell(i).fill = {
                            type: 'pattern',
                            name: 'sans-serif',
                            pattern: 'solid',
                            fgColor: { argb: i>1? rowColor : (rowInd == 0 ? 'f8ece0' : 'FFFFFF') },
                        };
                    }
                    // Add border to the row
                    cellRow.getCell(i).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                }
                cellRow.getCell(9).font = { bold: true, name: 'sans-serif' };
                cellRow.getCell(10).font = { bold: true, name: 'sans-serif' };
                k += 1;
                rowInd += 1;
            });
            // // Add data
            // Object.entries(suppliersGrn).map(([key, value]) => {
            //     if (key === 'supplier') {
            //         const supplierRow = 'Supplier : ' + value;
            //         const cellRowSupplier = worksheet.addRow([supplierRow]);
            //         cellRowSupplier.getCell(1).fill = {
            //             type: 'pattern',
            //             name: 'sans-serif',
            //             pattern: 'solid',
            //             fgColor: { argb: 'f8ece0' },
            //         };
            //         cellRowSupplier.border = {
            //             top: { style: 'thin' },
            //             left: { style: 'thin' },
            //             bottom: { style: 'thin' },
            //             right: { style: 'thin' }
            //         };
            //         // Merge cells for supplier row
            //         worksheet.mergeCells(`A${k}:G${k}`);
            //         k += 1;
            //     } else if (key === 'items') {
            //         // Add headers
            //         const headerRow = worksheet.addRow(['PO No','GRN No', 'Date', 'Bill No', 'Quantity', 'Size', 'GSM']);
            //         // Set the width for each column
            //         worksheet.columns = [
            //             { width: 10 }, 
            //             { width: 10 },
            //             { width: 20 },
            //             { width: 10 }, 
            //             { width: 10 },
            //             { width: 20 },
            //             { width: 10 },
            //         ];
            //         headerRow.font = { bold: true, name: 'sans-serif' };
            //         //Add border
            //         headerRow.border = {
            //             top: { style: 'thin' },
            //             left: { style: 'thin' },
            //             bottom: { style: 'thin' },
            //             right: { style: 'thin' }
            //         };
            //         k += 1;

            //         value.map((row) => {
            //             let rowColor = '#effbfb';
            //             rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
            //             const cellRow = worksheet.addRow([row?.purchase_order_id, row?.grn_id, row?.date, row?.bill_no, row?.quantity, row?.size, row?.gsm]);
            //             rowColor = rowColor == 'effbfb' ? 'e0f8f7' : 'effbfb';
            //             cellRow.getCell(4).alignment = { horizontal: 'right' };
            //             cellRow.getCell(6).alignment = { horizontal: 'right' };
            //             cellRow.getCell(7).alignment = { horizontal: 'right' };
            //             for (var i = 1; i < 8; i++) {
            //                 cellRow.getCell(i).fill = {
            //                     type: 'pattern',
            //                     name: 'sans-serif',
            //                     pattern: 'solid',
            //                     fgColor: { argb: rowColor },
            //                 };
            //             }
            //             // Add border to the row
            //             cellRow.border = {
            //                 top: { style: 'thin' },
            //                 left: { style: 'thin' },
            //                 bottom: { style: 'thin' },
            //                 right: { style: 'thin' }
            //             };
            //             k += 1;
            //         });
            //     }  else if (key === 'total_quantity') {
            //         const rowTotal = worksheet.addRow(['Total', '', '', '', value, '', '']);
            //         // Merge cells for supplier row
            //         worksheet.mergeCells(`A${k}:D${k}`);
            //         worksheet.mergeCells(`F${k}:G${k}`);
            //         rowTotal.getCell(1).alignment = { horizontal: 'right' };
            //         rowTotal.font = { bold: true, name: 'sans-serif' };
            //         rowTotal.border = {
            //             top: { style: 'thin' },
            //             left: { style: 'thin' },
            //             bottom: { style: 'thin' },
            //             right: { style: 'thin' }
            //         };
            //         k += 1;
            //     }
            // });
        });
      
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(itemWiseSupplierWiseGrn){
            const pdfTemplate = PDFTemplate(itemWiseSupplierWiseGrn, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`${pdfTemplate}`);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Item Wise Supplier Wise GRN'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Item Wise Supplier Wise GRN'}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                    <Grid item xs={12} lg={minWidthD ? 3 : 2.5} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Supplier"
                                                fullWidth
                                                name="supplier_id"
                                                value={supplierId}
                                                onChange={(e) => setSupplierId(e.target.value != null ? e.target.value : '')}
                                                options={suppliersDropdown}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={minWidthD ? 2 : 1.5} px={1} md={6}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Item"
                                                fullWidth
                                                name="item_id"
                                                value={itemId}
                                                onChange={(e) => setItemId(e.target.value != null ? e.target.value : '')}
                                                options={itemsDropdown}
                                            />
                                        </MDBox>
                                     </Grid>
                                     <Grid item lg={5} xs={12} px={1} md={8}>
                                        <MDBox mb={1}>
                                            <MDDateRangePicker
                                                label="Start Date"
                                                name="start_date"
                                                value={startDate ? startDate : null}
                                                format="DD-MM-YYYY"
                                                onChange={(newValue) => {
                                                    setDate(newValue, 'start_date');
                                                }}
                                                endLabel="End Date"
                                                endName="end_date"
                                                endValue={endDate ? endDate : null}
                                                endOnChange={(newValue) => {
                                                    setDate(newValue, 'end_date');
                                                }}
                                                onFilterChange={onFilterChange}
                                                filter={filter}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {itemWiseSupplierWiseGrn.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(itemWiseSupplierWiseGrn)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        {itemWiseSupplierWiseGrn?.map((eachItem) => {
                                            const suppliersGrn = eachItem?.suppliers;
                                            return (
                                                <>
                                                    <TableRow sx={{ backgroundColor: '#f8ece0' }}>
                                                        <TableCell colSpan={7} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Item :</b> {eachItem?.item}</TableCell>
                                                    </TableRow>
                                                    {Object.entries(suppliersGrn).map(([key, value]) => {
                                                        if (key === 'supplier') {
                                                            return (
                                                              <TableRow sx={{ backgroundColor: '#f8ece0' }}>
                                                                <TableCell colSpan={7} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Supplier :</b> {value}</TableCell>
                                                              </TableRow>
                                                            )
                                                        } else if (key === 'items') {
                                                            return (
                                                                <>
                                                                    <TableRow>
                                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>PO No</TableCell>
                                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>GRN No</TableCell>
                                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Date</TableCell>
                                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Bill No</TableCell>
                                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Quantity</TableCell>
                                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Size</TableCell>
                                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', width: '20%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>GSM</TableCell>
                                                                    </TableRow>
                                                                  {value.map((item) => {
                                                                    let rowColor = '#effbfb';
                                                                    rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                                                                    return (
                                                                      <TableRow sx={{ backgroundColor: rowColor }}>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: 'info.main', textAlign: 'right'}} component={Link} to={poPermission.edit ? `/manage-purchase-orders/edit?id=${item?.purchase_order_id}&tab=1&close_window=1` : ``} target={'_blank'}>{item?.purchase_order_id}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: 'info.main', textAlign: 'right'}} component={Link} to={grnPermission.edit ? `/manage-grn/edit?id=${item?.grn_id}&tab=1&close_window=1` : ``} target={'_blank'}>{item?.grn_id}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.date}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '10%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.bill_no}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.quantity}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.size}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', width: '20%', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.gsm}</TableCell>
                                                                      </TableRow>
                                                                    )})}
                                                                </>
                                                            )
                                                        } else if (key === 'total_quantity') {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell colSpan={4} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>Total</TableCell>
                                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}>{value}</TableCell>
                                                                    <TableCell colSpan={2} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #ccc'}}></TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )
                                        })}
                                        {itemWiseSupplierWiseGrn.length == 0 && ( 
                                            <TableRow>
                                                <TableCell colSpan={7} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
