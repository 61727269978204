/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useState } from "react";

//Work Order Context
export const WorkOrderContext = createContext({
    materialItem: [],
    printingItem: [],
    stockItem: [],
    otherItem: [],
    payment: [],
    woTotal: 0,
    otherData: [],
    stageTotals: [],
    isUseCustomerBalance: false,
    itemStockData: [],
    setWoMaterialItem: () => {},
    setWoPrintingItem: () => {},
    setWoStockItem: () => {},
    setWoOtherItem: () => {},
    setWoPaymentItem: () => {},
    setWoAmount: () => {},
    setItemOtherData: () => {},
    setMaterialTotal: () => {},
    setWoUseCustomerBalance: () => {},
    setWoItemStockData: () => {},
  });
  
  const WorkOrderContextProvider = ({ children }) => {
    const [materialItem, setMaterialItem] = useState([]);
    const [printingItem, setPrintingItem] = useState([]);
    const [stockItem, setStockItem] = useState([]);
    const [otherItem, setOtherItem] = useState([]);
    const [payment, setPayment] = useState([]);
    const [woTotal, setWoTotal] = useState(0);
    const [otherData, setOtherData] = useState([]);
    const [stageTotals, setStageTotals] = useState([]);
    const [isUseCustomerBalance, setIsUseCustomerBalance] = useState(false);
    const [itemStockData, setItemStockData] = useState([]);
  
    const setWoMaterialItem = async(items) => {
      setMaterialItem(items);
    };
  
    const setWoPrintingItem = (items) => {
      setPrintingItem(items);
    };
  
    const setWoStockItem = (items) => {
      setStockItem(items);
    };
  
    const setWoOtherItem = (items) => {
      setOtherItem(items);
    };
  
    const setWoPayment = (items) => {
      setPayment(items);
    };

    const setWoAmount = (amount) => {
      setWoTotal(amount);
    };
    
    const setItemOtherData = (data) => {
      setOtherData(data);
    }

    const setMaterialTotal = (array) => {
      setStageTotals(array);
    }

    const setWoUseCustomerBalance = (value) => {
      setIsUseCustomerBalance(value);
    }

    const setWoItemStockData = (array) => {
      setItemStockData(array);
    }
  
    return (
      <WorkOrderContext.Provider value={{ materialItem, printingItem, stockItem, otherItem, payment, woTotal, otherData, stageTotals, isUseCustomerBalance, itemStockData, setWoMaterialItem, setWoPrintingItem, setWoStockItem, setWoOtherItem, setWoPayment, setWoAmount, setItemOtherData, setMaterialTotal, setWoUseCustomerBalance, setWoItemStockData }}>
        {children}
      </WorkOrderContext.Provider>
    );
  };

  export {
    WorkOrderContextProvider
  }