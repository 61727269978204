import dayjs from 'dayjs';

export const PDFTemplate = (purchaseRegister, numberFormat) => {
    return  `<html>
  <head>
    <title>purchase_register_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Purchase Order Register</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 6%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">PO#</th>
                <th style="background-color: #dddddd; width: 14%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Date</th>
                <th style="background-color: #dddddd; width: 6%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Status</th>
                <th style="background-color: #dddddd; width: 40%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Item</th>
                <th style="background-color: #dddddd; width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Size</th>
                <th style="background-color: #dddddd; width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">GSM</th>
                <th style="background-color: #dddddd; width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Rate</th>
                <th style="background-color: #dddddd; width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Quantity</th>
                <th style="background-color: #dddddd; width: 8%; font-size: 0.875rem; border-bottom: 1px solid #000;">Amount</th>
            </tr>
            ${purchaseRegister?.map((eachItem, index) => {
                let i = 0;
                let rowColor = '#e0f8f7';
                let po = '';
                return `
                    <tr>
                        <td colspan="7" style="border-right: 1px solid #000; border-bottom: 1px solid #000;"><b>Supplier : </b>${eachItem?.supplier_name}</td>
                        <td colspan="2" style="border-bottom: 1px solid #000;"></td>
                    </tr>
                    ${eachItem?.poItems?.map((item) => {
                        if (po != item?.purchase_order_id){
                            rowColor = i % 2 === 0 ? '#e0f8f7' : '#effbfb';
                            i += 1; 
                            po = item?.purchase_order_id;
                        }
                        return `
                            <tr style="background-color: ${rowColor}">
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.purchase_order_id}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${dayjs(item?.submitted_date).format('DD-MMM-YYYY')}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.status}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.items}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.size_id ? item?.sizes : ''}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right">${numberFormat(item?.gsm)}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right">${item?.rate}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right">${item?.quantity * 1}</td>
                                <td style="font-size: 0.775rem; text-align: right;border-bottom: 1px solid #000;">${numberFormat(item?.amount)}</td>
                            </tr>
                        `
                    }). join('')}
                    <tr>
                        <td colspan="8" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right; border-bottom: ${(index + 1) != purchaseRegister?.length ? '1px solid #000' : ''};"><b>Total</b></td>
                        <td style="font-size: 0.875rem; text-align: right; border-bottom: ${(index + 1) != purchaseRegister?.length ? '1px solid #000' : ''};">${numberFormat(eachItem?.total)}</td>
                    </tr>
                `
            }).join('')}        
        </table>
      </center>
    </div>
  </body>
</html>`

}
