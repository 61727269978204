/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import dayjs from 'dayjs';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PrintingRatesService from "services/printing-rate-service";
import PrintingMachinesService from "services/printing-machine-service";
import { useCache } from "context";

// @mui icons
import Icon from "@mui/material/Icon";

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const location = useLocation();
  const [printingMachinesOptions, setPrintingMachinesOptions] = useState([]);
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const { cacheItems, setCacheItems } = useCache();

  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();

  const [inputs, setInputs] = useState({
    printing_machine_id: "",
    quantity: "",
    cash_rate: "",
    credit_rate: "",
    created_at: "",
  });

  const [errors, setErrors] = useState({
    printingMachineIdError: false,
    quantityError: false,
    cashRateError: false,
    creditRateError: false,
  });

  useEffect(() => {
    const getPrintingMachinesData = async() => {
      try {
        let response;
        if (cacheItems.hasOwnProperty('printing_machines')) {
          response = cacheItems['printing_machines'];
        } else {
          response = await PrintingMachinesService.getPrintingMachines(); // Fetch printing machines from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              printing_machines: response,
          }));
        }
        if (response) {
          var array = [];
          response?.map((eachPrintingMachine, index) => {
              array[index] = {
                key: eachPrintingMachine.id,
                value: eachPrintingMachine.name,
                disabled: eachPrintingMachine?.active ? false : true,
              }
          });
          setPrintingMachinesOptions(array);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Printing Machine'};
          navigate('/manage-printing-rates', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Printing Machine'};
          navigate('/manage-printing-rates', {state: state});
        }
      }
    }
    getPrintingMachinesData();
    if (action === "add" && id == null) return;

    const showPrintingRate = async() => {
      try {
        const data = await PrintingRatesService.showPrintingRate(id);
        setHeaderTitle(data.printing_machine?.name);
        setCreatedBy(data.user.first_name + (data.user.middle_name ? (" " + data.user.middle_name) : "") + " " + data.user.last_name);
        data.cash_rate = Number(data.cash_rate).toFixed(2);
        data.credit_rate = Number(data.credit_rate).toFixed(2);
        setInputs(data);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Printing Rate'};
          navigate('/manage-printing-rates', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Printing Rate'};
          navigate('/manage-printing-rates', {state: state});
        }
      }
    }
    showPrintingRate();
  }, []);

  const changeHandler = (e) => {
    if (e.target.name == 'quantity') {
      setErrors({
        quantityError: false,
      });
    }
    if (e.target.name == 'cash_rate') {
      setErrors({
        cashRateError: false,
      });
    }
    if (e.target.name == 'credit_rate') {
      setErrors({
        creditRateError: false,
      });
    }
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    const quantityFormat = /^([0-9]*)$/;
    if (!inputs.quantity.toString().trim().match(quantityFormat)) {
      setErrors({ ...errors, quantityError: true });
      return;
    }
    const decimalFormat = /^([0-9]{1,8}|[0-9]{1,8}\.[0-9]{1,2})$/;
    if (!inputs.cash_rate.trim().match(decimalFormat)) {
      setErrors({ ...errors, cashRateError: true });
      return;
    }
    if (!inputs.credit_rate.trim().match(decimalFormat)) {
      setErrors({ ...errors, creditRateError: true });
      return;
    }

    const newPrintingRate = { printing_machine_id: inputs.printing_machine_id, quantity: inputs.quantity, cash_rate: inputs.cash_rate, credit_rate: inputs.credit_rate};

    if(!newPrintingRate.printing_machine_id){
      openAlertSB({color: 'warning', icon: 'star', msg: "Printing machine is mandatory", title: 'Printing Rate'});
      setLoading(false);
    } else {
      if (action === 'add') {
        try {
          const response = await PrintingRatesService.addPrintingRate(newPrintingRate);
          setLoading(false);
          if (response) {
            const state = {color: 'success', icon: 'check', msg: 'Printing Rate Added Successfully!', title: 'Printing Rate'};
            navigate('/manage-printing-rates', { state: state });
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Printing Rate'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Printing Rate'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      } else {
        try {
          const response = await PrintingRatesService.updatePrintingRate(id ,newPrintingRate);
          setLoading(false);
          if (response) {
            openAlertSB({color: 'success', icon: 'check', msg: 'Printing Rate Updated Successfully!', title: 'Printing Rate'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Printing Rate'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Printing Rate'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      }
    }

    return () => {
      setInputs({
        printing_machine_id: "",
        quantity: "",
        cash_rate: "",
        credit_rate: "",
      });

      setErrors({
        printingMachineIdError: false,
        quantityError: false,
        cashRateError: false,
        creditRateError: false,
      });
    };
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add Printing Rate' : ('Edit Printing Rate : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.75rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <MDTypography variant="h6" color="white">
                    {action === 'add' ? 'Add Printing Rate' : ('Edit Printing Rate : ' + headerTitle)}
                  </MDTypography>
                </MDBox>
                <MDBox py={3}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                      <Grid item xs={12} md={6} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                            label="Printing Machine"
                            fullWidth
                            name="printing_machine_id"
                            value={inputs.printing_machine_id}
                            onChange={changeHandler}
                            options={printingMachinesOptions}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Quantity"
                            fullWidth
                            value={inputs.quantity}
                            name="quantity"
                            inputProps={{'maxLength':10}}
                            onChange={changeHandler}
                            error={errors.quantityError}
                            helperText={errors.quantityError ? "Quantity must be a valid number." : ""}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Cash Rate"
                            fullWidth
                            value={inputs.cash_rate}
                            name="cash_rate"
                            inputProps={{'maxLength':11}}
                            onChange={changeHandler}
                            error={errors.cashRateError}
                            helperText={errors.cashRateError ? "Please enter a valid number. It cannot have more than 8 digits before the decimal point and more than 2 digits after the decimal point." : ""}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Credit Rate"
                            fullWidth
                            value={inputs.credit_rate}
                            name="credit_rate"
                            inputProps={{'maxLength':11}}
                            onChange={changeHandler}
                            error={errors.creditRateError}
                            helperText={errors.creditRateError ? "Please enter a valid number. It cannot have more than 8 digits before the decimal point and more than 2 digits after the decimal point." : ""}
                            required
                          />
                        </MDBox>
                      </Grid>

                      {action === 'edit' &&
                        <>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                name=""
                                value={createdBy}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={dayjs(inputs.created_at).format('DD-MM-YYYY HH:mm A')}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      }

                      <Grid item md={10} xs={8}></Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={2}>
                          <MDButton component={Link} to="/manage-printing-rates" variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
