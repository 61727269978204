import dayjs from 'dayjs';

export const PDFTemplate = (dayWiseCosting, grandTotal, numberFormat) => {
    return  `<html>
  <head>
    <title>day_wise_costing_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Daywise Costing</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 60%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Item</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Quantity</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Rate</th>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-bottom: 1px solid #000;">Amount</th>
            </tr>
            ${dayWiseCosting?.map((eachItem, index) => {
                let rowColor = '#effbfb';
                return `
                    <tr style="background-color: #f8ece0;">
                        <td colspan="4" style="font-size: 0.775rem;border-bottom: 1px solid #000;"><b>Work Order#:</b> ${eachItem?.id}&nbsp;&nbsp;&nbsp;&nbsp;<b>Date: </b>${eachItem?.release_date}&nbsp;&nbsp;&nbsp;&nbsp;<b>Party: </b>${eachItem?.customer}&nbsp;&nbsp;&nbsp;&nbsp;<b>Job Type: </b>${eachItem?.job_type}&nbsp;&nbsp;&nbsp;&nbsp;<b>Delivery Date: </b>${eachItem?.delivery_date}</td>
                    </tr>
                    ${eachItem?.items?.map((item) => {
                        rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                        return `
                            <tr style="background-color: ${rowColor}">
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.item}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.quantity}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${item?.rate}</td>
                                <td style="font-size: 0.775rem; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.amount)}</td>
                            </tr>
                        `
                    }). join('')}
                    ${dayWiseCosting.length != (index + 1) ? `<tr>
                        <td colspan="4"style="font-size: 0.875rem; text-align: right;">&nbsp;</td>
                    </tr>` : ``}
                `
            }).join('')}
            <tr>
                <td colspan="3" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Total:</b></td>
                <td style="font-size: 0.875rem; text-align: right;">${numberFormat(grandTotal)}</td>
            </tr>        
        </table>
      </center>
    </div>
  </body>
</html>`

}
