/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useState } from "react";
import dayjs from 'dayjs';

//Preserve Table Search, Sort and Entries per page Context
export const PreserveTable = createContext({
    customer: {},
    supplier: {},
    group: {},
    supplierItem: {},
    size: {},
    unit: {},
    color: {},
    paymentType: {},
    printingMachine: {},
    printingRate: {},
    woPrintingType: {},
    item: {},
    status: {},
    parameter: {},
    hsnCode: {},
    user: {},
    permission: {},
    workOrder: {},
    operatorCompletedScheduleWorkOrder: {},
    adminNewProgressScheduleWorkOrder: {},
    adminCompletedScheduleWorkOrder: {},
    purchaseOrder: {},
    grn: {},
    invoice: {},
    customerPayment: {},
    customerInventory: {},
    setPreserveCustomer: () => {},
    setPreserveSupplier: () => {},
    setPreserveGroup: () => {},
    setPreserveSupplierItem: () => {},
    setPreserveSize: () => {},
    setPreserveUnit: () => {},
    setPreserveColor: () => {},
    setPreservePaymentType: () => {},
    setPreservePrintingMachine: () => {},
    setPreservePrintingRate: () => {},
    setPreserveWoPrintingType: () => {},
    setPreserveItem: () => {},
    setPreserveStatus: () => {},
    setPreserveParameter: () => {},
    setPreserveHsnCode: () => {},
    setPreserveUser: () => {},
    setPreservePermission: () => {},
    setPreserveWorkOrder: () => {},
    setPreserveOperatorCompletedScheduleWorkOrder: () => {},
    setPreserveAdminNewProgressScheduleWorkOrder: () => {},
    setPreserveAdminCompletedScheduleWorkOrder: () => {},
    setPreservePurchaseOrder: () => {},
    setPreserveGrn: () => {},
    setPreserveInvoice: () => {},
    setPreserveCustomerPayment: () => {},
    setPreserveCustomerInventory: () => {},
    reset: () => {},
  });

  const PreserveTableProvider = ({ children }) => {
    const [customer, setCustomer] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [supplier, setSupplier] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [group, setGroup] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [supplierItem, setSupplierItem] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "suppliers.name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [size, setSize] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "sizes.name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [unit, setUnit] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [color, setColor] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [paymentType, setPaymentType] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [printingMachine, setPrintingMachine] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [printingRate, setPrintingRate] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "printing_machines.name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [woPrintingType, setWoPrintingType] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [item, setItem] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "items.description",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [status, setStatus] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [parameter, setParameter] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [hsnCode, setHsnCode] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [user, setUser] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "first_name",
      "sort_column_value": "asc",
      "search_value": ""
    });
    const [permission, setPermission] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "desc",
      "search_value": ""
    });
    const [workOrder, setWorkOrder] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "desc",
      "search_value": "",
      "wo_id_filter": '',
      "date_filter": "",
      "customer_id_filter": "",
      "job_type_filter": "",
      "status_id_filter": "",
      "show_filter": false,
    });
    const [operatorCompletedScheduleWorkOrder, setOperatorCompletedScheduleWorkOrder] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "desc",
      "search_value": ""
    });
    const [adminNewProgressScheduleWorkOrder, setAdminNewProgressScheduleWorkOrder] = useState({
      "newProgressWoId": "",
      "newProgressCustomer": "",
      "newProgressSize": "",
      "newProgressOperator": "",
      "newProgressCreatedDate": "",
    });
    const [adminCompletedScheduleWorkOrder, setAdminCompletedScheduleWorkOrder] = useState({
      "completedWoId": "",
      "completedCustomer": "",
      "completedSize": "",
      "completedOperator": "",
      "completedCompletionDate": "",
      "completedCreatedDate": "",
      "completedStartedDate": dayjs(new Date).format('YYYY-MM-DD'),
    });
    const [purchaseOrder, setPurchaseOrder] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "desc",
      "search_value": ""
    });
    const [grn, setGrn] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "desc",
      "search_value": ""
    });

    const [invoice, setInvoice] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "desc",
      "search_value": ""
    });

    const [customerPayment, setCustomerPayment] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "desc",
      "search_value": ""
    });

    const [customerInventory, setCustomerInventory] = useState({
      "page_no": 1,
      "page_size": 10,
      "sort_column": "id",
      "sort_column_value": "desc",
      "search_value": ""
    });

    const setPreserveCustomer = async(customer) => {
        setCustomer(customer);
    };
    const setPreserveSupplier = async(supplier) => {
        setSupplier(supplier);
    };
    const setPreserveGroup = async(group) => {
        setGroup(group);
    };
    const setPreserveSupplierItem = async(supplierItem) => {
        setSupplierItem(supplierItem);
    };
    const setPreserveSize = async(size) => {
        setSize(size);
    };
    const setPreserveUnit = async(unit) => {
        setUnit(unit);
    };
    const setPreserveColor = async(color) => {
        setColor(color);
    };
    const setPreservePaymentType = async(paymentType) => {
        setPaymentType(paymentType);
    };
    const setPreservePrintingMachine = async(printingMachine) => {
        setPrintingMachine(printingMachine);
    };
    const setPreservePrintingRate = (printingRate) => {
        setPrintingRate(printingRate);
    };
    const setPreserveWoPrintingType = async(woPrintingType) => {
        setWoPrintingType(woPrintingType);
    };
    const setPreserveItem = async(item) => {
        setItem(item);
    };
    const setPreserveStatus = async(status) => {
        setStatus(status);
    };
    const setPreserveParameter = async(parameter) => {
        setParameter(parameter);
    };
    const setPreserveHsnCode = async(hsnCode) => {
        setHsnCode(hsnCode);
    };
    const setPreserveUser = async(user) => {
        setUser(user);
    };
    const setPreservePermission = async(permission) => {
        setPermission(permission);
    };
    const setPreserveWorkOrder = async(workOrder) => {
        setWorkOrder(workOrder);
    };
    const setPreserveOperatorCompletedScheduleWorkOrder = async(operatorCompletedScheduleWorkOrder) => {
        setOperatorCompletedScheduleWorkOrder(operatorCompletedScheduleWorkOrder);
    };
    const setPreserveAdminNewProgressScheduleWorkOrder = async(adminNewProgressScheduleWorkOrder) => {
        setAdminNewProgressScheduleWorkOrder(adminNewProgressScheduleWorkOrder);
    };
    const setPreserveAdminCompletedScheduleWorkOrder = async(adminCompletedScheduleWorkOrder) => {
        setAdminCompletedScheduleWorkOrder(adminCompletedScheduleWorkOrder);
    };
    const setPreservePurchaseOrder = async(purchaseOrder) => {
        setPurchaseOrder(purchaseOrder);
    };
    const setPreserveGrn = async(grn) => {
        setGrn(grn);
    };

    const setPreserveInvoice = async(invoice) => {
      setInvoice(invoice);
    };

    const setPreserveCustomerPayment = async(customerPayment) => {
      setCustomerPayment(customerPayment);
    };

    const setPreserveCustomerInventory = async(customerInventory) => {
      setCustomerInventory(customerInventory);
    };

    const reset = () => {
      setPreserveCustomer({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveSupplier({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveGroup({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveSupplierItem({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "suppliers.name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveSize({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "sizes.name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveUnit({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveColor({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreservePaymentType({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreservePrintingMachine({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreservePrintingRate({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "printing_machines.name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveWoPrintingType({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveItem({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "items.description",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveStatus({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveParameter({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveHsnCode({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreserveUser({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "first_name",
        "sort_column_value": "asc",
        "search_value": ""
      });
      setPreservePermission({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "desc",
        "search_value": ""
      });
      setPreserveWorkOrder({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "desc",
        "search_value": "",
        "wo_id_filter": "",
        "date_filter": "",
        "customer_id_filter": "",
        "job_type_filter": "",
        "status_id_filter": "",
        "show_filter": false,
      });
      setPreserveOperatorCompletedScheduleWorkOrder({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "desc",
        "search_value": "",
      });
      setPreserveAdminNewProgressScheduleWorkOrder({
        "newProgressWoId": "",
        "newProgressCustomer": "",
        "newProgressSize": "",
        "newProgressOperator": "",
        "newProgressCreatedDate": "",
      });
      setPreserveAdminCompletedScheduleWorkOrder({
        "completedWoId": "",
        "completedCustomer": "",
        "completedSize": "",
        "completedOperator": "",
        "completedCompletionDate": "",
        "completedCreatedDate": "",
        "completedStartedDate": dayjs(new Date).format('YYYY-MM-DD'),
      });
      setPreservePurchaseOrder({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "desc",
        "search_value": ""
      });
      setPreserveGrn({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "desc",
        "search_value": ""
      });

      setPreserveInvoice({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "desc",
        "search_value": ""
      });

      setPreserveCustomerPayment({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "desc",
        "search_value": ""
      });

      setPreserveCustomerInventory({
        "page_no": 1,
        "page_size": 10,
        "sort_column": "id",
        "sort_column_value": "desc",
        "search_value": ""
      });
    };

    return (
      <PreserveTable.Provider value={{
        customer,
        supplier,
        group,
        supplierItem,
        size,
        unit,
        color,
        paymentType,
        printingMachine,
        printingRate,
        woPrintingType,
        item,
        status,
        parameter,
        hsnCode,
        user,
        permission,
        workOrder,
        operatorCompletedScheduleWorkOrder,
        adminNewProgressScheduleWorkOrder,
        adminCompletedScheduleWorkOrder,
        purchaseOrder,
        grn,
        invoice,
        customerPayment,
        customerInventory,
        setPreserveCustomer,
        setPreserveSupplier,
        setPreserveGroup,
        setPreserveSupplierItem,
        setPreserveSize,
        setPreserveUnit,
        setPreserveColor,
        setPreservePaymentType,
        setPreservePrintingMachine,
        setPreservePrintingRate,
        setPreserveWoPrintingType,
        setPreserveItem,
        setPreserveStatus,
        setPreserveParameter,
        setPreserveHsnCode,
        setPreserveUser,
        setPreservePermission,
        setPreserveWorkOrder,
        setOperatorCompletedScheduleWorkOrder,
        setPreserveAdminNewProgressScheduleWorkOrder,
        setPreserveAdminCompletedScheduleWorkOrder,
        setPreservePurchaseOrder,
        setPreserveGrn,
        setPreserveInvoice,
        setPreserveCustomerPayment,
        setPreserveCustomerInventory,
        reset
       }}>
        {children}
      </PreserveTable.Provider>
    );
  };

  export {
    PreserveTableProvider
  }
