/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export default styled(({ className, color, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme, color }) => {

    const { palette,shadows, functions } = theme;

    const {
      grey,
      transparent,
      error: colorError,
      success: colorSuccess,
    } = palette;
    const { pxToRem } = functions;

    return {
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: (color === 'light') ? palette.common.white : palette.common.black,
          color: (color === 'light') ? "rgba(0, 0, 0, 0.87)" : "#ffffff",
          boxShadow: shadows[1],
          fontSize: 14,
        },
        [`& .${tooltipClasses.arrow}`]: {
          color: (color === 'light') ? palette.common.white : palette.common.black,
        },

    }
  });
