import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/user-profile/Header";

import AuthService from "../../services/auth-service";

const UserProfile = () => {
  const [isDemo, setIsDemo] = useState(false);
  const [loading, setLoading] = useState(false);
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [user, setUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    address: "",
    email: "",
    role: "",
    phone: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstNameError: false,
    middleNameError: false,
    lastNameError: false,
    addressError: false,
    emailError: false,
    phoneError: false,
    newPassError: false,
    confirmPassError: false,
  });

  const getUserData = async () => {
    const response = await AuthService.getProfile();
    // console.log(response);
    if (response.data.id == 1) {
      setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
    }
    setUser((prevUser) => ({
      ...prevUser,
      ...response.data,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    }));
  };

  useEffect(() => {
    getUserData();
  }, []);

  const changeHandler = (e) => {
    if (e.target.name == 'phone') {
      setErrors({
        phoneError: false,
      });
    }
    setLoading(false);
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();

    // validation
    // const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mailFormat = /^\w+[\+]*([\.-]?(\+)*\w+[\+]*)*[\+]*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (user.first_name.trim().length === 0) {
      setErrors({ ...errors, firstNameError: true });
      return;
    }

    if (user.last_name.trim().length === 0) {
      setErrors({ ...errors, lastNameError: true });
      return;
    }

    if (user.address.trim().length === 0) {
      setErrors({ ...errors, addressError: true });
      return;
    }

    if (user.email.trim().length === 0 || !user.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    const phoneFormat = /^[0-9]{10}$/;
    if (user.phone?.trim().length > 0 && !user.phone.toString().trim().match(phoneFormat)) {
      setErrors({ ...errors, phoneError: true });
      return;
    }

    if (user.confirmPassword || user.newPassword) {
      // in the api the confirmed password should be the same with the current password, not the new one
      if (user.confirmPassword.trim() !== user.newPassword.trim()) {
        setErrors({ ...errors, confirmPassError: true });
        return;
      }
      if (user.newPassword.trim().length < 8) {
        setErrors({ ...errors, newPassError: true });
        return;
      }
    }

    let userData = {
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      address: user.address,
      email: user.email,
      phone: user.phone,
    };

    // set new user data for call
    if (user.newPassword.length > 0) {
      userData = {
        first_name: user.first_name,
        middle_name: user.middle_name,
        last_name: user.last_name,
        address: user.address,
        email: user.email,
        phone: user.phone,
        password: user.newPassword,
        password_confirmation: user.confirmPassword,
      };
    }

    try {
      // call api for update
      const response = await AuthService.updateProfile(JSON.stringify(userData));
      setLoading(false);

      // reset errors
      setErrors({
        firstNameError: false,
        middleNameError: false,
        lastNameError: false,
        addressError: false,
        emailError: false,
        passwordError: false,
        newPassError: false,
        confirmPassError: false,
      });
      if (response) {
        // setNotification(true);
        openAlertSB({color: 'success', icon: 'check', msg: 'Your profile has been updated', title: 'User Profile'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'User Profile'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'User Profile'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle="My-Profile"/>
        <MDBox mb={2} />
        <Header name={user.first_name + (user.middle_name ? (" " + user.middle_name) : "") + " " + user.last_name} role={user.role} email={user.email}>
          <MDBox
            component="form"
            role="form"
            onSubmit={submitHandler}
            display="flex"
            flexDirection="column"
          >
            <MDBox display="flex" flexDirection="row" mt={1} mb={1}>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  First Name
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="name"
                    fullWidth
                    name="first_name"
                    value={user.first_name}
                    inputProps={{'maxLength':100}}
                    onChange={changeHandler}
                    error={errors.firstNameError}
                  />
                  {errors.firstNameError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The first name can not be null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Middle Name
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="name"
                    fullWidth
                    name="middle_name"
                    value={user.middle_name}
                    inputProps={{'maxLength':100}}
                    onChange={changeHandler}
                    error={errors.middleNameError}
                  />
                  {errors.middleNameError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The middle name can not be null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Last Name
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="name"
                    fullWidth
                    name="last_name"
                    value={user.last_name}
                    inputProps={{'maxLength':100}}
                    onChange={changeHandler}
                    error={errors.lastNameError}
                  />
                  {errors.lastNameError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The last name can not be null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

            </MDBox>

            <MDBox display="flex" flexDirection="row" mb={1}>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"

            >
              <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                Email
              </MDTypography>
              <MDBox mb={1} width="100%">
                <MDInput
                  type="email"
                  fullWidth
                  name="email"
                  value={user.email}
                  onChange={changeHandler}
                  error={errors.emailError}
                  disabled={true}
                />
                {errors.emailError && (
                  <MDTypography variant="caption" color="error" fontWeight="light">
                    The email must be valid
                  </MDTypography>
                )}
              </MDBox>
              {isDemo && (
                <MDTypography variant="caption" color="text" fontWeight="light">
                  In the demo version the email can not be updated
                </MDTypography>
              )}
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
              ml={2}
            >
              <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                Phone
              </MDTypography>
              <MDBox mb={1} width="100%">
                <MDInput
                  type="text"
                  fullWidth
                  name="phone"
                  value={user.phone}
                  onChange={changeHandler}
                  error={errors.phoneError}
                />
                {errors.phoneError && (
                  <MDTypography variant="caption" color="error" fontWeight="light">
                    The phone must be valid
                  </MDTypography>
                )}
              </MDBox>
              {isDemo && (
                <MDTypography variant="caption" color="text" fontWeight="light">
                  In the demo version the email can not be updated
                </MDTypography>
              )}
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
              ml={2}
            >
              <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                Address
              </MDTypography>
              <MDBox mb={2} width="100%">
                <MDInput
                  type="name"
                  fullWidth
                  name="address"
                  value={user.address}
                  inputProps={{'maxLength':255}}
                  onChange={changeHandler}
                  error={errors.addressError}
                />
                {errors.addressError && (
                  <MDTypography variant="caption" color="error" fontWeight="light">
                    The address can not be null
                  </MDTypography>
                )}
              </MDBox>
            </MDBox>
            </MDBox>

            <MDBox display="flex" flexDirection="column" mb={1}>
              <MDBox display="flex" flexDirection="row">
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  width="100%"
                  mr={2}
                >
                  <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                    New Password
                  </MDTypography>
                  <MDBox mb={2} width="100%">
                    <MDInput
                      type="password"
                      fullWidth
                      name="newPassword"
                      placeholder="New Password"
                      onChange={changeHandler}
                      error={errors.newPassError}
                      disabled={isDemo}
                      inputProps={{
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                    {errors.newPassError && (
                      <MDTypography variant="caption" color="error" fontWeight="light">
                        The password must be of at least 8 characters
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  width="100%"
                  ml={2}
                >
                  <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                    Password Confirmation
                  </MDTypography>
                  <MDBox mb={1} width="100%">
                    <MDInput
                      type="password"
                      fullWidth
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={changeHandler}
                      error={errors.confirmPassError}
                      disabled={isDemo}
                      inputProps={{
                        autoComplete: "confirmPassword",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                    {errors.confirmPassError && (
                      <MDTypography variant="caption" color="error" fontWeight="light">
                        The password confirmation must match the current password
                      </MDTypography>
                    )}
                  </MDBox>
                  {isDemo && (
                    <MDTypography variant="caption" color="text" ml={1} fontWeight="light">
                      In the demo version the password can not be updated
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox mt={1} display="flex" justifyContent="end">
                <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
                  {loading ? <MDCircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                      }}
                    />: 'Save'}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Header>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
};

export default UserProfile;
