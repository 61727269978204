import dayjs from 'dayjs';
import { numberFormat } from 'utility/helpers';

const stageType = ['DESIGN', 'PAPER', 'PRINTING', 'BINDING', 'EXTRA'];

export const jobCardPDFTemplate = (companyName, workOrder, materialItem, printingItem, stockItem, otherItem, noColorPrintingMachineId, hasGsm, hasSize, hasGsmOther, hasSizeOther) => {
    return  `<html>
  <head>
    <title>AP_Job_Card_${workOrder?.id} </title>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <center>
        <table style="width:98%; border:2px solid black;">
          <tr>
            <td colspan="2">
              <table style="width: 100%;">
                <tr>
                  <th style="text-align:center; font-size:24px;">${companyName}</th>
                </tr>
                <tr>
                  <th style="text-align:center; font-size:18px;">JOB CARD #${workOrder?.id} </th>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td height="20px"></td>
          </tr>
          <tr>
            <td colspan="2">
              <table style="width: 100%;">
                <tr>
                  <td style="width:17%;font-size:12px; text-align:left;"><b>Party:</b></td>
                  <td style="width:49%;font-size:12px; text-align:left; padding-right: 60px;">${workOrder?.customer?.name}</td>
                  <td style="width:15%;font-size:12px; text-align:left;"><b>Date:</b></td>
                  <td style="width:19%;font-size:12px; text-align:left; padding-right: 60px;">${workOrder ? dayjs(workOrder?.release_date).format('DD-MM-YYYY') : ''}</td>
                </tr>
                <tr>
                  <td style="font-size:12px; text-align:left;"><b>Contact Name:</b></td>
                  <td style="font-size:12px; text-align:left; padding-right: 60px;">${workOrder?.customer?.contact_person ? workOrder?.customer?.contact_person : ''}</td>
                  <td style="font-size:12px; text-align:left;"><b>Delivery Date:</b></td>
                  <td style="font-size:12px; text-align:left;">${workOrder ? dayjs(workOrder?.delivery_date + workOrder?.delivery_time).format('DD-MM-YYYY h:mm A') : ''}</td>
                </tr>
                <tr>
                  <td style="font-size:12px; text-align:left;"><b>Phone#:</b></td>
                  <td style="font-size:12px; text-align:left;">${workOrder?.customer?.mobile ? workOrder?.customer?.mobile : ''}</td>
                  <td style="font-size:12px; text-align:left;"><b>Size:</b></td>
                  <td style="font-size:12px; text-align:left;">${workOrder?.size?.name}</td>
                </tr>
                <tr>
                  <td style="font-size:12px; text-align:left;"><b>Job Type:</b></td>
                  <td colspan="3" style="font-size:12px; text-align:left;">${workOrder?.job_type}</td>
                </tr>
              </table>
            </td>
          </tr>
          ${workOrder?.is_show_instruction ?
            `<tr><td colspan="2" style="vertical-align: top;font-size:12px; white-space: pre-wrap;"><fieldset><legend><strong>Instructions</strong></legend>${workOrder?.instruction}</fieldset></td></tr>` : ''}
          <tr>
            <td height="20px" colspan="2">
              <hr style="border: 1px solid; width: 100%;"/>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table style="width:100%; border:2px solid #cccccc; border-collapse: collapse;">
                <tr height="25px">
                  <th colspan="5" style="fonts-size:14px; text-align:center;">
                    <u>Issued Item Details</u>
                  </th>
                </tr>
                <tr height="25px">
                  <th style="background-color: #dddddd;font-size:12px; text-align:'center'; border:2px solid #cccccc">Sr. No.</th>
                  <th style="background-color: #dddddd;font-size:12px; text-align:'center'; border:2px solid #cccccc">Item</th>
                  ${hasGsm ? `<th style="background-color: #dddddd;font-size:12px; text-align:'center'; border:2px solid #cccccc">Wt(GSM)</th>` : ``}
                  ${hasSize ? `<th style="background-color: #dddddd;font-size:12px; text-align:'center'; border:2px solid #cccccc">Size</th>` : ``}
                  <th style="background-color: #dddddd;font-size:12px; text-align:'center'; border:2px solid #cccccc">Quantity</th>
                </tr>
                ${stageType.map((stage) => {
                  if (materialItem[stage]?.length > 0) {
                  return `
                    <tr>
                      <td colspan=${stage=='PAPER' ? 3 : 5} style="background-color: #effbfb; font-size:12px; text-align:left; padding: 2px;">${stage}</td> ${stage == 'PAPER' ? `<td colspan="2" style="background-color: #effbfb; font-size:12px; text-align:right;; padding: 2px;">Cut Size: ${workOrder?.cut_size ? workOrder?.cut_size?.name : ''}</td>` : ''}
                    </tr>
                    ${materialItem[stage]?.map((eachMaterialItem, index) => {
                      return `<tr>
                      <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${index + 1}</td>
                      <td style="max-width: 250px; word-break: break-all!important; font-size:12px; text-align:left; border:2px solid #cccccc; padding: 2px;">${eachMaterialItem?.item?.description}</td>
                      ${hasGsm ? `<td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${numberFormat(eachMaterialItem?.gsm, false)}</td>` : ``}
                      ${hasSize ? `<td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${eachMaterialItem?.size ? eachMaterialItem?.size?.name : ''}</td>` : ``}
                      <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${eachMaterialItem?.quantity * 1}</td>
                    </tr>` }).join('')}` } }).join('')}
                ${materialItem['DESIGN']?.length == 0 && materialItem['PAPER']?.length == 0 && materialItem['BINDING']?.length == 0 && materialItem['PRINTING']?.length == 0 && materialItem['PAPER']?.length == 0 ?
                `<tr>
                  <td colspan="5" style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;"> Not Applicable</td>
                </tr>` : ''}
              </table>
            </td>
          </tr>
          <tr>
            <td height="20px" colspan="2">
              <hr style="border: 1px solid; width: 100%;"/>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table style="width:100%; border:2px solid #cccccc; border-collapse: collapse;">
                <tr height="25px">
                  <th colspan="10" style="fonts-size:14px; text-align:center;">
                    <u>Printing Item Details</u>
                  </th>
                </tr>
                <tr height="25px">
                  <th style="width: 3% !important; background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Sr. No.</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Narration</th>
                  <th style="width: 5% !important; background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Print Type</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Quantity</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Waste</th>
                  <th style="width: 6% !important; background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Color Scheme</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Colors</th>
                  <th style="width: 3% !important; background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;"># Set</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Lamination</th>
                </tr>
                ${printingItem?.map((eachPrintItem, index) => {
                  return `<tr>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${index + 1}</td>
                  <td style="max-width: 1050px !important; word-break: break-all!important; font-size:12px; text-align:left; border:2px solid #cccccc; padding: 1px;">${eachPrintItem?.description ? eachPrintItem.description : ''}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 1px;">${eachPrintItem?.print_type?.name}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 1px;">${eachPrintItem?.quantity}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 1px;">${eachPrintItem?.waste_qty}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 1px;">${eachPrintItem?.color_length}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 1px;">${(noColorPrintingMachineId != eachPrintItem.printing_machine_id) ? eachPrintItem?.color_scheme : ''}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 1px;">${eachPrintItem?.no_sets}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 1px;">${eachPrintItem?.is_lamination ? 'Yes' : 'No'}</td>
                </tr>`
                }).join('')}
                ${printingItem.length == 0 ? '<tr><td colspan="10" style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;"> Not Applicable</td></tr>' : ''}
              </table>
            </td>
          </tr>
          <tr>
            <td height="20px" colspan="2">
              <hr style="border: 1px solid; width: 100%;"/>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table style="width:100%; border:2px solid #cccccc; border-collapse: collapse;">
                <tr height="25px">
                  <th colspan="5" style="fonts-size:14px; text-align:center;">
                    <u>Stock Item Details</u>
                  </th>
                </tr>
                <tr height="25px">
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Sr. No.</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Item</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Opening Stock</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Quantity</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Closing Stock</th>
                </tr>
                ${stockItem?.map((eachStockItem, index) => {
                  return `<tr>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${index + 1}</td>
                  <td style="max-width: 250px; word-break: break-all!important; font-size:12px; text-align:left; border:2px solid #cccccc; padding: 2px;">${eachStockItem?.item?.description}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${eachStockItem?.opening_stock}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${eachStockItem?.quantity * 1}</td>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${eachStockItem?.opening_stock - eachStockItem?.quantity}</td>
                </tr>`
                }).join('')}
                ${stockItem.length == 0 ? ` <tr>
                  <td colspan="5" style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;"> Not Applicable</td>
                </tr> ` : ''}
              </table>
            </td>
          </tr>
          <tr>
            <td height="20px" colspan="2">
              <hr style="border: 1px solid; width: 100%;"/>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table style="width:100%; border:2px solid #cccccc; border-collapse: collapse;">
                <tr height="25px">
                  <th colspan="5" style="fonts-size:14px; text-align:center;">
                    <u>Other Item Details</u>
                  </th>
                </tr>
                <tr height="25px">
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Sr. No.</th>
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Item</th>
                  ${hasGsmOther ? `<th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Wt(GSM)</th>` : ``}
                  ${hasSizeOther ? `<th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Size</th>` : ``}
                  <th style="background-color: #dddddd; font-size:12px; text-align:center; border:2px solid #cccccc;">Quantity</th>
                </tr>
                ${otherItem?.map((eachOtherItem, index) => {
                  return `<tr>
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${index + 1}</td>
                  <td style="max-width: 250px; word-break: break-all!important; font-size:12px; text-align:left; border:2px solid #cccccc; padding: 2px;">${eachOtherItem?.item?.description}</td>
                  ${hasGsmOther ? `<td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${numberFormat(eachOtherItem?.gsm, false)}</td>` : ``}
                  ${hasSizeOther ? `<td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${eachOtherItem?.size?.name}</td>` : ``}
                  <td style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;">${eachOtherItem?.quantity * 1}</td>
                </tr>`
                }).join('')}
                ${otherItem.length == 0 ?
                  '<tr><td colspan="5" style="font-size:12px; text-align:center; border:2px solid #cccccc; padding: 2px;"> Not Applicable</td></tr>' : ''}
              </table>
            </td>
          </tr>
        </table>
      </center>
    </div>
  </body>
</html>`

}
