import HttpService from "./htttp.service";

class HsnCodeService {

  getHsnCodes = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'hsn_codes';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'hsn_codes?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addHsnCode = async(data) => {
    const fetchUrl = 'hsn_codes';
    return await HttpService.post(fetchUrl, data);
  }

  updateHsnCode = async (id, data) => {
    const fetchUrl = 'hsn_codes/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showHsnCode = async(id) => {
    const fetchUrl = 'hsn_codes/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteHsnCode = async (id) => {
    const fetchUrl = 'hsn_codes/' + id;
    return await HttpService.delete(fetchUrl);
  }

  updateHsnCodeStatus = async (id, data) => {
    const fetchUrl = 'hsn_codes/' + id;
    return await HttpService.patch(fetchUrl, data);
  }
}

export default new HsnCodeService();
