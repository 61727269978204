/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAccordion from "components/MDAccordion";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CustomerRateService from "services/customer-rate-service";
import authPermission from "utility/auth-permissions";

// @mui icons
import Icon from "@mui/material/Icon";

function CustomerRatesForm() {
  const { hasPermission } = authPermission();
  const customerRatesPermission = hasPermission('/manage-customers/customer-rates');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const ref = searchParams.get('ref');
  const customer_name = searchParams.get('customer_name');
  const navigate = useNavigate();
  const location = useLocation();
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [allRates, setAllRates] = useState([]);

  useEffect(() => {
    const getCustomerRates = async() => {
      const allCustomerRates = await CustomerRateService.getCustomerRates(id);
      const cloneAllRates = [...Object.entries(allCustomerRates)];
      cloneAllRates.forEach( async (eachData, index) => {
        eachData[1]['rates']?.forEach( async (eachRate, rate_index) => {
          cloneAllRates[index][1]['rates'][rate_index]['cash_rate'] = Number(cloneAllRates[index][1]['rates'][rate_index]['cash_rate']).toFixed(2);
          cloneAllRates[index][1]['rates'][rate_index]['credit_rate'] = Number(cloneAllRates[index][1]['rates'][rate_index]['credit_rate']).toFixed(2);
          cloneAllRates[index][1]['rates'][rate_index]['quantity_error'] = false;
          cloneAllRates[index][1]['save_btn_loading'] = false;
        });
      });
      setAllRates(cloneAllRates);
    }

    getCustomerRates();
  }, []);

  const getRows = (rates, mainIndex) => {
    let routeRows = [];
    if (rates?.length > 0) {
      rates.map((eachRate, rateIndex) => {
        const cols = {
          quantity:(
                <MDBox>
                  <MDInput
                    type="text"
                    value={eachRate.quantity}
                    name="quantity"
                    inputProps={{'maxLength':5, 'pattern':'^([0-9]*)$'}}
                    size="small"
                    onChange={(e) => changeHandler(e.target.name, e.target.value, mainIndex, rateIndex)}
                    error={eachRate.quantity_error}
                    disabled={customerRatesPermission.edit ? false : true}
                    sx={{ 'width':'120px' }}
                  />
                </MDBox>
                ),
          cash_rate:(<MDBox>
                      <MDInput
                        type="text"
                        value={eachRate.cash_rate}
                        name="cash_rate"
                        inputProps={{'maxLength':11, 'pattern':'^([0-9]{1,8}|[0-9]{1,8}\.[0-9]{1,4})$'}}
                        size="small"
                        onChange={(e) => changeHandler(e.target.name, e.target.value, mainIndex, rateIndex)}
                        onBlur={(e) => blurHandler(e.target.name, e.target.value, mainIndex, rateIndex)}
                        required={eachRate.quantity ? true : false}
                        disabled={customerRatesPermission.edit ? false : true}
                        sx={{ 'width':'120px' }}
                      />
                    </MDBox>),
          credit_rate:(<MDBox>
                        <Grid container>
                          <Grid item md={(rates.length - 1) != rateIndex ? 12 : 10}>
                            <MDInput
                              type="text"
                              value={eachRate.credit_rate}
                              name="credit_rate"
                              inputProps={{'maxLength':11, 'pattern':'^([0-9]{1,8}|[0-9]{1,8}\.[0-9]{1,4})$'}}
                              size="small"
                              onChange={(e) => changeHandler(e.target.name, e.target.value, mainIndex, rateIndex)}
                              onBlur={(e) => blurHandler(e.target.name, e.target.value, mainIndex, rateIndex)}
                              required={eachRate.quantity ? true : false}
                              disabled={customerRatesPermission.edit ? false : true}
                              sx={{ 'width':'120px' }}
                            />
                          </Grid>
                          {(rates.length - 1) === rateIndex &&
                            <Grid item md={2}>
                                  <MDButton sx={{ 'marginLeft':'10px'}} onClick={() => addNewCustomerRate(mainIndex)} variant="contained" color="success" iconOnly circular title="Add Customer Rate">
                                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                  </MDButton>
                            </Grid>
                          }
                        </Grid>
                      </MDBox>),
        };
        routeRows[rateIndex] = cols;
      });
    } else {
      const cols = {
        quantity:(<MDBox sx={{ 'width':'120px' }}></MDBox>),
        cash_rate:(<MDBox sx={{ 'width':'120px' }}></MDBox>),
        credit_rate:(<MDBox>
                      <Grid container>
                        <Grid item md={4}>
                              <MDButton sx={{ 'marginLeft':'150px'}} onClick={() => addNewCustomerRate(mainIndex)} variant="contained" color="success" iconOnly circular title="Add Customer Rate">
                                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                              </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>),
      };
      routeRows[0] = cols;
    }
    return routeRows;
  };

  const columns = [
    { Header: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{"Quantity"}</MDTypography>), accessor: "quantity", width: "2%", align: "left" },
    { Header: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{"Cash Rate"}</MDTypography>), accessor: "cash_rate", width: "2%", align: "left" },
    { Header: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{"Credit Rate"}</MDTypography>), accessor: "credit_rate", width: "96%", align: "left" },
  ];

  const addNewCustomerRate = (mainIndex) => {
    const cloneAllRates = [...allRates];
    cloneAllRates[mainIndex][1]['rates'][cloneAllRates[mainIndex][1]['rates'].length] = {
      customer_id: id,
      printing_machine_id: cloneAllRates[mainIndex][0],
      quantity: '',
      cash_rate: '',
      credit_rate: '',
      quantity_error: false,
    };
    setAllRates(cloneAllRates);
  };

  const changeHandler = (fieldName, fieldValue, mainIndex, rateIndex) => {
    const cloneAllRates = [...allRates];
    cloneAllRates[mainIndex][1]['rates'][rateIndex][fieldName] = fieldValue;
    cloneAllRates[mainIndex][1]['rates'][rateIndex]['quantity_error'] = false;
    setAllRates(cloneAllRates);
  };

  const blurHandler = (fieldName, fieldValue, mainIndex, rateIndex) => {
    const cloneAllRates = [...allRates];
    cloneAllRates[mainIndex][1]['rates'][rateIndex][fieldName] = (fieldValue * 1).toFixed(2);
    cloneAllRates[mainIndex][1]['rates'][rateIndex]['quantity_error'] = false;
    setAllRates(cloneAllRates);
  };

  const submitHandler = async (e, mainIndex) => {

    e.preventDefault();
    const cloneRates = {...allRates};
    cloneRates[mainIndex][1]['save_btn_loading'] = true;
    allRates.forEach( async (eachData, index) => {
      if (index == mainIndex) {
        if (eachData[1]?.rates?.length > 0) {
          try {
            const response = await CustomerRateService.deleteAllCustomerRate(id, eachData[1]?.rates[0]?.printing_machine_id);
          } catch (res) {
            if (res.hasOwnProperty("message")) {
              openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Customer Rate'});
              setTimeout(function() {
                closeAlertSB();
              }, 4000);

            } else {
              const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Customer Rate'};
              setTimeout(function() {
                closeAlertSB();
              }, 4000);

            }
          }

          eachData[1]?.rates.forEach( async (eachRate, i) => {
            if (eachRate.quantity > 0) {
              try {
                const newCustomerRate = {
                  customer_id: id,
                  printing_machine_id: eachRate.printing_machine_id,
                  quantity: eachRate.quantity,
                  cash_rate: eachRate.cash_rate,
                  credit_rate: eachRate.credit_rate,
                };
                const response = await CustomerRateService.addCustomerRate(newCustomerRate);
                if (response) {
                  openAlertSB({color: 'success', icon: 'check', msg: 'Customer Rates Added Successfully', title: 'Customer Rate'});
                  setTimeout(function() {
                    closeAlertSB();
                  }, 4000);
                }
              } catch (res) {
                if (res.hasOwnProperty("message")) {
                  let pattern = /The\squantity\shas\salready\sbeen\staken/;
                  let result = (res.message).match(pattern);
                  if (result) {
                    const cloneAllRates = [...allRates];
                    cloneAllRates[index][1]['rates'][i]['quantity_error'] = true;
                    setAllRates(cloneAllRates);
                  }
                  openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Customer Rate'});
                  setTimeout(function() {
                    closeAlertSB();
                  }, 4000);

                } else {
                  const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Customer Rate'};
                  setTimeout(function() {
                    closeAlertSB();
                  }, 4000);

                }
              }
            }
          });
        }
        const cloneRates = {...allRates};
        cloneRates[mainIndex][1]['save_btn_loading'] = false;
      }
    });
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={'Customer Rates : ' + customer_name}/>
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>

              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.625rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={11}>
                      <MDTypography mt={1} variant="h6" color="white">
                        {'Manage Customer Rates : ' + customer_name}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={1} title="Edit">
                      <MDButton ml={5}
                      component={Link}
                      to={ref ? `/manage-customers/edit?id=${id}&ref=${ref}` : `/manage-customers/edit?id=${id}`}
                      variant="outlined" color="white" iconOnly circular>
                        <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>edit</Icon>
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>

                <MDBox px={2} mt={2} mb={2}>
                  {allRates?.map((eachData, mainIndex) => (
                    <MDBox mt={2} component="form" role="form" method="POST" onSubmit={(e) => submitHandler(e, mainIndex)}>
                      <MDAccordion
                        accordionTitle={eachData[1]?.name}
                        summarySx={{ fontSize:'15px', backgroundColor:'#d3d3d3' }}
                        defaultExpanded={mainIndex == 0 ? true : false}
                      >
                      <Grid container>
                        <Grid item lg={8} md={12} xs={12}>
                          <DataTable
                            table={{ columns, rows:getRows(eachData[1]?.rates, mainIndex) }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder={true}
                            doubleClick={false}
                          />
                        </Grid>

                      </Grid>

                      <Grid container>
                        <Grid item md={10} xs={6}></Grid>

                        {!customerRatesPermission.edit ?
                          <Grid item md={1} xs={2}></Grid>
                          : <></>}

                        <Grid item md={1} xs={2}>
                          <MDBox mb={1} mr={2}>
                            <MDButton component={Link} to={ref ? `/manage-customers/edit?id=${id}&ref=${ref}` : `/manage-customers/edit?id=${id}`} variant="gradient" color="primary" fullWidth>
                              {'Back'}
                            </MDButton>
                          </MDBox>
                        </Grid>

                        {customerRatesPermission.edit ?
                        <Grid item md={1} xs={2}>
                          <MDBox mb={1} mr={3}>
                            <MDButton variant="gradient" color="info" fullWidth type="submit">
                              {eachData[1]?.save_btn_loading ?
                                <MDCircularProgress
                                  size={20}
                                /> :
                                'Save'
                              }
                            </MDButton>
                          </MDBox>
                        </Grid>
                        : <></>}

                      </Grid>
                      </MDAccordion>
                    </MDBox>
                  ))}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default CustomerRatesForm;
