import dayjs from 'dayjs';

export const PDFTemplate = (machineWiseSale, totalData, printingMachineDropdown, printingMachineArray, numberFormat) => {
    return  `<html>
  <head>
    <title>machine_wise_sales_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Machine Wise Sales</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.975rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: center !important;">Date</th>
                ${printingMachineDropdown?.map((eachType) => {
                  if (printingMachineArray.includes(eachType?.key)) {
                    return `
                      <th style="background-color: #dddddd; width: 10%; font-size: 0.975rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: center !important;">${eachType?.value}</th>
                    `
                  }
                }).join('')}
                <th style="background-color: #dddddd; width: 10%; font-size: 0.975rem; border-bottom: 1px solid #000;text-align: center !important;">Total</th>
            </tr>
            ${machineWiseSale?.map((item, index) => {
                let rowColor = index % 2 === 0 ? '#e0f8f7' : '#effbfb';
                return `
                    <tr style="background-color: ${rowColor}">
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;color:#284464;"><b>${item?.date}</b></td>
                        ${printingMachineDropdown?.map((eachType) => {
                          if (printingMachineArray.includes(eachType?.key)) {
                            return `
                              <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right !important;color:#284464;"><b>${item[eachType?.key] ? numberFormat(item[eachType?.key]) : '0.00'}</b></td>
                            `
                          }
                        }).join('')}
                        <td style="font-size: 0.775rem; border-bottom: 1px solid #000;text-align: right !important;"><b>${numberFormat(item?.total)}</b></td>
                    </tr>
                `
            }).join('')} 
            <tr>
                <th style="font-size: 0.775rem; border-right: 1px solid #000;">Total</th>
                ${printingMachineDropdown?.map((eachType) => {
                  if (printingMachineArray.includes(eachType?.key)) {
                    return `
                      <th style="font-size: 0.775rem; border-right: 1px solid #000;text-align: right !important;">${totalData[eachType?.key] ? numberFormat(totalData[eachType?.key]) : '0.00'}</th>
                    `
                  }
                }).join('')}
                <th style="font-size: 0.775rem;text-align: right !important;">${numberFormat(totalData?.total)}</th>
            </tr>       
        </table>
      </center>
    </div>
  </body>
</html>`

}
