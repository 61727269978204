/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useState, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Collapse from "@mui/material/Collapse";
import authPermission from "utility/auth-permissions";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { PreserveTable } from "context";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function SidenavCollapse({ icon, name, active, collapse, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [open, setOpen] = useState(false);
  const collapseName = location.pathname.replace("/", "");
  const { hasPermission } = authPermission();
  const preserveTable = useContext(PreserveTable);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem component="li" onClick={handleClick}>
        <MDBox
          {...rest}
          onClick={ async () => {
            if (collapse?.length < 1) {
              await preserveTable.reset();
            }
          }}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {collapse?.length > 0 ? open ? <ExpandMore /> : <KeyboardArrowLeftIcon /> : ""}
        </MDBox>
      </ListItem>
      {collapse?.length > 0 ? (
        <>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" sx={{marginLeft:"10px"}}>
              {collapse.map(
                ({ type, name, icon, title, noCollapse, key, href, route, collapse, target }) => {
                  if (hasPermission(route).view || hasPermission(href).view || hasPermission(name).view) {
                    return (<NavLink key={key} to={route} target={target ? target : ''}>
                      <SidenavCollapse
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        collapse={collapse ? collapse : []}
                        target={target ? target : ''}
                      />
                    </NavLink>)
                  }
                }
              )}
            </List>
          </Collapse>
        </>
      ) : (
        ""
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
