/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import dayjs from 'dayjs';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuppliersService from "services/supplier-service";
import authPermission from "utility/auth-permissions";

// @mui icons
import Icon from "@mui/material/Icon";
import { useCache } from "context";

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const ref = searchParams.get('ref');
  const closeWindow = searchParams.get('close_window') ? 1 : 0;
  const navigate = useNavigate();
  const location = useLocation();

  const { hasPermission } = authPermission();
  const baseRatePermission = hasPermission('/manage-suppliers/base-rate');

  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [active, setActive] = useState(1);
  const [isCreatePo, setIsCreatePo] = useState(1);
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const { cacheItems, setCacheItems } = useCache();

  const goBack = async() => {
    if (ref) {
      window.location.href = ref;
    } else if (closeWindow) {
      window.open("about:blank", "_self");
      window.close();
    } else {
      window.location.href = '/manage-suppliers';
    }
  }

  const [inputs, setInputs] = useState({
    name: "",
    address: "",
    city: "",
    email: "",
    phone: "",
    mobile: "",
    created_at: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    addressError: false,
    cityError: false,
    emailError: false,
    phoneError: false,
    mobileError: false,
  });

  useEffect(() => {
    if (action === "add" && id == null) return;

    const showSupplier = async() => {
      try {
        const data = await SuppliersService.showSupplier(id);
        setHeaderTitle(data.name);
        setInputs(data);
        setCreatedBy(data.user.first_name + (data.user.middle_name ? (" " + data.user.middle_name) : "") + " " + data.user.last_name);
        setActive(data.active);
        setIsCreatePo(data.is_create_po);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Supplier'};
          navigate('/manage-suppliers', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier'};
          navigate('/manage-suppliers', {state: state});
        }
      }
    }
    showSupplier();
  }, []);


  const changeHandler = (e) => {
    if (e.target.name == 'email') {
      setErrors({
        emailError: false,
      });
    }
    if (e.target.name == 'phone') {
      setErrors({
        phoneError: false,
      });
    }
    if (e.target.name == 'mobile') {
      setErrors({
        mobileError: false,
      });
    }
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    const mailFormat = /^\w+[\+]*([\.-]?(\+)*\w+[\+]*)*[\+]*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneFormat = /^[0-9]{10,11}$/;

    if (inputs.name.trim().length < 1) {
      setErrors({ ...errors, nameError: true });
      return;
    }
    if (inputs.address.trim().length < 1) {
      setErrors({ ...errors, addressError: true });
      return;
    }
    if ((inputs.email && inputs.email.trim().length > 0 && !inputs.email.trim().match(mailFormat))) {
      setErrors({ ...errors, emailError: true });
      return;
    }
    if (inputs.phone && !inputs.phone.trim().match(phoneFormat)) {
      setErrors({ ...errors, phoneError: true });
      return;
    }
    if (inputs.mobile && !inputs.mobile.trim().match(phoneFormat)) {
      setErrors({ ...errors, mobileError: true });
      return;
    }

    const newSupplier = {
      name: inputs.name,
      address: inputs.address,
      city: inputs.city,
      email: inputs.email,
      phone: inputs.phone,
      mobile: inputs.mobile,
      is_create_po: isCreatePo,
      active: active ? 1 : 0,
    };

    if (action === 'add') {
      try {
        const response = await SuppliersService.addSupplier(newSupplier);
        setLoading(false);
        if (response) {
          if (cacheItems.hasOwnProperty('suppliers')) {
            // Create a new object without the key
            const { suppliers, ...rest } = cacheItems; // Destructure to exclude key
            setCacheItems(rest); // Set the new cache items without key
          }
          const state = {color: 'success', icon: 'check', msg: 'Supplier Added Successfully!', title: 'Supplier'};
          navigate('/manage-suppliers', { state: state });
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Supplier'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    } else {
      try {
        const response = await SuppliersService.updateSupplier(id ,newSupplier);
        setLoading(false);
        if (response) {
          if (ref) {
            if (cacheItems.hasOwnProperty('suppliers')) {
              // Create a new object without the key
              const { suppliers, ...rest } = cacheItems; // Destructure to exclude key
              setCacheItems(rest); // Set the new cache items without key
            }
            const state = {color: 'success', icon: 'check', msg: 'Supplier Updated Successfully!', title: 'Customer'};
            navigate(`${ref}`, { state: state });
          } else {
            openAlertSB({color: 'success', icon: 'check', msg: 'Supplier Updated Successfully!', title: 'Supplier'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Supplier'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    }

    return () => {
      setInputs({
        name: "",
      });

      setErrors({
        nameError: false,
      });
    };
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add Supplier' : ('Edit Supplier : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.3rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={11}>
                      <MDTypography mt={1} variant="h6" color="white">
                        {action === 'add' ? 'Add Supplier' : ('Edit Supplier : ' + headerTitle)}
                      </MDTypography>
                    </Grid>
                      {action === "edit" && baseRatePermission.view ?
                        <Grid item xs={1} justifyContent="space-between">
                          <MDButton
                          component={Link}
                          to={ref ? `/manage-suppliers/base-rate?id=${id}&ref=${ref}` : `/manage-suppliers/base-rate?id=${id}`}
                          variant="outlined" color="white" iconOnly circular  title="Manage Base Rate">
                            <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>price_change_icon</Icon>
                          </MDButton>
                        </Grid> : ''
                      }
                  </Grid>
                </MDBox>
                <MDBox py={3}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Name"
                            fullWidth
                            value={inputs.name}
                            name="name"
                            inputProps={{'maxLength':80}}
                            onChange={changeHandler}
                            error={errors.nameError}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Address"
                            fullWidth
                            value={inputs.address}
                            name="address"
                            inputProps={{'maxLength':255}}
                            onChange={changeHandler}
                            error={errors.addressError}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="City"
                            fullWidth
                            value={inputs.city}
                            name="city"
                            inputProps={{'maxLength':25}}
                            onChange={changeHandler}
                            error={errors.cityError}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="email"
                            label="Email"
                            fullWidth
                            value={inputs.email}
                            name="email"
                            inputProps={{'maxLength':50}}
                            onChange={changeHandler}
                            error={errors.emailError}
                            helperText={errors.emailError ? "Please enter a valid email." : ""}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Phone"
                            fullWidth
                            value={inputs.phone}
                            name="phone"
                            inputProps={{'maxLength':20}}
                            onChange={changeHandler}
                            error={errors.phoneError}
                            helperText={errors.phoneError ? "Phone should be 10 or 11 digits." : ""}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Mobile"
                            fullWidth
                            value={inputs.mobile}
                            name="mobile"
                            inputProps={{'maxLength':20}}
                            onChange={changeHandler}
                            error={errors.mobileError}
                            helperText={errors.mobileError ? "Mobile should be 10 digits." : ""}
                          />
                        </MDBox>
                      </Grid>

                      {action === 'edit' &&
                        <>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                name=""
                                value={createdBy}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={dayjs(inputs.created_at).format('DD-MM-YYYY HH:mm A')}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      }
                      <Grid item md={2} xs={4} px={3}>
                        <MDBox>
                          <MDSwitch checked={isCreatePo ? true : false} onChange={() => setIsCreatePo(prev => !prev)} label="Allow Create PO" inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                        </MDBox>
                      </Grid>
                      <Grid item md={2} xs={4} px={3}>
                        <MDBox>
                          <MDSwitch checked={active ? true : false} onChange={() => setActive(prev => !prev)} label="Active" inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={4} px={3}></Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={2}>
                          <MDButton component={Link} onClick={() => goBack()} variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
