import HttpService from "./htttp.service";

class PermissionService {
  // authEndpoint = process.env.API_URL;

  getPermissions = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'permissions';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'permissions?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  };

  addPermission = async (data) => {
    const fetchUrl = 'permissions';
    return await HttpService.post(fetchUrl, data);
  };

  deletePermission = async (id) => {
    const fetchUrl = 'permissions/' + id;
    return await HttpService.delete(fetchUrl);
  };

  updatePermissionStatus = async (id, data) => {
    const fetchUrl = 'permissions/' + id;
    return await HttpService.patch(fetchUrl, data);
  };

  showPermission = async (id) => {
    const fetchUrl = 'permissions/' + id;
    return await HttpService.get(fetchUrl);
  };

  updatePermission = async (id, data) => {
    const fetchUrl = 'permissions/' + id;
    return await HttpService.put(fetchUrl, data);
  };
}

export default new PermissionService();
