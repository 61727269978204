/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCircularProgress from "components/MDCircularProgress";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import { Workbook } from 'exceljs';
import { PDFTemplate } from "./pdf-template";
import ReportService from "services/report-service";
import { Link } from "react-router-dom";
import MDSelect from "components/MDSelect";
import CustomerService from "services/customer-service";
import { useCache } from "context";

function Form() {
    const { hasPermission, systemConstant } = authPermission();
    const cuPermission = hasPermission('/manage-customers');
    const [customersDropdown, setCustomersDropdown] = useState([]);
    const [customerMonthlySale, setCustomerMonthlySale] = useState([]);
    const [totalData, setTotalDate] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [totalSort, setTotalSort] = useState('');
    const [startDate, setStartDate] = useState(dayjs(new Date).startOf('year').add(3, 'month'));
    const [endDate, setEndDate] = useState(dayjs(new Date).endOf('year').add(3, 'month'));
    const [filter, setFilter] = useState(7);
    const { cacheItems, setCacheItems } = useCache();
    const minWidth = useMediaQuery('(min-width:768px)');
    const minWidthD = useMediaQuery('(min-width:1432px)');

    const getCustomerMonthlySale = async(customerId = '', totalSort = '', startDate = '', endDate = '') => {
        const data = await ReportService.getCustomerMonthlySale(customerId, totalSort, startDate, endDate);
        let monthWiseCustomerSales = data.month_wise_customer_sales;
        if (totalSort == 1) {
            monthWiseCustomerSales = monthWiseCustomerSales.sort((a, b) => a.total - b.total);
        } else if (totalSort == 2) {
            monthWiseCustomerSales = monthWiseCustomerSales.sort((a, b) => b.total - a.total);
        }
        setCustomerMonthlySale(monthWiseCustomerSales);
        setTotalDate(data.totals);
    }

    useEffect(() => {
        const getCustomersData = async() => {
            let response; // Declare response outside the if...else block
            if (cacheItems.hasOwnProperty('customers')) {
                response = cacheItems['customers'];
            } else {
                response = await CustomerService.getCustomers(); // Fetch customers from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    customers: response,
                }));
            }
            if (response) {
            var customerArray = [];
            response?.map((eachCustomer, index) => {
                if(eachCustomer?.active) {
                    customerArray[index] = {
                        key: eachCustomer?.id,
                        value: eachCustomer?.name,
                        disabled: eachCustomer?.active ? false : true,
                    }
                }
            });
            setCustomersDropdown(customerArray);
            }
          }
        getCustomersData();
        getCustomerMonthlySale('', '', dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '', dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '');
    }, []);
    
    const submitHandler = () => {
        setSearchLoading(true);
        const sDate = dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD') : '';
        const eDate = dayjs(endDate).isValid() ? dayjs(endDate).format('YYYY-MM-DD') : '';
        getCustomerMonthlySale(customerId, totalSort, sDate, eDate);
        setSearchLoading(false);
    }

    const clearFilters = () => {
        setCustomerId('');
        setTotalSort('');
        setStartDate('');
        setEndDate('');
        setFilter(null);
    }

    const setDate = (dateValue, field) => {
        setFilter(null);
        if (field == 'start_date'){
            setStartDate(dateValue);
        } else if (field == 'end_date') {
            setEndDate(dateValue);
        }
    }

    const generateAndDownloadExcelFile = async(data, totalData) => {
        setExcelLoading(true);
        const fileName = 'customer_monthly_sales_report_' + dayjs().format('DD_MM_YYYY_HH_mm_ss');
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headingRow = worksheet.addRow(['Report: Customer Monthly Sales']);
        //Merge cells for supplier row
        worksheet.mergeCells(`A1:N1`);
        // Add headers
        const headerRow = worksheet.addRow(['Party', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'Total']);
        // Set the width for each column
        worksheet.columns = [
            { width: 30 }, 
            { width: 15 },
            { width: 15 },
            { width: 15 }, 
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 }, 
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 18 },
        ];
        headerRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 1; i < 15; i++) {
            headerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'dddddd' }, // Red color
            };
        }
        for (var i=1; i<15; i++) {
            // Add border to the row
            headerRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        }

        // Add data
        data?.forEach((row, index) => {
            const cellRow = worksheet.addRow([row?.customer, Number(row?.apr), Number(row?.may), Number(row?.jun), Number(row?.jul), Number(row?.aug), Number(row?.sep), Number(row?.oct), Number(row?.nov), Number(row?.dec), Number(row?.jan), Number(row?.feb), Number(row?.mar), Number(row?.total)]);
            let rowColor = index % 2 === 0 ? 'e0f8f7' : 'effbfb';
            for (var i = 1; i < 15; i++) {
                cellRow.getCell(i).fill = {
                    type: 'pattern',
                    name: 'sans-serif',
                    pattern: 'solid',
                    fgColor: { argb: rowColor },
                };
                if (i>1) {
                    cellRow.getCell(i).numFmt = '#,##0.00';
                }
            }

            for (var i=1; i<15; i++) {
                // Add border to the row
                cellRow.getCell(i).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        });

        const totalRow = worksheet.addRow(['Total', Number(totalData?.apr), Number(totalData?.may), Number(totalData?.jun), Number(totalData?.jul), Number(totalData?.aug), Number(totalData?.sep), Number(totalData?.oct), Number(totalData?.nov), Number(totalData?.dec), Number(totalData?.jan), Number(totalData?.feb), Number(totalData?.mar), Number(totalData?.total)]);
        totalRow.font = { bold: true, name: 'sans-serif' };
        for (var i = 2; i < 15; i++) {
            totalRow.getCell(i).numFmt = '#,##0.00';
        }

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setExcelLoading(false);
    };

    const handlePrint = async() => {
        setPrintLoading(true);
        if(customerMonthlySale){
            const pdfTemplate = PDFTemplate(customerMonthlySale, totalData, numberFormat);
    
            const originalBody = document.body;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`
                <style>
                    @media print {
                        @page { size: landscape; }
                    }
                </style>
                ${pdfTemplate}
            `);
            printWindow.document.close();
            await new Promise(r => setTimeout(r, 2000));
            printWindow.print();
            printWindow.close();
            document.body = originalBody;
        }
        setPrintLoading(false);
    };
    
    const onFilterChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar breadcrumbsTitle={'Customer Monthly Sales'} />
                <MDBox pt={4} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                    <Card>
                        <MDBox
                        mx={2}
                        mt={-3}
                        py={"0.3rem"}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        >
                        <Grid container>
                            <Grid item xs={12} md={5}>
                                <MDTypography variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                                    {'Customer Monthly Sales'}
                                </MDTypography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <MDBox>
                                    
                                </MDBox>
                            </Grid>
                        </Grid>
                        </MDBox>
                        <MDBox px={2} mt={1}>
                            <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                <Grid container>
                                    <Grid item xs={12} lg={minWidthD ? 3 : 2.5} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Customer"
                                                fullWidth
                                                name="customer_id"
                                                value={customerId}
                                                onChange={(e) => setCustomerId(e.target.value != null ? e.target.value : '')}
                                                options={customersDropdown}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} lg={minWidthD ? 1 : 1} md={6} px={1}>
                                        <MDBox mb={1}>
                                            <MDSelect
                                                label="Sort Total By"
                                                fullWidth
                                                name="total_sort"
                                                value={totalSort}
                                                onChange={(e) => setTotalSort(e.target.value != null ? e.target.value : '')}
                                                options={[{key: 1, value: 'ASC'}, {key: 2, value: 'DESC'}]}
                                            />
                                        </MDBox>
                                    </Grid>
                                     <Grid item lg={5} xs={12} px={1} md={8}>
                                        <MDBox mb={1}>
                                            <MDDateRangePicker
                                                label="Start Date"
                                                name="start_date"
                                                value={startDate ? startDate : null}
                                                format="DD-MM-YYYY"
                                                onChange={(newValue) => {
                                                    setDate(newValue, 'start_date');
                                                }}
                                                endLabel="End Date"
                                                endName="end_date"
                                                endValue={endDate ? endDate : null}
                                                endOnChange={(newValue) => {
                                                    setDate(newValue, 'end_date');
                                                }}
                                                onFilterChange={onFilterChange}
                                                filter={filter}
                                                filterSx={{width: minWidthD? 150 : 80}}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={submitHandler} iconOnly>
                                            {searchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                            </MDButton>
                                        </MDBox>
                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                            <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                            </MDButton>
                                        </MDBox>
                                    </Grid>
                                    {customerMonthlySale.length > 0 && (
                                        <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                            <MDBox mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={handlePrint}>
                                                {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(customerMonthlySale, totalData)} iconOnly>
                                                {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    )}
                                </Grid>
                            </fieldset>
                        </MDBox>
                        <MDBox mt={2} px={2} mb={2}>
                            <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '17%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Party</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>April</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>May</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>June</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>July</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>August</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>September</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>October</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>November</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>December</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>January</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>February</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6.2%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>March</TableCell>
                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '8%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Total</TableCell>
                                        </TableRow>
                                        {customerMonthlySale?.map((item, index) => {
                                            let rowColor = index % 2 === 0 ? '#e0f8f7' : '#effbfb';
                                            return (
                                                <TableRow sx={{ backgroundColor: rowColor }}>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: 'info.main'}} component={Link} to={cuPermission.edit ? `/manage-customers/edit?id=${item?.customer_id}&close_window=1` : ``} target={'_blank'}>{item?.customer}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.apr)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.may)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.jun)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.jul)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.aug)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.sep)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.oct)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.nov)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.dec)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.jan)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.feb)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.mar)}</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(item?.total)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        {customerMonthlySale.length == 0 ? 
                                            <TableRow>
                                                <TableCell colSpan={14} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                            </TableRow> :
                                            <TableRow>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>Total</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.apr)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.may)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.jun)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.jul)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.aug)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.sep)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.oct)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.nov)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.dec)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.jan)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.feb)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.mar)}</TableCell>
                                                <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{numberFormat(totalData?.total)}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MDBox>
                    </Card>
                    </Grid>
                </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Form;
