import dayjs from 'dayjs';

export const PDFTemplate = (invoiceRegister, grandAmount, grandTax, grandTotal, numberFormat) => {
    return  `<html>
  <head>
    <title>invoice_register_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Invoice Register</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd;width: 13%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Date</th>
                <th style="background-color: #dddddd;width: 28%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Job Type</th>
                <th style="background-color: #dddddd;width: 8%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">WO#</th>
                <th style="background-color: #dddddd;width: 12%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Invoice No</th>
                <th style="background-color: #dddddd;width: 13%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Basic</th>
                <th style="background-color: #dddddd;width: 13%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">TAX(VAT/GST)</th>
                <th style="background-color: #dddddd;width: 15%; font-size: 0.875rem; border-bottom: 1px solid #000;">Total</th>
            </tr>
            ${invoiceRegister?.map((eachItem, index) => {
                let rowColor = '#effbfb';
                return `
                    <tr style="background-color: #f8ece0;">
                        <td colspan="7" style="border-bottom: 1px solid #000;"><b>Customer Name: </b>${eachItem?.customer_name}</td>
                    </tr>
                    ${eachItem?.items?.map((item) => {
                        rowColor = rowColor == '#effbfb' ? '#e0f8f7' : '#effbfb';
                        return `
                            <tr style="background-color: ${rowColor}">
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.date}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.job_type}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.work_order_id}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.invoice_no}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${numberFormat(item?.amount)}</td>
                                <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;text-align: right;">${numberFormat(item?.tax)}</td>
                                <td style="font-size: 0.775rem; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.total)}</td>
                            </tr>
                        `
                    }). join('')}
                    <tr>
                        <td colspan="4" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right; border-bottom: 1px solid #000;"><b>Total:</b></td>
                        <td style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right; border-bottom: 1px solid #000;">${numberFormat(eachItem?.amount)}</td>
                        <td style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right; border-bottom: 1px solid #000;">${numberFormat(eachItem?.tax)}</td>
                        <td style="font-size: 0.875rem; text-align: right; border-bottom: 1px solid #000;">${numberFormat(eachItem?.total)}</td>
                    </tr>
                `
            }).join('')}
            <tr>
                <td colspan="4" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Grand Total:</b></td>
                <td style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;">${numberFormat(grandAmount)}</td>
                <td style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;">${numberFormat(grandTax)}</td>
                <td style="font-size: 0.875rem; text-align: right;">${numberFormat(grandTotal)}</td>
            </tr>        
        </table>
      </center>
    </div>
  </body>
</html>`

}
