/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation, useSearchParams } from "react-router-dom";

// Material Dashboard 2 React components
import * as React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import CustomerInventoryService from "services/customer-inventory-service";
import { useNavigate } from "react-router-dom";
import authPermission from "utility/auth-permissions";
import { numberFormat } from "utility/helpers";

export default function data(props) {
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-customers/customer-inventories');
  const customerPagePermission = hasPermission('/manage-customers');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const ref = searchParams.get('ref');
  const customer_name = searchParams.get('customer_name');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.customerInventory;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [customerInventoriesData, setCustomerInventoriesData] = useState([]);
  const navigate = useNavigate();
  let location = useLocation();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const [totalData, setTotalData] = useState(0);

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveCustomerInventory(preserveData);
    const response = await CustomerInventoryService.getCustomerInventories(id, page_no, page_size, sortColumn, sortColumnValue, searchWord);

    setCustomerInventoriesData(response?.data);
    setTotalData(response?.total);
  };

  const updateCustomerInventoryStatus = async (id, status) => {
    try {
      var data = {'active': status};
      const res = await CustomerInventoryService.updateCustomerInventoryStatus(id, data);
      if (res) {
        openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Customer Inventory'});
        setTimeout(function() {
            closeAlertSB();
        }, 3000);
        const response = await CustomerInventoryService.getCustomerInventories(id, page_no, page_size, sort_column, sort_column_value, search_value);
        setCustomerInventoriesData(response?.data);
        setTotalData(response?.total);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Customer Inventory'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Customer Inventory'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
  };

  const deleteCustomerInventory = async (id) => {
    try {
      const response = await CustomerInventoryService.deleteCustomerInventory(id);
      if (response) {
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Customer Inventory'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Customer Inventory'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Customer Inventory'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    const res = await CustomerInventoryService.getCustomerInventories(id, page_no, page_size, sort_column, sort_column_value, search_value);
    setCustomerInventoriesData(res?.data);
    setTotalData(res?.total);
    setSelectedId('');
    handleClose();
  };

  const allRows = [];
  customerInventoriesData?.map((eachCustomerInv, index) => {
      const eachData = {
        id: <MDTypography component={Link} to={customerPagePermission.edit ? `/manage-customers/customer-inventories/edit?id=${eachCustomerInv.id}&customer_id=${id}&customer_name=${customer_name}` : ''} variant="body2" color="text">
                {eachCustomerInv?.id}
              </MDTypography>,
        supplier_id: (<MDTypography display="block" variant="body2">
          {eachCustomerInv?.supplier?.name}
          </MDTypography>
          ),
        size_id: (<MDTypography display="block" variant="body2">
              {(eachCustomerInv?.size) ? (eachCustomerInv?.size?.name) : ('')}
              </MDTypography>
            ),
        item_id: (<MDTypography display="block" variant="body2">
                {(eachCustomerInv?.item) ? (eachCustomerInv?.item?.description) : ('')}
                </MDTypography>
              ),
        gsm: (<MDTypography display="block" variant="body2">
              {eachCustomerInv?.gsm ? numberFormat(eachCustomerInv?.gsm, false) : ''}
              </MDTypography>
            ),
        stock: (<MDTypography display="block" variant="body2">
              {eachCustomerInv?.stock * 1}
          </MDTypography>
          ),
        remaining_qty: (<MDTypography display="block" variant="body2">
              {eachCustomerInv?.remaining_qty * 1}
          </MDTypography>
          ),
        action: (
              <MDBox display="flex">
                {pagePermission.status ? (
                  // <MDSwitch
                  //   checked={eachCustomerInv.active ? true : false}
                  //   color="info"
                  //   title="Status"
                  //   onChange={() => updateCustomerInventoryStatus(eachCustomerInv.id , !eachCustomerInv.active)}
                  // />
                  ''
                ) : ''}
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-customers/customer-inventories/edit?id=${eachCustomerInv.id}&customer_id=${id}&customer_name=${customer_name}`} title="Edit" display="block" variant="body2" color="info">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.new ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-customers/customer-inventories/add?id=${eachCustomerInv.id}&customer_id=${id}&customer_name=${customer_name}`} title="Copy" display="block" variant="body2" color="secondary">
                    <Icon fontSize="small">content_copy_icon</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.remove ? (
                  // <MDTypography mt={1} component={Link} onClick={() => handleClickOpen(eachCustomerInv.id)} title="Remove" display="block" variant="body2" color="error">
                  //   <Icon fontSize="small">delete</Icon>
                  // </MDTypography>
                  ''
                ) : ''}
                {renderAlertSB}
              </MDBox>
            )
      };
      allRows[index] = eachData;
  });

  return {
    columns: [
      { Header: "Id", accessor: "id", width: "8%", align: "left" },
      { Header: "Supplier", accessor: "supplier_id", sorting_key: 'suppliers.name', width: "20%", align: "left" },
      { Header: "Size", accessor: "size_id", sorting_key: 'sizes.name', width: "15%", align: "left" },
      { Header: "Item", accessor: "item_id", sorting_key: 'items.description', width: "15%", align: "left" },
      { Header: "GSM", accessor: "gsm", width: "12%", align: "left" },
      { Header: "Stock", accessor: "stock", width: "10%", align: "left" },
      { Header: "Remaining Qty.", accessor: "remaining_qty", width: "10%", align: "left" },
      ...(pagePermission.edit) ? [{ Header: "actions", accessor: "action", width: "10%", align: "center" }] : [],
    ],
    rows: allRows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deleteCustomerInventory: deleteCustomerInventory,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
    id: id,
    ref: ref,
    customer_name: customer_name,
  };
}
