/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";
import MDTabPanel from "components/MDTabPanel";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDDatePicker from "components/MDDatePicker";
import MDDateRangePicker from "components/MDDateRangePicker.js";
import MDCircularProgress from "components/MDCircularProgress";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dayjs from 'dayjs';
import { formatDateTime } from "utility/helpers";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import ScheduleWorkOrdersData from "layouts/schedule-work-orders/data";

function ScheduleWorkOrders() {
  const {
    columns,
    rows,
    totalData,
    operatorCompletedTotalWo,
    newProgressTotalData,
    completedTotalData,
    loggedUserRole,
    handleTabChange,
    value,
    handleOperatorViewTabChange,
    operatorTabValue,
    submitHandler,
    endedAtOpen,
    rejectOpen,
    markCompleteOpen,
    updateCompletedDateOpen,
    nextJobOpen,
    nextPrintJobOpen,
    handleClose,
    selectedId,
    workOrderId,
    updateEndedAt,
    rejectScheduleWorkOrder,
    markCompleteScheduleWorkOrder,
    newProgressSearchWoId,
    setNewProgressSearchWoId,
    newProgressSearchCustomer,
    setNewProgressSearchCustomer,
    completedSearchCustomer,
    setCompletedSearchCustomer,
    newProgressSearchSize,
    setNewProgressSearchSize,
    completedSearchWoId,
    setCompletedSearchWoId,
    completedSearchSize,
    setCompletedSearchSize,
    operatorSearchWoId,
    setOperatorSearchWoId,
    operatorSearchCustomer,
    setOperatorSearchCustomer,
    operatorSearchSize,
    setOperatorSearchSize,
    operatorCompletedSearchWoId,
    setOperatorCompletedSearchWoId,
    operatorCompletedSearchCustomer,
    setOperatorCompletedSearchCustomer,
    operatorCompletedSearchSize,
    setOperatorCompletedSearchSize,
    operatorNewProgressWoIdOptions,
    operatorCompletedWoIdOptions,
    adminNewProgressWoIdOptions,
    adminCompletedWoIdOptions,
    customersOptions,
    sizesOptions,
    newProgressSearchOperator,
    setNewProgressSearchOperator,
    completedSearchOperator,
    setCompletedSearchOperator,
    operatorOptions,
    newProgressSearchCreatedDate,
    setNewProgressSearchCreatedDate,
    completedSearchCreatedDate,
    setCompletedSearchCreatedDate,
    operatorSearchCreatedDate,
    setOperatorSearchCreatedDate,
    operatorCompletedSearchCreatedDate,
    setOperatorCompletedSearchCreatedDate,
    completedSearchCompletionDate,
    setCompletedSearchCompletionDate,
    operatorCompletedSearchCompletionDate,
    setOperatorCompletedSearchCompletionDate,
    operatorCompletedSearchStartedDate,
    setOperatorCompletedSearchStartedDate,
    completedSearchStartedDate,
    setCompletedSearchStartedDate,
    newProgressSearchHandler,
    completedSearchHandler,
    operatorSearchHandler,
    operatorCompletedSearchHandler,
    endedAtLoading,
    rejectLoading,
    markCompleteLoading,
    searchLoading,
    saveBtnLoading,
    renderInProgressRowSubComponent,
    renderCompletedRowSubComponent,
    renderOperatorRowSubComponent,
    renderOperatorCompletedRowSubComponent,
    page,
    pageSize,
    getData,
    sortColumn,
    sortColumnValue,
    searchValue,
    currentJobInProgressWoId,
    handleRowClick,
    adminNewProgressRowExpanded,
    adminCompletedRowExpanded,
    operatorNewProgressRowExpanded,
    operatorCompletedRowExpanded,
    operatorId,
    setOperatorId,
    operatorDropdown,
    startDate,
    endDate,
    setDate,
    onFilterChange,
    filter,
    clearFilters,
    generateAndDownloadExcelFile,
    reportsubmitHandler,
    reportSearchLoading,
    operatorJobSheet,
    jobReportHandlePrint,
    printLoading,
    excelLoading,
    workOrderPermission,
    customerPermission,
    updateCompletedDateValue,
    setUpdateCompletedDateValue,
    updateCompletedDateLoading,
    updateCompletedDate } = ScheduleWorkOrdersData();

    const minWidthD = useMediaQuery('(min-width:1432px)');
    const minWidth = useMediaQuery('(min-width:768px)');

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
          <MDBox pt={4} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={"0.75rem"}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <MDTypography variant="h6" color="white">
                          Manage Schedule Work Orders
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>

                  {loggedUserRole === 'Operator' &&
                    <>
                      <MDBox px={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs sx={{padding: 0, margin: 0.75}} value={operatorTabValue} onChange={handleOperatorViewTabChange}  textColor="primary" aria-label="basic tabs example">
                          <Tab label="New & In Progress Work Orders Jobs" />
                          {(<Tab label="Completed Work Orders Jobs" />)}
                          {(<Tab label="Operator Job Sheet Report" />)}
                        </Tabs>
                      </MDBox>
                      <MDBox>
                        <MDTabPanel value={operatorTabValue} index={0}>
                          <MDBox>
                            <MDBox component="form" role="form" method="POST" onSubmit={(e) => operatorSearchHandler(e, false)}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={1}>
                                  <MDBox>
                                    <MDSelect
                                      label="WO#"
                                      fullWidth
                                      value={operatorSearchWoId}
                                      onChange={(e) => setOperatorSearchWoId(e.target.value)}
                                      options={operatorNewProgressWoIdOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.5}>
                                  <MDBox>
                                    <MDSelect
                                      label="Party"
                                      fullWidth
                                      value={operatorSearchCustomer}
                                      onChange={(e) => setOperatorSearchCustomer(e.target.value)}
                                      options={customersOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.5}>
                                  <MDBox>
                                    <MDSelect
                                      label="Size"
                                      fullWidth
                                      value={operatorSearchSize}
                                      onChange={(e) => setOperatorSearchSize(e.target.value)}
                                      options={sizesOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.5}>
                                  <MDBox>
                                    <MDDatePicker
                                      label="Created Date"
                                      value={dayjs(operatorSearchCreatedDate).isValid() ? dayjs(operatorSearchCreatedDate) : null}
                                      format="DD-MM-YYYY"
                                      onChange={(newValue) => {
                                        setOperatorSearchCreatedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                      }}
                                      slotProps={{ textField: {
                                          fullWidth: true,
                                        },
                                        field: {
                                          clearable: true
                                        },
                                        textField: { size: 'small' }
                                      }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={6} md={1} display="flex" alignItems="center">
                                  <MDBox>
                                    <MDButton variant="gradient" color="success" fullWidth type="submit" title={'Search'} iconOnly>
                                      {searchLoading?.operator_search ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                    </MDButton>
                                  </MDBox>
                                  <MDBox ml={2}>
                                    <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => operatorSearchHandler(e, true)} title={'Clear'} iconOnly>
                                      {searchLoading?.operator_clear ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.5}>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </MDBox>
                          <MDBox mt={2} mb={2}>
                            <DataTable
                              table={{ columns, rows }}
                              isSorted={false}
                              entriesPerPage={false}
                              showTotalEntries={false}
                              totalData={totalData}
                              verticalRowPadding={1}
                              horizontalRowPadding={1}
                              doubleClick={false}
                              noEndBorder={true}
                              noRowBottomBorder={true}
                              borderTop={true}
                              renderRowSubComponent={renderOperatorRowSubComponent}
                              handleRowClick={handleRowClick}
                              rowExpanded={operatorNewProgressRowExpanded}
                            />
                          </MDBox>
                        </MDTabPanel>

                        <MDTabPanel value={operatorTabValue} index={1}>

                          <MDBox>
                            <MDBox component="form" role="form" method="POST" onSubmit={(e) => operatorCompletedSearchHandler(e, false)}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={1}>
                                  <MDBox>
                                    <MDSelect
                                      label="WO#"
                                      fullWidth
                                      value={operatorCompletedSearchWoId}
                                      onChange={(e) => setOperatorCompletedSearchWoId(e.target.value)}
                                      options={operatorCompletedWoIdOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDSelect
                                      label="Party"
                                      fullWidth
                                      value={operatorCompletedSearchCustomer}
                                      onChange={(e) => setOperatorCompletedSearchCustomer(e.target.value)}
                                      options={customersOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDSelect
                                      label="Size"
                                      fullWidth
                                      value={operatorCompletedSearchSize}
                                      onChange={(e) => setOperatorCompletedSearchSize(e.target.value)}
                                      options={sizesOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDDatePicker
                                      label="Completed Date"
                                      value={dayjs(operatorCompletedSearchCompletionDate).isValid() ? dayjs(operatorCompletedSearchCompletionDate) : null}
                                      format="DD-MM-YYYY"
                                      onChange={(newValue) => {
                                        setOperatorCompletedSearchCompletionDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                      }}
                                      slotProps={{ textField: {
                                          fullWidth: true,
                                        },
                                        field: {
                                          clearable: true
                                        },
                                        textField: { size: 'small' }
                                      }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDDatePicker
                                      label="Created Date"
                                      value={dayjs(operatorCompletedSearchCreatedDate).isValid() ? dayjs(operatorCompletedSearchCreatedDate) : null}
                                      format="DD-MM-YYYY"
                                      onChange={(newValue) => {
                                        setOperatorCompletedSearchCreatedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                      }}
                                      slotProps={{ textField: {
                                          fullWidth: true,
                                        },
                                        field: {
                                          clearable: true
                                        },
                                        textField: { size: 'small' }
                                      }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDDatePicker
                                      label="Job Printed Date"
                                      value={dayjs(operatorCompletedSearchStartedDate).isValid() ? dayjs(operatorCompletedSearchStartedDate) : null}
                                      format="DD-MM-YYYY"
                                      onChange={(newValue) => {
                                        setOperatorCompletedSearchStartedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                      }}
                                      slotProps={{ textField: {
                                          fullWidth: true,
                                        },
                                        field: {
                                          clearable: true
                                        },
                                        textField: { size: 'small' }
                                      }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={6} md={1} display="flex" alignItems="center">
                                  <MDBox>
                                    <MDButton variant="gradient" color="success" fullWidth type="submit" title={'Search'} iconOnly>
                                      {searchLoading?.operator_completed_search ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                    </MDButton>
                                  </MDBox>
                                  <MDBox ml={2}>
                                    <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => operatorCompletedSearchHandler(e, true)} title={'Clear'} iconOnly>
                                      {searchLoading?.operator_completed_clear ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </MDBox>
                          <MDBox mt={2} mb={2}>
                            <DataTable
                              entriesPerPage={{
                                "defaultValue": pageSize
                              }}
                              canSearch={true}
                              showTotalEntries={true}
                              table={{ columns, rows }}
                              isSorted={true}
                              totalData={operatorCompletedTotalWo}
                              initialPageIndex={page - 1}
                              getData={getData}
                              pageCount={Math.ceil(operatorCompletedTotalWo/pageSize)}
                              sortCol={sortColumn}
                              sortColValue={sortColumnValue}
                              searchVal={searchValue}
                              verticalRowPadding={1}
                              horizontalRowPadding={1}
                              doubleClick={false}
                              noEndBorder={true}
                              noRowBottomBorder={true}
                              borderTop={true}
                              renderRowSubComponent={renderOperatorCompletedRowSubComponent}
                              handleRowClick={handleRowClick}
                              rowExpanded={operatorNewProgressRowExpanded}
                            />
                          </MDBox>

                        </MDTabPanel>

                        <MDTabPanel value={operatorTabValue} index={2}>
                          <MDBox>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                  <MDBox>
                                      <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                          <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                          <Grid container>
                                              {loggedUserRole == 'Admin' ? (
                                                  <Grid item xs={12} lg={minWidthD ? 3 : 2.5} md={6} px={1}>
                                                      <MDBox mb={1}>
                                                          <MDSelect
                                                              label="Operator"
                                                              fullWidth
                                                              name="operator_id"
                                                              value={operatorId}
                                                              onChange={(e) => setOperatorId(e.target.value != null ? e.target.value : '')}
                                                              options={operatorDropdown}
                                                          />
                                                      </MDBox>
                                                  </Grid>
                                              ) : ''}
                                              <Grid item lg={5} xs={12} px={1} md={8}>
                                                  <MDBox mb={1}>
                                                      <MDDateRangePicker
                                                          label="Start Date"
                                                          name="start_date"
                                                          value={startDate ? startDate : null}
                                                          format="DD-MM-YYYY"
                                                          onChange={(newValue) => {
                                                              setDate(newValue, 'start_date');
                                                          }}
                                                          endLabel="End Date"
                                                          endName="end_date"
                                                          endValue={endDate ? endDate : null}
                                                          endOnChange={(newValue) => {
                                                              setDate(newValue, 'end_date');
                                                          }}
                                                          onFilterChange={onFilterChange}
                                                          filter={filter}
                                                      />
                                                  </MDBox>
                                              </Grid>
                                              <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                                  <MDBox mt={minWidth ? -1.5 : ''}>
                                                      <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={reportsubmitHandler} iconOnly>
                                                      {reportSearchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                                      </MDButton>
                                                  </MDBox>
                                                  <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                      <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                          <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                                      </MDButton>
                                                  </MDBox>
                                              </Grid>
                                              {operatorJobSheet.length > 0 && (
                                                  <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                                      <MDBox mt={minWidth ? -1.5 : ''}>
                                                          <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={jobReportHandlePrint}>
                                                          {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                          </MDButton>
                                                      </MDBox>
                                                      <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                          <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(operatorJobSheet)} iconOnly>
                                                          {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                          </MDButton>
                                                      </MDBox>
                                                  </Grid>
                                              )}
                                          </Grid>
                                      </fieldset>
                                  </MDBox>
                                  <MDBox mt={2} mb={2}>
                                      <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                          <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                              <TableBody>
                                                  <TableRow>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '4%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Sr No.</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Job Card No.</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '15%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Party Name</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Narration</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '23%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Job Type</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Size</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Printing Date</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Color</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '4%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Quantity</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '3%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Type</TableCell>
                                                    <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem'}}>Duration</TableCell>
                                                  </TableRow>
                                                  {operatorJobSheet?.map((eachItem) => {
                                                      let i = 0;
                                                      let rowColor = '#e0f8f7';
                                                      let po = '';
                                                      return (
                                                          <>
                                                              {loggedUserRole == 'Admin' ? (
                                                                  <TableRow>
                                                                      <TableCell colSpan={10} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Operator :</b> {eachItem?.operator_name}</TableCell>
                                                                  </TableRow>
                                                                  ) : ''}
                                                              {eachItem?.items?.map((item) => {
                                                                  i = i + 1;
                                                                  rowColor = i % 2 === 0 ? '#e0f8f7' : '#effbfb';
                                                                  return (
                                                                      <TableRow sx={{ backgroundColor: rowColor }}>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{i}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: (workOrderPermission?.edit ? 'info.main' : '')}} component={workOrderPermission?.edit ? Link : ''} to={workOrderPermission?.edit ? `/manage-work-orders/edit?id=${item?.wo_id}&close_window=1` : ``} target={'_blank'}>{item?.wo_id}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', borderRight: '1px solid #ccc', color: (customerPermission?.edit ? 'info.main' : '')}} component={customerPermission?.edit ? Link : ''} to={customerPermission?.edit ? `/manage-customers/edit?id=${item?.customer_id}&close_window=1` : ``}target={'_blank'} ><b>{item?.customer_name}</b></TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.description}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.job_type}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.size}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{formatDateTime(item?.printing_date)}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.color}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'right'}}>{item?.quantity * 1}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.type}</TableCell>
                                                                          <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem'}}><b>{item?.duration}</b></TableCell>
                                                                      </TableRow>
                                                                  )
                                                              })}
                                                          </>
                                                      )
                                                  })}
                                                  {operatorJobSheet.length == 0 && (
                                                      <TableRow>
                                                          <TableCell colSpan={10} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                                      </TableRow>
                                                  )}
                                              </TableBody>
                                          </Table>
                                      </TableContainer>
                                  </MDBox>
                                </Grid>
                            </Grid>
                          </MDBox>
                        </MDTabPanel>

                      </MDBox>
                    </>
                  }
                  {loggedUserRole == 'Admin' &&
                    <>
                      <MDBox px={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs sx={{padding: 0, margin: 0.75}} value={value} onChange={handleTabChange}  textColor="primary" aria-label="basic tabs example">
                          <Tab label="New & In Progress Work Orders" />
                          {(<Tab label="Completed Work Orders" />)}
                          {(<Tab label="Operator Job Sheet Report" />)}
                        </Tabs>
                      </MDBox>
                      <MDBox>
                        <MDTabPanel value={value} index={0}>
                          <MDBox>
                            <MDBox component="form" role="form" method="POST" onSubmit={(e) => newProgressSearchHandler(e, false)}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={1}>
                                  <MDBox>
                                    <MDSelect
                                      label="WO#"
                                      fullWidth
                                      value={newProgressSearchWoId}
                                      onChange={(e) => setNewProgressSearchWoId(e.target.value)}
                                      options={adminNewProgressWoIdOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.5}>
                                  <MDBox>
                                    <MDSelect
                                      label="Party"
                                      fullWidth
                                      value={newProgressSearchCustomer}
                                      onChange={(e) => setNewProgressSearchCustomer(e.target.value)}
                                      options={customersOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.5}>
                                  <MDBox>
                                    <MDSelect
                                      label="Size"
                                      fullWidth
                                      value={newProgressSearchSize}
                                      onChange={(e) => setNewProgressSearchSize(e.target.value)}
                                      options={sizesOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.5}>
                                  <MDBox>
                                    <MDSelect
                                      label="Operators"
                                      fullWidth
                                      value={newProgressSearchOperator}
                                      onChange={(e) => setNewProgressSearchOperator(e.target.value)}
                                      options={operatorOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDDatePicker
                                      label="Created Date"
                                      value={dayjs(newProgressSearchCreatedDate).isValid() ? dayjs(newProgressSearchCreatedDate) : null}
                                      format="DD-MM-YYYY"
                                      onChange={(newValue) => {
                                        setNewProgressSearchCreatedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                      }}
                                      slotProps={{ textField: {
                                          fullWidth: true,
                                        },
                                        field: {
                                          clearable: true
                                        },
                                        textField: { size: 'small' }
                                      }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={6} md={1} display="flex" alignItems="center">
                                  <MDBox>
                                    <MDButton variant="gradient" color="success" fullWidth type="submit" title={'Seach'} iconOnly>
                                      {searchLoading?.progress_search ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                    </MDButton>
                                  </MDBox>
                                  <MDBox ml={2}>
                                    <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => newProgressSearchHandler(e, true)} title={'Clear'} iconOnly>
                                      {searchLoading?.progress_clear ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                              <Grid item xs={0} md={0.5}></Grid>
                            </MDBox>
                          </MDBox>
                          <MDBox mt={1} mb={2} component="form" role="form" method="POST" onSubmit={submitHandler}>
                            <DataTable
                              table={{ columns, rows }}
                              isSorted={false}
                              entriesPerPage={false}
                              showTotalEntries={false}
                              totalData={newProgressTotalData}
                              verticalRowPadding={1}
                              horizontalRowPadding={1}
                              doubleClick={false}
                              noEndBorder={true}
                              noRowBottomBorder={true}
                              borderTop={true}
                              renderRowSubComponent={renderInProgressRowSubComponent}
                              handleRowClick={handleRowClick}
                              rowExpanded={adminNewProgressRowExpanded}
                            />
                            <Grid mt={2} container>
                              <Grid item md={10} xs={9}></Grid>
                              <Grid item md={2} xs={3}>
                                <MDBox mr={2}>
                                  <MDButton variant="gradient" color="info" fullWidth type="submit">
                                    {saveBtnLoading ? <MDCircularProgress size={20}/> : 'Save'}
                                  </MDButton>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDTabPanel>
                        <MDTabPanel value={value} index={1}>
                          <MDBox>
                            <MDBox component="form" role="form" method="POST" onSubmit={(e) => completedSearchHandler(e, false)}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={1}>
                                  <MDBox>
                                    <MDSelect
                                      label="WO#"
                                      fullWidth
                                      value={completedSearchWoId}
                                      onChange={(e) => setCompletedSearchWoId(e.target.value)}
                                      options={adminCompletedWoIdOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={1.5}>
                                  <MDBox>
                                    <MDSelect
                                      label="Party"
                                      fullWidth
                                      value={completedSearchCustomer}
                                      onChange={(e) => setCompletedSearchCustomer(e.target.value)}
                                      options={customersOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                  <MDBox>
                                    <MDSelect
                                      label="Size"
                                      fullWidth
                                      value={completedSearchSize}
                                      onChange={(e) => setCompletedSearchSize(e.target.value)}
                                      options={sizesOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={1.5}>
                                  <MDBox>
                                    <MDSelect
                                      label="Operators"
                                      fullWidth
                                      value={completedSearchOperator}
                                      onChange={(e) => setCompletedSearchOperator(e.target.value)}
                                      options={operatorOptions}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDDatePicker
                                      label="Completed Date"
                                      value={dayjs(completedSearchCompletionDate).isValid() ? dayjs(completedSearchCompletionDate) : null}
                                      format="DD-MM-YYYY"
                                      onChange={(newValue) => {
                                        setCompletedSearchCompletionDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                      }}
                                      slotProps={{ textField: {
                                          fullWidth: true,
                                        },
                                        field: {
                                          clearable: true
                                        },
                                        textField: { size: 'small' }
                                      }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDDatePicker
                                      label="Created Date"
                                      value={dayjs(completedSearchCreatedDate).isValid() ? dayjs(completedSearchCreatedDate) : null}
                                      format="DD-MM-YYYY"
                                      onChange={(newValue) => {
                                        setCompletedSearchCreatedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                      }}
                                      slotProps={{ textField: {
                                          fullWidth: true,
                                        },
                                        field: {
                                          clearable: true
                                        },
                                        textField: { size: 'small' }
                                      }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <MDBox>
                                    <MDDatePicker
                                      label="Job Printed Date"
                                      value={dayjs(completedSearchStartedDate).isValid() ? dayjs(completedSearchStartedDate) : null}
                                      format="DD-MM-YYYY"
                                      onChange={(newValue) => {
                                        setCompletedSearchStartedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                      }}
                                      slotProps={{ textField: {
                                          fullWidth: true,
                                        },
                                        field: {
                                          clearable: true
                                        },
                                        textField: { size: 'small' }
                                      }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={6} md={1} display="flex" alignItems="center">
                                  <MDBox>
                                    <MDButton variant="gradient" color="success" fullWidth type="submit" title={'Seach'} iconOnly>
                                      {searchLoading?.completed_search ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                    </MDButton>
                                  </MDBox>
                                  <MDBox ml={2}>
                                    <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => completedSearchHandler(e, true)} title={'Clear'} iconOnly>
                                      {searchLoading?.completed_clear ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </MDBox>
                          <MDBox mt={1} mb={2}>
                            <DataTable
                              table={{ columns, rows }}
                              isSorted={false}
                              entriesPerPage={false}
                              showTotalEntries={false}
                              totalData={completedTotalData}
                              verticalRowPadding={1}
                              horizontalRowPadding={1}
                              doubleClick={false}
                              noEndBorder={true}
                              noRowBottomBorder={true}
                              borderTop={true}
                              renderRowSubComponent={renderCompletedRowSubComponent}
                              handleRowClick={handleRowClick}
                              rowExpanded={adminCompletedRowExpanded}
                            />
                          </MDBox>
                        </MDTabPanel>
                        <MDTabPanel value={value} index={2}>
                          <MDBox>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <MDBox>
                                        <fieldset style={{ padding: '5px', borderColor: '#c1a9a92b' }}>
                                            <legend style={{ fontSize: '13px', padding: '5px' }}>Filters:</legend>
                                            <Grid container>
                                                {loggedUserRole == 'Admin' ? (
                                                    <Grid item xs={12} lg={minWidthD ? 3 : 2.5} md={6} px={1}>
                                                        <MDBox mb={1}>
                                                            <MDSelect
                                                                label="Operator"
                                                                fullWidth
                                                                name="operator_id"
                                                                value={operatorId}
                                                                onChange={(e) => setOperatorId(e.target.value != null ? e.target.value : '')}
                                                                options={operatorDropdown}
                                                            />
                                                        </MDBox>
                                                    </Grid>
                                                ) : ''}
                                                <Grid item lg={5} xs={12} px={1} md={8}>
                                                    <MDBox mb={1}>
                                                        <MDDateRangePicker
                                                            label="Start Date"
                                                            name="start_date"
                                                            value={startDate ? startDate : null}
                                                            format="DD-MM-YYYY"
                                                            onChange={(newValue) => {
                                                                setDate(newValue, 'start_date');
                                                            }}
                                                            endLabel="End Date"
                                                            endName="end_date"
                                                            endValue={endDate ? endDate : null}
                                                            endOnChange={(newValue) => {
                                                                setDate(newValue, 'end_date');
                                                            }}
                                                            onFilterChange={onFilterChange}
                                                            filter={filter}
                                                        />
                                                    </MDBox>
                                                </Grid>
                                                <Grid item lg={minWidthD ? 1 : 1.5} xs={6} md={2} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                                    <MDBox mt={minWidth ? -1.5 : ''}>
                                                        <MDButton variant="gradient" color="success" fullWidth title={'Search'} onClick={reportsubmitHandler} iconOnly>
                                                        {reportSearchLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>search_icon</Icon>}
                                                        </MDButton>
                                                    </MDBox>
                                                    <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                        <MDButton variant="gradient" color="primary" fullWidth onClick={(e) => clearFilters(e)} title={'Clear'} iconOnly>
                                                            <Icon sx={{ fontWeight: "bold" }}>clear_icon</Icon>
                                                        </MDButton>
                                                    </MDBox>
                                                </Grid>
                                                {operatorJobSheet.length > 0 && (
                                                    <Grid item lg={minWidthD ? 1 : 1.5} md={2} xs={6} px={1} mb={minWidth ? '' : 1} display="flex" alignItems="center">
                                                        <MDBox mt={minWidth ? -1.5 : ''}>
                                                            <MDButton variant="gradient" color="secondary" fullWidth title={'Download Pdf'} iconOnly onClick={jobReportHandlePrint}>
                                                            {printLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>picture_as_pdf_icon </Icon>}
                                                            </MDButton>
                                                        </MDBox>
                                                        <MDBox ml={2} mt={minWidth ? -1.5 : ''}>
                                                            <MDButton variant="gradient" color="warning" fullWidth title={'Download Excel'} onClick={(e) => generateAndDownloadExcelFile(operatorJobSheet)} iconOnly>
                                                            {excelLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>border_all_icon</Icon>}
                                                            </MDButton>
                                                        </MDBox>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </fieldset>
                                    </MDBox>
                                    <MDBox mt={2} mb={2}>
                                        <TableContainer component={Paper} sx={{ borderRadius: 1 }}>
                                            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '4%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Sr No.</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Job Card No.</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '15%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Party Name</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Narration</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '23%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Job Type</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Size</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Printing Date</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '6%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Color</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '4%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Quantity</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '3%', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}>Type</TableCell>
                                                        <TableCell sx={{paddingTop: '5px', paddingBottom: '5px',fontWeight: 'bold', backgroundColor: '#dddddd', width: '10%', fontSize: '0.875rem'}}>Duration</TableCell>
                                                    </TableRow>
                                                    {operatorJobSheet?.map((eachItem) => {
                                                        let i = 0;
                                                        let rowColor = '#e0f8f7';
                                                        let po = '';
                                                        return (
                                                            <>
                                                                {loggedUserRole == 'Admin' ? (
                                                                    <TableRow>
                                                                        <TableCell colSpan={9} sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', borderRight: '1px solid #ccc'}}><b>Operator :</b> {eachItem?.operator_name}</TableCell>
                                                                    </TableRow>
                                                                    ) : ''}
                                                                {eachItem?.items?.map((item) => {
                                                                    i = i + 1;
                                                                    rowColor = i % 2 === 0 ? '#e0f8f7' : '#effbfb';
                                                                    return (
                                                                        <TableRow sx={{ backgroundColor: rowColor }}>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'center'}}>{i}</TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', color: 'info.main', textAlign: 'center'}} component={Link} to={workOrderPermission?.edit ? `/manage-work-orders/edit?id=${item?.wo_id}&close_window=1` : ``} target={'_blank'}>{item?.wo_id}</TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.875rem', borderRight: '1px solid #ccc', color: 'info.main'}} component={Link} to={customerPermission?.edit ? `/manage-customers/edit?id=${item?.customer_id}&close_window=1` : ``} target={'_blank'}><b>{item?.customer_name}</b></TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.description}</TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc'}}>{item?.job_type}</TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'center'}}>{item?.size}</TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'center'}}>{formatDateTime(item?.printing_date)}</TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'center'}}>{item?.color}</TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'center'}}><b>{item?.quantity * 1}</b></TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', borderRight: '1px solid #ccc', textAlign: 'center'}}>{item?.type}</TableCell>
                                                                            <TableCell sx={{paddingTop: '5px', paddingBottom: '5px', fontSize: '0.775rem', textAlign: 'center'}}><b>{item?.duration}</b></TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </>
                                                        )
                                                    })}
                                                    {operatorJobSheet.length == 0 && (
                                                        <TableRow>
                                                            <TableCell colSpan={9} sx={{paddingTop: '5px', paddingBottom: '5px', width: '6%', fontSize: '0.875rem', color: 'red', fontWeight: 'bold', textAlign: 'center'}}>No records found.</TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </MDBox>
                                </Grid>
                            </Grid>
                          </MDBox>
                        </MDTabPanel>
                      </MDBox>
                    </>
                  }
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        <Footer />
      </DashboardLayout>

      <MDDialog
        open={endedAtOpen}
        onClose={handleClose}
        successAction={() => updateEndedAt(selectedId, workOrderId)}
        dialogTitle={'Are you Sure?'}
        cancleButton={'No, Please!'}
        successButton={endedAtLoading ? <MDCircularProgress size={20} sx={{marginLeft: "32px", marginRight: "32px"}}/> : 'Yes, End Job!'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
      >
      <p>{`You want to end job ${currentJobInProgressWoId} from the scheduled work orders!`}</p>
      </MDDialog>

      <MDDialog
        open={rejectOpen}
        onClose={handleClose}
        successAction={() => rejectScheduleWorkOrder(selectedId)}
        dialogTitle={'Are you Sure?'}
        cancleButton={'No, Please!'}
        successButton={rejectLoading ? <MDCircularProgress size={20} sx={{marginLeft: "28px", marginRight: "28px"}}/> : 'Yes, Reject!'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
      >
      <p>You want to reject this schedule work order!</p>
      </MDDialog>

      <MDDialog
        open={markCompleteOpen}
        onClose={handleClose}
        successAction={() => markCompleteScheduleWorkOrder(selectedId)}
        dialogTitle={'Are you Sure?'}
        cancleButton={'No, Please!'}
        successButton={markCompleteLoading ? <MDCircularProgress size={20} sx={{marginLeft: "72px", marginRight: "72px"}}/> : 'Yes, Mark as Completed!'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
      >
      <p>You want to mark this schedule work order as Completed!</p>
      </MDDialog>

      <MDDialog
        open={updateCompletedDateOpen}
        onClose={handleClose}
        successAction={() => updateCompletedDate(selectedId)}
        dialogTitle={'Update Completed Date'}
        cancleButton={'Back'}
        successButton={updateCompletedDateLoading ? <MDCircularProgress size={20} sx={{marginLeft: "14px", marginRight: "14px"}}/> : 'Update'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <MDBox component="form" role="form" method="POST" mt={1} py={3} fullWidth>
          <Grid container>
          <Grid items px={2} md={12} xs={12}>
            <MDBox mb={2}>
              <MDDatePicker
                label="Completed Date"
                name="completed_date"
                value={dayjs(updateCompletedDateValue).isValid() ? dayjs(updateCompletedDateValue) : null}
                format="DD-MM-YYYY"
                onChange={(newValue) => {
                  setUpdateCompletedDateValue(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                }}
                slotProps={{ textField: {
                    fullWidth: true,
                    required: true
                  },
                  field: {
                    clearable: true
                  },
                  textField: { size: 'small' },
                }}
              />
            </MDBox>
          </Grid>
          </Grid>
        </MDBox>
      </MDDialog>

      <MDDialog
        open={nextJobOpen}
        onClose={handleClose}
        successAction={handleClose}
        successButton={'OK!'}
        showCancelButton={false}
        successButtonColor={'info'}
        icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
      >
      <p>{`Please end the current job ${currentJobInProgressWoId} in progress to start a new job!`}</p>
      </MDDialog>

      <MDDialog
        open={nextPrintJobOpen}
        onClose={handleClose}
        successAction={handleClose}
        successButton={'OK!'}
        showCancelButton={false}
        successButtonColor={'info'}
        icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
      >
      <p>{`Please end the current job ${currentJobInProgressWoId} in progress to view a job card!`}</p>
      </MDDialog>
    </>
  );
}

export default ScheduleWorkOrders;
