/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';

import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCheckbox from 'components/MDCheckbox';
import MDTabPanel from "components/MDTabPanel";
import MDCircularProgress from "components/MDCircularProgress";
import MDSwitch from "components/MDSwitch";
import MDDatePicker from "components/MDDatePicker";
import MDDialog from "components/MDDialog";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import UserService from "services/user-service";
import customAlert from "components/MDAlert/customAlert";
import PermissionService from "services/permission-service";
import PrintingMachineUserService from "services/printing-machine-user-service";
import { adminPermissions, operatorPermissions, salesPersonPermissions } from "utility/default-role-permissions";
import { useCache } from "context";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Form() {
  const { action } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [role, setRole] = useState('');

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [changeRole, setChangeRole] = useState([]);
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [userPermissionData, setUserPermissionData] = useState([]);
  const [userCustomPermissionData, setUserCustomPermissionData] = useState([]);

  const [active, setActive] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [fromDateRequired, setFromDateRequired] = useState(false);
  const [toDateRequired, setToDateRequired] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const [value, setValue] = useState(0);
  const [isCustom, setIsCustom] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCommission, setShowCommission] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const { cacheItems, setCacheItems } = useCache();
  const matches_permission = useMediaQuery('(min-width:1695px)');
  const matches_custom_permission = useMediaQuery('(min-width:830px)');
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      setIsCustom(true);
    } else {
      setIsCustom(false);
    }
  };

  let roleOptions = [
    {
      key:'Admin',
      value:'Admin',
      disabled: false,
    },
    {
      key:'Operator',
      value:'Operator',
      disabled: false,
    },
    {
      key:'Salesperson',
      value:'Salesperson',
      disabled: false,
    }
  ];

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    address: "",
    email: "",
    role:"",
    phone:"",
    password:"",
    confirm_password: "",
    commission_pct: "",
    allowed_ip: "*",
    created_at: "",
  });

  const [errors, setErrors] = useState({
    firstNameError: false,
    lastNameError: false,
    middleNameError: false,
    addressError: false,
    emailError: false,
    phoneError: false,
    passwordError: false,
    confirmPasswordError: false,
    commissionPctError: false,
    allowedIpError: false,
  });

  useEffect(() => {
    if (action === "add" && id == null) return;

    if (action === "edit" || (action === "add" && id != null)) {//When edit action is call
      const showUser = async() => {
        try {
          const data = await UserService.showUser(id);
          setRole(data.role);
          setHeaderTitle(data.first_name + (data.middle_name ? (" " + data.middle_name) : "") + " " + data.last_name);
          setInputs(data);
          if (data.role == 'Salesperson') {
            setShowCommission(true);
          }
          setActive(data.active);
          setFromDate(data.from_date);
          setToDate(data.to_date);
          setFromDateRequired(data.from_date ? true : false);
          setToDateRequired(data.to_date ? true : false);
          setCreatedBy(data.created_by.first_name + (data.created_by.middle_name ? (" " + data.created_by.middle_name) : "") + " " + data.created_by.last_name);
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            const state = {color: 'error', icon: 'warning', msg: res.message, title: 'User'};
            navigate('/user-management', {state: state});
          } else {
            const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'User'};
            navigate('/user-management', {state: state});
          }
        }
      }
      showUser();
    } else if (action === "permission") {//When permission action is call
      const getUserPermissions = async() => {
        const allOldUserPermissions = await UserService.getUserPermissions(id);
        const oldUserPermissions = allOldUserPermissions.route_permission.concat(allOldUserPermissions.custom_permission);
        const allUserPermission = [];
        oldUserPermissions.map((userPermission, index) => {
          allUserPermission[index] = userPermission.permission_id;
        });

        let permissions;
        if (cacheItems.hasOwnProperty('permissions')) {
          permissions = cacheItems['permissions'];
        } else {
          permissions = await PermissionService.getPermissions(); // Fetch customers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              permissions: permissions,
          }));
        }
        const newUserPermissionArray = [];
        permissions.map(async(permission) => {
          if (!allUserPermission.includes(permission.id)) {
            let newUserPermission = {};
            if (role == "Admin") {
              newUserPermission = {
                user_id: id,
                permission_id: permission.id,
                view: adminPermissions[permission.handler]?.view ? (adminPermissions[permission.handler]?.view) : 1,
                status: adminPermissions[permission.handler]?.status ? (adminPermissions[permission.handler]?.status) : 1,
                new: adminPermissions[permission.handler]?.new ? (adminPermissions[permission.handler]?.new) : 1,
                edit: adminPermissions[permission.handler]?.edit ? (adminPermissions[permission.handler]?.edit) : 1,
                remove: adminPermissions[permission.handler]?.remove ? (adminPermissions[permission.handler]?.remove) : 1,
              };
              newUserPermissionArray.push(newUserPermission);
            } else if (role == "Operator") {
              newUserPermission = {
                user_id: id,
                permission_id: permission.id,
                view: operatorPermissions[permission.handler]?.view ? (operatorPermissions[permission.handler]?.view) : 0,
                status: operatorPermissions[permission.handler]?.status ? (operatorPermissions[permission.handler]?.status) : 0,
                new: operatorPermissions[permission.handler]?.new ? (operatorPermissions[permission.handler]?.new) : 0,
                edit: operatorPermissions[permission.handler]?.edit ? (operatorPermissions[permission.handler]?.edit) : 0,
                remove: operatorPermissions[permission.handler]?.remove ? (operatorPermissions[permission.handler]?.remove) : 0,
              };
              newUserPermissionArray.push(newUserPermission);
            } else if (role == "Salesperson") {
              newUserPermission = {
                user_id: id,
                permission_id: permission.id,
                view: salesPersonPermissions[permission.handler]?.view ? (salesPersonPermissions[permission.handler]?.view) : 0,
                status: salesPersonPermissions[permission.handler]?.status ? (salesPersonPermissions[permission.handler]?.status) : 0,
                new: salesPersonPermissions[permission.handler]?.new ? (salesPersonPermissions[permission.handler]?.new) : 0,
                edit: salesPersonPermissions[permission.handler]?.edit ? (salesPersonPermissions[permission.handler]?.edit) : 0,
                remove: salesPersonPermissions[permission.handler]?.remove ? (salesPersonPermissions[permission.handler]?.remove) : 0,
              };
              newUserPermissionArray.push(newUserPermission);
            }
          }
        });
        if (newUserPermissionArray.length > 0) {
          const array = {
            'permission': newUserPermissionArray,
          };
          const res = await UserService.addUserPermissions(array);
        }
        const currentUserPermission = await UserService.getUserPermissions(id);
        setUserPermissionData(currentUserPermission.route_permission);
        setUserCustomPermissionData(currentUserPermission.custom_permission);
      }
      getUserPermissions();
    }
  }, [action]);

  const handleClickOpen = (id) => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const unAssignUser = async (changeRole) => {
      setLoading(false);
      if (changeRole == 'Salesperson') {
        setShowCommission(true);
        setInputs({
          ...inputs,
          ['role']: changeRole,
        });
      } else if (changeRole != 'Salesperson') {
        setShowCommission(false);
        setInputs({
          ...inputs,
          ['role']: changeRole,
          ['commission_pct']: null,
        });
      } else {
        setInputs({
          ...inputs,
          ['role']: changeRole,
        });
      }
      setOpen(false);
  };

  const changeHandler = async (e) => {
    if (e.target.name == 'role' && e.target.value != 'Operator') {
      try {
        const response = await PrintingMachineUserService.getPrintingMachineUsersByUserId(id);
        if (response.length > 0) {
          setChangeRole(e.target.value);
          handleClickOpen();
          return;
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'User'};
          navigate('/user-management', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'User'};
          navigate('/user-management', {state: state});
        }
      }
    }
    if (e.target.name == 'password') {
      setErrors({
        passwordError: false,
      });
    }
    if (e.target.name == 'phone') {
      setErrors({
        phoneError: false,
      });
    }
    if (e.target.name == 'email') {
      setErrors({
        emailError: false,
      });
    }
    if (e.target.name == 'commission_pct') {
      setErrors({
        commissionPctError: false,
      });
    }
    if (e.target.name == 'confirm_password') {
      setErrors({
        confirmPasswordError: false,
      });
    }
    setLoading(false);
    if (e.target.name == 'role' && e.target.value == 'Salesperson') {
      setShowCommission(true);
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name == 'role' && e.target.value != 'Salesperson') {
      setShowCommission(false);
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
        ['commission_pct']: null,
      });
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  //Logic to save and update user
  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (inputs.first_name.trim().length < 1) {
      setErrors({ ...errors, firstNameError: true });
      return;
    }

    if (inputs.last_name.trim().length < 1) {
      setErrors({ ...errors, lastNameError: true });
      return;
    }

    if (inputs.address.trim().length < 1) {
      setErrors({ ...errors, addressError: true });
      return;
    }

    if (inputs.allowed_ip.trim().length < 1) {
      setErrors({ ...errors, allowedIpError: true });
      return;
    }

    const mailFormat = /^\w+[\+]*([\.-]?(\+)*\w+[\+]*)*[\+]*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    const phoneFormat = /^[0-9]{10}$/;
    if (inputs.phone?.length > 0 && !inputs.phone.toString().trim().match(phoneFormat)) {
      setErrors({ ...errors, phoneError: true });
      return;
    }

    const commissionPattern = /^([0-9]{1,2}(\.[0-9]{1,2})?|100(\.00?)?)$/;
    if (inputs.commission_pct?.length > 0 && !inputs.commission_pct.trim().match(commissionPattern)) {
      setErrors({ ...errors, commissionPctError: true });
      return;
    }

    const passwordFormat = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\@\$\!\%\*\?\&])[A-Za-z\[0-9\]\@\$\!\%\*\?\&]{8,}/;
    if (inputs.password?.length > 0 && !inputs.password.trim().match(passwordFormat)) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    if (inputs.confirm_password || inputs.password) {
      // in the api the confirmed password should be the same with the current password, not the new one
      if (inputs.confirm_password?.trim() !== inputs.password.trim()) {
        setErrors({ ...errors, confirmPasswordError: true });
        return;
      }
      if (inputs.password?.trim().length < 8) {
        setErrors({ ...errors, passwordError: true });
        return;
      }
    }

    if (fromDateRequired && fromDate == null) {
      setFromDateError(true);
      return;
    }

    if (toDateRequired && toDate == null) {
      setToDateError(true);
      return;
    }

    let newUser = { first_name: inputs.first_name, middle_name: inputs.middle_name, last_name: inputs.last_name, address: inputs.address, email: inputs.email, role: inputs.role, phone: inputs.phone, active: active ? 1 : 0, commission_pct: inputs.commission_pct, from_date: fromDate, to_date: toDate, allowed_ip: inputs.allowed_ip };

    if (inputs.password?.length > 0) {
      newUser = { first_name: inputs.first_name, middle_name: inputs.middle_name, last_name: inputs.last_name, address: inputs.address, email: inputs.email, role: inputs.role, phone: inputs.phone, password: inputs.password, active: active ? 1 : 0, commission_pct: inputs.commission_pct, from_date: fromDate, to_date: toDate, allowed_ip: inputs.allowed_ip };
    }

    if(!newUser.role){
      openAlertSB({color: 'warning', icon: 'star', msg: "Role is mandatory", title: 'User'});
      setLoading(false);
    } else {
      if (action === 'add') {
        try {
          const response = await UserService.addUser(newUser);
          setLoading(false);
          if (response) {
            if (cacheItems.hasOwnProperty('users') || cacheItems.hasOwnProperty('salesperson') || cacheItems.hasOwnProperty('operators')) {
              // Create a new object without the key
              const { users, salesperson, ...rest } = cacheItems; // Destructure to exclude key
              setCacheItems(rest); // Set the new cache items without key
            }
            let permissions;
            if (cacheItems.hasOwnProperty('permissions')) {
              permissions = cacheItems['permissions'];
            } else {
              permissions = await PermissionService.getPermissions(); // Fetch customers from the API
              setCacheItems(prevCache => ({
                  ...prevCache,
                  permissions: permissions,
              }));
            }

            const newUserPermissionArray = [];
            permissions.map(async(permission) => {
              let newUserPermission = {};
              if (response.role == "Admin") {
                newUserPermission = {
                  user_id: response.id,
                  permission_id: permission.id,
                  view: adminPermissions[permission.handler]?.view ? (adminPermissions[permission.handler]?.view) : 1,
                  status: adminPermissions[permission.handler]?.status ? (adminPermissions[permission.handler]?.status) : 1,
                  new: adminPermissions[permission.handler]?.new ? (adminPermissions[permission.handler]?.new) : 1,
                  edit: adminPermissions[permission.handler]?.edit ? (adminPermissions[permission.handler]?.edit) : 1,
                  remove: adminPermissions[permission.handler]?.remove ? (adminPermissions[permission.handler]?.remove) : 1,
                };
                newUserPermissionArray.push(newUserPermission);
              } else if (response.role == "Operator") {
                newUserPermission = {
                  user_id: response.id,
                  permission_id: permission.id,
                  view: operatorPermissions[permission.handler]?.view ? (operatorPermissions[permission.handler]?.view) : 0,
                  status: operatorPermissions[permission.handler]?.status ? (operatorPermissions[permission.handler]?.status) : 0,
                  new: operatorPermissions[permission.handler]?.new ? (operatorPermissions[permission.handler]?.new) : 0,
                  edit: operatorPermissions[permission.handler]?.edit ? (operatorPermissions[permission.handler]?.edit) : 0,
                  remove: operatorPermissions[permission.handler]?.remove ? (operatorPermissions[permission.handler]?.remove) : 0,
                };
                newUserPermissionArray.push(newUserPermission);
              } else if (response.role == "Salesperson") {
                newUserPermission = {
                  user_id: response.id,
                  permission_id: permission.id,
                  view: salesPersonPermissions[permission.handler]?.view ? (salesPersonPermissions[permission.handler]?.view) : 0,
                  status: salesPersonPermissions[permission.handler]?.status ? (salesPersonPermissions[permission.handler]?.status) : 0,
                  new: salesPersonPermissions[permission.handler]?.new ? (salesPersonPermissions[permission.handler]?.new) : 0,
                  edit: salesPersonPermissions[permission.handler]?.edit ? (salesPersonPermissions[permission.handler]?.edit) : 0,
                  remove: salesPersonPermissions[permission.handler]?.remove ? (salesPersonPermissions[permission.handler]?.remove) : 0,
                };
                newUserPermissionArray.push(newUserPermission);
              }
            });
            if (newUserPermissionArray.length > 0) {
              const array = {
                'permission': newUserPermissionArray,
              };
              const res = await UserService.addUserPermissions(array);
            }
            const state = {color: 'success', icon: 'check', msg: 'User Added Successfully!', title: 'User'};
            navigate('/user-management', { state: state });
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'User'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'User'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      } else {
        try {
          const response = await UserService.updateUser(id, newUser);
          setLoading(false);
          if (response) {
            if (cacheItems.hasOwnProperty('users') || cacheItems.hasOwnProperty('salesperson') || cacheItems.hasOwnProperty('operators')) {
              // Create a new object without the key
              const { users, salesperson, ...rest } = cacheItems; // Destructure to exclude key
              setCacheItems(rest); // Set the new cache items without key
            }
            if (role !== response.role) {
              if (response.role != 'Operator') {
                try {
                  const res = await PrintingMachineUserService.deleteAllPrintingMachineUserByUserId(id);
                } catch (res) {
                  if (res.hasOwnProperty("message")) {
                    openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Printing Machine'});
                    setTimeout(function() {
                      closeAlertSB();
                    }, 4000);
                  } else {
                    const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Printing Machine'};
                    setTimeout(function() {
                      closeAlertSB();
                    }, 4000);

                  }
                }
              }

              try {
                const res = await UserService.deleteAllUserPermission(response.id);
              } catch (res) {
                if (res.hasOwnProperty("message")) {
                  openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'User'});
                  setTimeout(function() {
                    closeAlertSB();
                  }, 4000);

                } else {
                  const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'User'};
                  setTimeout(function() {
                    closeAlertSB();
                  }, 4000);

                }
              }

              let permissions;
              if (cacheItems.hasOwnProperty('permissions')) {
                permissions = cacheItems['permissions'];
              } else {
                permissions = await PermissionService.getPermissions(); // Fetch customers from the API
                setCacheItems(prevCache => ({
                    ...prevCache,
                    permissions: permissions,
                }));
              }

              const newUserPermissionArray = [];
              permissions.map(async(permission) => {
                let newUserPermission = {};
                if (response.role == "Admin") {
                  newUserPermission = {
                    user_id: response.id,
                    permission_id: permission.id,
                    view: adminPermissions[permission.handler]?.view ? (adminPermissions[permission.handler]?.view) : 1,
                    status: adminPermissions[permission.handler]?.status ? (adminPermissions[permission.handler]?.status) : 1,
                    new: adminPermissions[permission.handler]?.new ? (adminPermissions[permission.handler]?.new) : 1,
                    edit: adminPermissions[permission.handler]?.edit ? (adminPermissions[permission.handler]?.edit) : 1,
                    remove: adminPermissions[permission.handler]?.remove ? (adminPermissions[permission.handler]?.remove) : 1,
                  };
                  newUserPermissionArray.push(newUserPermission);
                } else if (response.role == "Operator") {
                  newUserPermission = {
                    user_id: response.id,
                    permission_id: permission.id,
                    view: operatorPermissions[permission.handler]?.view ? (operatorPermissions[permission.handler]?.view) : 0,
                    status: operatorPermissions[permission.handler]?.status ? (operatorPermissions[permission.handler]?.status) : 0,
                    new: operatorPermissions[permission.handler]?.new ? (operatorPermissions[permission.handler]?.new) : 0,
                    edit: operatorPermissions[permission.handler]?.edit ? (operatorPermissions[permission.handler]?.edit) : 0,
                    remove: operatorPermissions[permission.handler]?.remove ? (operatorPermissions[permission.handler]?.remove) : 0,
                  };
                  newUserPermissionArray.push(newUserPermission);
                } else if (response.role == "Salesperson") {
                  newUserPermission = {
                    user_id: response.id,
                    permission_id: permission.id,
                    view: salesPersonPermissions[permission.handler]?.view ? (salesPersonPermissions[permission.handler]?.view) : 0,
                    status: salesPersonPermissions[permission.handler]?.status ? (salesPersonPermissions[permission.handler]?.status) : 0,
                    new: salesPersonPermissions[permission.handler]?.new ? (salesPersonPermissions[permission.handler]?.new) : 0,
                    edit: salesPersonPermissions[permission.handler]?.edit ? (salesPersonPermissions[permission.handler]?.edit) : 0,
                    remove: salesPersonPermissions[permission.handler]?.remove ? (salesPersonPermissions[permission.handler]?.remove) : 0,
                  };
                  newUserPermissionArray.push(newUserPermission);
                }
              });
              if (newUserPermissionArray.length > 0) {
                const array = {
                  'permission': newUserPermissionArray,
                };
                const res = await UserService.addUserPermissions(array);
              }
            }
            openAlertSB({color: 'success', icon: 'check', msg: 'User Updated Successfully!', title: 'User'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'User'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'User'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      }
    }

    return () => {
      setInputs({
        first_name: "",
        last_name: "",
        middle_name: "",
        address: "",
        email: "",
        role:"",
        phone:"",
        password:"",
        confirm_password:"",
        commission_pct: "",
        allowed_ip: "",
      });

      setErrors({
        firstNameError: false,
        lastNameError: false,
        middleNameError: false,
        addressError: "",
        emailError: false,
        passwordError: false,
        confirmPasswordError: false,
        commissionPctError: False,
        allowedIpError: false,
      });
    };
  };

  //Starts User Permissions section
  const [headerState, setHeaderState] = useState({
    view: false,
    status: false,
    new: false,
    edit: false,
    remove: false,
  });

  //Route Permissions
  const routeColumns = [
    { Header: "", accessor: "permission", width: matches_permission ? "26%" : "40%", align: "left" },
    { Header: "", accessor: "action", width: matches_permission ? "10%" : "20%", align: "left" },
    { Header: (<MDCheckbox label=<MDTypography sx={{ "color": "#7b809a", "fontSize": "0.875rem", "fontWeight": "700" }} display="block" variant="body2">VIEW</MDTypography> checked={headerState.view} onChange={(e) => onHeaderChange("view", e.target.checked)} />), accessor: "view", width: matches_permission ? "12%" : "8%", align: "left" },
    { Header: (<MDCheckbox label=<MDTypography sx={{ "color": "#7b809a", "fontSize": "0.875rem", "fontWeight": "700" }} display="block" variant="body2">STATUS</MDTypography> checked={headerState.status} onChange={(e) => onHeaderChange("status", e.target.checked)} />), accessor: "status", width: matches_permission ? "12%" : "8%", align: "left" },
    { Header: (<MDCheckbox label=<MDTypography sx={{ "color": "#7b809a", "fontSize": "0.875rem", "fontWeight": "700" }} display="block" variant="body2">NEW</MDTypography> checked={headerState.new} onChange={(e) => onHeaderChange("new", e.target.checked)} />), accessor: "new", width: matches_permission ? "12%" : "8%", align: "left" },
    { Header: (<MDCheckbox label=<MDTypography sx={{ "color": "#7b809a", "fontSize": "0.875rem", "fontWeight": "700" }} display="block" variant="body2">EDIT</MDTypography> checked={headerState.edit} onChange={(e) => onHeaderChange("edit", e.target.checked)} />), accessor: "edit", width: matches_permission ? "12%" : "8%", align: "left" },
    { Header: (<MDCheckbox label=<MDTypography sx={{ "color": "#7b809a", "fontSize": "0.875rem", "fontWeight": "700" }} display="block" variant="body2">REMOVE</MDTypography> checked={headerState.remove} onChange={(e) => onHeaderChange("remove", e.target.checked)} />), accessor: "remove", width: matches_permission ? "12%" : "8%", align: "left" },
  ];

  const routeRows = [];
  userPermissionData.map((eachPermission, index) => {
    const marginLeft = (eachPermission.permission.indent > 0) ? ((eachPermission.permission.indent*10) + 'px !important') : '0px !important';
    const isCheckAll = (eachPermission.view && eachPermission.status && eachPermission.new && eachPermission.edit && eachPermission.remove) ? true : false;
    const cols = {
      permission:(
            <MDBox sx={{ marginLeft: marginLeft}}>
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {eachPermission.permission.name}
              </MDTypography>
          </MDBox>
          ),
      action:(<MDTypography sx={{ cursor: 'pointer' }} variant="caption" color="info" fontWeight="medium" onClick={(e) => onCheckedAllClick(index, !isCheckAll)}>
            {isCheckAll ? 'Un-Check-All' : 'Check-All'}
          </MDTypography>),
      view:(<MDCheckbox
            checked={eachPermission.view}
            onChange={(e) => onPermissionChange(index, "view", e.target.checked)}
          />),
      status:(<MDCheckbox
          checked={eachPermission.status}
          onChange={(e) => onPermissionChange(index, "status", e.target.checked)}
        />),
      new:(<MDCheckbox
          checked={eachPermission.new}
          onChange={(e) => onPermissionChange(index, "new", e.target.checked)}
        />),
      edit:(<MDCheckbox
          checked={eachPermission.edit}
          onChange={(e) => onPermissionChange(index, "edit", e.target.checked)}
        />),
      remove:(<MDCheckbox
          checked={eachPermission.remove}
          onChange={(e) => onPermissionChange(index, "remove", e.target.checked)}
        />),
    };
    routeRows[index] = cols;
  });

  // Custom Permission
  const customColumns = [
    { Header: "", accessor: "permission", width: matches_custom_permission ? "30%" : "32%", align: "left" },
    { Header: "", accessor: "action", width: matches_custom_permission ? "30%" : "32%", align: "left" },
    { Header: (<MDCheckbox label=<MDTypography sx={{ "color": "#7b809a", "fontSize": "0.875rem", "fontWeight": "700" }} display="block" variant="body2">VIEW</MDTypography> checked={headerState.view} onChange={(e) => onHeaderChange("view", e.target.checked)} />), accessor: "view", width: matches_custom_permission ? "20%" : "18%", align: "left" },
    { Header: (<MDCheckbox label=<MDTypography sx={{ "color": "#7b809a", "fontSize": "0.875rem", "fontWeight": "700" }} display="block" variant="body2">EDIT</MDTypography> checked={headerState.edit} onChange={(e) => onHeaderChange("edit", e.target.checked)} />), accessor: "edit", width: matches_custom_permission ? "20%" : "18%", align: "left" },
  ];

  const customRows = [];
  userCustomPermissionData.map((eachPermission, index) => {
    const marginLeft = (eachPermission.permission.indent > 0) ? ((eachPermission.permission.indent*10) + 'px !important') : '0px !important';
    const isCheckAll = (eachPermission.view && eachPermission.edit) ? true : false;
    const cols = {
      permission:(
            <MDBox sx={{ marginLeft: marginLeft}}>
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {eachPermission.permission.name}
              </MDTypography>
          </MDBox>
          ),
      action:(<MDTypography sx={{ cursor: 'pointer' }} variant="caption" color="info" fontWeight="medium" onClick={(e) => onCheckedAllClick(index, !isCheckAll)}>
            {isCheckAll ? 'Un-Check-All' : 'Check-All'}
          </MDTypography>),
      view:(<MDCheckbox
            checked={eachPermission.view}
            onChange={(e) => onPermissionChange(index, "view", e.target.checked)}
          />),
      edit:(<MDCheckbox
          checked={eachPermission.edit}
          onChange={(e) => onPermissionChange(index, "edit", e.target.checked)}
        />),
    };
    customRows[index] = cols;
  });

  const columns = isCustom ? customColumns : routeColumns;
  const rows = isCustom ? customRows : routeRows;

  const onPermissionChange = (index, fieldName, fieldValue) => {
    if (isCustom) {
      const cloneUserCustomPermission = [...userCustomPermissionData]
      cloneUserCustomPermission[index][fieldName] = fieldValue
      setUserCustomPermissionData(cloneUserCustomPermission);
    } else {
      const cloneUserPermission = [...userPermissionData]
      cloneUserPermission[index][fieldName] = fieldValue
      setUserPermissionData(cloneUserPermission);
    }
  }

  const onCheckedAllClick = (index, value) => {
    if (isCustom) {
      const cloneUserCustomPermission = [...userCustomPermissionData]
      cloneUserCustomPermission[index]['view'] = value
      cloneUserCustomPermission[index]['status'] = value
      cloneUserCustomPermission[index]['new'] = value
      cloneUserCustomPermission[index]['edit'] = value
      cloneUserCustomPermission[index]['remove'] = value
      setUserCustomPermissionData(cloneUserCustomPermission);
    } else {
      const cloneUserPermission = [...userPermissionData]
      cloneUserPermission[index]['view'] = value
      cloneUserPermission[index]['status'] = value
      cloneUserPermission[index]['new'] = value
      cloneUserPermission[index]['edit'] = value
      cloneUserPermission[index]['remove'] = value
      setUserPermissionData(cloneUserPermission);
    }
  }

  const onHeaderChange = (fieldName, fieldValue) => {
    setHeaderState({
      ...headerState,
      [fieldName]: fieldValue,
    });
    if (isCustom) {
      const cloneUserCustomPermission = [...userCustomPermissionData]
      cloneUserCustomPermission.map((permission, index) => {
        cloneUserCustomPermission[index][fieldName] = fieldValue
      });
      setUserCustomPermissionData(cloneUserCustomPermission);
    } else {
      const cloneUserPermission = [...userPermissionData]
      cloneUserPermission.map((permission, index) => {
        cloneUserPermission[index][fieldName] = fieldValue
      });
      setUserPermissionData(cloneUserPermission);
    }
  };

  const submitPermissionHandler = async (e) => {
    e.preventDefault();

    if (isCustom) {
      userCustomPermissionData.map(async(permission) => {
        const updatePermission = {
          user_id : permission.user_id,
          permission_id: permission.permission_id,
          view: permission.view ? 1: 0,
          status: 0,
          new: 0,
          edit: permission.edit ? 1: 0,
          remove: 0,
        }
        const updateUserPermission = await UserService.updateUserPermissions(permission.id, updatePermission);
      });
    } else {
      userPermissionData.map(async(permission) => {
        const updatePermission = {
          user_id : permission.user_id,
          permission_id: permission.permission_id,
          view: permission.view ? 1: 0,
          status: permission.status ? 1: 0,
          new: permission.new ? 1: 0,
          edit: permission.edit ? 1: 0,
          remove: permission.remove ? 1: 0,
        }
        const updateUserPermission = await UserService.updateUserPermissions(permission.id, updatePermission);
      });
    }

    openAlertSB({color: 'success', icon: 'check', msg: 'Permission Updated Successfully!', title: 'User Permission'})
    setTimeout(function() {
      closeAlertSB();
    }, 2000);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === "add" ? 'Add User' : (action === 'permission' ? ('Permission : ' + headerTitle) : ('Edit User : ' + headerTitle))}/>
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {action === 'add' || action === 'edit' ?
                <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.3rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={11}>
                      <MDTypography mt={1} variant="h6" color="white">
                        {action === "add" ? 'Add User' : ('Edit User : ' + headerTitle)}
                      </MDTypography>
                    </Grid>
                      {action === "add" ? '' :
                        <Grid item xs={1} justifyContent="space-between">
                          <MDButton
                          component={Link}
                          to={`/user-management/permission?id=${id}`}
                          variant="outlined" color="white" iconOnly circular  title="Permissions">
                            <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>lock_open</Icon>
                          </MDButton>
                        </Grid>
                      }
                  </Grid>
                </MDBox>
                <MDBox py={3}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="First Name"
                            fullWidth
                            value={inputs.first_name}
                            name="first_name"
                            inputProps={{'maxLength':100}}
                            onChange={changeHandler}
                            error={errors.firstNameError}
                            required
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Middle Name"
                            fullWidth
                            value={inputs.middle_name}
                            name="middle_name"
                            inputProps={{'maxLength':100}}
                            onChange={changeHandler}
                            error={errors.middleNameError}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Last Name"
                            fullWidth
                            value={inputs.last_name}
                            name="last_name"
                            inputProps={{'maxLength':100}}
                            onChange={changeHandler}
                            error={errors.lastNameError}
                            required
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Address"
                            fullWidth
                            value={inputs.address}
                            name="address"
                            inputProps={{'maxLength':255}}
                            onChange={changeHandler}
                            error={errors.addressError}
                            required
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="email"
                            label="Email"
                            fullWidth
                            name="email"
                            value={inputs.email}
                            inputProps={{'maxLength':50, 'autoComplete':'off'}}
                            onChange={changeHandler}
                            error={errors.emailError}
                            required
                            readOnly={action == 'edit' && id ? true : false}
                            helperText={errors.emailError ? "Please enter a valid email." : ""}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Phone"
                            fullWidth
                            name="phone"
                            value={inputs.phone}
                            error={errors.phoneError}
                            onChange={changeHandler}
                            helperText={errors.phoneError ? "Phone should be 10 digits" : ""}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                            label="Role"
                            fullWidth
                            name="role"
                            value={inputs.role}
                            onChange={changeHandler}
                            options={roleOptions}
                            required
                            disabled={id==localStorage.getItem('user_id') ? true : false}
                          />
                        </MDBox>
                      </Grid>
                      {showCommission &&
                        <Grid item md={6} xs={12} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Commission %"
                              fullWidth
                              value={inputs.commission_pct}
                              name="commission_pct"
                              onChange={changeHandler}
                              error={errors.commissionPctError}
                              helperText={errors.commissionPctError ? "Please enter a valid number. It cannot have value more than 100 and more than 2 digits after the decimal point." : ""}
                            />
                          </MDBox>
                        </Grid>
                      }
                      <Grid item md={3} xs={6} px={3}>
                        <MDBox mb={2}>
                          <MDDatePicker
                            label="From Date"
                            name="from_date"
                            value={dayjs(fromDate).isValid() ? dayjs(fromDate) : null}
                            format="DD-MM-YYYY"
                            maxDate={dayjs(toDate)}
                            onChange={(newValue) => {
                              setFromDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                              if (dayjs(newValue).isValid()) {
                                setFromDateRequired(true);
                                setToDateRequired(true);
                              } else {
                                if (toDate) {
                                  setFromDateRequired(true);
                                  setToDateRequired(true);
                                } else {
                                  setFromDateRequired(false);
                                  setToDateRequired(false);
                                }
                              }
                            }}
                            slotProps={{ textField: {
                                fullWidth: true,
                                error: fromDateError,
                                required: fromDateRequired,
                              },
                              field: {
                                clearable: true
                              },
                              textField: { size: 'small' }
                            }}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={3} xs={6} px={3}>
                        <MDBox mb={2}>
                          <MDDatePicker
                            label="To Date"
                            name="to_date"
                            value={dayjs(toDate).isValid() ? dayjs(toDate) : null}
                            format="DD-MM-YYYY"
                            minDate={dayjs(fromDate)}
                            onChange={(newValue) => {
                              setToDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                              if (dayjs(newValue).isValid()) {
                                setFromDateRequired(true);
                                setToDateRequired(true);
                              } else {
                                if (fromDate) {
                                  setFromDateRequired(true);
                                  setToDateRequired(true);
                                } else {
                                  setFromDateRequired(false);
                                  setToDateRequired(false);
                                }
                              }
                          }}
                            slotProps={{ textField: {
                                fullWidth: true,
                                error: toDateError,
                                required: toDateRequired,
                              },
                              field: {
                                clearable: true
                              },
                              textField: { size: 'small' }
                            }}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Allowed IPs"
                            fullWidth
                            value={inputs.allowed_ip}
                            name="allowed_ip"
                            inputProps={{'maxLength':300}}
                            onChange={changeHandler}
                            error={errors.allowedIpError}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="password"
                            label="Password"
                            fullWidth
                            name="password"
                            inputProps={{'maxLength':50, 'autoComplete':'off'}}
                            onChange={changeHandler}
                            error={errors.passwordError}
                            required={id ? false : true}
                            helperText={errors.passwordError ? "Password should at least be 8 characters long and should have at least one number, one lowercase, one uppercase letter & one special character." : ""}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="password"
                            label="Confirm Password"
                            fullWidth
                            name="confirm_password"
                            inputProps={{'maxLength':50}}
                            onChange={changeHandler}
                            error={errors.confirmPasswordError}
                            required={id ? false : true}
                          />
                        </MDBox>
                      </Grid>
                      {action === 'edit' &&
                        <>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                name=""
                                value={createdBy}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={dayjs(inputs.created_at).format('DD-MM-YYYY HH:mm A')}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      }
                      {!showCommission &&
                        <Grid item xs={12} px={6}></Grid>
                      }

                      <Grid item xs={4} md={2} px={3}>
                        <MDBox>
                          <MDSwitch checked={active ? true : false} onChange={() => setActive(prev => !prev)} label="Active" inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} md={8}></Grid>
                      <Grid item xs={2} md={1}>
                        <MDBox mr={2}>
                          <MDButton component={Link} to={`/user-management`} variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item xs={2} md={1}>
                        <MDBox mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
              :
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.3rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={11}>
                      <MDTypography mt={1} variant="h6" color="white">
                        {action === "permission" ? inputs.first_name + " " + inputs.last_name + (isCustom ? ' : Custom Permissions' : ' : Core Permissions') : ''}
                      </MDTypography>
                    </Grid>
                      {action === "add" ? '' :
                        <Grid item xs={1} title="Edit">
                          <MDButton ml={5}
                          component={Link}
                          to={`/user-management/edit?id=${id}`}
                          variant="outlined" color="white" iconOnly circular>
                            <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>edit</Icon>
                          </MDButton>
                        </Grid>
                      }
                  </Grid>
                </MDBox>
                <MDBox>
                  <MDBox px={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs sx={{padding: 0, margin: 0.75}} value={value} onChange={handleTabChange} textColor="primary" aria-label="basic tabs example">
                      <Tab label="Core Permissions" {...a11yProps(0)} />
                      <Tab label="Custom Permissions" {...a11yProps(1)} />
                    </Tabs>
                  </MDBox>
                  <MDTabPanel value={value} index={0}>
                    <MDBox component="form" role="form" method="POST" onSubmit={submitPermissionHandler}>
                      <Grid container>
                        <Grid item xs={12} px={3}>
                          <MDBox mb={2}>
                            <DataTable
                              table={{ columns, rows }}
                              isSorted={false}
                              entriesPerPage={false}
                              showTotalEntries={false}
                              verticalRowPadding='0.5'
                              noEndBorder
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={10}></Grid>
                        <Grid item xs={6} md={1}>
                          <MDBox mr={2}>
                            <MDButton component={Link} to={`/user-management`} variant="gradient" color="primary" fullWidth>
                              Back
                            </MDButton>
                          </MDBox>
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <MDBox mr={3}>
                            <MDButton variant="gradient" color="info" fullWidth type="submit">
                              Save
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDTabPanel>
                  <MDTabPanel value={value} index={1}>
                    <MDBox component="form" role="form" method="POST" onSubmit={submitPermissionHandler}>
                      <Grid container>
                        <Grid item xs={12} px={3}>
                          <MDBox mb={2}>
                            <DataTable
                              table={{ columns, rows }}
                              isSorted={false}
                              entriesPerPage={false}
                              showTotalEntries={false}
                              verticalRowPadding='0.5'
                              noEndBorder
                            />
                          </MDBox>
                        </Grid>
                        <Grid item md={10}></Grid>
                        <Grid item xs={6} md={1}>
                          <MDBox mr={2}>
                            <MDButton component={Link} to={`/user-management`} variant="gradient" color="primary" fullWidth>
                              Back
                            </MDButton>
                          </MDBox>
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <MDBox mr={3}>
                            <MDButton variant="gradient" color="info" fullWidth type="submit">
                              Save
                            </MDButton>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDTabPanel>
                </MDBox>
              </Card>
              }
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <MDDialog
        open={open}
        onClose={handleClose}
        successAction={() => unAssignUser(changeRole)}
        dialogTitle={'Are you Sure?'}
        cancleButton={'No, Please!'}
        successButton={'Yes, UnAssign User!'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
        >
        <p>After the role change, the user will be unassigned as an operator from the printing machine.</p>
      </MDDialog>
      {renderAlertSB}
    </>
  );
}

export default Form;
