import HttpService from "./htttp.service";

class GroupService {
  // authEndpoint = process.env.API_URL;

  getMaterialGroups = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'material_group';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'material_group?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  };

  addMaterialGroup = async (data) => {
    const fetchUrl = 'material_group';
    return await HttpService.post(fetchUrl, data);
  };

  deleteMaterialGroup = async (id) => {
    const fetchUrl = 'material_group/' + id;
    return await HttpService.delete(fetchUrl);
  };

  updateGroupStatus = async (id, data) => {
    const fetchUrl = 'material_group/' + id;
    return await HttpService.patch(fetchUrl, data);
  };

  showMaterialGroup = async (id) => {
    const fetchUrl = 'material_group/' + id;
    return await HttpService.get(fetchUrl);
  };

  updateMaterialGroup = async (id, data) => {
    const fetchUrl = 'material_group/' + id;
    return await HttpService.put(fetchUrl, data);
  };
}

export default new GroupService();
