/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import dayjs from 'dayjs';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SizesService from "services/size-service";
import GroupsService from "services/group-service";

// @mui icons
import Icon from "@mui/material/Icon";
import { useCache } from "context";

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const location = useLocation();
  const [groupsOptions, setGroupsOptions] = useState([]);

  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [active, setActive] = useState(1);
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const { cacheItems, setCacheItems } = useCache();

  const [inputs, setInputs] = useState({
    name: "",
    group_id: "",
    remark: "",
    created_at: "",
  });

  const [errors, setErrors] = useState({
    nameError: false,
    groupIdError: false,
    remarkError: false,
  });

  useEffect(() => {
    const getGroupsData = async() => {
      try {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('material_groups')) {
          response = cacheItems['material_groups'];
        } else {
          response = await GroupsService.getMaterialGroups(); // Fetch MaterialGroups from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              material_groups: response,
          }));
        }
        if (response) {
          var array = [];
          response?.map((eachGroup, index) => {
              array[index] = {
                key: eachGroup.id,
                value: eachGroup.name,
                disabled: eachGroup?.active ? false : true,
              }
          });
          setGroupsOptions(array);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Material Group'};
          navigate('/manage-sizes', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Material Group'};
          navigate('/manage-sizes', {state: state});
        }
      }
    }
    getGroupsData();
    if (action === "add" && id == null) return;

    const showSize = async() => {
      try {
        const data = await SizesService.showSize(id);
        setHeaderTitle(data.name);
        setInputs(data);
        setCreatedBy(data.user.first_name + (data.user.middle_name ? (" " + data.user.middle_name) : "") + " " + data.user.last_name);
        setActive(data.active);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Material Group'};
          navigate('/manage-sizes', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Material Group'};
          navigate('/manage-sizes', {state: state});
        }
      }
    }
    showSize();
  }, []);

  const changeHandler = (e) => {
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (inputs.name.trim().length < 1) {
      setErrors({ ...errors, nameError: true });
      return;
    }

    const newSize = { name: inputs.name, group_id: inputs.group_id, remark: inputs.remark, active: active ? 1 : 0 };

    if(!newSize.group_id){
      openAlertSB({color: 'warning', icon: 'star', msg: "Group is mandatory", title: 'Size'});
      setLoading(false);
    } else {
      if (action === 'add') {
        try {
          const response = await SizesService.addSize(newSize);
          setLoading(false);
          if (response) {
            if (cacheItems.hasOwnProperty('sizes') || cacheItems.hasOwnProperty('sizes_of_group_printing')) {
              // Create a new object without the key
              const { sizes, sizes_of_group_printing, ...rest } = cacheItems; // Destructure to exclude key
              setCacheItems(rest); // Set the new cache items without key
            }
            const state = {color: 'success', icon: 'check', msg: 'Size Added Successfully!', title: 'Size'};
            navigate('/manage-sizes', { state: state });
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Size'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Size'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      } else {
        try {
          const response = await SizesService.updateSize(id ,newSize);
          setLoading(false);
          if (response) {
            if (cacheItems.hasOwnProperty('sizes') || cacheItems.hasOwnProperty('sizes_of_group_printing')) {
              // Create a new object without the key
              const { sizes, sizes_of_group_printing, ...rest } = cacheItems; // Destructure to exclude key
              setCacheItems(rest); // Set the new cache items without key
            }
            openAlertSB({color: 'success', icon: 'check', msg: 'Size Updated Successfully!', title: 'Size'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Size'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Size'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      }
    }

    return () => {
      setInputs({
        name: "",
        group_id: "",
        remark: "",
      });

      setErrors({
        nameError: false,
        groupIdError: false,
        remarkError: false,
      });
    };
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add Size' : ('Edit Size : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.75rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <MDTypography variant="h6" color="white">
                    {action === 'add' ? 'Add Size' : ('Edit Size : ' + headerTitle)}
                  </MDTypography>
                </MDBox>
                <MDBox py={3}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Name"
                            fullWidth
                            value={inputs.name}
                            name="name"
                            inputProps={{'maxLength':30}}
                            onChange={changeHandler}
                            error={errors.nameError}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} md={6} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                            label="Group"
                            fullWidth
                            name="group_id"
                            value={inputs.group_id}
                            onChange={changeHandler}
                            options={groupsOptions}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Description"
                            fullWidth
                            value={inputs.remark}
                            name="remark"
                            inputProps={{'maxLength':125}}
                            onChange={changeHandler}
                            error={errors.remarkError}
                          />
                        </MDBox>
                      </Grid>

                      {action === 'edit' &&
                        <>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                name=""
                                value={createdBy}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={dayjs(inputs.created_at).format('DD-MM-YYYY HH:mm A')}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      }

                      <Grid item md={3} xs={5} px={3}>
                        <MDBox mb={2}>
                          <MDSwitch checked={active ? true : false} onChange={() => setActive(prev => !prev)} label="Active" inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                        </MDBox>
                      </Grid>

                      <Grid item md={10} xs={8}></Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={2}>
                          <MDButton component={Link} to="/manage-sizes" variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
