import HttpService from "./htttp.service";

class GrnItemService {

  getGrnItems = async(id) => {
    let fetchUrl = 'grn_items';
    if (id) {
      fetchUrl = 'grn_items?id=' + id;
    }
    return await HttpService.get(fetchUrl);
  }

  addGrnItem = async(data) => {
    const fetchUrl = 'grn_items';
    return await HttpService.post(fetchUrl, data);
  }

  updateGrnItem = async (id, data) => {
    const fetchUrl = 'grn_items/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  showGrnItem = async(id) => {
    const fetchUrl = 'grn_items/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteGrnItem = async (id) => {
    const fetchUrl = 'grn_items/' + id;
    return await HttpService.delete(fetchUrl);
  }

  deleteAllGrnItem = async (grn_id) => {
    const fetchUrl = 'grn_items?grn_id=' + grn_id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new GrnItemService();
