/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCheckbox from 'components/MDCheckbox';
import MDDatePicker from "components/MDDatePicker";
import DataTable from "examples/Tables/DataTable";
import useMediaQuery from '@mui/material/useMediaQuery';
import { WorkOrderContext } from "context";

// @mui icons
import WoPaymentService from "services/wo-payment-service";
import PaymentTypesService from "services/payment-type-service";
import CustomerPaymentService from "services/customer-payment-service";
import authPermission from "utility/auth-permissions";
import { useCache } from "context";

function Payment({calculateTotal, numberFormat, isCancel, onlyNumbers, isWithBill, customerId}) {

  const workOrderContext = useContext(WorkOrderContext);
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { hasPermission, systemConstant } = authPermission();
  const payment = workOrderContext.payment;
  const [customerCurrentBalance, setCustomerCurrentBalance] = useState(0);
  const [paymentTypeDropdown, setPaymentTypeDropdown] = useState([]);
  const [outstandingAmount, setOutstandingAmount] = useState(0);
  const { cacheItems, setCacheItems } = useCache();
  const minWidth = useMediaQuery('(min-width:1125px)');
  const minWidthXs = useMediaQuery('(min-width:768px)');

  useEffect(() => {
    const getWoPayments = async() => {
        let total = 0;
        payment.forEach((eachItem) => {
            total = Number(total) + Number(eachItem.amount);
        });
        setOutstandingAmount(total.toFixed(2));
    }
    getWoPayments();

    const getPaymentType = async() => {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('payment_types')) {
          response = cacheItems['payment_types'];
        } else {
          response = await PaymentTypesService.getPaymentTypes(); // Fetch customers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              payment_types: response,
          }));
        }
        if (response) {
          var dataArray = [];
          var otherDetail = [];
          response?.map((eachData, index) => {
            dataArray[index] = {
                key: eachData.id,
                value: eachData.name,
                disabled: eachData?.active ? false : true,
            }
          });
          setPaymentTypeDropdown(dataArray);
        }
    }
    getPaymentType();

    const getCustomerBalance = async () => {
      const balance = await CustomerPaymentService.getCurrentBalance(customerId ? customerId : 0);
      setCustomerCurrentBalance(balance);
    }

    getCustomerBalance();
  }, []);

  const onChangePaymentField = (index, fieldName, fieldValue) => {
    const clonePayment = [...payment];
    let total = outstandingAmount;
    if (fieldName == 'amount') {
        let diff = Number(fieldValue) - Number(clonePayment[index]['amount']);
        total = Number(total) + Number(diff);
        setOutstandingAmount(total.toFixed(2));
    }
    clonePayment[index][fieldName] = fieldValue;
    workOrderContext.setWoPayment(clonePayment);
  };

  const rows = [];
  let totalPaymentPaid = 0;
  payment?.map((eachItem, index) => {
    totalPaymentPaid = Number(totalPaymentPaid) + Number(eachItem?.amount);
    const cols = {
        payment_type: (
            <MDSelect
                fullWidth
                value={eachItem?.payment_type_id}
                onChange={(e) => onChangePaymentField(index, 'payment_type_id', e.target.value)}
                options={paymentTypeDropdown}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: 300,
                  "& .MuiNativeSelect-select": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
                height="40px"
            />),
        payment_date: (
            <MDDatePicker
                value={dayjs(eachItem?.payment_date).isValid() ? dayjs(eachItem?.payment_date) : dayjs(new Date())}
                format="DD-MM-YYYY"
                disabled={eachItem?.is_approved || isCancel ? true : false}
                onChange={(newValue) => {
                onChangePaymentField(index, 'payment_date', dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : dayjs(new Date().format('YYYY-MM-DD')))
                }}
                slotProps={{ textField: {
                    fullWidth: false,
                    required: true,
                },
                field: {
                    clearable: true
                },
                textField: { size: 'small' },
                }}
            />
            ),
        amount: (
            <MDInput
                type="text"
                value={eachItem?.amount}
                onChange={(e) => {onlyNumbers(e.target.value, '-decimal(16,4)') ? onChangePaymentField(index, 'amount', e.target.value) : ''}}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: '6.5rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
            />
            ),
        description: (
            <MDInput
                type="text"
                value={eachItem?.description}
                onChange={(e) => onChangePaymentField(index, 'description', e.target.value)}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: '27rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
                inputProps={{maxLength: 250}}
            />),
        is_cash: (
            <MDCheckbox
                checked={eachItem?.is_cash}
                onChange={(e) => onChangePaymentField(index, 'is_cash', e.target.checked)}
                disabled={eachItem?.is_approved || isCancel ? true : false}
            />),
        cheque_no: (
            <MDInput
                type="text"
                value={eachItem?.cheque_no}
                onChange={(e) => onChangePaymentField(index, 'cheque_no', e.target.value)}
                disabled={eachItem?.is_cash ? true : false}
                sx={{
                  width: '8rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
            />),
    };
    rows[index] = cols;
  });

  const columns = [
    { Header: "Payment Type", accessor: "payment_type", width: "18%", align: "left" },
    { Header: "Date", accessor: "payment_date", width: "14%", align: "left" },
    { Header: "Amount", accessor: "amount", width: "10%", align: "center" },
    { Header: "Description", accessor: "description", width: "20%", align: "left" },
    { Header: "Is Cash?", accessor: "is_cash", width: "8%", align: "center" },
    { Header: "Cheque No", accessor: "cheque_no", width: "10%", align: "center" },
  ];

  return (
    <Card sx={{borderRadius: "0.35rem"}}>
        <MDBox>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder={true}
            doubleClick={false}
            thBgColor="#a195952e"
            verticalRowPadding={0.3}
            horizontalRowPadding={0.5}
            tableBorderRadius={"0.35rem"}
            headerFontSize={"0.75rem"}
          />
        </MDBox>
        <MDBox mt={3} mb={1}>
            <Grid container>
                <Grid item xs={12} md={7} display={minWidth ? 'flex' : ''}>
                  <MDTypography display="block" variant="subtitle2"  sx={{fontWeight: "500"}} pl={2}>
                    Customer Current Balance : Rs.{customerCurrentBalance > 0 ? numberFormat(customerCurrentBalance) : '0.00'}
                  </MDTypography>
                  {customerCurrentBalance > 0 && (
                    <MDTypography display="block" variant="subtitle2" pl={2} mt={-1}>
                      <MDCheckbox
                        label={'Use Customer Balance'}
                        checked={workOrderContext.isUseCustomerBalance}
                        onChange={(e) => workOrderContext.setWoUseCustomerBalance(!workOrderContext.isUseCustomerBalance)}
                        disabled={isCancel ? true : false}
                      />
                    </MDTypography>)}
                </Grid>
                {hasPermission('wo_rates').view ? (
                  <Grid item xs={12} md={5}>
                      <MDTypography display="block" variant="subtitle2"  sx={{fontWeight: "500"}} textAlign={minWidthXs ? 'right' : 'left'} pr={minWidthXs ? 2 : ''} pl={minWidthXs ? '' : 2}>
                        Outstanding Amount : Rs.{((isWithBill ? calculateTotal(workOrderContext.woTotal) : workOrderContext.woTotal) - totalPaymentPaid) ? numberFormat(((isWithBill ? calculateTotal(workOrderContext.woTotal) : workOrderContext.woTotal) - totalPaymentPaid).toFixed(2)) : '0.00'}
                      </MDTypography>
                  </Grid>
                ) : ''}  
            </Grid>
        </MDBox>
    </Card>
  );
}

export default Payment;
