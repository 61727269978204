import dayjs from 'dayjs';

export const PDFTemplate = (paymentReceived, total, numberFormat) => {
    return  `<html>
  <head>
    <title>payment_received_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Payment Received</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Payment Date</th>
                <th style="background-color: #dddddd; width: 30%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Party</th>
                <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Payment Type</th>
                <th style="background-color: #dddddd; width: 10%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">JC# / Bill#</th>
                <th style="background-color: #dddddd; width: 20%; font-size: 0.875rem; border-bottom: 1px solid #000;">Amount</th>
            </tr>
            ${paymentReceived?.map((eachItem, index) => {
                let rowColor = index % 2 == 0 ? '#e0f8f7' : '#effbfb';
                    return `
                        <tr style="background-color: ${rowColor}">
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${dayjs(eachItem?.payment_date).format('DD-MMM-YYYY')}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${eachItem?.customer}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${eachItem?.payment_name}</td>
                            <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${eachItem?.work_order_id}</td>
                            <td style="font-size: 0.775rem; border-bottom: 1px solid #000; text-align: right;">${numberFormat(eachItem?.amount)}</td>
                        </tr>
                    `
            }).join('')}
            <tr>
                <td colspan="4" style="font-size: 0.875rem; border-right: 1px solid #000; text-align: right;"><b>Total:</b></td>
                <td style="font-size: 0.875rem; text-align: right;"><b>${numberFormat(total)}</b></td>
            </tr>        
        </table>
      </center>
    </div>
  </body>
</html>`

}
