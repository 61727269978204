/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import PermissionService from "services/permission-service";
import customAlert from "components/MDAlert/customAlert";
import authPermission from "utility/auth-permissions";

// @mui icons
import Icon from "@mui/material/Icon";
import { useCache } from "context";

export default function data() {

  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/permission');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.permission;

  const [permissionData, setPermissionData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const { cacheItems, setCacheItems } = useCache();
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const getPermissionData = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    const response = await PermissionService.getPermissions(page, pageSize, sortColumn, sortColumnValue, searchValue);
    setPermissionData(response?.data);
    setTotalData(response?.total);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    //getPermissionData(page_no, page_size, sort_column, sort_column_value, search_value);
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreservePermission(preserveData);
    getPermissionData(page_no, page_size, sortColumn, sortColumnValue, searchWord);
  };

  const deletePermission = async (id) => {
    try {
      const response = await PermissionService.deletePermission(id);
      if (cacheItems.hasOwnProperty('permissions')) {
        // Create a new object without the key
        const { permissions, ...rest } = cacheItems; // Destructure to exclude key
        setCacheItems(rest); // Set the new cache items without key
      }
      openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Permission'});
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Permission'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Permission'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    setSelectedId('');
    handleClose();
    getPermissionData(page_no, page_size, sort_column, sort_column_value, search_value);
  };

  //Insert Permission
  const insertPermissions = async(allRoutes, oldPermissions, indent = 0) => {
    if (cacheItems.hasOwnProperty('permissions')) {
      // Create a new object without the key
      const { permissions, ...rest } = cacheItems; // Destructure to exclude key
      setCacheItems(rest); // Set the new cache items without key
    }
    allRoutes.map(async(routes) => {
      const newPermission = {
        name: routes.name,
        handler: routes.route? routes.route: routes.name,
        display_order: routes.order ? routes.order : 0,
        indent: routes.indent ? routes.indent : indent,
        is_heading: routes.is_heading ? routes.is_heading : 0,
        is_custom: 0,
      };

      if (!oldPermissions.includes(newPermission.handler)) {
        const response = await PermissionService.addPermission(newPermission);
      } else {
        const permissionId = oldPermissions.indexOf(newPermission.handler);
        const response = await PermissionService.updatePermission(permissionId, newPermission);
      }

      if (routes.collapse?.length > 0) {
        insertPermissions(routes.collapse, oldPermissions, indent + 1);
      }
    });
    getPermissionData(page_no, page_size, sort_column, sort_column_value, search_value);
  }

  //Process new permission
  const processPermissions = async(allRoutes, allCustomRoutes) => {
    //Getting existing permission
    const existingPermission = await PermissionService.getPermissions();
    const oldPermissions = [];
    existingPermission.map((permission) => {
      oldPermissions[permission.id] = permission.handler;
    });

    //New permission
    insertPermissions(allRoutes, oldPermissions);

    //New custom permission
    allCustomRoutes.map(async(customRoutes) => {
      const newPermission = {
        name: customRoutes.name,
        handler: customRoutes.route,
        display_order: customRoutes.order ? customRoutes.order : 0,
        indent: customRoutes.indent ? customRoutes.indent : 0,
        is_heading: customRoutes.is_heading ? customRoutes.is_heading : 0,
        is_custom: 1,
      };
      if (!oldPermissions.includes(customRoutes.route)) {
        const response = await PermissionService.addPermission(newPermission);
      } else {
        const permissionId = oldPermissions.indexOf(customRoutes.route);
        const response = await PermissionService.updatePermission(permissionId, newPermission);
      }
    });

    openAlertSB({color: 'success', icon: 'check', msg: 'New Permissions Processed Successfully!', title: 'Permission'});
    getPermissionData(page_no, page_size, sort_column, sort_column_value, search_value);
  };

  let rows = [];
  permissionData?.map((eachPermission, index) => {
      const cols = {
        id:(
              <MDTypography component={Link} to={pagePermission.edit ? `/permission/edit?id=${eachPermission.id}` : ''} variant="body2" color="text">
                {eachPermission.id}
              </MDTypography>
            ),
        name:(
          <MDTypography display="block" variant="body2">
          {eachPermission.name}
          </MDTypography>
          ),
        handler:(
          <MDTypography display="block" variant="body2">
          {eachPermission.handler}
          </MDTypography>
          ),
        display_order:(
          <MDTypography display="block" variant="body2">
          {eachPermission.display_order}
          </MDTypography>
          ),
        indent:(
        <MDTypography display="block" variant="body2">
        {eachPermission.indent}
        </MDTypography>
        ),
        action:(
              <MDBox display="flex">
                {pagePermission.edit ? (
                  <MDTypography mt={1} title="Edit" component={Link} to={`/permission/edit?id=${eachPermission.id}`} display="block" variant="body2" color="info">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.remove ? (
                  <MDTypography mt={1} title="Remove" component="a"
                  onClick={() => handleClickOpen(eachPermission.id)}
                  display="block" variant="body2" color="error">
                    <Icon fontSize="small">delete</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            ),
      };
      rows[index] = cols;
  });

  return {
    columns: [
      { Header: "Id", accessor: "id", width: "10%", align: "left" },
      { Header: "Title", accessor: "name", width: "30%", align: "left" },
      { Header: "Handler", accessor: "handler", width: "30%", align: "left" },
      { Header: "Display Order", accessor: "display_order", width: "12%", align: "center" },
      { Header: "Indent", accessor: "indent", width: "10%", align: "center" },
      ...(pagePermission.edit || pagePermission.remove || pagePermission.status) ? [{ Header: "Actions", accessor: "action", width: "10%", align: "center" }] : [],
    ],

    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deletePermission: deletePermission,
    processPermissions: processPermissions,
    selectedId: selectedId,
    pagePermission: pagePermission,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
  };
}
