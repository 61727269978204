import dayjs from 'dayjs';

export const PDFTemplate = (jobCostSheetSummary, grandSale, grandExpense, grandElectricity, grandLabor, grandMisc, grandProfit, numberFormat) => {
    return  `<html>
  <head>
    <title>job_cost_sheet_summary_report_${dayjs().format('DD_MM_YYYY_HH_mm_ss')}</title>
    <style>
        table tr th {
            text-align: left !important;
        }
    </style>
  </head>
  <body style="font-family: sans-serif;">
    <div>
      <h4><u>Report: Job Cost Sheet Summary</u></h4>
      <center>
        <table style="width: 100%; border: 1px solid;">
            <tr>
                <th style="background-color: #dddddd; width: 15%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Date</th>
                <th style="background-color: #dddddd; width: 16%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Total Sales</th>
                <th style="background-color: #dddddd; width: 14%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Raw</th>
                <th style="background-color: #dddddd; width: 14%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Electricity</th>
                <th style="background-color: #dddddd; width: 14%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Labour</th>
                <th style="background-color: #dddddd; width: 14%; font-size: 0.875rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">Miscellaneous</th>
                <th style="background-color: #dddddd; width: 14%; font-size: 0.875rem; border-bottom: 1px solid #000;">Profit</th>
            </tr>
            ${jobCostSheetSummary?.map((item, index) => {
                let rowColor = index % 2 === 0 ? '#e0f8f7' : '#effbfb';
                return `
                    <tr style="background-color: ${rowColor}">
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000;">${item?.date}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.total_sales)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.total_expense)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.electricity)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.labor)}</td>
                        <td style="font-size: 0.775rem; border-right: 1px solid #000; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.misc)}</td>
                        <td style="font-size: 0.775rem; border-bottom: 1px solid #000; text-align: right;">${numberFormat(item?.profit)}</td>
                    </tr>
                `
            }).join('')}   
            <tr>
                <th style="font-size: 0.875rem; border-right: 1px solid #000;">Total</th>
                <th style="font-size: 0.875rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(grandSale)}</th>
                <th style="font-size: 0.875rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(grandExpense)}</th>
                <th style="font-size: 0.875rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(grandElectricity)}</th>
                <th style="font-size: 0.875rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(grandLabor)}</th>
                <th style="font-size: 0.875rem; border-right: 1px solid #000;text-align: right !important;">${numberFormat(grandMisc)}</th>
                <th style="font-size: 0.875rem;text-align: right !important;">${numberFormat(grandProfit)}</th>
            </tr>     
        </table>
      </center>
    </div>
  </body>
</html>`

}
