import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";

import { setupAxiosInterceptors } from "./services/interceptor";
import ProtectedRoute from "examples/ProtectedRoute";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import Login from "auth/login";
import Register from "auth/register";
import { AuthContext, WorkOrderContextProvider, PurchaseOrderContextProvider, GrnContextProvider, PreserveTableProvider } from "context";
import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";
import UserManagementForm from "layouts/user-management/form";
import PermissionForm from "layouts/permission/form";
import AddGroup from "layouts/material-group/form";
import Colors from "layouts/manage-colors/form";
import Parameter from "layouts/manage-parameters/form";
import HsnCode from "layouts/manage-hsn-codes/form";
import Customer from "layouts/manage-customers/form";
import CustomerRate from "layouts/manage-customers/customer-rates";
import CustomerPayment from "layouts/manage-customers/payments";
import CustomerInventories from "layouts/manage-customer-inventories/form";
import CustomerInventory from "layouts/manage-customers/customer-inventories";
import CustomerInventoryEdit from "layouts/manage-customers/customer-inventories-edit";
import PaymentType from "layouts/manage-payment-types/form";
import Status from "layouts/manage-statuses/form";
import Supplier from "layouts/manage-suppliers/form";
import SupplierBaseRate from "layouts/manage-suppliers/base-rate";
import Unit from "layouts/manage-units/form";
import PrintingMachine from "layouts/manage-printing-machines/form";
import PrintingRate from "layouts/manage-printing-rates/form";
import Size from "layouts/manage-sizes/form";
import PrintingType from "layouts/manage-printing-types/form";
import Item from "layouts/manage-items/form";
import SupplierItem from "layouts/manage-supplier-items/form";
import WorkOrder from "layouts/manage-work-orders/form";
import WorkOrderJobCard from "layouts/manage-work-orders/job-card";
import PurchaseOrder from "layouts/manage-purchase-orders/form";
import Grn from "layouts/manage-grn/form";
import Invoice from "layouts/manage-invoices/form";
import InvoiceCreate from "layouts/manage-invoices/wo-index";
import { Helmet } from "react-helmet";
import authPermission from "utility/auth-permissions";

export default function App() {
  const authContext = useContext(AuthContext);
  const { hasPermission } = authPermission();

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/auth/login");
  });

  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      {isDemo && (
        <Helmet>
          <meta
            name="keywords"
            content=""
          />
          <meta
            name="description"
            content=""
          />
          <meta
            itemProp="name"
            content="Aditya Prints - Business Management System"
          />
          <meta
            itemProp="description"
            content=""
          />
          <meta
            itemProp="image"
            content=""
          />
          <meta name="twitter:card" content="product" />
          <meta name="twitter:site" content="@techhighway" />
          <meta
            name="twitter:title"
            content="Aditya Prints - Business Management System"
          />
          <meta
            name="twitter:description"
            content=""
          />
          <meta name="twitter:creator" content="@techhighway" />
          <meta
            name="twitter:image"
            content=""
          />
          <meta
            property="og:title"
            content="Aditya Prints - Business Management System"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content=""
          />
          <meta
            property="og:image"
            content=""
          />
          <meta
            property="og:description"
            content=""
          />
          <meta property="og:site_name" content="Creative Tim" />
        </Helmet>
      )}
      {direction === "rtl" ? (
        <CacheProvider value={rtlCache}>
          <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
            <PreserveTableProvider>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="Aditya Prints"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {/* {configsButton} */}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              <Route path="login" element={<Navigate to="/auth/login" />} />
              <Route path="register" element={<Navigate to="/auth/register" />} />
              <Route path="forgot-password" element={<Navigate to="/auth/forgot-password" />} />
              <Route path="/permission/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PermissionForm /></ProtectedRoute>} />
              <Route path="/manage-groups/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><AddGroup /></ProtectedRoute>} />
              <Route path="/manage-colors/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Colors /></ProtectedRoute>} />
              <Route path="/manage-parameters/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Parameter /></ProtectedRoute>} />
              <Route path="/manage-hsn-codes/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><HsnCode /></ProtectedRoute>} />
              <Route path="/manage-customers/customer-rates" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerRate /></ProtectedRoute>} />
              <Route path="/manage-customers/payments" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerPayment /></ProtectedRoute>} />
              <Route path="/manage-customer-inventories/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerInventories /></ProtectedRoute>} />
              <Route path="/manage-customers/customer-inventories" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerInventory /></ProtectedRoute>} />
              <Route path="/manage-customers/customer-inventories/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerInventoryEdit /></ProtectedRoute>} />
              <Route path="/manage-customers/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Customer /></ProtectedRoute>} />
              <Route path="/manage-payment-types/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PaymentType /></ProtectedRoute>} />
              <Route path="/manage-statuses/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Status /></ProtectedRoute>} />
              <Route path="/manage-suppliers/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Supplier /></ProtectedRoute>} />
              <Route path="/manage-suppliers/base-rate" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><SupplierBaseRate /></ProtectedRoute>} />
              <Route path="/manage-units/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Unit /></ProtectedRoute>} />
              <Route path="/manage-printing-machines/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PrintingMachine /></ProtectedRoute>} />
              <Route path="/manage-printing-rates/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PrintingRate /></ProtectedRoute>} />
              <Route path="/manage-sizes/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Size /></ProtectedRoute>} />
              <Route path="/manage-wo-printing-types/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PrintingType /></ProtectedRoute>} />
              <Route path="/manage-items/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Item /></ProtectedRoute>} />
              <Route path="/manage-supplier-items/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><SupplierItem /></ProtectedRoute>} />
              <Route path="/manage-work-orders/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><WorkOrderContextProvider><WorkOrder /></WorkOrderContextProvider></ProtectedRoute>} />
              <Route path="/manage-purchase-orders/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PurchaseOrderContextProvider><PurchaseOrder /></PurchaseOrderContextProvider></ProtectedRoute>} />
              <Route path="/manage-grn/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><GrnContextProvider><Grn /></GrnContextProvider></ProtectedRoute>} />
              <Route path="/manage-invoices/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Invoice /></ProtectedRoute>} />
              <Route path="/manage-invoices/create" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><InvoiceCreate /></ProtectedRoute>} />
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </PreserveTableProvider>
          </ThemeProvider>
        </CacheProvider>
      ) : (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
        <PreserveTableProvider>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="Aditya Prints"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {/* {configsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/register" element={<Register />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/permission/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PermissionForm /></ProtectedRoute>} />
            <Route path="/manage-groups/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><AddGroup /></ProtectedRoute>} />
            <Route path="/manage-colors/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Colors /></ProtectedRoute>} />
            <Route path="/manage-parameters/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Parameter /></ProtectedRoute>} />
            <Route path="/manage-hsn-codes/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><HsnCode /></ProtectedRoute>} />
            <Route path="/manage-customers/customer-rates" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerRate /></ProtectedRoute>} />
            <Route path="/manage-customers/payments" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerPayment /></ProtectedRoute>} />
            <Route path="/manage-customer-inventories/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerInventories /></ProtectedRoute>} />
            <Route path="/manage-customers/customer-inventories" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerInventory /></ProtectedRoute>} />
            <Route path="/manage-customers/customer-inventories/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><CustomerInventoryEdit /></ProtectedRoute>} />
            <Route path="/manage-customers/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Customer /></ProtectedRoute>} />
            <Route path="/manage-payment-types/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PaymentType /></ProtectedRoute>} />
            <Route path="/manage-statuses/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Status /></ProtectedRoute>} />
            <Route path="/manage-suppliers/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Supplier /></ProtectedRoute>} />
            <Route path="/manage-suppliers/base-rate" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><SupplierBaseRate /></ProtectedRoute>} />
            <Route path="/manage-units/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Unit /></ProtectedRoute>} />
            <Route path="/manage-printing-machines/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PrintingMachine /></ProtectedRoute>} />
            <Route path="/manage-printing-rates/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PrintingRate /></ProtectedRoute>} />
            <Route path="/manage-sizes/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Size /></ProtectedRoute>} />
            <Route path="/manage-wo-printing-types/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PrintingType /></ProtectedRoute>} />
            <Route path="/manage-items/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Item /></ProtectedRoute>} />
            <Route path="/manage-supplier-items/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><SupplierItem /></ProtectedRoute>} />
            <Route path="/manage-work-orders/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><WorkOrderContextProvider><WorkOrder /></WorkOrderContextProvider></ProtectedRoute>} />
            <Route path="/manage-work-orders/job-card" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><WorkOrderJobCard /></ProtectedRoute>} />
            <Route path="/manage-purchase-orders/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><PurchaseOrderContextProvider><PurchaseOrder /></PurchaseOrderContextProvider></ProtectedRoute>} />
            <Route path="/manage-grn/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><GrnContextProvider><Grn /></GrnContextProvider></ProtectedRoute>} />
            <Route path="/manage-invoices/:action" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><Invoice /></ProtectedRoute>} />
            <Route path="/manage-invoices/create" element={<ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}><InvoiceCreate /></ProtectedRoute>} />
            <Route
              exact
              path="user-profile"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}>
                  <UserProfile />
                </ProtectedRoute>
              }
              key="user-profile"
            />
            <Route
              exact
              path="user-management"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}>
                  <UserManagement />
                </ProtectedRoute>
              }
              key="user-management"
            />
            <Route
              exact
              path="user-management/:action"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated} refresh={authContext.refresh} isLoader={authContext.isLoader}>
                  <UserManagementForm />
                </ProtectedRoute>
              }
              key="user-management"
            />
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </PreserveTableProvider>
        </ThemeProvider>
      )}
    </>
  );
}
