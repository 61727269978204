/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import * as React from 'react';
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import MDDatePicker from "components/MDDatePicker";
import customAlert from "components/MDAlert/customAlert";
import MDTabPanel from "components/MDTabPanel";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PurchaseOrderService from "services/purchase-order-service";
import SuppliersService from "services/supplier-service";
import StatusServices from "services/status-services";
import PoItemService from "services/po-item-service";
import PoItem from "./po-item";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat, onlyNumbers } from "utility/helpers";
import { PurchaseOrderContext } from "context";
import { PoPDFTemplate } from "./po-pdf-template";
import MDDialog from "components/MDDialog";
import { useCache } from "context";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//temporary check for staging only it will be removed once it goes on production
const domainRegex = /\@(techhighway\.co\.in|techhighway\.ca)$/i;

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const ref = searchParams.get('ref');
  const closeWindow = searchParams.get('close_window') ? 1 : 0;
  const tab = searchParams.get('tab') ? Number(searchParams.get('tab')) : (localStorage.getItem("poTabValue") ? Number(localStorage.getItem("poTabValue")) : 0);
  const navigate = useNavigate();

  const { hasPermission, systemConstant } = authPermission();
  const workOrderPermission = hasPermission('/manage-work-orders');
  const { cacheItems, setCacheItems } = useCache();
  const newStatus = systemConstant('NEW_STATUS');
  const companyName = systemConstant('COMPANY_NAME');
  const [completedStatus, setCompletedStatus] = useState(false);
  const purchaseOrderContext = useContext(PurchaseOrderContext);
  const netAmount = purchaseOrderContext.poTotal.toFixed(2);
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobIconLoading, setJobIconLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [submittedDate, setSubmittedDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [suppliersDropdown, setSuppliersDropdown] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [value, setValue] = useState(0);
  const [openPreview, setOpenPreview] = useState(false);
  const minWidth = useMediaQuery('(min-width:1725px)');
  const minWidthD = useMediaQuery('(min-width:1150px)');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const woEditBtnStyle = {
    padding: 0,
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    borderTopRightRadius: "0.375rem",
    borderBottomRightRadius: "0.375rem",
    borderColor: "#d2d6da",
    backgroundColor: "#F5F5F5",
    ':hover': {
      backgroundColor: '#F5F5F5',
      borderColor: "#d2d6da",
      opacity : 1,
    }
  };

  const [inputs, setInputs] = useState({
    supplier_id: '',
    work_order_id: '',
    job_type: 'New Order',
    description: '',
    status_id: '',
    is_email_sent: '',
    created_by: '',
    created_at: '',
    amount: 0.00,
    customer_id: '',
  });

  const pageReload = (tab) => {
    localStorage.setItem("poTabValue", tab);
    navigate(0);
  }

  useEffect(() => {
    const getSuppliersData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('suppliers')) {
        response = cacheItems['suppliers'];
      } else {
        response = await SuppliersService.getSuppliers(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            suppliers: response,
        }));
      }

      if (response) {
        var supplierArray = [];
        response?.map((eachSupplier, index) => {
            supplierArray[index] = {
              key: eachSupplier?.id,
              value: eachSupplier?.name,
              disabled: eachSupplier?.active ? false : true,
            }
        });
        setSuppliersDropdown(supplierArray);
      }
    }
    getSuppliersData();

    const getStatusData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('statuses')) {
        response = cacheItems['statuses'];
      } else {
        response = await StatusServices.getStatuses(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            statuses: response,
        }));
      }

      if (response) {
        var statusArray = [];
        response?.map((eachStatus, index) => {
            statusArray[index] = {
              key: eachStatus?.id,
              value: eachStatus?.name,
              disabled: eachStatus?.active ? false : true,
            }
        });
        setStatusDropdown(statusArray);
      }
    }
    getStatusData();

    const getPoItems = async() => {
      let total = 0;
      let items = [];
      if (id) {
        items = await PoItemService.getPoItems(id);
      }
      items.forEach((eachItem,index) => {
        items[index]['size_name'] = eachItem.size?.name;
        items[index]['loading'] = false;
        items[index]['discount_pct'] = Number(items[index]['discount_pct']) * 1;
        items[index]['quantity'] = Number(items[index]['quantity']) * 1;
        items[index]['received_qty'] = (action === "add") ? 0 : items[index]['received_qty']; 
        total = Number(total) + Number(items[index]['amount']);
      });
      let i = items.length % 5;
      for (i; i<5; i++) {
        items[items.length] = {
            purchase_order_id: id,
            id: 0,
            supplier_item_id: '',
            item_id: '',
            size_id: '',
            size_name: '',
            gsm: '',
            quantity: '',
            rate: '',
            discount_pct: '',
            amount: '',
            received_qty: '',
            loading: false,
        };
      }

      purchaseOrderContext.setPoItem(items);
      purchaseOrderContext.setPoAmount(total);
    }
    getPoItems();

    if (action === "add" && id == null) return;

    const showPurchaseOrder = async() => {
      try {
        const data = await PurchaseOrderService.showPurchaseOrder(id);
        setHeaderTitle(data.supplier_id ? (data.id + " (" + data.supplier.name + ")") : '');
        setInputs(data);
        setSubmittedDate(data.submitted_date);
        if (action === 'add') {
          data.status_id = newStatus;
          setSubmittedDate(dayjs(new Date).format('YYYY-MM-DD'));
        }
        setPurchaseOrder(data);
        if (systemConstant('COMPLETED_STATUS') == data.status_id && action === 'edit') {
          setCompletedStatus(true);
        }
        if (tab && data.supplier_id) {
          handleTabChange('', tab);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Purchase Order'};
          navigate('/manage-purchase-orders', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Purchase Order'};
          navigate('/manage-purchase-orders', {state: state});
        }
      }
    }
    showPurchaseOrder();
    localStorage.removeItem("poTabValue");
  }, []);

  const changeHandler = (e) => {
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {

    setLoading(true);
    e.preventDefault();


    const newPurchaseOrder = {
      supplier_id: inputs.supplier_id,
      job_type: inputs.job_type,
      description: inputs.description,
      amount: purchaseOrderContext.poTotal ? purchaseOrderContext.poTotal : inputs.amount,
      submitted_date: submittedDate,
      status_id: inputs.status_id ? inputs.status_id : newStatus,
      customer_id: inputs.customer_id,
      work_order_id: inputs.work_order_id,
    };

    const poItem = purchaseOrderContext.item;

    if (!newPurchaseOrder.supplier_id) {
      openAlertSB({color: 'warning', icon: 'star', msg: "Supplier is mandatory", title: 'Purchase Order'});
      setLoading(false);
    } else {
      if (action === 'add') {
        try {
          const response = await PurchaseOrderService.addPurchaseOrder(newPurchaseOrder);
          setLoading(false);
          if (response) {
            if (poItem.length > 0) {
              const itemArray = {
                'item': poItem ? poItem : '',
                'purchase_order_id': response.id,
              }
              const itemResponse = await PoItemService.addPoItem(itemArray);
            }
            const totalUpdateResponse = await PurchaseOrderService.updatePoTotal({id: response.id});
            const state = {color: 'success', icon: 'check', msg: 'Purchase Order Added Successfully!', title: 'Purchase Order'};
            navigate('/manage-purchase-orders', { state: state });
          }
        } catch (res) {
          const totalUpdateResponse = await PurchaseOrderService.updatePoTotal({id: ''});
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Purchase Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Purchase Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      } else {
        try {
          const response = await PurchaseOrderService.updatePurchaseOrder(id ,newPurchaseOrder);
          setLoading(false);
          if (response) {
            if (poItem.length > 0) {
              const itemArray = {
                'item': poItem ? poItem : '',
                'purchase_order_id': response.id,
              }
              const deletePoItem = await PoItemService.deleteAllPoItem(response.id);
              const itemResponse = await PoItemService.addPoItem(itemArray);
            }
            const totalUpdateResponse = await PurchaseOrderService.updatePoTotal({id: response.id});
            setHeaderTitle(response.supplier_id ? (response.id + " (" + response.supplier.name + ")") : '');
            setInputs(response);
            setSubmittedDate(response.submitted_date);
            //openAlertSB({color: 'success', icon: 'check', msg: 'Purchase Order Updated Successfully!', title: 'Purchase Order'});
            const state = {color: 'success', icon: 'check', msg: 'Purchase Order Added Successfully!', title: 'Purchase Order'};
            navigate('/manage-purchase-orders', { state: state });
            // setTimeout(function() {
            //   pageReload(value);
            // }, 500);
          }
        } catch (res) {
          const totalUpdateResponse = await PurchaseOrderService.updatePoTotal({id: id});
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Purchase Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Purchase Order'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      }
    }

    return () => {
      setInputs({
        supplier_id: '',
        work_order_id: '',
        job_type: '',
        description: '',
        amount: '',
        status_id: '',
        is_email_sent: '',
        created_by: '',
        created_at: '',
        customer_id: '',
      });
    };
  };

  const getPDFData = async() => {
    const items = await PoItemService.getPoItems(id);
    // Check if 'gsm' is present in any 'poItems'
    const hasGsm = items.some(item => item.gsm > 0);
    
    // Check if 'size' is present in any 'poItems'
    const hasSize = items.some(item => item.size);

    return {
      poItem: items,
      hasGsm: hasGsm,
      hasSize: hasSize,
    };
  }

  const goBack = async() => {
    if (ref) {
      window.location.href = ref;
    } else if (closeWindow) {
      window.open("about:blank", "_self");
      window.close();
    } else {
      window.location.href = '/manage-purchase-orders';
    }
  }

  const handlePrint = async() => {
    setJobIconLoading(true);
    const response = await getPDFData();
    const companyName = systemConstant('COMPANY_NAME');
    const companyAddress = systemConstant('COMPANY_ADDRESS');
    if(response){
        const pdfTemplate = PoPDFTemplate(companyName, companyAddress, purchaseOrder, response.poItem, response.hasGsm, response.hasSize, numberFormat);

        const originalBody = document.body;
        const printWindow = window.open('', '_blank');
        setJobIconLoading(false);
        printWindow.document.write(`${pdfTemplate}`);
        printWindow.document.close();
        await new Promise(r => setTimeout(r, 2000));
        printWindow.print();
        printWindow.close();
        document.body = originalBody;
    }
  };

  const sendEmail = async(id, email) => {
    setOpenPreview(false);
    setEmailLoading(true);
    if (email) {
      try {
        const response = await PurchaseOrderService.sendEmail(id);
        if (response) {
          setInputs({
            ...inputs,
            ['is_email_sent']: 1,
          })
          openAlertSB({color: 'success', icon: 'check', msg: 'Email sent Successfully!', title: 'Purchase Order'});
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Purchase Order'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Purchase Order'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      }
    } else {
      openAlertSB({color: 'warning', icon: 'star', msg: 'Email is not added to this Supplier', title: 'Purchase Order'});
    }
    setEmailLoading(false);
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add Purchase Order' : ('Purchase Order : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.3rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={12} md={minWidth ? 5 : 5}>
                      <MDTypography mt={1} variant="h6" color="white" sx={{ whiteSpace: 'no-wrap' }}>
                        {action === 'add' ? 'Add Purchase Order' : ('Purchase Order : ' + headerTitle)}
                      </MDTypography>
                    </Grid>
                    {action === 'edit' && (
                      <Grid item xs={12} md={minWidth ? 7 : 7} display={minWidthD ? 'flex' : ''} justifyContent="flex-end">
                        <MDBox
                          py={"0.450rem"}
                          px={2}
                          mt={minWidthD ? '' : 1}
                          mr={minWidthD ? 2 : ''}
                          variant="gradient"
                          bgColor="success"
                          borderRadius="lg"
                          coloredShadow="success"
                          width="auto"
                          whiteSpace={minWidth ? 'nowrap' : ''}
                          textAlign="center"
                        >
                          <MDTypography variant="h6" color="white">
                            Total = RS.{numberFormat(purchaseOrderContext.poTotal)}
                          </MDTypography>
                        </MDBox>
                        <MDBox display="flex" textAlign="center" mt={minWidthD ? '' : 1}>
                          <MDButton
                              onClick={handlePrint}
                              variant="gradient" color="secondary" title="Print Job Card" iconOnly>
                                {jobIconLoading ? <MDCircularProgress size={20}/> : <Icon sx={{ fontWeight: "bold" }}>print_icon </Icon>}
                          </MDButton>
                          <MDButton
                              onClick={() => setOpenPreview(true)}
                              sx={{marginLeft: 2}}
                              variant="gradient" color={inputs.is_email_sent ? 'success' : 'warning'} title={inputs.is_email_sent ? 'Email is sent' : 'Send Email'} iconOnly>
                                {emailLoading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />:<Icon sx={{ fontWeight: "bold" }}>forward_to_inbox_icon</Icon>}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
                <MDBox>
                  <MDBox px={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs sx={{padding: 0, margin: 0.75}} value={value} onChange={handleTabChange} textColor="primary" aria-label="basic tabs example">
                      <Tab label="Purchase Order" {...a11yProps(0)} />
                      <Tab label="Items" {...a11yProps(1)} />
                    </Tabs>
                  </MDBox>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <MDTabPanel value={value} index={0}>
                      <Grid container>
                        <Grid item md={6} xs={12} px={3}>
                          <MDBox mb={2}>
                            <MDDatePicker
                              label="Submitted Date"
                              name="submitted_date"
                              value={dayjs(submittedDate).isValid() ? dayjs(submittedDate) : dayjs(new Date())}
                              format="DD-MM-YYYY"
                              disabled={completedStatus && action === 'edit' ? true : false}
                              onChange={(newValue) => {
                                setSubmittedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                              }}
                              slotProps={{ textField: {
                                  fullWidth: true,
                                  required: true
                                },
                                field: {
                                  clearable: true
                                },
                                textField: { size: 'small' }
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="Supplier"
                              fullWidth
                              name="supplier_id"
                              value={inputs.supplier_id}
                              onChange={changeHandler}
                              options={suppliersDropdown}
                              required
                              disabled={completedStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Job Type"
                              fullWidth
                              value={inputs.job_type}
                              name="job_type"
                              onChange={changeHandler}
                              inputProps={{'maxLength':100}}
                              required
                              disabled={completedStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDSelect
                              label="Status"
                              fullWidth
                              name="status_id"
                              value={inputs.status_id ? inputs.status_id : (statusDropdown.length > 0 ? newStatus : '')}
                              onChange={changeHandler}
                              options={statusDropdown}
                              required
                              disabled={completedStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} px={3}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Net Amount"
                              fullWidth
                              value={numberFormat(netAmount, false)}
                              name="amount"
                              disabled={true}
                            />
                          </MDBox>
                        </Grid>
                        {action === 'edit' && (
                        <>
                          <Grid item xs={12} md={6} px={3}>
                            <MDBox display="flex" mb={2}>
                              <MDInput
                                type="text"
                                label="Work Order #"
                                fullWidth
                                value={inputs.work_order_id ? inputs.work_order_id : ''}
                                onChange={changeHandler}
                                disabled={true}
                                InputProps={{ sx: { borderTopRightRadius: "0", borderBottomRightRadius: "0" } }}
                              />
                              {workOrderPermission.edit && inputs.work_order_id &&
                                <MDButton target="_blank" sx={woEditBtnStyle} title="Edit Work Order" variant="outlined" color="secondary" size="small"
                                component={Link} to={`/manage-work-orders/edit?id=${inputs.work_order_id}`}
                                >
                                  <Icon fontSize="medium" sx={{fontSize:"22px"}}>open_in_new_icon</Icon>
                                </MDButton>
                              }
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                value={inputs.created_by ? (inputs.user?.first_name + ' ' + (inputs.user?.middle_name ? (inputs.user.middle_name + ' ') : '') + inputs.user?.last_name) : ' '}
                                onChange={changeHandler}
                                disabled={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={inputs.created_at ? dayjs(inputs.created_at).format('DD-MM-YYYY h:m A') : ' '}
                                onChange={changeHandler}
                                disabled={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                        )}
                        <Grid item xs={12} md={12} px={3}>
                          <MDBox>
                            <MDInput
                              fullWidth
                              multiline
                              rows={6}
                              type="text"
                              label="Instruction"
                              name="description"
                              value={inputs.description}
                              inputProps={{maxLength: '6000'}}
                              onChange={changeHandler}
                              disabled={completedStatus && action === 'edit' ? true : false}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDTabPanel>
                    <MDTabPanel value={value} index={1}><PoItem supplierId={inputs.supplier_id} numberFormat={numberFormat} onlyNumbers={onlyNumbers} isCompleted={completedStatus}></PoItem></MDTabPanel>
                    <Grid container mb={2}>
                      <Grid item md={completedStatus ? 11 : 10} xs={1}></Grid>
                      <Grid item md={1} xs={3}>
                        <MDBox mt={1} mr={2}>
                          <MDButton component={Link} onClick={() => goBack()} variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      {!completedStatus && (
                        <Grid item md={1} xs={3}>
                        <MDBox mt={1} mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                        </Grid>
                      )}
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
      <MDDialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        successAction={() => sendEmail(id, (inputs?.supplier_id ? inputs.supplier.email : ''))}
        dialogTitle={'Mail Preview'}
        cancleButton={'Cancel'}
        successButton={'Send'}
        cancleButtonColor={'light'}
        successButtonColor={'info'}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <MDBox fullWidth>
          <MDBox sx={{ width: '100%', border: '1px solid lightgray', borderRadius: 5}}>
            <MDBox sx={{ paddingLeft: 2, paddingTop: 3 }}>
              <b>From: </b> {companyName}{process.env.REACT_APP_IS_DEMO === "true" ? ' Staging' : ''} &lt;email.of.aditya.prints@gmail.com&gt;
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <b>To: </b>&lt;{process.env.REACT_APP_IS_DEMO === "true" && !domainRegex.test(inputs?.supplier?.email) ? 'adityaprints@outlook.com' : inputs?.supplier?.email}&gt;
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 1 }}>
              <b>Cc: </b>&lt;adityaprints@outlook.com&gt;
              <hr sx={{ border: '1px solid lightgray', width: '100%' }} />
            </MDBox>
            <MDBox sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 2 }}>
              <b>Subject: </b>Purchase Order #{purchaseOrder?.id}
            </MDBox>
            <MDBox sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <hr sx={{ border: '1px solid lightgray', width: '100%' }} />
            </MDBox>
            <MDBox sx={{ paddingLeft: 3, paddingTop: 2, paddingBottom: 2 }}>
              <h4>Hi {purchaseOrder?.supplier?.name},</h4>
              <br />
              <p>Please find attached Purchase Order.</p>
              <br />
              <h5>Thanks & Regards,</h5>
              <h5>{companyName}</h5>
              <br />
              <MDButton
                  onClick={handlePrint}
                  variant="gradient" size="large" color="secondary" title="Click to print/download the attachment" iconOnly>
                    <Icon fontSize="medium">picture_as_pdf_icon </Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDDialog>
    </>
  );
}

export default Form;
