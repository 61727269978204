import HttpService from "./htttp.service";

class ColorsService {

  getColors = async(page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    let fetchUrl = 'colors';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'colors?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    }
    return await HttpService.get(fetchUrl);
  }

  addColor = async(newColor) => {
    const fetchUrl = 'colors';
    return await HttpService.post(fetchUrl, newColor);
  }

  updateColor = async (id, data) => {
    const fetchUrl = 'colors/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updateColorStatus = async (id, data) => {
    const fetchUrl = 'colors/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showColor = async(id) => {
    const fetchUrl = 'colors/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteColor = async (id) => {
    const fetchUrl = 'colors/' + id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new ColorsService();
