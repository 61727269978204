/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import * as React from 'react';
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import dayjs from 'dayjs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDCircularProgress from "components/MDCircularProgress";
import MDDatePicker from "components/MDDatePicker";
import customAlert from "components/MDAlert/customAlert";
import MDTabPanel from "components/MDTabPanel";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuppliersService from "services/supplier-service";

// @mui icons
import Icon from "@mui/material/Icon";
import useMediaQuery from '@mui/material/useMediaQuery';
import authPermission from "utility/auth-permissions";
import { numberFormat, onlyNumbers } from "utility/helpers";
import { GrnContext } from "context";
import MDRadio from "components/MDRadio";
import GrnService from "services/grn-service";
import PoItemService from "services/po-item-service";
import GrnItem from "./grn-item";
import Payment from "./payment";
import GrnItemService from "services/grn-item-service";
import PoPaymentService from "services/po-payment-service";
import { useCache } from "context";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const ref = searchParams.get('ref');
  const closeWindow = searchParams.get('close_window') ? 1 : 0;
  const tab = searchParams.get('tab') ? Number(searchParams.get('tab')) : (localStorage.getItem("grnTabValue") ? Number(localStorage.getItem("grnTabValue")) : 1);
  const navigate = useNavigate();

  const { systemConstant } = authPermission();
  const cancelledStatus = systemConstant('CANCELLED_STATUS');
  const inProgressStatus = systemConstant('IN_PROGRESS_STATUS');
  const completedStatus = systemConstant('COMPLETED_STATUS');
  const grnContext = useContext(GrnContext);
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [headerTitle, setHeaderTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [isShowTab, setIsShowTab] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [submittedDate, setSubmittedDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [billDate, setBillDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [suppliersDropdown, setSuppliersDropdown] = useState([]);
  const [supplierId, setSupplierId] = useState('');
  const { cacheItems, setCacheItems } = useCache();
  const grnItems = grnContext.item;
  const grnTotal = grnContext.grnTotal;
  const [value, setValue] = useState(0);
  const minWidth = useMediaQuery('(min-width:1725px)');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const pageReload = (tab) => {
    localStorage.setItem("grnTabValue", tab);
    navigate(0);
  }

  const goBack = async() => {
    if (ref) {
      window.location.href = ref;
    } else if (closeWindow) {
      window.open("about:blank", "_self");
      window.close();
    } else {
      window.location.href = '/manage-grn';
    }
  }

  const [inputs, setInputs] = useState({
    supplier_id: '',
    description: '',
    bill_no: '',
    is_bill_paid: 0,
    is_with_bill: 1,
    amount: 0,
    transport_charge: 0,
    sgst_tax: 0,
    cgst_tax: 0,
    discount: 0,
    other_charge: 0,
    total: 0,
  });

  useEffect(() => {

    const getSuppliersData = async() => {
      let response; // Declare response outside the if...else block
      if (cacheItems.hasOwnProperty('suppliers')) {
        response = cacheItems['suppliers'];
      } else {
        response = await SuppliersService.getSuppliers(); // Fetch customers from the API
        setCacheItems(prevCache => ({
            ...prevCache,
            suppliers: response,
        }));
      }
      if (response) {
        var supplierArray = [];
        response?.map((eachSupplier, index) => {
            supplierArray[index] = {
              key: eachSupplier?.id,
              value: eachSupplier?.name,
              disabled: eachSupplier?.active ? false : true,
            }
        });
        setSuppliersDropdown(supplierArray);
      }
    }
    getSuppliersData();

    if (action === "add") return;

    const showGrn = async() => {
      try {
        const data = await GrnService.showGrn(id);
        setHeaderTitle(data.supplier_id ? (data.id + " (" + data.supplier.name + ")") : '');
        data.amount = Number(data.amount).toFixed(2);
        data.transport_charge = Number(data.transport_charge).toFixed(2);
        data.tax = Number(data.tax).toFixed(2);
        data.discount = Number(data.discount).toFixed(2);
        data.other_charge = Number(data.other_charge).toFixed(2);
        data.total = Number(data.total).toFixed(2);
        setInputs(data);
        setSubmittedDate(data.submitted_date);
        setBillDate(data.bill_date);
        if (tab && data) {
          handleTabChange('', tab);
        }
        //getItems
        const items = await GrnItemService.getGrnItems(id);
        const poItemsWithIsChecked = items.map(item => ({
          ...item,
          new_received_qty: item.quantity,
          job_type: item.purchase_order.job_type,
          customer: item.work_order?.customer?.name,
          is_checked: true, // add the new column with a default value of false
        }));
        grnContext.setGrnItem(poItemsWithIsChecked);
        setIsShowTab(true);
        setIsShowError(false);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'GRN'};
          navigate('/manage-grn', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'GRN'};
          navigate('/manage-grn', {state: state});
        }
      }
      setIsShowTab(true);
    }
    showGrn();
    localStorage.removeItem("woTabValue");
  }, []);

  const getItems = async(supplier_id, cancelledStatus) => {
    setInputs({
      ...inputs,
      ['supplier_id']: supplier_id,
    });
    const items = await PoItemService.getPoItemsWithPendingReceipts(supplier_id, cancelledStatus);
    if (items.length > 0) {
      const poItemsWithIsChecked = items.map(item => ({
        ...item,
        new_received_qty: item.quantity - item.received_qty,
        is_checked: false, // add the new column with a default value of false
      }));
      grnContext.setGrnItem(poItemsWithIsChecked);
      setIsShowTab(true);
      setIsShowError(false);
    } else {
      setIsShowError(true);
      setIsShowTab(false);
    }
  }

  const changeHandler = (e) => {
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {

    setLoading(true);
    e.preventDefault();


    const newGrn = {
      supplier_id: inputs.supplier_id,
      description: inputs.description,
      bill_no: inputs.bill_no,
      is_bill_paid: inputs.is_bill_paid,
      is_with_bill: inputs.is_with_bill,
      amount: grnTotal ? grnTotal : inputs.amount,
      transport_charge: inputs.transport_charge ? inputs.transport_charge : 0,
      sgst_tax: inputs.sgst_tax ? inputs.sgst_tax : 0,
      cgst_tax: inputs.cgst_tax ? inputs.cgst_tax : 0,
      discount: inputs.discount ? inputs.discount : 0,
      other_charge: inputs.other_charge ? inputs.other_charge : 0,
      total: Number(grnTotal ? grnTotal : inputs.amount) + Number(inputs.transport_charge) + Number(inputs.sgst_tax) +  Number(inputs.cgst_tax) + Number(inputs.other_charge) - Number(inputs.discount),
      submitted_date: submittedDate,
      bill_date: billDate,
    };

    const filteredGrnItem = grnItems.filter(item => item.is_checked);
    const payment = grnContext.payment;

    if (action === 'add') {
      try {
        if (newGrn.is_with_bill == 1 && !newGrn.bill_no) {
          openAlertSB({color: 'warning', icon: 'star', msg: 'Please enter bill no.', title: 'GRN'});
          setLoading(false);
        } else if (filteredGrnItem.length == 0) {
          openAlertSB({color: 'warning', icon: 'star', msg: 'Please check at least one item ', title: 'GRN'});
          setLoading(false);
        } else {
          const response = await GrnService.addGrn(newGrn);
          setLoading(false);
          if (response) {
            const itemArray = {
              grn_id: response.id,
              in_progress_status: inProgressStatus,
              completed_status: completedStatus,
              item: filteredGrnItem,
            }
            if (cacheItems.hasOwnProperty('items')) {
              // Create a new object without the key
              const { items, ...rest } = cacheItems; // Destructure to exclude key
              setCacheItems(rest); // Set the new cache items without key
            }
            const addItem = await GrnItemService.addGrnItem(itemArray);

            if (payment.length > 0) {
              const poPayment = payment.map(item => ({
                ...item,
                grn_id: response.id,
              }));
              const paymentArray = {
                'payment': poPayment ? poPayment : ''
              }
              const paymentResponse = await PoPaymentService.addPoPayment(paymentArray);
            }

            const state = {color: 'success', icon: 'check', msg: 'GRN Added Successfully!', title: 'GRN'};
            navigate('/manage-grn', { state: state })
          }
        }
      } catch (res) {
        setLoading(false);
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'GRN'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'GRN'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    } else {
      try {
        const response = await GrnService.updateGrn(id ,newGrn);
        setLoading(false);
        if (response) {
          if (payment.length > 0) {
            const paymentArray = {
              'payment': payment ? payment : ''
            }
            const deletePoPaymentItem = await PoPaymentService.deleteAllPoPayment(response.id);
            const paymentResponse = await PoPaymentService.addPoPayment(paymentArray);
          }
          setHeaderTitle(response.supplier_id ? (response.id + " (" + response.supplier.name + ")") : '');
          setInputs(response);
          //openAlertSB({color: 'success', icon: 'check', msg: 'GRN Updated Successfully!', title: 'GRN'});
          const state = {color: 'success', icon: 'check', msg: 'GRN Updated Successfully!', title: 'GRN'};
          setTimeout(function() {
            navigate('/manage-grn', { state: state })
            // pageReload(value);
          }, 500);
        }
      } catch (res) {
        setLoading(false);
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'GRN'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'GRN'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
        setLoading(false);
      }
    }

    return () => {
      setInputs({
        supplier_id: '',
        description: '',
        bill_no: '',
        is_bill_paid: 0,
        is_with_bill: 1,
        amount: 0,
        transport_charge: 0,
        sgst_tax: 0,
        cgst_tax: 0,
        discount: 0,
        other_charge: 0,
        total: 0,
      });
    };
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add GRN' : ('GRN : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.75rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <Grid container>
                    <Grid item xs={12} md={minWidth ? 5 : 3}>
                      <MDTypography variant="h6" color="white">
                        {action === 'add' ? 'Add GRN' : ('GRN : ' + headerTitle)}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                {action === 'add' && (
                  <MDBox mt={5} px={3}>
                    <Grid container>
                      <Grid item md={6} xs={12} >
                        <MDBox mb={2}>
                          <MDSelect
                            label="Select supplier to create GRN"
                            fullWidth
                            name="supplier_id"
                            value={supplierId}
                            onChange={(e) => setSupplierId(e.target.value)}
                            options={suppliersDropdown}
                            required
                          />
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={12} >
                        <MDBox ml={2}>
                          <MDButton component={Link} onClick={() => getItems(supplierId, cancelledStatus)} variant="gradient" color="primary" fullWidth>
                            Go
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                )}
                {isShowError && (
                  <MDBox my={1} mx={1} border="2px solid #ddd" borderRadius="5px" textAlign="center">
                    <MDTypography mt={1} variant="h6" color="text">
                      {inputs.supplier_id ? "No open PO's found for this supplier." : "No supplier selected."}
                    </MDTypography>
                  </MDBox>
                )}
                {isShowTab && (
                <MDBox my={1} mx={1} border={action === 'add' ? "2px solid #ddd" : ''} borderRadius={action === 'add' ? "5px" : ''}>
                  <MDBox px={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs sx={{padding: 0, margin: 0.75}} value={value} onChange={handleTabChange} textColor="primary" aria-label="basic tabs example">
                      <Tab label="GRN" {...a11yProps(0)} />
                      <Tab label="Items" {...a11yProps(1)} />
                      <Tab label="Payment" {...a11yProps(2)} />
                    </Tabs>
                  </MDBox>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <MDTabPanel value={value} index={0}>
                      <MDBox component="fieldset" border="1px solid #ccc" padding="1rem">
                        <MDBox component="legend" color="info" fontSize="medium">General</MDBox>
                        <Grid container>
                          <Grid item md={6} xs={12} >
                            <MDBox mb={2}>
                              <MDDatePicker
                                label="Submitted Date"
                                name="submitted_date"
                                value={dayjs(submittedDate).isValid() ? dayjs(submittedDate) : dayjs(new Date())}
                                format="DD-MM-YYYY"
                                disabled={action === "edit" ? true : false}
                                onChange={(newValue) => {
                                  setSubmittedDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                }}
                                slotProps={{ textField: {
                                    fullWidth: true,
                                    required: true
                                  },
                                  field: {
                                    clearable: true
                                  },
                                  textField: { size: 'small' }
                                }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} pl={3}>
                            <MDBox mb={2}>
                              <MDSelect
                                label="Supplier"
                                fullWidth
                                name="supplier_id"
                                value={inputs.supplier_id}
                                onChange={changeHandler}
                                options={suppliersDropdown}
                                required
                                disabled={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={12} >
                            <MDBox mb={2}>
                              <MDInput
                                  fullWidth
                                  multiline
                                  rows={2}
                                  type="text"
                                  label="Remarks"
                                  name="description"
                                  value={inputs.description}
                                  inputProps={{maxLength: '250'}}
                                  onChange={changeHandler}
                                  disabled={action === "edit" ? true : false}
                                />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox component="fieldset" border="1px solid #ccc" padding="1rem">
                        <MDBox component="legend" color="info" fontSize="medium">Bill Details</MDBox>
                        <Grid container>
                          <Grid item md={6} xs={12} >
                            <MDBox mb={2}>
                              <MDInput
                                fullWidth
                                type="text"
                                label="Bill No"
                                name="bill_no"
                                value={inputs.bill_no}
                                inputProps={{maxLength: '30'}}
                                onChange={changeHandler}
                                disabled={action === "edit" ? true : false}
                                required={inputs.is_with_bill == 1 ? true : false}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6} pl={3}>
                            <MDBox mb={2}>
                              <MDDatePicker
                                label="Bill Date"
                                name="bill_date"
                                value={dayjs(billDate).isValid() ? dayjs(billDate) : dayjs(new Date())}
                                format="DD-MM-YYYY"
                                disabled={action === "edit" ? true : false}
                                onChange={(newValue) => {
                                  setBillDate(dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : null);
                                }}
                                slotProps={{ textField: {
                                    fullWidth: true,
                                    required: true
                                  },
                                  field: {
                                    clearable: true
                                  },
                                  textField: { size: 'small' }
                                }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={12} >
                            <MDBox mb={2}>
                              <MDRadio
                                label="Bill"
                                name="is_with_bill"
                                value={inputs.is_with_bill}
                                onChange={changeHandler}
                                options={[{"label":"Work Order", "value":0}, {"label":"With Bill", "value":1}]}
                                disabled={action === "edit" ? true : false}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox component="fieldset" border="1px solid #ccc" padding="1rem">
                        <MDBox component="legend" color="info" fontSize="medium">Commercial</MDBox>
                        <Grid container>
                          <Grid item md={4} xs={12} >
                            <MDBox mb={2}>
                              <MDInput
                                fullWidth
                                type="text"
                                label="Amount"
                                name="amount"
                                value={grnTotal ? numberFormat(grnTotal, false) : numberFormat(inputs.amount, false)}
                                onChange={changeHandler}
                                disabled={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4} pl={3}>
                            <MDBox mb={2}>
                              <MDInput
                                  fullWidth
                                  type="text"
                                  label="Transport"
                                  name="transport_charge"
                                  value={inputs.transport_charge ? inputs.transport_charge : ''}
                                  onChange={(e) => {onlyNumbers(e.target.value, 'decimal(10,2)') ? changeHandler(e) : ''}}
                                  disabled={action === "edit" ? true : false}
                                />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2} pl={3}>
                            <MDBox mb={2}>
                              <MDInput
                                  fullWidth
                                  type="text"
                                  label="SGST"
                                  name="sgst_tax"
                                  value={inputs.sgst_tax ? inputs.sgst_tax : ''}
                                  onChange={(e) => {onlyNumbers(e.target.value, 'decimal(10,2)') ? changeHandler(e) : ''}}
                                  disabled={action === "edit" ? true : false}
                                />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2} pl={3}>
                            <MDBox mb={2}>
                              <MDInput
                                  fullWidth
                                  type="text"
                                  label="CGST"
                                  name="cgst_tax"
                                  value={inputs.cgst_tax ? inputs.cgst_tax : ''}
                                  onChange={(e) => {onlyNumbers(e.target.value, 'decimal(10,2)') ? changeHandler(e) : ''}}
                                  disabled={action === "edit" ? true : false}
                                />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <MDInput
                                  fullWidth
                                  type="text"
                                  label="Discount"
                                  name="discount"
                                  value={inputs.discount ? inputs.discount : ''}
                                  onChange={(e) => {onlyNumbers(e.target.value, 'decimal(10,2)') ? changeHandler(e) : ''}}
                                  disabled={action === "edit" ? true : false}
                                />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4} pl={3}>
                            <MDBox mb={2}>
                              <MDInput
                                  fullWidth
                                  type="text"
                                  label="Other"
                                  name="other_charge"
                                  value={inputs.other_charge ? inputs.other_charge : ''}
                                  onChange={(e) => {onlyNumbers(e.target.value, 'decimal(10,2)') ? changeHandler(e) : ''}}
                                  disabled={action === "edit" ? true : false}
                                />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4} pl={3}>
                            <MDBox mb={2}>
                              <MDInput
                                  fullWidth
                                  type="text"
                                  label="Total"
                                  name="total"
                                  value={Number(Number(grnTotal ? grnTotal : inputs.amount) + Number(inputs.transport_charge) + Number(inputs.sgst_tax) + Number(inputs.cgst_tax) + Number(inputs.other_charge) - Number(inputs.discount)).toFixed(2)}
                                  onChange={changeHandler}
                                  disabled={true}
                                />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDTabPanel>
                    <MDTabPanel value={value} index={1}><GrnItem grnItems={grnItems} numberFormat={numberFormat} onlyNumbers={onlyNumbers} isCompleted={action === 'edit' ? true : false} openAlertSB={openAlertSB}></GrnItem></MDTabPanel>
                    <MDTabPanel value={value} index={2}><Payment numberFormat={numberFormat} onlyNumbers={onlyNumbers} poTotal={Number(grnTotal ? grnTotal : inputs.amount) + Number(inputs.transport_charge) + Number(inputs.sgst_tax) + Number(inputs.cgst_tax) + Number(inputs.other_charge) - Number(inputs.discount)}></Payment></MDTabPanel>
                    <Grid container mb={2}>
                      <Grid item md={10} xs={1}></Grid>
                      <Grid item md={1} xs={3}>
                        <MDBox mr={2}>
                          <MDButton component={Link} onClick={() => goBack()} variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={3}>
                        <MDBox mr={3}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                        </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
