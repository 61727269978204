/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import DataTable from "examples/Tables/DataTable";
import { WorkOrderContext } from "context";

// @mui icons
import WoOtherItemService from "services/wo-other-item-service";
import ItemService from "services/item-service";
import WorkOrderService from "services/work-order-service";
import CustomerInventoryService from "services/customer-inventory-service";
import SizeService from "services/size-service";
import { useCache } from "context";

function Other({numberFormat, isCancel, openAlertSB, onlyNumbers}) {
  const workOrderContext = useContext(WorkOrderContext);
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const otherItem = workOrderContext.otherItem;
//   const [otherItem, setOtherItem] = useState([]);
  const [itemDropdown, setItemDropdown] = useState([]);
  const [sizeDropdown, setSizeDropdown] = useState([]);
  const { cacheItems, setCacheItems } = useCache();
  const itemStockData = workOrderContext.itemStockData;

  useEffect(() => {

    const getItems = async() => {
        // let response; // Declare response outside the if...else block
        // if (cacheItems.hasOwnProperty('items')) {
        //   response = cacheItems['items'];
        // } else {
        //   response = await ItemService.getItems(); // Fetch customers from the API
        //   setCacheItems(prevCache => ({
        //       ...prevCache,
        //       items: response,
        //   }));
        // }
        const woData = await WorkOrderService.showWorkOrder(id);
        let response = await CustomerInventoryService.getCustomerInventories('', '', '', '', '', woData?.customer_id);

        if (response) {
          var dataArray = [];
          var stockArray = [];

          response?.map((eachData, index) => {
            if (eachData?.remaining_qty > 0) {
              const data = dataArray.filter((option) => option?.key == eachData?.item_id);
              if (data?.length == 0) {
                dataArray[dataArray?.length] = {
                  key: eachData?.item_id,
                  value: eachData?.item?.description,
                  disabled: false,
                }
                stockArray[eachData?.item_id + '_' + eachData?.size_id + '_' + (eachData?.gsm * 1)] = eachData?.remaining_qty * 1;
              } else {
                if (stockArray[eachData?.item_id + '_' + eachData?.size_id + '_' + (eachData?.gsm * 1)]) {
                  stockArray[eachData?.item_id + '_' + eachData?.size_id + '_' + (eachData?.gsm * 1)] = (stockArray[eachData?.item_id + '_' + eachData?.size_id + '_' + (eachData?.gsm * 1)] * 1) + (eachData?.remaining_qty * 1);
                } else {
                  stockArray[eachData?.item_id + '_' + eachData?.size_id + '_' + (eachData?.gsm * 1)] = eachData?.remaining_qty * 1;
                }
              }
            }
          });

          // response?.map((eachData, index) => {
          //   if (eachData.customer_supplied && eachData.stock > 0) {
          //     dataArray[index] = {
          //       key: eachData.id,
          //       value: eachData.description,
          //       disabled: eachData?.active ? false : true,
          //     }
          //     stockArray[eachData.id] = eachData.stock;
          //   }
          // });
          //
          otherItem?.map( async (eachOtherItem, index) => {
            if (eachOtherItem?.id > 0) {
              const data = dataArray.filter((option) => option?.key == eachOtherItem?.item_id);
              if (data?.length == 0) {
                dataArray[dataArray?.length] = {
                  key: eachOtherItem?.item_id,
                  value: eachOtherItem?.item?.description,
                  // disabled: true,
                }
                stockArray[eachOtherItem?.item_id + '_' + eachOtherItem?.size_id + '_' + (eachOtherItem?.gsm * 1)] = 0;
              }
            }
          });

          workOrderContext.setWoItemStockData(stockArray);
          setItemDropdown(dataArray);
        }
    }
    getItems();

    const getSizes = async() => {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('sizes')) {
          response = cacheItems['sizes'];
        } else {
          response = await SizeService.getSizes(); // Fetch customers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              sizes: response,
          }));
        }
        if (response) {
          var dataArray = [];
          response?.map((eachData, index) => {
            dataArray[index] = {
                key: eachData.id,
                value: eachData.name,
                disabled: eachData?.active ? false : true,
            }
          });
          setSizeDropdown(dataArray);
        }
    }
    getSizes();
  }, []);

  const onChangeOtherField = (index, fieldName, fieldValue) => {
    const cloneOtherItem = [...otherItem];
    const cloneItemStockData = itemStockData;

    let prevQty = cloneOtherItem[index]['quantity'] ? cloneOtherItem[index]['quantity'] : 0;
    let item_id = cloneOtherItem[index]['item_id'];
    let prev_size_id = cloneOtherItem[index]['size_id'];
    let prev_gsm = cloneOtherItem[index]['gsm'];

    cloneOtherItem[index][fieldName] = fieldValue;
    let size_id = cloneOtherItem[index]['size_id'];
    let gsm = cloneOtherItem[index]['gsm'];

    if (fieldName == 'quantity') {
      let currentOpeningStock = Number(cloneItemStockData[item_id + '_' + prev_size_id + '_' + (prev_gsm * 1)] ? cloneItemStockData[item_id + '_' + prev_size_id + '_' + (prev_gsm * 1)] : 0) + Number(prevQty);

      if (fieldValue > currentOpeningStock) {
        cloneOtherItem[index]['quantity'] = prevQty;
        openAlertSB({color: 'warning', icon: 'star', msg: 'Quantity can not be more than item stock (' + currentOpeningStock + ')', title: 'Work Order'});
      }

      cloneItemStockData[cloneOtherItem[index]['item_id'] + '_' + prev_size_id + '_' + (prev_gsm * 1)] = Number(currentOpeningStock) - cloneOtherItem[index]['quantity'];
      workOrderContext.setWoItemStockData(cloneItemStockData);
    }
    if (fieldName == 'item_id') {
      if (item_id && prev_size_id && prev_gsm) {
        cloneItemStockData[item_id + '_' + prev_size_id + '_' + (prev_gsm * 1)] = Number(cloneItemStockData[item_id + '_' + prev_size_id + '_' + prev_gsm * 1]) + Number(prevQty);
      }
      cloneOtherItem[index]['size_id'] = '';
      cloneOtherItem[index]['gsm'] = '';
      cloneOtherItem[index]['quantity'] = 0;
      workOrderContext.setWoItemStockData(cloneItemStockData);
    }
    if (fieldName == 'size_id') {
      if (cloneOtherItem[index]['quantity'] > 0) {
        cloneItemStockData[item_id + '_' + prev_size_id + '_' + (prev_gsm * 1)] = cloneItemStockData[item_id + '_' + prev_size_id + '_' + (prev_gsm * 1)] + cloneOtherItem[index]['quantity'];
      }
      cloneOtherItem[index]['gsm'] = '';
      cloneOtherItem[index]['quantity'] = 0;
      workOrderContext.setWoItemStockData(cloneItemStockData);
    }
    if (fieldName == 'gsm') {
      if (cloneOtherItem[index]['quantity'] > 0) {
        cloneItemStockData[item_id + '_' + prev_size_id + '_' + (prev_gsm * 1)] = cloneItemStockData[item_id + '_' + prev_size_id + '_' + (prev_gsm * 1)] + cloneOtherItem[index]['quantity'];
      }
      if (cloneItemStockData[item_id + '_' + prev_size_id + '_' + (cloneOtherItem[index]['gsm'] * 1)]) {
        cloneOtherItem[index]['quantity'] = cloneItemStockData[item_id + '_' + prev_size_id + '_' + (cloneOtherItem[index]['gsm'] * 1)];
        cloneItemStockData[item_id + '_' + prev_size_id + '_' + (cloneOtherItem[index]['gsm'] * 1)] = 0;
      } else {
        cloneOtherItem[index]['quantity'] = 0;
      }
      workOrderContext.setWoItemStockData(cloneItemStockData);
    }

    workOrderContext.setWoOtherItem(cloneOtherItem);
  };

  const rows = [];
  otherItem?.map((eachItem, index) => {
    const cols = {
        item: (
            <MDSelect
                fullWidth
                value={eachItem?.item_id}
                onChange={(e) => onChangeOtherField(index, 'item_id', e.target.value)}
                options={itemDropdown}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: 400,
                  "& .MuiNativeSelect-select": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
                height="40px"
            />),
        size: (
            <MDSelect
                fullWidth
                value={eachItem?.size_id}
                onChange={(e) => onChangeOtherField(index, 'size_id', e.target.value)}
                options={sizeDropdown}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: 400,
                  "& .MuiNativeSelect-select": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
                height="40px"
            />),
        gsm: (
            <MDInput
                type="text"
                value={eachItem?.gsm}
                onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeOtherField(index, 'gsm', e.target.value) : ''}}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: '6rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
            />
            ),
        quantity: (
            <MDInput
                type="text"
                value={eachItem?.quantity}
                onChange={(e) => {onlyNumbers(e.target.value, 'decimal(12,4)') ? onChangeOtherField(index, 'quantity', e.target.value) : ''}}
                disabled={eachItem?.is_approved || isCancel ? true : false}
                sx={{
                  width: '6rem',
                  "& .MuiInputBase-input": {
                    padding: "0.5rem",
                    fontSize: "13px",
                  }
                }}
            />
            ),
    };
    rows[index] = cols;
  });

  const columns = [
    { Header: "Item", accessor: "item", width: "30%", align: "left" },
    { Header: "Size", accessor: "size", width: "30%", align: "left" },
    { Header: "GSM", accessor: "gsm", width: "20%", align: "left" },
    { Header: "Quantity", accessor: "quantity", width: "20%", align: "left" },
  ];

  return (
    <Card>
        <MDBox>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder={true}
            doubleClick={false}
            thBgColor="#a195952e"
            verticalRowPadding={0.2}
            tableBorderRadius={"0.35rem"}
            headerFontSize={"0.75rem"}
          />
        </MDBox>
      </Card>
  );
}

export default Other;
