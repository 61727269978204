/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import Switch from '@mui/material/Switch';
import customAlert from "components/MDAlert/customAlert";
import GroupService from "services/group-service";
import authPermission from "utility/auth-permissions";

// @mui icons
import Icon from "@mui/material/Icon";
import { useCache } from "context";

export default function data() {
  const { hasPermission } = authPermission();
  const pagePermission = hasPermission('/manage-groups');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.group;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const navigate = useNavigate();
  const [groupData, setGroupData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [totalData, setTotalData] = useState(0);
  const { cacheItems, setCacheItems } = useCache();
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    // const getGroupData = async () => {
    //   const response = await GroupService.getMaterialGroups(page_no, page_size, sort_column, sort_column_value, search_value);
    //   setGroupData(response?.data);
    //   setTotalData(response?.total);
    // };
    //   getGroupData();
    }, []);

    const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
      var preserveData = {
        "page_no": page_no,
        "page_size": page_size,
        "sort_column": sortColumn,
        "sort_column_value": sortColumnValue,
        "search_value": searchWord,
      };
      preserveTable.setPreserveGroup(preserveData);
      const response = await GroupService.getMaterialGroups(page_no, page_size, sortColumn, sortColumnValue, searchWord);
      setGroupData(response?.data);
      setTotalData(response?.total);
    };

    const updateGroupStatus = async (id, status) => {
      try {
        var data = {'active': status};
        const res = await GroupService.updateGroupStatus(id, data);
        if (res) {
          if (cacheItems.hasOwnProperty('material_groups')) {
            // Create a new object without the key
            const { material_groups, ...rest } = cacheItems; // Destructure to exclude key
            setCacheItems(rest); // Set the new cache items without key
          }
          openAlertSB({color: 'success', icon: 'check', msg: res, title: 'Material Group'});
          setTimeout(function() {
              closeAlertSB();
          }, 3000);
          const response = await GroupService.getMaterialGroups(page_no, page_size, sort_column, sort_column_value, search_value);
          setGroupData(response?.data);
          setTotalData(response?.total);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Material Group'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Material Group'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      }
    };

  const deleteGroup = async (id) => {
    try {
      const response = await GroupService.deleteMaterialGroup(id);
      if (response) {
        if (cacheItems.hasOwnProperty('material_groups')) {
          // Create a new object without the key
          const { material_groups, ...rest } = cacheItems; // Destructure to exclude key
          setCacheItems(rest); // Set the new cache items without key
        }
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Material Group'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Material Group'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Material Group'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    setSelectedId('');
    handleClose();
    const res = await GroupService.getMaterialGroups(page_no, page_size, sort_column, sort_column_value, search_value);
    setGroupData(res?.data);
    setTotalData(res?.total);
  };

  let rows = [];
  groupData?.map((eachGroup, index) => {
      const cols = {
        id:(
              <MDTypography component={Link} to={pagePermission.edit ? `/manage-groups/edit?id=${eachGroup.id}` : ''} variant="body2" color="text">
                {eachGroup?.id}
              </MDTypography>
            ),
        name:(
          <MDTypography display="block" variant="body2">
          {eachGroup?.name}
          </MDTypography>
          ),
        type:(
          <MDTypography display="block" variant="body2">
          {eachGroup?.type ? eachGroup?.type : ''}
          </MDTypography>
          ),
        remark:(
          <MDTypography display="block" variant="body2">
          {eachGroup?.remark}
          </MDTypography>
          ),
        action:(
              <MDBox display="flex">
                {pagePermission.status ? (
                  <MDSwitch checked={eachGroup?.active ? true : false} onChange={() => updateGroupStatus(eachGroup?.id, !eachGroup?.active)} inputProps={{ 'aria-label': 'controlled' }} title="Status" color="info"/>
                ) : ''}
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-groups/edit?id=${eachGroup?.id}`} title="Edit" display="block" variant="body2" color="info">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
              ) : ''}
              {pagePermission.new ? (
                <MDTypography mr={1} mt={1} component={Link} to={`/manage-groups/add?id=${eachGroup.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                  <Icon fontSize="small">content_copy_icon</Icon>
                </MDTypography>
              ) : ''}
              {pagePermission.remove ? (
                  <MDTypography mt={1} component="a"
                  onClick={() => handleClickOpen(eachGroup?.id)}
                  title="Remove"
                  display="block" variant="body2" color="error">
                    <Icon fontSize="small">delete</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            ),
      };
      rows[index] = cols;
  });

  return {
    columns: [
      { Header: "id", accessor: "id", width: "10%", align: "left" },
      { Header: "name", accessor: "name", width: "35%", align: "left" },
      { Header: "description", accessor: "remark", width: "40%", align: "center" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "15%", align: "center" }] : [],
    ],

    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deleteGroup: deleteGroup,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
