import HttpService from "./htttp.service";

class ScheduleWorkOrderService {

  getScheduleWorkOrders = async(page, pageSize, sortColumn, sortColumnValue, searchValue, statuses, searchScheduleWoId='', searchCustomerId='', searchSize='', searchCreatedDate='', searchCompletedDate='', searchJobStartedDate='', operatorId='') => {
    let fetchUrl = 'schedule_work_orders';
    if (page && pageSize && sortColumn && sortColumnValue && operatorId) { // for OPERATOR Completed Schedule Jobs
      fetchUrl = 'schedule_work_orders?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue) + '&statuses=' + statuses + '&search_operator_id=' + operatorId + '&search_schedule_wo_id=' + searchScheduleWoId + '&search_customer_id=' + searchCustomerId + '&search_size=' + searchSize + '&search_release_date=' + searchCreatedDate + '&search_completed_date=' + searchCompletedDate + '&search_job_started_date=' + searchJobStartedDate;
    } else if (statuses && operatorId) { // get options OPERATOR completed
      fetchUrl = 'schedule_work_orders?statuses=' + statuses + '&search_operator_id=' + operatorId;
    } else if (statuses) {  // get ADMIN new inprogress schedule WO, get ADMIN completed Schedule WO, get OPERATOR schedule work order,
      fetchUrl = 'schedule_work_orders?statuses=' + statuses + '&search_schedule_wo_id=' + searchScheduleWoId + '&search_customer_id=' + searchCustomerId + '&search_size=' + searchSize + '&search_release_date=' + searchCreatedDate + '&search_completed_date=' + searchCompletedDate + '&search_job_started_date=' + searchJobStartedDate;
    }
    return await HttpService.get(fetchUrl);
  }
}

export default new ScheduleWorkOrderService();
