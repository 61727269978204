/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import authPermission from "utility/auth-permissions";
import DashboardService from "services/dashboard-service";
import { useState, useEffect } from "react";

function Dashboard() {
  const { hasPermission, systemConstant } = authPermission();
  const cancelledStatus = systemConstant('CANCELLED_STATUS');
  const newStatus = systemConstant('NEW_STATUS');
  const draftStatus = systemConstant('DRAFT_STATUS');
  const inProgressStatus = systemConstant('IN_PROGRESS_STATUS');
  const woPermission = hasPermission('/manage-work-orders');
  const userPermission = hasPermission('/user-management');
  const customerPermission = hasPermission('/manage-customers');
  const supplierPermission = hasPermission('/manage-suppliers');
  const outstandingReportPermission = hasPermission('/outstanding-bill');
  const supplierDueReportPermission = hasPermission('/supplier-payment-due');
  const [cancelledWo, setCancelledWo] = useState(0);
  const [newUsers, setNewUsers] = useState(0);
  const [newCustomers, setNewCustomers] = useState(0);
  const [newSuppliers, setNewSuppliers] = useState(0);
  const [pendingWo, setPendingWo] = useState(0);
  const [dueWo, setDueWo] = useState(0);
  const [outstandingBill, setOutstandingBill] = useState(0);
  const [supplierPaymentDue, setSupplierPaymentDue] = useState(0);
  const [operatorNewWo, setOperatorNewWo] = useState(0);
  const [operatorCompletedWo, setOperatorCompletedWo] = useState(0);
  const [operatorInProgressWo, setOperatorInProgressWo] = useState(0);
  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("user_id");

  const getDashboardStatistics = async() => {
    const data = await DashboardService.getDashboardStatistics();
    setCancelledWo(data.cancelled_wo);
    setNewUsers(data.new_users);
    setNewCustomers(data.new_customers);
    setNewSuppliers(data.new_suppliers);
    setPendingWo(data.pending_approval_wo);
    setDueWo(data.in_progress_wo);
    setOutstandingBill(data.outstanding_bills);
    setSupplierPaymentDue(data.supplier_payment_due);
  }

  const getOperatorDashboardStatistics = async() => {
    const data = await DashboardService.getOperatorDashboardStatistics(userId);
    setOperatorNewWo(data.new_wo);
    setOperatorCompletedWo(data.completed_wo);
    setOperatorInProgressWo(data.in_progress_wo);
  }

  useEffect(() => {
    if (role == 'Admin') {
      getDashboardStatistics();
    } else if (role == 'Operator') {
      getOperatorDashboardStatistics();
    }  
  }, []);
  if (role == 'Admin') {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container spacing={3}>
            {woPermission.view ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    color="error"
                    icon="work_off_icon"
                    title={<a href={woPermission.edit ? `/manage-work-orders?status_param=${cancelledStatus}` : ''} target={'_blank'} style={{ color: 'info.main', textDecoration: 'none' }}>Cancelled Work Orders</a>}
                    count={cancelledWo}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+55%",
                    //   label: "than lask week",
                    // }}
                  />
                </MDBox>
              </Grid>
            ) : ''}
            {userPermission.view ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    icon="group_add_icon"
                    title={<a href={userPermission.edit ? "/user-management" : ''} target={'_blank'} style={{ color: 'info.main', textDecoration: 'none' }}>New Users</a>}
                    count={newUsers}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+3%",
                    //   label: "than last month",
                    // }}
                  />
                </MDBox>
              </Grid>
            ) : ''}
            {customerPermission.view ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    color={'success'}
                    icon="groups_icon"
                    title={<a href={customerPermission.edit ? "/manage-customers" : ''} target={'_blank'} style={{ color: 'info.main', textDecoration: 'none' }}>New Customers</a>}
                    count={newCustomers}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+3%",
                    //   label: "than last month",
                    // }}
                  />
                </MDBox>
              </Grid>
            ) : ''}
            {supplierPermission.view ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    color={'dark'}
                    icon="diversity_2_icon"
                    title={<a href={supplierPermission.edit ? "/manage-suppliers" : ''} target={'_blank'} style={{ color: 'info.main', textDecoration: 'none' }}>New Suppliers</a>}
                    count={newSuppliers}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+3%",
                    //   label: "than last month",
                    // }}
                  />
                </MDBox>
              </Grid>
            ) : ''}
            {woPermission.view ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    color="warning"
                    icon="create_new_folder_icon"
                    title={<a href={woPermission.edit ? `/manage-work-orders?status_param=${newStatus},${draftStatus}` : ''} target={'_blank'} style={{ color: 'info.main', textDecoration: 'none' }}>New work orders pending approval</a>}
                    count={pendingWo}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+55%",
                    //   label: "than lask week",
                    // }}
                  />
                </MDBox>
              </Grid>
            ) : ''}
            {outstandingReportPermission.view ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    color={'secondary'}
                    icon="receipt_long_icon"
                    title={<a href={outstandingReportPermission.edit ? "/outstanding-bill" : ''} target={'_blank'} style={{ color: 'info.main', textDecoration: 'none' }}>Outstanding bills</a>}
                    count={outstandingBill}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+3%",
                    //   label: "than last month",
                    // }}
                  />
                </MDBox>
              </Grid>
            ) : ''}
            {supplierDueReportPermission.view ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    color="error"
                    icon="production_quantity_limits_icon"
                    title={<a href={supplierDueReportPermission.edit ? "/supplier-payment-due" : ''} target={'_blank'} style={{ color: 'info.main', textDecoration: 'none' }}>Supplier Payment Due</a>}
                    count={supplierPaymentDue}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+1%",
                    //   label: "than yesterday",
                    // }}
                  />
                </MDBox>
              </Grid>
            ) : ''}
            {woPermission.view ? (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    color="primary"
                    icon="folder_off_icon"
                    title={<a href={woPermission.edit ? `/manage-work-orders?status_param=${inProgressStatus}` : ''} target={'_blank'} style={{ color: 'info.main', textDecoration: 'none' }}>Work Orders due to be completed</a>}
                    count={dueWo}
                    // percentage={{
                    //   color: "success",
                    //   amount: "",
                    //   label: "Just updated",
                    // }}
                  />
                </MDBox>
              </Grid>
            ) : ''}
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else if (role == 'Salesperson') {
    window.location.href = '/user-profile';
  } else if (role == 'Operator') {
    return (
      <DashboardLayout>
        <DashboardNavbar />
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    icon="create_new_folder_icon"
                    title="New Work Orders"
                    count={operatorNewWo}
                    // percentage={{
                    //   color: "success",
                    //   amount: "",
                    //   label: "Just updated",
                    // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    marginTop={-5}
                    paddingTop={3}
                    paddingBottom={3}
                    color="primary"
                    icon="folder_off_icon"
                    title="In Progress Work Orders"
                    count={operatorInProgressWo}
                    // percentage={{
                    //   color: "success",
                    //   amount: "",
                    //   label: "Just updated",
                    // }}
                  />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      marginTop={-5}
                      paddingTop={3}
                      paddingBottom={3}
                      color={'success'}
                      icon="task_icon"
                      title="Completed Work Orders"
                      count={operatorCompletedWo}
                      // percentage={{
                      //   color: "success",
                      //   amount: "+3%",
                      //   label: "than last month",
                      // }}
                    />
                  </MDBox>
                </Grid>
            </Grid>
          </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default Dashboard;
