/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import dayjs from 'dayjs';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDSelect from "components/MDSelect";
import customAlert from "components/MDAlert/customAlert";
import MDCircularProgress from 'components/MDCircularProgress';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SupplierItemService from "services/supplier-item-service";
import SuppliersService from "services/supplier-service";
import UnitService from "services/unit-service";
import SizeService from "services/size-service";
import ItemService from "services/item-service";
import { useCache } from "context";

// @mui icons
import Icon from "@mui/material/Icon";
import { position } from "stylis";

function Form() {
  const {action} = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const location = useLocation();
  const [suppliersOptions, setSuppliersOptions] = useState([]);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const [sizesOptions, setSizesOptions] = useState([]);
  const [itemsOptions, setItemsOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { cacheItems, setCacheItems } = useCache();

  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [active, setActive] = useState(1);
  const [headerTitle, setHeaderTitle] = useState('');
  const [createdBy, setCreatedBy] = useState('');

  const [inputs, setInputs] = useState({
    supplier_id: "",
    item_id: "",
    purchase_unit: "",
    purchase_rate: "",
    size_id: "",
    gsm: "0.00",
    created_at: "",
  });

  const [errors, setErrors] = useState({
    supplierIdError: false,
    itemIdError: false,
    purchaseUnitError: false,
    purchaseRateError: false,
    sizeIdError: false,
    gsmError: false,
  });

  useEffect(() => {
    const getSuppliersData = async() => {
      try {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('suppliers')) {
          response = cacheItems['suppliers'];
        } else {
          response = await SuppliersService.getSuppliers(); // Fetch Suppliers from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              suppliers: response,
          }));
        }
        if (response) {
          var supplierArray = [];
          response?.map((eachSupplier, index) => {
              supplierArray[index] = {
                key: eachSupplier?.id,
                value: eachSupplier?.name,
                disabled: eachSupplier?.active ? false : true,
              }
          });
          setSuppliersOptions(supplierArray);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Supplier'};
          navigate('/manage-supplier-items', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier'};
          navigate('/manage-supplier-items', {state: state});
        }
      }
    }

    const getItemsData = async() => {
      try {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('items')) {
          response = cacheItems['items'];
        } else {
          response = await ItemService.getItems(); // Fetch Items from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              items: response,
          }));
        }
        if (response) {
          var itemArray = [];
          response?.map((eachItem, index) => {
              itemArray[index] = {
                key: eachItem?.id,
                value: eachItem?.description,
                disabled: eachItem?.active ? false : true,
              }
          });
          setItemsOptions(itemArray);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Item'};
          navigate('/manage-supplier-items', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Item'};
          navigate('/manage-supplier-items', {state: state});
        }
      }
    }

    const getUnitsData = async() => {
      try {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('units')) {
          response = cacheItems['units'];
        } else {
          response = await UnitService.getUnits(); // Fetch Units from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              units: response,
          }));
        }
        if (response) {
          var unitsArray = [];
          response?.map((eachSupplier, index) => {
              unitsArray[index] = {
                key: eachSupplier?.id,
                value: eachSupplier?.name,
                disabled: eachSupplier?.active ? false : true,
              }
          });
          setUnitsOptions(unitsArray);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Unit'};
          navigate('/manage-supplier-items', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Unit'};
          navigate('/manage-supplier-items', {state: state});
        }
      }
    }

    const getSizesData = async() => {
      try {
        let response; // Declare response outside the if...else block
        if (cacheItems.hasOwnProperty('sizes')) {
          response = cacheItems['sizes'];
        } else {
          response = await SizeService.getSizes(); // Fetch Sizes from the API
          setCacheItems(prevCache => ({
              ...prevCache,
              sizes: response,
          }));
        }
        if (response) {
          var sizesArray = [];
          response?.map((eachSize, index) => {
              sizesArray[index] = {
                key: eachSize?.id,
                value: eachSize?.name,
                disabled: eachSize?.active ? false : true,
              }
          });
          setSizesOptions(sizesArray);
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Size'};
          navigate('/manage-supplier-items', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Size'};
          navigate('/manage-supplier-items', {state: state});
        }
      }
    }

    getSuppliersData();
    getItemsData();
    getUnitsData();
    getSizesData();

    if (action === "add" && id == null) return;

    const showSupplierItem = async() => {
      try {
        const data = await SupplierItemService.showSupplierItem(id);
        setHeaderTitle(data.supplier?.name);
        data.gsm = Number(data.gsm).toFixed(2);
        setInputs(data);
        setCreatedBy(data.user.first_name + (data.user.middle_name ? (" " + data.user.middle_name) : "") + " " + data.user.last_name);
        setActive(data.active);
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          const state = {color: 'error', icon: 'warning', msg: res.message, title: 'Supplier Item'};
          navigate('/manage-supplier-items', {state: state});
        } else {
          const state = {color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier Item'};
          navigate('/manage-supplier-items', {state: state});
        }
      }
    }
    showSupplierItem();
  }, []);

  const changeHandler = (e) => {
    if (e.target.name == 'purchase_rate') {
      setErrors({
        purchaseRateError: false,
      });
    }
    if (e.target.name == 'gsm') {
      setErrors({
        gsmError: false,
      });
    }
    setLoading(false);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    const decimalFormat = /^-?([0-9]{1,8}|[0-9]{1,8}\.[0-9]{1,4})$/;
    if (inputs.purchase_rate && !inputs.purchase_rate.trim().match(decimalFormat)) {
      setErrors({ ...errors, purchaseRateError: true });
      return;
    }
    if (inputs.gsm.trim().length < 1 || !inputs.gsm.trim().match(decimalFormat)) {
      setErrors({ ...errors, gsmError: true });
      return;
    }

    const newSupplierItem = { supplier_id: inputs.supplier_id, item_id: inputs.item_id, purchase_unit: inputs.purchase_unit, purchase_rate: inputs.purchase_rate, size_id: inputs.size_id, gsm: inputs.gsm, active: active ? 1 : 0 };

    if(!newSupplierItem.supplier_id){
      openAlertSB({color: 'warning', icon: 'star', msg: "Supplier is mandatory", title: 'Supplier Item'});
      setLoading(false);
    } else {
      if (action === 'add') {
        try {
          const response = await SupplierItemService.addSupplierItem(newSupplierItem);
          setLoading(false);
          if (response) {
            const state = {color: 'success', icon: 'check', msg: 'Supplier Item Added Successfully!', title: 'Supplier Item'};
            navigate('/manage-supplier-items', { state: state });
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Supplier Item'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier Item'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      } else {
        try {
          const response = await SupplierItemService.updateSupplierItem(id ,newSupplierItem);
          setLoading(false);
          if (response) {
            openAlertSB({color: 'success', icon: 'check', msg: 'Supplier Item Updated Successfully!', title: 'Supplier Item'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
        } catch (res) {
          if (res.hasOwnProperty("message")) {
            openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Supplier Item'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          } else {
            openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Supplier Item'});
            setTimeout(function() {
              closeAlertSB();
            }, 4000);
          }
          setLoading(false);
        }
      }
    }

    return () => {
      setInputs({
        supplier_id: "",
        item_id: "",
        purchase_unit: "",
        purchase_rate: "",
        size_id: "",
        gsm: "",
      });

      setErrors({
        supplierIdError: false,
        itemIdError: false,
        purchaseUnitError: false,
        purchaseRateError: false,
        sizeIdError: false,
        gsmError: false,
      });
    };
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar breadcrumbsTitle={action === 'add' ? 'Add Supplier Item' : ('Edit Supplier Item : ' + headerTitle)} />
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={"0.75rem"}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                >
                  <MDTypography variant="h6" color="white">
                    {action === 'add' ? 'Add Supplier Item' : ('Edit Supplier Item : ' + headerTitle)}
                  </MDTypography>
                </MDBox>
                <MDBox py={3}>
                  <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                    <Grid container>
                      <Grid item xs={12} md={6} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                            label="Supplier"
                            fullWidth
                            name="supplier_id"
                            value={inputs.supplier_id}
                            onChange={changeHandler}
                            options={suppliersOptions}
                            required
                          />
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} md={6} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                            label="Items"
                            fullWidth
                            name="item_id"
                            value={inputs.item_id}
                            onChange={changeHandler}
                            options={itemsOptions}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} md={6} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                            label="Purchase Unit"
                            fullWidth
                            name="purchase_unit"
                            value={inputs.purchase_unit}
                            onChange={changeHandler}
                            options={unitsOptions}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Purchase Rate"
                            fullWidth
                            value={inputs.purchase_rate}
                            name="purchase_rate"
                            inputProps={{'maxLength':11}}
                            onChange={changeHandler}
                            error={errors.purchaseRateError}
                            helperText={errors.purchaseRateError ? "Please enter a valid number. It cannot have more than 8 digits before the decimal point and more than 4 digits after the decimal point." : ""}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} md={6} px={3}>
                        <MDBox mb={2}>
                          <MDSelect
                            label="Size"
                            fullWidth
                            name="size_id"
                            value={inputs.size_id}
                            onChange={changeHandler}
                            options={sizesOptions}
                          />
                        </MDBox>
                      </Grid>

                      <Grid item md={6} xs={12} px={3}>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="GSM"
                            fullWidth
                            value={inputs.gsm}
                            name="gsm"
                            inputProps={{'maxLength':11}}
                            onChange={changeHandler}
                            error={errors.gsmError}
                            helperText={errors.gsmError ? "Please enter a valid number. It cannot have more than 8 digits before the decimal point and more than 2 digits after the decimal point." : ""}
                            required
                          />
                        </MDBox>
                      </Grid>

                      {action === 'edit' &&
                        <>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created By"
                                fullWidth
                                name=""
                                value={createdBy}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item md={6} xs={12} px={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Created At"
                                fullWidth
                                value={dayjs(inputs.created_at).format('DD-MM-YYYY HH:mm A')}
                                inputProps={{'maxLength':50}}
                                readOnly={true}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      }

                      <Grid item md={2} xs={4} px={3}>
                        <MDBox>
                          <MDSwitch checked={active ? true : false} onChange={() => setActive(prev => !prev)} label="Active" inputProps={{ 'aria-label': 'controlled' }} color="info"/>
                        </MDBox>
                      </Grid>

                      <Grid item md={8} xs={4}></Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={2}>
                          <MDButton component={Link} to="/manage-supplier-items" variant="gradient" color="primary" fullWidth>
                            Back
                          </MDButton>
                        </MDBox>
                      </Grid>
                      <Grid item md={1} xs={2}>
                        <MDBox mr={3} sx={{position: 'relative'}}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                            {loading ? <MDCircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                }}
                              />: (action === 'add' ? 'Save' : 'Update')}
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {renderAlertSB}
    </>
  );
}

export default Form;
