/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import authPermission from "utility/auth-permissions";
import GrnService from "services/grn-service";
import dayjs from 'dayjs';

export default function data(props) {
  const { hasPermission, systemConstant } = authPermission();
  const pagePermission = hasPermission('/manage-grn');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.grn;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [grnData, setGrnData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  let location = useLocation();

  const getGrnData = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    const response = await GrnService.getGrns(page, pageSize, sortColumn, sortColumnValue, searchValue);
    setGrnData(response?.data);
    setTotalData(response?.total);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    //getGrnData(page_no, page_size, sort_column, sort_column_value, search_value);
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveGrn(preserveData);
    getGrnData(page_no, page_size, sortColumn, sortColumnValue, searchWord);
  };

  const rows = [];
  grnData?.map((eachGrn, index) => {
      const col = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-grn/edit?id=${eachGrn.id}` : ''} variant="body2" color="text">
                {eachGrn?.id}
              </MDTypography>,
        submitted_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
          {eachGrn ? dayjs(eachGrn?.submitted_date).format('DD-MM-YYYY') : ''}
          </MDTypography>
          ),
        supplier_name: (<MDTypography color="info" component={Link} to={hasPermission('/manage-suppliers').edit ? `/manage-suppliers/edit?id=${eachGrn.supplier_id}&ref=/manage-grn` : ''} display="block" variant="body2">
          {eachGrn?.supplier_name}
          </MDTypography>
        ),
        bill_no: (<MDTypography display="block" variant="body2">
          {eachGrn?.bill_no}
          </MDTypography>
        ),
        action: (
              <MDBox display="flex">
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-grn/edit?id=${eachGrn.id}`} title="Edit" display="block" variant="caption" color="info" fontWeight="medium">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            )
      };
      rows[index] = col;
  });

  return {
    columns: [
      { Header: "GRN Id", accessor: "id", width: "8%", align: "left" },
      { Header: "Date", accessor: "submitted_date", width: "8%", align: "left" },
      { Header: "Supplier", accessor: "supplier_name", width: "25%", align: "left" },
      { Header: "Bill No.", accessor: "bill_no", width: "11%", align: "left" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "10%", align: "center" }] : [],
    ],
    rows: rows,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
