export const adminPermissions =
    {
        "/dashboard": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "Setup": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "Access Management": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-customers": {
            view: 0,
            status: 0,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-customer-inventories": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-suppliers": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-groups": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-supplier-items": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-sizes": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-units": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-colors": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-payment-types": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-printing-machines": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-printing-rates": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-parameters": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-wo-printing-types": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-items": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-statuses": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-hsn-codes": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-schedule-work-orders": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/user-profile": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/user-management": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/permission": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-customers/customer-rates": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "work_order": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "material_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "printing_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "material_from_stock_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "other_items_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "payment_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "wo_rates": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "Reports": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
    };

export const operatorPermissions =
    {
        "/dashboard": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "Setup": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "Access Management": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-customers": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-customer-inventories": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-suppliers": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-groups": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-supplier-items": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-sizes": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-units": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-colors": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-payment-types": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-printing-machines": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-printing-rates": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-parameters": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-wo-printing-types": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-items": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-statuses": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-hsn-codes": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-schedule-work-orders": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/user-profile": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/user-management": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/permission": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-customers/customer-rates": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "work_order": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "material_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "printing_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "material_from_stock_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "other_items_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "payment_tab": {
            view: 1,
            status: 0,
            new: 0,
            edit: 1,
            remove: 0
        },
        "wo_rates": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
    };

export const salesPersonPermissions =
    {
        "/dashboard": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "Setup": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "Access Management": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/manage-customers": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-customer-inventories": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-suppliers": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-groups": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-supplier-items": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-sizes": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-units": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-colors": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-payment-types": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-printing-machines": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-printing-rates": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-parameters": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-wo-printing-types": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-items": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-statuses": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-hsn-codes": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-schedule-work-orders": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/user-profile": {
            view: 1,
            status: 1,
            new: 1,
            edit: 1,
            remove: 1
        },
        "/user-management": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/permission": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "/manage-customers/customer-rates": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "work_order": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "material_tab": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "printing_tab": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "material_from_stock_tab": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "other_items_tab": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "payment_tab": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
        "wo_rates": {
            view: 0,
            status: 0,
            new: 0,
            edit: 0,
            remove: 0
        },
    };
