/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MDInput from "components/MDInput";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const MDDatePicker = forwardRef(
  ({ label,
     name,
     value,
     format,
     onChange,
     slotProps,
     disabled,
     sx,
     ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']} sx={{marginTop: '-0.500rem'}}>
          <DatePicker
            {...rest}
            label={label}
            name={name}
            value={value}
            format={format}
            onChange={onChange}
            slotProps={slotProps}
            disabled={disabled}
            sx={sx}
            renderInput={(params) => (
              <MDInput {...params} />
            )}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  }
);

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  slotProps: { textField: {
      fullWidth: true,
      error: false,
      required: false,
      size: 'small',
    },
    field: {
      clearable: true
    },
  },
  sx: {width: "100%", height: "40px"},
  disabled: false,
};

export default MDDatePicker;
