/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSwitch from "components/MDSwitch";
import MDCircularProgress from "components/MDCircularProgress";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import authPermission from "utility/auth-permissions";
import PurchaseOrderService from "services/purchase-order-service";
import PoItemService from "services/po-item-service";
import dayjs from 'dayjs';
import { PoPDFTemplate } from "./po-pdf-template";

export default function data(props) {
  const { hasPermission, systemConstant } = authPermission();
  const pagePermission = hasPermission('/manage-purchase-orders');
  const companyName = systemConstant('COMPANY_NAME');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.purchaseOrder;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedSupplierName, setSelectedSupplierName] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [colorData, setColorData] = useState([]);
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPreview(false);
  };

  const numberFormat = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(number);
  }

  const getPurchaseOrderData = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    const response = await PurchaseOrderService.getPurchaseOrders(page, pageSize, sortColumn, sortColumnValue, searchValue);
    const updatedData = response.data.map(item => ({
      ...item, // Spread existing properties
      print_po_loading: false // Add the new key with its value
    }));

    // Set the updated data to state
    setPurchaseOrderData(updatedData);
    setTotalData(response?.total);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    //getPurchaseOrderData(page_no, page_size, sort_column, sort_column_value, search_value);
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreservePurchaseOrder(preserveData);
    getPurchaseOrderData(page_no, page_size, sortColumn, sortColumnValue, searchWord);
  };

  const deletePurchaseOrder = async (id) => {
    try {
      const response = await PurchaseOrderService.deletePurchaseOrder(id);
      if (response) {
        openAlertSB({color: 'success', icon: 'check', msg: response, title: 'Purchase Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Purchase Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      } else {
        openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Purchase Order'});
        setTimeout(function() {
          closeAlertSB();
        }, 4000);
      }
    }
    getPurchaseOrderData(page_no, page_size, sort_column, sort_column_value, search_value);
    setSelectedId('');
    handleClose();
  };

  const getPDFData = async(id) => {
    const data = await PurchaseOrderService.showPurchaseOrder(id);
    const items = await PoItemService.getPoItems(id);
    // Check if 'gsm' is present in any 'poItems'
    const hasGsm = items.some(item => item.gsm > 0);

    // Check if 'size' is present in any 'poItems'
    const hasSize = items.some(item => item.size);
    return {
      order: data,
      poItem: items,
      hasGsm: hasGsm,
      hasSize: hasSize,
    };
  }

  const handlePrint = async(id, index) => {
    const clonePurchaseOrderData = [...purchaseOrderData];
    console.log('purchaseOrderData=>', clonePurchaseOrderData);
    // clonePurchaseOrderData[index]['print_po_loading'] = true;
    const response = await getPDFData(id);
    const companyName = systemConstant('COMPANY_NAME');
    const companyAddress = systemConstant('COMPANY_ADDRESS');
    if(response){
        const pdfTemplate = PoPDFTemplate(companyName, companyAddress, response.order, response.poItem, response.hasGsm, response.hasSize, numberFormat);

        const originalBody = document.body;
        const printWindow = window.open('', '_blank');
        // clonePurchaseOrderData[index]['print_po_loading'] = false;
        setPurchaseOrderData(clonePurchaseOrderData);
        printWindow.document.write(`${pdfTemplate}`);
        printWindow.document.close();
        await new Promise(r => setTimeout(r, 2000));
        printWindow.print();
        printWindow.close();
        document.body = originalBody;
    }
  };

  const sendEmail = async(id, email) => {
    setOpenPreview(false);
    if (email) {
      openAlertSB({color: 'info', icon: 'info_icon', msg: 'Sending email is in process.Please wait.', title: 'Purchase Order'});
      try {
        const response = await PurchaseOrderService.sendEmail(id);
        if (response) {
          openAlertSB({color: 'success', icon: 'check', msg: 'Email sent Successfully!', title: 'Purchase Order'});
        }
      } catch (res) {
        if (res.hasOwnProperty("message")) {
          openAlertSB({color: 'error', icon: 'warning', msg: res.message, title: 'Purchase Order'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        } else {
          openAlertSB({color: 'error', icon: 'warning', msg: res.errors[0].detail, title: 'Purchase Order'});
          setTimeout(function() {
            closeAlertSB();
          }, 4000);
        }
      }
    } else {
      openAlertSB({color: 'warning', icon: 'star', msg: 'Email is not added to this Supplier', title: 'Purchase Order'});
    }
    getPurchaseOrderData(page_no, page_size, sort_column, sort_column_value, search_value);
  }

  const rows = [];
  purchaseOrderData?.map((eachPurchaseOrder, index) => {
      const col = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-purchase-orders/edit?id=${eachPurchaseOrder.id}` : ''} variant="body2" color="text">
                {eachPurchaseOrder?.id}
              </MDTypography>,
        submitted_date: (<MDTypography display="block" variant="body2" whiteSpace="nowrap">
          {eachPurchaseOrder ? dayjs(eachPurchaseOrder?.submitted_date).format('DD-MM-YYYY') : ''}
          </MDTypography>
          ),
        supplier_name: (<MDTypography color="info" component={Link} to={hasPermission('/manage-suppliers').edit ? `/manage-suppliers/edit?id=${eachPurchaseOrder.supplier_id}&ref=/manage-purchase-orders` : ''} display="block" variant="body2">
          {eachPurchaseOrder?.supplier_name}
          </MDTypography>
        ),
        amount: (<MDTypography display="block" variant="body2">
          {numberFormat(eachPurchaseOrder?.amount)}
          </MDTypography>
        ),
        job_type: (<MDTypography color="info" component={Link} to={pagePermission.edit ? `/manage-purchase-orders/edit?id=${eachPurchaseOrder.id}` : ''} display="block" variant="body2">
          {eachPurchaseOrder?.job_type}
          </MDTypography>
        ),
        work_order_id: (<MDTypography display="block" variant="body2" color={eachPurchaseOrder?.work_order_id ? "info" : ''} component={eachPurchaseOrder?.work_order_id ? Link : ''} to={(hasPermission('/manage-work-orders').edit && eachPurchaseOrder.work_order_id) ? `/manage-work-orders/edit?id=${eachPurchaseOrder.work_order_id}&ref=/manage-purchase-orders` : ''}>
          {eachPurchaseOrder?.work_order_id ? eachPurchaseOrder?.work_order_id : ''}
          </MDTypography>
        ),
        status: (<MDTypography display="block" variant="body2">
          {eachPurchaseOrder?.name}
          </MDTypography>
        ),
        action: (
              <MDBox display="flex">
                <MDTypography mr={1} mt={1} component={Link} onClick={() => handlePrint(eachPurchaseOrder.id, index)} title="Print PO" display="block" variant="caption" color="secondary" fontWeight="medium">
                    {eachPurchaseOrder?.print_po_loading ? <MDCircularProgress size={20} color="info"/> : <Icon fontSize="small">print_icon</Icon>}
                </MDTypography>
                <MDTypography mr={1} mt={1} component={Link} onClick={() => {setOpenPreview(true);setSelectedId(eachPurchaseOrder.id);setSelectedEmail(eachPurchaseOrder?.email);setSelectedSupplierName(eachPurchaseOrder?.supplier_name)}} color={eachPurchaseOrder?.is_email_sent ? 'success' : 'warning'} display="block" variant="caption" title={eachPurchaseOrder?.is_email_sent ? 'Email is sent' : 'Send Email'} fontWeight="medium">
                    <Icon fontSize="small">forward_to_inbox_icon</Icon>
                </MDTypography>
                {pagePermission.edit ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-purchase-orders/edit?id=${eachPurchaseOrder.id}`} title="Edit" display="block" variant="caption" color="info" fontWeight="medium">
                    <Icon fontSize="small">edit</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.new ? (
                  <MDTypography mr={1} mt={1} component={Link} to={`/manage-purchase-orders/add?id=${eachPurchaseOrder.id}`} title="Copy" display="block" variant="caption" color="secondary" fontWeight="medium">
                    <Icon fontSize="small">content_copy_icon</Icon>
                  </MDTypography>
                ) : ''}
                {pagePermission.remove ? (
                  <MDTypography mt={1} component={Link} onClick={() => handleClickOpen(eachPurchaseOrder.id)} title="Remove" display="block" variant="caption" color="error" fontWeight="error">
                    <Icon fontSize="small">delete</Icon>
                  </MDTypography>
                ) : ''}
                {renderAlertSB}
              </MDBox>
            )
      };
      rows[index] = col;
  });

  return {
    columns: [
      { Header: "PO#", accessor: "id", width: "7%", align: "left" },
      { Header: "Date", accessor: "submitted_date", width: "7%", align: "left" },
      { Header: "Supplier", accessor: "supplier_name", width: "19%", align: "left" },
      { Header: "Net Amount", accessor: "amount", width: "12%", align: "left" },
      { Header: "Job Type", accessor: "job_type", width: "30%", align: "left" },
      { Header: "WO Id", accessor: "work_order_id", width: "7%", align: "left" },
      { Header: "Status", accessor: "status", width: "8%", align: "left" },
      ...(pagePermission.status || pagePermission.edit || pagePermission.remove) ? [{ Header: "actions", accessor: "action", width: "8%", align: "center" }] : [],
    ],
    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    deletePurchaseOrder: deletePurchaseOrder,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
    companyName: companyName,
    selectedEmail: selectedEmail,
    selectedSupplierName: selectedSupplierName,
    openPreview: openPreview,
    sendEmail: sendEmail,
    handlePrint: handlePrint,
  };
}
