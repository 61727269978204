import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import userTableData from "layouts/user-management/data";

const UserManagement = () => {
  const { columns, rows, open, handleClickOpen, handleClose, deleteUser, selectedId, pagePermission, totalData, page, pageSize, getData, sortColumn, sortColumnValue, searchValue } = userTableData();

  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={"0.3rem"}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={11}>
                    <MDTypography mt={1} variant="h6" color="white">
                      Manage Users
                    </MDTypography>
                  </Grid>
                  <Grid item xs={1}>
                    { pagePermission.new ? (
                      <MDButton ml={5}
                      component={Link}
                      to="/user-management/add"
                      variant="outlined" color="white" iconOnly circular title="Add">
                        <Icon fontSize="medium" sx={{ fontWeight: "bold", fontSize:"16px" }}>add</Icon>
                      </MDButton>
                    ) : ''}
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox>
                <DataTable
                  entriesPerPage={{
                    "defaultValue": pageSize
                  }}
                  canSearch={true}
                  showTotalEntries={true}
                  table={{ columns, rows }}
                  isSorted={true}
                  totalData={totalData}
                  initialPageIndex={page - 1}
                  getData={getData}
                  pageCount={Math.ceil(totalData/pageSize)}
                  sortCol={sortColumn}
                  sortColValue={sortColumnValue}
                  searchVal={searchValue}
                  noEndBorder={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>

    <MDDialog
    open={open}
    onClose={handleClose}
    successAction={() => deleteUser(selectedId)}
    dialogTitle={'Are you Sure?'}
    cancleButton={'No, Please!'}
    successButton={'Yes, Delete it!'}
    cancleButtonColor={'light'}
    successButtonColor={'info'}
    icon={<Icon sx={{ width:'100%', marginTop:"25px" }} fontSize="large" color="primary">error</Icon>}
    >
    <p>You will not be able to recover the deleted record!</p>
    </MDDialog>
    </>
  );
};

export default UserManagement;
