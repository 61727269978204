/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDMultipleSelect
import MDSelectRoot from "components/MDMultipleSelect/MDSelectRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import { Typography } from "@mui/material";

const MDMultipleSelect = React.forwardRef(
  ({ label, name, value, options, onChange, disabled, required, size, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const preSelectOption = options ? options : [];
    let selectOption = [];
    let optionsArray = [];

    preSelectOption?.map((eachOption, index) => {
      optionsArray[eachOption.key] = eachOption.value;
      if (value.includes(eachOption.key)) {
        selectOption = [eachOption, ...selectOption];
      } else {
        selectOption.push(eachOption);
      }
    });

    const ITEM_HEIGHT = 50;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
        <Box>
            <FormControl size={size} fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">{label} {required ? '*' : ''}</InputLabel>
                <MDSelectRoot
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name={name}
                  value={value}
                  onChange={onChange}
                  input={<OutlinedInput label={label} />}
                  renderValue={(selected) =>
                    (selected.map((value, index) => {
                      return optionsArray[value] + (selected.length - 1 != index ? ', ' : '');
                    }))}
                  MenuProps={MenuProps}
                  disabled={disabled}
                  required={required}
                  {...rest}
                  ownerState={{ disabled }}
                  >
                  {selectOption?.map((eachOption) => (
                      <MenuItem sx={{ height: '2.2rem'}} value={eachOption.key}>
                        <Checkbox checked={value.indexOf(eachOption.key) > -1} />
                        <ListItemText primary={
                          <Typography sx={{ fontSize: '1rem' }}> {/* Adjust font size here */}
                            {eachOption.value}
                          </Typography>} />
                      </MenuItem>
                  ))}
                </MDSelectRoot>
            </FormControl>
        </Box>
    );
  }
);

// Setting default values for the props of MDMultipleSelect
MDMultipleSelect.defaultProps = {
  disabled: false,
  required: false,
  size: 'small',
};

export default MDMultipleSelect;
