/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import customAlert from "components/MDAlert/customAlert";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import { PreserveTable } from "context";
import authPermission from "utility/auth-permissions";
import dayjs from 'dayjs';
import WorkOrderService from "services/work-order-service";
import { numberFormat } from "utility/helpers";

export default function data(props) {
  const { hasPermission, systemConstant } = authPermission();
  const pagePermission = hasPermission('/manage-invoices');
  const woPermission = hasPermission('/manage-work-orders');
  const customerPermission = hasPermission('/manage-customers');
  const preserveTable = useContext(PreserveTable);
  const {page_no, page_size, sort_column, sort_column_value, search_value} = preserveTable?.invoice;
  const { openAlertSB, closeAlertSB, renderAlertSB } = customAlert();
  const [workOrderData, setWorkOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [colorData, setColorData] = useState([]);
  let location = useLocation();

  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getWorkOrderData = async (page, pageSize, sortColumn, sortColumnValue, searchValue) => {
    const response = await WorkOrderService.getWorkOrdersForInvoice(page, pageSize, sortColumn, sortColumnValue, searchValue);
    setWorkOrderData(response?.data);
    setTotalData(response?.total);
  };

  useEffect(() => {
    if (location?.state?.color) {
      openAlertSB(location?.state);
      setTimeout(function() {
          closeAlertSB();
      }, 3000);
    }
    getWorkOrderData(page_no, page_size, sort_column, sort_column_value, search_value);
  }, []);

  const getData = async (page_no, page_size, sortColumn, sortColumnValue, searchWord) => {
    var preserveData = {
      "page_no": page_no,
      "page_size": page_size,
      "sort_column": sortColumn,
      "sort_column_value": sortColumnValue,
      "search_value": searchWord,
    };
    preserveTable.setPreserveWorkOrder(preserveData);
    getWorkOrderData(page_no, page_size, sortColumn, sortColumnValue, searchWord);
  };

  const rows = [];
  workOrderData?.map((eachWorkOrder, index) => {
      const col = {
        id: <MDTypography component={Link} to={pagePermission.edit ? `/manage-invoices/add?id=${eachWorkOrder.id}` : ''} variant="body2" color="text">
                {eachWorkOrder?.id}
              </MDTypography>,      
        release_date: (<MDTypography display="block" variant="body2" >
          {eachWorkOrder ? dayjs(eachWorkOrder?.release_date).format('DD-MM-YYYY') : ''}
          </MDTypography>
          ),
        customer_name: (<MDTypography color="info" component={Link} to={customerPermission.edit ? `/manage-invoices/add?id=${eachWorkOrder.id}&ref=/manage-invoices/create` : ''} display="block" variant="body2">
          {eachWorkOrder?.customer_name}
          </MDTypography>
        ),
        job_type: (<MDTypography display="block" variant="body2">
          {eachWorkOrder?.job_type}
          </MDTypography>
        ),
        delivery_date: (<MDTypography display="block" variant="body2">
            {eachWorkOrder ? dayjs(eachWorkOrder?.delivery_date).format('DD-MM-YYYY') : ''}
            </MDTypography>
          ),
        status: <MDTypography 
        //color="info" component={Link} to={woPermission.edit ? `/manage-work-orders/edit?id=${eachWorkOrder.work_order_id}&ref=/manage-invoices` : ''} 
        display="block" variant="body2">
            {eachWorkOrder?.status?.name}
            </MDTypography>,  
        created: (<MDTypography display="block" variant="body2">
            <MDTypography display="block" variant="body2">
                {eachWorkOrder?.first_name + " " + eachWorkOrder?.last_name}
            </MDTypography>
            <MDTypography display="block" variant="body2">
                {eachWorkOrder? dayjs(eachWorkOrder?.created_at).format('DD-MM-YYYY hh:mm A') : ''}
            </MDTypography>
            {renderAlertSB}
          </MDTypography>
        ),
      };
      rows[index] = col;
  });

  return {
    columns: [
      { Header: "WO #", accessor: "id", width: "12%", align: "left" },
      { Header: "Date", accessor: "release_date", width: "8%", align: "left" },
      { Header: "Party", accessor: "customer_name", width: "16%", align: "left" },
      { Header: "Job Type", accessor: "job_type", width: "11%", align: "left" },
      { Header: "Delivery Date", accessor: "delivery_date", width: "14%", align: "left" },
      { Header: "Status", accessor: "status", width: "14%", align: "left" },
      { Header: "Created By & At", accessor: "created", width: "13%", align: "left" },
    ],
    rows: rows,
    open: open,
    handleClickOpen: handleClickOpen,
    handleClose: handleClose,
    selectedId: selectedId,
    totalData: totalData,
    page: page_no,
    pageSize: page_size,
    getData: getData,
    sortColumn: sort_column,
    sortColumnValue: sort_column_value,
    searchValue: search_value,
    pagePermission: pagePermission,
  };
}
