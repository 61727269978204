import HttpService from "./htttp.service";

class SizeService {

  getSizes = async(page, pageSize, sortColumn, sortColumnValue, searchValue, groups='') => {
    let fetchUrl = 'sizes';
    if (page && pageSize && sortColumn && sortColumnValue) {
      fetchUrl = 'sizes?page=' + page + '&per_page=' + pageSize + '&sort_column=' + sortColumn + '&sort_column_value=' + sortColumnValue + '&search_value=' + encodeURIComponent(searchValue);
    } else if (groups) {
      fetchUrl = 'sizes?groups=' + groups;
    }
    return await HttpService.get(fetchUrl);
  }

  addSize = async(newSize) => {
    const fetchUrl = 'sizes';
    return await HttpService.post(fetchUrl, newSize);
  }

  updateSize = async (id, data) => {
    const fetchUrl = 'sizes/' + id;
    return await HttpService.put(fetchUrl, data);
  }

  updateSizeStatus = async (id, data) => {
    const fetchUrl = 'sizes/' + id;
    return await HttpService.patch(fetchUrl, data);
  }

  showSize = async(id) => {
    const fetchUrl = 'sizes/' + id;
    return await HttpService.get(fetchUrl);
  }

  deleteSize = async (id) => {
    const fetchUrl = 'sizes/' + id;
    return await HttpService.delete(fetchUrl);
  }
}

export default new SizeService();
