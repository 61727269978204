/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for MDButton
import MDSelectRoot from 'components/MDSelect/MDSelectRoot';

// Material Dashboard 2 React contexts
import { useMaterialUIController } from 'context';
import MDBox from 'components/MDBox';

const MDSelect = React.forwardRef(
  ({ label, labelProps, name, value, options, onChange, disabled, required, height, isSort, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const [itemValue, setItemValue] = React.useState('');
    const [currentInput, setCurrentInput] = React.useState('');
    const focusTimeoutRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    let selectOption = options ? options : [];

    React.useEffect (() => {
      setItemValue(value ? value : 0);
      // return () => {
      //   if (focusTimeoutRef.current !== null) {
      //     clearTimeout(focusTimeoutRef.current);
      //   }
      // };
    }, [value]);

    if (isSort) {
      selectOption = selectOption.sort((a, b) => {
        if (typeof a.value === 'number') {
          return a.value - b.value;
        } else {
          return a.value.localeCompare(b.value);
        }
      });
    }

    const disabledOptions = selectOption.filter((option) => option?.disabled);
    const enabledOptions = selectOption.filter((option) => !option?.disabled);
    selectOption = [...enabledOptions, ...disabledOptions];
    selectOption.splice(0, 0, { key: 0, value: 'Select', disabled: false });

    const changeHandler = (selectedValue) => {
      // focusTimeoutRef.current = null;
      // focusTimeoutRef.current = window.setTimeout(() => {
      //   focusTimeoutRef.current = null;
      // }, 50); // Set the timeout duration as needed

      setItemValue(selectedValue);
      const selectedOption = {
        target: {
          name: name,
          value: Number(selectedValue) ? Number(selectedValue) : (name == 'role') ? selectedValue : null,
        }
      };
      onChange(selectedOption);
    };

    const handelOpen = () => {
      setOpen(true);
    };

    const handelClose = () => {
      setOpen(false);
      // focusTimeoutRef.current = null;
      // focusTimeoutRef.current = window.setTimeout(() => {
      //   focusTimeoutRef.current = null;
      // }, 50); // Set the timeout duration as needed
    };

    // const handleKeyPress = (event) => {
    //   const prevKey = currentInput;
    //   const searchKey = prevKey + event.key;
    //   setCurrentInput(searchKey);

    //   if (event.key === 'ArrowDown') {
    //     event.preventDefault();
    //     handelClose();
    //     const nextIndex = selectOption.findIndex((option) => option.key === itemValue) + 1;
    //     if (nextIndex < selectOption.length) {
    //       if (!selectOption[nextIndex].disabled) {
    //         setItemValue(selectOption[nextIndex].key);
    //         changeHandler(selectOption[nextIndex].key);
    //       }
    //     }
    //     setCurrentInput('');
    //   } if (event.key === 'ArrowUp') {
    //     event.preventDefault();
    //     handelClose();
    //     const prevIndex = selectOption.findIndex((option) => option.key === itemValue) - 1;
    //     if (prevIndex < selectOption.length && prevIndex >= 0) {
    //       if (!selectOption[prevIndex].disabled) {
    //         setItemValue(selectOption[prevIndex].key);
    //         changeHandler(selectOption[prevIndex].key);
    //       }
    //     }
    //     setCurrentInput('');
    //   } else if (event.key.length === 1) {
    //     setTimeout(function() {
    //       const matchingOption = selectOption.find((option) => option.value.toLowerCase().startsWith(searchKey.toLowerCase()));
    //       if (matchingOption && !matchingOption.disabled) {
    //         setItemValue(matchingOption.key);
    //         changeHandler(matchingOption.key);
    //       }
    //       setCurrentInput('');
    //     }, 500);
    //   }
    // };

    return (
      <Box>
        <FormControl size="small" fullWidth sx={{ overflowY: 'visible', justifyContent: "center"}}>
          <InputLabel id="demo-simple-select-label" sx={labelProps}>{label} {required ? '*' : ''}</InputLabel>
          <MDSelectRoot
            labelId="demo-simple-select-label"
            name={name}
            open={open}
            onOpen={() => {
              if (!open) {
                handelOpen();
              }
            }}
            onClose={handelClose}
            value={itemValue}
            label={label}
            onChange={(e) => {
              changeHandler(e.target.value);
            }}
            disabled={disabled}
            required={required}
            // onKeyPress={(e) => {
            //   !rest.native ? handleKeyPress(e) : '';
            // }}
            // onKeyDown={(e) => {
            //   rest.native ? handleKeyPress(e) : '';
            // }}
            // onFocus={() => {
            //   if (focusTimeoutRef.current === null) {
            //     focusTimeoutRef.current = window.setTimeout(() => {
            //       focusTimeoutRef.current = null;
            //     }, 50); // Set the timeout duration as needed
            //     handelOpen();
            //   }
            // }}
            {...rest}
            ownerState={{ disabled }}
            MenuProps={
              {
                PaperProps: {
                style: {
                  maxHeight: 300,
                },
              }}}
          >
            {/* {required ? '' : (<MenuItem value="">Select</MenuItem>)} */}
            {selectOption?.map((eachOption) => (
              rest.native ? <option value={eachOption.key} disabled={eachOption?.disabled ? (eachOption?.disabled) : false} onFocus={(e) => changeHandler(eachOption.key)}>{eachOption.value}</option> : <MenuItem value={eachOption.key} disabled={eachOption?.disabled ? (eachOption?.disabled) : false} onFocus={(e) => changeHandler(eachOption.key)}>{eachOption.value}</MenuItem>
            ))}
          </MDSelectRoot>
        </FormControl>
      </Box>
    );
  }
);

// Setting default values for the props of MDButton
MDSelect.defaultProps = {
  disabled: false,
  required: false,
  labelProps: {},
  native: true,
  height: "40px",
  isSort: true,
};

export default MDSelect;
