/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav  .

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import ManageColor from "layouts/manage-colors";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";
import Permission from "layouts/permission";

import Login from "auth/login";
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";
import ManageGroup from "layouts/material-group";
import ManagePaymentType from "layouts/manage-payment-types";
import ManageParameter from "layouts/manage-parameters";
import ManageCustomer from "layouts/manage-customers";
import ManageCustomerInventory from "layouts/manage-customer-inventories";
import ManageStatus from "layouts/manage-statuses";
import ManageSupplier from "layouts/manage-suppliers";
import ManageUnit from "layouts/manage-units";
import ManagePrintingMachine from "layouts/manage-printing-machines";
import ManagePrintingRate from "layouts/manage-printing-rates";
import ManageSize from "layouts/manage-sizes";
import ManageHsnCode from "layouts/manage-hsn-codes";
import ManageWoPrintingType from "layouts/manage-printing-types";
import ManageItems from "layouts/manage-items";
import ManageSupplierItem from "layouts/manage-supplier-items";
import ManageWorkOrder from "layouts/manage-work-orders";
import ManagePurchaseOrder from "layouts/manage-purchase-orders";
import ManageGrn from "layouts/manage-grn";
import ManageScheduleWorkOrder from "layouts/schedule-work-orders";
import ManageInvoice from "layouts/manage-invoices";
import PurchaseRegister from "reports/purchase-register";
import SupplierItemRegister from "reports/supplier-item-register";
import IncompleteWo from "reports/incomplete-work-order";
import SupplierPurchaseRegister from "reports/supplier-purchase-register";
import PaymentReceived from "reports/payment-received";
import SupplierPaymentDue from "reports/supplier-payment-due";
import SupplierPaymentMade from "reports/supplier-payment-made";
import DayWiseCosting from "reports/day-wise-costing";
import SupplierInvoiceRegister from "reports/supplier-invoice-register";
import InvoiceRegister from "reports/invoice-register";
import JobCostSheet from "reports/job-cost-sheet";
import JobCostSheetSummary from "reports/job-cost-sheet-summary";
import OutstandingBill from "reports/outstanding-bill";
import ItemWiseSupplierWiseGrn from "reports/item-wise-supplier-wise-grn";
import ItemReceiptVSIssue from "reports/item-receipt-vs-issue";
import CustomerMonthlySale from "reports/customer-monthly-sale";
import DailyPaymentReceived from "reports/daily-payment-received";
import MachineWiseSale from "reports/machine-wise-sale";
import DailyCustomerPaper from "reports/customer-inventory-inward";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    order: 101,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Work Orders",
    key: "manage-work-orders",
    icon: <Icon fontSize="medium">folder_icon</Icon>,
    route: "/manage-work-orders",
    order: 102,
    component: <ManageWorkOrder />,
  },
  {
    type: "collapse",
    name: "Purchase Orders",
    key: "manage-purchase-orders",
    icon: <Icon fontSize="medium">shopping_cart_icon</Icon>,
    route: "/manage-purchase-orders",
    order: 103,
    component: <ManagePurchaseOrder />,
  },
  {
    type: "collapse",
    name: "GRN",
    key: "manage-grn",
    icon: <Icon fontSize="medium">add_shopping_cart_icon</Icon>,
    route: "/manage-grn",
    order: 104,
    component: <ManageGrn/>,
  },
  {
    type: "collapse",
    name: "Schedule WO",
    key: "manage-schedule-work-orders",
    icon: <Icon fontSize="small">event_note_icon</Icon>,
    route: "/manage-schedule-work-orders",
    order: 105,
    component: <ManageScheduleWorkOrder />,
  },
  {
    type: "collapse",
    name: "Invoices",
    key: "manage-invoices",
    icon: <Icon fontSize="small">receipt_icon</Icon>,
    route: "/manage-invoices",
    order: 106,
    component: <ManageInvoice />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    heading: 1,
    order: 150,
    icon: <Icon fontSize="medium">query_stats_icon</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "Purchase Register",
        key: "purchase-register",
        icon: <Icon fontSize="medium">shop_icon</Icon>,
        route: "/purchase-register",
        order: 151,
        component: <PurchaseRegister />,
      },
      {
        type: "collapse",
        name: "Supplier Item Register",
        key: "supplier-item-register",
        icon: <Icon fontSize="medium">category_icon</Icon>,
        route: "/supplier-item-register",
        order: 152,
        component: <SupplierItemRegister />,
      },
      {
        type: "collapse",
        name: "Supplier Purchase Register",
        key: "supplier-purchase-register",
        icon: <Icon fontSize="medium">shop_two_icon</Icon>,
        route: "/supplier-purchase-register",
        order: 153,
        component: <SupplierPurchaseRegister />,
      },
      {
        type: "collapse",
        name: "Item Wise Sup. Wise GRN",
        key: "item-wise-supplier-wise-grn",
        icon: <Icon fontSize="medium">category_icon</Icon>,
        route: "/item-wise-supplier-wise-grn",
        order: 154,
        component: <ItemWiseSupplierWiseGrn />,
      },
      {
        type: "collapse",
        name: "Supplier Invoice Register",
        key: "supplier-invoice-register",
        icon: <Icon fontSize="medium">description_icon</Icon>,
        route: "/supplier-invoice-register",
        order: 155,
        component: <SupplierInvoiceRegister />,
      },
      {
        type: "collapse",
        name: "Item Receipt VS Issue",
        key: "item-receipt-vs-issue",
        icon: <Icon fontSize="medium">inventory_icon</Icon>,
        route: "/item-receipt-vs-issue",
        order: 156,
        component: <ItemReceiptVSIssue />,
      },
      {
        type: "collapse",
        name: "Incomplete Work Order",
        key: "incomplete-wo-register",
        icon: <Icon fontSize="medium">folder_off_icon</Icon>,
        route: "/incomplete-wo-register",
        order: 157,
        component: <IncompleteWo />,
      },
      {
        type: "collapse",
        name: "Supplier Payment Due",
        key: "supplier-payment-due",
        icon: <Icon fontSize="medium">production_quantity_limits_icon</Icon>,
        route: "/supplier-payment-due",
        order: 158,
        component: <SupplierPaymentDue />,
      },
      {
        type: "collapse",
        name: "Customer Monthly Sales",
        key: "customer-monthly-sale",
        icon: <Icon fontSize="medium">show_chart_icon</Icon>,
        route: "/customer-monthly-sale",
        order: 159,
        component: <CustomerMonthlySale />,
      },
      {
        type: "collapse",
        name: "Daily Payment Received",
        key: "daily-payment-received",
        icon: <Icon fontSize="medium">price_check_icon</Icon>,
        route: "/daily-payment-received",
        order: 160,
        component: <DailyPaymentReceived />,
      },
      {
        type: "collapse",
        name: "Machine Wise Sales",
        key: "machine-wise-sale",
        icon: <Icon fontSize="medium">scanner_icon</Icon>,
        route: "/machine-wise-sale",
        order: 161,
        component: <MachineWiseSale />,
      },
      {
        type: "collapse",
        name: "Daily Customer Paper",
        key: "daily-customer-paper",
        icon: <Icon fontSize="medium">post_add_icon</Icon>,
        route: "/daily-customer-paper",
        order: 162,
        component: <DailyCustomerPaper />,
      },
    ]
  },
  {
    type: "collapse",
    name: "Finance",
    key: "finance",
    heading: 1,
    order: 200,
    icon: <Icon fontSize="medium">account_balance_icon</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "Daywise Costing",
        key: "day-wise-costing",
        icon: <Icon fontSize="medium">edit_calendar_icon</Icon>,
        route: "/day-wise-costing",
        order: 201,
        component: <DayWiseCosting />,
      },
      {
        type: "collapse",
        name: "Payment Received",
        key: "payment-received",
        icon: <Icon fontSize="medium">add_card_icon</Icon>,
        route: "/payment-received",
        order: 201,
        component: <PaymentReceived />,
      },
      {
        type: "collapse",
        name: "Outstanding Bills",
        key: "outstanding-bill",
        icon: <Icon fontSize="medium">receipt_long_icon</Icon>,
        route: "/outstanding-bill",
        order: 203,
        component: <OutstandingBill />,
      },
      {
        type: "collapse",
        name: "Job Cost Sheet",
        key: "job-cost-sheet",
        icon: <Icon fontSize="medium">request_page_icon</Icon>,
        route: "/job-cost-sheet",
        order: 204,
        component: <JobCostSheet />,
      },
      {
        type: "collapse",
        name: "Job Cost Sheet Summary",
        key: "job-cost-sheet-summary",
        icon: <Icon fontSize="medium">summarize_icon</Icon>,
        route: "/job-cost-sheet-summary",
        order: 205,
        component: <JobCostSheetSummary />,
      },
      {
        type: "collapse",
        name: "Supplier Payment Made",
        key: "supplier-payment-made",
        icon: <Icon fontSize="medium">credit_score_icon</Icon>,
        route: "/supplier-payment-made",
        order: 206,
        component: <SupplierPaymentMade />,
      },
      {
        type: "collapse",
        name: "Invoice Register",
        key: "invoice-register",
        icon: <Icon fontSize="medium">receipt_icon</Icon>,
        route: "/invoice-register",
        order: 207,
        component: <InvoiceRegister />,
      },
    ]
  },
  {
    type: "collapse",
    name: "Setup",
    key: "set-up",
    heading: 1,
    order: 250,
    icon: <Icon fontSize="medium">handyman_icon</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "Items",
        key: "manage-items",
        icon: <Icon fontSize="medium">list_alt_icon</Icon>,
        route: "/manage-items",
        order: 251,
        component: <ManageItems />,
      },
      {
        type: "collapse",
        name: "Supplier Items",
        key: "manage-supplier-items",
        icon: <Icon fontSize="medium">category_icon</Icon>,
        route: "/manage-supplier-items",
        order: 252,
        component: <ManageSupplierItem />,
      },
      {
        type: "collapse",
        name: "Customers",
        key: "manage-customers",
        icon: <Icon fontSize="medium">groups_icon</Icon>,
        route: "/manage-customers",
        order: 253,
        component: <ManageCustomer />,
      },
      {
        type: "collapse",
        name: "Customer Inventories",
        key: "manage-customer-inventories",
        icon: <Icon fontSize="medium">inventory_2_icon</Icon>,
        route: "/manage-customer-inventories",
        order: 254,
        component: <ManageCustomerInventory />,
      },
      {
        type: "collapse",
        name: "Suppliers",
        key: "manage-suppliers",
        icon: <Icon fontSize="medium">diversity_2_icon</Icon>,
        route: "/manage-suppliers",
        order: 255,
        component: <ManageSupplier />,
      },
      {
        type: "collapse",
        name: "Groups",
        key: "manage-groups",
        icon: <Icon fontSize="medium">workspaces_icon</Icon>,
        route: "/manage-groups",
        order: 256,
        component: <ManageGroup />,
      },
      {
        type: "collapse",
        name: "Sizes",
        key: "manage-sizes",
        icon: <Icon fontSize="medium">aspect_ration_icon</Icon>,
        route: "/manage-sizes",
        order: 257,
        component: <ManageSize />,
      },
      {
        type: "collapse",
        name: "Units",
        key: "manage-units",
        icon: <Icon fontSize="medium">balance_icon</Icon>,
        route: "/manage-units",
        order: 258,
        component: <ManageUnit />,
      },
      {
        type: "collapse",
        name: "Colors",
        key: "manage-colors",
        icon: <Icon fontSize="medium">color_lens_icon</Icon>,
        route: "/manage-colors",
        order: 259,
        component: <ManageColor />,
      },
      {
        type: "collapse",
        name: "Payment Types",
        key: "manage-payment-types",
        icon: <Icon fontSize="medium">payments_icon</Icon>,
        route: "/manage-payment-types",
        order: 260,
        component: <ManagePaymentType />,
      },
      {
        type: "collapse",
        name: "Printing Machines",
        key: "manage-printing-machines",
        icon: <Icon fontSize="medium">print_icon</Icon>,
        route: "/manage-printing-machines",
        order: 261,
        component: <ManagePrintingMachine />,
      },
      {
        type: "collapse",
        name: "Printing Rates",
        key: "manage-printing-rates",
        icon: <Icon fontSize="medium">rate_review_icon</Icon>,
        route: "/manage-printing-rates",
        order: 262,
        component: <ManagePrintingRate />,
      },
      {
        type: "collapse",
        name: "WO Printing Types",
        key: "manage-wo-printing-types",
        icon: <Icon fontSize="medium">scanner_icon</Icon>,
        route: "/manage-wo-printing-types",
        order: 263,
        component: <ManageWoPrintingType />,
      },
      {
        type: "collapse",
        name: "Statuses",
        key: "manage-statuses",
        icon: <Icon fontSize="medium">security_icon</Icon>,
        route: "/manage-statuses",
        order: 264,
        component: <ManageStatus />,
      },
      {
        type: "collapse",
        name: "Parameters",
        key: "manage-parameters",
        icon: <Icon fontSize="medium">settings_suggest_icon</Icon>,
        route: "/manage-parameters",
        order: 265,
        component: <ManageParameter />,
      },
      {
        type: "collapse",
        name: "HSN Codes",
        key: "manage-hsn-codes",
        icon: <Icon fontSize="medium">confirmation_number_icon</Icon>,
        route: "/manage-hsn-codes",
        order: 266,
        component: <ManageHsnCode />,
      }
    ]
  },
  {
    type: "collapse",
    name: "Access Management",
    key: "access-management",
    heading: 1,
    order: 300,
    icon: <Icon fontSize="medium">accessibility_icon</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "My Profile",
        key: "user-profile",
        icon: <Icon fontSize="medium">person</Icon>,
        route: "/user-profile",
        order: 301,
        component: <UserProfile />,
      },
      {
        type: "collapse",
        name: "Users",
        key: "users",
        icon: <Icon fontSize="medium">list</Icon>,
        route: "/user-management",
        order: 302,
        component: <UserManagement />,
      },
      {
        type: "collapse",
        name: "Permissions",
        key: "permission",
        icon: <Icon fontSize="medium">lock_open</Icon>,
        route: "/permission",
        order: 303,
        component: <Permission />
      },
    ]
  },
];

export default routes;
